export default {
  data: () => ({
    metaDataTenant: {
      page: 1,
      take: 50,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataTenant: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    tenantData: [],
    tenantOptions: [],

    metaDataTenantSchedules: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataTenantSchedules: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    tenantId: '',
    tenantSchedulesData: [],
    isInstitute: false,
  }),
  methods: {
    getDefaultTenantSchedules() {
      return [
        {
          day: 0,
          open_time: '00:00:00',
          closed_time: '00:00:00',
          is_close: 0,
          is_open: 1,
        },
        {
          day: 1,
          open_time: '00:00:00',
          closed_time: '00:00:00',
          is_close: 0,
          is_open: 1,
        },
        {
          day: 2,
          open_time: '00:00:00',
          closed_time: '00:00:00',
          is_close: 0,
          is_open: 1,
        },
        {
          day: 3,
          open_time: '00:00:00',
          closed_time: '00:00:00',
          is_close: 0,
          is_open: 1,
        },
        {
          day: 4,
          open_time: '00:00:00',
          closed_time: '00:00:00',
          is_close: 0,
          is_open: 1,
        },
        {
          day: 5,
          open_time: '00:00:00',
          closed_time: '00:00:00',
          is_close: 0,
          is_open: 1,
        },
        {
          day: 6,
          open_time: '00:00:00',
          closed_time: '00:00:00',
          is_close: 0,
          is_open: 1,
        },
      ]
    },
    async getAllTenant(setTenantId = false, getSchedules = false) {
      await this.$store
        .dispatch('tenant/getAll', {
          page: this.filterDataTenant.page,
          take: this.filterDataTenant.take,
          order: this.filterDataTenant.order,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.tenantData = responseData.data
            this.tenantOptions = this.tenantData.map((item) => {
              return {
                label: item.name,
                value: item.id,
              }
            })
          }
          if (responseData.meta) {
            this.metaDataTenant = responseData.meta
          }

          if (setTenantId) {
            if (this.tenantData.length > 0)
              this.tenantId = this.tenantData[0].id
          }
          if (getSchedules) {
            this.getTenantSchedules(true)
          }
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    async getTenantSchedules(first = false) {
      await this.$store
        .dispatch('tenant/getTenantSchedules', {
          tenantId: this.tenantId,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            const data = responseData.data
            if (data.length > 0) {
              this.tenantSchedulesData = data.map((obj) => ({
                ...obj,
                is_open: !obj.is_close,
              }))
            } else {
              this.tenantSchedulesData = this.getDefaultTenantSchedules()
            }
          }
          if (responseData.meta) {
            this.metaDataTenantSchedules = responseData.meta
          }

          if (!first) {
            this.message = response.data.message
            this.showToast('success')
          }
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    async setTenantSchedules(params) {
      await this.$store
        .dispatch('tenant/setTenantSchedules', {
          day_close: params.day_close,
          schedules: params.schedules,
          tenant_id: this.tenantId,
        })
        .then((response) => {
          this.message = response.data.message
          this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
  },
}

<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
  </div>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Order Detail Table -->
        <TableComponent
          :title="$t($route.meta.title)"
          :header="customerListHeader"
          :is-show-search="true"
          :is-show-add="true"
          :is-not-empty="customerData.length > 0"
          :meta-data="metaData"
          @change-filter="changeFilter($event)"
          @do-add="showModalAdd"
        >
          <tr
            v-for="customer in customerData"
            :key="customer.id"
            class="cursor-pointer bg-white hover:bg-gray-100 hover:shadow-md"
            @click="showDetailCustomer(customer.id)"
          >
            <td>
              {{ customer.full_name }}
            </td>
            <td class="text-center">
              {{ customer.phone_number }}
            </td>
            <td class="text-center">
              <span v-if="customer.transaction">
                {{ customer.transaction }}
              </span>
              <span v-else>-</span>
            </td>
            <td class="text-center">
              <span v-if="customer.last_transaction">
                {{ customer.last_transaction }}
              </span>
              <span v-else>-</span>
            </td>
            <!-- <td class="space-x-2">
              <EditIcon
                class="inline h-4 w-4 cursor-pointer text-warning hover:text-gray-500"
                @click="showModalEdit(customer)"
              />
              <DeleteIcon
                class="inline h-4 w-4 cursor-pointer text-danger hover:text-gray-500"
                @click="showModalDelete(customer.id)"
              />
            </td> -->
          </tr>
        </TableComponent>
        <!-- END: Order Detail Table -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->

        <!-- BEGIN: Modal -->
        <Modal :show="showModal" size="modal-lg" @hidden="showModal = false">
          <ModalBody class="p-4">
            <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
              <div class="flex flex-col space-y-3 border-b pb-2">
                <div class="flex justify-between">
                  <p class="text-base font-medium text-gray-500">
                    <span>
                      {{ $t('page.customer.modal.add') }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="flex flex-col space-y-4">
                <div class="flex flex-col space-y-2">
                  <label
                    class="block text-left text-xs font-bold text-gray-500 md:mb-0"
                    for="customer-name"
                  >
                    {{ $t('page.customer.input.name') }}
                  </label>
                  <input
                    id="customer-name"
                    v-model="name"
                    required="required"
                    class="w-full appearance-none rounded border-2 py-2 px-4 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                    type="text"
                    :placeholder="$t('page.customer.input.placeholder.name')"
                  />
                </div>
                <div class="flex flex-col space-y-2">
                  <label
                    class="block text-left text-xs font-bold text-gray-500 md:mb-0"
                    for="customer-phoneNumber"
                  >
                    {{ $t('page.customer.input.phoneNumber') }}
                  </label>
                  <div class="relative mt-2 rounded-md shadow-sm">
                    <div
                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                      <span class="text-gray-500 sm:text-sm">+62</span>
                    </div>
                    <input
                      id="customer-phoneNumber"
                      v-model="phoneNumber"
                      required="required"
                      class="block w-full appearance-none rounded border-2 py-2 pl-12 pr-20 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      type="text"
                      :placeholder="
                        $t('page.customer.input.placeholder.phoneNumber')
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end space-x-2 px-5 pb-8">
              <button type="button" class="btn w-24" @click="showModal = false">
                {{ $t('button.cancel') }}
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click="saveCustomer"
              >
                {{ $t('button.save') }}
              </button>
            </div>
          </ModalBody>
        </Modal>
        <!-- END: Modal -->

        <!-- BEGIN: Modal -->
        <Modal
          :show="detailModal"
          size="modal-xl"
          @hidden="detailModal = false"
        >
          <ModalBody class="p-4">
            <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
              <div class="flex flex-col space-y-3 border-b pb-2">
                <div class="flex justify-between">
                  <p class="font-medium text-gray-600">
                    <span>
                      {{ $t('page.customer.modal.detail') }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="flex flex-col space-y-4">
                <div v-if="customerDetailData !== null" class="flex space-x-6">
                  <div class="flex w-1/12">
                    <ImageLoader
                      :image="customerDetailData.avatar"
                      :alt="customerDetailData.full_name"
                      :is-default-avatar-svg="false"
                      :is-default-menu="true"
                      rounded-class="rounded-full"
                    />
                  </div>
                  <div class="w-1/11 grid w-full grid-cols-3 gap-10">
                    <div
                      class="flex flex-col items-start justify-center space-y-2"
                    >
                      <p class="text-base font-semibold">
                        {{ customerDetailData.full_name }}
                      </p>
                      <p
                        v-if="customerDetailData.phone_number"
                        class="text-xs text-gray-400"
                      >
                        {{ customerDetailData.phone_number }}
                      </p>
                      <p v-else class="text-gray-400">-</p>
                    </div>
                    <div
                      class="flex flex-col items-start justify-center space-y-2"
                    >
                      <p class="text-xs text-gray-400">
                        {{ $t('page.customer.modal.totalVisit') }}
                      </p>
                      <p
                        v-if="customerDetailData.total_visit"
                        class="text-base font-semibold"
                      >
                        {{ customerDetailData.total_visit }}
                      </p>
                      <p v-else class="text-gray-400">-</p>
                    </div>
                    <div
                      class="flex flex-col items-start justify-center space-y-2"
                    >
                      <p class="text-xs text-gray-400">
                        {{ $t('page.customer.modal.lastVisit') }}
                      </p>
                      <p
                        v-if="customerDetailData.last_visit"
                        class="text-base font-semibold"
                      >
                        {{ formattedDate(customerDetailData.last_visit) }}
                      </p>
                      <p v-else class="text-gray-400">-</p>
                    </div>

                    <div
                      class="flex flex-col items-start justify-center space-y-2"
                    >
                      <p class="text-xs text-gray-400">
                        {{ $t('page.customer.modal.totalSpending') }}
                      </p>
                      <p
                        v-if="customerDetailData.total_spending"
                        class="text-base font-semibold"
                      >
                        <Currency
                          :total-in-string="
                            String(customerDetailData.total_spending)
                          "
                          class="text-base"
                        />
                      </p>
                      <p v-else class="text-gray-400">-</p>
                    </div>
                    <div
                      class="flex flex-col items-start justify-center space-y-2"
                    >
                      <p class="text-xs text-gray-400">
                        {{ $t('page.customer.modal.memberSince') }}
                      </p>
                      <p
                        v-if="customerDetailData.member_since"
                        class="text-base font-semibold"
                      >
                        {{ formattedDate(customerDetailData.member_since) }}
                      </p>
                      <p v-else class="text-gray-400">-</p>
                    </div>
                    <div
                      class="flex flex-col items-start justify-center space-y-2"
                    >
                      <p class="text-xs text-gray-400">
                        {{ $t('page.customer.modal.statusSocial') }}
                      </p>
                      <p
                        v-if="customerDetailData.level"
                        class="flex items-center space-x-2 text-base font-semibold"
                      >
                        <span>
                          {{ customerDetailData.level }}
                        </span>
                        <Currency
                          :total-in-string="customerDetailData.point"
                          :with-prefix="false"
                          :leading-text="$t('page.customer.modal.point')"
                          wrap-class="inline text-xs rounded-full bg-emerald-600 py-1 px-4 text-white"
                        />
                      </p>
                      <p v-else class="text-gray-400">-</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end space-x-2 px-5 pb-8">
              <button
                type="button"
                class="btn btn-primary w-24"
                @click="detailModal = false"
              >
                {{ $t('button.close') }}
              </button>
            </div>
          </ModalBody>
        </Modal>
        <!-- END: Modal -->

        <DeleteModal
          :id="deleteId"
          :show-modal="showModalDel"
          @yes="confirmDelete($event)"
          @no="showModalDel = false"
        />

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import customerMixin from '@/mixins/customer.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import DeleteModal from '@/components/DeleteModal.vue'

import TableComponent from '@/components/TableComponent.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import Currency from '@/components/Currency.vue'

export default {
  name: 'CustomerListPage',
  components: {
    Breadcrumb,
    DeleteModal,
    TableComponent,
    ImageLoader,

    Currency,
  },
  mixins: [globalMixin, customerMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()

    const dateFilter = ref('')

    return {
      t,
      n,
      store,
      dateFilter,
    }
  },
  data: () => ({
    name: '',
    phoneNumber: '',
    customerId: null,
    message: '',
    showModal: false,
    detailModal: false,
    isEdit: false,
    isLoadingSave: false,
    customerListHeader: [
      {
        item: 'page.customer.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.customer.tableHeaders.phoneNumber',
        customClass: 'text-center',
      },
      {
        item: 'page.customer.tableHeaders.totalTransaction',
        customClass: 'text-center',
      },
      {
        item: 'page.customer.tableHeaders.lastTransaction',
        customClass: 'text-center',
      },
      //   {
      //     item: 'action.action',
      //   },
    ],
  }),
  mounted() {
    this.getAllCustomer()
  },
  methods: {
    showDetailCustomer(customerId) {
      this.getCustomerTransaction(customerId)
      this.getCustomerDetail(customerId)
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllCustomer()
    },
    resetData(afterSave = true) {
      this.customerId = null
      this.name = ''
      this.phoneNumber = ''
      if (afterSave) {
        this.isLoadingSave = false
        this.showModal = false
        this.isEdit = false
        this.getAllCustomer(false)
      }
    },
    showModalDelete(id) {
      this.showModalDel = true
      this.deleteId = id
    },
    confirmDelete(id) {
      this.deleteCustomer(id)
    },
    showModalAdd() {
      this.showModal = true
      this.isEdit = false
      this.resetData(false)
    },
    showModalEdit(data) {
      this.showModal = true
      this.isEdit = true
      this.name = data.name
      this.phoneNumber = data.phone_number
      this.customerId = data.id
    },
    saveCustomer() {
      if ((this.name === '' && this.phoneNumber === '') || this.isLoadingSave)
        return

      let phone_number = this.phoneNumber
      if (this.phoneNumber.startsWith('+62'))
        phone_number = this.phoneNumber.replace(/^.{3}/g, '0')
      else if (this.phoneNumber.startsWith('62'))
        phone_number = this.phoneNumber.replace(/^.{2}/g, '0')
      else if (this.phoneNumber.startsWith('8'))
        phone_number = this.phoneNumber.replace(/^.{2}/g, '08')

      const params = {
        name: this.name,
        phone_number,
      }
      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.customerId === null) {
          this.isLoadingSave = false
          return
        }
        params.id = this.customerId

        this.saveCustomerAPI(params, true)
      } else {
        this.saveCustomerAPI(params)
      }
    },
  },
}
</script>

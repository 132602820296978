<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.payment')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Order Detail Table -->
        <TableComponent
          :title="$t($route.meta.title)"
          :header="paymentChannelHeader"
          :is-show-search="true"
          :is-not-empty="paymentchannelData.length > 0"
          :meta-data="metaData"
          @change-filter="changeFilter($event)"
        >
          <tr
            v-for="channel in paymentchannelData.filter(
              (item) => item.payment_channel_type !== 'va'
            )"
            :key="channel.id"
          >
            <td>
              {{ channel.payment_channel_name }}
            </td>
            <td>
              <template v-if="channel.mdr_type === 'percentage'">
                <p>{{ channel.mdr_value }}%</p>
              </template>
              <template v-else>
                <Currency :total-in-string="channel.mdr_value" />
              </template>
            </td>
            <td class="text-center">
              <div class="form-switch">
                <input
                  v-model="channel.is_active"
                  type="checkbox"
                  class="form-check-input"
                  @change="setPaymentChannelStatus($event, channel)"
                />
              </div>
            </td>
            <td v-if="isInstituteAdmin && false" class="space-x-2">
              <span
                class="cursor-pointer font-medium text-primary"
                @click="showModalEdit(channel)"
              >
                {{ $t('action.edit') }}
              </span>
            </td>
          </tr>
        </TableComponent>
        <!-- END: Order Detail Table -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->

        <!-- BEGIN: Modal Input -->
        <Modal :show="showModal" size="modal-lg" @hidden="showModal = false">
          <ModalBody class="p-4">
            <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
              <div class="flex flex-col space-y-3">
                <div class="flex justify-between">
                  <p class="font-bold">
                    <span>
                      {{ $t('page.paymentChannel.editMDR') }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="flex flex-col space-y-4">
                <div class="flex space-x-4">
                  <label
                    class="block text-left font-bold text-gray-700"
                    for="mdr-fee"
                  >
                    {{ $t('page.paymentChannel.activeChannel') }} :
                  </label>
                  <p>{{ activeChannelName }}</p>
                </div>
                <div class="flex flex-col">
                  <label
                    class="block text-left font-bold text-gray-700"
                    for="mdr-fee"
                  >
                    {{ $t('page.paymentChannel.mdrFee') }}
                  </label>
                  <input
                    id="mdr-fee"
                    v-model="mdr"
                    required="required"
                    class="w-full appearance-none rounded border-2 py-2 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                    type="number"
                    :placeholder="$t('page.paymentChannel.mdrFee')"
                  />
                </div>
                <div class="flex flex-col">
                  <label
                    class="block text-left font-bold text-gray-700"
                    for="mdr-fee"
                  >
                    {{ $t('page.paymentChannel.mdrFee') }}
                  </label>
                  <select v-model="mdrType">
                    <option
                      v-for="mdrT in mdrTypes"
                      :key="mdrT.value"
                      :value="mdrT.value"
                    >
                      {{ mdrT.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex justify-end space-x-2 px-5 pb-8">
              <button type="button" class="btn w-24" @click="showModal = false">
                {{ $t('button.cancel') }}
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click="savePaymentChannel"
              >
                {{ $t('button.save') }}
              </button>
            </div>
          </ModalBody>
        </Modal>
        <!-- END: Modal Input -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->
      </div>
    </div>
    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import generalpaymentMixin from '@/mixins/generalpayment.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import Currency from '@/components/Currency.vue'

import TableComponent from '@/components/TableComponent.vue'

export default {
  name: 'PaymentChannelPage',
  components: {
    Breadcrumb,
    TableComponent,
    Currency,
  },
  mixins: [globalMixin, generalpaymentMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()

    const dateFilter = ref('')

    return {
      t,
      n,
      store,
      dateFilter,
    }
  },
  data: () => ({
    instituteId: null,
    activeId: null,
    mdr: 0,
    mdrType: '',
    activeChannelName: '',
    mdrTypes: [
      {
        value: 'percentage',
        label: 'Percent (%)',
      },
      {
        value: 'fixed',
        label: 'Fixed (Rp)',
      },
    ],
    paymentChannelHeader: [
      {
        item: 'page.paymentChannel.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.paymentChannel.tableHeaders.mdrFee',
        customClass: '',
      },
      {
        item: 'page.paymentChannel.tableHeaders.status',
        customClass: 'text-center',
      },
    ],
    client_reference_code: '',
  }),
  mounted() {
    if ('institute' in this.activeUserStore) {
      this.client_reference_code = this.activeUserStore.institute.slug
    }

    if (this.isInstituteAdmin && false) {
      this.paymentChannelHeader.push({
        item: 'action.action',
        customClass: '',
      })
    }

    this.getAllPaymentChannel(false, this.client_reference_code, true)
  },
  methods: {
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllPaymentChannel(true, this.client_reference_code, true)
    },
    setPaymentChannelStatus($event, data) {
      const checked = $event.target.checked

      const payload = {
        id: data.id,
        is_active: checked,
        is_cash_payment: false,
      }

      if (!this.isLoading) {
        this.setPaymentChannelStatusAPI(payload).then(() => {
          data.is_active = checked
        })
      }
    },
    showModalEdit(data) {
      this.mdr = data.admin_fee
      this.mdrType = data.admin_fee_type
      this.activeId = data.id
      this.activeChannelName = data.payment_channel_name
      this.showModal = true
    },
    resetData(afterSave = true) {
      this.activeId = null
      this.mdr = 0
      this.mdrType = ''
      this.activeChannelName = ''
      if (afterSave) {
        this.isLoadingSave = false
        this.showModal = false
        this.getAllPaymentChannel(false, this.client_reference_code, true)
      }
    },
    savePaymentChannel() {
      if (this.mdr === '') {
        this.message = 'Failed to update payment channel'

        setTimeout(() => {
          this.showToast('failed')
        }, 500)

        return
      }

      const params = {
        admin_fee: this.mdr.toString(),
        admin_fee_type: this.mdrType,
        id: this.activeId,
      }

      this.setPaymentChannelMDRAPI(params)
    },
  },
}
</script>

<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.analytic.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex w-full justify-end md:w-fit">
      <div class="relative w-full text-slate-500 sm:ml-auto sm:mt-0">
        <CalendarIcon
          class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
        />
        <Litepicker
          v-model="dateFilter"
          :options="{
            autoApply: false,
            singleMode: false,
            numberOfColumns: 2,
            numberOfMonths: 2,
            showWeekNumbers: true,
            format: 'YYYY-MM-DD',
            dropdowns: {
              minYear: 1990,
              maxYear: null,
              months: true,
              years: true,
            },
          }"
          class="form-control box pl-8 sm:w-56"
        />
      </div>
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Order Detail Report -->
        <div class="col-span-12 mt-8">
          <div
            class="intro-y flex w-full flex-col justify-between space-y-4 md:flex-row"
          >
            <div class="flex align-middle">
              <div class="flex justify-center self-center align-middle">
                <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
              </div>
              <div class="flex">
                <div
                  class="flex flex-col space-y-1 md:flex-row md:space-x-2 md:space-y-0 md:self-center md:align-middle"
                >
                  <h2 class="truncate text-lg font-medium">
                    {{ $t('page.analytic.stockTurnOver.title') }}
                  </h2>
                  <span class="flex self-center text-xs text-gray-500">
                    {{ dateFilterFormat(dateFilter) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="flex align-middle">
              <a href="" class="flex items-center space-x-2 text-primary">
                <RefreshCcwIcon class="h-4 w-4" />
                <span>{{ $t('action.reloadData') }}</span>
              </a>
            </div>
          </div>
          <div class="mt-5 grid grid-cols-12 gap-6">
            <Card
              :title="$t('page.analytic.stockTurnOver.saleStock')"
              :is-currency="false"
              :total="transaction_total"
              wrapper-class="col-span-12 sm:col-span-6 xl:col-span-6"
            />
            <Card
              :title="$t('page.analytic.stockTurnOver.sale')"
              :total="net_sales_total"
              wrapper-class="col-span-12 sm:col-span-6 xl:col-span-6"
            />
          </div>
        </div>
        <!-- END: Order Detail Report -->
        <!-- BEGIN: Order Detail Table -->
        <TableComponent
          :date-string="dateFilter"
          :title="$t('page.analytic.stockTurnOver.tableTitle')"
          :filter="filterDataPaymentMethod"
          :header="orderDetailReportHeader"
          :is-show-search="true"
          :is-not-empty="orderDetailReportData.length > 0"
          :meta-data="metaData"
          @change-filter="changeFilter($event)"
        >
          <tr
            v-for="data in orderDetailReportData"
            :key="data.order_number"
            class="cursor-pointer"
            @click="showDetail(data)"
          >
            <td>{{ data.order_number }}</td>
            <td>{{ data.customer_name }}</td>
            <td>
              {{ formatDateLocale(data.transaction_time) }}
              <!-- {{ data.transaction_time }} -->
            </td>
            <td>{{ data.tenant }}</td>
            <td class="text-center">
              {{ data.payment_method }}
            </td>
            <td>
              <Currency :total-in-string="data.payment_amount" />
            </td>
          </tr>
        </TableComponent>
        <!-- END: Order Detail Table -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->

        <!-- BEGIN: Modal -->
        <Modal
          :show="showDetailModal"
          size="modal-lg"
          @hidden="showDetailModal = false"
        >
          <ModalBody class="p-4 text-base md:text-sm">
            <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
              <div class="flex flex-col space-y-3">
                <div class="flex flex-col justify-between md:flex-row">
                  <p class="font-bold">
                    {{ $t('page.report.orderDetail.title') }}
                  </p>
                  <p class="text-slate-500">
                    {{ formatDateLocale(activeData.transaction_time) }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col space-y-3">
                <div class="flex flex-col justify-between md:flex-row">
                  <p class="font-semibold md:font-normal">
                    {{ $t('page.report.orderDetail.tableHeaders.orderNo') }}
                  </p>
                  <p>{{ activeData.order_number }}</p>
                </div>
                <div class="flex flex-col justify-between md:flex-row">
                  <p class="font-semibold md:font-normal">
                    {{
                      $t('page.report.orderDetail.tableHeaders.customerName')
                    }}
                  </p>
                  <p>{{ activeData.customer_name }}</p>
                </div>
                <div class="flex flex-col justify-between md:flex-row">
                  <p class="font-semibold md:font-normal">
                    {{ $t('page.report.orderDetail.tableHeaders.tenant') }}
                  </p>
                  <p>{{ activeData.tenant }}</p>
                </div>
                <div class="flex flex-col justify-between md:flex-row">
                  <p class="font-semibold md:font-normal">
                    {{
                      $t('page.report.orderDetail.tableHeaders.paymentMethod')
                    }}
                  </p>
                  <p>{{ activeData.payment_method }}</p>
                </div>
              </div>
              <div class="flex flex-col space-y-3">
                <div class="flex flex-col md:flex-row md:justify-between">
                  <p class="font-bold text-slate-500 md:font-normal">
                    {{ $t('page.report.orderDetail.tableHeaders.orderDetail') }}
                  </p>
                </div>
                <div
                  v-for="product in activeData.products"
                  :key="product.id"
                  class="flex flex-col justify-between text-center md:flex-row md:text-start"
                >
                  <p class="space-x-3">
                    <span>{{ product.name }}</span>
                    <span class="text-xs text-gray-400">
                      x {{ product.quantity }}
                    </span>
                  </p>
                  <p>
                    <Currency
                      :total-in-string="product.total"
                      wrap-class="justify-center md:justify-start"
                    />
                  </p>
                </div>
                <hr />
                <div class="flex flex-col justify-between md:flex-row">
                  <p class="font-semibold md:font-normal">
                    {{ $t('page.report.orderDetail.tableHeaders.discount') }}
                  </p>
                  <p>
                    <Currency
                      :total-in-string="activeData.discount"
                      wrap-class="text-red-500 justify-center md:justify-start"
                      prefix-text="-"
                    />
                  </p>
                </div>
                <hr />
                <div
                  v-if="activeData.admin_fee > 0"
                  class="flex flex-col justify-between md:flex-row"
                >
                  <p class="font-semibold md:font-normal">
                    {{ $t('page.report.orderDetail.tableHeaders.adminFee') }}
                  </p>
                  <p>
                    <Currency
                      :total-in-string="activeData.admin_fee"
                      wrap-class="justify-center md:justify-start"
                    />
                  </p>
                </div>
                <div v-if="activeData.tax_fee > 0" class="flex justify-between">
                  <p class="font-semibold md:font-normal">
                    {{ $t('page.report.orderDetail.tableHeaders.taxFee') }}
                  </p>
                  <p>
                    <Currency
                      :total-in-string="activeData.tax_fee"
                      wrap-class="justify-center md:justify-start"
                    />
                  </p>
                </div>
                <div
                  v-if="activeData.service_fee > 0"
                  class="flex flex-col justify-between md:flex-row"
                >
                  <p class="font-semibold md:font-normal">
                    {{ $t('page.report.orderDetail.tableHeaders.serviceFee') }}
                  </p>
                  <p>
                    <Currency
                      :total-in-string="activeData.service_fee"
                      wrap-class="justify-center md:justify-start"
                    />
                  </p>
                </div>
                <div
                  v-if="activeData.institute_fee > 0"
                  class="flex flex-col justify-between md:flex-row"
                >
                  <p class="font-semibold md:font-normal">
                    {{
                      $t('page.report.orderDetail.tableHeaders.instituteFee')
                    }}
                  </p>
                  <p>
                    <Currency
                      :total-in-string="activeData.institute_fee"
                      wrap-class="justify-center md:justify-start"
                    />
                  </p>
                </div>
                <div
                  v-if="activeData.mdr_fee > 0 && false"
                  class="flex flex-col justify-between md:flex-row"
                >
                  <p class="font-semibold md:font-normal">
                    {{ $t('page.report.orderDetail.tableHeaders.mdrFee') }}
                  </p>
                  <p>
                    <Currency
                      :total-in-string="activeData.mdr_fee"
                      wrap-class="justify-center md:justify-start"
                    />
                  </p>
                </div>
                <div
                  v-if="activeData.unique_code > 0"
                  class="flex flex-col justify-between md:flex-row"
                >
                  <p class="font-semibold md:font-normal">
                    {{ $t('page.report.orderDetail.tableHeaders.uniqueCode') }}
                  </p>
                  <p>
                    <Currency
                      :total-in-string="activeData.unique_code"
                      wrap-class="justify-center md:justify-start"
                    />
                  </p>
                </div>
                <hr />
                <div class="flex flex-col justify-between md:flex-row">
                  <p class="font-semibold md:font-normal">
                    {{ $t('page.report.orderDetail.tableHeaders.totalPay') }}
                  </p>
                  <p>
                    <Currency
                      :total-in-string="activeData.payment_amount"
                      wrap-class="justify-center md:justify-start"
                    />
                  </p>
                </div>
                <hr />
              </div>
            </div>
            <div class="flex justify-end px-5 pb-8">
              <button
                type="button"
                class="btn btn-primary w-24"
                @click="showDetailModal = false"
              >
                {{ $t('button.close') }}
              </button>
            </div>
          </ModalBody>
        </Modal>
        <!-- END: Modal -->
      </div>
    </div>

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import SaleIcon from '@/assets/svg/sale.svg'

import TableComponent from '@/components/TableComponent.vue'
import Card from '@/components/Card.vue'
import Currency from '@/components/Currency.vue'

export default {
  name: 'StockTurnoverPage',
  components: {
    Breadcrumb,
    TableComponent,
    Card,

    Currency,
    SaleIcon,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()

    const dateFilter = ref('')

    return {
      t,
      n,
      store,
      dateFilter,
    }
  },
  data: () => ({
    gross_sales_total: 0,
    net_sales_total: 0,
    payment_total: 0,
    transaction_total: 0,
    message: '',
    showDetailModal: false,
    activeData: {},
    filterDataPaymentMethod: {
      // title: 'Metode Pembayaran',
      // items: ['DANA', 'BCA', 'OVO'],
    },
    orderDetailReportHeader: [
      {
        item: 'page.analytic.stockTurnOver.tableHeaders.stockName',
        customClass: '',
      },
      {
        item: 'page.analytic.stockTurnOver.tableHeaders.category',
        customClass: '',
      },
      {
        item: 'page.analytic.stockTurnOver.tableHeaders.sale',
        customClass: '',
      },
      {
        item: 'page.analytic.stockTurnOver.tableHeaders.stock',
        customClass: '',
      },
      {
        item: 'page.report.orderDetail.tableHeaders.paymentMethod',
        customClass: 'text-center',
      },
      {
        item: 'page.analytic.stockTurnOver.tableHeaders.saleTotal',
        customClass: '',
      },
    ],
    orderDetailReportData: [],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.getSalesDetailSummary()
      this.getSalesDetail()
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }
  },
  methods: {
    async getSalesDetailSummary() {
      this.isLoading = true

      await this.store
        .dispatch('sales/salesDetailSummary', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          tenant_id: this.selectedTenantIdString,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.gross_sales_total = responseData.gross_sales_total
            this.net_sales_total = responseData.net_sales_total
            this.payment_total = responseData.payment_total
            this.transaction_total = responseData.transaction_total
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          this.showToast('failed')
        })
    },
    async getSalesDetail() {
      await this.store
        .dispatch('sales/salesDetail', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          customer: this.filterData.search,
          tenant_id: this.selectedTenantIdString,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.orderDetailReportData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    async downloadSalesDetail(fileType) {
      await this.store
        .dispatch('sales/salesDetailDownload', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'report_sales.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getSalesDetail()
    },
    showDetail(data) {
      this.showDetailModal = true
      this.activeData = data
    },
  },
}
</script>

<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="relative grid grid-cols-12 gap-6">
        <div
          v-if="!checkEmptyObject(purchaseOrderDetailData)"
          class="col-span-12 mt-8"
        >
          <Breadcrumb
            link="stock-purchase-history"
            title="Riwayat Pesanan"
            :subtitle="getSplitString(purchaseOrderDetailData.id).toUpperCase()"
          />
        </div>
        <!-- BEGIN: Detail Order -->

        <div class="col-span-12">
          <div
            class="intro-y flex w-full flex-col justify-between space-y-2 md:flex-row md:space-y-0"
          >
            <div class="flex align-middle">
              <div class="flex justify-center self-center align-middle">
                <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
              </div>
              <div class="flex flex-col justify-between md:flex-row">
                <div class="flex self-start align-middle md:self-center">
                  <h2 class="truncate text-lg font-medium uppercase md:mr-5">
                    <span v-if="viewMode === 1">
                      {{ getSplitString($route.params.orderId) }}
                    </span>
                    <span v-else>
                      {{ getSplitString(purchaseOrderDetailData.id) }}
                    </span>
                  </h2>
                </div>
                <div class="flex w-full justify-end self-center">
                  <p class="text-xs text-gray-500 md:text-sm">
                    <span v-if="!checkEmptyObject(purchaseOrderDetailData)">
                      {{
                        formattedDate(
                          subDate(purchaseOrderDetailData.created),
                          'dddd, DD MMMM YYYY'
                        )
                      }}
                    </span>
                    <span v-else>-</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-0 flex space-x-4 align-middle md:mt-2">
              <button class="rounded-lg bg-white px-2 py-2 md:py-0">
                <span> {{ $t('info.status') }}: </span>
                <template v-if="!checkEmptyObject(purchaseOrderDetailData)">
                  <span
                    v-if="
                      purchaseOrderDetailDataStatuses.every((status) =>
                        ['PENDING'].includes(status)
                      )
                    "
                    :class="[getStatusOrderColor('PENDING', 'text')]"
                  >
                    {{ $t(`page.purchaseOrder.history.PENDING`) }}
                  </span>
                  <span
                    v-else-if="
                      purchaseOrderDetailDataStatuses.some((status) =>
                        ['PENDING'].includes(status)
                      )
                    "
                    :class="[getStatusOrderColor('PROCESS', 'text')]"
                  >
                    {{ $t(`page.purchaseOrder.history.PROCESS`) }}
                  </span>
                  <span
                    v-else
                    :class="[
                      'status' in purchaseOrderDetailDataComputedFirst
                        ? getStatusOrderColor(
                            purchaseOrderDetailDataComputedFirst.status,
                            'text'
                          )
                        : '',
                    ]"
                  >
                    {{
                      $t(
                        `page.purchaseOrder.history.${purchaseOrderDetailDataComputedFirst.status}`
                      )
                    }}
                  </span>
                </template>
              </button>

              <button
                v-if="false"
                class="rounded-lg bg-primary px-2 text-white"
              >
                {{ $t('button.downloadInvoice') }}
              </button>
            </div>
          </div>
        </div>

        <div class="col-span-12 mt-6 space-y-3 md:col-span-8 md:mt-0">
          <div class="rounded-lg bg-white p-4">
            <p class="mb-4 text-xl">
              {{ $t('page.purchaseOrder.history.detail.title') }}
            </p>
            <template v-if="viewMode === 1">
              <div
                v-for="supplierData in purchaseOrderDetailDataComputed"
                :key="supplierData.id"
              >
                <div class="-mb-4 flex w-full justify-between">
                  <div class="flex items-center space-x-2">
                    <p>{{ supplierData.supplier_name }}</p>
                    <p
                      class="rounded-lg px-2 py-1 text-xs text-white"
                      :class="[
                        'status' in supplierData
                          ? getStatusOrderColor(supplierData.status, 'bg')
                          : '',
                      ]"
                    >
                      {{
                        $t(`page.purchaseOrder.history.${supplierData.status}`)
                      }}
                    </p>
                  </div>
                  <template v-if="isInstitute">
                    <div
                      v-if="
                        ['PENDING'].includes(supplierData.status) &&
                        purchaseOrderDetailData.payment_type === 'cash'
                      "
                    >
                      <button
                        class="w-full cursor-pointer rounded-md border bg-red-500 px-2 py-2 text-xs text-white"
                        @click="
                          cancelPurchaseOrderSupplier(
                            {
                              transactionSupplierId: supplierData.id,
                              transactionId: purchaseOrderDetailData.id,
                            },
                            true
                          )
                        "
                      >
                        Batalkan pesanan
                      </button>
                    </div>
                  </template>
                </div>
                <TableComponent
                  :is-show-search="false"
                  :show-title="false"
                  :header="purchaseItemHeaderList"
                  :is-not-empty="supplierData.purchase_item.length > 0"
                  :is-show-pagination="false"
                  :show-icon="false"
                  wrapper-class="rounded-lg"
                >
                  <tr v-for="data in supplierData.purchase_item" :key="data.id">
                    <td class="uppercase">
                      {{ getSplitString(data.id) }}
                    </td>
                    <td>{{ data.product.name }}</td>
                    <td class="text-center">x{{ data.quantity }}</td>
                    <td>
                      <Currency
                        :total-in-string="data.total"
                        wrap-class="justify-end"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right font-bold shadow-none">
                      {{
                        $t(
                          'page.purchaseOrder.history.detail.tableHeaders.total'
                        )
                      }}
                    </td>
                    <td class="text-center">
                      <Currency
                        :total-in-string="supplierData.sub_total"
                        wrap-class="justify-end"
                      />
                    </td>
                  </tr>
                </TableComponent>
              </div>
            </template>
            <template v-else>
              <div>
                <p class="-mb-4">
                  {{ purchaseOrderDetailDataComputed.supplier_name }}
                </p>
                <TableComponent
                  v-if="!checkEmptyObject(purchaseOrderDetailDataComputed)"
                  :is-show-search="false"
                  :show-title="false"
                  :header="purchaseItemHeaderList"
                  :is-not-empty="
                    purchaseOrderDetailData.purchase_item.length > 0
                  "
                  :is-show-pagination="false"
                  :show-icon="false"
                  wrapper-class="rounded-lg"
                >
                  <tr
                    v-for="data in purchaseOrderDetailData.purchase_item"
                    :key="data.id"
                  >
                    <td class="uppercase">
                      {{ getSplitString(data.id) }}
                    </td>
                    <td>{{ data.product.name }}</td>
                    <td class="text-center">x{{ data.quantity }}</td>
                    <td>
                      <Currency
                        :total-in-string="data.total"
                        wrap-class="justify-end"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="text-right font-bold shadow-none">
                      {{
                        $t(
                          'page.purchaseOrder.history.detail.tableHeaders.total'
                        )
                      }}
                    </td>
                    <td class="text-center">
                      <Currency
                        :total-in-string="purchaseOrderDetailData.sub_total"
                        wrap-class="justify-end"
                      />
                    </td>
                  </tr>
                </TableComponent>
              </div>
            </template>
          </div>

          <div class="rounded-lg bg-white p-4">
            <TableComponent
              v-if="!checkEmptyObject(purchaseOrderDetailData)"
              :is-show-search="false"
              :title="$t('page.purchaseOrder.history.paidByInstitute')"
              :show-table-header="false"
              :is-not-empty="
                viewMode === 1
                  ? purchaseOrderDetailDataComputed.length > 0
                  : purchaseOrderDetailDataComputed.purchase_item.length > 0
              "
              :is-show-pagination="false"
              :show-icon="false"
              wrapper-class="rounded-lg"
            >
              <tr>
                <td>Subtotal</td>
                <td>
                  <span v-if="viewMode === 1">
                    {{ purchaseOrderDetailDataComputed.length }}
                  </span>
                  <span v-else>
                    {{ purchaseOrderDetailDataComputed.purchase_item.length }}
                  </span>
                  items
                </td>
                <td>
                  <Currency
                    :total-in-string="totalPricePODetail"
                    wrap-class="justify-end"
                  />
                </td>
              </tr>
              <tr>
                <td>Pengiriman</td>
                <td>Kargo Supplier</td>
                <td>
                  <Currency
                    :total-in-string="purchaseOrderDetailData.shipping_fee"
                    wrap-class="justify-end"
                  />
                </td>
              </tr>
              <tr>
                <td>Fee Transaksi</td>
                <td>3%</td>
                <td>
                  <Currency
                    :total-in-string="purchaseOrderDetailData.transaction_fee"
                    wrap-class="justify-end"
                  />
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-right font-bold shadow-none">
                  {{
                    $t('page.purchaseOrder.history.detail.tableHeaders.total')
                  }}
                </td>
                <td>
                  <Currency
                    :total-in-string="
                      viewMode === 1
                        ? purchaseOrderDetailData.total
                        : purchaseOrderDetailData.total_origin
                    "
                    wrap-class="justify-end"
                  />
                </td>
              </tr>
            </TableComponent>
          </div>
        </div>

        <div class="col-span-12 space-y-3 md:col-span-4">
          <div class="space-y-3 rounded-lg bg-white py-4 px-8">
            <h1 class="text-base">Informasi Tenant</h1>
            <div class="flex flex-col space-y-1">
              <p class="text-sm text-gray-500">Nama Tenant</p>
              <p class="font-bold">
                <span
                  v-if="
                    !checkEmptyObject(purchaseOrderDetailData) &&
                    'tenant' in purchaseOrderDetailData
                  "
                >
                  {{ purchaseOrderDetailData.tenant.name }}
                </span>
                <span v-else> - </span>
              </p>
            </div>
            <div class="flex flex-col space-y-1">
              <p class="text-sm text-gray-500">Nama Penerima</p>
              <p class="font-bold">
                <span
                  v-if="
                    !checkEmptyObject(purchaseOrderDetailData) &&
                    'address' in purchaseOrderDetailData
                  "
                >
                  {{ purchaseOrderDetailData.address.recipient }}
                </span>
                <span v-else> - </span>
              </p>
            </div>
            <div v-if="false" class="flex flex-col space-y-1">
              <p class="text-sm text-gray-500">Email</p>
              <p class="font-bold">
                <span
                  v-if="
                    !checkEmptyObject(purchaseOrderDetailData) &&
                    'address' in purchaseOrderDetailData
                  "
                >
                  {{ purchaseOrderDetailData.address.email || '-' }}
                </span>
                <span v-else> - </span>
              </p>
            </div>
            <div class="flex flex-col space-y-1">
              <p class="text-sm text-gray-500">Nomor telepon</p>
              <p class="font-bold">
                <span
                  v-if="
                    !checkEmptyObject(purchaseOrderDetailData) &&
                    'address' in purchaseOrderDetailData
                  "
                >
                  {{ purchaseOrderDetailData.address.phone || '-' }}
                </span>
                <span v-else> - </span>
              </p>
            </div>
            <div class="flex flex-col space-y-1">
              <p class="text-sm text-gray-500">Alamat</p>
              <div class="font-bold">
                <div
                  v-if="
                    !checkEmptyObject(purchaseOrderDetailData) &&
                    'address' in purchaseOrderDetailData
                  "
                >
                  {{ purchaseOrderDetailData.address.detail_address }}
                  <span v-if="'village' in purchaseOrderDetailData.address">
                    ,
                    {{ purchaseOrderDetailData.address.village }}
                  </span>
                  <span v-if="'district' in purchaseOrderDetailData.address">
                    ,
                    {{ purchaseOrderDetailData.address.district }}
                  </span>
                  <span v-if="'city' in purchaseOrderDetailData.address">
                    ,
                    {{ purchaseOrderDetailData.address.city }}
                  </span>
                  <span v-if="'province' in purchaseOrderDetailData.address">
                    ,
                    {{ purchaseOrderDetailData.address.province }}
                  </span>
                  <span v-if="'postal_code' in purchaseOrderDetailData.address">
                    ,
                    {{ purchaseOrderDetailData.address.postal_code }}
                  </span>
                </div>
                <span v-else> - </span>
              </div>
            </div>
            <div v-if="false" class="flex flex-col space-y-1">
              <p class="text-sm text-gray-500">Metode Pembayaran</p>
              <p class="font-bold">
                {{ purchaseOrderDetailData.payment_type }}
              </p>
            </div>
          </div>
          <div class="space-y-3 rounded-lg bg-white py-4 px-8">
            <h1 class="text-base">Informasi Pengiriman</h1>
            <div class="flex flex-col space-y-1">
              <p class="text-sm text-gray-500">Ekspedisi</p>
              <p class="font-bold">Kargo Supplier</p>
            </div>
            <div class="flex flex-col space-y-1">
              <p class="text-sm text-gray-500">Nomor Resi</p>
              <p class="font-bold">-</p>
            </div>
            <div class="flex flex-col space-y-1">
              <p class="text-sm text-gray-500">Metode pembayaran</p>
              <p class="font-bold uppercase">
                {{ purchaseOrderDetailData.payment_type }}
              </p>
            </div>
            <div
              v-if="
                !['PENDING'].includes(
                  purchaseOrderDetailDataComputedFirst.status
                )
              "
              class="flex flex-col space-y-1"
            >
              <p class="text-sm text-gray-500">Status Terakhir</p>
              <p class="font-bold">
                <span v-if="!checkEmptyObject(purchaseOrderDetailData)">
                  {{
                    $t(
                      `page.purchaseOrder.history.${purchaseOrderDetailDataComputedFirst.status}`
                    )
                  }}
                </span>
                <span v-else> - </span>
              </p>
            </div>

            <div
              v-if="
                !isInstitute &&
                !checkEmptyObject(purchaseOrderDetailData) &&
                ['REQUEST'].includes(
                  purchaseOrderDetailDataComputedFirst.status
                ) &&
                false
              "
            >
              <button
                class="w-full rounded-md border border-red-500 px-4 py-2 text-red-500"
                @click="cancelPurchaseOrder(purchaseOrderDetailData.id, true)"
              >
                Batalkan pesanan
              </button>
            </div>

            <template v-if="isInstitute">
              <template v-if="purchaseOrderDetailData.payment_type === 'cash'">
                <div
                  v-if="
                    !checkEmptyObject(purchaseOrderDetailData) &&
                    purchaseOrderDetailDataStatuses.every((status) =>
                      ['PENDING'].includes(status)
                    )
                  "
                >
                  <button
                    class="w-full cursor-pointer rounded-md border border-red-500 px-4 py-2 text-red-500"
                    @click="
                      cancelPurchaseOrder(purchaseOrderDetailData.id, true)
                    "
                  >
                    Batalkan pesanan
                  </button>
                </div>
              </template>
              <template v-else>
                <div
                  v-if="
                    !checkEmptyObject(purchaseOrderDetailData) &&
                    purchaseOrderDetailDataStatuses.some((status) =>
                      ['PENDING'].includes(status)
                    )
                  "
                >
                  <button
                    class="w-full cursor-pointer rounded-md border border-red-500 px-4 py-2 text-red-500"
                    @click="
                      cancelPurchaseOrder(purchaseOrderDetailData.id, true)
                    "
                  >
                    Batalkan pesanan
                  </button>
                </div>
              </template>
              <div
                v-if="
                  !checkEmptyObject(purchaseOrderDetailData) &&
                  ['DELIVERY'].includes(
                    purchaseOrderDetailDataComputedFirst.status
                  )
                "
              >
                <button
                  class="w-full cursor-pointer rounded-md border border-primary px-4 py-2 text-primary"
                  @click="receivePurchaseOrderSupplier(transactionSupplierId)"
                >
                  Terima pesanan
                </button>
              </div>
              <div
                v-if="
                  !checkEmptyObject(purchaseOrderDetailData) &&
                  ['REQUEST'].includes(
                    purchaseOrderDetailDataComputedFirst.status
                  )
                "
                class="space-y-4"
              >
                <div class="flex w-full flex-col space-y-1">
                  <label>Pilih metode pembayaran</label>
                  <select
                    v-model="selectedPaymentChannel"
                    class="w-full rounded-lg border p-2 pr-10 outline-none hover:border-primary"
                  >
                    <option
                      v-for="channel in paymentchannelData.filter(
                        (item) => item.name.toLowerCase() === 'cash'
                      )"
                      :key="channel.id"
                      :value="channel"
                    >
                      {{ channel.name }}
                    </option>
                  </select>
                </div>

                <button
                  class="w-full cursor-pointer rounded-md border border-primary bg-primary px-4 py-2 text-white"
                  @click="approvePurchaseOrder(purchaseOrderDetailData.id)"
                >
                  Buat pesanan
                </button>
                <button
                  class="w-full cursor-pointer rounded-md border border-primary px-4 py-2 text-primary"
                  @click="adjustmentPurchaseOrder(purchaseOrderDetailData.id)"
                >
                  Lakukan penyesuaian
                </button>
                <button
                  class="w-full cursor-pointer rounded-md border border-red-500 px-4 py-2 text-red-500"
                  @click="rejectPurchaseOrder(purchaseOrderDetailData.id)"
                >
                  Reject pesanan
                </button>
              </div>
            </template>
          </div>
        </div>

        <!-- END: Detail Order -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->

        <LoadingIndicator v-if="isLoading || isLoadingSave" />

        <!-- BEGIN: Modal Order -->
        <Modal :show="showModalOrder" @hidden="showModalOrder = false">
          <template v-if="!checkEmptyObject(purchaseOrderDetailData)">
            <ModalBody class="p-0">
              <div class="p-5 text-center">
                <div class="text-sm">
                  <template v-if="purchaseOrderDetailData.status === 'PROCESS'">
                    <span>
                      {{ $t('page.order.wantSentOrder') }}
                    </span>
                    <span
                      v-if="'tenant' in purchaseOrderDetailData"
                      class="font-bold"
                    >
                      {{ purchaseOrderDetailData.tenant.name }}
                    </span>
                    <span>? {{ $t('page.order.makeSureOrder') }}</span>
                  </template>
                  <template
                    v-else-if="purchaseOrderDetailData.status === 'DELIVERY'"
                  >
                    <span>
                      {{ $t('page.order.makeSureOrderSent') }}
                    </span>
                  </template>
                  <template
                    v-else-if="purchaseOrderDetailData.status === 'RECEIVED'"
                  >
                    <span>
                      {{ $t('page.order.makeSureOrderProof') }}
                    </span>
                  </template>
                </div>
              </div>
              <div
                class="flex w-full justify-center space-x-2 px-4 pb-4 text-center md:justify-end"
              >
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="showModalOrder = false"
                >
                  {{ $t('button.back') }}
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="updateStatusOrder()"
                >
                  <span v-if="purchaseOrderDetailData.status === 'PROCESS'">
                    {{ $t('page.order.sendOrder') }}
                  </span>
                  <span
                    v-else-if="purchaseOrderDetailData.status === 'DELIVERY'"
                  >
                    {{ $t('page.order.orderSentOk') }}
                  </span>
                  <span
                    v-else-if="purchaseOrderDetailData.status === 'RECEIVED'"
                  >
                    {{ $t('button.done') }}
                  </span>
                </button>
              </div>
            </ModalBody>
          </template>
        </Modal>
        <!-- END: Modal Order -->

        <!-- BEGIN: Modal Reject Order -->
        <Modal
          :show="showModalOrderReject"
          @hidden="showModalOrderReject = false"
        >
          <template v-if="!checkEmptyObject(purchaseOrderDetailData)">
            <ModalBody class="p-0">
              <div class="p-5 text-center">
                <div class="text-sm">
                  <template v-if="purchaseOrderDetailData.status === 'PENDING'">
                    <span>
                      {{ $t('page.order.wantRejectOrder') }}
                    </span>
                  </template>
                  <template
                    v-else-if="
                      ['PROCESS', 'PAID'].includes(
                        purchaseOrderDetailData.status
                      )
                    "
                  >
                    <span>
                      {{ $t('page.order.wantCancelOrder') }}
                    </span>
                  </template>
                </div>
              </div>
              <div
                class="flex w-full justify-center space-x-2 px-4 pb-4 text-center md:justify-end"
              >
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  @click="showModalOrderReject = false"
                >
                  {{ $t('button.back') }}
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="rejectStatusOrder()"
                >
                  <span v-if="purchaseOrderDetailData.status === 'PENDING'">
                    {{ $t('page.order.button.rejectOrder') }}
                  </span>
                  <span
                    v-if="
                      ['PROCESS', 'PAID'].includes(
                        purchaseOrderDetailData.status
                      )
                    "
                  >
                    {{ $t('page.order.button.cancelOrder') }}
                  </span>
                </button>
              </div>
            </ModalBody>
          </template>
        </Modal>
        <!-- END: Modal Reject Order -->

        <!-- BEGIN: Modal Image -->
        <Modal
          :show="showModalImage"
          size="modal-lg"
          @hidden="showModalImage = false"
        >
          <template v-if="!checkEmptyObject(purchaseOrderDetailData)">
            <ModalBody class="p-0">
              <div
                class="max-h-full max-w-full overflow-scroll p-5 text-center"
              >
                <p class="pb-3">Bukti Pembayaran</p>

                <img
                  v-show="checkEmptyObject(file)"
                  :src="purchaseOrderDetailData.proof_of_payment"
                />

                <img
                  v-show="!checkEmptyObject(file)"
                  id="filePreview"
                  ref="filePreview"
                  src="#"
                  alt="Preview Bukti Pembayaran"
                />
              </div>
              <div
                class="flex w-full justify-end space-x-2 px-4 pb-4 text-center"
              >
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="showModalImage = false"
                >
                  {{ $t('button.back') }}
                </button>
              </div>
            </ModalBody>
          </template>
        </Modal>
        <!-- END: Modal Image -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

import { helper as $h } from '@/utils/helper'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import globalMixin from '@/mixins/global.js'
import stockPurchaseMixin from '@/mixins/stockPurchase.js'
import tenantMixin from '@/mixins/tenant.js'

import Currency from '@/components/Currency.vue'
import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'

import SaleIcon from '@/assets/svg/sale.svg'
import OpenInNew from '@/assets/svg/open-in-new.svg'

export default {
  name: 'OrderListPage',
  components: {
    Currency,
    Breadcrumb,
    TableComponent,

    SaleIcon,
    OpenInNew,
  },
  mixins: [globalMixin, stockPurchaseMixin, tenantMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const format = 'DD MMMM YYYY'
    const dateToday = ref('')

    onMounted(() => {
      const lang = route.query.lang
      if (lang === 'en') {
        dateToday.value = $h.formatDate(new Date(), format, 'en')
      } else {
        dateToday.value = $h.formatDate(new Date(), format, 'id')
      }
    })

    return {
      t,
      n,
      store,
      route,
      router,
      dateToday,
    }
  },
  data: () => ({
    showModalImage: false,
    showModalOrderReject: false,
    file: {},
    transactionSupplierId: undefined,
    status: undefined,
    viewMode: 1, // 1: purchase ingredient / 2: purchase supplier
    viewModePurchaseIngredient: ['PENDING', 'REQUEST', 'REJECTED'],

    purchaseItemHeaderList: [
      {
        item: 'page.purchaseOrder.history.detail.tableHeaders.id',
        customClass: '',
      },
      {
        item: 'page.purchaseOrder.history.detail.tableHeaders.productName',
        customClass: '',
      },
      {
        item: 'page.purchaseOrder.history.detail.tableHeaders.qty',
        customClass: 'text-center',
      },
      {
        item: 'page.purchaseOrder.history.detail.tableHeaders.price',
        customClass: 'text-right',
      },
    ],
  }),
  computed: {
    hasProofPayment() {
      if (
        !this.checkEmptyObject(this.purchaseOrderDetailData) &&
        'proof_of_payment' in this.purchaseOrderDetailData
      ) {
        return (
          this.purchaseOrderDetailData.proof_of_payment !== '' &&
          this.purchaseOrderDetailData.proof_of_payment !== null
        )
      } else {
        return false
      }
    },
    purchaseOrderDetailDataComputed() {
      if (!this.checkEmptyObject(this.purchaseOrderDetailData)) {
        if (this.viewMode === 1) {
          return this.purchaseOrderDetailData.purchase_suppliers
        }
      }
      return this.purchaseOrderDetailData
    },
    purchaseOrderDetailDataComputedFirst() {
      if (!this.checkEmptyObject(this.purchaseOrderDetailDataComputed)) {
        if (this.viewMode === 1) {
          if (this.purchaseOrderDetailDataComputed.length > 0)
            return this.purchaseOrderDetailDataComputed[0]
        }
      }
      return this.purchaseOrderDetailDataComputed
    },
    purchaseOrderDetailDataStatuses() {
      if (!this.checkEmptyObject(this.purchaseOrderDetailData)) {
        if (this.viewMode === 1) {
          return this.purchaseOrderDetailData.purchase_suppliers.reduce(
            (accumulator, currVal) => {
              accumulator.push(currVal.status)
              return accumulator
            },
            []
          )
        }
      }
      return [this.purchaseOrderDetailData.status]
    },
  },
  mounted() {
    this.transactionSupplierId = this.$route.query.id
    this.status = this.$route.query.status
    if (this.status !== undefined) {
      this.viewMode = this.viewModePurchaseIngredient.includes(
        this.status.toUpperCase()
      )
        ? 1
        : 2
    }

    if (this.transactionSupplierId === undefined) this.viewMode = 1

    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        if (this.viewMode === 1) {
          this.getDetailPurchaseOrder(this.$route.params.orderId, true)
        } else {
          this.getDetailPurchaseSupplier(this.transactionSupplierId, true)
        }

        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          if (this.viewMode === 1) {
            this.getDetailPurchaseOrder(this.$route.params.orderId, true)
          } else {
            this.getDetailPurchaseSupplier(this.transactionSupplierId, true)
          }
        })
        this.getAllPaymentChannel(false)
        this.isInstitute = true
      }
    }
  },
  methods: {
    fileInput(event) {
      this.showModalImage = false

      this.file = event.target.files[0]

      if (event.target.files) {
        const fileReader = new FileReader()
        const preview = document.getElementById('filePreview')
        if (preview) {
          fileReader.onload = (event) => {
            preview.setAttribute('src', event.target.result)
          }
        }
        fileReader.readAsDataURL(event.target.files[0])
      }

      this.uploadPurchaseOrderProofPayment(
        this.$route.params.orderId,
        this.file,
        true
      ).then(() => {
        this.getDetailPurchaseOrder(this.$route.params.orderId)
      })
    },
    clickButtonRejectStatus() {
      if (!['PAID', 'PENDING'].includes(this.purchaseOrderDetailData.status))
        return

      this.showModalOrderReject = true
    },
    rejectStatusOrder() {
      this.showModalOrderReject = false

      this.updatePurchaseOrderStatus(
        this.purchaseOrderDetailData.id,
        'CANCELLED',
        true
      )
    },
    clickButtonChangeOrderStatus() {
      if (
        this.purchaseOrderDetailData.status === 'DELIVERY' &&
        !this.hasProofPayment
      ) {
        return
      }

      if (
        ['PROCESS', 'DELIVERY', 'RECEIVED'].includes(
          this.purchaseOrderDetailData.status
        )
      ) {
        this.showModalOrder = true
      } else {
        this.updateStatusOrder()
      }
    },
    updateStatusOrder() {
      this.showModalOrder = false

      // if (['PAID', 'DELIVERY'].includes(this.purchaseOrderDetailData.status)) {
      //   this.updatePurchaseOrderStatus(
      //     this.purchaseOrderDetailData.id,
      //     this.getNextStatus(this.purchaseOrderDetailData.status),
      //     true
      //   ).then(() => {
      //     this.updatePurchaseOrderStatus(
      //       this.purchaseOrderDetailData.id,
      //       this.getNextStatus(this.purchaseOrderDetailData.status),
      //       false
      //     )
      //   })
      // } else {
      this.updatePurchaseOrderStatus(
        this.purchaseOrderDetailData.id,
        this.getNextStatus(this.purchaseOrderDetailData.status),
        true
      )
      // }
    },
    approvePurchaseOrder(id) {
      const params = {}
      params.id = id
      if (!this.checkEmptyObject(this.selectedPaymentChannel)) {
        params.payment_type = this.selectedPaymentChannel.channel_type
        params.payment_channel_code =
          this.selectedPaymentChannel.payment_channel_code
        params.payment_channel_id = this.selectedPaymentChannel.id
        params.client_reference_channel_id =
          this.selectedPaymentChannel.client_reference_channel_id
        params.phone_number = null

        this.approveStockPurchaseAPI(params)
      }
    },
    adjustmentPurchaseOrder(id) {
      this.$router.push({
        name: 'stock-purchase-cart',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
          from: id,
        },
      })
    },
  },
}
</script>

<template>
  <Chart
    :type="type"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useDarkModeStore } from '@/stores/dark-mode'
import { useColorSchemeStore } from '@/stores/color-scheme'
import { colors } from '@/utils/colors'

const props = defineProps({
  type: {
    type: String,
    default: 'pie',
  },
  width: {
    type: [Number, String],
    default: 'auto',
  },
  height: {
    type: [Number, String],
    default: 'auto',
  },
  label: {
    type: Array,
    default: () => {
      return []
    },
  },
  chartColors: {
    type: Array,
    default: () => {
      return [
        colors.danger(0.9),
        colors.warning(0.9),
        colors.pending(0.9),
        colors.success(0.9),
        colors.primary(0.9),
      ]
    },
  },
  data: {
    type: Array,
    default: () => {
      return []
    },
  },
})

const darkMode = computed(() => useDarkModeStore().darkMode)
const colorScheme = computed(() => useColorSchemeStore().colorScheme)

const data = computed(() => {
  return {
    labels: props.label,
    datasets: [
      {
        data: props.data,
        backgroundColor: colorScheme.value ? props.chartColors : '',
        hoverBackgroundColor: colorScheme.value ? props.chartColors : '',
        borderWidth: 3,
        hoverBorderWidth: 0,
        borderAlign: 'center',
        borderColor: darkMode.value ? colors.darkmode[700]() : colors.white,
      },
    ],
  }
})

const options = computed(() => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    cutoutPercentage: 100,
    plugins: {
      legend: {
        display: false,
      },
    },
  }
})
</script>

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n.js'
import axios from './plugins/axios.js'
import globalComponents from './global-components'
import utils from './utils'
import store from '@/stores/vuex'
import VueLoadImage from 'vue-load-image'
import Shimmer from 'vue3-shimmer'
import VueClipboard from 'vue3-clipboard'
import { saveAs } from 'file-saver'
import vSelect from 'vue-select'

import '@vueform/multiselect/themes/default.css'
import './assets/css/app.css'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import weekday from 'dayjs/plugin/weekday'

import Vue3Lottie from 'vue3-lottie'
import LoadingIndicator from '@/components/loader/LoadingIndicator.vue'
import Toast from '@/components/Toast.vue'

import { configure } from 'vee-validate'

dayjs.extend(relativeTime)
dayjs.extend(weekday)

// const app = createApp(App).use(router).use(createPinia())
const app = createApp(App)

app.use(store)
app.use(createPinia())
app.use(i18n)
app.use(router)
app.use(Shimmer)
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.use(Vue3Lottie)

// function debounce(fn, delay) {
//   let timeoutId

//   return function () {
//     const args = arguments

//     clearTimeout(timeoutId)
//     timeoutId = setTimeout(function () {
//       fn.apply(this, args)
//     }, delay)
//     console.log(timeoutId)
//   }
// }

// app.provide('debounce', debounce) // provide debounce
app.provide('axios', axios) // provide axios
app.provide('saveAs', saveAs) // provide axios
app.provide('dayjs', dayjs) // provide dayJS

// Check Device Width
function setDeviceWidth(width) {
  if (width < 768) {
    return 'w-full'
  } else {
    return 'w-phone'
  }
}
app.provide('deviceWidth', setDeviceWidth(window.innerWidth))

// app.prototype.$axios = axios
// app.config.globalProperties.$http = axios

app.component('VueLoadImage', VueLoadImage)
app.component('LoadingIndicator', LoadingIndicator)
app.component('Toast', Toast)
// eslint-disable-next-line vue/component-definition-name-casing
app.component('v-select', vSelect)

import 'vue-select/dist/vue-select.css'

globalComponents(app)

utils(app)

app.mount('#app')

configure({
  validateOnInput: true,
  // validateOnModelUpdate: false,
  // validateOnBlur: false,
  // validateOnChange: false,
})

export default {
  data: () => ({
    expenseData: [],

    expenseId: null,
    expenseName: '',
    expenseNumber: '',
    expenseDate: null,
    expenseTime: null,
    expenseNote: '',
    expenseTotal: '',

    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
  }),
  methods: {
    async getAllInstituteExpense(
      showMessage = true,
      setLoading = true,
      setMetaData = true
    ) {
      if (setLoading) this.isLoading = true
      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
      }

      await this.$store
        .dispatch('expense/getAllInstituteExpense', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            this.expenseData = responseData.data
          }
          if (responseData.meta && setMetaData) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getAllInstituteExpenseHistories(
      showMessage = true,
      setLoading = true,
      setMetaData = true
    ) {
      if (setLoading) this.isLoading = true
      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
        start_date: this.filterDates.start,
        end_date: this.filterDates.end,
      }

      await this.$store
        .dispatch('expense/getAllInstituteExpenseHistories', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            this.expenseData = responseData.data
          }
          if (responseData.meta && setMetaData) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveInstituteExpenseAPI(params, isUpdate = false) {
      let dispatcher = 'expense/createInstituteExpense'
      if (isUpdate) dispatcher = 'expense/updateInstituteExpense'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async downloadInstituteExpense(fileType) {
      await this.$store
        .dispatch('expense/downloadInstituteExpense', {
          order: this.filterData.order,
          q: this.filterData.search,
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'institute_expense_report.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteInstituteExpenseAPI(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('expense/deleteInstituteExpense', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllInstituteExpenseHistories()
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}

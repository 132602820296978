<template>
  <div class="flex">
    <div
      class="flex flex-col md:flex-row md:items-center md:space-x-2 md:self-center md:align-middle"
    >
      <h2 class="truncate text-base font-medium md:text-lg">
        {{ title }}
      </h2>
      <span class="text-xs text-gray-500 md:flex md:self-center">
        {{ dateString }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TitleComponent',
  props: {
    title: {
      type: String,
      default: '',
    },
    dateString: {
      type: String,
      default: '',
    },
  },
}
</script>

export default {
  data: () => ({
    staffData: [],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },

    staffAccessData: [],
    staffAccessMetaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    staffAccessFilterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    isEditStaff: false,
    staffId: '',
    userImage: '',
  }),
  methods: {
    async getAllStaff(showMessage = true, setLoading = false) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('staff/getAll', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: this.filterData.search,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.staffData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveStaffAPI(params, isUpdate = false) {
      let dispatcher = 'staff/create'
      if (isUpdate) dispatcher = 'staff/update'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)

          this.$router.push({
            name: 'employee',
            params: { ...this.$route.params },
            query: { lang: this.$route.query.lang },
          })
        })
        .catch((e) => {
          this.isLoadingSave = false
          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteStaffAPI(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('staff/delete', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllStaff(false)
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getDetailStaffAPI(id) {
      await this.store
        .dispatch('staff/getDetail', { id })
        .then((response) => {
          this.staffDetailData = response.data.data

          this.formData.name = this.staffDetailData.full_name
          this.formData.email = this.staffDetailData.email
          this.formData.phone = this.staffDetailData.phone_number
          this.formData.employeeNumber = this.staffDetailData.employee_number
          this.formData.tenant = this.staffDetailData.tenant.id
          this.formData.isActive = this.staffDetailData.is_active
          this.formData.position = this.staffDetailData.role.position
          this.formData.roleId = this.staffDetailData.role.role_id
          if ('privilege' in this.staffDetailData)
            this.formData.privilegeId = this.staffDetailData.privilege.id
          this.userImage = this.staffDetailData.avatar

          if ('user_address' in this.staffDetailData) {
            this.formData.province =
              this.staffDetailData.user_address.province_id
            this.formData.city = this.staffDetailData.user_address.city_id
            this.formData.district =
              this.staffDetailData.user_address.district_id
            this.formData.village = this.staffDetailData.user_address.village_id
            this.formData.address =
              this.staffDetailData.user_address.detail_address
            this.formData.postalCode =
              this.staffDetailData.user_address.postal_code
          }
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    async getAllStaffAccess(showMessage = true) {
      await this.$store
        .dispatch('staff/getAllStaffAccess', {
          order: this.staffAccessFilterData.order,
          page: this.staffAccessFilterData.page,
          take: this.staffAccessFilterData.take,
          q: this.staffAccessFilterData.search,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.staffAccessData = responseData.data
          }
          if (responseData.meta) {
            this.staffAccessMetaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}

<template>
  <TabGroup>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box my-5 pt-2">
      <div
        class="mt-6 flex flex-row border-t border-slate-200/60 px-5 pt-5 dark:border-darkmode-400 lg:mt-4 lg:border-t-0 lg:pt-0"
      >
        <div class="flex justify-center self-center align-middle">
          <ProfileMenuIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
        </div>
        <div class="flex">
          <p v-if="isEditStaff" class="text-lg">Form Ubah Karyawan</p>
          <p v-else class="text-lg">Form Tambah Karyawan</p>
        </div>
      </div>
      <div class="validate-form my-6 px-5">
        <div
          class="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 dark:border-darkmode-400 lg:flex-row"
        >
          <div
            class="border-t border-slate-200/60 px-5 pt-5 dark:border-darkmode-400 lg:w-1/4 lg:border-t-0 lg:pt-0"
          >
            <div class="flex items-center truncate sm:whitespace-normal">
              <p class="text-md">Informasi Karyawan</p>
            </div>
          </div>

          <div
            class="mt-4 flex flex-col px-5 md:mt-0 md:w-3/4 md:justify-start"
          >
            <div
              class="image-fit relative h-20 w-20 flex-none cursor-pointer rounded-full border md:h-24 md:w-24 md:rounded-none md:border-none"
              @click="selectFile()"
            >
              <template
                v-if="
                  userImage !== '' && userImage !== null && avatarPreview === ''
                "
              >
                <ImageLoader
                  :image="userImage"
                  :alt="formData.name"
                  rounded-class="rounded-full"
                />
              </template>
              <template v-else-if="avatarPreview !== ''">
                <ImageLoader
                  :image="avatarPreview"
                  :alt="formData.name"
                  rounded-class="rounded-full"
                />
              </template>

              <div
                class="absolute bottom-0 right-0 mb-1 flex cursor-pointer items-center justify-center rounded-full bg-primary p-1 lg:p-2"
              >
                <input
                  id="hidden-input"
                  ref="imageUploader"
                  type="file"
                  accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                  class="hidden"
                />
                <CameraIcon class="h-3 w-3 text-white lg:h-4 lg:w-4" />
              </div>
            </div>

            <div class="intro-x lg:mt-4">
              <div class="flex w-full flex-col lg:flex-row">
                <div class="input-form mt-3 w-full">
                  <label
                    for="form-name"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    Nama Karyawan *
                  </label>
                  <input
                    id="form-name"
                    v-model.trim="validate.name.$model"
                    type="text"
                    name="name"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.name.$error }"
                    :placeholder="$t('formLabel.name')"
                  />
                  <template v-if="validate.name.$error">
                    <div
                      v-for="(error, index) in validate.name.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3 w-full lg:ml-4">
                  <label
                    for="form-email"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.email') }}
                  </label>
                  <input
                    id="form-email"
                    v-model.trim="validate.email.$model"
                    type="email"
                    name="email"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.email.$error }"
                    :placeholder="$t('formLabel.email')"
                  />
                  <template v-if="validate.email.$error">
                    <div
                      v-for="(error, index) in validate.email.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>

              <div class="flex w-full flex-col lg:flex-row">
                <div class="input-form mt-3 w-full">
                  <label
                    for="form-phone"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.phone') }}*
                  </label>
                  <input
                    id="form-phone"
                    v-model="validate.phone.$model"
                    type="text"
                    name="phone"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.phone.$error }"
                    :placeholder="$t('formLabel.phone')"
                  />
                  <template v-if="validate.phone.$error">
                    <div
                      v-for="(error, index) in validate.phone.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="flex w-full flex-col lg:flex-row">
                <div class="input-form mt-3 w-full">
                  <label
                    for="form-employeeNumber"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    ID Karyawan
                  </label>
                  <input
                    id="form-employeeNumber"
                    v-model="validate.employeeNumber.$model"
                    type="text"
                    name="employeeNumber"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.employeeNumber.$error }"
                    placeholder="ID Karyawan"
                  />
                </div>
              </div>
              <div class="flex w-full flex-col space-x-10 lg:flex-row">
                <div
                  v-if="isInstituteAdmin && !isEditStaff"
                  class="input-form mt-3 w-1/2"
                >
                  <label
                    for="form-tenant"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    Tenant*
                  </label>
                  <select
                    v-model="validate.tenant.$model"
                    class="relative mx-auto box-border flex h-11 w-full cursor-pointer items-center justify-end rounded-md border border-gray-200 bg-white text-sm leading-snug outline-none"
                    :class="{ 'border-danger': validate.tenant.$error }"
                  >
                    <option
                      v-for="tenant in tenantData"
                      :key="tenant.id"
                      :value="tenant.id"
                    >
                      {{ tenant.name }}
                    </option>
                  </select>

                  <template v-if="validate.tenant.$error">
                    <div
                      v-for="(error, index) in validate.tenant.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3 w-1/2 w-full">
                  <label
                    for="form-isActive"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    Status Karyawan
                  </label>
                  <div class="form-switch mt-2">
                    <input
                      v-model="validate.isActive.$model"
                      type="checkbox"
                      class="form-check-input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 dark:border-darkmode-400 lg:flex-row"
        >
          <div
            class="mt-4 border-t border-slate-200/60 px-5 dark:border-darkmode-400 lg:w-1/4 lg:border-t-0"
          >
            <div class="flex items-center truncate sm:whitespace-normal">
              <p class="text-md">
                {{ $t('page.bussiness.addressInformation') }}
              </p>
            </div>
          </div>

          <div class="mt-4 flex flex-col px-5 lg:w-3/4 lg:justify-start">
            <div class="input-form mt-3 w-full lg:mt-0">
              <label
                for="form-address"
                class="form-label flex w-full flex-col sm:flex-row"
              >
                {{ $t('formLabel.address') }}*
              </label>
              <input
                id="form-address"
                v-model.trim="validate.address.$model"
                type="text"
                name="address"
                class="intro-x login__input form-control block py-3 px-4"
                :class="{ 'border-danger': validate.address.$error }"
                :placeholder="$t('formLabel.address')"
              />
              <template v-if="validate.address.$error">
                <div
                  v-for="(error, index) in validate.address.$errors"
                  :key="index"
                  class="mt-2 text-danger"
                >
                  {{ $t(error.$message) }}
                </div>
              </template>
            </div>

            <div class="input-form mt-3 w-full">
              <label
                for="form-addressTwo"
                class="form-label flex w-full flex-col sm:flex-row"
              >
                {{ $t('formLabel.address') }}({{ $t('general.optional') }})
              </label>
              <input
                id="form-addressTwo"
                v-model.trim="validate.addressTwo.$model"
                type="text"
                name="addressTwo"
                class="intro-x login__input form-control block py-3 px-4"
                :class="{ 'border-danger': validate.addressTwo.$error }"
                :placeholder="$t('formLabel.address')"
              />
              <template v-if="validate.addressTwo.$error">
                <div
                  v-for="(error, index) in validate.addressTwo.$errors"
                  :key="index"
                  class="mt-2 text-danger"
                >
                  {{ $t(error.$message) }}
                </div>
              </template>
            </div>
            <div class="flex w-full flex-col lg:flex-row">
              <div class="input-form mt-3 w-full">
                <label
                  for="form-address-province"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.province') }}*
                </label>
                <div class="mt-2">
                  <Multiselect
                    v-model="validate.province.$model"
                    label="province_name"
                    track-by="province_name"
                    :placeholder="$t('formLabel.select.province')"
                    :options="provinceList"
                    :searchable="true"
                    :classes="multiSelectClasses"
                    :class="{ 'border-danger': validate.province.$error }"
                    @select="changeAddress('province')"
                  />
                  <template v-if="validate.province.$error">
                    <div
                      v-for="(error, index) in validate.province.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="input-form mt-3 w-full lg:ml-4">
                <label
                  for="form-address-city"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.city') }}*
                </label>
                <div class="mt-2">
                  <Multiselect
                    v-model="validate.city.$model"
                    label="city_name"
                    track-by="city_name"
                    :placeholder="$t('formLabel.select.city')"
                    :options="cityList"
                    :searchable="true"
                    :classes="multiSelectClasses"
                    :class="{ 'border-danger': validate.city.$error }"
                    :disabled="cityDisabled === true"
                    @select="changeAddress('city')"
                  />
                  <template v-if="validate.city.$error">
                    <div
                      v-for="(error, index) in validate.city.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div class="flex w-full flex-col lg:flex-row">
              <div class="input-form mt-3 w-full">
                <label
                  for="form-address-district"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.district') }}*
                </label>
                <div class="mt-2">
                  <Multiselect
                    v-model="validate.district.$model"
                    label="district_name"
                    track-by="district_name"
                    :placeholder="$t('formLabel.select.district')"
                    :options="districtList"
                    :searchable="true"
                    :classes="multiSelectClasses"
                    :class="{ 'border-danger': validate.district.$error }"
                    :disabled="districtDisabled === true"
                    @select="changeAddress('district')"
                  />
                  <template v-if="validate.district.$error">
                    <div
                      v-for="(error, index) in validate.district.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="input-form mt-3 w-full lg:ml-4">
                <label
                  for="form-address-village"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.village') }}*
                </label>
                <div class="mt-2">
                  <Multiselect
                    v-model="validate.village.$model"
                    label="village_name"
                    track-by="village_name"
                    :placeholder="$t('formLabel.select.village')"
                    :options="villageList"
                    :searchable="true"
                    :classes="multiSelectClasses"
                    :class="{ 'border-danger': validate.village.$error }"
                    :disabled="villageDisabled === true"
                    @select="changeAddress('village')"
                  />
                  <template v-if="validate.village.$error">
                    <div
                      v-for="(error, index) in validate.village.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div class="flex w-full flex-col lg:flex-row">
              <div class="input-form mt-3 w-full">
                <label
                  for="form-address-postal-code"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.postalCode') }}
                </label>
                <div class="mt-2">
                  <input
                    id="form-postalCode"
                    v-model.trim="validate.postalCode.$model"
                    type="number"
                    name="postalCode"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.postalCode.$error }"
                    :placeholder="$t('formLabel.postalCode')"
                  />
                  <template v-if="validate.postalCode.$error">
                    <div
                      v-for="(error, index) in validate.postalCode.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 dark:border-darkmode-400 lg:flex-row"
        >
          <div
            class="mt-4 border-t border-slate-200/60 px-5 dark:border-darkmode-400 lg:w-1/4 lg:border-t-0"
          >
            <div class="flex items-center truncate sm:whitespace-normal">
              <p class="text-md">Hak Akses</p>
            </div>
          </div>

          <div class="flex w-full flex-wrap">
            <div class="mt-4 flex flex-col px-5 lg:w-2/4 lg:justify-start">
              <div class="flex w-full flex-col lg:flex-row">
                <div class="input-form mt-3 w-full">
                  <label
                    for="form-address-province"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    Role*
                  </label>
                  <div class="mt-2">
                    <select
                      v-model="validate.roleId.$model"
                      class="relative mx-auto box-border flex h-11 w-full cursor-pointer items-center justify-end rounded-md border border-gray-200 bg-white text-sm leading-snug outline-none"
                      :class="{ 'border-danger': validate.roleId.$error }"
                    >
                      <option
                        v-for="role in roleData"
                        :key="role.id"
                        :value="role.id"
                      >
                        {{ role.name }}
                      </option>
                    </select>
                    <template v-if="validate.roleId.$error">
                      <div
                        v-for="(error, index) in validate.roleId.$errors"
                        :key="index"
                        class="mt-2 text-danger"
                      >
                        {{ $t(error.$message) }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-4 flex flex-col px-5 lg:w-2/4 lg:justify-start">
              <div class="flex w-full flex-col lg:flex-row">
                <div class="input-form mt-3 w-full">
                  <label
                    for="form-addressTwo"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    Employee Pin*
                  </label>
                  <button
                    v-if="isEditStaff && !inputPin"
                    class="btn btn-primary"
                    @click="changeInputPin"
                  >
                    Edit pin
                  </button>
                  <template v-else>
                    <input
                      id="form-addressTwo"
                      v-model="validate.employeePin.$model"
                      type="text"
                      name="addressTwo"
                      class="intro-x login__input form-control block py-3 px-4"
                      :class="{ 'border-danger': validate.employeePin.$error }"
                      placeholder="Employee Pin"
                    />
                    <template v-if="validate.employeePin.$error">
                      <div
                        v-for="(error, index) in validate.employeePin.$errors"
                        :key="index"
                        class="mt-2 text-danger"
                      >
                        {{ $t(error.$message) }}
                      </div>
                    </template>
                    <div v-if="isEditStaff" class="pl-4 pt-2">
                      <ul class="list-disc text-red-400">
                        <li>{{ $t('formError.pin.numeric') }}</li>
                        <li>{{ $t('formError.pin.minLength') }}</li>
                        <li>{{ $t('formError.pin.maxLength') }}</li>
                      </ul>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="mt-4 flex flex-col px-5 lg:w-2/4 lg:justify-start">
              <div class="flex w-full flex-col lg:flex-row">
                <div class="input-form mt-3 w-full">
                  <label
                    for="form-position"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    Privilege
                  </label>
                  <div class="mt-2">
                    <select
                      v-model="validate.privilegeId.$model"
                      class="relative mx-auto box-border flex h-11 w-full cursor-pointer items-center justify-end rounded-md border border-gray-200 bg-white text-sm leading-snug outline-none"
                      :class="{ 'border-danger': validate.privilegeId.$error }"
                    >
                      <option
                        v-for="privilege in privilegeData"
                        :key="privilege.id"
                        :value="privilege.id"
                      >
                        {{ privilege.name }}
                      </option>
                    </select>
                    <template v-if="validate.privilegeId.$error">
                      <div
                        v-for="(error, index) in validate.privilegeId.$errors"
                        :key="index"
                        class="mt-2 text-danger"
                      >
                        {{ $t(error.$message) }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-end py-5">
          <button
            type="button"
            class="btn btn-outline-primary mr-2 w-24"
            @click="$router.push({ name: 'employee' })"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            type="submit"
            class="btn btn-primary py-3 px-4"
            @click="saveStaff"
          >
            <LoadingIcon
              v-if="isLoading"
              icon="spinning-circles"
              color="white"
              class="mr-2 h-4 w-4"
            />
            {{ $t('button.saveChanges') }}
          </button>
        </div>
      </div>

      <div
        id="success-notification-content"
        class="toastify-content flex hidden"
      >
        <CheckCircleIcon class="text-success" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('formInfo.saveChangesSuccess') }}</div>
          <div class="mt-1 text-slate-500">
            {{ responseMessage }}
          </div>
        </div>
      </div>
      <!-- END: Success Notification Content -->
      <!-- BEGIN: Failed Notification Content -->
      <div
        id="failed-notification-content"
        class="toastify-content flex hidden"
      >
        <XCircleIcon class="text-danger" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('formInfo.saveChangesFailed') }}</div>
          <div class="mt-1 text-slate-500">{{ responseMessage }}</div>
        </div>
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </TabGroup>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  email,
  maxLength,
  minLength,
  numeric,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { resizeImg } from '@/plugins/pica'
import Toastify from 'toastify-js'
import Multiselect from '@vueform/multiselect'

import globalMixin from '@/mixins/global.js'
import roleMixin from '@/mixins/role.js'
import staffMixin from '@/mixins/staff.js'
import tenantMixin from '@/mixins/tenant.js'
import privilegeMixin from '@/mixins/privilege.js'

import ProfileMenuIcon from '@/assets/svg/menu/user.svg'

import ImageLoader from '@/components/image-loader/Main.vue'

export default {
  components: {
    Multiselect,
    ProfileMenuIcon,
    ImageLoader,
  },
  mixins: [globalMixin, roleMixin, staffMixin, tenantMixin, privilegeMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const activeUser = store.getters['auth/activeUser']
    const route = useRoute()
    const router = useRouter()

    const avatarPreview = ref('')
    const imageFile = ref(null)

    const isLoading = ref(false)
    const responseMessage = ref('')

    const provincePage = ref(1)
    const cityPage = ref(1)
    const districtPage = ref(1)
    const villagePage = ref(1)

    const cityDisabled = ref(true)
    const districtDisabled = ref(true)
    const villageDisabled = ref(true)

    const provinceList = ref([])
    const cityList = ref([])
    const districtList = ref([])
    const villageList = ref([])

    const inputPin = ref(false)

    const formData = reactive({
      name: '',
      email: '',
      description: '',
      phone: '',
      address: '',
      addressTwo: '',
      postalCode: '',
      employeeNumber: '',
      isActive: true,
      employeePin: '',
      roleId: '',
      privilegeId: '',
      province: '',
      city: '',
      district: '',
      village: '',
      tenant: '',
      image: null,
    })

    const nameRequired = helpers.withMessage(
      'formError.name.required',
      required
    )

    const emailValid = helpers.withMessage('formError.email.validEmail', email)

    const phoneRequired = helpers.withMessage(
      'formError.phone.required',
      required
    )
    const phoneNumeric = helpers.withMessage('formError.phone.numeric', numeric)

    const addressRequired = helpers.withMessage(
      'formError.address.required',
      required
    )
    const roleRequired = helpers.withMessage(
      'formError.role.required',
      required
    )
    const provinceRequired = helpers.withMessage(
      'formError.province.required',
      required
    )
    const cityRequired = helpers.withMessage(
      'formError.city.required',
      required
    )
    const districtRequired = helpers.withMessage(
      'formError.district.required',
      required
    )
    const villageRequired = helpers.withMessage(
      'formError.village.required',
      required
    )

    const postalCodeMaxLength = helpers.withMessage(
      'formError.postalCode.maxLength',
      maxLength(5)
    )

    const pinRequired = helpers.withMessage('formError.pin.required', required)
    const pinMinLength = helpers.withMessage(
      'formError.pin.minLength',
      minLength(6)
    )
    const pinMaxLength = helpers.withMessage(
      'formError.pin.maxLength',
      maxLength(6)
    )

    const pinNumeric = helpers.withMessage('formError.pin.numeric', numeric)

    const rules = {
      name: {
        nameRequired,
      },
      email: {
        emailValid,
      },
      phone: {
        phoneRequired,
        phoneNumeric,
      },
      description: {},
      address: {
        addressRequired,
      },
      addressTwo: {},
      postalCode: {
        postalCodeMaxLength,
      },
      employeeNumber: {},
      isActive: {},
      roleId: {
        roleRequired,
      },
      privilegeId: {},
      province: {
        provinceRequired,
      },
      city: {
        cityRequired,
      },
      district: {
        districtRequired,
      },
      village: {
        villageRequired,
      },
      employeePin: {},
      tenant: {},
      image: {},
    }

    if (activeUser.role.role === 'Institute Admin') {
      const tenantRequired = helpers.withMessage(
        'formError.tenant.required',
        required
      )
      rules.tenant = { tenantRequired }
    }

    if (route.query.employeeId !== undefined && route.query.employeeId !== '') {
      cityDisabled.value = false
      districtDisabled.value = false
      villageDisabled.value = false

      if (inputPin.value) {
        rules.employeePin = {
          pinRequired,
          pinMaxLength,
          pinMinLength,
          pinNumeric,
        }
      }
    } else {
      rules.employeePin = {
        pinRequired,
        pinMaxLength,
        pinMinLength,
        pinNumeric,
      }
    }

    let validate = useVuelidate(rules, toRefs(formData))

    const changeInputPin = () => {
      inputPin.value = true
      rules.employeePin = {
        pinRequired,
        pinMaxLength,
        pinMinLength,
        pinNumeric,
      }
      validate = useVuelidate(rules, toRefs(formData))
    }

    onMounted(async () => {
      await getAddressProvince()
    })

    const getAddressProvince = async () => {
      const queryParameters = {
        order: 'DESC',
        page: provincePage.value,
        take: 20,
        q: '',
      }
      await store
        .dispatch('address/province', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const provinceTemporary = []
            response.data.data.forEach((item) => {
              provinceTemporary.push({
                value: item.id,
                ...item,
              })
            })
            provinceList.value = [...provinceList.value, ...provinceTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('province')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressCity = async () => {
      const queryParameters = {
        order: 'DESC',
        page: cityPage.value,
        take: 50,
        q: '',
        province_id: formData.province,
      }
      await store
        .dispatch('address/city', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const cityTemporary = []
            response.data.data.forEach((item) => {
              cityTemporary.push({
                value: item.id,
                ...item,
              })
            })
            if (cityPage.value === 1) {
              cityList.value = []
              cityList.value = [...cityTemporary]
            } else {
              cityList.value = [...cityList.value, ...cityTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('city')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressDistrict = async () => {
      const queryParameters = {
        order: 'DESC',
        page: 1,
        take: 50,
        q: '',
        city_id: formData.city,
      }
      await store
        .dispatch('address/district', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const districtTemporary = []
            response.data.data.forEach((item) => {
              districtTemporary.push({
                value: item.id,
                ...item,
              })
            })
            if (districtPage.value === 1) {
              districtList.value = []
              districtList.value = [...districtTemporary]
            } else {
              districtList.value = [...districtList.value, ...districtTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('district')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressVillage = async () => {
      const queryParameters = {
        order: 'DESC',
        page: 1,
        take: 50,
        q: '',
        district_id: formData.district,
      }
      await store
        .dispatch('address/village', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const villageTemporary = []
            response.data.data.forEach((item) => {
              villageTemporary.push({
                value: item.id,
                ...item,
              })
            })
            if (villagePage.value === 1) {
              villageList.value = []
              villageList.value = [...villageTemporary]
            } else {
              villageList.value = [...villageList.value, ...villageTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('village')
            }
          }
        })
        .catch((error) => {})
    }

    const loadMores = async (type) => {
      if (type === 'province') {
        provincePage.value += 1
        getAddressProvince()
      } else if (type === 'city') {
        cityPage.value += 1
        getAddressCity()
      } else if (type === 'district') {
        districtPage.value += 1
        getAddressDistrict()
      } else if (type === 'village') {
        villagePage.value += 1
        getAddressVillage()
      }
    }

    const changeAddress = async (type) => {
      setTimeout(() => {
        if (type === 'province') {
          cityPage.value = 1
          formData.city = ''
          cityList.value = []
          cityDisabled.value = false

          formData.district = ''
          districtList.value = []
          districtDisabled.value = true

          formData.village = ''
          villageList.value = []
          villageDisabled.value = true

          if (formData.province !== '') {
            getAddressCity()
          }
        } else if (type === 'city') {
          districtPage.value = 1
          formData.district = ''
          districtList.value = []
          districtDisabled.value = false

          formData.village = ''
          villageList.value = []
          villageDisabled.value = true

          if (formData.city !== '') {
            getAddressDistrict()
          }
        } else if (type === 'district') {
          villagePage.value = 1
          formData.village = ''
          villageList.value = []
          villageDisabled.value = false

          if (formData.district !== '') {
            getAddressVillage()
          }
        }
      }, 200)
    }

    const showToast = (success) => {
      let toastId = '#failed-notification-content'
      if (success) {
        toastId = '#success-notification-content'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const previewImage = (file) => {
      // no need resize
      if (file.size < 1024) {
        avatarPreview.value = window.URL.createObjectURL(file)
        imageFile.value = file
      }
      const targetWidth = 1000
      resizeImg(file, targetWidth, (resized) => {
        avatarPreview.value = window.URL.createObjectURL(resized)
        imageFile.value = resized
      })
    }

    const selectFile = () => {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
      hidden.onchange = (e) => {
        previewImage(e.target.files[0])
        formData.image = e.target.files[0]
      }
    }

    return {
      t,
      n,
      store,
      route,
      router,
      isLoading,
      formData,
      validate,
      responseMessage,
      // updateBussiness,
      avatarPreview,
      selectFile,
      imageFile,
      cityDisabled,
      districtDisabled,
      villageDisabled,
      changeAddress,
      provinceList,
      cityList,
      districtList,
      villageList,
      inputPin,
      changeInputPin,

      getAddressProvince,
      getAddressCity,
      getAddressDistrict,
      getAddressVillage,
    }
  },
  mounted() {
    if (
      this.$route.query.employeeId !== undefined &&
      this.$route.query.employeeId !== ''
    ) {
      this.getDetailStaffAPI(this.$route.query.employeeId).then(() => {
        this.getAddressCity()
        this.getAddressDistrict()
        this.getAddressVillage()
      })
      this.isEditStaff = true
      this.staffId = this.$route.query.employeeId
    }

    if (this.isInstituteAdmin) {
      this.getAllTenant(true).then(() => {
        this.formData.tenant = this.tenantId
      })
    } else {
      if (this.activeUserStore !== null) {
        const role = this.activeUserStore.role.role
        if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
          this.tenantId = this.activeUserStore.tenant.id
        }
      }
      this.formData.tenant = this.tenantId
    }

    this.getAllRole(true, true)
    this.getAllPrivilege()
  },
  methods: {
    async saveStaff() {
      const isFormCorrect = await this.validate.$validate()
      if (isFormCorrect) {
        const params = {
          full_name: this.formData.name,
          email: this.formData.email === null ? '' : this.formData.email,
          phone: this.formData.phone,
          employee_number: this.formData.employeeNumber,
          position: this.formData.position,
          role_id: this.formData.roleId,
          privilege_id: this.formData.privilegeId,
          is_active: this.formData.isActive,
          avatar_file: this.imageFile === null ? '' : this.imageFile,
          // avatar_file: this.formData.image,
          province_id: this.formData.province,
          city_id: this.formData.city,
          district_id: this.formData.district,
          village_id: this.formData.village,
          postal_code: this.formData.postalCode,
          detail_address: `${this.formData.address} ${this.formData.addressTwo}`,
        }

        if (this.isEditStaff) {
          if (this.inputPin) {
            params.employee_pin = this.formData.employeePin
          } else {
            params.employee_pin = ''
          }
        } else {
          params.employee_pin = this.formData.employeePin
        }

        if (this.isInstituteAdmin) {
          params.tenant_id = this.formData.tenant
        } else {
          params.tenant_id = this.tenantId
        }

        if (this.isEditStaff) {
          params.id = this.staffId

          this.saveStaffAPI(params, true)
        } else {
          this.saveStaffAPI(params, false)
        }
      }
    },
  },
}
</script>

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.report.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
      <div class="flex w-full md:w-fit">
        <div class="relative w-full text-slate-500 sm:ml-auto sm:mt-0 md:w-fit">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateFilter"
            :options="{
              autoApply: false,
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              format: 'YYYY-MM-DD',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 md:w-56"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isInstitute && !isInstituteSuperAdmin"
      class="flex w-full justify-end"
    >
      <div class="mt-8 flex w-full md:w-2/6">
        <select
          v-model="tenantId"
          class="w-full border-none p-2 pr-10 outline-none"
          @change="getDataBalance(true, true)"
        >
          <option
            v-for="tenant in tenantOptions"
            :key="tenant.value"
            :value="tenant.value"
          >
            {{ tenant.label }}
          </option>
        </select>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Order Detail Report -->
          <div class="col-span-12 mt-8">
            <div
              class="intro-y flex w-full flex-col justify-between space-y-2 md:flex-row md:space-y-0"
            >
              <div class="flex align-middle">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <div class="flex">
                  <div
                    class="flex flex-col items-center self-center align-middle md:flex-row md:space-x-2"
                  >
                    <h2 class="truncate text-lg font-medium">
                      {{ $t($route.meta.title) }}
                    </h2>
                    <span class="flex self-center text-xs text-gray-500">
                      {{ dateFilterFormat(dateFilter) }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex align-middle">
                <a href="" class="flex items-center space-x-2 text-primary">
                  <RefreshCcwIcon class="h-4 w-4" />
                  <span>{{ $t('action.reloadData') }}</span>
                </a>
              </div>
            </div>
            <div class="mt-8 grid grid-cols-12 gap-6 md:mt-5">
              <Card
                :title="$t('page.balance.totalBalance')"
                :total="balanceData.total_balance"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
              />
              <Card
                :title="$t('page.balance.cashOnHand')"
                :total="balanceData.cash_on_hand"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
              />
              <Card
                :title="$t('page.balance.totalIncome')"
                :total="balanceData.total_income"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
              />
              <Card
                :title="$t('page.balance.omniPayment')"
                :total="balanceData.omni_payment"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
              />
            </div>
          </div>
          <!-- END: Order Detail Report -->
          <!-- BEGIN: Order Detail Table -->
          <TableComponent
            :date-string="dateFilter"
            :title="$t('page.report.orderDetail.orderDetailTable')"
            :header="balaceTableHeaders"
            :is-show-search="false"
            :is-not-empty="balanceDataHistories.length > 0"
            :meta-data="metaData"
            :is-show-pagination="false"
            @change-filter="changeFilter($event)"
          >
            <template #additionalFilter>
              <select v-if="filterData.type" @change="changeBalanceType">
                <option
                  v-for="data in balanceTypes"
                  :key="data.value"
                  :value="data.value"
                >
                  {{ data.label }}
                </option>
              </select>
            </template>

            <tr v-for="data in balanceDataHistories" :key="data.id">
              <td>
                {{ formattedDate(data.created_at, 'DD MMMM YYYY HH:mm:ss') }}
              </td>
              <td>
                <Currency
                  :prefix-text="data.balance > 0 ? '' : '-'"
                  :total-in-string="Math.abs(data.balance)"
                />
              </td>
              <td class="text-center">
                <div class="flex w-full justify-center">
                  <p
                    v-if="data.type === 'credit'"
                    class="rounded-full bg-green-700 py-1 px-4 text-xs text-white"
                  >
                    {{ $t('page.balance.incomingBalance') }}
                  </p>
                  <p
                    v-else
                    class="rounded-full bg-red-600 py-1 px-3 text-xs text-white"
                  >
                    {{ $t('page.balance.outcomingBalance') }}
                  </p>
                </div>
              </td>
            </tr>
          </TableComponent>
          <!-- END: Order Detail Table -->

          <!-- BEGIN: Toast -->
          <Toast
            id="success"
            :title="$t('formInfo.success')"
            :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
          />
          <Toast
            id="failed"
            :title="$t('formInfo.failed')"
            :message="message !== '' ? message : $t('formInfo.fetchFailed')"
            :is-failed="true"
          />
          <!-- END: Toast -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import balanceMixin from '@/mixins/balance.js'
import tenantMixin from '@/mixins/tenant.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import SaleIcon from '@/assets/svg/sale.svg'

import TableComponent from '@/components/TableComponent.vue'
import Card from '@/components/Card.vue'
import Currency from '@/components/Currency.vue'

export default {
  name: 'SalesReportPage',
  components: {
    Breadcrumb,
    TableComponent,
    Card,

    Currency,
    SaleIcon,
  },
  mixins: [globalMixin, balanceMixin, tenantMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()

    const dateFilter = ref('')

    return {
      t,
      n,
      store,
      dateFilter,
    }
  },
  data: () => ({
    message: '',
    showDetailModal: false,
    activeData: {},
    balaceTableHeaders: [
      {
        item: 'page.balance.tableHeaders.transactionDate',
        customClass: '',
      },
      //   {
      //     item: 'page.balance.tableHeaders.transactionChannel',
      //     customClass: '',
      //   },
      {
        item: 'page.balance.tableHeaders.transactionTotal',
        customClass: '',
      },
      {
        item: 'page.balance.tableHeaders.transactionType',
        customClass: 'text-center',
      },
    ],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      if (this.tenantId !== '') {
        this.getDataBalance(true, true)
      }
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getDataBalance(true, true)
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getDataBalance(true, true)
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    changeFilter(event) {
      this.filterData = event.filterData
      this.getDataBalance(true, true)
    },
    changeBalanceType(event) {
      this.filterData.type = event.target.value
      this.getDataBalance(true, true)
    },
  },
}
</script>

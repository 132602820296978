<template>
  <div class="py-2">
    <DarkModeSwitcher />
    <MainColorSwitcher />
    <MobileMenu />
    <div class="relative mt-[4.7rem] flex md:mt-0">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav">
        <router-link
          :to="{ name: 'dashboard' }"
          class="intro-x flex items-center pl-5 pt-4"
        >
          <InkanteenIcon class="w-8 text-white" />
          <span class="ml-3 hidden text-lg text-white xl:block">
            {{ appName }}
          </span>
        </router-link>
        <div class="side-nav__devider my-6"></div>

        <div v-if="activeUserStore !== null" class="px-2 pb-4 text-white">
          <p class="text-xs">
            {{ $t(`info.greeting.${generateGreeting()}`) }},
          </p>
          <div
            v-if="isInstituteSuperAdmin && activeTenantStore !== null"
            class="flex gap-2"
          >
            <p class="max-w-full truncate text-lg">
              {{ activeTenantStore.name }}
            </p>
            <SwapIcon
              class="cursor-pointer hover:text-gray-100"
              @click="toDashboardSuperInstitutePage()"
            />
          </div>
          <p
            v-else-if="'full_name' in activeUserStore"
            class="max-w-full truncate text-lg"
          >
            {{ activeUserStore.full_name }}
          </p>
        </div>
        <ul>
          <!-- BEGIN: First Child -->
          <template
            v-for="(menu, menuKey) in formattedMenuComputed"
            :key="menuKey"
          >
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
            ></li>
            <li
              v-else-if="
                activeMenu === menu.menu || menu.pageName === 'dashboard'
              "
              :key="menuKey"
            >
              <a
                :href="
                  menu.submenu && menu.showSubMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown,
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <template v-if="menu.svg === true">
                    <HomeMenuIcon v-if="menu.icon === 'HomeIcon'" class="w-6" />
                    <ReportMenuIcon
                      v-else-if="menu.icon === 'ReportIcon'"
                      class="w-6"
                    />
                    <BookIcon
                      v-else-if="menu.icon === 'BookIcon'"
                      class="w-6"
                    />
                    <IncomeIcon
                      v-else-if="menu.icon === 'IncomeIcon'"
                      class="w-6"
                    />
                    <ExpenseIcon
                      v-else-if="menu.icon === 'ExpenseIcon'"
                      class="w-6"
                    />
                    <ReportCashIcon
                      v-else-if="menu.icon === 'ReportCashIcon'"
                      class="w-6"
                    />
                    <AnalyticMenuIcon
                      v-if="menu.icon === 'AnalyticIcon'"
                      class="w-6"
                    />
                    <ProfileMenuIcon
                      v-if="menu.icon === 'ProfileIcon'"
                      class="w-5"
                    />
                    <StoreIcon v-if="menu.icon === 'StoreIcon'" class="w-5" />
                    <EmployeeIcon
                      v-if="menu.icon === 'EmployeeIcon'"
                      class="w-5"
                    />
                    <TableManagementIcon
                      v-if="menu.icon === 'TableManagementIcon'"
                      class="w-5"
                    />
                    <OmniChannelIcon
                      v-if="menu.icon === 'OmniChannelIcon'"
                      class="w-5"
                    />
                    <PaymentChannelIcon
                      v-if="menu.icon === 'PaymentChannelIcon'"
                      class="w-5"
                    />
                    <BagIcon v-if="menu.icon === 'BagIcon'" />
                    <MoneyIcon v-if="menu.icon === 'MoneyIcon'" />
                  </template>
                  <template v-else>
                    <component :is="menu.icon" />
                  </template>
                </div>
                <div class="side-menu__title">
                  {{ $t(menu.title) }}
                  <div
                    v-if="menu.submenu && menu.showSubMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'rotate-180 transform': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </a>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul
                  v-if="menu.submenu && menu.showSubMenu && menu.activeDropdown"
                >
                  <li
                    v-for="(submenu, subMenuKey) in menu.submenu"
                    :key="subMenuKey"
                  >
                    <a
                      :href="
                        submenu.submenu
                          ? 'javascript:;'
                          : router.resolve({ name: submenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': submenu.active }"
                      @click="linkTo(submenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <!-- <ActivityIcon /> -->
                        <CircleIcon class="w-2" />
                      </div>
                      <div class="side-menu__title">
                        {{ $t(submenu.title) }}
                        <div
                          v-if="submenu.submenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'rotate-180 transform': submenu.activeDropdown,
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </a>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="submenu.submenu && submenu.activeDropdown">
                        <li
                          v-for="(
                            lastSubMenu, lastSubMenuKey
                          ) in submenu.submenu"
                          :key="lastSubMenuKey"
                        >
                          <a
                            :href="
                              lastSubMenu.submenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <!-- <ZapIcon /> -->
                              <CircleIcon class="w-2" />
                            </div>
                            <div class="side-menu__title">
                              {{ $t(lastSubMenu.title) }}
                            </div>
                          </a>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { computed, onMounted, provide, ref, watch } from 'vue'

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import { useSideMenuStore } from '@/stores/side-menu'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import MainColorSwitcher from '@/components/main-color-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
// import LoadingIndicator from '@/components/loader/LoadingIndicator.vue'
import { linkTo, nestedMenu, findActiveMenu, enter, leave } from './index'
import dom from '@left4code/tw-starter/dist/js/dom'
import globalMixin from '@/mixins/global.js'

import InkanteenIcon from '@/assets/svg/inkanteen-icon-dynamic.svg'

import HomeMenuIcon from '@/assets/svg/menu/home.svg'
import ReportMenuIcon from '@/assets/svg/menu/report.svg'
import AnalyticMenuIcon from '@/assets/svg/menu/analytic.svg'
import ProfileMenuIcon from '@/assets/svg/menu/user.svg'
import BookIcon from '@/assets/svg/menu/book.svg'
import IncomeIcon from '@/assets/svg/menu/income.svg'
import ExpenseIcon from '@/assets/svg/menu/expense.svg'
import ReportCashIcon from '@/assets/svg/menu/report-cash.svg'
import StoreIcon from '@/assets/svg/menu/store.svg'
import EmployeeIcon from '@/assets/svg/menu/karyawan.svg'
import TableManagementIcon from '@/assets/svg/menu/meja.svg'
import OmniChannelIcon from '@/assets/svg/menu/omnichannel.svg'
import PaymentChannelIcon from '@/assets/svg/menu/payment.svg'
import BagIcon from '@/assets/svg/menu/bag.svg'
import MoneyIcon from '@/assets/svg/menu/money.svg'

import SwapIcon from '@/assets/svg/swap.svg'

export default {
  components: {
    TopBar,
    MobileMenu,
    DarkModeSwitcher,
    MainColorSwitcher,
    SideMenuTooltip,
    nestedMenu,

    // LoadingIndicator,

    InkanteenIcon,
    HomeMenuIcon,
    ReportMenuIcon,
    AnalyticMenuIcon,
    ProfileMenuIcon,
    BookIcon,
    IncomeIcon,
    ExpenseIcon,
    ReportCashIcon,
    StoreIcon,
    EmployeeIcon,
    TableManagementIcon,
    OmniChannelIcon,
    PaymentChannelIcon,
    BagIcon,
    MoneyIcon,
    SwapIcon,
  },
  mixins: [globalMixin],
  setup() {
    const { t } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const sideMenuStore = useSideMenuStore()
    const formattedMenu = ref([])
    const menuFiltered = ref([])
    const userMenus = ref([])
    const userFeatures = ref([])

    const activeMenu = computed({
      get() {
        return store.getters['menu/activeMenu']
      },
      set(val) {
        return store.dispatch('menu/activeMenu', val)
      },
    })

    provide('forceActiveMenu', (pageName) => {
      route.forceActiveMenu = pageName
      formattedMenu.value = $h.toRaw(nestedMenu(menuFiltered.value, route))
    })

    watch(
      computed(() => route.path),
      () => {
        delete route.forceActiveMenu
        validatePage()
        formattedMenu.value = $h.toRaw(nestedMenu(menuFiltered.value, route))
      }
    )

    onMounted(async () => {
      dom('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')

      await getUserAccess()
    })

    const validatePage = () => {
      if (!route.meta.general) {
        if (userFeatures.value.length > 0) {
          let isRouteNameExist = false

          const routeExist = userFeatures.value.filter(
            (feature) => feature.link === route.name
          )

          if (routeExist.length > 0) {
            isRouteNameExist = true
          }

          if (!isRouteNameExist) {
            // router.push({
            //   path: '/',
            //   params: { slug: route.params.slug },
            //   query: { lang: route.query.lang },
            // })
            // window.location = `/${props.activeMenu}`
          }
        }
      }
    }

    const getUserAccess = async () => {
      if (store.getters['auth/activeUser'] !== null) {
        await store
          .dispatch('menu/access')
          .then((response) => {
            userMenus.value = response.data.menus
            userFeatures.value = response.data.features

            // validating page
            validatePage()

            const parentMenuFiltered = []
            // console.log(sideMenuStore.menu)
            sideMenuStore.menu.forEach((obj) => {
              // if (obj.activeDropdown) console.log(obj.pageName)
              if (obj.general) {
                // if (!obj.checkMenu || obj.menu === props.activeMenu) {
                parentMenuFiltered.push(obj)
                // }
                // } else if (obj.menu === props.activeMenu) {
              } else if (obj === 'divider') {
                //if
                parentMenuFiltered.push(obj)
              } else {
                // const featureCheck = userFeatures.value.filter(
                //   (feature) => feature.link === obj.pageName
                // )
                // if (featureCheck.length > 0) {
                parentMenuFiltered.push(obj)
                // }
              }
              // }
            })

            parentMenuFiltered.forEach((obj, key) => {
              // activeMenu.value === obj.menu
              if (obj === 'divider') {
                menuFiltered.value.push(obj)
              } else if (obj.general === true) {
                menuFiltered.value.push(obj)
              } else {
                // redeclaring object, so the data will not related anymore with the real one
                const newMenu = {
                  ...obj,
                }
                if (obj.submenu !== undefined && obj.submenu.length > 0) {
                  newMenu.submenu = []
                  obj.submenu.forEach((subObj) => {
                    // const featureCheck = userFeatures.value.filter(
                    //   (feature) => feature.link === subObj.pageName
                    // )

                    // if (featureCheck.length > 0) {
                    newMenu.submenu.push(subObj)
                    // }
                  })
                }
                menuFiltered.value.push(newMenu)
              }
            })
            formattedMenu.value = $h.toRaw(
              nestedMenu(menuFiltered.value, route)
            )
          })
          .catch((error) => {
            return error
          })
      }
    }

    return {
      t,
      route,
      router,
      sideMenuStore,
      formattedMenu,
      activeMenu,
      userMenus,
      userFeatures,
      linkTo,
      enter,
      leave,
      getUserAccess,
      validatePage,
    }
  },
  computed: {
    formattedMenuComputed() {
      if (this.isInstituteRetail) {
        // Institute Retail
        return this.formattedMenu.filter(
          (item) =>
            (this.menuRetail.includes(item.pageName) ||
              item.menu !== 'setting') &&
            !this.forbiddenMenu.includes(item.pageName)
        )
      } else if (this.isInstituteSuperAdminMode) {
        // Institute Super Admin Mode
        const filteredMenu = []

        this.formattedMenu.forEach((item) => {
          if (this.menuDashboardSuperInstituteOnly.includes(item.pageName)) {
            filteredMenu.push(item)
          } else {
            if ('submenu' in item) {
              const subMenuFiltered = []
              item.submenu.forEach((subMenuItem) => {
                if (
                  this.menuDashboardSuperInstituteOnly.includes(
                    subMenuItem.pageName
                  )
                ) {
                  subMenuFiltered.push(subMenuItem)
                }
              })
              if (subMenuFiltered.length > 0) {
                item.submenu = subMenuFiltered
                filteredMenu.push(item)
              }
            }
          }
        })
        return filteredMenu
      } else if (!this.isInstituteSuperAdmin) {
        // Else, not institute super admin and not retail, default
        const filteredMenu = []

        this.formattedMenu.forEach((item) => {
          if (
            !this.menuSuperInstituteAdminOnly.includes(item.pageName) &&
            !this.menuRetailOnly.includes(item.pageName) &&
            !this.forbiddenMenu.includes(item.pageName)
          ) {
            filteredMenu.push(item)
          } else {
            if ('submenu' in item) {
              const subMenuFiltered = []
              item.submenu.forEach((subMenuItem) => {
                if (this.retailAllowGlobal.includes(subMenuItem.pageName)) {
                  subMenuFiltered.push(subMenuItem)
                }
              })
              if (subMenuFiltered.length > 0) {
                item.submenu = subMenuFiltered
                filteredMenu.push(item)
              }
            }
          }
        })
        return filteredMenu
      } else {
        // Institute Super Admin, Look Tenant Mode
        const filteredMenu = []

        this.formattedMenu.forEach((item) => {
          if (
            !this.menuRetailOnly.includes(item.pageName) &&
            !this.forbiddenMenu.includes(item.pageName)
          ) {
            filteredMenu.push(item)
          } else {
            if ('submenu' in item) {
              const subMenuFiltered = []
              item.submenu.forEach((subMenuItem) => {
                if (this.retailAllowGlobal.includes(subMenuItem.pageName)) {
                  subMenuFiltered.push(subMenuItem)
                }
              })
              if (subMenuFiltered.length > 0) {
                item.submenu = subMenuFiltered
                filteredMenu.push(item)
              }
            }
          }
        })
        return filteredMenu
      }
    },
  },
}
</script>

<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.report.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex w-full justify-end md:w-fit">
      <div class="relative w-full text-slate-500 sm:ml-auto sm:mt-0">
        <CalendarIcon
          class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
        />
        <Litepicker
          v-model="dateFilter"
          :options="{
            autoApply: false,
            singleMode: false,
            numberOfColumns: 2,
            numberOfMonths: 2,
            showWeekNumbers: true,
            format: 'YYYY-MM-DD',
            dropdowns: {
              minYear: 1990,
              maxYear: null,
              months: true,
              years: true,
            },
          }"
          class="form-control box pl-8 sm:w-56"
        />
      </div>
      <ExportCSVComponent @export="getNonSalesDetail('download', $event)" />
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Non Sales Detail Report -->
        <div class="col-span-12 mt-8">
          <div
            class="intro-y flex w-full flex-col justify-between space-y-4 md:flex-row"
          >
            <div class="flex align-middle">
              <div class="flex justify-center self-center align-middle">
                <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
              </div>
              <div class="flex">
                <div
                  class="flex flex-col space-y-1 md:flex-row md:space-x-2 md:space-y-0 md:self-center md:align-middle"
                >
                  <h2 class="truncate text-lg font-medium">
                    {{ $t($route.meta.title) }}
                  </h2>
                  <span class="flex self-center text-xs text-gray-500">
                    {{ dateFilterFormat(dateFilter) }}
                  </span>
                </div>
              </div>
            </div>
            <div class="flex align-middle">
              <a href="" class="flex items-center space-x-2 text-primary">
                <RefreshCcwIcon class="h-4 w-4" />
                <span>{{ $t('action.reloadData') }}</span>
              </a>
            </div>
          </div>
          <div class="mt-5 grid grid-cols-12 gap-6">
            <Card
              :title="$t('page.report.nonSalesDetail.totalNominal')"
              :total="totalAmount"
              wrapper-class="col-span-12 sm:col-span-6 xl:col-span-6"
            />
            <Card
              :title="$t('page.report.nonSalesDetail.totalTransaction')"
              :is-currency="false"
              :total="totalTransaction"
              wrapper-class="col-span-12 sm:col-span-6 xl:col-span-6"
            />
          </div>
        </div>
        <!-- END: Non Sales Detail Report -->
        <!-- BEGIN: Non Sales Detail Table -->
        <TableComponent
          :date-string="dateFilter"
          :title="$t('page.report.nonSalesDetail.nonSalesDetailTable')"
          :header="nonSalesDetailReportHeader"
          :is-show-search="true"
          :is-not-empty="nonSalesDetailReportData.length > 0"
          :meta-data="metaData"
          @change-filter="changeFilter($event)"
        >
          <tr v-for="data in nonSalesDetailReportData" :key="data.id">
            <td class="uppercase">{{ getSplitString(data.id, '-', 0, '') }}</td>
            <td v-if="false">{{ data.full_name }}</td>
            <td>
              {{ formatDateLocale(data.created_at) }}
            </td>
            <td>{{ data.tenant_name }}</td>
            <!-- <td class="text-center">
              {{ data.payment_type }}
            </td> -->
            <td>
              <Currency :total-in-string="data.sub_total" />
            </td>
          </tr>
        </TableComponent>
        <!-- END: Non Sales Detail Table -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import SaleIcon from '@/assets/svg/sale.svg'

import TableComponent from '@/components/TableComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import Card from '@/components/Card.vue'
import Currency from '@/components/Currency.vue'

export default {
  name: 'NonSalesDetailPage',
  components: {
    Breadcrumb,
    TableComponent,
    ExportCSVComponent,
    Card,

    Currency,
    SaleIcon,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()

    const dateFilter = ref('')

    return {
      t,
      n,
      store,
      dateFilter,
    }
  },
  data: () => ({
    totalAmount: 0,
    totalTransaction: 0,
    nonSalesDetailReportHeader: [
      {
        item: 'page.report.nonSalesDetail.tableHeaders.orderNo',
        customClass: '',
      },
      //   {
      //     item: 'page.report.nonSalesDetail.tableHeaders.customerName',
      //     customClass: '',
      //   },
      {
        item: 'page.report.nonSalesDetail.tableHeaders.time',
        customClass: '',
      },
      {
        item: 'page.report.nonSalesDetail.tableHeaders.tenant',
        customClass: '',
      },
      //   {
      //     item: 'page.report.nonSalesDetail.tableHeaders.paymentMethod',
      //     customClass: 'text-center',
      //   },
      {
        item: 'page.report.nonSalesDetail.tableHeaders.totalPay',
      },
    ],
    nonSalesDetailReportData: [],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.getNonSalesDetail()
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }
  },
  methods: {
    async getNonSalesDetail(mode = 'getAll', fileType = null) {
      this.isLoading = true

      const params = {
        start_date: this.filterDates.start,
        end_date: this.filterDates.end,
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
      }

      if (mode === 'download') {
        params.file_type = fileType
        params.mode = mode
      }

      await this.store
        .dispatch('sales/nonSalesDetail', params)
        .then((response) => {
          this.isLoading = false
          if (mode === 'download') {
            const filename = this.extractFilename(
              response.dispotition,
              'report_sales.csv'
            )
            saveAs(response.data, filename)
          } else {
            const responseData = response.data.data
            if ('data' in responseData) {
              this.nonSalesDetailReportData = responseData.data
            }
            if ('summary' in responseData) {
              const responseSummary = responseData.summary
              this.totalAmount = responseSummary.total_amount
              this.totalTransaction = responseSummary.total_transaction
            }
            if ('meta' in responseData) {
              this.metaData = responseData.meta
            }
          }
          this.message = response.data.message

          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          this.isLoading = false
          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getNonSalesDetail()
    },
  },
}
</script>

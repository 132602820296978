<template>
  <div
    id="loading-indicator"
    class="align-center absolute z-50 flex h-full w-full justify-center"
  >
    <div class="flex w-full justify-center">
      <div
        wire:loading
        class="z-50 flex w-full flex-col items-center justify-center overflow-hidden bg-slate-100 opacity-90"
      >
        <div
          wire:loading
          class="z-50 flex w-full flex-col items-center justify-center overflow-hidden"
        >
          <div
            class="w-90 h-90 flex flex-col items-center justify-center rounded-md p-4 opacity-100"
          >
            <!-- <div
                class="loader-animation mb-4 h-12 w-12 rounded-full border-4 border-t-4 border-gray-200 ease-linear"
              ></div>
              <h2 class="text-center text-sm font-semibold text-gray-400">
                Loading...
              </h2> -->
            <Vue3Lottie
              ref="customControl"
              :animation-data="GorillaLottie"
              :height="300"
              :width="300"
              :loop="true"
              :speed="3.25"
              :auto-play="true"
              direction="forward"
              :pause-animation="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GorillaLottie from '@/assets/lottie/gorilla-transparent.json'

export default {
  name: 'LoadingIndicator',
  data() {
    return {
      GorillaLottie,
    }
  },
}
</script>

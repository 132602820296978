export default {
  data: () => ({
    omnichannelId: '',
    omnichannelData: [],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },

    // Form data
    name: '',
    isActive: false,
    posPaymentChannel: false,
    channelFee: 0,
    feeType: 'PERCENTAGE',
    feeTypes: [
      {
        label: 'Persentase',
        value: 'PERCENTAGE',
      },
      {
        label: 'Harga',
        value: 'PRICE',
      },
    ],
    omniChannelProducts: [],
    productIds: '',

    checkedIds: [],
    unChekedIds: [],
  }),
  methods: {
    async getAllOmnichannel(showMessage = true, tenantId, setLoading = false) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('omnichannel/getAll', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: this.filterData.search,
          tenant_id: tenantId,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.omnichannelData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getDetailOmnichannel(showMessage = true, omnichannelId) {
      await this.$store
        .dispatch('omnichannel/getDetail', {
          id: omnichannelId,
        })
        .then((response) => {
          const responseData = response.data.data

          this.showModal = true
          this.isEdit = true
          this.name = responseData.name
          this.channelFee = responseData.channel_fee
          this.feeType = responseData.type
          this.isActive = responseData.is_active
          this.omniChannelId = responseData.id
          this.omniChannelProducts = responseData.product

          const dataIds = this.omniChannelProducts.map((item) => {
            if (item.product) {
              return item.product.id
            }
          })
          this.checkedProduct = dataIds
          this.productIds = dataIds.toString()

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async updateOmnichannelStatusAPI(params) {
      await this.store
        .dispatch('omnichannel/updateStatus', params)
        .then((response) => {
          // return response
        })
        .catch((e) => {
          // this.message = e.message
          // setTimeout(() => {
          //   this.showToast('failed')
          // }, 500)
        })
    },
    async saveOmnichannelAPI(params, isUpdate = false) {
      let dispatcher = 'omnichannel/create'
      if (isUpdate) dispatcher = 'omnichannel/update'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteOmnichannelAPI(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('omnichannel/delete', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllOmnichannel(false)
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}

import axios, { routePathAPI, updateActiveUserAxios } from '@/plugins/axios.js'
import axiosCore from '@/plugins/axios-core.js'
import axiosGeneralPayment from '@/plugins/axios-general-payment.js'
import axiosLumen from '@/plugins/axios-lumen.js'
import axiosOrder, {
  updateActiveUserAxiosOrder,
} from '@/plugins/axios-order.js'
import axiosSCM from '@/plugins/axios-scm.js'

import router from '@/router'

// initial state
const state = () => ({
  isUserLoggedIn: false,
  activeTenant: null,
})

// getters
const getters = {
  isUserLoggedIn(state) {
    if (state.isUserLoggedIn === false) {
      return (
        localStorage.getItem('inkanteenDashboard.accessToken') !== null &&
        localStorage.getItem('inkanteenDashboard.refreshToken') !== null
      )
    }
    return state.isUserLoggedIn
  },
  accessToken(state) {
    return localStorage.getItem('inkanteenDashboard.accessToken') || null
  },
  refreshToken(state) {
    return localStorage.getItem('inkanteenDashboard.refreshToken') || null
  },
  activeUser(state) {
    return (
      JSON.parse(localStorage.getItem('inkanteenDashboard.activeUser')) || null
    )
  },
  tenantTotal(state) {
    return localStorage.getItem('inkanteenDashboard.tenantTotal') || 0
  },
  userLoggedInDate(state) {
    return localStorage.getItem('inkanteenDashboard.loggedInDate') || null
  },
  activeTenant(state) {
    if (state.activeTenant !== null) return JSON.parse(state.activeTenant)
    else if (
      localStorage.getItem('inkanteenDashboard.activeTenant') !== null &&
      localStorage.getItem('inkanteenDashboard.activeTenant') !== undefined
    ) {
      return JSON.parse(localStorage.getItem('inkanteenDashboard.activeTenant'))
    } else {
      return null
    }
  },
}

// actions
const actions = {
  updateLoginState({ commit, state }, loggedIn) {
    return commit('SET_IS_USER_LOGGED_IN', loggedIn)
  },
  setActiveTenant({ commit }, tenantData) {
    commit('SET_ACTIVE_TENANT', tenantData)
  },
  removeActiveTenant({ commit }) {
    commit('REMOVE_ACTIVE_TENANT')
  },
  login({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/auth/login`,
          {
            email: payload.email,
            password: payload.password,
          },
          {
            headers: {
              'x-language-code': currentLanguageCode,
              platform: import.meta.env.VITE_PLATFORM || null,
              device: import.meta.env.VITE_DEVICE || null,
            },
          }
        )
        .then(async (response) => {
          if (response.status === 200) {
            // SET TOKEN
            const token = response.data.data.token
            const accessToken = token.access_token
            const refreshToken = token.refresh_token

            const tokenType = 'Bearer'
            const axiosToken = `${tokenType} ${accessToken}`
            axios.defaults.headers.common['Authorization'] = axiosToken
            axiosCore.defaults.headers.common['Authorization'] = axiosToken
            axiosGeneralPayment.defaults.headers.common['Authorization'] =
              axiosToken
            axiosLumen.defaults.headers.common['Authorization'] = axiosToken
            axiosOrder.defaults.headers.common['Authorization'] = axiosToken
            axiosSCM.defaults.headers.common['Authorization'] = axiosToken

            await commit('SET_ACCESS_TOKEN', accessToken)
            await commit('SET_REFRESH_TOKEN', refreshToken)
            await commit('SET_LOGGED_IN_DATE', Date.now())

            // SET ACTIVE USER
            const user = response.data.data.user
            commit('SET_ACTIVE_USER', user)
            updateActiveUserAxiosOrder(JSON.stringify(user))
            updateActiveUserAxios(JSON.stringify(user))

            if ('tenant_total' in response.data.data) {
              const tenant_total = response.data.data.tenant_total
              commit('SET_TENANT_TOTAL', tenant_total)
            }

            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  refreshToken({ commit }) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const refreshToken =
        localStorage.getItem('inkanteenDashboard.refreshToken') || null

      const params = {
        refresh_token: refreshToken,
      }

      axios
        .post(`${routePathAPI()}/auth/token/refresh`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
            platform: import.meta.env.VITE_PLATFORM || null,
            device: import.meta.env.VITE_DEVICE || null,
          },
        })
        .then(async (response) => {
          if (response.status === 200) {
            // SET TOKEN
            const token = response.data.data
            const accessToken = token.access_token
            const refreshToken = token.refresh_token

            const tokenType = 'Bearer'
            const axiosToken = `${tokenType} ${accessToken}`
            axios.defaults.headers.common['Authorization'] = axiosToken
            axiosCore.defaults.headers.common['Authorization'] = axiosToken
            axiosGeneralPayment.defaults.headers.common['Authorization'] =
              axiosToken
            axiosLumen.defaults.headers.common['Authorization'] = axiosToken
            axiosOrder.defaults.headers.common['Authorization'] = axiosToken
            axiosSCM.defaults.headers.common['Authorization'] = axiosToken

            await commit('SET_ACCESS_TOKEN', accessToken)
            await commit('SET_REFRESH_TOKEN', refreshToken)
            await commit('SET_LOGGED_IN_DATE', Date.now())

            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resetPassword({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/auth/reset-password`,
          {
            email: payload.email,
          },
          {
            headers: {
              'x-language-code': currentLanguageCode,
              platform: import.meta.env.VITE_PLATFORM || null,
              device: import.meta.env.VITE_DEVICE || null,
            },
          }
        )
        .then(async (response) => {
          if (response.status === 200) {
            // SET TOKEN
            const token = response.data.data.token
            const accessToken = token.access_token
            const refreshToken = token.refresh_token

            const tokenType = 'Bearer'
            const axiosToken = `${tokenType} ${accessToken}`
            axios.defaults.headers.common['Authorization'] = axiosToken
            axiosCore.defaults.headers.common['Authorization'] = axiosToken
            axiosGeneralPayment.defaults.headers.common['Authorization'] =
              axiosToken
            axiosLumen.defaults.headers.common['Authorization'] = axiosToken
            axiosOrder.defaults.headers.common['Authorization'] = axiosToken
            axiosSCM.defaults.headers.common['Authorization'] = axiosToken

            await commit('SET_ACCESS_TOKEN', accessToken)
            await commit('SET_REFRESH_TOKEN', refreshToken)
            await commit('SET_LOGGED_IN_DATE', Date.now())

            // SET ACTIVE USER
            const user = response.data.data.user
            commit('SET_ACTIVE_USER', user)
            updateActiveUserAxiosOrder(JSON.stringify(user))
            updateActiveUserAxios(JSON.stringify(user))

            if ('tenant_total' in response.data.data) {
              const tenant_total = response.data.data.tenant_total
              commit('SET_TENANT_TOTAL', tenant_total)
            }

            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout({ commit }, payload) {
    commit('SET_IS_USER_LOGGED_IN', false)
    commit('REMOVE_ACCESS_TOKEN')
    commit('REMOVE_REFRESH_TOKEN')
    commit('REMOVE_ACTIVE_USER')
    commit('REMOVE_TENANT_TOTAL')
    commit('REMOVE_USER_FEATURE')
    commit('REMOVE_USER_MENU')
    commit('REMOVE_ACTIVE_TENANT')
    // commit('REMOVE_DEVICE_ID')
    commit('REMOVE_LOGGED_IN_DATE')
    delete axios.defaults.headers.common['Authorization']
    delete axiosCore.defaults.headers.common['Authorization']
    delete axiosGeneralPayment.defaults.headers.common['Authorization']
    delete axiosLumen.defaults.headers.common['Authorization']
    delete axiosOrder.defaults.headers.common['Authorization']
    delete axiosSCM.defaults.headers.common['Authorization']

    return true
  },
}

// mutations
const mutations = {
  SET_ACCESS_TOKEN(state, accessToken) {
    localStorage.setItem('inkanteenDashboard.accessToken', accessToken)
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    localStorage.setItem('inkanteenDashboard.refreshToken', refreshToken)
  },
  SET_ACTIVE_USER(state, user) {
    localStorage.setItem('inkanteenDashboard.activeUser', JSON.stringify(user))
  },
  SET_TENANT_TOTAL(state, tenantTotal) {
    localStorage.setItem('inkanteenDashboard.tenantTotal', tenantTotal)
  },
  SET_ACTIVE_TENANT(state, tenant) {
    state.activeTenant = JSON.stringify(tenant)
    localStorage.setItem(
      'inkanteenDashboard.activeTenant',
      JSON.stringify(tenant)
    )
  },
  SET_IS_USER_LOGGED_IN(state, isUserLoggedIn) {
    state.isUserLoggedIn = isUserLoggedIn
  },
  SET_LOGGED_IN_DATE(state, date) {
    localStorage.setItem('inkanteenDashboard.loggedInDate', date)
  },
  REMOVE_ACCESS_TOKEN(state) {
    localStorage.removeItem('inkanteenDashboard.accessToken')
  },
  REMOVE_REFRESH_TOKEN(state) {
    localStorage.removeItem('inkanteenDashboard.refreshToken')
  },
  REMOVE_ACTIVE_USER(state) {
    localStorage.removeItem('inkanteenDashboard.activeUser')
  },
  REMOVE_TENANT_TOTAL(state) {
    localStorage.removeItem('inkanteenDashboard.tenantTotal')
  },
  REMOVE_ACTIVE_TENANT(state) {
    if (state.activeTenant === null) {
      state.activeTenant = 'refresh' // to force refresh state
    }
    state.activeTenant = null
    localStorage.removeItem('inkanteenDashboard.activeTenant')
  },
  REMOVE_USER_FEATURE(state) {
    localStorage.removeItem('inkanteenDashboard.userFeature')
  },
  REMOVE_USER_MENU(state) {
    localStorage.removeItem('inkanteenDashboard.userMenu')
  },
  REMOVE_DEVICE_ID(state) {
    localStorage.removeItem('inkanteenDashboard.deviceId')
  },
  REMOVE_LOGGED_IN_DATE(state) {
    localStorage.removeItem('inkanteenDashboard.loggedInDate')
  },
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo =
      JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <div :id="id" class="toastify-content flex hidden" :class="[wrapperClass]">
    <XCircleIcon v-if="isFailed" class="text-danger" />
    <CheckCircleIcon v-else class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ title }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ToastComponent',
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    message: { type: String, required: true },
    isFailed: { type: Boolean, default: false },
    wrapperClass: { type: String, default: '' },
  },
}
</script>

<template>
  <div class="text-slate-500 sm:ml-auto sm:mt-0">
    <button
      :class="[
        wrapperClass !== ''
          ? wrapperClass
          : 'bg-black text-white dark:bg-white dark:text-black',
      ]"
      class="btn box ml-3 flex items-center"
    >
      <slot name="icon"></slot>
      {{ title }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'ButtonComponent',
  props: {
    wrapperClass: { type: String, default: '' },
    title: { type: String, default: '' },
  },
}
</script>

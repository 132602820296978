export default {
  data: () => ({
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    roleData: [],
  }),
  methods: {
    async getAllRole(showMessage = true, isTenantOnly = false) {
      await this.$store
        .dispatch('role/getAll', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: this.filterData.search,
          tenant_only: isTenantOnly,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.roleData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}

<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.payment')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex">
      <ButtonComponent
        v-if="showContent"
        :title="$t('page.additionalFee.addButton')"
        @click="showModalAdd"
      >
        <template #icon> + </template>
      </ButtonComponent>
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div v-if="showContent" class="grid grid-cols-12 gap-6">
        <!-- BEGIN: AdditionalFee List -->
        <TableComponent
          :is-show-search="true"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :title="$t('page.additionalFee.title')"
          :header="additionalFeeHeader"
          :is-not-empty="additionalFeeData.length > 0"
          :meta-data="metaDataAdditionalFee"
          :show-icon="false"
          @change-filter="changeFilter($event)"
          @change-filter-dropdown="changeFilterDropdown($event)"
        >
          <tr
            v-for="data in additionalFeeData"
            :key="data.id"
            :class="[
              data.is_editable === 1 ? 'cursor-pointer' : 'cursor-not-allowed',
            ]"
          >
            <td @click="getDetailData(data.id)">{{ data.name }}</td>
            <td class="text-center" @click="getDetailData(data.id)">
              <span v-if="data.type">
                {{ $t(`page.additionalFee.feeTypes.${data.type}`) }}
              </span>
              <span v-else>-</span>
            </td>
            <td class="text-center" @click="getDetailData(data.id)">
              <template v-if="data.amount_type === 'fixed'">
                <Currency
                  :total-in-string="data.amount"
                  wrap-class="justify-center"
                />
              </template>
              <p
                v-else-if="data.amount_type === 'percentage'"
                class="space-x-1"
              >
                <span>{{ data.amount }}</span>
                <span>%</span>
              </p>
              <p v-else>-</p>
            </td>
            <td class="space-x-2">
              <div class="form-switch">
                <input
                  :checked="data.is_active === 1"
                  type="checkbox"
                  class="form-check-input"
                  @change="setAdditionalFeeStatus($event, data)"
                />
              </div>
            </td>
          </tr>
        </TableComponent>
        <!-- END: AdditionalFee List -->
      </div>
      <div
        v-else
        class="col-span-12 mt-6 space-x-2 bg-white p-5 text-center dark:bg-slate-900 md:p-10"
      >
        <InfoIcon class="inline w-5" />
        <span>{{ $t('info.featureOnlyOneTenant') }}</span>
      </div>
    </div>

    <!-- BEGIN: Modal Add AdditionalFee -->
    <Modal
      :show="showAddAdditionalFeeModal"
      size="modal-lg"
      @hidden="closeModal('additionalFee')"
    >
      <ModalBody class="p-4">
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="additionalFeeSchema"
          :initial-errors="{}"
          :initial-touched="{}"
          :validate-on-mount="false"
          @submit="saveAdditionalFee"
        >
          <div class="flex flex-col space-y-4 px-5 pb-8 text-center">
            <div class="flex flex-col space-y-3">
              <div class="flex items-center space-x-1">
                <PackageIcon />
                <p class="font-bold">
                  <span v-if="!isEdit">
                    {{ $t('page.additionalFee.forms.title') }}
                  </span>
                  <span v-else>
                    {{ $t('page.additionalFee.forms.editTitle') }}
                  </span>
                </p>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="additionalFeeName"
                  >
                    <span>
                      {{ $t('page.additionalFee.forms.name')
                      }}<sup class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>

                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="additionalFeeName"
                    name="additionalFeeName"
                  >
                    <input
                      id="additionalFeeName"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.additionalFeeName !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      :placeholder="
                        $t('page.additionalFee.forms.namePlaceholder')
                      "
                    />
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.additionalFeeName !== undefined">
                      {{ $t(errors.additionalFeeName) }}
                    </template>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex w-full space-x-2">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="additionalFeeType"
                  >
                    <span>
                      {{ $t('page.additionalFee.forms.feeType')
                      }}<sup class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>
                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="additionalFeeType"
                    name="additionalFeeType"
                  >
                    <select
                      id="additionalFeeType"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.additionalFeeType !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      @change="changeAdditionalFeeType"
                    >
                      <option value="" disabled hidden>
                        {{ $t('page.additionalFee.forms.feeTypePlaceholder') }}
                      </option>
                      <option
                        v-for="feeType in additionalFeeTypes"
                        :key="feeType"
                        :value="feeType"
                      >
                        {{ $t(`page.additionalFee.feeTypes.${feeType}`) }}
                      </option>
                    </select>
                  </Field>

                  <span class="text-xs text-red-400">
                    <template v-if="errors.additionalFeeType !== undefined">
                      {{ $t(errors.additionalFeeType) }}
                    </template>
                  </span>
                </div>
              </div>
            </div>

            <div
              v-if="additionalFeeType === 'pajak'"
              class="flex flex-col gap-4 border-b pb-4"
            >
              <div class="flex space-x-4">
                <div>
                  <input
                    v-model="additionalFeeAdvanceType"
                    type="radio"
                    value="before-discount"
                    class="text-primary"
                    :checked="additionalFeeAdvanceType === 'before-discount'"
                  />
                  {{ $t('page.additionalFee.forms.beforeDiscount') }}
                </div>
                <div>
                  <input
                    v-model="additionalFeeAdvanceType"
                    type="radio"
                    class="text-primary"
                    value="after-discount"
                    :checked="additionalFeeAdvanceType === 'after-discount'"
                  />

                  {{ $t('page.additionalFee.forms.afterDiscount') }}
                </div>
              </div>
              <div class="flex space-x-4">
                <div>
                  <input
                    v-model="additionalFeeAdvanceType"
                    type="radio"
                    class="text-primary"
                    value="include-price"
                    :checked="additionalFeeAdvanceType === 'include-price'"
                  />

                  {{ $t('page.additionalFee.forms.includePrice') }}
                </div>
                <div>
                  <input
                    v-model="additionalFeeAdvanceType"
                    type="radio"
                    value="tax-per-item"
                    class="text-primary"
                    :checked="additionalFeeAdvanceType === 'tax-per-item'"
                  />
                  {{ $t('page.additionalFee.forms.taxPerItem') }}
                </div>
              </div>
              <div
                v-if="additionalFeeAdvanceType === 'tax-per-item'"
                class="flex w-full justify-start"
              >
                <div
                  class="cursor-pointer rounded-md border border-dashed border-gray-400 px-6 py-2 text-xs text-gray-500 hover:text-gray-700"
                  @click="showModalItems"
                >
                  <span v-if="additionalFeeItems.length > 0">
                    {{
                      $t('page.additionalFee.forms.totalItem', {
                        total: additionalFeeItems.length,
                      })
                    }}
                  </span>
                  <span v-else>
                    {{ $t('page.additionalFee.forms.addItem') }}
                  </span>
                </div>
              </div>
              <div
                class="flex items-center space-x-2 rounded-lg bg-gray-100 p-2 text-xs"
              >
                <p>{{ $t('page.additionalFee.forms.infoLamp') }}</p>
                <p>{{ $t('page.additionalFee.forms.infoDesc') }}</p>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="additionalFeeAmount"
                  >
                    <span>
                      {{ $t('page.additionalFee.forms.amount') }}
                      <sup class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>

                <Field
                  v-slot="{ field }"
                  v-model="additionalFeeAmountTypeIsPercent"
                  name="additionalFeeAmountTypeIsPercent"
                >
                  <input type="hidden" v-bind="field" />
                </Field>
                <div class="flex space-x-4">
                  <div>
                    <input
                      v-model="additionalFeeAmountType"
                      type="radio"
                      value="percentage"
                      class="text-primary"
                      :checked="additionalFeeAmountType === 'percentage'"
                    />
                    {{ $t('page.additionalFee.forms.percent') }}
                  </div>
                  <div v-if="additionalFeeType !== 'pajak'">
                    <input
                      v-model="additionalFeeAmountType"
                      type="radio"
                      class="text-primary"
                      value="fixed"
                      :checked="additionalFeeAmountType === 'fixed'"
                    />

                    {{ $t('page.additionalFee.forms.nominal') }}
                  </div>
                </div>
                <Field
                  v-slot="{ field }"
                  v-model="additionalFeeAmount"
                  name="additionalFeeAmount"
                >
                  <div
                    v-if="additionalFeeAmountType === 'fixed'"
                    class="relative"
                  >
                    <div
                      class="start-0 pointer-events-none absolute inset-y-0 flex items-center pl-3.5"
                    >
                      <p>Rp</p>
                    </div>
                    <input
                      id="additionalFeeAmount"
                      v-bind="field"
                      required="required"
                      class="block w-full appearance-none rounded border-2 p-2.5 pl-10 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.additionalFeeAmount !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      placeholder="0"
                      @keypress="isInputNumber($event)"
                    />
                  </div>
                  <div v-else class="relative">
                    <input
                      id="additionalFeeAmount"
                      v-bind="field"
                      required="required"
                      class="block w-full appearance-none rounded border-2 p-2.5 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.additionalFeeAmount !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      placeholder="0"
                      @keypress="isInputNumber($event)"
                    />
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                      <p>%</p>
                    </div>
                  </div>
                </Field>
                <span class="text-xs text-red-400">
                  <template v-if="errors.additionalFeeAmount !== undefined">
                    {{ $t(errors.additionalFeeAmount) }}
                  </template>
                </span>
              </div>
            </div>
          </div>
          <div
            class="flex w-full px-5 pb-8"
            :class="[isEdit ? 'justify-between' : 'justify-end']"
          >
            <div v-if="isEdit" class="flex">
              <button
                type="button"
                class="btn btn-outline-danger w-24"
                @click="showModalDelete(additionalFeeId)"
              >
                {{ $t('button.delete') }}
              </button>
            </div>
            <div
              class="flex justify-end space-x-2"
              :class="[isEdit ? '' : 'w-full']"
            >
              <button
                type="button"
                class="w-24 text-primary"
                @click="closeModal('additionalFee')"
              >
                {{ $t('button.cancel') }}
              </button>
              <button type="submit" class="btn btn-primary w-24">
                {{ $t('button.save') }}
              </button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
    <!-- END: Modal Add AdditionalFee -->

    <!-- BEGIN: Modal Select Product -->
    <Modal
      :show="showAddItemProductModal"
      size="modal-lg"
      @hidden="closeModal('item')"
    >
      <ModalBody class="p-4">
        <div class="flex flex-col space-y-4 text-center">
          <div class="flex flex-col space-y-3">
            <div class="flex items-center space-x-1">
              <PackageIcon />
              <p class="font-bold">
                {{ $t('page.additionalFee.forms.addItem') }}
              </p>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex shadow">
                <input
                  v-model="searchProduct"
                  class="w-full border-none p-2 outline-none"
                  type="text"
                  :placeholder="$t('formLabel.searchFoo', { foo: 'Item' })"
                />
                <button
                  class="text-blue hover:text-blue-light flex w-auto items-center justify-end p-2"
                  @click="
                    searchingProduct('productCategory', 'local', searchProduct)
                  "
                >
                  <SearchIcon />
                </button>
              </div>
              <div>
                <div class="flex w-full items-center justify-between py-1 pb-4">
                  <div class="font-semibold">Menu tenant</div>
                  <div
                    class="flex cursor-pointer items-center space-x-2 rounded-md border px-2 py-1 text-xs font-medium shadow-sm"
                    @click="checkAll"
                  >
                    <span>{{ $t('formInfo.selectAll') }}</span>
                    <SelectAllIcon
                      :class="[checkedAll ? 'text-primary' : 'text-gray-400']"
                    />
                  </div>
                </div>
                <div
                  v-if="productData.length > 0"
                  class="grid h-96 max-h-96 grid-cols-12 gap-6 overflow-y-scroll"
                >
                  <div
                    v-for="product in productData"
                    :key="product.id"
                    class="relative col-span-3 space-y-2"
                  >
                    <div>
                      <ImageLoader
                        v-if="product.images.length > 0"
                        :image="product.images[0].image"
                        :alt="product.name"
                      />
                      <ImageLoader v-else :image="null" :alt="product.name" />
                    </div>
                    <div class="max-w-full truncate">{{ product.name }}</div>
                    <div class="absolute top-0 left-1">
                      <input
                        type="checkbox"
                        class="form-check-input border"
                        :checked="
                          checkedProduct.find(
                            (item) => item.product_id === product.id
                          ) !== undefined
                        "
                        @change="checkProduct($event, product)"
                      />
                    </div>
                  </div>
                </div>
                <div v-else class="flex w-full justify-center pt-5 text-center">
                  {{ $t('info.dataNotFound') }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex w-full justify-end px-5 pb-8">
            <div class="flex w-full justify-end space-x-2">
              <button
                type="button"
                class="btn w-24"
                @click="closeModal('item')"
              >
                {{ $t('button.cancel') }}
              </button>
              <button
                type="submit"
                class="btn btn-primary w-24"
                @click="saveProductItem"
              >
                {{ $t('button.save') }}
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
    <!-- END: Modal Select Product -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <DeleteModal
      :id="deleteId"
      :show-modal="showModalDel"
      @yes="confirmDelete($event)"
      @no="showModalDel = false"
    />

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>
<script>
import debounce from 'lodash.debounce'
import * as Yup from 'yup'

import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import taxMixin from '@/mixins/tax.js'
import productMixin from '@/mixins/product.js'

import { Form, Field } from 'vee-validate'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import Currency from '@/components/Currency.vue'
import ImageLoader from '@/components/image-loader/Main.vue'

import PackageIcon from '@/assets/svg/package.svg'
import SelectAllIcon from '@/assets/svg/select-all.svg'

export default {
  name: 'AdditionalFeeListPage',
  components: {
    Form,
    Field,
    Breadcrumb,
    TableComponent,
    ButtonComponent,
    DeleteModal,
    Currency,
    ImageLoader,

    PackageIcon,
    SelectAllIcon,
  },
  mixins: [globalMixin, tenantMixin, taxMixin, productMixin],
  data: () => ({
    showAddAdditionalFeeModal: false,
    showAddItemProductModal: false,
    checkedProduct: [],
    showContent: true,

    additionalFeeHeader: [
      {
        item: 'page.additionalFee.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.additionalFee.tableHeaders.feeType',
        customClass: 'text-center',
      },
      {
        item: 'page.additionalFee.tableHeaders.total',
        customClass: 'text-center',
      },
      {
        item: 'page.additionalFee.tableHeaders.isActive',
        customClass: '',
      },
    ],

    // Form Validation
    additionalFeeSchema: Yup.object().shape({
      additionalFeeName: Yup.string()
        .typeError('formError.additionalFeeName.required')
        .required('formError.additionalFeeName.required')
        .matches(/^(?!\s+$).*/, 'formError.additionalFeeName.required'),
      additionalFeeType: Yup.string()
        .typeError('formError.additionalFeeType.required')
        .required('formError.additionalFeeType.required'),
      additionalFeeAmountTypeIsPercent: Yup.bool(),
      additionalFeeAmount: Yup.number()
        .typeError('formError.additionalFeeAmount.required')
        .required('formError.additionalFeeAmount.required')
        .when('additionalFeeAmountTypeIsPercent', {
          is: true,
          then: Yup.number()
            .typeError('formError.additionalFeeAmount.required')
            .required('formError.additionalFeeAmount.required')
            .max(100, 'formError.additionalFeeAmount.maxPercent'),
        }),
    }),
  }),
  computed: {
    checkedAll() {
      const checkedProductIds = this.checkedProduct.map(
        (item) => item.product_id
      )
      return (
        this.productData.length > 0 &&
        this.productData.every((item) => checkedProductIds.includes(item.id))
      )
    },
  },
  watch: {
    additionalFeeAmountType(val) {
      this.additionalFeeAmountTypeIsPercent = val === 'percentage'
    },
    searchProduct: debounce(function (newVal) {
      this.searchingProduct('productCategory', 'local', newVal)
    }, 500),
  },
  mounted() {
    if (this.activeUserStore !== null) {
      if ('institute' in this.activeUserStore) {
        if ('is_multiple_tenant' in this.activeUserStore.institute)
          this.showContent = !this.activeUserStore.institute.is_multiple_tenant
      }
      if (this.showContent) {
        const role = this.activeUserStore.role.role
        if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
          this.tenantId = this.activeUserStore.tenant.id
          this.getAllAdditionalFee()
          this.getAllProduct(false, true, false, '', false, true)
          this.isInstitute = false
        } else {
          this.getAllTenant(true).then(() => {
            this.getAllAdditionalFee()
            this.getAllProduct(false, true, false, '', false, true)
          })
          this.isInstitute = true
        }
      }
    }
  },
  methods: {
    changeFilter(event) {
      this.filterDataAdditionalFee = event.filterData
      this.getAllAdditionalFee()
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getAllAdditionalFee()
    },
    handleAdvanceType(advanced) {
      if ('per_item' in advanced && advanced.per_item) return 'tax-per-item'
      else if ('before_discount' in advanced && advanced.before_discount)
        return 'before-discount'
      else if ('after_discount' in advanced && advanced.after_discount)
        return 'after-discount'
      else if ('including_price' in advanced && advanced.including_price)
        return 'include-price'
      else return 'before-discount'
    },
    showModalUpdate(data) {
      this.showAddAdditionalFeeModal = true
      this.isEdit = true
      this.additionalFeeId = data.id
      this.additionalFeeName = data.name
      this.additionalFeeType = data.type
      this.additionalFeeAmount = data.amount
      this.additionalFeeIsActive = data.is_active
      this.additionalFeeAdvanceType = this.handleAdvanceType(data.advanced)
      this.additionalFeeAmountType = data.amount_type
      this.additionalFeeItems = data.advanced.items
      this.checkedProduct = data.advanced.items
    },
    showModalAdd() {
      this.showAddAdditionalFeeModal = true
      this.isEdit = false
      this.resetData(false)
    },
    saveAdditionalFee() {
      if (this.additionalFeeName === '' || this.isLoadingSave) return

      const params = {}
      params.name = this.additionalFeeName
      params.type = this.additionalFeeType
      params.amount_type = this.additionalFeeAmountType
      params.amount = this.additionalFeeAmount
      params.is_active = this.additionalFeeIsActive
      const advanced = {}
      advanced.items = this.additionalFeeItems
      advanced.per_item = this.additionalFeeAdvanceType === 'tax-per-item'
      advanced.after_discount =
        this.additionalFeeAdvanceType === 'after-discount'
      advanced.before_discount =
        this.additionalFeeAdvanceType === 'before-discount'
      advanced.including_price =
        this.additionalFeeAdvanceType === 'include-price'

      params.advanced = advanced

      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.additionalFeeId === '') {
          this.isLoadingSave = false
          return
        }
        params.id = this.additionalFeeId

        this.saveAdditionalFeeAPI(params, true)
      } else {
        this.saveAdditionalFeeAPI(params, false)
      }
    },
    closeModal(type = 'item') {
      if (type === 'item') {
        this.showAddItemProductModal = false
        this.checkedProduct = []
        this.searchProduct = ''
      } else {
        this.showAddAdditionalFeeModal = false
      }
    },
    confirmDelete(id) {
      this.deleteAdditionalFeeAPI(id)
    },
    resetData(afterSave = true) {
      this.additionalFeeId = null
      this.additionalFeeName = ''
      this.additionalFeeType = ''
      this.additionalFeeAmount = ''
      this.additionalFeeAdvanceType = 'before-discount'
      this.additionalFeeAmountType = 'percentage'
      this.additionalFeeIsActive = 0
      this.additionalFeeItems = []
      this.checkedProduct = []

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            additionalFeeName: '',
            additionalFeeType: '',
            additionalFeeAmount: '',
            additionalFeeAmountType: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showAddAdditionalFeeModal = false
        this.isEdit = false
        this.getAllAdditionalFee({
          showMessage: false,
          setLoading: true,
          search: '',
          setMetaData: true,
        })
      }
    },
    getDetailData(id) {
      this.getAdditionalFeeDetailAPI(id).then((res) => {
        this.showModalUpdate(this.additionalFeeDetailData)
      })
    },
    setAdditionalFeeStatus($event, data) {
      const checked = $event.target.checked

      const payload = {
        id: data.id,
        is_active: checked,
        type: data.type,
      }

      if (!this.isLoading) {
        this.setAdditionalFeeStatusAPI(payload)
      }
    },
    checkAll() {
      if (this.checkedAll) {
        this.checkedProduct = []
      } else {
        this.productData.forEach((product) => {
          this.checkProduct(
            {
              target: {
                checked: true,
              },
            },
            product
          )
        })
      }
    },
    checkProduct($event, product) {
      if ($event.target.checked) {
        if (
          this.checkedProduct.find((item) => item.product_id === product.id) ===
          undefined
        ) {
          this.checkedProduct.push({
            product_id: product.id,
            product_name: product.name,
            product_image:
              product.images.length > 0 ? product.images[0].image : null,
            product_price: product.price,
          })
        }
      } else {
        this.checkedProduct = this.checkedProduct.filter(
          (item) => item.product_id !== product.id
        )
      }
    },
    saveProductItem() {
      this.additionalFeeItems = this.checkedProduct
      this.closeModal('item')
    },
    showModalItems() {
      this.showAddItemProductModal = true
      if (this.additionalFeeItems.length > 0) {
        const additionalFeeItems = [...this.additionalFeeItems]
        this.checkedProduct = additionalFeeItems
      } else {
        this.checkedProduct = []
      }
    },
    changeAdditionalFeeType() {
      if (
        this.additionalFeeType === 'pajak' &&
        this.additionalFeeAmountType === 'fixed'
      )
        this.additionalFeeAmountType = 'percentage'
    },
  },
}
</script>

<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.inventoryList')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex">
      <ButtonComponent
        :title="$t('page.retail.categoryList.addButton')"
        @click="showModalAdd"
      >
        <template #icon> + </template>
      </ButtonComponent>
    </div>
  </div>

  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Product List -->
        <TableComponent
          :is-show-search="true"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :title="$t('page.retail.categoryList.title')"
          :header="categoryListHeader"
          :is-not-empty="categoryData.length > 0"
          :meta-data="metaDataCategory"
          @change-filter="changeFilter($event)"
          @change-filter-dropdown="changeFilterDropdown($event)"
        >
          <template #icon>
            <BookIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
          </template>

          <tr
            v-for="data in categoryData"
            :key="data.id"
            class="cursor-pointer"
          >
            <td>{{ data.name }}</td>
            <td class="text-center">
              {{ data.description || '-' }}
            </td>
            <td class="space-x-2">
              <div class="flex space-x-2">
                <button
                  class="flex items-center space-x-1 rounded-sm bg-primary px-4 py-1 text-xs font-medium text-white"
                  @click="showModalUpdate(data)"
                >
                  <EditIcon />
                  <span>{{ $t('action.edit') }}</span>
                </button>
                <button
                  class="flex items-center space-x-1 rounded-sm bg-danger px-4 py-1 text-xs font-medium text-white"
                  @click="showModalDelete(data.id)"
                >
                  <DeleteIcon />
                  <span>{{ $t('action.delete') }}</span>
                </button>
              </div>
            </td>
          </tr>
        </TableComponent>
        <!-- END: Product List -->
      </div>
    </div>

    <!-- BEGIN: Modal Add Product -->
    <Modal :show="showAddCategoryModal" size="modal-lg" @hidden="closeModal">
      <ModalBody class="p-4">
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="categorySchema"
          :initial-errors="{}"
          :initial-touched="{}"
          :validate-on-mount="false"
          @submit="saveCategory"
        >
          <div class="flex flex-col space-y-4 px-5 pb-8 text-center">
            <div class="flex flex-col space-y-3">
              <div class="flex items-center space-x-1">
                <PackageIcon />
                <p class="font-bold">
                  <span v-if="!isEdit">
                    {{ $t('page.retail.categoryList.forms.addCategory') }}
                  </span>
                  <span v-else>
                    {{ $t('page.retail.categoryList.forms.editCategory') }}
                  </span>
                </p>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="categoryName"
                  >
                    <span>
                      {{ $t('page.retail.categoryList.forms.name')
                      }}<sup class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>

                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="categoryName"
                    name="categoryName"
                  >
                    <input
                      id="categoryName"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.categoryName !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      :placeholder="
                        $t('page.retail.categoryList.forms.namePlaceholder')
                      "
                    />
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.categoryName !== undefined">
                      {{ $t(errors.categoryName) }}
                    </template>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="categoryDescription"
                  >
                    {{ $t('page.retail.categoryList.forms.description') }}
                  </label>
                </div>
                <div>
                  <textarea
                    id="categoryDescription"
                    v-model="categoryDescription"
                    required="required"
                    class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                    :placeholder="
                      $t(
                        'page.retail.categoryList.forms.descriptionPlaceholder'
                      )
                    "
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end space-x-2 px-5 pb-8">
            <button type="button" class="w-24 text-primary" @click="closeModal">
              {{ $t('button.cancel') }}
            </button>
            <button type="submit" class="btn btn-primary w-24">
              {{ $t('button.save') }}
            </button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
    <!-- END: Modal Add Product -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <DeleteModal
      :id="deleteId"
      :show-modal="showModalDel"
      @yes="confirmDelete($event)"
      @no="showModalDel = false"
    />

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import * as Yup from 'yup'

import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import retailMixin from '@/mixins/retail.js'

import { Form, Field } from 'vee-validate'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

import DeleteModal from '@/components/DeleteModal.vue'

import BookIcon from '@/assets/svg/menu/book.svg'
import PackageIcon from '@/assets/svg/package.svg'
import EditIcon from '@/assets/svg/edit.svg'
import DeleteIcon from '@/assets/svg/delete.svg'

export default {
  name: 'CategoryListPage',
  components: {
    Form,
    Field,
    Breadcrumb,
    TableComponent,
    ButtonComponent,

    DeleteModal,

    BookIcon,
    PackageIcon,
    EditIcon,
    DeleteIcon,
  },
  mixins: [globalMixin, tenantMixin, retailMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    return {
      t,
      n,
      store,
      route,
      router,
    }
  },
  data: () => ({
    categoryListHeader: [
      {
        item: 'page.retail.categoryList.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.retail.categoryList.tableHeaders.description',
        customClass: 'text-center',
      },
      {
        item: 'action.action',
        customClass: '',
      },
    ],

    showAddCategoryModal: false,

    // Form Validation
    categorySchema: Yup.object().shape({
      categoryName: Yup.string()
        .typeError('formError.categoryName.required')
        .required('formError.categoryName.required')
        .matches(/^(?!\s+$).*/, 'formError.categoryName.required'),
    }),
  }),
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllCategory()
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getAllCategory()
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    changeFilter(event) {
      this.filterDataCategory = event.filterData
      this.getAllCategory()
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getAllCategory()
    },
    showModalUpdate(data) {
      this.showAddCategoryModal = true
      this.isEdit = true
      this.categoryId = data.id
      this.categoryName = data.name
      this.categoryDescription = data.description
    },
    showModalAdd() {
      this.showAddCategoryModal = true
      this.isEdit = false
      this.resetData(false)
    },

    saveCategory() {
      if (this.categoryName === '' || this.isLoadingSave) return

      const params = {}
      params.tenant_id = this.tenantId
      params.name = this.categoryName
      params.description = this.categoryDescription

      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.categoryId === '') {
          this.isLoadingSave = false
          return
        }
        params.id = this.categoryId

        this.saveCategoryAPI(params, true)
      } else {
        this.saveCategoryAPI(params, false)
      }
    },
    closeModal() {
      this.showAddCategoryModal = false
    },
    confirmDelete(id) {
      this.deleteCategoryAPI(id)
    },
    resetData(afterSave = true) {
      this.categoryId = null
      this.categoryName = ''
      this.categoryDescription = ''

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            categoryName: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showAddCategoryModal = false
        this.isEdit = false
        this.getAllCategory({
          showMessage: false,
          setLoading: true,
          search: '',
          setMetaData: true,
        })
      }
    },
  },
}
</script>

import { defineStore } from 'pinia'

export const useSideMenuStore = defineStore('sideMenu', {
  state: () => ({
    menu: [
      {
        general: true,
        checkMenu: true,
        icon: 'HomeIcon',
        svg: true,
        pageName: 'dashboard',
        menu: 'sales',
        title: 'menu.dashboard',
      },
      {
        general: true,
        checkMenu: true,
        icon: 'HomeIcon',
        svg: true,
        pageName: 'dashboard-super-institute',
        menu: 'sales',
        title: 'menu.dashboard',
      },
      {
        mobileDivier: true,
        title: 'topBarMenu.sale',
      },
      // {
      //   general: true,
      //   checkMenu: true,
      //   icon: 'HomeIcon',
      //   svg: true,
      //   pageName: 'dashboard-finance',
      //   menu: 'finance',
      //   title: 'menu.dashboard',
      // },
      // {
      //   general: true,
      //   checkMenu: true,
      //   icon: 'HomeIcon',
      //   svg: true,
      //   pageName: 'dashboard-setting',
      //   menu: 'setting',
      //   title: 'menu.dashboard',
      // },
      // SALES
      {
        icon: 'ReportIcon',
        svg: true,
        pageName: 'report',
        menu: 'sales',
        title: 'menu.report.index',
        showSubMenu: true,
        submenu: [
          {
            icon: '',
            pageName: 'report-summary',
            title: 'menu.report.summary',
          },
          {
            icon: '',
            pageName: 'report-order-detail',
            title: 'menu.report.orderDetail',
          },
          {
            icon: '',
            pageName: 'report-non-sales-detail',
            title: 'menu.report.nonSalesDetail',
          },
          {
            icon: '',
            pageName: 'report-tenant-sales',
            title: 'menu.report.tenantSales',
          },
          {
            icon: '',
            pageName: 'report-daily-sales',
            title: 'menu.report.dailySales',
          },
          {
            icon: '',
            pageName: 'report-product-sales',
            title: 'menu.report.productSales',
          },
          {
            icon: '',
            pageName: 'report-payment-method',
            title: 'menu.report.paymentMethod',
          },
          {
            icon: '',
            pageName: 'report-withdrawal',
            title: 'menu.report.withdrawal',
          },
          {
            icon: '',
            pageName: 'report-promotion',
            title: 'menu.report.promotion',
          },
          {
            icon: '',
            pageName: 'report-additional-fee',
            title: 'menu.report.additionalFee',
          },
        ],
      },
      {
        icon: 'AnalyticIcon',
        svg: true,
        pageName: 'analytic',
        menu: 'sales',
        title: 'menu.analytic.index',
        showSubMenu: true,
        submenu: [
          {
            icon: '',
            pageName: 'analytic-average-transaction',
            title: 'menu.analytic.averageTransaction',
          },
          {
            icon: '',
            pageName: 'analytic-growth-percentage',
            title: 'menu.analytic.growthPercentage',
          },
          // {
          //   icon: '',
          //   pageName: 'analytic-stock-turnover',
          //   title: 'menu.analytic.stockTurnOver',
          // },
          {
            icon: '',
            pageName: 'analytic-omnichannel-index',
            title: 'menu.analytic.omnichannelIndex',
          },
          {
            icon: '',
            pageName: 'analytic-satisfaction-index',
            title: 'menu.analytic.satisfactionIndex',
          },
        ],
      },
      {
        icon: 'ReportIcon',
        svg: true,
        general: true,
        pageName: 'shift-history',
        menu: 'sales',
        title: 'menu.setting.shift',
      },
      // END SALES

      {
        mobileDivier: true,
        title: 'topBarMenu.setting',
      },
      // SETTING
      // {
      //   icon: 'ProfileIcon',
      //   svg: true,
      //   pageName: 'profile',
      //   menu: 'setting',
      //   title: 'menu.profile.index',
      //   showSubMenu: true,
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'profile-user',
      //       title: 'menu.profile.account',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'profile-bussiness',
      //       title: 'menu.profile.bussiness',
      //     },
      //   ],
      // },
      {
        icon: 'TableManagementIcon',
        svg: true,
        general: true,
        pageName: 'table-management',
        menu: 'setting',
        title: 'menu.setting.tableManagement',
      },
      // {
      //   icon: 'ProfileIcon',
      //   svg: true,
      //   general: true,
      //   pageName: 'kiosk-management',
      //   menu: 'setting',
      //   title: 'menu.setting.kiosk',
      // },
      {
        icon: 'EmployeeIcon',
        svg: true,
        general: true,
        pageName: 'employee',
        menu: 'setting',
        title: 'menu.employee.title',
        showSubMenu: true,
        submenu: [
          {
            icon: '',
            pageName: 'employee',
            title: 'menu.employee.title',
          },
          // {
          //   icon: '',
          //   pageName: 'employee-privilege',
          //   title: 'menu.employee.privilege',
          // },
        ],
      },
      {
        icon: 'MoneyIcon',
        svg: true,
        general: true,
        pageName: 'omnichannel',
        menu: 'setting',
        title: 'menu.setting.payment',
        showSubMenu: true,
        submenu: [
          {
            icon: '',
            pageName: 'additional-fee',
            title: 'menu.setting.additionalFee',
          },
          {
            icon: '',
            pageName: 'omnichannel',
            title: 'menu.setting.omnichannel',
          },
          {
            icon: '',
            pageName: 'payment-channel',
            title: 'menu.setting.paymentChannel',
          },
        ],
      },
      // {
      //   icon: 'ProfileIcon',
      //   svg: true,
      //   general: true,
      //   pageName: 'office-hour',
      //   menu: 'setting',
      //   title: 'menu.setting.officeHour',
      // },
      {
        icon: 'StoreIcon',
        svg: true,
        pageName: 'product-list',
        menu: 'setting',
        title: 'menu.product.index',
        showSubMenu: true,
        submenu: [
          {
            icon: '',
            pageName: 'ingredient-category-list',
            title: 'menu.product.ingredientCategories',
          },
          {
            icon: '',
            pageName: 'ingredient-brand-list',
            title: 'menu.product.ingredientBrands',
          },
          {
            icon: '',
            pageName: 'ingredient-list',
            title: 'menu.product.ingredients',
          },
          {
            icon: '',
            pageName: 'variant-list',
            title: 'menu.product.variant',
          },
          {
            icon: '',
            pageName: 'product-list',
            title: 'menu.product.productList',
          },
        ],
      },
      {
        icon: 'StoreIcon',
        svg: true,
        pageName: 'inventory-adjustment',
        menu: 'setting',
        title: 'menu.setting.inventory',
        showSubMenu: true,
        submenu: [
          {
            icon: '',
            pageName: 'inventory-adjustment',
            title: 'menu.setting.inventoryAdjustment',
          },
          // {
          //   icon: '',
          //   pageName: 'inventory-pantry',
          //   title: 'menu.setting.pantry',
          // },
        ],
      },
      {
        icon: 'StoreIcon',
        svg: true,
        pageName: 'retail-inventory-unit',
        menu: 'setting',
        title: 'menu.setting.inventoryList',
        showSubMenu: true,
        submenu: [
          {
            icon: '',
            pageName: 'retail-inventory-unit',
            title: 'menu.setting.unitList',
          },
          {
            icon: '',
            pageName: 'retail-inventory-category',
            title: 'menu.setting.categoryList',
          },
          {
            icon: '',
            pageName: 'retail-inventory-brand',
            title: 'menu.setting.brandList',
          },
          {
            icon: '',
            pageName: 'retail-inventory-product',
            title: 'menu.setting.productList',
          },
        ],
      },
      {
        icon: 'StoreIcon',
        svg: true,
        pageName: 'retail-inventory-management-supplier',
        menu: 'setting',
        title: 'menu.setting.inventoryManagement',
        showSubMenu: true,
        submenu: [
          {
            icon: '',
            pageName: 'retail-inventory-management-stock-adjustment',
            title: 'menu.setting.stockAdjustment',
          },
          {
            icon: '',
            pageName: 'retail-inventory-management-stock-purchase',
            title: 'menu.setting.stockPurchase',
          },
          {
            icon: '',
            pageName: 'retail-inventory-management-supplier',
            title: 'menu.setting.supplier',
          },
        ],
      },
      // END SETTING

      // PURCHASE STOCK
      // {
      //   mobileDivier: true,
      //   title: 'topBarMenu.purchaseOrder',
      // },
      {
        icon: 'ReportIcon',
        svg: true,
        general: true,
        pageName: 'stock-purchase-history',
        menu: 'purchase_order',
        title: 'menu.purchaseOrder.historyOrder',
        showSubMenu: false,
        submenu: [
          {
            icon: '',
            pageName: 'stock-purchase-history-detail',
            title: 'menu.purchaseOrder.historyOrder',
          },
        ],
      },
      {
        icon: 'BagIcon',
        svg: true,
        general: true,
        pageName: 'stock-purchase',
        menu: 'purchase_order',
        title: 'menu.purchaseOrder.stockPurchase',
        showSubMenu: false,
        submenu: [
          {
            icon: '',
            pageName: 'stock-purchase-cart',
            title: 'menu.purchaseOrder.stockPurchase',
          },
          {
            icon: '',
            pageName: 'stock-purchase-cart-address',
            title: 'menu.purchaseOrder.stockPurchase',
          },
          {
            icon: '',
            pageName: 'stock-purchase-cart-address-form',
            title: 'menu.purchaseOrder.stockPurchase',
          },
          {
            icon: '',
            pageName: 'stock-purchase-supplier',
            title: 'menu.purchaseOrder.stockPurchase',
          },
          {
            icon: '',
            pageName: 'stock-purchase-supplier-detail',
            title: 'menu.purchaseOrder.stockPurchase',
          },
          {
            icon: '',
            pageName: 'stock-purchase-product-detail',
            title: 'menu.purchaseOrder.stockPurchase',
          },
        ],
      },
      // END PURCHASE STOCK

      // FINANCE
      // {
      //   mobileDivier: true,
      //   title: 'topBarMenu.finance',
      // },
      {
        icon: 'IncomeIcon',
        svg: true,
        general: true,
        pageName: 'balance',
        menu: 'finance',
        title: 'menu.balance.index',
      },
      // {
      //   icon: 'BookIcon',
      //   svg: true,
      //   pageName: 'cashbook',
      //   menu: 'finance',
      //   title: 'menu.cashbook.index',
      //   showSubMenu: true,
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'cashbook-bank-list',
      //       title: 'menu.cashbook.bankList',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'transfer-list',
      //       title: 'menu.cashbook.transferList',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'account-list',
      //       title: 'menu.cashbook.accountList',
      //     },
      //   ],
      // },
      // {
      //   icon: 'IncomeIcon',
      //   svg: true,
      //   pageName: 'income',
      //   menu: 'finance',
      //   title: 'menu.income.index',
      //   showSubMenu: true,
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'tenant-bill-list',
      //       title: 'menu.income.tenantBillList',
      //     },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'income-list',
      //     //   title: 'menu.income.incomeList',
      //     // },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'income-reconciliation',
      //     //   title: 'menu.income.incomeReconciliation',
      //     // },
      //   ],
      // },
      {
        icon: 'ExpenseIcon',
        svg: true,
        pageName: 'expense',
        menu: 'finance',
        title: 'menu.expense.index',
        showSubMenu: true,
        submenu: [
          {
            icon: '',
            pageName: 'expense-list',
            title: 'menu.expense.expenseList',
          },
          {
            icon: '',
            pageName: 'routine-billing-list',
            title: 'menu.expense.routineBillingList',
          },
        ],
      },
      //     {
      //       icon: '',
      //       pageName: 'payment-list',
      //       title: 'menu.expense.paymentList',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'cost-list',
      //       title: 'menu.expense.costList',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'partner-list',
      //       title: 'menu.expense.partnerList',
      //     },
      //   ],
      // },
      // {
      //   icon: 'ReportCashIcon',
      //   svg: true,
      //   pageName: 'finance-report',
      //   menu: 'finance',
      //   title: 'menu.financeReport.index',
      //   showSubMenu: true,
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'balance-sheet',
      //       title: 'menu.financeReport.balanceSheet',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'income-statement',
      //       title: 'menu.financeReport.incomeStatement',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'cashflow-statement',
      //       title: 'menu.financeReport.cashflowStatement',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'debt-report',
      //       title: 'menu.financeReport.debtReport',
      //     },
      //   ],
      // },
      // END FINANCE
    ],
  }),
})

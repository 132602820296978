<template>
  <TabGroup>
    <div class="intro-y box my-5 pt-2">
      <div
        class="mt-6 flex flex-row border-t border-slate-200/60 px-5 pt-5 dark:border-darkmode-400 lg:mt-4 lg:border-t-0 lg:pt-0"
      >
        <div class="flex justify-center self-center align-middle">
          <ProfileMenuIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
        </div>
        <div class="flex">
          <p class="text-lg">Tambah Hak Akses</p>
        </div>
      </div>
      <Form
        ref="form"
        v-slot="{ errors }"
        :validation-schema="privilegeSchema"
        :initial-errors="{}"
        :initial-touched="{}"
        :validate-on-mount="false"
        @submit="savePrivilege"
      >
        <div class="flex w-full space-x-4 px-5 pt-5">
          <div
            class="cursor-pointer border-b-2 px-4 pb-2"
            :class="[isPrivilegeMode ? 'border-gray-600' : 'border-primary']"
            @click="isPrivilegeMode = false"
          >
            Informasi
          </div>
          <div
            class="cursor-pointer border-b-2 px-4 pb-2"
            :class="[isPrivilegeMode ? 'border-primary' : 'border-gray-600']"
            @click="isPrivilegeMode = true"
          >
            Daftar Hak Akses
          </div>
        </div>
        <div v-show="!isPrivilegeMode" class="w-full px-6 pt-4">
          <div class="flex w-full flex-col space-y-2">
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="name"
                  >
                    <span>
                      Nama Privilege
                      <sup class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>
                <div class="text-left">
                  <Field v-slot="{ field }" v-model="name" name="name">
                    <input
                      id="name"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.name !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      placeholder="Nama Privilege"
                    />
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.name !== undefined">
                      {{ $t(errors.name) }}
                    </template>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="description"
                  >
                    <span>Deskripsi</span>
                  </label>
                </div>
                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="description"
                    name="description"
                  >
                    <textarea
                      id="description"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.description !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                    ></textarea>
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.description !== undefined">
                      {{ $t(errors.description) }}
                    </template>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="isPrivilegeMode">
          <div class="flex w-full p-4">
            <div class="intro-y flex w-1/6 p-5">
              <div class="mt-1 space-y-3">
                <span
                  v-for="menu in menuData"
                  :key="menu.id"
                  class="flex cursor-pointer items-center rounded-md px-3 py-2 font-medium"
                  :class="
                    activeMenu === menu.id
                      ? 'bg-primary text-white'
                      : 'bg-white text-slate-700'
                  "
                  @click="changeActiveMenu(menu)"
                >
                  {{ menu.name }}
                </span>
              </div>
            </div>
            <div class="flex w-5/6 flex-col">
              <p class="space-x-2 font-semibold">
                <span>Izin Akses Fitur</span>
                <span class="capitalize">{{ activeMenuName }}</span>
              </p>
              <p class="text-gray-600">
                Jika akses dinonaktifkan, maka pengguna tidak bisa mengakses
                menu dan seluruh fitur {{ activeMenuName }}
              </p>
              <div class="space-x-2 pt-4">
                <span>Beri izin akses Menu {{ activeMenuName }}</span>

                <div class="form-switch inline">
                  <input
                    v-model="privilegeMenus[activeMenu]"
                    type="checkbox"
                    class="form-check-input"
                  />
                </div>
              </div>
              <div class="pt-4">
                <p class="font-bold">Daftar Fitur</p>
                <div class="flex w-full flex-col space-y-2 pt-2">
                  <div
                    v-for="feature in featureData"
                    :key="feature.id"
                    class="flex w-full flex-col"
                  >
                    <div
                      class="flex w-full items-center space-x-2 rounded-md bg-gray-100 p-2"
                    >
                      <div class="form-switch inline">
                        <input
                          v-model="privilegeMenuFeatures[feature.id]"
                          type="checkbox"
                          class="form-check-input"
                        />
                      </div>
                      <div class="flex w-full items-center justify-between">
                        <div>{{ feature.name }}</div>
                        <div
                          v-if="feature.menu_features.length > 0"
                          class="cursor-pointer"
                          @click="toggleActiveFeature(feature)"
                        >
                          <span>Detail</span>

                          <ChevronUpIcon
                            v-if="activeMenuFeature === feature.id"
                            class="ml-0.5 inline h-8 w-8"
                          />
                          <ChevronDownIcon
                            v-else
                            class="ml-0.5 inline h-8 w-8"
                          />
                        </div>
                      </div>
                    </div>
                    <TableComponent
                      v-if="activeMenuFeature === feature.id"
                      wrapper-class="py-2"
                      wrapper-table-class="mt-0"
                      :title="$t('page.report.orderDetail.orderDetailTable')"
                      :show-title="false"
                      :header="featureHeaders"
                      :is-show-search="false"
                      :is-not-empty="feature.menu_features.length > 0"
                      :is-show-pagination="false"
                    >
                      <tr
                        v-for="menu_feature in feature.menu_features"
                        :key="menu_feature.id"
                      >
                        <td class="text-left">{{ menu_feature.name }}</td>
                        <td class="text-center">
                          <input
                            v-model="featureReads[menu_feature.id]"
                            class="accent-primary checked:bg-primary focus:bg-primary focus:ring-primary"
                            type="checkbox"
                            role="switch"
                          />
                        </td>
                        <td class="text-center">
                          <input
                            v-model="featureWrites[menu_feature.id]"
                            class="accent-primary checked:bg-primary focus:bg-primary focus:ring-primary"
                            type="checkbox"
                            role="switch"
                          />
                        </td>
                        <td class="text-center">
                          <input
                            v-model="featureEdits[menu_feature.id]"
                            class="accent-primary checked:bg-primary focus:bg-primary focus:ring-primary"
                            type="checkbox"
                            role="switch"
                          />
                        </td>
                        <td class="text-center">
                          <input
                            v-model="featureDeletes[menu_feature.id]"
                            class="accent-primary checked:bg-primary focus:bg-primary focus:ring-primary"
                            type="checkbox"
                            role="switch"
                          />
                        </td>
                      </tr>
                    </TableComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="validate-form my-6 px-5">
          <div class="flex w-full justify-end py-5">
            <button
              type="button"
              class="btn btn-outline-primary mr-2 w-24"
              @click="
                isPrivilegeMode
                  ? (isPrivilegeMode = false)
                  : $router.push({ name: 'employee-privilege' })
              "
            >
              <span v-if="isPrivilegeMode">{{ $t('button.back') }}</span>
              <span v-else>{{ $t('button.cancel') }}</span>
            </button>
            <button type="submit" class="btn btn-primary py-3 px-4">
              <LoadingIcon
                v-if="isLoading && isPrivilegeMode"
                icon="spinning-circles"
                color="white"
                class="mr-2 h-4 w-4"
              />
              <span v-if="isPrivilegeMode">{{ $t('button.save') }}</span>
              <span v-else>{{ $t('button.next') }}</span>
            </button>
          </div>
        </div>
      </Form>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </TabGroup>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import * as Yup from 'yup'

import globalMixin from '@/mixins/global.js'
import roleMixin from '@/mixins/role.js'
import menuMixin from '@/mixins/menu.js'
import privilegeMixin from '@/mixins/privilege.js'

import { Form, Field } from 'vee-validate'

import TableComponent from '@/components/TableComponent.vue'

import ProfileMenuIcon from '@/assets/svg/menu/user.svg'

export default {
  components: {
    Form,
    Field,
    TableComponent,
    ProfileMenuIcon,
  },
  mixins: [globalMixin, roleMixin, menuMixin, privilegeMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    return {
      t,
      n,
      store,
      route,
      router,
    }
  },
  data: () => ({
    isPrivilegeMode: false,
    name: '',
    description: '',
    tenantId: null,
    instituteId: null,

    featureHeaders: [
      {
        item: 'page.privilege.tableHeaders.subFeature',
        customClass: 'text-left',
      },
      {
        item: 'page.privilege.tableHeaders.show',
        customClass: 'text-center',
      },
      {
        item: 'page.privilege.tableHeaders.add',
        customClass: 'text-center',
      },
      {
        item: 'page.privilege.tableHeaders.edit',
        customClass: 'text-center',
      },
      {
        item: 'page.privilege.tableHeaders.delete',
        customClass: 'text-center',
      },
    ],

    // Form Validation
    privilegeSchema: Yup.object().shape({
      name: Yup.string()
        .typeError('formError.name.required')
        .required('formError.name.required')
        .matches(/^(?!\s+$).*/, 'formError.name.required'),
    }),
  }),
  mounted() {
    if (this.activeUserStore !== null) {
      this.tenantId = this.activeUserStore.tenant.id
      this.instituteId = this.activeUserStore.institute.id
    }
    this.getAllMenu().then(() => {
      this.getAllMenuFeature()
    })
  },
  methods: {
    changeActiveMenu(menu) {
      this.activeMenu = menu.id
      this.activeMenuName = menu.name
      const featureDataActiveMenu = this.featureDataActiveMenu.find(
        (item) => item.id === menu.id
      )
      if (featureDataActiveMenu !== undefined) {
        this.featureData = featureDataActiveMenu.values
      } else {
        this.getAllMenuFeature()
      }
    },
    toggleActiveFeature(feature) {
      if (this.activeMenuFeature === feature.id) {
        this.activeMenuFeature = ''
      } else {
        this.activeMenuFeature = feature.id
      }
    },
    savePrivilege() {
      if (this.isPrivilegeMode) {
        const paramPrivilege = {
          institute_id: this.instituteId,
          tenant_id: this.tenantId,
          name: this.name,
          description: this.description,
        }
        this.savePrivilegeAPI(paramPrivilege).then(() => {
          const privilegeMenus = []
          const privilegeMenuFeatures = []

          this.privilegeMenus.forEach((menu, key) => {
            privilegeMenus.push({
              id: key,
              view: true,
              add: true,
              edit: true,
              delete: true,
            })
          })

          this.privilegeMenuFeatures.forEach((feature, key) => {
            privilegeMenuFeatures.push({
              id: key,
              view: true,
              add: true,
              edit: true,
              delete: true,
            })
          })

          const readKeys = this.featureReads.keys()
          const writerKeys = this.featureWrites.keys()
          const editKeys = this.featureEdits.keys()
          const deleteKeys = this.featureDeletes.keys()

          const subPrivilegeMenuFeatures = this.uniqueList([
            ...readKeys,
            ...writerKeys,
            ...editKeys,
            ...deleteKeys,
          ])

          subPrivilegeMenuFeatures.forEach((feature) => {
            privilegeMenuFeatures.push({
              id: feature,
              view:
                this.featureWrites[feature] !== undefined
                  ? this.featureWrites[feature]
                  : false,
              add:
                this.featureEdits[feature] !== undefined
                  ? this.featureEdits[feature]
                  : false,
              edit:
                this.featureReads[feature] !== undefined
                  ? this.featureReads[feature]
                  : false,
              delete:
                this.featureDeletes[feature] !== undefined
                  ? this.featureDeletes[feature]
                  : false,
            })
          })

          const paramPrivilegeMenu = {
            role_privilege_id: this.activePrivilegeID,
            menu: privilegeMenus,
            menu_feature: privilegeMenuFeatures,
          }

          this.savePrivilegeMenuMappingAPI(paramPrivilegeMenu).then(() => {
            this.resetData()
          })
        })
      } else {
        this.isPrivilegeMode = true
      }
    },
    resetData(afterSave = true) {
      if (afterSave) {
        this.isLoadingSave = false
        this.isLoading = false
        this.$router.push({
          name: 'employee-privilege',
          params: { slug: this.$route.params.slug },
          query: { lang: this.$route.query.lang },
        })
      }
    },
  },
}
</script>

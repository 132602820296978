<template>
  <Chart
    :type="chartType"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useDarkModeStore } from '@/stores/dark-mode'
import { useColorSchemeStore } from '@/stores/color-scheme'
import { colors } from '@/utils/colors'

const props = defineProps({
  chartType: {
    type: String,
    default: 'line',
  },
  width: {
    type: [Number, String],
    default: 'auto',
  },
  height: {
    type: [Number, String],
    default: 'auto',
  },
  itemName: {
    type: [Array, String],
    default: '',
  },
  label: {
    type: Array,
    default: () => {
      return []
    },
  },
  customColor: {
    type: Array,
    default: () => {
      return []
    },
  },
  data: {
    type: Array,
    default: () => {
      return []
    },
  },
  showLegend: {
    type: Boolean,
    default: false,
  },
})

const darkMode = computed(() => useDarkModeStore().darkMode)
const colorScheme = computed(() => useColorSchemeStore().colorScheme)

function getColor(index) {
  switch (index) {
    case 0:
      return colors.primary()
    case 1:
      return colors.secondary()
    case 2:
      return colors.success()
    case 3:
      return colors.info()
    case 4:
      return colors.warning()
    case 5:
      return colors.pending()
    case 6:
      return colors.danger()
    case 7:
      return colors.light()
    case 8:
      return colors.dark()
    default:
      return colors.primary()
  }
}

function generateDataset() {
  let dataset = []
  if (Array.isArray(props.itemName)) {
    props.itemName.forEach((item, index) =>
      dataset.push({
        label: item,
        data: props.data[index],
        borderWidth: 2,
        borderColor: colorScheme.value
          ? props.customColor.length > 0
            ? props.customColor[index]
            : getColor(index)
          : '',
        backgroundColor:
          props.chartType !== 'bar' ? 'transparent' : getColor(index),
        pointBorderColor:
          props.customColor.length > 0
            ? props.customColor[index]
            : getColor(index),
        tension: 0.4,
      })
    )
  } else {
    dataset.push({
      label: props.itemName,
      data: props.data,
      borderWidth: 2,
      borderColor: colorScheme.value ? colors.primary(0.8) : '',
      backgroundColor:
        props.chartType !== 'bar' ? 'transparent' : colors.primary(),
      pointBorderColor: colors.primary(),
      tension: 0.4,
    })
  }
  return dataset
}

const data = computed(() => {
  return {
    labels: props.label,
    datasets: generateDataset(),
  }
})

const options = computed(() => {
  return {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: props.showLegend,
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 12,
          },
          color: colors.slate['500'](1),
        },
        grid: {
          display: true,
          drawBorder: true,
        },
      },
      y: {
        ticks: {
          font: {
            size: 12,
          },
          color: colors.slate['500'](1),
          callback: function (value) {
            return value
          },
        },
        grid: {
          color: darkMode.value
            ? colors.slate['500'](0.3)
            : colors.slate['300'](),
          borderDash: [2, 2],
          drawBorder: true,
        },
      },
    },
  }
})
</script>

<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
  </div>

  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Table List -->
        <div class="col-span-12 mt-6 bg-white p-5 dark:bg-slate-900 md:p-10">
          <div class="intro-y block items-center sm:flex">
            <div class="flex align-middle">
              <div class="flex justify-center self-center align-middle">
                <BookIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
              </div>
              <div class="flex">
                <div class="flex self-center align-middle">
                  <h2 class="mr-3 truncate text-lg font-medium">
                    {{ $t('page.tableManagement.index') }}
                  </h2>
                </div>
              </div>
            </div>
            <div class="mt-3 flex items-center space-x-2 sm:ml-auto sm:mt-0">
              <template v-if="!isEdit">
                <ButtonComponent
                  :title="$t('action.edit')"
                  wrapper-class="bg-white text-black dark:bg-gray-100 dark:text-black border-black dark:border-white hover:opacity-80"
                  @click="isEdit = true"
                >
                  <template #icon>
                    <EditIcon class="mr-1 h-4 w-4" />
                  </template>
                </ButtonComponent>
                <ButtonComponent
                  :title="$t('page.tableManagement.addTable')"
                  @click="showModalAddTable"
                >
                  <template #icon> + </template>
                </ButtonComponent>
              </template>
              <template v-else>
                <ButtonComponent
                  :title="$t('button.cancel')"
                  wrapper-class="bg-white text-black dark:bg-gray-100 dark:text-black border-black dark:border-white hover:opacity-80"
                  @click="cancelChange"
                >
                </ButtonComponent>
                <ButtonComponent
                  :title="$t('button.saveChange')"
                  @click="saveChange"
                >
                </ButtonComponent>
              </template>
            </div>
          </div>
          <div
            v-for="(item, key, index) in tableData"
            :key="index"
            class="mt-8 flex w-full flex-col space-y-4"
          >
            <div class="relative flex w-full border-b border-gray-400">
              <div
                class="absolute -top-3 left-0 bg-white px-2 text-gray-600 dark:bg-dark dark:text-white"
              >
                <span v-if="key == 'null'">
                  {{ $t('page.tableManagement.withoutSegmentation') }}
                </span>
                <span v-else>
                  {{ $t('page.tableManagement.segmentation', { segmen: key }) }}
                </span>
              </div>
            </div>
            <div class="grid w-full grid-cols-3 gap-2 md:grid-cols-7">
              <TableCard
                v-for="table in item"
                :key="table.id"
                :table="table"
                :is-edit="isEdit"
                :segment="key"
                @do-delete="deleteTable"
                @change-status="changeStatusTable"
              />
            </div>
          </div>
        </div>
        <!-- END: Table List -->

        <!-- BEGIN: Modal -->
        <Modal
          :show="showModalAdd"
          size="modal-lg"
          @hidden="showModalAdd = false"
        >
          <ModalBody class="p-4">
            <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
              <div class="flex flex-col space-y-3 border-b pb-2">
                <div class="flex justify-between">
                  <p class="text-base font-medium text-gray-500">
                    <span>
                      {{ $t('page.tableManagement.input.add') }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="flex flex-col space-y-4">
                <div class="flex items-center justify-between space-x-2">
                  <label
                    class="block text-left text-xs font-bold text-gray-500 md:mb-0"
                    for="table-multiple"
                  >
                    {{ $t('page.tableManagement.input.addMultiple') }}
                  </label>
                  <label
                    class="relative inline-flex cursor-pointer items-center"
                  >
                    <input
                      v-model="is_multiple_table_number"
                      type="checkbox"
                      class="peer sr-only"
                    />
                    <div
                      class="peer h-5 w-9 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-0 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-0"
                    ></div>
                  </label>
                </div>
                <div class="flex items-center justify-between space-x-2">
                  <label
                    class="block text-left text-xs font-bold text-gray-500 md:mb-0"
                    for="table-segmented"
                  >
                    {{ $t('page.tableManagement.input.segmentedTable') }}
                  </label>
                  <label
                    class="relative inline-flex cursor-pointer items-center"
                  >
                    <input
                      v-model="is_segmented_table"
                      type="checkbox"
                      class="peer sr-only"
                    />
                    <div
                      class="peer h-5 w-9 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-0 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-0"
                    ></div>
                  </label>
                </div>
                <div
                  v-for="(item, key) in total_segmentation"
                  :key="key"
                  class="grid grid-cols-1 gap-2 md:grid-cols-3"
                >
                  <div class="flex flex-col space-y-2">
                    <label
                      class="block text-left text-xs font-bold text-gray-500 md:mb-0"
                      for="table-segmented"
                    >
                      {{ $t('page.tableManagement.input.tableSegmentation') }}
                    </label>
                    <input
                      id="segmentation-key"
                      v-model="segmentation[key]"
                      required="required"
                      :disabled="!is_segmented_table"
                      class="appearance-none rounded border-2 py-2 px-2 text-sm leading-tight focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        is_segmented_table
                          ? 'cursor-pointer text-gray-700'
                          : 'cursor-not-allowed bg-gray-200 text-gray-300',
                      ]"
                      type="text"
                      :placeholder="
                        $t('page.tableManagement.input.tableSegmentation')
                      "
                    />
                  </div>
                  <div class="flex flex-col space-y-2">
                    <label
                      class="block text-left text-xs font-bold text-gray-500 md:mb-0"
                      for="table-segmented-from"
                    >
                      {{ $t('page.tableManagement.input.from') }}
                    </label>
                    <input
                      id="table-from"
                      v-model="from[key]"
                      required="required"
                      class="appearance-none rounded border-2 py-2 px-2 text-sm leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      type="number"
                      :placeholder="$t('page.tableManagement.input.from')"
                    />
                  </div>
                  <div class="flex flex-col space-y-2">
                    <label
                      class="block text-left text-xs font-bold text-gray-500 md:mb-0"
                      for="table-segmented-to"
                    >
                      {{ $t('page.tableManagement.input.to') }}
                    </label>
                    <input
                      id="table-to"
                      v-model="to[key]"
                      required="required"
                      :disabled="!is_multiple_table_number"
                      class="appearance-none rounded border-2 py-2 px-2 text-sm leading-tight focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        is_multiple_table_number
                          ? 'cursor-pointer text-gray-700'
                          : 'cursor-not-allowed bg-gray-200 text-gray-300',
                      ]"
                      type="number"
                      :placeholder="$t('page.tableManagement.input.to')"
                    />
                  </div>
                </div>
                <span
                  class="cursor-pointer self-start font-medium text-gray-700 hover:text-gray-900"
                  @click="total_segmentation = total_segmentation + 1"
                >
                  + {{ $t('page.tableManagement.input.addSegmentation') }}
                </span>
              </div>
            </div>
            <div class="flex justify-end space-x-2 px-5 pb-8">
              <button
                type="button"
                class="btn w-24"
                @click="showModalAdd = false"
              >
                {{ $t('button.cancel') }}
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click="saveTable"
              >
                {{ $t('button.save') }}
              </button>
            </div>
          </ModalBody>
        </Modal>
        <!-- END: Modal -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->
      </div>
    </div>

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import tableMixin from '@/mixins/table.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import TableCard from '@/components/card/TableCard.vue'

import BookIcon from '@/assets/svg/menu/book.svg'

export default {
  name: 'TableManagementPage',
  components: {
    Breadcrumb,
    ButtonComponent,
    TableCard,

    BookIcon,
  },
  mixins: [globalMixin, tableMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    return {
      t,
      n,
      store,
      route,
      router,
    }
  },
  data: () => ({
    showModalAdd: false,
    isEdit: false,
    is_multiple_table_number: false,
    is_segmented_table: false,
    total_segmentation: 1,
    segmentation: [],
    from: [],
    to: [],
  }),
  mounted() {
    this.getAllTable(true, true)
  },
  methods: {
    showModalAddTable() {
      this.showModalAdd = true
      this.resetForm()
    },
    resetForm() {
      this.is_multiple_table_number = false
      this.is_segmented_table = false
      this.total_segmentation = 1
      this.segmentation = []
      this.from = []
      this.to = []
    },
    resetData() {
      this.showModalAdd = false

      this.resetForm()
    },
    cancelChange() {
      this.isEdit = false
      this.tableData = this.oldTableData
    },
    saveChange() {
      this.isEdit = false
      if (this.deletedId.length > 0) {
        this.deleteTableAPI(this.deletedId.toString())
      }
      if (this.changeStatusData.length > 0) {
        this.updateStatusAPI(this.changeStatusData)
      }
    },
    deleteTable(event) {
      this.deletedId.push(event.id)
      this.tableData[event.segment] = this.tableData[event.segment].filter(
        (item) => item.id !== event.id
      )
    },
    changeStatusTable(event) {
      this.changeStatusData = this.changeStatusData.filter(
        (item) => item.id !== event.id
      )
      this.changeStatusData.push(event)
    },
    saveTable() {
      if (this.isLoadingSave || this.from.length <= 0) return

      for (let i = 0; i < this.from.length; i++) {
        // if (
        //   this.segmentation[i] === '' ||
        //   this.from[i] === '' ||
        //   this.to[i] === ''
        // ) {
        //   continue
        // }
        const params = {
          is_multiple_table_number: this.is_multiple_table_number,
          is_segmented_table: this.is_segmented_table,
          segmentation: this.segmentation[i],
          from: this.from[i],
          to: this.to[i],
          number_of_person: 2,
        }
        this.isLoadingSave = true

        this.saveTableAPI(params)
      }
    },
  },
}
</script>

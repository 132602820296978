<template>
  <TabGroup>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box my-5 pt-2">
      <div
        class="mt-6 flex flex-row border-t border-slate-200/60 px-5 pt-5 dark:border-darkmode-400 lg:mt-4 lg:border-t-0 lg:pt-0"
      >
        <div class="flex justify-center self-center align-middle">
          <ProfileMenuIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
        </div>
        <div class="flex">
          <p class="text-lg">{{ $t('menu.profile.account') }}</p>
        </div>
      </div>
      <div class="flex w-full p-4">
        <div class="intro-y flex w-1/6 p-5">
          <div class="mt-1 space-y-3">
            <span
              class="flex cursor-pointer items-center rounded-md px-3 py-2 font-medium"
              :class="
                activeMenu === 'profile'
                  ? 'bg-primary text-white'
                  : 'bg-white text-slate-700'
              "
              @click="activeMenu = 'profile'"
            >
              <ProfileMenuIcon class="mr-2 h-4 w-4" />
              {{ $t('page.profile.account') }}
            </span>
            <span
              class="flex cursor-pointer items-center rounded-md px-3 py-2 font-medium"
              :class="
                activeMenu === 'address'
                  ? 'bg-primary text-white'
                  : 'bg-white text-slate-700'
              "
              @click="activeMenu = 'address'"
            >
              <UsersIcon class="mr-2 h-4 w-4" />
              {{ $t('page.profile.addressInformation') }}
            </span>
            <span
              class="flex cursor-pointer items-center rounded-md px-3 py-2 font-medium"
              :class="
                activeMenu === 'password'
                  ? 'bg-primary text-white'
                  : 'bg-white text-slate-700'
              "
              @click="activeMenu = 'password'"
            >
              <LockIcon class="mr-2 h-4 w-4" />
              {{ $t('page.profile.changePassword') }}
            </span>
          </div>
        </div>
        <div class="intro-y flex w-5/6 p-5">
          <form
            v-if="activeMenu === 'profile'"
            class="validate-form w-full px-5"
            @submit.prevent="updateProfile"
          >
            <div
              class="-mx-5 flex flex-col pb-5 dark:border-darkmode-400 lg:flex-row"
            >
              <div class="flex w-full flex-col px-5 lg:mt-0 lg:justify-start">
                <div
                  class="flex items-center truncate py-4 sm:whitespace-normal"
                >
                  <p class="text-xl font-bold">
                    {{ $t('page.profile.account') }}
                  </p>
                </div>
                <div
                  class="image-fit relative h-20 w-20 flex-none lg:h-24 lg:w-24"
                >
                  <template v-if="avatarPreview != ''">
                    <ImageLoader
                      :image="avatarPreview"
                      :alt="activeUser.full_name"
                      rounded-class="rounded-full"
                    />
                  </template>
                  <template v-else>
                    <ImageLoader
                      :image="activeUser.avatar"
                      :alt="activeUser.full_name"
                      rounded-class="rounded-full"
                    />
                  </template>

                  <div
                    class="absolute bottom-0 right-0 mb-1 flex cursor-pointer items-center justify-center rounded-full bg-primary p-1 lg:p-2"
                    @click="selectFile()"
                  >
                    <input
                      id="hidden-input"
                      type="file"
                      accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                      class="hidden"
                      @change="handleFileUpload($event)"
                    />
                    <CameraIcon class="h-3 w-3 text-white lg:h-4 lg:w-4" />
                  </div>
                </div>

                <div class="intro-x lg:mt-4">
                  <div class="flex w-full flex-col lg:flex-row">
                    <div class="input-form mt-3 w-full">
                      <label
                        for="form-fullName"
                        class="form-label flex w-full flex-col sm:flex-row"
                      >
                        {{ $t('formLabel.fullName') }}*
                      </label>
                      <input
                        id="form-fullName"
                        v-model.trim="validate.fullName.$model"
                        type="text"
                        name="fullName"
                        class="intro-x login__input form-control block py-3 px-4"
                        :class="{ 'border-danger': validate.fullName.$error }"
                        :placeholder="$t('formLabel.fullName')"
                      />
                      <template v-if="validate.fullName.$error">
                        <div
                          v-for="(error, index) in validate.fullName.$errors"
                          :key="index"
                          class="mt-2 text-danger"
                        >
                          {{ $t(error.$message) }}
                        </div>
                      </template>
                    </div>
                    <div class="input-form mt-3 w-full lg:ml-4">
                      <label
                        for="form-email"
                        class="form-label flex w-full flex-col sm:flex-row"
                      >
                        {{ $t('formLabel.email') }}
                      </label>
                      <input
                        id="form-email"
                        v-model.trim="validate.email.$model"
                        readonly
                        type="email"
                        name="email"
                        class="intro-x login__input form-control block py-3 px-4"
                        :class="{ 'border-danger': validate.email.$error }"
                        :placeholder="$t('formLabel.email')"
                      />
                      <template v-if="validate.email.$error">
                        <div
                          v-for="(error, index) in validate.email.$errors"
                          :key="index"
                          class="mt-2 text-danger"
                        >
                          {{ $t(error.$message) }}
                        </div>
                      </template>
                    </div>
                  </div>

                  <div class="input-form mt-3">
                    <label
                      for="form-phone"
                      class="form-label flex w-full flex-col sm:flex-row"
                    >
                      {{ $t('formLabel.phone') }}*
                    </label>
                    <input
                      id="form-phone"
                      v-model="validate.phone.$model"
                      type="text"
                      name="phone"
                      class="intro-x login__input form-control block py-3 px-4"
                      :class="{ 'border-danger': validate.phone.$error }"
                      :placeholder="$t('formLabel.phone')"
                    />
                    <template v-if="validate.phone.$error">
                      <div
                        v-for="(error, index) in validate.phone.$errors"
                        :key="index"
                        class="mt-2 text-danger"
                      >
                        {{ $t(error.$message) }}
                      </div>
                    </template>
                  </div>
                </div>
                <div
                  class="mt-10 flex items-center truncate py-2 sm:whitespace-normal"
                >
                  <p class="text-lg font-bold">
                    {{ $t('page.profile.accessInformation') }}
                  </p>
                </div>
                <div class="flex w-full flex-col lg:flex-row">
                  <div class="input-form mt-3 w-full lg:mt-0">
                    <label
                      for="form-accessRight"
                      class="form-label flex w-full flex-col sm:flex-row"
                    >
                      {{ $t('formLabel.accessRight') }}
                    </label>
                    <input
                      id="form-accessRight"
                      v-model.trim="formData.accessRight"
                      readonly
                      type="text"
                      name="accessRight"
                      class="intro-x login__input form-control block py-3 px-4"
                    />
                  </div>
                  <div class="input-form mt-3 w-full lg:mt-0 lg:ml-4">
                    <label
                      for="form-pin"
                      class="form-label flex w-full flex-col sm:flex-row"
                    >
                      {{ $t('formLabel.pin') }}
                    </label>
                    <input
                      id="form-pin"
                      v-model.trim="validate.pin.$model"
                      type="number"
                      name="pin"
                      class="intro-x login__input form-control block py-3 px-4"
                      :placeholder="$t('formLabel.pin')"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="flex w-full justify-end py-5">
              <button type="submit" class="btn btn-primary py-3 px-4">
                <LoadingIcon
                  v-if="isLoading"
                  icon="spinning-circles"
                  color="white"
                  class="mr-2 h-4 w-4"
                />
                {{ $t('button.saveChanges') }}
              </button>
            </div>
          </form>

          <form
            v-if="activeMenu === 'address'"
            class="validate-form w-full px-5"
            @submit.prevent="updateProfileAddress"
          >
            <div
              class="-mx-5 flex flex-col pb-5 dark:border-darkmode-400 lg:flex-row"
            >
              <div class="flex w-full flex-col px-5 lg:justify-start">
                <div
                  class="my-4 flex items-center truncate sm:whitespace-normal"
                >
                  <p class="text-lg font-bold">
                    {{ $t('page.profile.addressInformation') }}
                  </p>
                </div>
                <div class="input-form mt-3 w-full lg:mt-0">
                  <label
                    for="form-address"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.address') }}*
                  </label>
                  <input
                    id="form-address"
                    v-model.trim="validateAddress.address.$model"
                    type="text"
                    name="address"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validateAddress.address.$error }"
                    :placeholder="$t('formLabel.address')"
                  />
                  <template v-if="validateAddress.address.$error">
                    <div
                      v-for="(error, index) in validateAddress.address.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>

                <div class="input-form mt-3 w-full">
                  <label
                    for="form-addressTwo"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.address') }}({{ $t('general.optional') }})
                  </label>
                  <input
                    id="form-addressTwo"
                    v-model.trim="validateAddress.addressTwo.$model"
                    type="text"
                    name="addressTwo"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{
                      'border-danger': validateAddress.addressTwo.$error,
                    }"
                    :placeholder="$t('formLabel.address')"
                  />
                  <template v-if="validateAddress.addressTwo.$error">
                    <div
                      v-for="(error, index) in validateAddress.addressTwo
                        .$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>

                <div class="flex w-full flex-col lg:flex-row">
                  <div class="input-form mt-3 w-full">
                    <label
                      for="form-address-province"
                      class="form-label flex w-full flex-col sm:flex-row"
                    >
                      {{ $t('formLabel.province') }}*
                    </label>
                    <div class="mt-2">
                      <Multiselect
                        v-model="province"
                        value="province_code"
                        label="province_name"
                        track-by="province_name"
                        :placeholder="$t('formLabel.select.province')"
                        :options="provinceList"
                        :searchable="true"
                        :classes="multiSelectClasses"
                        @change="changeAddress('province')"
                      />
                    </div>
                  </div>
                  <div class="input-form mt-3 w-full lg:ml-4">
                    <label
                      for="form-address-city"
                      class="form-label flex w-full flex-col sm:flex-row"
                    >
                      {{ $t('formLabel.city') }}*
                    </label>
                    <div class="mt-2">
                      <Multiselect
                        v-model="city"
                        label="city_name"
                        track-by="city_name"
                        :placeholder="$t('formLabel.select.city')"
                        :options="cityList"
                        :searchable="true"
                        :classes="multiSelectClasses"
                        :disabled="cityDisabled === true"
                        @change="changeAddress('city')"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex w-full flex-col lg:flex-row">
                  <div class="input-form mt-3 w-full">
                    <label
                      for="form-address-district"
                      class="form-label flex w-full flex-col sm:flex-row"
                    >
                      {{ $t('formLabel.district') }}*
                    </label>
                    <div class="mt-2">
                      <Multiselect
                        v-model="district"
                        label="district_name"
                        track-by="district_name"
                        :placeholder="$t('formLabel.select.district')"
                        :options="districtList"
                        :searchable="true"
                        :classes="multiSelectClasses"
                        :disabled="districtDisabled === true"
                        @change="changeAddress('district')"
                      />
                    </div>
                  </div>
                  <div class="input-form mt-3 w-full lg:ml-4">
                    <label
                      for="form-address-village"
                      class="form-label flex w-full flex-col sm:flex-row"
                    >
                      {{ $t('formLabel.village') }}*
                    </label>
                    <div class="mt-2">
                      <Multiselect
                        v-model="village"
                        label="village_name"
                        track-by="village_name"
                        :placeholder="$t('formLabel.select.village')"
                        :options="villageList"
                        :searchable="true"
                        :classes="multiSelectClasses"
                        :disabled="villageDisabled === true"
                        @change="changeAddress('village')"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex w-full flex-col lg:flex-row">
                  <div class="input-form mt-3 w-full">
                    <label
                      for="form-address-postal-code"
                      class="form-label flex w-full flex-col sm:flex-row"
                    >
                      {{ $t('formLabel.postalCode') }}*
                    </label>
                    <div class="mt-2">
                      <input
                        id="form-postalCode"
                        v-model.trim="validateAddress.postalCode.$model"
                        type="number"
                        name="postalCode"
                        class="intro-x login__input form-control block py-3 px-4"
                        :class="{
                          'border-danger': validateAddress.postalCode.$error,
                        }"
                        :placeholder="$t('formLabel.address')"
                      />
                      <template v-if="validateAddress.postalCode.$error">
                        <div
                          v-for="(error, index) in validateAddress.postalCode
                            .$errors"
                          :key="index"
                          class="mt-2 text-danger"
                        >
                          {{ $t(error.$message) }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex w-full justify-end py-5">
              <button type="submit" class="btn btn-primary py-3 px-4">
                <LoadingIcon
                  v-if="isLoading"
                  icon="spinning-circles"
                  color="white"
                  class="mr-2 h-4 w-4"
                />
                {{ $t('button.saveChanges') }}
              </button>
            </div>
          </form>

          <form
            v-if="activeMenu === 'password'"
            class="validate-form w-full px-5"
            @submit.prevent="updatePassword"
          >
            <div
              class="-mx-5 flex flex-col pb-5 dark:border-darkmode-400 lg:flex-row"
            >
              <div class="flex w-full flex-col px-5 lg:justify-start">
                <div
                  class="my-4 flex items-center truncate sm:whitespace-normal"
                >
                  <p class="text-lg font-bold">
                    {{ $t('page.profile.changePassword') }}
                  </p>
                </div>
                <div class="input-form">
                  <label
                    for="form-oldPassword"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.oldPassword') }}
                  </label>
                  <div class="relative">
                    <input
                      id="form-old-password"
                      v-model.trim="validatePassword.oldPassword.$model"
                      :type="showPassword ? 'text' : 'password'"
                      name="oldPassword"
                      autocomplete="off"
                      class="login__input form-control block py-3 px-4"
                      :class="{
                        'border-danger': validatePassword.oldPassword.$error,
                      }"
                      :placeholder="$t('formLabel.oldPassword')"
                    />

                    <div
                      class="absolute inset-0 left-auto flex cursor-pointer items-center"
                    >
                      <span class="px-3 text-sm font-medium text-slate-400">
                        <EyeStrikeIcon
                          v-if="showPassword"
                          class="h-6 w-6"
                          @click="showPassword = false"
                        />
                        <EyeIcon
                          v-else
                          class="h-6 w-6"
                          @click="showPassword = true"
                        />
                      </span>
                    </div>
                  </div>
                  <template v-if="validatePassword.oldPassword.$error">
                    <div
                      v-for="(error, index) in validatePassword.oldPassword
                        .$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label
                    for="newPassword"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.newPassword') }}
                  </label>
                  <div class="relative">
                    <input
                      id="newPassword"
                      v-model.trim="validatePassword.newPassword.$model"
                      :type="showNewPassword ? 'text' : 'password'"
                      name="newPassword"
                      autocomplete="off"
                      class="login__input form-control block py-3 px-4"
                      :class="{
                        'border-danger': validatePassword.newPassword.$error,
                      }"
                      :placeholder="$t('formLabel.newPassword')"
                    />

                    <div
                      class="absolute inset-0 left-auto flex cursor-pointer items-center"
                    >
                      <span class="px-3 text-sm font-medium text-slate-400">
                        <EyeStrikeIcon
                          v-if="showNewPassword"
                          class="h-6 w-6"
                          @click="showNewPassword = false"
                        />
                        <EyeIcon
                          v-else
                          class="h-6 w-6"
                          @click="showNewPassword = true"
                        />
                      </span>
                    </div>
                  </div>
                  <template v-if="validatePassword.newPassword.$error">
                    <div
                      v-for="(error, index) in validatePassword.newPassword
                        .$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3">
                  <label
                    for="form-newPasswordConfirmation"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.newPasswordConfirmation') }}
                  </label>
                  <div class="relative">
                    <input
                      id="form-new-confirm-password"
                      v-model.trim="
                        validatePassword.newPasswordConfirmation.$model
                      "
                      :type="showConfirmNewPassword ? 'text' : 'password'"
                      name="newPasswordConfirmation"
                      autocomplete="off"
                      class="login__input form-control block py-3 px-4"
                      :class="{
                        'border-danger':
                          validatePassword.newPasswordConfirmation.$error,
                      }"
                      :placeholder="$t('formLabel.newPasswordConfirmation')"
                    />

                    <div
                      class="absolute inset-0 left-auto flex cursor-pointer items-center"
                    >
                      <span class="px-3 text-sm font-medium text-slate-400">
                        <EyeStrikeIcon
                          v-if="showConfirmNewPassword"
                          class="h-6 w-6"
                          @click="showConfirmNewPassword = false"
                        />
                        <EyeIcon
                          v-else
                          class="h-6 w-6"
                          @click="showConfirmNewPassword = true"
                        />
                      </span>
                    </div>
                  </div>
                  <template
                    v-if="validatePassword.newPasswordConfirmation.$error"
                  >
                    <div
                      v-for="(error, index) in validatePassword
                        .newPasswordConfirmation.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="flex w-full justify-end py-5">
              <button type="submit" class="btn btn-primary py-3 px-4">
                <LoadingIcon
                  v-if="isLoading"
                  icon="spinning-circles"
                  color="white"
                  class="mr-2 h-4 w-4"
                />
                {{ $t('button.saveChanges') }}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div
        id="success-notification-content"
        class="toastify-content flex hidden"
      >
        <CheckCircleIcon class="text-success" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('formInfo.saveChangesSuccess') }}</div>
          <div class="mt-1 text-slate-500">
            {{ responseMessage }}
          </div>
        </div>
      </div>
      <!-- END: Success Notification Content -->
      <!-- BEGIN: Failed Notification Content -->
      <div
        id="failed-notification-content"
        class="toastify-content flex hidden"
      >
        <XCircleIcon class="text-danger" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('formInfo.saveChangesFailed') }}</div>
          <div class="mt-1 text-slate-500">{{ responseMessage }}</div>
        </div>
      </div>
    </div>
  </TabGroup>
</template>

<script>
import { computed, onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  url,
  integer,
  sameAs,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import Toastify from 'toastify-js'
import globalMixin from '@/mixins/global.js'
import Multiselect from '@vueform/multiselect'

import ProfileMenuIcon from '@/assets/svg/menu/user.svg'
import EyeIcon from '@/assets/svg/eye.svg'
import EyeStrikeIcon from '@/assets/svg/eye-strike.svg'

import ImageLoader from '@/components/image-loader/Main.vue'

export default {
  components: {
    Multiselect,
    ProfileMenuIcon,
    ImageLoader,
    EyeIcon,
    EyeStrikeIcon,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const avatarPreview = ref('')
    const imageFile = ref(null)

    const isLoading = ref(false)
    const responseMessage = ref('')

    const activeMenu = ref('profile')

    const provincePage = ref(1)
    const cityPage = ref(1)
    const districtPage = ref(1)
    const villagePage = ref(1)

    const province = ref(null)
    const city = ref(null)
    const district = ref(null)
    const village = ref(null)

    const cityDisabled = ref(true)
    const districtDisabled = ref(true)
    const villageDisabled = ref(true)

    const provinceList = ref([])
    const cityList = ref([])
    const districtList = ref([])
    const villageList = ref([])

    const showPassword = ref(false)
    const showNewPassword = ref(false)
    const showConfirmNewPassword = ref(false)

    const formData = reactive({
      fullName: '',
      email: '',
      phone: '',
      password: '',
      accessRight: '',
      pin: '',
      address: '',
      addressTwo: '',
      postalCode: '',
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      file: '',
    })

    const fullNameRequired = helpers.withMessage(
      'formError.fullName.required',
      required
    )
    const emailRequired = helpers.withMessage(
      'formError.email.required',
      required
    )
    const emailValid = helpers.withMessage('formError.email.validEmail', email)
    const phoneRequired = helpers.withMessage(
      'formError.phone.required',
      required
    )
    const addressRequired = helpers.withMessage(
      'formError.address.required',
      required
    )
    const postalCodeRequired = helpers.withMessage(
      'formError.postalCode.required',
      required
    )

    const passwordMinLength = helpers.withMessage(
      'formError.password.minLength',
      minLength(6)
    )

    const oldPasswordRequired = helpers.withMessage(
      'formError.oldPassword.required',
      required
    )

    const newPasswordRequired = helpers.withMessage(
      'formError.newPassword.required',
      required
    )

    const newPasswordConfirmationRequired = helpers.withMessage(
      'formError.newPasswordConfirmation.required',
      required
    )
    const newPasswordConfirmationSameAsPassword = helpers.withMessage(
      'formError.newPasswordConfirmation.sameAsPassword',
      sameAs(formData.newPassword)
    )

    const rules = {
      fullName: {
        fullNameRequired,
      },
      email: {
        emailRequired,
        emailValid,
      },
      phone: {
        phoneRequired,
      },
      pin: {},
    }
    const rulesAddress = {
      address: {
        addressRequired,
      },
      addressTwo: {},
      postalCode: {
        postalCodeRequired,
      },
    }
    const rulesPassword = {
      oldPassword: {
        oldPasswordRequired,
        minLength: passwordMinLength,
      },
      newPassword: {
        newPasswordRequired,
        minLength: passwordMinLength,
      },
      newPasswordConfirmation: {
        newPasswordConfirmationRequired,
        minLength: passwordMinLength,
        // sameAsNewPassword: sameAs('newPassword'),
      },
    }

    const validate = useVuelidate(rules, toRefs(formData))
    const validateAddress = useVuelidate(rulesAddress, toRefs(formData))
    const validatePassword = useVuelidate(rulesPassword, toRefs(formData))

    onMounted(async () => {
      await getUserProfile()
      await getUserAddress()
      await getAddressProvince()
      if (city.value !== null && province.value !== null) {
        await getAddressCity()
        cityDisabled.value = false
      }
      if (district.value !== null && city.value !== null) {
        await getAddressDistrict()
        districtDisabled.value = false
      }
      if (village.value !== null && district.value !== null) {
        await getAddressVillage()
        villageDisabled.value = false
      }
    })

    const getAddressProvince = async () => {
      const queryParameters = {
        order: 'DESC',
        page: provincePage.value,
        take: 20,
        q: '',
      }
      await store
        .dispatch('address/province', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const provinceTemporary = []
            response.data.data.forEach((item) => {
              provinceTemporary.push({
                value: item.id,
                ...item,
              })
            })
            provinceList.value = [...provinceList.value, ...provinceTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('province')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressCity = async () => {
      const queryParameters = {
        order: 'DESC',
        page: cityPage.value,
        take: 50,
        q: '',
        province_id: province.value,
      }
      await store
        .dispatch('address/city', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const cityTemporary = []
            response.data.data.forEach((item) => {
              cityTemporary.push({
                value: item.id,
                ...item,
              })
            })
            if (cityPage.value === 1) {
              cityList.value = []
              cityList.value = [...cityTemporary]
            } else {
              cityList.value = [...cityList.value, ...cityTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('city')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressDistrict = async () => {
      const queryParameters = {
        order: 'DESC',
        page: 1,
        take: 50,
        q: '',
        city_id: city.value,
      }
      await store
        .dispatch('address/district', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const districtTemporary = []
            response.data.data.forEach((item) => {
              districtTemporary.push({
                value: item.id,
                ...item,
              })
            })
            if (districtPage.value === 1) {
              districtList.value = []
              districtList.value = [...districtTemporary]
            } else {
              districtList.value = [...districtList.value, ...districtTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('district')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressVillage = async () => {
      const queryParameters = {
        order: 'DESC',
        page: 1,
        take: 50,
        q: '',
        district_id: district.value,
      }
      await store
        .dispatch('address/village', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const villageTemporary = []
            response.data.data.forEach((item) => {
              villageTemporary.push({
                value: item.id,
                ...item,
              })
            })
            if (villagePage.value === 1) {
              villageList.value = []
              villageList.value = [...villageTemporary]
            } else {
              villageList.value = [...villageList.value, ...villageTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('village')
            }
          }
        })
        .catch((error) => {})
    }

    const loadMores = async (type) => {
      if (type === 'province') {
        provincePage.value += 1
        getAddressProvince()
      } else if (type === 'city') {
        cityPage.value += 1
        getAddressCity()
      } else if (type === 'district') {
        districtPage.value += 1
        getAddressDistrict()
      } else if (type === 'village') {
        villagePage.value += 1
        getAddressVillage()
      }
    }

    const changeAddress = async (type) => {
      setTimeout(() => {
        if (type === 'province') {
          cityPage.value = 1
          city.value = ''
          cityList.value = []
          cityDisabled.value = false

          district.value = ''
          districtList.value = []
          districtDisabled.value = true

          village.value = ''
          villageList.value = []
          villageDisabled.value = true

          if (province.value !== '') {
            getAddressCity()
          }
        } else if (type === 'city') {
          districtPage.value = 1
          district.value = ''
          districtList.value = []
          districtDisabled.value = false

          village.value = ''
          villageList.value = []
          villageDisabled.value = true

          if (city.value !== '') {
            getAddressDistrict()
          }
        } else if (type === 'district') {
          villagePage.value = 1
          village.value = ''
          villageList.value = []
          villageDisabled.value = false

          if (district.value !== '') {
            getAddressVillage()
          }
        }
      }, 200)
    }

    const getUserProfile = async () => {
      await store
        .dispatch('user/profile')
        .then((response) => {
          const userData = response.data
          formData.fullName = userData.full_name
          formData.email = userData.email
          formData.phone = userData.phone_number
          formData.accessRight = userData.role.role
        })
        .catch((error) => {})
    }

    const splitWords = (text, numWords) => {
      const words = text.split(' ')
      let part1 = '',
        part2 = ''
      words.forEach((word, idx) => {
        if (idx < numWords) {
          part1 += ' ' + word
        } else {
          part2 += ' ' + word
        }
      })
      return [part1.trim(), part2.trim()]
    }

    const getUserAddress = async () => {
      await store
        .dispatch('user/address')
        .then((response) => {
          const userData = response.data
          const address = splitWords(userData.detail_address, 30)
          formData.address = address[0]
          formData.addressTwo = address[1]
          formData.postalCode = userData.postal_code
          province.value = userData.province_id
          city.value = userData.city_id
          district.value = userData.district_id
          village.value = userData.village_id
        })
        .catch((error) => {})
    }

    const handleFileUpload = (event) => {
      formData.file = event.target.files[0]
    }

    const updateProfile = async () => {
      validate.value.$touch()
      if (validate.value.$invalid == false) {
        isLoading.value = true
        let formParams = new FormData()
        formParams.append('full_name', formData.fullName)
        formParams.append('email', formData.email)
        formParams.append('phone_number', formData.phone)
        if (formData.file !== '') {
          formParams.append('avatar_file', formData.file)
        }
        await store
          .dispatch('user/updateProfile', formParams)
          .then((response) => {
            if (response.data.statusCode === 200) {
              const message = response.data.message
              responseMessage.value = message
              // show toast
              setTimeout(() => {
                showToast({
                  success: true,
                })
              }, 200)
            } else {
              const message = response.message.detail || response.data.message
              responseMessage.value = message
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
            isLoading.value = false
          })
          .catch((error) => {
            isLoading.value = false
            const statusCode = error.response.data.statusCode
            const message =
              error.response.data.message.detail || error.response.data.message
            responseMessage.value = message
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }
    const updateProfileAddress = async () => {
      validateAddress.value.$touch()
      if (validateAddress.value.$invalid == false) {
        isLoading.value = true

        let address = formData.address
        if (formData.addressTwo !== '') address += formData.addressTwo
        const payload = {
          province_id: province.value,
          city_id: city.value,
          district_id: district.value,
          village_id: village.value,
          postal_code: formData.postalCode,
          detail_address: address,
        }
        await store
          .dispatch('user/updateAddress', payload)
          .then((response) => {
            if (response.data.statusCode === 200) {
              const message = response.data.message
              responseMessage.value = message
              // show toast
              setTimeout(() => {
                showToast({
                  success: true,
                })
              }, 200)
            } else {
              const message = response.message.detail || response.data.message
              responseMessage.value = message
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
            isLoading.value = false
          })
          .catch((error) => {
            isLoading.value = false
            const statusCode = error.response.data.statusCode
            const message =
              error.response.data.message.detail || error.response.data.message
            responseMessage.value = message
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }
    const updatePassword = async () => {
      if (formData.newPassword !== formData.newPasswordConfirmation) {
        responseMessage.value = t(
          'formError.newPasswordConfirmation.sameAsPassword'
        )

        setTimeout(() => {
          showToast()
        }, 200)
        return
      }
      validatePassword.value.$touch()
      if (validatePassword.value.$invalid == false) {
        isLoading.value = true
        const payload = {
          old_password: formData.oldPassword,
          new_password: formData.newPassword,
        }
        await store
          .dispatch('user/updatePassword', payload)
          .then((response) => {
            if (response.data.statusCode === 200) {
              const message = response.data.message
              responseMessage.value = message
              // show toast
              setTimeout(() => {
                showToast({
                  success: true,
                })
              }, 200)
            } else {
              const message = response.message.detail || response.data.message
              responseMessage.value = message
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
            isLoading.value = false
          })
          .catch((error) => {
            isLoading.value = false
            const statusCode = error.response.data.statusCode
            const message =
              error.response.data.message.detail || error.response.data.message
            responseMessage.value = message
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }

    const showToast = (success) => {
      let toastId = '#failed-notification-content'
      if (success) {
        toastId = '#success-notification-content'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const previewImage = (file) => {
      // no need resize
      if (file.size < 1024) {
        avatarPreview.value = window.URL.createObjectURL(file)
        imageFile.value = file
      }
      const targetWidth = 1000
      resizeImg(file, targetWidth, (resized) => {
        avatarPreview.value = window.URL.createObjectURL(resized)
        imageFile.value = resized
      })
    }

    const selectFile = () => {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
      hidden.onchange = (e) => {
        previewImage(e.target.files[0])
      }
    }

    return {
      t,
      n,
      store,
      route,
      router,
      isLoading,
      formData,
      validate,
      validateAddress,
      validatePassword,
      responseMessage,
      handleFileUpload,
      updateProfile,
      updateProfileAddress,
      updatePassword,
      activeMenu,
      avatarPreview,
      selectFile,
      province,
      city,
      district,
      village,
      cityDisabled,
      districtDisabled,
      villageDisabled,
      changeAddress,
      provinceList,
      cityList,
      districtList,
      villageList,
      showPassword,
      showNewPassword,
      showConfirmNewPassword,
    }
  },
  mounted() {
    this.activeUser = this.$store.getters['auth/activeUser']
  },
}
</script>

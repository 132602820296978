<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex">
      <ButtonComponent
        :title="$t('menu.employee.addPrivilege')"
        @click="
          $router.push({
            name: 'add-privilege',
            params: { slug: $route.params.slug },
            query: { lang: $route.query.lang },
          })
        "
      >
        <template #icon> + </template>
      </ButtonComponent>
    </div>
  </div>

  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Employee List -->
        <TableComponent
          :date-string="dateFilter"
          :title="$t('page.employee.employeePrivilege.title')"
          :header="employeePrivilegeHeader"
          :meta-data="metaData"
          :is-not-empty="privilegeData.length > 0"
          :is-show-search="true"
          @change-filter="changeFilter($event)"
        >
          <template #icon>
            <BookIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
          </template>

          <tr v-for="data in privilegeData" :key="data.id">
            <td>{{ data.name }}</td>
            <td class="text-center">{{ data.description || '-' }}</td>
          </tr>
        </TableComponent>
        <!-- END: Employee List -->
      </div>
    </div>
  </div>

  <!-- BEGIN: Toast -->
  <Toast
    id="success"
    :title="$t('formInfo.success')"
    :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
  />
  <Toast
    id="failed"
    :title="$t('formInfo.failed')"
    :message="message !== '' ? message : $t('formInfo.fetchFailed')"
    :is-failed="true"
  />
  <!-- END: Toast -->
  <LoadingIndicator v-if="isLoading || isLoadingSave" />
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import privilegeMixin from '@/mixins/privilege.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

import BookIcon from '@/assets/svg/menu/book.svg'

export default {
  name: 'EmployeeManagementPage',
  components: {
    Breadcrumb,
    TableComponent,
    ButtonComponent,
    BookIcon,
  },
  mixins: [globalMixin, privilegeMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
    }
  },
  data: () => ({
    employeePrivilegeHeader: [
      {
        item: 'page.employee.employeePrivilege.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.employee.employeePrivilege.tableHeaders.description',
        customClass: 'text-center',
      },
    ],
  }),
  mounted() {
    this.getAllPrivilege()
  },
  methods: {
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllPrivilege()
    },
  },
}
</script>

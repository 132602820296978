<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.inventoryManagement')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex">
      <ButtonComponent
        :title="$t('page.retail.supplierList.addButton')"
        @click="showModalAdd"
      >
        <template #icon> + </template>
      </ButtonComponent>
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Supplier List -->
        <TableComponent
          :is-show-search="true"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :title="$t('page.retail.supplierList.title')"
          :header="supplierListHeader"
          :is-not-empty="supplierData.length > 0"
          :meta-data="metaDataSupplier"
          :show-icon="false"
          @change-filter="changeFilter($event)"
          @change-filter-dropdown="changeFilterDropdown($event)"
        >
          <tr
            v-for="data in supplierData"
            :key="data.id"
            class="cursor-pointer"
          >
            <td>{{ data.name }}</td>
            <td>
              {{ data.phone || '-' }}
            </td>
            <td>
              <div v-if="'address' in data">
                {{ data.address }}
                <span v-if="'city' in data">
                  ,
                  {{ data.city.city_name }}
                </span>
                <span v-if="'province' in data">
                  ,
                  {{ data.province.province_name }}
                </span>
                <span v-if="'postal_code' in data">
                  ,
                  {{ data.postal_code }}
                </span>
              </div>
              <span v-else> - </span>
            </td>
            <td class="space-x-2">
              <div class="flex space-x-2">
                <button
                  class="flex items-center space-x-1 rounded-sm bg-primary px-4 py-2 text-xs font-medium text-white"
                  @click="showModalUpdate(data)"
                >
                  <EditIcon />
                  <span>{{ $t('action.edit') }}</span>
                </button>
                <button
                  class="flex items-center space-x-1 rounded-sm bg-danger px-4 py-2 text-xs font-medium text-white"
                  @click="showModalDelete(data.id)"
                >
                  <DeleteIcon />
                  <span>{{ $t('action.delete') }}</span>
                </button>
              </div>
            </td>
          </tr>
        </TableComponent>
        <!-- END: Supplier List -->
      </div>
    </div>

    <!-- BEGIN: Modal Add Supplier -->
    <Modal :show="showAddSupplierModal" size="modal-lg" @hidden="closeModal">
      <ModalBody class="p-4">
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="supplierSchema"
          :initial-errors="{}"
          :initial-touched="{}"
          :validate-on-mount="false"
          @submit="saveSupplier"
        >
          <div class="flex flex-col space-y-4 px-5 pb-8 text-center">
            <div class="flex flex-col space-y-3">
              <div class="flex items-center space-x-1">
                <PackageIcon />
                <p class="font-bold">
                  <span v-if="!isEdit">
                    {{ $t('page.retail.supplierList.forms.title') }}
                  </span>
                  <span v-else>
                    {{ $t('page.retail.supplierList.forms.editTitle') }}
                  </span>
                </p>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="supplierName"
                  >
                    <span>
                      {{ $t('page.retail.supplierList.forms.name')
                      }}<sup class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>

                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="supplierName"
                    name="supplierName"
                  >
                    <input
                      id="supplierName"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.supplierName !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      :placeholder="
                        $t('page.retail.supplierList.forms.namePlaceholder')
                      "
                    />
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.supplierName !== undefined">
                      {{ $t(errors.supplierName) }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="phoneNumber"
                  >
                    <span>
                      {{ $t('page.retail.supplierList.forms.phoneNumber')
                      }}<sup class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>

                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="phoneNumber"
                    name="phoneNumber"
                  >
                    <input
                      id="phoneNumber"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.phoneNumber !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      :placeholder="
                        $t(
                          'page.retail.supplierList.forms.phoneNumberPlaceholder'
                        )
                      "
                    />
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.phoneNumber !== undefined">
                      {{ $t(errors.phoneNumber) }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="supplierAddress"
                  >
                    <span>
                      {{ $t('page.retail.supplierList.forms.supplierAddress') }}
                    </span>
                  </label>
                </div>

                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="supplierAddress"
                    name="supplierAddress"
                  >
                    <textarea
                      id="supplierAddress"
                      v-bind="field"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.supplierAddress !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      :placeholder="
                        $t(
                          'page.retail.supplierList.forms.supplierAddressPlaceholder'
                        )
                      "
                    ></textarea>
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.supplierAddress !== undefined">
                      {{ $t(errors.supplierAddress) }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="flex w-full space-x-2">
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="block text-left text-xs font-bold text-gray-700"
                      for="selectedProvince"
                    >
                      <span>
                        {{ $t('page.retail.supplierList.forms.province')
                        }}<sup class="text-red-500">*</sup>
                      </span>
                    </label>
                  </div>
                  <div class="text-left">
                    <Field
                      v-slot="{ field }"
                      v-model="selectedProvince"
                      name="selectedProvince"
                    >
                      <select
                        id="selectedProvince"
                        v-bind="field"
                        required="required"
                        class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.selectedProvince !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                        @change="changeProvince"
                      >
                        <option value="" disabled hidden>
                          {{
                            $t(
                              'page.retail.supplierList.forms.provincePlaceholder'
                            )
                          }}
                        </option>
                        <option
                          v-for="province in provinces"
                          :key="province.id"
                          :value="province.id"
                        >
                          {{ province.province_name }}
                        </option>
                      </select>
                    </Field>

                    <span class="text-xs text-red-400">
                      <template v-if="errors.selectedProvince !== undefined">
                        {{ $t(errors.selectedProvince) }}
                      </template>
                    </span>
                  </div>
                </div>
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="block text-left text-xs font-bold text-gray-700"
                      for="selectedCity"
                    >
                      <span>
                        {{ $t('page.retail.supplierList.forms.city')
                        }}<sup class="text-red-500">*</sup>
                      </span>
                    </label>
                  </div>
                  <div class="text-left">
                    <Field
                      v-slot="{ field }"
                      v-model="selectedCity"
                      name="selectedCity"
                    >
                      <select
                        id="selectedCity"
                        v-bind="field"
                        required="required"
                        class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.selectedCity !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                      >
                        <option value="" disabled hidden>
                          {{
                            $t('page.retail.supplierList.forms.cityPlaceholder')
                          }}
                        </option>
                        <option
                          v-for="city in cities"
                          :key="city.id"
                          :value="city.id"
                        >
                          {{ city.city_name }}
                        </option>
                      </select>
                    </Field>

                    <span class="text-xs text-red-400">
                      <template v-if="errors.selectedCity !== undefined">
                        {{ $t(errors.selectedCity) }}
                      </template>
                    </span>
                  </div>
                </div>
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="block text-left text-xs font-bold text-gray-700"
                      for="postalCode"
                    >
                      <span>
                        {{ $t('page.retail.supplierList.forms.postalCode')
                        }}<sup class="text-red-500">*</sup>
                      </span>
                    </label>
                  </div>

                  <div class="text-left">
                    <Field
                      v-slot="{ field }"
                      v-model="postalCode"
                      name="postalCode"
                    >
                      <input
                        id="postalCode"
                        v-bind="field"
                        required="required"
                        class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.postalCode !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                        type="text"
                        :placeholder="
                          $t(
                            'page.retail.supplierList.forms.postalCodePlaceholder'
                          )
                        "
                      />
                    </Field>
                    <span class="text-xs text-red-400">
                      <template v-if="errors.postalCode !== undefined">
                        {{ $t(errors.postalCode) }}
                      </template>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end space-x-2 px-5 pb-8">
            <button type="button" class="w-24 text-primary" @click="closeModal">
              {{ $t('button.cancel') }}
            </button>
            <button type="submit" class="btn btn-primary w-24">
              {{ $t('button.save') }}
            </button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
    <!-- END: Modal Add Supplier -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <DeleteModal
      :id="deleteId"
      :show-modal="showModalDel"
      @yes="confirmDelete($event)"
      @no="showModalDel = false"
    />

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>
<script>
import * as Yup from 'yup'

import globalMixin from '@/mixins/global.js'
import addressMixin from '@/mixins/address.js'
import tenantMixin from '@/mixins/tenant.js'
import retailMixin from '@/mixins/retail.js'

import { Form, Field } from 'vee-validate'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import DeleteModal from '@/components/DeleteModal.vue'

import PackageIcon from '@/assets/svg/package.svg'
import EditIcon from '@/assets/svg/edit.svg'
import DeleteIcon from '@/assets/svg/delete.svg'

const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[0-9]{3}?[0-9]{4,7}$/
export default {
  name: 'SupplierListPage',
  components: {
    Form,
    Field,
    Breadcrumb,
    TableComponent,
    ButtonComponent,
    DeleteModal,

    PackageIcon,
    EditIcon,
    DeleteIcon,
  },
  mixins: [globalMixin, addressMixin, tenantMixin, retailMixin],
  data: () => ({
    showAddSupplierModal: false,

    supplierListHeader: [
      {
        item: 'page.retail.supplierList.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.retail.supplierList.tableHeaders.phoneNumber',
        customClass: '',
      },
      {
        item: 'page.retail.supplierList.tableHeaders.supplierAddress',
        customClass: '',
      },
      {
        item: 'action.action',
        customClass: '',
      },
    ],

    // Form Validation
    supplierSchema: Yup.object().shape({
      supplierName: Yup.string()
        .typeError('formError.supplierName.required')
        .required('formError.supplierName.required')
        .matches(/^(?!\s+$).*/, 'formError.supplierName.required'),
      phoneNumber: Yup.string()
        .typeError('formError.phoneNumber.required')
        .required('formError.phoneNumber.required')
        .matches(phoneRegExp, 'formError.phoneNumber.notValid'),
      selectedProvince: Yup.string()
        .typeError('formError.province.required')
        .required('formError.province.required'),
      selectedCity: Yup.string()
        .typeError('formError.city.required')
        .required('formError.city.required'),
      postalCode: Yup.string()
        .typeError('formError.postalCode.required')
        .required('formError.postalCode.required')
        .matches(/^(?!\s+$).*/, 'formError.postalCode.required')
        .max(5, 'formError.postalCode.maxLength'),
    }),
  }),
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllSupplier()
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getAllSupplier()
        })
        this.isInstitute = true
      }
    }
    this.getProvince().then(() => {
      if (this.provinces.length > 0) {
        this.getCity(this.provinces[0].id)
      }
    })
  },
  methods: {
    changeFilter(event) {
      this.filterDataSupplier = event.filterData
      this.getAllSupplier()
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getAllSupplier()
    },
    showModalUpdate(data) {
      this.showAddSupplierModal = true
      this.isEdit = true
      this.supplierId = data.id
      this.supplierName = data.name
      this.phoneNumber = data.phone
      this.supplierAddress = data.address
      if (data.province_id) {
        this.getProvince().then(() => {
          this.selectedProvince = data.province_id
          this.getCity(data.province_id).then(() => {
            this.selectedCity = data.city_id
          })
        })
      }

      this.postalCode = data.postal_code
    },
    showModalAdd() {
      this.showAddSupplierModal = true
      this.isEdit = false
      this.resetData(false)
    },
    saveSupplier() {
      if (this.supplierName === '' || this.isLoadingSave) return

      const params = {}
      params.tenant_id = this.tenantId
      params.name = this.supplierName
      params.phone = this.phoneNumber
      params.address = this.supplierAddress
      params.province_id = this.selectedProvince.toString()
      params.city_id = this.selectedCity.toString()
      params.postal_code = this.postalCode
      params.description = null

      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.supplierId === '') {
          this.isLoadingSave = false
          return
        }
        params.id = this.supplierId

        this.saveSupplierAPI(params, true)
      } else {
        this.saveSupplierAPI(params, false)
      }
    },
    closeModal() {
      this.showAddSupplierModal = false
    },
    confirmDelete(id) {
      this.deleteSupplierAPI(id)
    },
    resetData(afterSave = true) {
      this.supplierId = null
      this.supplierName = ''
      this.phoneNumber = ''
      this.supplierAddress = ''
      this.selectedProvince = ''
      this.selectedCity = ''
      this.postalCode = ''

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            supplierName: '',
            phoneNumber: '',
            selectedProvince: '',
            selectedCity: '',
            postalCode: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showAddSupplierModal = false
        this.isEdit = false
        this.getAllSupplier({
          showMessage: false,
          setLoading: true,
          search: '',
          setMetaData: true,
        })
      }
    },
  },
}
</script>

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.purchaseOrder.stockPurchase')"
          link="stock-purchase"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
    </div>
    <div class="mt-8 flex w-full flex-col justify-center space-y-4">
      <div class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8">
        <div class="flex w-full items-center justify-between text-base">
          <span>Daftar Supplier</span>
          <div class="ml-4 flex shadow">
            <input
              v-model="searchSupplier"
              class="w-full border-none p-2 outline-none"
              type="text"
              :placeholder="$t('formLabel.searchFoo', { foo: 'Supplier' })"
            />
            <button
              class="text-blue hover:text-blue-light flex w-auto items-center justify-end p-2"
              @click="filterSupplier"
            >
              <SearchIcon />
            </button>
          </div>
        </div>
        <template v-if="supplierData.length > 0">
          <div
            v-for="supplier in supplierData"
            :key="supplier.id"
            class="flex w-full flex-col"
          >
            <div
              v-if="
                supplier.id in supplierProductBySupplier &&
                supplierProductBySupplier[supplier.id].length > 0
              "
              class="flex w-full items-center justify-between"
            >
              <span>{{ supplier.name }}</span>
              <span
                v-if="
                  supplier.id in supplierProductBySupplier &&
                  supplierProductBySupplier[supplier.id].length > 0
                "
                class="cursor-pointer text-xs text-primary"
                @click="showDetailSupplier(supplier.id)"
              >
                Lihat semua
              </span>
            </div>
            <div class="mt-2 grid grid-cols-3 gap-6 md:grid-cols-5">
              <template
                v-if="
                  supplier.id in supplierProductBySupplier &&
                  supplierProductBySupplier[supplier.id].length > 0
                "
              >
                <ProductSupplierItem
                  v-for="product in supplierProductBySupplier[supplier.id]"
                  :key="product.id"
                  :product="product"
                  @show-detail="showDetailProductSupplier"
                />
              </template>
              <DualRingLoader v-else-if="isLoading" />
            </div>
          </div>
        </template>
        <div v-else-if="!isLoading" class="text-center text-gray-500">
          Supplier <span class="font-semibold">{{ searchSupplier }}</span> tidak
          ditemukan
        </div>
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>
<script>
import debounce from 'lodash.debounce'
import globalMixin from '@/mixins/global.js'
import stockPurchaseMixin from '@/mixins/stockPurchase.js'
import tenantMixin from '@/mixins/tenant.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import DualRingLoader from '@/components/loader/DualRingLoader.vue'
import ProductSupplierItem from '@/components/item/ProductSupplierItem.vue'

export default {
  name: 'StockPurchaseSupplier',
  components: {
    Breadcrumb,

    DualRingLoader,
    ProductSupplierItem,
  },
  mixins: [globalMixin, stockPurchaseMixin, tenantMixin],
  watch: {
    searchSupplier: debounce(function (newVal, oldVal) {
      this.filterSupplier()
    }, 500),
  },
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllSupplier(true, false, true).then(() => {
          this.supplierData.forEach((item) => {
            this.getAllProductSupplier(false, true, null, 6, item.id)
          })
        })
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getAllSupplier(true, false, true).then(() => {
            this.supplierData.forEach((item) => {
              this.getAllProductSupplier(false, true, null, 6, item.id)
            })
          })
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    filterSupplier() {
      this.getAllSupplier(true, true, true, null, this.searchSupplier).then(
        () => {
          this.supplierData.forEach((item) => {
            this.getAllProductSupplier(false, true, null, 6, item.id)
          })
        }
      )
    },
  },
}
</script>

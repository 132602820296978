<template>
  <nav
    aria-label="breadcrumb"
    class="-intro-x mr-auto flex max-w-full text-center"
  >
    <ol class="breadcrumb">
      <li class="breadcrumb-item cursor-pointer">
        <a @click="link !== null ? toLink(link) : ''">{{ title }}</a>
      </li>
      <template v-if="subtitles.length > 0">
        <li
          v-for="(sub, key) in subtitles"
          :key="key"
          class="breadcrumb-item"
          :class="[key === subtitles.length - 1 ? 'active' : 'cursor-pointer']"
          aria-current="page"
        >
          <a
            v-if="sub.link !== null"
            class="truncate"
            @click="toLink(sub.link)"
          >
            {{ sub.title }}
          </a>
          <span v-else class="truncate">{{ sub.title }}</span>
        </li>
      </template>
      <li v-else class="breadcrumb-item active" aria-current="page">
        {{ subtitle }}
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'BreadcumbComponent',
  props: {
    title: {
      type: String,
      default: null,
      required: true,
    },
    link: {
      type: String,
      default: 'dashboard',
    },
    subtitle: {
      type: String,
      default: '',
      required: false,
    },
    subtitles: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  methods: {
    toLink(link) {
      return this.$router.push({
        name: `${link}`,
        params: { slug: this.$route.params.slug },
        query: { lang: this.$route.query.lang },
      })
    },
  },
}
</script>

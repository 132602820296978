<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.purchaseOrder.stockPurchase')"
          link="stock-purchase"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
    </div>
    <div class="mt-8 flex w-full flex-col justify-center space-y-4">
      <div class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8">
        <div class="flex w-full flex-col justify-between text-base md:flex-row">
          <span>
            {{ $t('page.purchaseOrder.stockPurchase.recomendation') }}
          </span>
          <div class="flex space-x-4 text-xs">
            <div class="flex items-center space-x-1">
              <div class="rounded-full bg-yellow-700 px-2">&nbsp;</div>
              <span>
                {{ $t('page.purchaseOrder.stockPurchase.stockRunningLow') }}
              </span>
            </div>
            <div class="flex items-center space-x-1">
              <div class="rounded-full bg-red-500 px-2">&nbsp;</div>
              <span>
                {{ $t('page.purchaseOrder.stockPurchase.outOfStock') }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex w-full">
          <div v-if="recommendedProductByReminder.length > 0" class="w-full">
            <div class="grid grid-cols-3 gap-6 md:grid-cols-5">
              <ProductSupplierItem
                v-for="product in recommendedProductByReminder"
                :key="product.id"
                :product="product"
                :reminder-data="
                  ingredientReminderData.find(
                    (item) =>
                      item.name.toLowerCase() === product.name.toLowerCase()
                  )
                "
                @show-detail="showDetailProductSupplier"
              />
            </div>
          </div>
          <div v-else class="w-full text-center text-gray-500">
            {{ $t('page.purchaseOrder.stockPurchase.noRecomendation') }}
          </div>
        </div>
      </div>
      <div class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8">
        <div class="flex w-full items-center justify-between text-base">
          <span>
            {{ $t('page.purchaseOrder.stockPurchase.searchFromSupplier') }}
          </span>
          <span
            class="cursor-pointer text-xs text-primary"
            @click="showAllSupplier()"
          >
            {{ $t('page.purchaseOrder.stockPurchase.showSupplierList') }}
          </span>
        </div>
        <div
          v-for="supplier in supplierData"
          :key="supplier.id"
          class="flex w-full flex-col"
        >
          <div class="flex w-full items-center justify-between">
            <span>{{ supplier.name }}</span>
            <span
              class="cursor-pointer text-xs text-primary"
              @click="showDetailSupplier(supplier.id)"
            >
              {{ $t('button.seeAll') }}
            </span>
          </div>
          <div class="mt-2 grid grid-cols-3 gap-6 md:grid-cols-5">
            <ProductSupplierItem
              v-for="product in supplierProductData"
              :key="product.id"
              :product="product"
              @show-detail="showDetailProductSupplier"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import stockPurchaseMixin from '@/mixins/stockPurchase.js'
import tenantMixin from '@/mixins/tenant.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import ProductSupplierItem from '@/components/item/ProductSupplierItem.vue'

export default {
  name: 'StockPurchasePage',
  components: {
    Breadcrumb,

    ProductSupplierItem,
  },
  mixins: [globalMixin, stockPurchaseMixin, tenantMixin],
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllIngredientStock(false).then(() => {
          this.getAllProductSupplier(
            false,
            true,
            this.searchNameByReminder,
            null,
            null,
            true
          )
        })

        this.getAllSupplier(true, true, true, 1).then(() => {
          this.getAllProductSupplier(false, true, null, 6)
        })
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          // Get Recommended
          this.getAllIngredientStock(false).then(() => {
            this.getAllProductSupplier(
              false,
              true,
              this.searchNameByReminder,
              null,
              null,
              true
            )
          })

          // Get first supplier data
          this.getAllSupplier(true, true, true, 1).then(() => {
            this.getAllProductSupplier(false, true, null, 6)
          })
        })
        this.isInstitute = true
      }
    }
  },
}
</script>

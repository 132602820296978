<template>
  <Dropdown class="mt-2 md:mt-0 md:ml-auto">
    <DropdownToggle
      class="btn hover:btn-outline-secondary w-full bg-white font-normal md:w-fit"
      @click="showDropdown = true"
    >
      {{ $t(`formLabel.dropdown.timeRange.${active}`) }}
      <ChevronDownIcon class="ml-2 h-4 w-4" />
    </DropdownToggle>
    <DropdownMenu class="w-40">
      <DropdownContent v-if="showDropdown" class="h-48 overflow-y-auto">
        <DropdownItem
          v-for="time in options"
          :key="time"
          :class="time === active ? 'bg-slate-100' : ''"
          @click="doFilter(time)"
        >
          {{ $t(`formLabel.dropdown.timeRange.${time}`) }}
        </DropdownItem>
      </DropdownContent>
    </DropdownMenu>
  </Dropdown>
</template>
<script>
export default {
  name: 'FilterTimeRangeComponent',
  props: {
    active: {
      type: String,
      default: 'weekly',
    },
    options: {
      type: Array,
      default: () => {
        return ['daily', 'weekly', 'monthly', 'quarterly', 'yearly']
      },
    },
  },
  emits: ['change-filter'],
  data: () => ({
    showDropdown: false,
  }),
  methods: {
    doFilter(event) {
      this.$emit('change-filter', event)
      this.showDropdown = false
    },
  },
}
</script>

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.analytic.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
      <div class="flex">
        <div class="flex flex-col justify-center pr-2">
          <p>
            {{ $t('formLabel.from') }}
          </p>
        </div>
        <div class="relative text-slate-500 sm:ml-auto sm:mt-0">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateStart"
            :options="{
              autoApply: true,
              singleMode: true,
              numberOfColumns: 1,
              numberOfMonths: 1,
              showWeekNumbers: false,
              format: 'YYYY-MM',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                date: false,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 sm:w-28"
          />
        </div>
        <div class="flex flex-col justify-center pr-2 pl-6">
          <p>
            {{ $t('formLabel.to') }}
          </p>
        </div>
        <div class="relative text-slate-500 sm:ml-auto sm:mt-0">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateEnd"
            :options="{
              autoApply: true,
              singleMode: true,
              numberOfColumns: 1,
              numberOfMonths: 1,
              showWeekNumbers: false,
              format: 'YYYY-MM',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                date: false,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 sm:w-28"
          />
        </div>
      </div>
    </div>

    <div
      v-if="isInstitute && !isInstituteSuperAdmin"
      class="flex w-full justify-end"
    >
      <div class="mt-8 flex w-full md:w-2/6">
        <select
          v-model="tenantId"
          class="w-full border-none p-2 pr-10 outline-none"
          @change="init(true)"
        >
          <option
            v-for="tenant in tenantOptions"
            :key="tenant.value"
            :value="tenant.value"
          >
            {{ tenant.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="relative grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Growth Percentage -->
          <div class="col-span-12 mt-8">
            <div
              class="intro-y flex h-10 w-full flex-col justify-between md:flex-row"
            >
              <div class="flex align-middle">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <div class="flex">
                  <div class="flex self-center align-middle">
                    <h2 class="mr-3 truncate text-lg font-medium">
                      {{ $t($route.meta.title) }}
                    </h2>
                    <span class="flex self-center text-xs text-gray-500">
                      {{ dateEnd }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex align-middle">
                <a href="" class="flex items-center space-x-2 text-primary">
                  <RefreshCcwIcon class="h-4 w-4" />
                  <span>{{ $t('action.reloadData') }}</span>
                </a>
              </div>
            </div>
            <div class="mt-8 grid grid-cols-12 gap-6 md:mt-5">
              <Card
                :is-currency="false"
                :title="$t('page.analytic.growthPercentage.title')"
                :is-up="growth_percentage.is_up"
                :total="growth_percentage.percent"
                :different="growth_percentage_total"
                :total-is-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
              />
              <Card
                :is-up="false"
                :title="$t('page.analytic.growthPercentage.thisMonthSale')"
                :total="cardData.sales_total_this_month"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
              />
              <Card
                :is-up="false"
                :title="$t('page.analytic.growthPercentage.lastMonthSale')"
                :total="cardData.sales_total_last_month"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
              />
            </div>
          </div>
          <!-- END: Growth Percentage -->
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Growth MoM -->
          <div class="col-span-12 mt-4 2xl:col-span-6">
            <div class="intro-y box p-5 md:mt-6">
              <div class="flex flex-col md:flex-row md:items-center">
                <div class="flex align-middle">
                  <div class="flex justify-center self-center align-middle">
                    <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                  </div>
                  <div class="flex">
                    <div class="flex self-center align-middle">
                      <h2 class="mr-3 truncate text-lg font-medium">
                        {{ $t('page.analytic.growthPercentage.growthMoM') }}
                      </h2>
                      <span class="flex self-center text-xs text-gray-500">
                        {{ dateEnd }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ReportLineChart
                  v-if="!growthTotalChartEmpty"
                  :height="275"
                  :label="growthTotalChartLabel"
                  :item-name="growthTotalChartItemName"
                  :data="growthTotalChartData"
                  chart-type="bar"
                  class="mt-6 -mb-6"
                />
                <NoData v-else />
              </div>
            </div>
          </div>
          <!-- END: Growth MoM -->

          <!-- BEGIN: Growth MoM Percentage -->
          <div class="col-span-12 mt-4 2xl:col-span-6">
            <div class="intro-y box p-5 md:mt-6">
              <div class="flex flex-col md:flex-row md:items-center">
                <div class="flex align-middle">
                  <div class="flex justify-center self-center align-middle">
                    <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                  </div>
                  <div class="flex">
                    <div class="flex self-center align-middle">
                      <h2 class="mr-3 truncate text-lg font-medium">
                        {{
                          $t(
                            'page.analytic.growthPercentage.growthMoMPercentage'
                          )
                        }}
                      </h2>
                      <span class="flex self-center text-xs text-gray-500">
                        {{ dateEnd }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ReportLineChart
                  v-if="!growthPercentageChartEmpty"
                  :height="275"
                  :label="growthPercentageChartLabel"
                  :item-name="growthPercentageChartItemName"
                  :data="growthPercentageChartData"
                  chart-type="bar"
                  class="mt-6 -mb-6"
                />
                <NoData v-else />
              </div>
            </div>
          </div>
          <!-- END: Growth MoM Percentage -->
        </div>
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'

import Card from '@/components/Card.vue'
import NoData from '@/components/NoData.vue'
import SaleIcon from '@/assets/svg/sale.svg'

export default {
  name: 'GrowthPercentagePage',
  components: {
    Breadcrumb,
    ReportLineChart,
    SaleIcon,
    Card,
    NoData,
  },
  mixins: [globalMixin, tenantMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateStart = ref()
    const dateEnd = ref()

    return {
      t,
      n,
      store,
      route,
      router,
      dateStart,
      dateEnd,
    }
  },
  data: () => ({
    growthTotalChartLabel: [],
    growthTotalChartItemName: '',
    growthTotalChartData: [],
    growthTotalChartEmpty: false,
    growthPercentageChartLabel: [],
    growthPercentageChartItemName: '',
    growthPercentageChartData: [],
    growthPercentageChartEmpty: false,
    growth_percentage: {
      percent: '0%',
      is_up: false,
    },
    growth_percentage_total: 0,
    cardData: {
      sales_total_this_month: 0,
      sales_total_last_month: 0,
    },
  }),
  watch: {
    dateStart: function () {
      if (
        (this.isInstituteSuperAdmin && this.selectedTenantIdString !== '') ||
        this.tenantId !== ''
      ) {
        this.init(true)
      }
    },
    dateEnd: function () {
      if (
        (this.isInstituteSuperAdmin && this.selectedTenantIdString !== '') ||
        this.tenantId !== ''
      ) {
        this.init()
      }
    },
  },
  mounted() {
    const endDate = this.dayjs()
    const startDate = endDate.subtract(1, 'month')

    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.dateStart = startDate.format('YYYY-MM')
        this.dateEnd = endDate.format('YYYY-MM')
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.dateStart = startDate.format('YYYY-MM')
          this.dateEnd = endDate.format('YYYY-MM')
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    init(showMessage = false) {
      if (!this.isLoading) {
        this.getGrowthPercentageSummary(showMessage)
        this.getGrowthPercentageChart()
      }
    },
    async getGrowthPercentageSummary(showMessage = false) {
      this.isLoading = true

      const params = {}

      if (this.selectedTenantIdString !== '')
        params.tenant_id = this.selectedTenantIdString
      else if (this.tenantId !== '') params.tenant_id = this.tenantId

      await this.$store
        .dispatch('analytic/growthPercentageSummary', params)
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.growth_percentage = responseData.growth_percentage
            this.growth_percentage_total = responseData.growth_percentage.total
            this.cardData.sales_total_this_month =
              responseData.sales_total_this_month
            this.cardData.sales_total_last_month =
              responseData.sales_total_last_month
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getGrowthPercentageChart() {
      const params = {
        start_month_year: this.dateStart,
        end_month_year: this.dateEnd,
      }

      if (this.selectedTenantIdString !== '')
        params.tenant_id = this.selectedTenantIdString
      else if (this.tenantId !== '') params.tenant_id = this.tenantId

      await this.store
        .dispatch('analytic/growthPercentageChart', params)
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            const objectKey = Object.keys(responseData)
            if (responseData.growth_percentage_total.length > 0) {
              this.growthTotalChartItemName = objectKey.at(0)
              let growthTotalLabel = []
              let growthTotalTotal = []
              responseData.growth_percentage_total.forEach((item) => {
                growthTotalLabel.push(item.name)
                growthTotalTotal.push(item.total)
              })
              this.growthTotalChartLabel = growthTotalLabel
              this.growthTotalChartData = growthTotalTotal
              this.growthTotalChartEmpty = false
            } else {
              this.growthTotalChartEmpty = true
            }

            if (responseData.growth_percentage_percent.length > 0) {
              this.growthPercentageChartItemName = objectKey.at(-1)
              let growthPercentageLabel = []
              let growthPercentageTotal = []
              responseData.growth_percentage_percent.forEach((item) => {
                growthPercentageLabel.push(item.name)
                growthPercentageTotal.push(item.total)
              })
              this.growthPercentageChartData = growthPercentageTotal
              this.growthPercentageChartLabel = growthPercentageLabel
              this.growthPercentageChartEmpty = false
            } else {
              this.growthPercentageChartEmpty = true
            }
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}
</script>

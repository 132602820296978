import axiosGeneralPayment, {
  routePathAPIGeneralPayment,
} from '@/plugins/axios-general-payment.js'

import router from '@/router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  getAll({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.client_reference_code)
        params.client_reference_code = payload.client_reference_code
      // params.is_active = payload.is_active === true ? true : false
      params.is_cashier_payment = payload.is_cashier_payment ? true : false
      if (payload.payment_channel_id)
        params.payment_channel_id = payload.payment_channel_id
      if (payload.payment_channel_name)
        params.payment_channel_name = payload.payment_channel_name

      axiosGeneralPayment
        .get(`${routePathAPIGeneralPayment()}/client-reference-channels`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {
        is_active: payload.is_active,
        is_cash_payment: payload.is_cash_payment,
      }
      axiosGeneralPayment
        .put(
          `${routePathAPIGeneralPayment()}/client-reference-channels/${
            payload.id
          }`,
          params,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateMDR({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {
        admin_fee: payload.admin_fee,
        admin_fee_type: payload.admin_fee_type,
      }
      axiosGeneralPayment
        .put(
          `${routePathAPIGeneralPayment()}/client-reference-channels/${
            payload.id
          }`,
          params,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default {
  data: () => ({
    ingredientReminderData: [],
    recommendedProductByReminder: [],
    searchNameByReminder: '',

    supplierData: [],
    activeSupplier: {},
    supplierId: '',
    supplierProductData: [],
    activeSupplierProductId: null,
    activeSupplierProductData: {},
    supplierProductBySupplier: {},

    searchSupplier: '',
    searchSupplierProduct: '',

    shippingData: {},
    transactionFeePercentage: null,

    adjustStockPurchaseId: undefined,

    selectedPaymentChannel: {},
    paymentchannelData: [],

    qty: 1,

    purchaseOrderData: [],
    purchaseOrderDetailData: {},
    totalPricePODetail: {},
    isOrderSupplier: false,
    statusList: [
      'TOTAL',
      'REQUEST',
      'PENDING',
      'PAID',
      'PROCESS',
      'DELIVERY',
      'RECEIVED',
      'CANCELLED',
      'COMPLETED',
      'REJECTED',
    ],
    statusActive: 'TOTAL',

    // START: USER ADDRESS
    userAddressData: [],
    activeUserAddress: undefined,
    userAddressId: null,
    // END: USER ADDRESS

    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },

    metaDataProductSupplier: {
      page: 1,
      take: 11,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataProductSupplier: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    showModalOrder: false,
  }),
  methods: {
    async getAllIngredientStock(showMessage = true) {
      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
        is_reminder: true,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('scm/getAllIngredientStock', params)
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.ingredientReminderData = responseData.data.reduce(
              (filtered, item) => {
                filtered.push({
                  id: item.id,
                  name: item.product_ingredient.name,
                  stock: item.stock,
                })
                return filtered
              },
              []
            )

            this.searchNameByReminder = this.ingredientReminderData.reduce(
              (filtered, item, index) => {
                if (index > 0) filtered += `|${item.name}`
                else filtered = item.name
                return filtered
              },
              ''
            )
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    // BEGIN: SUPPLIER PRODUCT
    async getAllProductSupplier(
      showMessage = true,
      setLoading = true,
      search = null,
      take = null,
      supplierId = null,
      setRecommendedProduct = false
    ) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterDataProductSupplier.order,
        page: this.filterDataProductSupplier.page,
        take: take !== null ? take : this.filterDataProductSupplier.take,
        q:
          search !== null
            ? search
            : this.searchNameByReminder !== ''
            ? this.searchNameByReminder
            : this.filterDataProductSupplier.search,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      if (!setRecommendedProduct) {
        if (supplierId !== null) {
          params.supplier_id = supplierId
        } else if (this.supplierId !== null) {
          params.supplier_id = this.supplierId
        }
      }

      await this.$store
        .dispatch('stockPurchase/getAllProductSupplier', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            if (supplierId !== null) {
              this.supplierProductBySupplier[supplierId] = responseData.data
            } else {
              if (setRecommendedProduct)
                this.recommendedProductByReminder = responseData.data
              else this.supplierProductData = responseData.data
            }
          }
          if (responseData.meta) {
            this.metaDataProductSupplier = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getDetailProductSupplier(productSupplierId, showMessage = true) {
      this.isLoading = true

      await this.$store
        .dispatch('stockPurchase/getDetailProductSupplier', {
          id: productSupplierId,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data

          this.activeSupplierProductData = responseData

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getDetailProductSupplierStock(productSupplierId, showMessage = true) {
      this.isLoading = true

      await this.$store
        .dispatch('stockPurchase/getDetailProductSupplierStock', {
          id: productSupplierId,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data

          this.activeSupplierProductData = responseData

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    // END: SUPPLIER PRODUCT

    // BEGIN: SUPPLIER
    async getAllSupplier(
      showMessage = true,
      setLoading = true,
      setSupplierId = false,
      take = null,
      search = null
    ) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: take !== null ? take : this.filterData.take,
        q: search !== null ? search : this.filterData.search,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('stockPurchase/getAllSupplier', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.supplierData = responseData.data
            if (setSupplierId && responseData.data.length > 0) {
              this.supplierId = this.supplierData[0].id
            }
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getDetailSupplier(supplierId, showMessage = true) {
      this.isLoading = true

      await this.$store
        .dispatch('stockPurchase/getDetailSupplier', {
          id: supplierId,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data

          this.activeSupplier = responseData

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    // END: SUPPLIER

    // BEGIN: STOCK PURCHASE
    async saveStockPurchaseRequestAPI(params, isUpdate = false) {
      this.isLoadingSave = true

      let dispatcher = 'stockPurchase/createStockPurchaseRequest'
      if (isUpdate) dispatcher = ''

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.isLoadingSave = false
          this.showModalPurchase = false

          const responseData = response.data.data

          this.$store.dispatch('stockPurchase/resetStockPurchaseCart')
          this.$store.dispatch('stockPurchase/resetSelectedAddress')

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)

          this.$router.push({
            name: 'stock-purchase-history-detail',
            params: { orderId: responseData.id },
            query: { lang: this.$route.query.lang },
          })
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.showModalPurchase = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveStockPurchaseAPI(params, isUpdate = false) {
      this.isLoadingSave = true

      let dispatcher = 'stockPurchase/createStockPurchase'
      if (isUpdate) dispatcher = ''

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.isLoadingSave = false
          this.showModalPurchase = false

          this.$store.dispatch('stockPurchase/resetStockPurchaseCart')
          this.$store.dispatch('stockPurchase/resetSelectedAddress')

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)

          this.$router.push({
            name: 'stock-purchase-history',
            params: { ...this.$route.params },
            query: { lang: this.$route.query.lang },
          })
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.showModalPurchase = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    async approveStockPurchaseAPI(params, isUpdate = false) {
      this.isLoadingSave = true

      let dispatcher = 'stockPurchase/approveStockPurchase'
      if (isUpdate) dispatcher = ''

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.isLoadingSave = false
          this.showModalPurchase = false

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)

          this.getDetailPurchaseOrder(params.id, true)
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.showModalPurchase = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    async adjustStockPurchaseAPI(params, isUpdate = false) {
      this.isLoadingSave = true

      let dispatcher = 'stockPurchase/adjustStockPurchase'
      if (isUpdate) dispatcher = ''

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.isLoadingSave = false
          this.showModalPurchase = false

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)

          this.showHistoryDetailPurchaseOrder(params)
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.showModalPurchase = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    async getAllPurchaseOrder(
      status = 'TOTAL',
      filterDates,
      setLoading = false
    ) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      if (filterDates !== undefined) {
        if ('start' in filterDates) params.start_date = filterDates.start
        if ('end' in filterDates) params.end_date = filterDates.end
      }

      if (status !== 'TOTAL') {
        params.status = status
      }

      await this.$store
        .dispatch('stockPurchase/getAllPurchaseOrder', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            if ('data' in responseData) {
              const purchaseOrderData = []
              const responseDataPO = responseData.data
              if (responseDataPO.length > 0) {
                responseDataPO.forEach(
                  (item) =>
                    item.purchase_suppliers.forEach((itemPS) => {
                      itemPS.transaction_id = item.transaction_id
                      purchaseOrderData.push(itemPS)
                    })
                  // purchaseOrderData.push(
                  //   ...item.purchase_suppliers,
                  // )
                )
              }
              this.purchaseOrderData = purchaseOrderData
              this.isOrderSupplier = false
            }
            if ('meta' in responseData) {
              this.metaData = responseData.meta
            }
          }

          if (setLoading) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getDetailPurchaseOrder(id, setLoading = false) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('stockPurchase/getDetailPurchaseOrder', {
          id,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.purchaseOrderDetailData = responseData
            if ('sub_total' in this.purchaseOrderDetailData) {
              this.totalPricePODetail = this.purchaseOrderDetailData.sub_total
            } else {
              this.totalPricePODetail = responseData.purchase_suppliers.reduce(
                (item, currVal) => {
                  item += currVal.total
                  return item
                },
                0
              )
            }
          }
          this.isOrderSupplier = false

          if (setLoading) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async cancelPurchaseOrder(id, setLoading = false) {
      if (setLoading) this.isLoading = true

      const params = {
        id,
      }

      await this.$store
        .dispatch('stockPurchase/cancelPurchaseOrder', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          this.showModal = false

          this.getDetailPurchaseOrder(id, true)

          // this.purchaseOrderDetailData = responseData
          // if ('sub_total' in this.purchaseOrderDetailData) {
          //   this.totalPricePODetail = this.purchaseOrderDetailData.sub_total
          // } else {
          //   this.totalPricePODetail = responseData.purchase_suppliers.reduce(
          //     (item, currVal) => {
          //       item += currVal.total
          //       return item
          //     },
          //     0
          //   )
          // }

          if (setLoading) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async rejectPurchaseOrder(id, setLoading = false) {
      if (setLoading) this.isLoading = true

      const params = {
        id,
      }

      await this.$store
        .dispatch('stockPurchase/rejectPurchaseOrder', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          this.showModal = false

          this.getDetailPurchaseOrder(id, true)

          // this.purchaseOrderDetailData = responseData
          // if ('sub_total' in this.purchaseOrderDetailData) {
          //   this.totalPricePODetail = this.purchaseOrderDetailData.sub_total
          // } else {
          //   this.totalPricePODetail = responseData.purchase_suppliers.reduce(
          //     (item, currVal) => {
          //       item += currVal.total
          //       return item
          //     },
          //     0
          //   )
          // }

          if (setLoading) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async uploadPurchaseOrderProofPayment(id, file, setLoading = false) {
      if (setLoading) this.isLoading = true

      const params = {
        id,
        file,
      }

      await this.$store
        .dispatch('stockPurchase/uploadPurchaseOrderProofPayment', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.showModalOrder = false

            if (setLoading) {
              this.message = response.data.message
              setTimeout(() => {
                this.showToast('success')
              }, 500)
            }
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    // END: STOCK PURCHASE

    // BEGIN: STOCK PURCHASE SUPPLIER
    async getAllPurchaseSupplier(
      status = 'TOTAL',
      filterDates,
      setLoading = false
    ) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      if (filterDates !== undefined) {
        if ('start' in filterDates) params.start_date = filterDates.start
        if ('end' in filterDates) params.end_date = filterDates.end
      }

      if (status !== 'TOTAL') {
        params.status = status
      }

      await this.$store
        .dispatch('stockPurchase/getAllPurchaseSupplier', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            if ('data' in responseData) {
              const purchaseOrderData = []
              const responseDataPO = responseData.data
              if (responseDataPO.length > 0) {
                responseDataPO.forEach((item) => {
                  item.transaction_id = item.purchase_id
                  purchaseOrderData.push(item)
                })
              }
              this.purchaseOrderData = purchaseOrderData
              this.isOrderSupplier = true
            }
            if ('meta' in responseData) {
              this.metaData = responseData.meta
            }
          }

          if (setLoading) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getDetailPurchaseSupplier(id, setLoading = false) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('stockPurchase/getDetailPurchaseSupplier', {
          id,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.purchaseOrderDetailData = responseData
            if ('sub_total' in this.purchaseOrderDetailData) {
              this.totalPricePODetail = this.purchaseOrderDetailData.sub_total
            } else {
              this.totalPricePODetail = responseData.purchase_item.reduce(
                (item, currVal) => {
                  item += currVal.total
                  return item
                },
                0
              )
            }
          }
          this.isOrderSupplier = true

          if (setLoading) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async receivePurchaseOrderSupplier(id, setLoading = false) {
      if (setLoading) this.isLoading = true

      const params = {
        id,
      }

      await this.$store
        .dispatch('stockPurchase/receivePurchaseOrderSupplier', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          this.showModal = false

          this.getDetailPurchaseSupplier(id, true)

          if (setLoading) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async cancelPurchaseOrderSupplier(payload, setLoading = false) {
      if (setLoading) this.isLoading = true

      const params = {
        id: payload.transactionSupplierId,
      }

      await this.$store
        .dispatch('stockPurchase/cancelPurchaseOrderSupplier', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          this.showModal = false

          if (payload.transactionId !== null) {
            this.getDetailPurchaseOrder(payload.transactionId, true)
          }

          if (setLoading) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    // END: STOCK PURCHASE SUPPLIER

    // BEGIN: USER ADDRESS
    async getAllUserAddress(showMessage = true, setLoading = true) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
      }

      await this.$store
        .dispatch('stockPurchase/getAllUserAddress', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.userAddressData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getDetailUserAddress(userAddressId, showMessage = true) {
      this.isLoading = true

      await this.$store
        .dispatch('stockPurchase/getDetailUserAddress', {
          id: userAddressId,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data

          this.activeUserAddress = responseData

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveUserAddressAPI(params, isUpdate = false) {
      this.isLoadingSave = true

      let dispatcher = 'stockPurchase/createUserAddress'
      if (isUpdate) dispatcher = 'stockPurchase/updateUserAddress'

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.isLoadingSave = false

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)

          this.$router.push({
            name: 'stock-purchase-cart-address',
            params: { ...this.$route.params },
            query: { lang: this.$route.query.lang },
          })
        })
        .catch((e) => {
          this.isLoadingSave = false
          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteUserAddressAPI(id) {
      this.isLoadingSave = true
      await this.$store
        .dispatch('stockPurchase/deleteUserAddress', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          // this.getAllUserAddress(false)
          this.$router.push({
            name: 'stock-purchase-cart-address',
            params: { ...this.$route.params },
            query: { lang: this.$route.query.lang },
          })
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    // END: USER ADDRESS

    async getAllPaymentChannel(showMessage = true, setLoading = false) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('stockPurchase/getAllPaymentChannel', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          payment_channel_name: this.filterData.search,
          is_cashier_payment: false,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.paymentchannelData = responseData.data
            this.selectedPaymentChannel = responseData.data.find(
              (item) => item.name.toLowerCase() === 'cash'
            )
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    // BEGIN: SETTING
    async getShippingFee(showMessage = false) {
      await this.$store
        .dispatch('stockPurchase/getShippingFee')
        .then((response) => {
          const responseData = response.data.data

          this.shippingData = responseData

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getTransactionFee(showMessage = false) {
      await this.$store
        .dispatch('stockPurchase/getTransactionFee')
        .then((response) => {
          const responseData = response.data.data

          this.transactionFeePercentage = responseData

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    // END: SETTING

    showAllSupplier() {
      this.$router.push({
        name: 'stock-purchase-supplier',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
    showDetailSupplier(supplierId) {
      this.$router.push({
        name: 'stock-purchase-supplier-detail',
        params: {
          slug: this.$route.params.slug,
          id: supplierId,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
    showDetailProductSupplier($event) {
      this.$router.push({
        name: 'stock-purchase-product-detail',
        params: {
          slug: this.$route.params.slug,
          id: $event.id,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
    showHistoryDetailPurchaseOrder($event) {
      this.$router.push({
        name: 'stock-purchase-history-detail',
        params: {
          slug: this.$route.params.slug,
          orderId: $event.id,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
    addItemQuantity() {
      this.qty++
    },
    subItemQuantity() {
      this.qty--
    },
    checkProductInCart(productId) {
      if (this.stockPurchaseCart.length > 0) {
        const productInCart = this.stockPurchaseCart.find(
          (item) => item.productId === productId
        )
        if (productInCart !== undefined) {
          return productInCart
        }
      }
      return null
    },
    addToCart(params, showMessage = true) {
      let purchaseCartArr = this.stockPurchaseCart
      let message = 'Berhasil memasukkan produk ke dalam keranjang'
      const productInCart = this.checkProductInCart(params.productId)
      if (productInCart !== null) {
        purchaseCartArr = purchaseCartArr.filter(
          (item) => item.productId !== params.productId
        )
        message = 'Berhasil mengupdate keranjang'
      }
      if (params.qty !== 0) {
        purchaseCartArr.push(params)
      }
      this.stockPurchaseCart = purchaseCartArr

      if (showMessage) {
        this.message = message
        setTimeout(() => {
          this.showToast('success')
        }, 500)
      }
    },
  },
}

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.report.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
    </div>

    <div class="relative grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Product Sales Line Chart -->
          <div class="col-span-12 mt-4">
            <div class="intro-y box px-1 py-5 md:mt-5 md:p-5">
              <div
                class="flex w-full flex-col justify-between md:flex-row md:items-center"
              >
                <div class="flex self-center align-middle">
                  <h2 class="mr-3 truncate text-lg font-medium">
                    {{ $t('page.report.productSales.title') }}
                  </h2>
                  <span
                    class="flex self-center text-xs uppercase text-gray-500"
                  >
                    {{ periodicFilter }}
                  </span>
                </div>
                <div
                  class="mt-0 flex w-full items-center justify-center sm:ml-auto md:mt-3"
                >
                  <FilterTimeRange
                    :active="periodicFilter"
                    @change-filter="changeFilterTimeRange($event)"
                  />
                </div>
              </div>
              <div>
                <ReportLineChart
                  v-if="!productSalesChartEmpty"
                  :label="productSalesChartLabel"
                  :item-name="productSalesChartItemName"
                  :data="productSalesChartData"
                  :show-legend="true"
                  :height="275"
                  class="mt-6 -mb-6"
                />
                <NoData v-else />
              </div>
              <div
                v-if="!productSalesChartEmpty"
                class="flex w-full max-w-full flex-row overflow-x-scroll pt-10"
              >
                <ComparisonItem
                  v-for="(activeProduct, index) in activeProductData"
                  :key="activeProduct.id"
                  :title="activeProduct.name"
                  :value="activeProduct.id"
                  :has-close-icon="activeProductData.length > 1"
                  :show-color="true"
                  :color="getColor(index)"
                  custom-class="w-1/2 md:w-1/4 2xl:w-1/6"
                  @delete-comparison="deleteComparison($event)"
                />
                <ComparisonItem
                  :title="$t('page.report.addComparison')"
                  :has-plus-icon="true"
                  value="add"
                  custom-class="w-1/2 md:w-1/4 2xl:w-1/6 bg-slate-700 text-white hover:bg-slate-800"
                  @click="showProductModal = true"
                />
              </div>
            </div>
          </div>
          <!-- END: Product Sales Line Chart -->
        </div>
      </div>
    </div>

    <div
      class="flex w-full flex-wrap items-center justify-end space-y-4 space-x-0 pt-10 md:flex-nowrap md:space-x-4 md:space-y-0"
    >
      <div class="flex w-full justify-end md:w-fit">
        <div class="relative w-full text-slate-500 sm:ml-auto sm:mt-0">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateFilter"
            :options="{
              autoApply: false,
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              format: 'YYYY-MM-DD',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 sm:w-56"
          />
        </div>
        <ExportCSVComponent @export="downloadProductSales" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Product Sales Report -->
          <div class="col-span-12 mt-8">
            <div
              class="intro-y flex w-full flex-col justify-between space-y-2 md:flex-row md:space-y-0"
            >
              <div class="flex align-middle">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>

                <TitleComponent
                  :title="$t($route.meta.title)"
                  :date-string="dateFilter"
                />
              </div>
              <div class="flex align-middle">
                <a href="" class="flex items-center space-x-2 text-primary">
                  <RefreshCcwIcon class="h-4 w-4" />
                  <span>{{ $t('action.reloadData') }}</span>
                </a>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-12 gap-6 md:mt-5">
              <template v-if="isInstituteAdmin">
                <Card
                  :title="$t('page.report.productSales.totalSales')"
                  :is-currency="true"
                  :is-up="cardData.gross_sales_total.is_up"
                  :percentage="cardData.gross_sales_total.percent"
                  :total="cardData.gross_sales_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
                <Card
                  :title="$t('page.report.productSales.totalSalesNett')"
                  :is-currency="true"
                  :is-up="cardData.net_sales_total.is_up"
                  :percentage="cardData.net_sales_total.percent"
                  :total="cardData.net_sales_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
                <Card
                  :title="$t('page.report.productSales.totalPay')"
                  :is-currency="true"
                  :is-up="cardData.payment_total.is_up"
                  :percentage="cardData.payment_total.percent"
                  :total="cardData.payment_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
                <Card
                  :title="$t('page.report.productSales.totalProduct')"
                  :is-currency="false"
                  :is-up="cardData.product_total.is_up"
                  :percentage="cardData.product_total.percent"
                  :total="cardData.product_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
              </template>
              <template v-else>
                <Card
                  :title="$t('page.report.productSales.totalSales')"
                  :is-currency="true"
                  :is-up="cardData.gross_sales_total.is_up"
                  :percentage="cardData.gross_sales_total.percent"
                  :total="cardData.gross_sales_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
                />
                <Card
                  :title="$t('page.report.productSales.totalSalesNett')"
                  :is-currency="true"
                  :is-up="cardData.net_sales_total.is_up"
                  :percentage="cardData.net_sales_total.percent"
                  :total="cardData.net_sales_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
                />
                <Card
                  :title="$t('page.report.productSales.totalProduct')"
                  :is-currency="false"
                  :is-up="cardData.product_total.is_up"
                  :percentage="cardData.product_total.percent"
                  :total="cardData.product_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
                />
              </template>
            </div>
          </div>
          <!-- END: Product Sales Report -->
        </div>
      </div>
    </div>

    <div
      class="flex w-full flex-wrap items-center justify-end space-y-4 space-x-0 pt-10 md:flex-nowrap md:space-x-4 md:space-y-0"
    >
      <div
        class="flex w-full flex-wrap items-center space-y-2 md:flex-nowrap md:space-y-0 md:space-x-2"
      >
        <Multiselect
          v-if="isInstitute"
          v-model="tenantIds"
          :options="tenantOptions"
          class="w-full border-none shadow outline-none ring-0"
          :searchable="true"
          :classes="multiSelectClasses"
          mode="multiple"
          :close-on-select="true"
          :can-clear="true"
          :hide-selected="false"
          :placeholder="$t('formInput.filterTenant')"
        >
          <template #option="{ option, isSelected }">
            <p class="flex w-full items-center justify-between">
              <span>{{ option.label }}</span>
              <span v-if="isSelected(option)" class="text-lg text-gray-500">
                &times;
              </span>
            </p>
          </template>
        </Multiselect>
        <Multiselect
          v-model="categoryIds"
          :options="categoryDataSelect"
          class="w-full border-none shadow outline-none ring-0"
          :disabled="isInstitute && filterTenants === ''"
          :searchable="true"
          :classes="multiSelectClasses"
          mode="multiple"
          :close-on-select="true"
          :can-clear="true"
          :hide-selected="false"
          :loading="isLoadingCategories"
          placeholder="Filter Kategori"
        >
          <template #option="{ option, isSelected }">
            <p class="flex w-full items-center justify-between">
              <span>{{ option.label }}</span>
              <span v-if="isSelected(option)" class="text-lg text-gray-500">
                &times;
              </span>
            </p>
          </template>
        </Multiselect>
        <Multiselect
          v-model="itemIds"
          :options="productItemDataSelect"
          class="w-full border-none shadow outline-none ring-0"
          :searchable="true"
          :classes="multiSelectClasses"
          :disabled="filterTenants !== '' && filterCategories === ''"
          mode="multiple"
          :close-on-select="true"
          :can-clear="true"
          :hide-selected="false"
          :loading="isLoadingItems"
          placeholder="Filter Item"
        >
          <template #option="{ option, isSelected }">
            <p class="flex w-full items-center justify-between">
              <span>{{ option.label }}</span>
              <span v-if="isSelected(option)" class="text-lg text-gray-500">
                &times;
              </span>
            </p>
          </template>
        </Multiselect>
        <ButtonComponent :title="$t('button.apply')" @click="doFilter" />
      </div>
    </div>
    <!-- BEGIN: Product Sales Table -->
    <TableComponent
      :date-string="dateFilter"
      :title="$t('page.report.productSales.productSalesTable')"
      :header="productSalesReportHeader"
      :is-show-search="true"
      :meta-data="metaData"
      :is-not-empty="productSalesReportData.length > 0"
      :is-show-pagination="false"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="data in productSalesReportData" :key="data.product_id">
        <td class="text-center">{{ data.product_name }}</td>
        <td class="text-center">{{ data.product_category }}</td>
        <td class="text-center">{{ data.sales_total }}</td>
        <td class="text-center">{{ data.sales_total_percentage }}</td>
        <!-- <td>
            <Currency
              :total-in-string="data.sales_price"
              wrap-class="justify-center"
            />
          </td> -->
        <!-- <td class="text-center">{{ data.sales_price_percentage }}</td> -->
        <!-- <td><Currency :total-in-string="data.gross_profit" /></td> -->
      </tr>
    </TableComponent>
    <!-- END: Product Sales Table -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <!-- BEGIN: Modal Add Product -->
    <Modal
      :show="showProductModal"
      size="modal-xl"
      :slide-over="true"
      @hidden="showProductModal = false"
    >
      <ModalBody class="p-0">
        <div class="pb-8 text-center md:px-5">
          <TableComponent
            :date-string="$t('info.max6')"
            :title="$t('page.report.productSales.productTable')"
            :header="productPickHeader"
            :is-show-search="true"
            :show-icon="false"
            :meta-data="metaDataProduct"
            :is-not-empty="productData.length > 0"
            @change-filter="changeFilterProduct($event)"
          >
            <tr v-for="product in productData" :key="product.id">
              <td>
                <input
                  id="default-checkbox"
                  type="checkbox"
                  :value="product.id"
                  class="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                  :checked="productIds.includes(product.id)"
                  @change="selectProduct($event, product.id)"
                />
              </td>
              <td>{{ product.name }}</td>
            </tr>
          </TableComponent>
        </div>
        <div class="flex justify-end px-5 pb-8">
          <span
            v-if="showWarningFilter"
            class="flex w-full justify-start text-danger"
            >{{ $t('info.max6Warning') }}</span
          >
          <button
            type="button"
            class="btn btn-outline-primary mr-2 w-24"
            @click="showProductModal = false"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            type="button"
            class="btn w-24"
            :class="[
              productIds.length > 0
                ? 'btn-primary cursor-pointer text-white'
                : 'btn-slate-300 cursor-not-allowed text-slate-100',
            ]"
            @click="saveProductIds"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </ModalBody>
    </Modal>
    <!-- END: Modal Add Product -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import productMixin from '@/mixins/product.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'

import Multiselect from '@vueform/multiselect'

import FilterTimeRange from '@/components/FilterTimeRange.vue'
import TableComponent from '@/components/TableComponent.vue'
import TitleComponent from '@/components/TitleComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import Card from '@/components/Card.vue'
import ComparisonItem from '@/components/ComparisonItem.vue'
import NoData from '@/components/NoData.vue'

import SaleIcon from '@/assets/svg/sale.svg'

export default {
  name: 'ProductSalesPage',
  components: {
    Breadcrumb,
    ReportLineChart,

    TableComponent,
    TitleComponent,
    ButtonComponent,
    ExportCSVComponent,
    Card,
    ComparisonItem,
    FilterTimeRange,
    NoData,
    Multiselect,
    SaleIcon,
  },
  mixins: [globalMixin, tenantMixin, productMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()
    const periodicFilter = ref('daily')
    const productIds = ref([])
    const showProductModal = ref(false)

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
      periodicFilter,
      productIds,
      showProductModal,
    }
  },
  data: () => ({
    isLoadingItems: false,
    productSalesReportHeader: [
      {
        item: 'page.report.productSales.tableHeaders.product',
        customClass: 'text-center',
      },
      {
        item: 'page.report.productSales.tableHeaders.category',
        customClass: 'text-center',
      },
      {
        item: 'page.report.productSales.tableHeaders.totalSale',
        customClass: 'text-center',
      },
      {
        item: 'page.report.productSales.tableHeaders.totalPercent',
        customClass: 'text-center',
      },
      // {
      //   item: 'page.report.productSales.tableHeaders.sales',
      //   customClass: 'text-center',
      // },
      // {
      //   item: 'page.report.productSales.tableHeaders.percentSales',
      //   customClass: 'text-center',
      // },
      // {
      //   item: 'page.report.productSales.tableHeaders.grossProfit',
      //   customClass: '',
      // },
    ],
    productSalesReportData: [],
    cardData: {
      gross_sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      net_sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      payment_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      product_total: {
        total: 0,
        percent: '0',
        is_up: false,
      },
    },
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
      category_ids: '',
      item_ids: '',
    },
    productPickHeader: [
      {
        item: '',
        customClass: '',
      },
      {
        item: 'page.report.productSales.tableHeaders.product',
        customClass: 'text-center',
      },
    ],
    metaDataProduct: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataProduct: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    filterDataProductItem: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    productData: [],
    productDataAll: [],
    productDataUnique: [],
    productDataMapped: [],
    activeProductData: [],
    productSalesChartEmpty: true,
    productSalesChartLabel: [],
    productSalesChartItemName: [],
    productSalesChartData: [],
    productItemDataAll: [],
    productItemDataUnique: [],
    productItemDataSelect: [],
    showWarningFilter: false,

    filterTenants: '',
    filterCategories: '',
    filterItemIds: '',

    tenantIds: [],
    categoryIds: [],
    itemIds: [],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
    productIdComp() {
      return this.productIds.toString()
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.init()
    },
    tenantIds: function (val) {
      const tenantIds = val.toString()
      this.filterData.tenant_ids = tenantIds
      this.filterTenants = tenantIds

      this.itemIds = []
      this.filterData.item_ids = ''
      this.filterItemIds = ''

      if (val.length > 0) {
        this.getAllCategory(tenantIds)
      } else {
        this.categoryDataSelect = []
      }
    },
    categoryIds: function (val) {
      const categoryIds = val.toString()
      this.filterData.category_ids = categoryIds
      this.filterCategories = categoryIds

      this.filterDataProductItem.page = 1
      this.getAllProductItemAPI()
    },
    itemIds: function (val) {
      const itemIds = val.toString()
      this.filterData.item_ids = itemIds
      this.filterItemIds = itemIds
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantIds.push(this.activeUserStore.tenant.id)
        this.isInstitute = false

        this.getAllCategory().then(() => {
          this.getAllProductItemAPI()
        })
      } else {
        this.getAllTenant(false).then(() => {
          this.getAllProductItemAPI()
        })
        this.isInstitute = true
      }
    }
    this.getAllProductAPI(true)
  },
  methods: {
    init() {
      this.getSummary(true)
      this.getProductSales(true)
    },
    async getAllProductAPI(first = false, showMessage = true) {
      await this.store
        .dispatch('product/getAll', {
          order: this.filterDataProduct.order,
          page: this.filterDataProduct.page,
          take: this.filterDataProduct.take,
          q: this.filterDataProduct.search,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.productData = responseData.data
            this.productDataAll.push(...new Set(responseData.data))
            this.productDataUnique = this.uniqByKeepLast(
              this.productDataAll,
              (it) => it.id
            )
            if (this.productData.length > 0 && first) {
              this.productIds.push(this.productData[0].id)
              this.getProductSalesChart(first)
            }

            this.productDataMapped = this.productDataUnique.reduce(
              (filtered, item) => {
                let key = item.id
                if (!filtered[key]) {
                  filtered[key] = null
                }
                filtered[key] = item
                return filtered
              },
              {}
            )
          }
          if (responseData.meta) {
            this.metaDataProduct = responseData.meta
          }

          if (!first && showMessage) {
            this.message = response.data.message

            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getAllProductItemAPI(first = true, showMessage = false) {
      if (first) this.isLoadingItems = true

      await this.store
        .dispatch('product/getAll', {
          order: this.filterDataProductItem.order,
          page: this.filterDataProductItem.page,
          take: this.filterDataProductItem.take,
          q: this.filterDataProductItem.search,
          category_ids: this.filterData.category_ids,
        })
        .then((response) => {
          if (first) this.isLoadingItems = false

          const responseData = response.data.data
          if (responseData.data) {
            if (first) {
              this.productItemDataAll = responseData.data
            } else {
              this.productItemDataAll.push(...new Set(responseData.data))
            }
            this.productItemDataUnique = this.uniqByKeepLast(
              this.productItemDataAll,
              (it) => it.id
            )

            const productItemDataSelect = []
            this.productItemDataUnique.forEach((item) => {
              productItemDataSelect.push({
                label: item.name,
                value: item.id,
              })
            })
            this.productItemDataSelect = productItemDataSelect
          }
          if (responseData.meta) {
            if (
              'hasNextPage' in responseData.meta &&
              responseData.meta.hasNextPage
            ) {
              this.filterDataProductItem.page += 1
              this.getAllProductItemAPI(false)
            }
          }

          if (showMessage) {
            this.message = response.data.message

            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (first) this.isLoadingItems = true

          this.message = e.message

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getSummary(first = false) {
      this.isLoading = true

      await this.$store
        .dispatch('sales/salesPerProductSummary', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          category_ids: this.filterData.category_ids,
          product_ids: this.filterData.item_ids,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.cardData.gross_sales_total = responseData.gross_sales_total
            this.cardData.net_sales_total = responseData.net_sales_total
            this.cardData.payment_total = responseData.payment_total
            this.cardData.product_total = responseData.product_total
          }
          if (!first) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getProductSales(first = false, showLoading = false) {
      if (showLoading) this.isLoading = true

      await this.store
        .dispatch('sales/salesPerProduct', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          page: this.filterData.page,
          take: this.filterData.take,
          tenant_ids: this.filterData.tenant_ids,
          category_ids: this.filterData.category_ids,
          product_ids: this.filterData.item_ids,
          product_name: this.filterData.search,
        })
        .then((response) => {
          if (showLoading) this.isLoading = false

          const responseData = response.data.data
          this.productSalesReportData = responseData

          if (!first) {
            this.message = response.data.message

            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (showLoading) this.isLoading = false

          this.message = e.message

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getProductSalesChart(first = false) {
      await this.store
        .dispatch('sales/salesPerProductChart', {
          periodic: this.periodicFilter,
          product_ids: this.productIds.toString(),
        })
        .then((response) => {
          const responseData = response.data
          if (responseData.data) {
            const filteredChart = responseData.data.reduce((filtered, item) => {
              let key = item.product_id
              if (!filtered[key]) {
                filtered[key] = null
              }
              filtered[key] = item.charts
              return filtered
            }, {})

            this.productSalesChartEmpty = true
            this.productSalesChartItemName = []
            this.activeProductData = []
            this.productSalesChartData = []
            this.productIds.forEach((item, index) => {
              if (this.productDataMapped[item] !== undefined) {
                this.activeProductData.push(this.productDataMapped[item])
                this.productSalesChartItemName.push(
                  this.productDataMapped[item].name.toUpperCase()
                )
              }
              if (filteredChart[item] !== undefined) {
                this.productSalesChartEmpty = false
              }
              let labels = []
              let values = []
              filteredChart[item].forEach((item) => {
                labels.push(item.name)
                values.push(item.total)
              })
              if (index === 0) {
                this.productSalesChartLabel = labels
              }
              this.productSalesChartData.push(values)
            })
          }

          if (!first) {
            this.message = response.data.message

            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async downloadProductSales(fileType) {
      await this.store
        .dispatch('sales/salesPerProductDownload', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
          category_ids: this.filterData.category_ids,
          product_ids: this.filterData.item_ids,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'report_sales.csv'
          )
          saveAs(response.data, filename)

          this.message = response.data.message

          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          this.message = e.message

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    changeFilterTimeRange(event) {
      this.periodicFilter = event
      this.getProductSalesChart()
    },
    changeFilter(event) {
      const tenantIds = this.filterData.tenant_ids
      const categoryIds = this.filterData.category_ids
      const itemsIds = this.filterData.item_ids

      this.filterData = event.filterData
      this.filterData.tenant_ids = tenantIds
      this.filterData.category_ids = categoryIds
      this.filterData.item_ids = itemsIds
      this.getProductSales()
    },
    saveProductIds() {
      if (this.productIds.length > 0) {
        if (this.productIds.length > 6) {
          this.showWarningFilter = true
          return
        }
        this.showWarningFilter = false
        this.showProductModal = false
        this.getProductSalesChart()
      }
    },
    changeFilterProduct(event) {
      this.filterDataProduct = event.filterData
      this.getAllProductAPI(false, false)
    },
    selectProduct(event, productIdComp) {
      if (event.target.checked) {
        this.productIds.push(productIdComp)
      } else {
        this.productIds = this.productIds.filter(
          (item) => item != productIdComp
        )
      }
    },
    deleteComparison(event) {
      this.productIds = this.productIds.filter((item) => item != event.item)
      this.getProductSalesChart()
    },
    doFilter() {
      this.filterData.tenant_ids = this.filterTenants
      this.filterData.category_ids = this.filterCategories
      this.filterData.item_ids = this.filterItemIds

      this.getProductSales(false, true)
      // this.getSummary()
    },
  },
}
</script>

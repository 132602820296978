export default {
  appName: 'Dashboard InKanteen',
  language: {
    id: 'ID',
    en: 'EN',
    languages: 'Languages',
    change: 'Change Language',
    bahasa: 'Bahasa',
    english: 'English',
  },
  menu: {
    home: 'Home',
    account: 'Account',
    dashboard: 'Dashboard',
    report: {
      index: 'Report',
      summary: 'Report Summary',
      orderDetail: 'Detail Order',
      tenantSales: 'Tenant Sales',
      dailySales: 'Daily Sales',
      productSales: 'Product Sales',
      paymentMethod: 'Payment Method Report',
      withdrawal: 'Withdrawal Report',
      promotion: 'Promotion Report',
    },
    analytic: {
      index: 'Analytic',
      averageTransaction: 'Average Transaction',
      growthPercentage: 'Growth Percentage',
      stockTurnOver: 'Stock Turnover',
      ecommerceIndex: 'E-commerce Index',
      satisfactionIndex: 'Satisfaction Index',
    },
    profile: {
      index: 'Profile',
      account: 'Account Information',
      bussiness: 'Bussiness Information',
    },
  },
  action: {
    profile: 'Profile',
    sale: 'Sale',
    finance: 'Finance',
    setting: 'Setting',
    logout: 'Logout',
    reloadData: 'Reload Data',
    exportCSV: 'Export CSV',
  },
  page: {
    dashboard: {
      sale: 'Sale',
      grossProfit: 'Gross Profit',
      netProfit: 'Net Profit',
      transactionTotal: 'Transaction Total',
      bestSellingMenu: 'Best Selling Menu',
      paymentMethod: 'Payment Method',
      higherThanYesterday: 'Higher than yesterday',
      lowerThanYesterday: 'Lower than yesterday',
    },
    profile: {
      index: 'Profile',
      account: 'Profile Account',
      accessInformation: 'Access Rights Information',
      addressInformation: 'Address Information',
      changePassword: 'Change Password',
      accessRight: 'Access Rights',
    },
    bussiness: {
      index: 'Bussiness',
      information: 'Bussiness Information',
      addressInformation: 'Address Information',
      socialMedia: 'Social Media',
    },
    report: {
      withdrawal: {
        todaySales: 'Today Sales',
        totalSales: 'Total Sales',
        balance: 'Balance',
        dailySales: 'Daily Sales',
        tableHeaders: {
          time: 'Time',
          sales: 'Sales',
          grossProfit: 'Gross Profit',
          totalTransactions: 'Total Transactions',
          totalProducts: 'Total Products',
          totalWithdrawal: 'Total Withdrawal',
          withdrawalMethod: 'Withdrawal Method',
          sendTo: 'Send To',
          status: 'Status',
        },
        withdrawalReport: 'Withdrawal Report',
      },
    },
    setting: 'Setting',
  },
  general: {
    greeting: 'Welcome!',
    hello: 'Hello',
    tnc: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    optional: 'optional',
  },
  info: {
    dataNotFound: 'Data not found',
    loginGreeting: 'A few more clicks to sign in to your account.',
    loginGreetingDetail: 'Manage your accounts in one place',
    currentVersion: 'Current Version Dashboard',
    pageMissing: 'Oops. This page has gone missing',
    pageMissingInstruction:
      'You may have mistyped the address or the page may have moved.',
  },
  confirmation: {
    logout: 'Are you sure want to logout?',
  },
  button: {
    submit: 'Submit',
    login: 'Login',
    logout: 'Logout',
    cancel: 'Cancel',
    register: 'Register',
    sendEmail: 'Send Email',
    sendOTP: 'Send OTP',
    resetPassword: 'Reset Password',
    forgotPassword: 'Forgot Password?',
    saveChanges: 'Save Changes',
    connect: 'Connect',
    connected: 'Connected',
    backToHome: 'Back to Home',
    apply: 'Apply',
  },
  formInfo: {
    notLogin: 'You are not logged in',
    registerSuccess: 'You have successfully registered',
    registerFailed: 'Registration failed',
    loginSuccess: 'Login Success',
    loginFailed: 'Login Failed',
    saveChangesSuccess: 'Changes Saved Successfully',
    saveChangesFailed: 'Changes Failed to Save',
    loginDesc: 'Enter your email and password',
    loginCheck: 'By signin up, you agree to our',
  },
  formLabel: {
    name: 'Nama',
    fullName: 'Full Name',
    bussinessName: 'Bussiness Name',
    tenantName: 'Tenant Name',
    email: 'Email',
    phone: 'Phone Number',
    password: 'Password',
    passwordConfirmation: 'Password Confirmation',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    newPasswordConfirmation: 'New Password Confirmation',
    accessRight: 'Access Right',
    pin: 'OTP',
    description: 'Description',
    website: 'Website',
    province: 'Province',
    city: 'City',
    district: 'District',
    village: 'Village',
    postalCode: 'Postal Code',
    address: 'Address',
    select: {
      province: 'Select Province',
      city: 'Select City',
      district: 'Select District',
      village: 'Select Village',
    },
  },
  formInput: {
    enterName: 'Please enter your name',
    enterFullName: 'Please enter your full name',
    enterBussinessName: 'Please enter your bussiness name',
    enterTenantName: 'Please enter tenant name',
    enterDescription: 'Please enter desription',
    enterWebsite: 'Please enter website',
    enterEmail: 'Please enter your email',
    enterPhone: 'Please enter your phone number',
    enterPassword: 'Please enter your password',
    enterPIN: 'Please enter your PIN',
    enterAddress: 'Please enter your address',
    enterPostalCode: 'Please enter postal code',
  },
  formError: {
    name: {
      required: 'Name is required',
    },
    fullName: {
      required: 'Full Name is required',
    },
    bussinessName: {
      required: 'Bussiness Name is required',
    },
    tenantName: {
      required: 'Tenant Name is required',
    },
    email: {
      required: 'Email is required',
      validEmail: 'Email must be valid email address',
    },
    phone: {
      required: 'Phone number is required',
    },
    pin: {
      required: 'PIN is required',
    },
    address: {
      required: 'Address is required',
    },
    postalCode: {
      required: 'Postal Code is required',
    },
    password: {
      required: 'Password is required',
      minLength: 'Password at least 6 characters',
      alphaNumeric: 'Password only allowed alphanumeric and special characters',
    },
    wrongEmailOrPassword: 'Wrong email or password',
    validEmailAddress: 'This field must be valid email address',
    validPhoneNumber:
      'Please enter valid WhatsApp number, min 10 digit numbers',
    fieldAlphabet: 'must only contain alphabetic characters',
    fieldAlphaNumeric: 'must only contain alphanumeric characters',
    fieldNumeric: 'must only contain numeric characters',
  },
}

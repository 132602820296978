import axios, { routePathAPI } from '@/plugins/axios.js'
import router from '@/router'

// initial state
const state = () => ({
  isUserLoggedIn: false,
  activeMenu: null,
})

// getters
const getters = {
  userMenu(state) {
    return (
      JSON.parse(localStorage.getItem('inkanteenDashboard.userMenu')) || null
    )
  },
  userFeature(state) {
    return (
      JSON.parse(localStorage.getItem('inkanteenDashboard.userFeature')) || null
    )
  },
  activeMenu(state) {
    if (state.activeMenu !== null) return state.activeMenu
    else if (
      localStorage.getItem('inkanteenDashboard.activeMenu') !== null &&
      localStorage.getItem('inkanteenDashboard.activeMenu') !== undefined
    ) {
      return localStorage.getItem('inkanteenDashboard.activeMenu')
    } else {
      return 'sales'
    }
  },
}

// actions
const actions = {
  access({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/role-access`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          if (response.status === 200) {
            const data = response.data.data
            await commit('UPDATE_USER_MENU', data.menus)
            await commit('UPDATE_USER_FEATURES', data.features)

            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllMenu({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q

      axios
        .get(`${routePathAPI()}/menu`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllMenuFeature({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q
      if (payload.menu_id) params.menu_id = payload.menu_id

      axios
        .get(`${routePathAPI()}/menu/feature`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  activeMenu({ commit }, payload) {
    commit('SET_ACTIVE_MENU', payload)
  },
  resetActiveMenu({ commit }, payload) {
    commit('RESET_ACTIVE_MENU', payload)
  },
}

// mutations
const mutations = {
  UPDATE_USER_MENU(state, payload) {
    // Store data in localStorage
    localStorage.setItem('inkanteenDashboard.userMenu', JSON.stringify(payload))
  },
  UPDATE_USER_FEATURES(state, payload) {
    // Store data in localStorage
    localStorage.setItem(
      'inkanteenDashboard.userFeature',
      JSON.stringify(payload)
    )
  },
  SET_ACTIVE_MENU(state, payload) {
    state.activeMenu = payload
    localStorage.setItem('inkanteenDashboard.activeMenu', payload)
  },
  RESET_ACTIVE_MENU(state) {
    state.activeMenu = null
    localStorage.removeItem('inkanteenDashboard.activeMenu')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<script setup>
import debounce from 'lodash.debounce'
import { ref, computed, watch } from 'vue'
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const emit = defineEmits(['update:modelValue', 'selected'])

const props = defineProps({
  modelValue: Object,
  options: {
    type: Array,
    default: () => [],
  },
  loadOptions: Function,
  createOption: Function,
})

const options = ref(props.options)
const isLoading = ref(false)

const queryOption = computed(() => {
  return query.value === ''
    ? null
    : {
        missing: true,
        label: query.value,
      }
})

let query = ref('')
const updateQuery = debounce((q) => {
  if (props.loadOptions) {
    isLoading.value = true
    props.loadOptions(q, (results) => {
      options.value = results

      if (
        props.modelValue &&
        !options.value.some((o) => {
          return o.value === props.modelValue?.value
        })
      ) {
        options.value.unshift(props.modelValue)
      }
      isLoading.value = false
    })
  }
}, 500)

watch(
  query,
  (q) => {
    updateQuery(q)
  },
  { immediate: true }
)

let filteredOptions = computed(() =>
  query.value === ''
    ? options.value
    : options.value.filter((option) =>
        option.label
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, ''))
      )
)

function handleUpdateModelValue(selected) {
  emit('update:modelValue', selected)
  emit('selected', selected)

  if (props.createOption && selected?.missing) {
    isLoading.value = true
    props.createOption(selected, (option) => {
      emit('update:modelValue', option)
      isLoading.value = false
    })
  }
}
</script>

<template>
  <Combobox
    by="value"
    :model-value="props.modelValue"
    @update:model-value="handleUpdateModelValue"
  >
    <div class="relative mt-1">
      <div
        class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
      >
        <ComboboxInput
          class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
          :display-value="(option) => option.label"
          @change="query = $event.target.value"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>
      </div>
      <TransitionRoot
        leave="transition ease-in duration-100"
        leave-from="opacity-100"
        leave-to="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          class="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <!-- !queryOption && -->
          <div
            v-if="
              filteredOptions.length === 0 && !isLoading && !props.createOption
            "
            class="relative cursor-default select-none py-2 px-4 text-xs text-gray-700"
          >
            {{ $t('formInfo.dataNotFound') }}
          </div>

          <div
            v-if="isLoading"
            class="relative cursor-default select-none py-2 px-4 text-gray-700"
          >
            Loading...
          </div>

          <template v-if="!isLoading">
            <ComboboxOption
              v-if="
                queryOption && !filteredOptions.length && props.createOption
              "
              v-slot="{ active }"
              as="template"
              :value="queryOption"
            >
              <li
                class="relative cursor-default select-none py-2 px-4"
                :class="{
                  'bg-teal-600 text-white': active,
                  'text-gray-900': !active,
                }"
              >
                Create "{{ queryOption.label }}"
              </li>
            </ComboboxOption>
            <ComboboxOption
              v-for="option in filteredOptions"
              :key="option.value"
              v-slot="{ selected, active }"
              as="template"
              :value="option"
            >
              <li
                class="relative cursor-default select-none py-2 px-4"
                :class="{
                  'bg-teal-600 text-white': active,
                  'text-gray-900': !active,
                }"
              >
                <span
                  class="block truncate"
                  :class="{ 'font-medium': selected, 'font-normal': !selected }"
                >
                  {{ option.label }}
                </span>
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3"
                  :class="{ 'text-white': active, 'text-teal-600': !active }"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </template>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</template>

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      v-if="!checkEmptyObject(activeSupplierProductData)"
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.purchaseOrder.stockPurchase')"
          link="stock-purchase"
          :subtitles="[
            {
              title: 'Daftar supplier',
              link: 'stock-purchase-supplier',
            },
            {
              title: activeSupplierProductData.name,
              link: null,
            },
          ]"
        />
      </div>
    </div>
    <div
      v-if="!checkEmptyObject(activeSupplierProductData)"
      class="mt-8 flex w-full flex-col justify-center space-y-4"
    >
      <div class="flex w-full space-x-2 rounded-lg bg-white px-2 py-8">
        <div class="flex w-2/12 items-center justify-center">
          <ImageLoader
            :alt="activeSupplierProductData.name"
            :image="activeSupplierProductData.image"
          />
        </div>
        <div class="flex w-10/12 flex-col pr-8">
          <div class="flex w-full flex-col border-b border-gray-300 pb-2">
            <p class="font-semibold">{{ activeSupplierProductData.name }}</p>
            <div class="flex space-x-1 text-xs text-gray-500">
              <Currency
                :total-in-string="activeSupplierProductData.sale_price"
              />
              <template v-if="false">
                <p>•</p>
                <p
                  v-if="'master_unit' in activeSupplierProductData"
                  class="space-x-1"
                >
                  <span>Jumlah stok supplier :</span>
                  <span class="text-primary">{{
                    activeSupplierProductData.master_unit.name
                  }}</span>
                </p></template
              >
              <p>•</p>
              <p
                v-if="'master_unit' in activeSupplierProductData"
                class="space-x-1"
              >
                <span>Unit :</span>
                <span class="text-primary">{{
                  activeSupplierProductData.master_unit.name
                }}</span>
              </p>
            </div>
          </div>
          <div class="flex items-center justify-between py-2">
            <p class="font-semibold">
              {{ activeSupplierProductData.supplier.name }}
            </p>
            <p
              class="cursor-pointer text-xs text-primary"
              @click="showDetailSupplier(supplierId)"
            >
              Lihat supplier
            </p>
          </div>
          <div class="mt-6 flex flex-col space-y-2">
            <p class="font-medium">Pesan bahan</p>
            <div class="flex">
              <QtyCounter
                :show-delete="false"
                :auto-delete="false"
                :last-qty="qty"
                @sub-item="subItemQuantity()"
                @add-item="addItemQuantity()"
              />
            </div>
          </div>

          <div class="mt-6 flex justify-between">
            <div class="flex space-x-2">
              <p>Subtotal</p>
              <Currency
                :total-in-string="activeSupplierProductData.sale_price * qty"
                wrap-class="font-semibold"
              />
            </div>
            <button
              class="rounded-lg bg-primary px-4 py-1 text-sm text-white"
              @click="
                addToCart({
                  supplierId,
                  productData: activeSupplierProductData,
                  productId: activeSupplierProductData.id,
                  qty,
                  supplierData: activeSupplierProductData.supplier,
                })
              "
            >
              Masukkan keranjang
            </button>
          </div>
        </div>
      </div>
      <div class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8">
        <div class="flex w-full items-center justify-between text-base">
          <span>Lainnya dari supplier ini</span>
          <div class="ml-4 flex shadow">
            <input
              v-model="searchSupplierProduct"
              class="w-full border-none p-2 outline-none"
              type="text"
              :placeholder="$t('formLabel.searchFoo', { foo: 'Product' })"
            />
            <button
              class="text-blue hover:text-blue-light flex w-auto items-center justify-end p-2"
              @click="filterSupplierProduct"
            >
              <SearchIcon />
            </button>
          </div>
        </div>

        <div
          v-if="
            supplierId !== null &&
            supplierId !== undefined &&
            supplierId !== '' &&
            supplierId in supplierProductBySupplier
          "
          class="mt-2 grid grid-cols-3 gap-6 md:grid-cols-5"
        >
          <template v-if="supplierProductBySupplier[supplierId].length > 0">
            <ProductSupplierItem
              v-for="product in supplierProductBySupplier[supplierId].filter(
                (item) => item.id !== activeSupplierProductData.id
              )"
              :key="product.id"
              :product="product"
              :disabled="product.id === activeSupplierProductData.id"
              @show-detail="changeId"
            />
          </template>
          <div
            v-else-if="!isLoading"
            class="col-span-3 w-full text-center text-gray-500 md:col-span-5"
          >
            Product
            <span class="font-bold">{{ searchSupplierProduct }}</span> tidak
            ditemukan
          </div>
        </div>
        <div
          v-if="
            supplierId !== null &&
            supplierId !== undefined &&
            supplierId !== '' &&
            supplierId in supplierProductBySupplier &&
            supplierProductBySupplier[supplierId].length > 0
          "
          class="flex w-full justify-between"
        >
          <ChevronLeftIcon
            :class="[
              !metaDataProductSupplier.hasPreviousPage
                ? 'cursor-not-allowed opacity-25'
                : 'cursor-pointer',
            ]"
            class="h-6 w-6"
            @click="metaDataProductSupplier.hasPreviousPage ? prevPage() : ''"
          />
          <ChevronRightIcon
            :class="[
              !metaDataProductSupplier.hasNextPage ||
              metaDataProductSupplier.pageCount === metaDataProductSupplier.page
                ? 'cursor-not-allowed opacity-25'
                : 'cursor-pointer',
            ]"
            class="h-6 w-6"
            @click="metaDataProductSupplier.hasNextPage ? nextPage() : ''"
          />
        </div>
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>
<script>
import debounce from 'lodash.debounce'
import globalMixin from '@/mixins/global.js'
import stockPurchaseMixin from '@/mixins/stockPurchase.js'
import tenantMixin from '@/mixins/tenant.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import ProductSupplierItem from '@/components/item/ProductSupplierItem.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import Currency from '@/components/Currency.vue'
import QtyCounter from '@/components/QtyCounter.vue'

export default {
  name: 'StockPurchaseProductDetail',
  components: {
    Breadcrumb,

    ProductSupplierItem,
    ImageLoader,
    Currency,
    QtyCounter,
  },
  mixins: [globalMixin, stockPurchaseMixin, tenantMixin],
  watch: {
    searchSupplierProduct: debounce(function (newVal, oldVal) {
      this.filterSupplierProduct()
    }, 500),
  },
  mounted() {
    this.qty = 1
    this.activeSupplierProductId = this.$route.params.id
    if (
      this.activeSupplierProductId !== undefined &&
      this.activeSupplierProductId !== null
    ) {
      if (this.activeUserStore !== null) {
        const role = this.activeUserStore.role.role
        if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
          this.tenantId = this.activeUserStore.tenant.id

          this.getDetailProductSupplier(this.activeSupplierProductId).then(
            () => {
              if (
                !this.checkEmptyObject(this.activeSupplierProductData) &&
                'supplier' in this.activeSupplierProductData
              ) {
                this.supplierId = this.activeSupplierProductData.supplier.id

                this.getAllProductSupplier(
                  false,
                  false,
                  null,
                  null,
                  this.supplierId,
                  false
                )
              }
            }
          )
          this.isInstitute = false
        } else {
          this.getAllTenant(true).then(() => {
            this.getDetailProductSupplier(this.activeSupplierProductId).then(
              () => {
                if (
                  !this.checkEmptyObject(this.activeSupplierProductData) &&
                  'supplier' in this.activeSupplierProductData
                ) {
                  this.supplierId = this.activeSupplierProductData.supplier.id
                  this.getAllProductSupplier(
                    false,
                    false,
                    null,
                    null,
                    this.supplierId,
                    false
                  )
                }
              }
            )
          })
          this.isInstitute = true
        }
      }
    }
  },
  methods: {
    filterSupplierProduct() {
      this.getAllProductSupplier(
        false,
        true,
        this.searchSupplierProduct,
        null,
        this.supplierId
      )
    },
    changeId($event) {
      this.qty = 1
      this.activeSupplierProductId = $event.id
      this.getDetailProductSupplier(this.activeSupplierProductId).then(() => {
        if (
          !this.checkEmptyObject(this.activeSupplierProductData) &&
          'supplier' in this.activeSupplierProductData
        ) {
          this.supplierId = this.activeSupplierProductData.supplier.id

          this.getAllProductSupplier(
            false,
            false,
            null,
            null,
            this.supplierId,
            false
          )
        }
      })
    },
    prevPage() {
      this.filterDataProductSupplier.page -= 1
      this.getAllProductSupplier(
        false,
        false,
        null,
        null,
        this.supplierId,
        false
      )
    },
    nextPage() {
      this.filterDataProductSupplier.page += 1
      this.getAllProductSupplier(
        false,
        false,
        null,
        null,
        this.supplierId,
        false
      )
    },
  },
}
</script>

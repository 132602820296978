<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.inventory')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex">
      <ButtonComponent
        :title="$t('menu.setting.addInventoryAdjustment')"
        @click="addAdjustmentModal"
      >
        <template #icon> + </template>
      </ButtonComponent>
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Product List -->
        <TableComponent
          :is-show-search="true"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :title="$t('page.inventoryAdjustment.tableHeaders.title')"
          :header="adjustmentListHeader"
          :is-not-empty="inventoryAdjustmentData.length > 0"
          :meta-data="metaData"
          :is-show-search-with-filter="false"
          @change-filter="changeFilter($event)"
          @change-filter-dropdown="changeFilterDropdown($event)"
        >
          <template #icon>
            <BookIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
          </template>

          <tr
            v-for="data in inventoryAdjustmentData"
            :key="data.id"
            class="cursor-pointer"
            @click="showDetail(data)"
          >
            <td>#{{ data.code }}</td>
            <td class="text-center">
              {{ formattedDate(subDate(data.date)) }}
            </td>
            <td>{{ data.reason }}</td>
            <td class="text-center">{{ data.note }}</td>
          </tr>
        </TableComponent>
        <!-- END: Product List -->
      </div>
    </div>

    <Modal :show="showAddModal" size="modal-xl" @hidden="showAddModal = false">
      <Form
        ref="form"
        v-slot="{ errors }"
        :validation-schema="inventoryAdjustmentSchema"
        :initial-errors="{}"
        :initial-touched="{}"
        :validate-on-mount="false"
        @submit="saveInvetoryAdjustment"
      >
        <ModalBody class="p-4">
          <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
            <div class="flex w-full">
              <p class="font-bold">Buat Penyesuaian Baru</p>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-sm font-bold text-gray-700"
                    for="category"
                  >
                    Pilih alasan
                  </label>
                </div>
                <div class="text-left">
                  <Field v-slot="{ field }" v-model="category" name="category">
                    <select
                      id="category"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-sm leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.category !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                    >
                      <option
                        v-for="invCat in inventoryCategories"
                        :key="invCat.id"
                        :value="invCat.id"
                      >
                        {{ invCat.name }}
                      </option>
                    </select>
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.category !== undefined">
                      {{ $t(errors.category) }}
                    </template>
                  </span>
                </div>
              </div>
              <div
                v-if="getCategory(category) === 'DAMAGE'"
                class="flex w-full flex-col space-y-2"
              >
                <div>
                  <label
                    class="block text-left text-sm font-bold text-gray-700"
                    for="reason"
                  >
                    Pilih detail alasan
                  </label>
                </div>
                <div class="text-left">
                  <Field v-slot="{ field }" v-model="reason" name="reason">
                    <select
                      id="reason"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-sm leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.reason !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                    >
                      <option
                        v-for="(detail, key) in detailReason['DAMAGE']"
                        :key="key"
                        :value="$t(detail.label)"
                      >
                        {{ $t(detail.label) }}
                      </option>
                    </select>
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.reason !== undefined">
                      {{ $t(errors.reason) }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-sm font-bold text-gray-700"
                    for="notes"
                  >
                    Catatan
                  </label>
                </div>
                <div class="text-left">
                  <Field v-slot="{ field }" v-model="notes" name="notes">
                    <textarea
                      id="notes"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-sm leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.notes !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      placeholder="Catatan"
                    ></textarea>
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.notes !== undefined">
                      {{ $t(errors.notes) }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6">
                <TableComponent
                  :is-show-search="false"
                  :is-show-pagination="false"
                  :show-title="false"
                  :header="
                    getHeadersStockAdjustment(getCategory(category, 'all'))
                  "
                  :add-empty-header="true"
                  wrapper-class="-mt-3"
                >
                  <template v-if="items.length > 0">
                    <tr
                      v-for="(item, key) in items"
                      :key="key"
                      class="cursor-pointer"
                    >
                      <td>
                        <ComboBox
                          v-model="stockItems[key]"
                          :load-options="searchIngredientStock"
                        />
                      </td>
                      <td class="text-center">
                        <span
                          v-if="
                            stockItems[key] !== undefined &&
                            !checkEmptyObject(stockItems[key]) &&
                            'stock_id' in stockItems[key]
                          "
                        >
                          {{ getInventoryStock(stockItems[key].stock_id) }}
                        </span>
                        <span v-else>-</span>
                      </td>
                      <td class="text-center">
                        <input
                          v-model="ingredientItems[key]"
                          type="number"
                          required="required"
                          class="w-full appearance-none rounded border focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                          :class="[
                            ingredientItemError
                              ? 'border-red-400'
                              : 'border-gray-200',
                          ]"
                        />
                      </td>
                      <td class="text-center">
                        <span
                          v-if="
                            stockItems[key] !== undefined &&
                            !checkEmptyObject(stockItems[key]) &&
                            'stock_id' in stockItems[key]
                          "
                        >
                          {{
                            countStockAffected(
                              getInventoryStock(stockItems[key].stock_id),
                              getCategory(category, 'all'),
                              ingredientItems[key]
                            )
                          }}
                        </span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <TrashIcon
                          class="inline cursor-pointer text-danger hover:text-gray-500"
                          @click="deleteItem(item.id)"
                        />
                      </td>
                    </tr>
                  </template>
                  <tr
                    class="cursor-pointer"
                    :class="
                      stockItemError ? 'border border-red-500 text-red-500' : ''
                    "
                    @click="addNewItem()"
                  >
                    <td colspan="5" class="text-center font-semibold">
                      + Tambah bahan
                    </td>
                  </tr>
                </TableComponent>
              </div>
            </div>

            <div class="flex justify-end space-x-2 px-5">
              <button
                type="button"
                class="w-24 text-primary"
                @click.stop="showAddModal = false"
              >
                {{ $t('button.cancel') }}
              </button>
              <button type="submit" class="btn btn-primary w-24">
                {{ $t('button.save') }}
              </button>
            </div>
          </div>
        </ModalBody>
      </Form>
    </Modal>

    <!-- BEGIN: Modal Detail -->
    <Modal
      :show="showDetailModal"
      size="modal-xl"
      @hidden="showDetailModal = false"
    >
      <ModalBody
        v-if="!checkEmptyObject(inventoryAdjustmentActiveData)"
        class="p-4 text-base md:text-sm"
      >
        <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
          <div class="flex flex-col space-y-3">
            <div class="flex flex-col justify-between pb-5 md:flex-row">
              <p class="font-bold">
                {{ $t('menu.setting.inventoryAdjustment') }}
              </p>
              <p class="text-slate-500">
                {{
                  formattedDate(
                    subDate(inventoryAdjustmentActiveData.date),
                    'dddd, DD MMMM YYYY'
                  )
                }}
              </p>
            </div>

            <div class="flex flex-col justify-between md:flex-row">
              <p>
                {{ $t('page.inventoryAdjustment.tableHeaders.adjustmentNo') }}
              </p>
              <p>#{{ inventoryAdjustmentActiveData.code }}</p>
            </div>
            <div class="flex flex-col justify-between md:flex-row">
              <p>{{ $t('page.inventoryAdjustment.tableHeaders.reason') }}</p>
              <p>{{ inventoryAdjustmentActiveData.reason }}</p>
            </div>
            <div class="flex flex-col justify-between md:flex-row">
              <p>{{ $t('page.inventoryAdjustment.tableHeaders.note') }}</p>
              <p>
                <span v-if="inventoryAdjustmentActiveData.note">
                  {{ inventoryAdjustmentActiveData.note }}
                </span>
                <span v-else>-</span>
              </p>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-6">
            <TableComponent
              :is-show-search="false"
              :is-show-pagination="false"
              :show-title="false"
              :header="
                getHeadersStockAdjustment(
                  inventoryAdjustmentActiveData.category_code
                )
              "
              :is-not-empty="inventoryAdjustmentActiveData.items.length > 0"
              wrapper-class="-mt-5"
            >
              <tr
                v-for="(item, key) in inventoryAdjustmentActiveData.items"
                :key="key"
                class="cursor-pointer"
              >
                <td>
                  <span v-if="'ingredient' in item">
                    {{ item.ingredient.name }}
                  </span>
                </td>
                <td class="text-center">{{ item.in_stock }}</td>
                <td class="text-center">{{ item.quantity }}</td>
                <td class="text-center">
                  {{
                    countStockAffected(
                      item.in_stock,
                      inventoryAdjustmentActiveData.category_code,
                      item.quantity
                    )
                  }}
                </td>
              </tr>
            </TableComponent>
          </div>
        </div>

        <div class="flex justify-end space-x-2 px-5">
          <button
            type="button"
            class="btn btn-primary w-24"
            @click="closeModalDetail"
          >
            {{ $t('button.close') }}
          </button>
        </div>
      </ModalBody>
    </Modal>
    <!-- END: Modal Detail -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import inventoryMixin from '@/mixins/inventory.js'
import * as Yup from 'yup'

import { Form, Field } from 'vee-validate'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import ComboBox from '@/components/select-search/ComboBox.vue'

import BookIcon from '@/assets/svg/menu/book.svg'
import TrashIcon from '@/assets/svg/trash.svg'

export default {
  name: 'InventoryAdjustmentPage',
  components: {
    Form,
    Field,
    Breadcrumb,
    TableComponent,
    ButtonComponent,
    ComboBox,

    BookIcon,
    TrashIcon,
  },
  mixins: [globalMixin, tenantMixin, inventoryMixin],
  data: () => ({
    adjustmentListHeader: [
      {
        item: 'page.inventoryAdjustment.tableHeaders.adjustmentNo',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableHeaders.date',
        customClass: 'text-center',
      },
      {
        item: 'page.inventoryAdjustment.tableHeaders.reason',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableHeaders.note',
        customClass: 'text-center',
      },
    ],
    detailAdjustmentReceiveListHeader: [
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.name',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.stock',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.receiveStock',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.newestTotal',
        customClass: '',
      },
    ],
    detailAdjustmentCountListHeader: [
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.name',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.stock',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.countStock',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.newestTotal',
        customClass: '',
      },
    ],
    detailAdjustmentReduceListHeader: [
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.name',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.stock',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.reduceStock',
        customClass: '',
      },
      {
        item: 'page.inventoryAdjustment.tableDetailHeaders.newestTotal',
        customClass: '',
      },
    ],

    showAddModal: false,
    showDetailModal: false,

    // Form Validation
    inventoryAdjustmentSchema: Yup.object().shape({}),
    stockItemError: false,
    ingredientItemError: false,
  }),
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllInventoryAdjustment()
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getAllInventoryAdjustment()
        })
        this.isInstitute = true
      }
    }
    this.getAllInventoryCategory(false, false, true)
  },
  methods: {
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllInventoryAdjustment()
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getAllInventoryAdjustment()
    },
    addAdjustmentModal() {
      this.showAddModal = true
      this.resetData(false)
    },
    resetData(afterSave = true) {
      this.notes = ''
      this.reason = ''
      this.items = []
      this.stockItems = []
      this.ingredientItems = []
      this.stockItemError = false
      this.ingredientItemError = false

      //   setTimeout(() => {

      // if (this.$refs.form !== null) {
      //     this.$refs.form.setErrors({
      //       productName: '',
      //     })
      // }
      //   }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showAddModal = false
        this.isEdit = false
        this.ingredientStockDataUnique = []
        this.getAllInventoryAdjustment()
      }
    },
    showDetail(data) {
      this.inventoryAdjustmentActiveData = data
      this.showDetailModal = true
    },
    closeModalDetail() {
      this.showDetailModal = false
    },
    countStockAffected(initial = 0, category = {}, affected = 0) {
      if ('code' in category) {
        if (category.code === 'COUNT') {
          return affected
        } else if (category.code === 'RECEIVED') {
          return initial + affected
        } else {
          return initial - affected
        }
      } else {
        return '-'
      }
    },
    getHeadersStockAdjustment(category) {
      if ('code' in category) {
        if (category.code === 'COUNT') {
          return this.detailAdjustmentCountListHeader
        } else if (category.code === 'RECEIVED') {
          return this.detailAdjustmentReceiveListHeader
        } else {
          return this.detailAdjustmentReduceListHeader
        }
      } else {
        return this.detailAdjustmentReceiveListHeader
      }
    },
    saveInvetoryAdjustment() {
      if (this.isLoadingSave) return

      const params = {}
      params.tenant_id = this.tenantId
      params.inventory_category_code = this.getCategory(this.category)
      params.reason =
        this.reason === ''
          ? this.getCategory(this.category, 'name')
          : this.reason
      params.note = this.notes

      const items = []

      if (
        this.stockItems.length > 0 &&
        this.ingredientItems.length === this.stockItems.length
      ) {
        this.stockItems.forEach((item, key) => {
          items.push({
            stock_id: item.stock_id,
            quantity: this.ingredientItems[key],
          })
        })
        params.items = items
      } else {
        if (this.ingredientItems.length !== this.stockItems.length) {
          this.message = this.$t('formError.ingredientStock.required')
          this.ingredientItemError = true
        } else {
          this.message = this.$t('formError.inventoryStock.required')
          this.stockItemError = true
        }

        setTimeout(() => {
          this.showToast('failed')
        }, 500)
        return
      }

      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.activeId === '') {
          this.isLoadingSave = false
          return
        }
        params.id = this.activeId

        this.saveInvetoryAdjustmentAPI(params, true)
      } else {
        this.saveInvetoryAdjustmentAPI(params, false)
      }
    },
    addNewItem() {
      this.items.push({ id: this.generateId() })
      this.stockItemError = false
    },
    deleteItem(id) {
      this.items = this.items.filter((item) => item.id !== id)
    },
  },
}
</script>

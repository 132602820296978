import axiosSCM, { routePathAPISCM } from '@/plugins/axios-scm.js'
import router from '@/router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  getAll({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.is_active) params.is_active = payload.is_active
      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      if (payload.q) params.q = payload.q

      axiosSCM
        .get(`${routePathAPISCM()}/omni-channel`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetail({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .get(`${routePathAPISCM()}/omni-channel/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create({ commit }, payload) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('tenant_id', payload.tenant_id)
      formData.append('name', payload.name)
      formData.append('description', payload.description)
      formData.append('channel_fee', payload.channel_fee)
      formData.append('type', payload.type)
      formData.append('product_ids', payload.product_ids)

      axiosSCM
        .post(`${routePathAPISCM()}/omni-channel/create`, formData, config)
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 201 || response.status === 200) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({ commit }, payload) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('tenant_id', payload.tenant_id)
      formData.append('name', payload.name)
      formData.append('description', payload.description)
      formData.append('channel_fee', payload.channel_fee)
      formData.append('type', payload.type)
      formData.append('product_ids', payload.product_ids)

      axiosSCM
        .put(
          `${routePathAPISCM()}/omni-channel/update/${payload.id}`,
          formData,
          config
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 201 || response.status === 200) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateStatus({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {
        active: payload.active,
      }

      axiosSCM
        .post(
          `${routePathAPISCM()}/omni-channel/status/${payload.id}`,
          params,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 201) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          let message = ''
          if (error.response.status === 500) message = error.message
          else message = error.response.data.message.detail
          reject({
            status: error.response.status,
            message,
            data: error.response.data,
          })
        })
    })
  },
  delete({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .delete(`${routePathAPISCM()}/omni-channel/delete/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          let message = ''
          if (error.response.status === 500) message = error.message
          else message = error.response.data.message.detail
          reject({
            status: error.response.status,
            message,
            data: error.response.data,
          })
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

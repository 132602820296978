<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
  </div>

  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Access List -->
        <TableComponent
          :date-string="dateFilter"
          :title="$t('page.employee.employeeAccess.title')"
          :header="employeeAccessHeader"
          :meta-data="staffAccessMetaData"
          :is-not-empty="staffAccessData.length > 0"
          :is-show-search="true"
          @change-filter="changeFilter($event)"
        >
          <template #icon>
            <BookIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
          </template>

          <tr v-for="data in staffAccessData" :key="data.id">
            <td>{{ data.name }}</td>
            <td>{{ data.description }}</td>
            <td>{{ data.rank }}</td>
          </tr>
        </TableComponent>
        <!-- END: Access List -->
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>
<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import staffMixin from '@/mixins/staff.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'

import BookIcon from '@/assets/svg/menu/book.svg'

export default {
  name: 'EmployeeAccessPage',
  components: {
    Breadcrumb,
    TableComponent,
    BookIcon,
  },
  mixins: [globalMixin, staffMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
    }
  },
  data: () => ({
    employeeAccessHeader: [
      {
        item: 'page.employee.employeeAccess.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.employee.employeeAccess.tableHeaders.description',
        customClass: '',
      },
      {
        item: 'page.employee.employeeAccess.tableHeaders.rank',
        customClass: '',
      },
    ],
  }),

  mounted() {
    this.getAllStaffAccess()
  },
  methods: {
    changeFilter(event) {
      this.staffAccessFilterData = event.filterData
      this.getAllStaffAccess()
    },
  },
}
</script>

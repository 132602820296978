import Pica from 'pica'

/**
 *
 * @param file image file instanceof File
 * @param targetWidth output width in number
 * @param cb callback function
 */
export function resizeImg(file, targetWidth, cb) {
  const img = new Image()
  const pica = Pica()

  img.onload = () => {
    const width = targetWidth
    const height = (img.height * width) / img.width
    const from = img
    const to = document.createElement('canvas')
    to.width = width
    to.height = height

    pica
      .resize(from, to)
      .then((result) => {
        return pica.toBlob(result, 'image/jpeg', 0.9)
      })
      .then((blob) => {
        const newfile = blobToFile(blob, file.name)
        cb(newfile)
      })
  }
  img.src = window.URL.createObjectURL(file)
}

function blobToFile(blob, fileName) {
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type: blob.type,
  })
}

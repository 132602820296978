export default {
  data: () => ({
    inventoryAdjustmentData: [],
    inventoryAdjustmentActiveData: {},

    inventoryCategories: [],
    category: '',
    reason: '',
    notes: '',
    items: [],
    stockItems: [],
    ingredientItems: [],

    ingredientStockDataUnique: [],
    ingredientStockData: [],
    ingredientStockDataSearch: [],
    ingredientStockDataSearchOption: [],

    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },

    detailReason: {
      DAMAGE: [
        {
          label: 'page.inventoryAdjustment.reason.damage',
        },
        {
          label: 'page.inventoryAdjustment.reason.rotten',
        },
        {
          label: 'page.inventoryAdjustment.reason.badQuality',
        },
      ],
    },
  }),
  methods: {
    async getAllInventoryAdjustment(
      showMessage = true,
      setLoading = true,
      setMetaData = true
    ) {
      if (setLoading) this.isLoading = true
      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('inventory/getAllInventoryAdjustment', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.inventoryAdjustmentData = responseData.data
          }
          if (responseData.meta && setMetaData) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveInvetoryAdjustmentAPI(params, isUpdate = false, isReset = true) {
      let dispatcher = 'inventory/createInventoryAdjustment'
      if (isUpdate) dispatcher = 'scm/updateProduct'
      this.isLoadingSave = true

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.isLoadingSave = false

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
            this.showModalInfo = true
          }

          if (isReset) this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    async getAllInventoryCategory(
      showMessage = true,
      setLoading = true,
      setValue = true
    ) {
      if (setLoading) this.isLoading = true

      const params = {}

      await this.$store
        .dispatch('inventory/getAllInventoryCategory', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.inventoryCategories = responseData
            if (setValue && this.inventoryCategories.length > 0) {
              this.category = this.inventoryCategories[0].id
            }
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getAllIngredientStock(
      showMessage = true,
      setLoading = false,
      take = null,
      search = '',
      setMetaData = false,
      isFilter = false
    ) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: take !== null ? take : this.filterData.take,
        q: search === '' ? this.filterData.search : search,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('scm/getAllIngredientStock', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            let ingredientStockData = responseData.data
            if (isFilter) {
              ingredientStockData = responseData.data.filter(
                (item) => !this.stockItems.includes(item.id)
              )
            }

            this.ingredientStockData = ingredientStockData

            if (this.ingredientStockDataUnique.length > 0) {
              responseData.data.forEach((item) => {
                const index = this.ingredientStockDataUnique.findIndex(
                  (existing) => existing.id === item.id
                )

                if (index === -1) {
                  this.ingredientStockDataUnique.push(item)
                }
              })
            } else {
              this.ingredientStockDataUnique = responseData.data
            }

            const mappedData = ingredientStockData.reduce(function (
              accumulator,
              currData
            ) {
              accumulator.push({
                stock_id: currData.id,
                stock_name: currData.product_ingredient.name,
                value: currData.id,
                label: currData.product_ingredient.name,
              })
              return accumulator
            },
            [])
            if (search === '' || search === null) {
              this.ingredientStockDataSearch = mappedData
            } else {
              this.ingredientStockDataSearchOption = mappedData
              return this.ingredientStockDataSearchOption
            }
          }

          if ('meta' in responseData && setMetaData) {
            this.metaData = response.data.meta
          }

          if (showMessage) {
            this.message = response.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          console.log(e)

          // const message = e.response.data.message
          // if (typeof message === 'string') {
          //   this.message = message
          // } else {
          //   this.message = message.detail
          // }

          // setTimeout(() => {
          //   this.showToast('failed')
          // }, 500)
        })
    },

    searchIngredientStock(query, setOptions) {
      if (query !== null && query !== undefined && query !== '') {
        const getSelectedItemStockId = this.stockItems.reduce(
          (accumulator, curVal) => {
            accumulator.push(curVal.stock_id)
            return accumulator
          },
          []
        )

        this.getAllIngredientStock(false, false, 50, query, false, true).then(
          () => {
            setOptions(
              this.ingredientStockDataSearchOption.filter(
                (item) => !getSelectedItemStockId.includes(item.stock_id)
              )
            )
          }
        )
      }
    },
    getCategory(id, get = 'code') {
      const categoryDetail = this.inventoryCategories.find(
        (item) => item.id === id
      )
      if (categoryDetail !== undefined) {
        if (get === 'code' && 'code' in categoryDetail) {
          return categoryDetail.code
        } else if (get === 'name' && 'name' in categoryDetail) {
          return categoryDetail.name
        } else {
          return categoryDetail
        }
      } else {
        return {}
      }
    },
    getInventoryStock(id, get = 'stock') {
      const inventoryStock = this.ingredientStockDataUnique.find(
        (item) => item.id === id
      )
      if (inventoryStock !== undefined) {
        if (get === 'stock' && 'stock' in inventoryStock) {
          return inventoryStock.stock
        } else {
          return inventoryStock
        }
      } else {
        return {}
      }
    },
  },
}

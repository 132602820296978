export function randomString(length, chars) {
  var mask = ''
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz'
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  if (chars.indexOf('#') > -1) mask += '0123456789'
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\'
  var result = ''
  for (var i = length; i > 0; --i)
    result += mask[Math.floor(Math.random() * mask.length)]
  return result
}

export function setLocalStorageWithExpiry(key, value, ttl) {
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getLocalStorageWithExpiry(key) {
  const itemStr = localStorage.getItem(key)
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }
  const item = JSON.parse(itemStr)
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

export function checkTimeLoggedIn() {
  const loggedInDate =
    localStorage.getItem('inkanteenDashboard.loggedInDate') || null
  if (loggedInDate !== null) {
    const dateNow = new Date()
    const dateObjExpired = new Date(parseInt(loggedInDate))
    dateObjExpired.setHours(dateObjExpired.getHours() + 6)

    return dateNow > dateObjExpired
  } else {
    return true
  }
}

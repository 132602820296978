<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div class="mt-4 flex w-full flex-col justify-start space-y-4 md:flex-row">
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.report.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
    </div>

    <div class="relative grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Tenant Sales Line Chart -->
          <div class="col-span-12 md:mt-4">
            <div class="intro-y box mt-5 px-1 py-5 md:p-5">
              <div
                class="flex w-full flex-col justify-between md:flex-row md:items-center"
              >
                <div class="flex self-center align-middle">
                  <h2 class="mr-3 truncate text-base font-medium md:text-lg">
                    {{ $t('page.report.dailySales.title') }}
                  </h2>
                  <span
                    class="flex self-center text-xs uppercase text-gray-500"
                  >
                    {{ periodicFilter }}
                  </span>
                </div>
                <div
                  class="mt-0 flex w-full items-center justify-center sm:ml-auto md:mt-3"
                >
                  <FilterTimeRange
                    :active="periodicFilter"
                    @change-filter="changeFilterTimeRange($event)"
                  />
                </div>
              </div>
              <div>
                <ReportLineChart
                  v-if="!dailySalesChartEmpty"
                  :label="dailySalesChartLabel"
                  :item-name="dailySalesChartItemName"
                  :data="dailySalesChartData"
                  :show-legend="true"
                  :height="275"
                  class="mt-6 -mb-6"
                />
                <NoData v-else />
              </div>
              <div
                class="flex w-full max-w-full flex-row overflow-x-scroll pt-10"
              >
                <ComparisonItem
                  v-for="(item, index) in comparisonItem"
                  :key="index"
                  :title="$t(`${item.title}`)"
                  :has-checkbox="true"
                  :show-color="true"
                  :value="item.value"
                  :is-checked="chartCategory.includes(item.value)"
                  :color="item.color !== '' ? item.color : 'rgb(192,192,192)'"
                  @change-filter="changeChartCategory($event)"
                />
              </div>
            </div>
          </div>
          <!-- END: Tenant Sales Line Chart -->
        </div>
      </div>
    </div>

    <div class="flex w-full justify-end pt-8 md:pt-12">
      <div class="flex w-full justify-end md:w-fit">
        <div class="relative w-full text-slate-500 sm:ml-auto sm:mt-0">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateFilter"
            :options="{
              autoApply: false,
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              format: 'YYYY-MM-DD',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 sm:w-56"
          />
        </div>
        <ExportCSVComponent @export="downloadDailySales" />
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Tenant Sales Report -->
          <div class="col-span-12 mt-8">
            <div
              class="intro-y flex w-full flex-col justify-between space-y-2 md:flex-row md:space-y-0"
            >
              <div class="flex align-middle">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <TitleComponent
                  :title="$t($route.meta.title)"
                  :date-string="dateFilter"
                />
              </div>
              <div class="flex align-middle">
                <a href="" class="flex items-center space-x-2 text-primary">
                  <RefreshCcwIcon class="h-4 w-4" />
                  <span>{{ $t('action.reloadData') }}</span>
                </a>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-12 gap-6 md:mt-5">
              <Card
                :title="$t('page.report.dailySales.totalSalesGross')"
                :is-currency="true"
                :is-up="cardData.gross_sales_total.is_up"
                :percentage="cardData.gross_sales_total.percent"
                :total="cardData.gross_sales_total.total"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
              />
              <Card
                :title="$t('page.report.dailySales.totalSales')"
                :is-currency="true"
                :is-up="cardData.net_sales_total.is_up"
                :percentage="cardData.net_sales_total.percent"
                :total="cardData.net_sales_total.total"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
              />
              <Card
                v-if="false"
                :title="$t('page.report.dailySales.totalPay')"
                :is-currency="true"
                :is-up="cardData.payment_total.is_up"
                :percentage="cardData.payment_total.percent"
                :total="cardData.payment_total.total"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
              />
              <Card
                :title="$t('page.report.dailySales.totalTransaction')"
                :is-currency="false"
                :is-up="cardData.transaction_total.is_up"
                :percentage="cardData.transaction_total.percent"
                :total="cardData.transaction_total.total"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
              />
              <Card
                :title="$t('page.report.dailySales.totalProduct')"
                :is-currency="false"
                :is-up="cardData.product_total.is_up"
                :percentage="cardData.product_total.percent"
                :total="cardData.product_total.total"
                :show-percentage="true"
                wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
              />
            </div>
          </div>
          <!-- END: Tenant Sales Report -->
        </div>
      </div>
    </div>
    <!-- BEGIN: Order Detail Table -->
    <TableComponent
      :date-string="dateFilter"
      :title="$t('page.report.dailySales.dailySalesTable')"
      :header="dailySalesReportHeader"
      :is-show-search="true"
      :search-options="dateOptions"
      :meta-data="metaData"
      :is-not-empty="dailySalesReportData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="(data, index) in dailySalesReportData" :key="index">
        <td>{{ data.date_time }}</td>
        <td><Currency :total-in-string="data.sales_total" /></td>
        <td><Currency :total-in-string="data.gross_profit" /></td>
        <td class="text-center">{{ data.transaction_total }}</td>
        <td class="text-center">{{ data.product_total }}</td>
      </tr>
    </TableComponent>
    <!-- END: Order Detail Table -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import SaleIcon from '@/assets/svg/sale.svg'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'

import FilterTimeRange from '@/components/FilterTimeRange.vue'
import TitleComponent from '@/components/TitleComponent.vue'
import TableComponent from '@/components/TableComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import Card from '@/components/Card.vue'
import NoData from '@/components/NoData.vue'
import Currency from '@/components/Currency.vue'
import ComparisonItem from '@/components/ComparisonItem.vue'

export default {
  name: 'DailySalesPage',
  components: {
    Breadcrumb,
    ReportLineChart,

    SaleIcon,
    FilterTimeRange,
    TitleComponent,
    TableComponent,
    ExportCSVComponent,
    Card,
    NoData,
    Currency,
    ComparisonItem,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()
    const periodicFilter = ref('daily')
    const chartCategory = ref([
      'gross_sales',
      'net_sales',
      // 'sales',
      // 'gross_profit',
      'transaction',
      'product',
    ])

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
      periodicFilter,
      chartCategory,
    }
  },
  data: () => ({
    dailySalesReportHeader: [
      {
        item: 'page.report.dailySales.tableHeaders.time',
        customClass: '',
      },
      {
        item: 'page.report.dailySales.tableHeaders.sales',
        customClass: '',
      },
      {
        item: 'page.report.dailySales.tableHeaders.grossProfit',
        customClass: '',
      },
      {
        item: 'page.report.dailySales.tableHeaders.totalTransaction',
        customClass: 'text-center',
      },
      {
        item: 'page.report.dailySales.tableHeaders.totalProduct',
        customClass: 'text-center',
      },
    ],
    dailySalesReportData: [],
    cardData: {
      gross_sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      net_sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      payment_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      transaction_total: {
        total: 0,
        percent: '0',
        is_up: false,
      },
      product_total: {
        total: 0,
        percent: '0',
        is_up: false,
      },
    },
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    dateOptions: [],
    dailySalesChartEmpty: true,
    dailySalesChartLabel: [],
    dailySalesChartItemName: [],
    dailySalesChartData: [],
    comparisonItem: [
      {
        title: 'page.report.dailySales.salesGross',
        value: 'gross_sales',
        color: '',
      },
      {
        title: 'page.report.dailySales.sales',
        value: 'net_sales',
        color: '',
      },
      {
        title: 'page.report.dailySales.transaction',
        value: 'transaction',
        color: '',
      },
      {
        title: 'page.report.dailySales.product',
        value: 'product',
        color: '',
      },
    ],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
    chartCategories() {
      return this.chartCategory.toString()
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.init()
      this.getDailySalesChart(true)
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }
  },
  methods: {
    init() {
      this.getSummary(true)
      this.getDailySales(true)
    },
    async getSummary(first = false) {
      this.isLoading = true

      await this.$store
        .dispatch('sales/salesDailySummary', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.cardData.gross_sales_total = responseData.gross_sales_total
            this.cardData.net_sales_total = responseData.net_sales_total
            this.cardData.payment_total = responseData.payment_total
            this.cardData.transaction_total = responseData.transaction_total
            this.cardData.product_total = responseData.product_total
          }

          if (!first) {
            this.message = response.data.message
            this.showToast('success')
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          this.showToast('failed')
        })
    },
    async getDailySales(first = false) {
      this.dateOptions = []
      await this.store
        .dispatch('sales/salesDaily', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          page: this.filterData.page,
          take: this.filterData.take,
          search_date: this.filterData.search,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.dailySalesReportData = responseData.data
            if (this.filterData.search === '') {
              this.dateOptions = this.dailySalesReportData.map((item) =>
                this.formatDate(item.date_time)
              )
            }
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
            this.metaData.pageCount = Math.abs(responseData.meta.pageCount)
          }

          if (!first) {
            this.message = response.data.message
            this.showToast('success')
          }
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    async getDailySalesChart(first = false) {
      await this.store
        .dispatch('sales/salesDailyChart', {
          periodic: this.periodicFilter,
          chart_categories: this.chartCategories,
        })
        .then((response) => {
          const responseData = response.data
          if (responseData.data) {
            const filteredChart = responseData.data.reduce((filtered, item) => {
              let key = item.chart_id
              if (!filtered[key]) {
                filtered[key] = null
              }
              filtered[key] = item.charts
              return filtered
            }, {})

            this.dailySalesChartEmpty = true
            this.dailySalesChartItemName = []
            this.dailySalesChartData = []
            this.comparisonItem.forEach((item) => {
              if (this.chartCategory.includes(item.value)) {
                item.color = this.getColor(
                  this.chartCategory.indexOf(item.value)
                )
              } else {
                item.color = ''
              }
            })
            const filteredCategory = this.chartCategory.filter(
              (item) => filteredChart[item.toUpperCase()] !== undefined
            )
            filteredCategory.forEach((item, index) => {
              this.dailySalesChartItemName.push(item.toUpperCase())
              if (filteredChart[item.toUpperCase()] !== undefined) {
                this.dailySalesChartEmpty = false
              }
              let labels = []
              let values = []
              // if (filteredChart[item.toUpperCase()] !== undefined) {
              filteredChart[item.toUpperCase()].forEach((item) => {
                labels.push(item.name)
                values.push(item.total)
              })
              if (index === 0) {
                this.dailySalesChartLabel = labels
              }
              this.dailySalesChartData.push(values)
              // }
            })
          }

          if (!first) {
            this.message = response.data.message
            this.showToast('success')
          }
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    async downloadDailySales(fileType) {
      await this.store
        .dispatch('sales/salesDailyDownload', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'report_sales.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    changeFilterTimeRange(event) {
      this.periodicFilter = event
      this.getDailySalesChart()
    },
    changeChartCategory(event) {
      if (event.status) {
        this.chartCategory.push(event.item)
      } else {
        this.chartCategory = this.chartCategory.filter(
          (item) => item != event.item
        )
      }
      this.getDailySalesChart()
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getDailySales()
    },
  },
}
</script>

<template>
  <div
    class="text-ink-primary min-h-28 relative flex h-28 flex-col items-center justify-between space-y-2 self-center rounded-lg border-2 py-1"
  >
    <div class="flex h-1/3 items-center">
      <div
        v-if="isEdit"
        class="absolute left-2 top-2 cursor-pointer"
        @click.stop="doDelete({ id: table.id, segment: segment })"
      >
        <TrachIcon />
      </div>
      <div v-if="isEdit" class="absolute right-2 top-2">
        <label class="relative mb-5 inline-flex cursor-pointer items-center">
          <input
            ref="checkboxStatus"
            type="checkbox"
            class="peer sr-only"
            :checked="table.is_active"
            @change="changeTableStatus($event)"
          />
          <div
            class="peer h-5 w-9 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-4 after:w-4 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-0 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-0"
          ></div>
        </label>
      </div>
    </div>

    <p
      class="h-2/3 text-center text-3xl font-medium"
      :class="[
        table.is_active ? 'text-black dark:text-white' : 'text-gray-500',
      ]"
    >
      {{ table.label }}
    </p>
  </div>
</template>
<script>
import TrachIcon from '@/assets/svg/trash.svg'

export default {
  name: 'TableCard',
  components: { TrachIcon },
  props: {
    table: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    segment: {
      type: String,
      default: '',
    },
  },
  emits: ['do-delete', 'change-status'],
  methods: {
    doDelete(data) {
      this.$emit('do-delete', data)
    },
    changeTableStatus() {
      const params = {
        id: this.table.id,
        is_active: this.$refs.checkboxStatus.checked ? 1 : 0,
      }
      this.$emit('change-status', params)
    },
  },
}
</script>

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div class="mt-4 flex w-full flex-col justify-start space-y-4 md:flex-row">
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.report.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
    </div>
    <template v-if="showContent">
      <div class="flex w-full justify-end pt-8 md:pt-12">
        <div class="flex w-full justify-end md:w-fit">
          <div class="relative w-full text-slate-500 sm:ml-auto sm:mt-0">
            <CalendarIcon
              class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
            />
            <Litepicker
              v-model="dateFilter"
              :options="{
                autoApply: false,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                showWeekNumbers: true,
                format: 'YYYY-MM-DD',
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true,
                },
              }"
              class="form-control box pl-8 sm:w-56"
            />
          </div>
          <ExportCSVComponent
            @export="downloadAdditionalFeeReport($event, filterDates)"
          />
        </div>
      </div>

      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12">
          <div class="grid grid-cols-12 gap-6">
            <!-- BEGIN: Additional Fee Report -->
            <div class="col-span-12 mt-8">
              <div
                class="intro-y flex w-full flex-col justify-between space-y-2 md:flex-row md:space-y-0"
              >
                <div class="flex align-middle">
                  <div class="flex justify-center self-center align-middle">
                    <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                  </div>
                  <TitleComponent
                    :title="$t($route.meta.title)"
                    :date-string="dateFilter"
                  />
                </div>
                <div class="flex align-middle">
                  <a href="" class="flex items-center space-x-2 text-primary">
                    <RefreshCcwIcon class="h-4 w-4" />
                    <span>{{ $t('action.reloadData') }}</span>
                  </a>
                </div>
              </div>
              <div class="mt-4 grid grid-cols-12 gap-6 md:mt-5">
                <Card
                  :title="$t('page.report.additionalFee.totalTax')"
                  :is-currency="true"
                  :total="additionalFeeCardData.tax_fee_total"
                  wrapper-class="col-span-12 sm:col-span-12 xl:col-span-3"
                />
                <Card
                  :title="$t('page.report.additionalFee.totalService')"
                  :is-currency="true"
                  :total="additionalFeeCardData.service_fee_total"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
                <Card
                  :title="$t('page.report.additionalFee.instituteCharge')"
                  :is-currency="true"
                  :total="additionalFeeCardData.institute_fee_total"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-2"
                />
                <Card
                  :title="$t('page.report.additionalFee.inkanteenFee')"
                  :is-currency="true"
                  :total="additionalFeeCardData.admin_fee_total"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-2"
                />
                <Card
                  :title="$t('page.report.additionalFee.other')"
                  :is-currency="true"
                  :total="additionalFeeCardData.other_fee_total"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-2"
                />
              </div>
            </div>
            <!-- END: Additional Fee Report -->
          </div>
        </div>
      </div>
      <!-- BEGIN: Order Detail Table -->
      <TableComponent
        :date-string="dateFilter"
        :title="$t('page.report.additionalFee.additionalFeeTable')"
        :header="additionalFeeReportHeader"
        :is-show-search="false"
        :meta-data="metaDataAdditionalFeeReport"
        :is-not-empty="additionalFeeReportData.length > 0"
        @change-filter="changeFilter($event)"
      >
        <tr v-for="(data, index) in additionalFeeReportData" :key="index">
          <td class="uppercase">{{ getSplitString(data.transaction_id) }}</td>
          <td><Currency :total-in-string="data.payment_amount" /></td>
          <td>
            <div class="flex justify-between">
              <Currency :total-in-string="data.tax_fee" />
              <div
                v-if="
                  'tax_config' in data &&
                  data.tax_config !== null &&
                  'advanced' in data.tax_config
                "
                class="text-left text-xs font-bold text-gray-700"
                @mouseover="showTooltipTax = data.transaction_id"
                @mouseleave="showTooltipTax = ''"
              >
                <p
                  class="cursor-help self-center rounded-full border border-gray-300 px-1.5 text-xs text-gray-400 hover:border-gray-500 hover:text-gray-500"
                >
                  <span>i</span>
                </p>
                <p
                  v-if="showTooltipTax === data.transaction_id"
                  class="absolute space-x-1 bg-white p-3 text-center text-xs shadow-lg"
                >
                  <span class="font-normal">{{
                    $t('page.report.additionalFee.info.take')
                  }}</span>
                  <span
                    v-if="data.tax_config.advanced.per_item"
                    class="font-semibold"
                  >
                    {{ $t('page.report.additionalFee.info.perItem') }}
                  </span>
                  <span
                    v-else-if="data.tax_config.advanced.before_discount"
                    class="font-semibold"
                  >
                    {{ $t('page.report.additionalFee.info.beforeDiscount') }}
                  </span>
                  <span
                    v-else-if="data.tax_config.advanced.after_discount"
                    class="font-semibold"
                  >
                    {{ $t('page.report.additionalFee.info.afterDiscount') }}
                  </span>
                  <span
                    v-else-if="data.tax_config.advanced.including_price"
                    class="font-semibold"
                  >
                    {{ $t('page.report.additionalFee.info.includePrice') }}
                  </span>
                </p>
              </div>
            </div>
          </td>
          <td>
            <div class="flex justify-between">
              <Currency :total-in-string="data.service_fee" />
            </div>
          </td>
          <td>
            <div class="flex justify-between">
              <Currency :total-in-string="data.institute_fee" />
            </div>
          </td>
          <td>
            <div class="flex justify-between">
              <Currency :total-in-string="data.admin_fee" />
            </div>
          </td>
          <td>
            <div class="flex justify-between">
              <Currency :total-in-string="data.other_fee" />
            </div>
          </td>
        </tr>
      </TableComponent>
      <!-- END: Order Detail Table -->
    </template>
    <div
      v-else
      class="col-span-12 mt-6 space-x-2 bg-white p-5 text-center dark:bg-slate-900 md:p-10"
    >
      <InfoIcon class="inline w-5" />
      <span>{{ $t('info.featureOnlyOneTenant') }}</span>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import taxMixin from '@/mixins/tax.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TitleComponent from '@/components/TitleComponent.vue'
import TableComponent from '@/components/TableComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import Card from '@/components/Card.vue'
import Currency from '@/components/Currency.vue'

import SaleIcon from '@/assets/svg/sale.svg'

export default {
  name: 'AdditionalFeeReportPage',
  components: {
    Breadcrumb,

    SaleIcon,
    TitleComponent,
    TableComponent,
    ExportCSVComponent,
    Card,
    Currency,
  },
  mixins: [globalMixin, taxMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
    }
  },
  data: () => ({
    showContent: true,
    showTooltipTax: '',
    additionalFeeReportHeader: [
      {
        item: 'page.report.additionalFee.tableHeaders.orderNumber',
        customClass: '',
      },
      {
        item: 'page.report.additionalFee.tableHeaders.totalCost',
        customClass: '',
      },
      {
        item: 'page.report.additionalFee.tableHeaders.tax',
        customClass: '',
      },
      {
        item: 'page.report.additionalFee.tableHeaders.service',
        customClass: '',
      },
      {
        item: 'page.report.additionalFee.tableHeaders.institute',
        customClass: '',
      },
      {
        item: 'page.report.additionalFee.tableHeaders.inkanteenFee',
        customClass: '',
      },
      {
        item: 'page.report.additionalFee.tableHeaders.other',
        customClass: '',
      },
    ],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.init(this.additionalFeeReportData.length === 0)
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    if (this.activeUserStore !== null) {
      if ('institute' in this.activeUserStore) {
        if ('is_multiple_tenant' in this.activeUserStore.institute)
          this.showContent = !this.activeUserStore.institute.is_multiple_tenant
      }
    }
  },
  methods: {
    init(isInit = true) {
      if (this.showContent) {
        this.getAdditionalFeeReportSummary(isInit, this.filterDates)
        this.getAdditionalFeeReport(isInit, this.filterDates)
      }
    },
    changeFilter(event) {
      this.filterDataAdditionalFeeReport = event.filterData
      this.getAdditionalFeeReport(false, this.filterDates)
    },
  },
}
</script>

<template>
  <div
    class="cursor-pointer rounded-lg border"
    :class="[
      wrapperClass,
      disabled ? 'cursor-not-allowed bg-gray-100' : 'cursor-pointer',
    ]"
    @click="clickProductSupplierItem"
  >
    <ImageLoader :image="product.image" :alt="product.name" />
    <div class="space-y-2 p-2">
      <div class="flex w-full space-x-2">
        <div
          v-if="reminderData !== undefined && reminderData.stock !== null"
          class="rounded-full px-2"
          :class="[reminderData.stock > 0 ? 'bg-yellow-700' : 'bg-red-500']"
        >
          &nbsp;
        </div>
        <div class="line-clamp-1 w-full max-w-full truncate">
          {{ product.name }}
        </div>
      </div>
      <div class="flex space-x-1 text-xs text-gray-500">
        <Currency :total-in-string="product.sale_price" />
        <p>•</p>
        <p v-if="'master_unit' in product" class="space-x-1">
          <span>Unit :</span>
          <span class="text-primary">{{ product.master_unit.name }}</span>
        </p>
      </div>
      <div
        v-if="'supplier' in product"
        class="flex space-x-1 text-xs text-gray-500"
      >
        <TruckIcon />
        <p>{{ product.supplier.name }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import ImageLoader from '@/components/image-loader/Main.vue'
import Currency from '@/components/Currency.vue'
import TruckIcon from '@/assets/svg/truck-line.svg'

export default {
  name: 'ProductSupplierItem',
  components: {
    ImageLoader,
    Currency,
    TruckIcon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    reminderData: {
      type: Object,
      default: () => {
        return { stock: null }
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
  emits: ['show-detail'],
  methods: {
    clickProductSupplierItem() {
      if (!this.disabled) this.$emit('show-detail', { id: this.product.id })
    },
  },
}
</script>

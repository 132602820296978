<template>
  <div
    class="w-full cursor-pointer space-y-2 rounded-lg border p-4 shadow-sm"
    :class="[
      selectedAddressId === addressData.id
        ? 'border-primary'
        : 'border-gray-300',
    ]"
    @click="selectAddress"
  >
    <div class="flex w-full items-center">
      <div class="flex w-11/12 flex-col">
        <div class="flex w-full items-center space-x-2">
          <p class="font-semibold">{{ addressData.label }}</p>
          <p
            v-if="addressData.is_primary"
            class="rounded-lg bg-green-50 p-1 text-green-500"
          >
            Utama
          </p>
        </div>
        <div class="flex w-full flex-col">
          <p>{{ addressData.recipient }}</p>
          <p>{{ addressData.phone }}</p>
        </div>
        <div class="flex w-full text-left">
          <p>
            <span>{{ addressData.detail_address }}</span>
            <span v-if="'village' in addressData">
              ,
              {{ addressData.village }}
            </span>
            <span v-if="'district' in addressData">
              ,
              {{ addressData.district }}
            </span>
            <span v-if="'city' in addressData">
              ,
              {{ addressData.city }}
            </span>
            <span v-if="'province' in addressData">
              ,
              {{ addressData.province }}
            </span>
            <span v-if="'postal_code' in addressData">
              ,
              {{ addressData.postal_code }}
            </span>
          </p>
        </div>
      </div>
      <div class="flex w-1/12 justify-center">
        <div
          id="circle"
          class="border"
          :class="[
            selectedAddressId === addressData.id
              ? 'border-primary bg-primary'
              : 'border-gray-300 bg-white',
          ]"
        ></div>
      </div>
    </div>
    <div class="flex w-full border-t pt-3">
      <div
        class="flex cursor-pointer items-center space-x-1 text-xs text-primary"
        @click.stop="
          $router.push({
            name: 'stock-purchase-cart-address-form',
            params: {
              slug: $route.params.slug,
            },
            query: {
              lang: $route.query.lang,
              id: addressData.id,
            },
          })
        "
      >
        <EditIcon />
        <p>Ubah alamat</p>
      </div>
    </div>
  </div>
</template>
<script>
import EditIcon from '@/assets/svg/edit.svg'

export default {
  name: 'AddressItemComponent',
  components: { EditIcon },
  props: {
    addressData: {
      type: Object,
      required: true,
    },
    selectedAddressId: {
      type: String,
      required: true,
    },
  },
  emits: ['select-address'],
  methods: {
    selectAddress() {
      this.$emit('select-address', this.addressData.id)
    },
  },
}
</script>

<style>
#circle {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
</style>

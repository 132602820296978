<template>
  <div>
    <DarkModeSwitcher />
    <div class="container">
      <!-- BEGIN: Error Page -->
      <div
        class="error-page flex h-screen flex-col items-center justify-center text-center lg:flex-row lg:text-left"
      >
        <div class="-intro-x lg:mr-20">
          <ErrorIllustration class="w-40 lg:w-72" />
        </div>
        <div class="mt-10 text-white lg:mt-0">
          <div class="intro-x text-8xl font-medium">404</div>
          <div class="intro-x mt-5 text-xl font-medium lg:text-3xl">
            {{ $t('info.pageMissing') }}
          </div>
          <div class="intro-x mt-3 text-lg">
            {{ $t('info.pageMissingInstruction') }}
          </div>
          <button
            class="intro-x btn mt-10 border-white py-3 px-4 text-white dark:border-darkmode-400 dark:text-slate-200"
            @click="openURL('dashboard')"
          >
            {{ $t('button.backToHome') }}
          </button>
        </div>
      </div>
      <!-- END: Error Page -->
    </div>
  </div>
</template>

<script>
import dom from '@left4code/tw-starter/dist/js/dom'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import ErrorIllustration from '@/assets/images/error-illustration.svg'

export default {
  name: 'VersionPage',
  components: {
    DarkModeSwitcher,
    ErrorIllustration,
  },
  mounted() {
    dom('body').removeClass('main').removeClass('login').addClass('error-page')
  },
  methods: {
    openURL(pageName) {
      this.$router.push({
        name: pageName,
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>

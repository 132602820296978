<template>
  <div class="me-2 h-2.5 w-2.5 rounded-full" :class="colorClass"></div>
</template>
<script>
export default {
  name: 'DotComponent',
  props: {
    colorClass: {
      type: String,
      default: 'bg-gray-400',
    },
  },
}
</script>

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.expense.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
      <div class="flex w-full md:w-fit">
        <AddButtonComponent
          wrapper-class="bg-primary text-white dark:bg-white dark:text-black"
          :title="$t('page.routineBilling.add')"
          @click="showModalRoutineBilling"
        />
        <ExportCSVComponent
          wrapper-class="bg-primary text-white dark:bg-white dark:text-black"
          @export="downloadRoutineBilling"
        />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Routine Billing List Table -->
          <TableComponent
            :title="$t('page.routineBilling.tableHeaders.title')"
            :header="routineBillingTableHeaders"
            :is-show-search="true"
            :is-not-empty="routineBillingData.length > 0"
            :meta-data="metaData"
            :is-show-pagination="true"
            @change-filter="changeFilter($event)"
          >
            <template #icon>
              <ExpenseIcon class="mr-2 h-5 w-5 text-black dark:text-white" />
            </template>
            <template #additionalPreFilter>
              <div class="flex w-1/2 items-center">
                <div
                  class="w-full rounded-l-lg border border-gray-200 p-2 font-bold outline-none transition"
                  :class="
                    mode === 'done'
                      ? 'bg-gray-200'
                      : ' cursor-pointer  bg-white'
                  "
                  @click="changeMode('done')"
                >
                  {{ $t('page.routineBilling.alreadyPaid') }}
                </div>
                <div
                  class="w-full rounded-r-lg border border-gray-200 p-2 font-bold outline-none transition"
                  :class="
                    mode === 'not' ? 'bg-gray-200' : ' cursor-pointer  bg-white'
                  "
                  @click="changeMode('not')"
                >
                  {{ $t('page.routineBilling.notYetPaid') }}
                </div>
              </div>
            </template>

            <tr v-for="data in routineBillingData" :key="data.id">
              <td>
                {{ data.name }}
              </td>
              <td class="text-center">
                {{ data.due }}
              </td>
              <td>
                <Currency
                  :prefix-text="data.total > 0 ? '' : '-'"
                  :total-in-string="Math.abs(data.total)"
                />
              </td>
              <td>
                {{ data.customerId }}
              </td>
              <td>
                {{ data.account.name }}
              </td>
              <td>
                {{ data.account.number }}
              </td>
            </tr>
          </TableComponent>
          <!-- END: Routine Billing List Table -->

          <!-- BEGIN: Modal Add Routine Billing -->
          <Modal
            :show="showModalAddRoutineBilling"
            size="modal-xl"
            @hidden="closeModal"
          >
            <ModalBody class="p-4">
              <Form
                ref="form"
                v-slot="{ errors }"
                :validation-schema="routineBillingSchema"
                :initial-errors="{}"
                :initial-touched="{}"
                :validate-on-mount="false"
                @submit="saveRoutineBilling"
              >
                <div class="flex flex-col space-y-4 px-5 pb-8 text-center">
                  <div class="flex flex-col space-y-3">
                    <div class="flex items-center space-x-1">
                      <ExpenseIcon class="h-5 w-5 text-black" />
                      <p class="font-bold">
                        <span v-if="!isEdit">
                          {{ $t('page.routineBilling.forms.title') }}
                        </span>
                        <span v-else>
                          {{ $t('page.routineBilling.forms.editTitle') }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-col gap-4 md:flex-row">
                    <div class="flex w-full flex-col space-y-2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="billingName"
                        >
                          <span>
                            {{ $t('page.routineBilling.forms.name')
                            }}<sup class="text-red-500">*</sup>
                          </span>
                        </label>
                      </div>

                      <div class="text-left">
                        <Field
                          v-slot="{ field }"
                          v-model="billingName"
                          name="billingName"
                        >
                          <input
                            id="billingName"
                            v-bind="field"
                            required="required"
                            class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            :class="[
                              errors.billingName !== undefined
                                ? 'border-red-400'
                                : 'border-gray-200',
                            ]"
                            type="text"
                            :placeholder="
                              $t('page.routineBilling.forms.namePlaceholder')
                            "
                          />
                        </Field>
                        <span class="text-xs text-red-400">
                          <template v-if="errors.billingName !== undefined">
                            {{ $t(errors.billingName) }}
                          </template>
                        </span>
                      </div>
                    </div>

                    <div class="flex w-full flex-col space-y-2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="partnerName"
                        >
                          <span>
                            {{ $t('page.routineBilling.forms.partnerName') }}
                          </span>
                        </label>
                      </div>

                      <div class="text-left">
                        <Field
                          v-slot="{ field }"
                          v-model="partnerName"
                          name="partnerName"
                        >
                          <input
                            id="partnerName"
                            v-bind="field"
                            required="required"
                            class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            :class="[
                              errors.partnerName !== undefined
                                ? 'border-red-400'
                                : 'border-gray-200',
                            ]"
                            type="text"
                            :placeholder="
                              $t(
                                'page.routineBilling.forms.partnerNamePlaceholder'
                              )
                            "
                          />
                        </Field>
                        <span class="text-xs text-red-400">
                          <template v-if="errors.partnerName !== undefined">
                            {{ $t(errors.partnerName) }}
                          </template>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-4 md:flex-row">
                    <div class="flex w-full flex-col space-y-2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="totalBilling"
                        >
                          <span>
                            {{ $t('page.routineBilling.forms.total') }}
                          </span>
                        </label>
                      </div>

                      <div class="text-left">
                        <Field
                          v-slot="{ field }"
                          v-model="totalBilling"
                          name="totalBilling"
                        >
                          <input
                            id="totalBilling"
                            v-bind="field"
                            required="required"
                            class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            :class="[
                              errors.totalBilling !== undefined
                                ? 'border-red-400'
                                : 'border-gray-200',
                            ]"
                            type="number"
                            :placeholder="
                              $t('page.routineBilling.forms.totalPlaceholder')
                            "
                          />
                        </Field>
                        <span class="text-xs text-red-400">
                          <template v-if="errors.totalBilling !== undefined">
                            {{ $t(errors.totalBilling) }}
                          </template>
                        </span>
                      </div>
                    </div>

                    <div class="flex w-full flex-col space-y-2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="isAutomaticBilling"
                        >
                          <span>
                            {{
                              $t('page.routineBilling.forms.isAutomaticBilling')
                            }}
                          </span>
                        </label>
                      </div>

                      <div class="flex gap-6 py-2">
                        <div
                          class="flex cursor-pointer items-center gap-1"
                          @click="isAutomaticBilling = true"
                        >
                          <input
                            v-model="isAutomaticBilling"
                            type="radio"
                            class="text-primary"
                            :checked="isAutomaticBilling"
                          />
                          <span>{{ $t('info.yes') }}</span>
                        </div>
                        <div
                          class="flex cursor-pointer items-center gap-1"
                          @click="isAutomaticBilling = false"
                        >
                          <input
                            v-model="isAutomaticBilling"
                            type="radio"
                            class="text-primary"
                            :checked="!isAutomaticBilling"
                          />

                          <span>{{ $t('info.no') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-4 border-b pb-8 md:flex-row">
                    <div class="flex w-full flex-col space-y-2 pr-2 md:w-1/2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="dueDate"
                        >
                          <span>
                            {{ $t('page.routineBilling.forms.dueDate')
                            }}<sup class="text-red-500">*</sup>
                          </span>
                        </label>
                      </div>

                      <div class="text-left">
                        <Field
                          v-slot="{ field }"
                          v-model="dueDate"
                          name="dueDate"
                        >
                          <select
                            id="dueDate"
                            v-bind="field"
                            required="required"
                            class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            :class="[
                              errors.dueDate !== undefined
                                ? 'border-red-400'
                                : 'border-gray-200',
                            ]"
                          >
                            <option value="" disabled hidden>
                              {{
                                $t(
                                  'page.routineBilling.forms.dueDatePlaceholder'
                                )
                              }}
                            </option>
                            <option v-for="i in 31" :key="i" :value="i">
                              {{ i }}
                            </option>
                          </select>
                        </Field>
                        <span class="text-xs text-red-400">
                          <template v-if="errors.dueDate !== undefined">
                            {{ $t(errors.dueDate) }}
                          </template>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-if="!isInputAccount" class="flex w-full justify-start">
                    <div
                      class="flex cursor-pointer items-center gap-2 text-black"
                      @click="isInputAccount = true"
                    >
                      <p class="rounded-md border border-black px-1">+</p>
                      <p>{{ $t('page.routineBilling.forms.addAccount') }}</p>
                    </div>
                  </div>
                  <div
                    v-if="isInputAccount"
                    class="flex flex-col gap-4 md:flex-row"
                  >
                    <div class="flex w-full flex-col space-y-2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="accountName"
                        >
                          <span>
                            {{ $t('page.routineBilling.forms.accountName') }}
                          </span>
                        </label>
                      </div>

                      <div class="text-left">
                        <Field
                          v-slot="{ field }"
                          v-model="accountName"
                          name="accountName"
                        >
                          <input
                            id="accountName"
                            v-bind="field"
                            required="required"
                            class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            :class="[
                              errors.accountName !== undefined
                                ? 'border-red-400'
                                : 'border-gray-200',
                            ]"
                            type="text"
                            :placeholder="
                              $t(
                                'page.routineBilling.forms.accountNamePlaceholder'
                              )
                            "
                          />
                        </Field>
                        <span class="text-xs text-red-400">
                          <template v-if="errors.accountName !== undefined">
                            {{ $t(errors.accountName) }}
                          </template>
                        </span>
                      </div>
                    </div>

                    <div class="flex w-full flex-col space-y-2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="accountNumber"
                        >
                          <span>
                            {{ $t('page.routineBilling.forms.accountNumber') }}
                          </span>
                        </label>
                      </div>

                      <div class="text-left">
                        <Field
                          v-slot="{ field }"
                          v-model="accountNumber"
                          name="accountNumber"
                        >
                          <input
                            id="accountNumber"
                            v-bind="field"
                            required="required"
                            class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            :class="[
                              errors.accountNumber !== undefined
                                ? 'border-red-400'
                                : 'border-gray-200',
                            ]"
                            type="text"
                            :placeholder="
                              $t(
                                'page.routineBilling.forms.accountNumberPlaceholder'
                              )
                            "
                          />
                        </Field>
                        <span class="text-xs text-red-400">
                          <template v-if="errors.accountNumber !== undefined">
                            {{ $t(errors.accountNumber) }}
                          </template>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-end space-x-2 px-5 pb-8">
                  <button
                    type="button"
                    class="w-24 text-primary"
                    @click="closeModal"
                  >
                    {{ $t('button.cancel') }}
                  </button>
                  <button type="submit" class="btn btn-primary w-24">
                    {{ $t('button.save') }}
                  </button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
          <!-- END: Modal Add Routine Billing -->

          <!-- BEGIN: Toast -->
          <Toast
            id="success"
            :title="$t('formInfo.success')"
            :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
          />
          <Toast
            id="failed"
            :title="$t('formInfo.failed')"
            :message="message !== '' ? message : $t('formInfo.fetchFailed')"
            :is-failed="true"
          />
          <!-- END: Toast -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Yup from 'yup'
import globalMixin from '@/mixins/global.js'
import expenseMixin from '@/mixins/expense.js'

import { Form, Field } from 'vee-validate'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import AddButtonComponent from '@/components/AddButtonComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'

import ExpenseIcon from '@/assets/svg/menu/expense.svg'

export default {
  name: 'RoutineBillingList',
  components: {
    Form,
    Field,
    Breadcrumb,
    TableComponent,

    Currency,
    AddButtonComponent,
    ExportCSVComponent,

    ExpenseIcon,
  },
  mixins: [globalMixin, expenseMixin],
  data: () => ({
    mode: 'done',

    // FORM
    routineBillingId: null,
    billingName: '',
    partnerName: '',
    totalBilling: null,
    dueDate: '',
    isAutomaticBilling: false,
    isInputAccount: false,
    accountName: '',
    accountNumber: '',

    routineBillingDataDone: [
      {
        id: 2,
        name: 'Test Done',
        due: '1',
        total: 45000,
        customerId: '312312',
        account: { name: 'Siroj Gans', number: '123343' },
      },
    ],
    routineBillingDataNot: [
      {
        id: 1,
        name: 'Test',
        due: '1',
        total: 45000,
        customerId: '312312',
        account: { name: 'Siroj', number: '123343' },
      },
    ],
    routineBillingTableHeaders: [
      {
        item: 'page.routineBilling.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.routineBilling.tableHeaders.dueDate',
        customClass: 'text-center',
      },
      {
        item: 'page.routineBilling.tableHeaders.total',
        customClass: '',
      },
      {
        item: 'page.routineBilling.tableHeaders.customerId',
        customClass: '',
      },
      {
        item: 'page.routineBilling.tableHeaders.accountName',
        customClass: '',
      },
      {
        item: 'page.routineBilling.tableHeaders.accountNumber',
        customClass: '',
      },
    ],

    showModalAddRoutineBilling: false,

    // Form Validation
    routineBillingSchema: Yup.object().shape({
      billingName: Yup.string()
        .typeError('formError.billingName.required')
        .required('formError.billingName.required')
        .matches(/^(?!\s+$).*/, 'formError.billingName.required'),
    }),
  }),
  computed: {
    routineBillingData() {
      if (this.mode === 'done') return this.routineBillingDataDone
      else return this.routineBillingDataNot
    },
  },
  methods: {
    changeFilter(event) {
      this.filterData = event.filterData
    },
    changeMode(mode = 'done') {
      this.mode = mode
    },
    closeModal() {
      this.showModalAddRoutineBilling = false
    },
    showModalRoutineBilling() {
      this.showModalAddRoutineBilling = true
      this.resetData(false)
    },
    resetData(afterSave = true) {
      this.routineBillingId = null
      this.billingName = ''
      this.partnerName = ''
      this.totalBilling = null
      this.dueDate = null
      this.isAutomaticBilling = false
      this.isInputAccount = false
      this.accountName = ''
      this.accountNumber = ''

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            billingName: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showModalAddRoutineBilling = false
        this.isEdit = false
        // this.getAllBrand({
        //   showMessage: false,
        //   setLoading: true,
        //   search: '',
        //   setMetaData: true,
        // })
      }
    },
    saveRoutineBilling() {
      const params = {
        name: this.billingName,
      }
      console.log(params)

      this.resetData()
    },
    async downloadRoutineBilling(fileType) {
      await this.store
        .dispatch('sales/salesDailyDownload', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'report_sales.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
  },
}
</script>

import axiosCore, { routePathAPICore } from '@/plugins/axios-core.js'
import router from '@/router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  // TransactionAverage
  transactionAverageSummary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
      }
      if (payload.tenant_id) params.tenant_id = payload.tenant_id

      axiosCore
        .get(`${routePathAPICore()}/analytic/average/summary`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  transactionAverageChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
      }
      if (payload.tenant_id) params.tenant_id = payload.tenant_id

      axiosCore
        .get(`${routePathAPICore()}/analytic/average/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  transactionAverage({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
        order: payload.order ? payload.order : 'DESC',
      }
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.tenant_id) params.tenant_id = payload.tenant_id

      axiosCore
        .get(`${routePathAPICore()}/analytic/average/list`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  transactionAverageDownload({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
        file_type: payload.file_type ? payload.file_type : '.csv', // .csv / .xlsx
      }
      if (payload.tenant_id) params.tenant_id = payload.tenant_id

      axiosCore
        .get(`${routePathAPICore()}/analytic/average/download`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
          responseType: 'blob',
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // GrowthPercantage
  growthPercentageSummary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.tenant_id) params.tenant_id = payload.tenant_id

      axiosCore
        .get(`${routePathAPICore()}/analytic/growth-percentage/summary`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,

              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  growthPercentageChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_month_year: payload.start_month_year,
        end_month_year: payload.end_month_year,
      }
      if (payload.tenant_id) params.tenant_id = payload.tenant_id

      axiosCore
        .get(`${routePathAPICore()}/analytic/growth-percentage/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // OmniChannel
  omniChannelIndexGetAll({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
        tenant_id: payload.tenant_id ? payload.tenant_id : '',
        order: payload.order ? payload.order : 'DESC',
      }

      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.q) params.q = payload.q

      axiosCore
        .get(`${routePathAPICore()}/analytic/ecommerce-index`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  omniChannelIndexSummary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
        tenant_id: payload.tenant_id ? payload.tenant_id : '',
      }

      axiosCore
        .get(`${routePathAPICore()}/analytic/ecommerce-index/summary`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  omniChannelIndexChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
        tenant_id: payload.tenant_id ? payload.tenant_id : '',
      }

      axiosCore
        .get(`${routePathAPICore()}/analytic/ecommerce-index/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // SatisfactionIndex
  satisfactionIndexSummary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      axiosCore
        .get(`${routePathAPICore()}/analytic/satisfaction-index/summary`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  satisfactionIndex({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order

      axiosCore
        .get(`${routePathAPICore()}/analytic/satisfaction-index/list`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // Most & Least
  mostLeastTenantChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.institute_id) params.institute_id = payload.institute_id
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      if (payload.periodic) params.periodic = payload.periodic
      if (payload.start_date) params.start_date = payload.start_date
      if (payload.end_date) params.end_date = payload.end_date
      if (payload.type) params.type = payload.type // MOST, LEAST
      if (payload.limit) params.limit = payload.limit

      axiosCore
        .get(`${routePathAPICore()}/analytic/most-least-tenant/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  mostLeastCategoryChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.institute_id) params.institute_id = payload.institute_id
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      if (payload.periodic) params.periodic = payload.periodic
      if (payload.start_date) params.start_date = payload.start_date
      if (payload.end_date) params.end_date = payload.end_date
      if (payload.type) params.type = payload.type // MOST, LEAST
      if (payload.limit) params.limit = payload.limit

      axiosCore
        .get(`${routePathAPICore()}/analytic/most-least-category/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  mostLeastProductChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.institute_id) params.institute_id = payload.institute_id
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      if (payload.periodic) params.periodic = payload.periodic
      if (payload.start_date) params.start_date = payload.start_date
      if (payload.end_date) params.end_date = payload.end_date
      if (payload.type) params.type = payload.type // MOST, LEAST
      if (payload.limit) params.limit = payload.limit

      axiosCore
        .get(`${routePathAPICore()}/analytic/most-least-product/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.analytic.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
      <div class="flex">
        <div class="relative text-slate-500 sm:ml-auto sm:mt-0">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateFilter"
            :options="{
              autoApply: false,
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              format: 'YYYY-MM-DD',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 sm:w-56"
          />
        </div>
      </div>
    </div>

    <div class="relative grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Satisfaction Index -->
          <div class="col-span-12 mt-8">
            <div
              class="intro-y flex h-10 w-full flex-col justify-between md:flex-row"
            >
              <div class="flex align-middle">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <div class="flex">
                  <div class="flex self-center align-middle">
                    <h2 class="mr-3 truncate text-lg font-medium">
                      {{ $t($route.meta.title) }}
                    </h2>
                    <span class="flex self-center text-xs text-gray-500">
                      {{ dateFilterFormat(dateFilter) }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex align-middle">
                <a href="" class="flex items-center space-x-2 text-primary">
                  <RefreshCcwIcon class="h-4 w-4" />
                  <span>{{ $t('action.reloadData') }}</span>
                </a>
              </div>
            </div>
            <div class="mt-8 grid grid-cols-12 gap-6 md:mt-5">
              <Card
                :title="$t('page.analytic.satisfactionIndex.totalTransaction')"
                :is-currency="false"
                :total="cardData.transaction_total"
                wrapper-class="col-span-12 sm:col-span-6"
              />
              <Card
                :title="$t('page.analytic.satisfactionIndex.totalReview')"
                :is-currency="false"
                :total="cardData.review_total"
                wrapper-class="col-span-12 sm:col-span-6"
              />
            </div>
          </div>
          <!-- END: Satisfaction Index -->
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Satisfaction Index Pie Chart -->
          <div class="col-span-12 mt-4">
            <div class="intro-y box p-5 md:mt-6">
              <div class="report-chart">
                <ReportPieChart
                  v-if="!satisfactionChartEmpty"
                  :label="satisfactionChartLabel"
                  :data="satisfactionChartData"
                  :height="275"
                  class="mt-6 -mb-6"
                />
                <NoData v-else />
              </div>
            </div>
          </div>
          <!-- END: Satisfaction Index Pie Chart -->
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: User Review -->
          <TableComponent
            :date-string="dateFilter"
            :title="$t('page.analytic.satisfactionIndex.userReview')"
            :header="userReviewHeader"
            :is-not-empty="userReviewData.length > 0"
          >
            <tr v-for="data in userReviewData" :key="data.transaction_time">
              <td>{{ data.customer_name }}</td>
              <td>{{ data.transaction_time }}</td>
              <td>{{ satisfactionLevel(data.satisfaction_level) }}</td>
              <td>{{ data.review }}</td>
            </tr>
          </TableComponent>

          <!-- END: User Review -->
        </div>
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import SaleIcon from '@/assets/svg/sale.svg'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Card from '@/components/Card.vue'
import NoData from '@/components/NoData.vue'

export default {
  name: 'SatisfactionIndexPage',
  components: {
    Breadcrumb,
    TableComponent,
    SaleIcon,
    ReportPieChart,
    Card,
    NoData,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
    }
  },
  data: () => ({
    satisfactionChartLabel: [],
    satisfactionChartItemName: '',
    satisfactionChartData: [],
    satisfactionChartEmpty: false,
    userReviewHeader: [
      {
        item: 'page.analytic.satisfactionIndex.tableHeaders.customerName',
        customClass: '',
      },
      {
        item: 'page.analytic.satisfactionIndex.tableHeaders.time',
        customClass: '',
      },
      {
        item: 'page.analytic.satisfactionIndex.tableHeaders.satisficationLevel',
        customClass: '',
      },
      {
        item: 'page.analytic.satisfactionIndex.tableHeaders.review',
        customClass: '',
      },
    ],
    cardData: {
      transaction_total: 0,
      review_total: 0,
    },
    userReviewData: [],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.init()
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }
  },
  methods: {
    init() {
      this.getSummary()
      this.getSatisfactionIndex()
    },
    satisfactionLevel(level) {
      let satisfication_level = ''
      switch (level) {
        case 1:
          satisfication_level = this.t(
            'page.analytic.satisfactionIndex.satisfactionLevel.verySatisfied'
          )
          break
        case 2:
          satisfication_level = this.t(
            'page.analytic.satisfactionIndex.satisfactionLevel.satisfied'
          )
          break
        case 3:
          satisfication_level = this.t(
            'page.analytic.satisfactionIndex.satisfactionLevel.aLittleSatisfied'
          )
          break
        case 4:
          satisfication_level = this.t(
            'page.analytic.satisfactionIndex.satisfactionLevel.notSatisfied'
          )
          break
        case 5:
          satisfication_level = this.t(
            'page.analytic.satisfactionIndex.satisfactionLevel.veryNotSatisfied'
          )
          break
        default:
          satisfication_level = this.t(
            'page.analytic.satisfactionIndex.satisfactionLevel.noComment'
          )
      }
      return satisfication_level
    },
    async getSummary() {
      this.isLoading = true

      await this.$store
        .dispatch('analytic/satisfactionIndexSummary', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.cardData.transaction_total = responseData.transaction_total
            this.cardData.review_total = responseData.review_total

            if (responseData.product_review_chart.length > 0) {
              this.satisfactionChartItemName = 'Satisfaction Index'
              let satisfactionLabel = []
              let satisfactionTotal = []
              responseData.product_review_chart.forEach((item) => {
                satisfactionLabel.push(item.name)
                satisfactionTotal.push(item.total)
              })
              this.satisfactionChartLabel = satisfactionLabel
              this.satisfactionChartData = satisfactionTotal
              this.satisfactionChartEmpty = false
            } else {
              this.satisfactionChartEmpty = true
            }

            this.satisfactionChartEmpty = this.checkArray(
              this.satisfactionChartData,
              0
            )
          }

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          this.isLoading = false

          this.satisfactionChartEmpty = true
          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getSatisfactionIndex() {
      await this.store
        .dispatch('analytic/satisfactionIndex', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          page: this.filterData.page,
          take: this.filterData.take,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.userReviewData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          // this.message = e.message
          // this.showToast('failed')
          // console.log(this.message)
        })
    },
  },
}
</script>

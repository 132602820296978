export default {
  data: () => ({
    balanceDataHistories: [],
    balanceData: {
      total_balance: 0,
      cash_on_hand: 0,
      total_income: 0,
      omni_payment: 0,
    },
    balanceTypes: [
      {
        value: 'all',
        label: 'Semua Jenis Transaksi',
      },
      {
        value: 'credit',
        label: 'Balance Masuk',
      },
      {
        value: 'debit',
        label: 'Balance Keluar',
      },
    ],

    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',

      startDate: '',
      endDate: '',
      type: 'all',
    },
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
  }),
  methods: {
    async getDataBalance(showMessage = true, setLoading = false) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: '',
        startDate: this.filterDates.start,
        endDate: this.filterDates.end,
        type: this.filterData.type,
      }

      if (this.isInstituteSuperAdmin && this.selectedTenantIdString !== '')
        params.tenant_id = this.selectedTenantIdString
      else if (this.tenantId !== '') params.tenant_id = this.tenantId

      await this.$store
        .dispatch('balance/getBalance', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.balanceData = responseData

            const balanceHistory = []
            responseData.histories.forEach((item) => {
              balanceHistory.push(...item.items)
            })

            this.balanceDataHistories = balanceHistory
          }

          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}

import dom from '@left4code/tw-starter/dist/js/dom'

// Setup side menu
const findActiveMenu = (submenu, route) => {
  let match = false
  submenu.forEach((item) => {
    if (
      ((route.forceActiveMenu !== undefined &&
        item.pageName === route.forceActiveMenu) ||
        (route.forceActiveMenu === undefined &&
          item.pageName === route.name)) &&
      !item.ignore
    ) {
      match = true
    } else if (!match && item.submenu) {
      match = findActiveMenu(item.submenu, route)
    }
  })
  return match
}

const nestedMenu = (menu, route) => {
  menu.forEach((item, key) => {
    if (typeof item !== 'string') {
      let menuItem = menu[key]
      menuItem.active =
        ((route.forceActiveMenu !== undefined &&
          item.pageName === route.forceActiveMenu) ||
          (route.forceActiveMenu === undefined &&
            item.pageName === route.name) ||
          (item.submenu && findActiveMenu(item.submenu, route))) &&
        !item.ignore

      if (item.submenu) {
        menuItem.activeDropdown = findActiveMenu(item.submenu, route)
        menuItem = {
          ...item,
          ...nestedMenu(item.submenu, route),
        }
      }
    }
  })

  return menu
}

const linkTo = (menu, router, event) => {
  if (menu.submenu) {
    menu.activeDropdown = !menu.activeDropdown
  } else {
    event.preventDefault()
    router.push({
      name: menu.pageName,
    })
  }
}

const enter = (el, done) => {
  dom(el).slideDown(300)
}

const leave = (el, done) => {
  dom(el).slideUp(300)
}

export { nestedMenu, linkTo, findActiveMenu, enter, leave }

import axiosOrder, { routePathAPIOrder } from '@/plugins/axios-order.js'
import router from '@/router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  getAll({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.sortBy) params.sortBy = payload.sortBy
      if (payload.q) params.q = payload.q

      axiosOrder
        .get(`${routePathAPIOrder()}/tables`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {
        is_multiple_table_number: payload.is_multiple_table_number,
        is_segmented_table: payload.is_segmented_table,
        segmentation: payload.segmentation,
        from: payload.from,
        to: payload.to,
        number_of_person: payload.number_of_person,
      }

      axiosOrder
        .post(`${routePathAPIOrder()}/tables`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 201 || response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          let message = ''
          if (error.response.status === 500) message = error.message
          else message = error.response.data.message.detail
          reject({
            status: error.response.status,
            message,
            data: error.response.data,
          })
        })
    })
  },
  updateStatus({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosOrder
        .post(`${routePathAPIOrder()}/tables/bulk-update-status`, payload, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          let message = ''
          if (error.response.status === 500) message = error.message
          else message = error.response.data.message.detail
          reject({
            status: error.response.status,
            message,
            data: error.response.data,
          })
        })
    })
  },
  update({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {
        name: payload.name,
        code: payload.code,
      }
      axiosOrder
        .put(`${routePathAPIOrder()}/kiosk/${payload.id}`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          let message = ''
          if (error.response.status === 500) message = error.message
          else message = error.response.data.message.detail
          reject({
            status: error.response.status,
            message,
            data: error.response.data,
          })
        })
    })
  },
  delete({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosOrder
        .delete(`${routePathAPIOrder()}/tables/${payload.ids}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          let message = ''
          if (error.response.status === 500) message = error.message
          else message = error.response.data.message.detail
          reject({
            status: error.response.status,
            message,
            data: error.response.data,
          })
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <div
    class="mr-2 flex cursor-pointer flex-row items-center justify-between rounded-lg border border-slate-500 py-2 px-2 hover:bg-slate-700 hover:text-white md:py-4"
    :class="[customClass]"
  >
    <div
      v-if="showColor"
      class="inline rounded-full font-mono"
      :style="`background-color: ${color};`"
    >
      &nbsp;
    </div>
    <span v-if="hasPlusIcon">+</span>
    <p class="inline truncate px-2 text-xs md:text-sm">{{ title }}</p>
    <span v-if="hasCloseIcon" class="cursor-pointer" @click="deleteComparison">
      x
    </span>
    <div v-if="hasCheckbox" class="inline">
      <input
        type="checkbox"
        :checked="isChecked"
        @change="changeStatus($event)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ComparisonItemComponents',
  props: {
    title: { type: String, required: true },
    value: { type: [String, Number], required: true },
    customClass: { type: String, default: '' },
    color: { type: String, default: '4ECAC9' },
    isChecked: { type: Boolean, default: false },
    showColor: { type: Boolean, default: false },
    hasCheckbox: { type: Boolean, default: false },
    hasPlusIcon: { type: Boolean, default: false },
    hasCloseIcon: { type: Boolean, default: false },
  },
  emits: ['change-filter', 'delete-comparison'],
  methods: {
    changeStatus(event) {
      this.$emit('change-filter', {
        status: event.target.checked,
        item: this.value,
      })
    },
    deleteComparison() {
      this.$emit('delete-comparison', {
        item: this.value,
      })
    },
  },
}
</script>

import axiosCore, { routePathAPICore } from '@/plugins/axios-core.js'
import router from '@/router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  // Sales Summary
  summary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids

      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/summary`, {
          params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  summaryDownload({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/summary/download`, {
          params: {
            start_date: payload.start_date,
            end_date: payload.end_date,
            file_type: payload.file_type ? payload.file_type : '.csv', // .csv / .xlsx
          },
          headers: {
            'x-language-code': currentLanguageCode,
          },
          responseType: 'blob',
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // Sales Detail
  salesDetailSummary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    const params = {
      start_date: payload.start_date,
      end_date: payload.end_date,
    }

    if (payload.payment_method) params.payment_method = payload.payment_method //balance, qris, va, ewallet, cash, split-payment
    if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
    if (payload.institute_id) params.institute_id = payload.institute_id

    return new Promise((resolve, reject) => {
      axiosCore
        .get(`${routePathAPICore()}/report/sales/detail/summary`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesDetail({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
        order: payload.order ? payload.order : 'DESC',
      }
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.product_name) params.product_name = payload.product_name
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
      // if (payload.q) params.q = payload.q
      if (payload.q) params.product_name = payload.q
      if (payload.payment_method) params.payment_method = payload.payment_method //balance, qris, va, ewallet, cash, split-payment
      if (payload.customer) params.customer = payload.customer
      if (payload.status) params.status = payload.status //pending,paid,process,completed,cancelled
      if (payload.institute_id) params.institute_id = payload.institute_id

      axiosCore
        .get(`${routePathAPICore()}/report/sales/detail`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesDetailDownload({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    const params = {
      order: payload.order ? payload.order : 'DESC',
      file_type: payload.file_type ? payload.file_type : '.csv', // .csv / .xlsx
    }

    if (payload.start_date) params.start_date = payload.start_date
    if (payload.end_date) params.end_date = payload.end_date
    if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
    if (payload.payment_method) params.payment_method = payload.payment_method // balance, qris, va, ewallet, cash
    if (payload.institute_id) params.institute_id = payload.institute_id

    return new Promise((resolve, reject) => {
      axiosCore
        .get(`${routePathAPICore()}/report/sales/detail/download`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
          responseType: 'blob',
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // Non Sales Detail
  nonSalesDetail({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let responseType = 'json'
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
        tenant_id: payload.tenant_id,
        with_summary: true,
      }
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.q) params.criteria = `id:${payload.q}`
      if (payload.order) {
        params.order = payload.order
        params.sortBy = `created_at:${payload.order}`
      } else {
        params.order = 'DESC'
        params.sortBy = `created_at:desc`
      }
      if (payload.file_type) params.file_type = payload.file_type
      if (payload.mode) {
        params.mode = payload.mode // download
        if (payload.mode === 'download') responseType = 'blob'
      }

      axiosCore
        .get(`${routePathAPICore()}/transaction/non-sales`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
          responseType: responseType,
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // Sales Per Day
  salesDailySummary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/per-day/summary`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesDaily({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.search_date) params.search_date = payload.search_date

      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/per-day/list`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesDailyChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
        chart_categories: payload.chart_categories
          ? payload.chart_categories.toUpperCase()
          : 'TRANSACTION',
      }

      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/per-day/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesDailyDownload({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/per-day/download`, {
          params: {
            start_date: payload.start_date,
            end_date: payload.end_date,
            file_type: payload.file_type ? payload.file_type : '.csv', // .csv / .xlsx
          },
          headers: {
            'x-language-code': currentLanguageCode,
          },
          responseType: 'blob',
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // Sales per Product
  salesPerProductSummary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
        category_ids: payload.category_ids ? payload.category_ids : '',
        product_ids: payload.product_ids ? payload.product_ids : '',
      }
      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/per-product/summary`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesPerProduct({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.product_name) params.product_name = payload.product_name

      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
      if (payload.category_ids) params.category_ids = payload.category_ids
      if (payload.product_ids) params.product_ids = payload.product_ids

      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/per-product/list`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesPerProductChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
      }
      params.product_ids =
        payload.product_ids === null ? '' : payload.product_ids

      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/per-product/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesPerProductDownload({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/per-product/download`, {
          params: {
            start_date: payload.start_date,
            end_date: payload.end_date,
            file_type: payload.file_type ? payload.file_type : '.csv', // .csv / .xlsx
            category_ids: payload.category_ids ? payload.category_ids : '',
            product_ids: payload.product_ids ? payload.product_ids : '',
          },
          headers: {
            'x-language-code': currentLanguageCode,
          },
          responseType: 'blob',
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

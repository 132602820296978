<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.product.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
  </div>

  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Bank List -->
        <TableComponent
          :title="$t('page.product.ingredientList.title')"
          :header="ingredientListHeader"
          :is-show-search="true"
          :is-show-search-with-filter="isInstitute"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :is-not-empty="ingredientData.length > 0"
          :is-show-add="true"
          :title-add="$t('menu.product.addIngredients')"
          :meta-data="metaData"
          @do-add="showModalAdd"
          @change-filter-dropdown="changeFilterDropdown($event)"
          @change-filter="changeFilter($event)"
        >
          <template #icon>
            <BookIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
          </template>

          <tr
            v-for="data in ingredientData"
            :key="data.id"
            class="cursor-pointer"
            @click="showModalUpdate(data)"
          >
            <td>{{ data.name }}</td>
            <td><Currency :total-in-string="data.price" /></td>
            <td>
              <span v-if="'master_unit' in data">
                {{ data.master_unit.name }}
              </span>
              <span v-else>-</span>
            </td>
            <td>
              <span v-if="'stock' in data">
                {{ data.stock.stock }}
              </span>
              <span v-else>-</span>
            </td>
          </tr>
        </TableComponent>
        <!-- END: Bank List -->
      </div>
    </div>

    <!-- BEGIN: Modal Input -->
    <Modal :show="showModal" size="modal-lg" @hidden="showModal = false">
      <ModalBody class="p-4">
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="ingredientSchema"
          :initial-errors="{}"
          :initial-touched="{}"
          :validate-on-mount="false"
          @submit="saveIngredient"
        >
          <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
            <div class="flex flex-col space-y-3">
              <div class="flex justify-between">
                <p class="font-bold">
                  <span v-if="!isEdit"> Tambah Jenis Bahan </span>
                  <span v-else> Edit Jenis Bahan </span>
                </p>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex flex-col space-y-2">
                <div>
                  <label
                    class="block text-left font-bold text-gray-700 md:mb-0"
                    for="ingredient-name"
                  >
                    <span>Nama Bahan<sup class="text-red-500">*</sup></span>
                  </label>
                </div>
                <div class="text-left">
                  <Field v-slot="{ field }" v-model="name" name="name">
                    <input
                      id="ingredient-name"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.name !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      placeholder="Masukkan nama bahan"
                    />
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.name !== undefined">
                      {{ $t(errors.name) }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="flex flex-col space-y-2">
                <div>
                  <label
                    class="block text-left font-bold text-gray-700 md:mb-0"
                    for="ingredient-description"
                  >
                    Deskripsi
                  </label>
                </div>
                <div>
                  <input
                    id="ingredient-description"
                    v-model="description"
                    required="required"
                    class="w-full appearance-none rounded border-2 border-gray-200 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                    type="text"
                    placeholder="Masukkan deskripsi bahan"
                  />
                </div>
              </div>
              <div class="flex w-full space-x-4">
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="price"
                    >
                      Harga beli bahan<sup class="text-red-500">*</sup>
                    </label>
                  </div>
                  <div class="text-left">
                    <Field v-slot="{ field }" v-model="price" name="price">
                      <input
                        id="price"
                        v-bind="field"
                        required="required"
                        class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.price !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                        type="number"
                      />
                    </Field>

                    <span class="text-xs text-red-400">
                      <template v-if="errors.price !== undefined">
                        {{ $t(errors.price) }}
                      </template>
                    </span>
                  </div>
                </div>

                <div v-if="false" class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="sale_price"
                    >
                      Harga jual bahan
                    </label>
                  </div>
                  <div>
                    <input
                      id="sale_price"
                      v-model="sale_price"
                      required="required"
                      class="w-full appearance-none rounded border-2 py-2 px-4 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      type="number"
                    />
                  </div>
                </div>
              </div>

              <div class="flex w-full space-x-2">
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="category"
                    >
                      Kategori<sup class="text-red-500">*</sup>
                    </label>
                  </div>
                  <div class="text-left">
                    <Field
                      v-slot="{ field }"
                      v-model="categoryId"
                      name="categoryId"
                    >
                      <select
                        id="categoryId"
                        v-bind="field"
                        required="required"
                        class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.categoryId !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                      >
                        <option
                          v-for="category in categoryData"
                          :key="category.id"
                          :value="category.id"
                        >
                          {{ category.name }}
                        </option>
                      </select>
                    </Field>

                    <span class="text-xs text-red-400">
                      <template v-if="errors.categoryId !== undefined">
                        {{ $t(errors.categoryId) }}
                      </template>
                    </span>
                  </div>
                </div>
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="brand"
                    >
                      Brand
                    </label>
                  </div>
                  <div>
                    <select
                      id="brand"
                      v-model="brandId"
                      required="required"
                      class="w-full appearance-none rounded border-2 border-gray-200 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                    >
                      <option
                        v-for="brand in brandData"
                        :key="brand.id"
                        :value="brand.id"
                      >
                        {{ brand.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="unit"
                    >
                      Unit<sup class="text-red-500">*</sup>
                    </label>
                  </div>
                  <div class="text-left">
                    <Field v-slot="{ field }" v-model="unitId" name="unitId">
                      <select
                        v-bind="field"
                        required="required"
                        class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.unitId !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                      >
                        <template
                          v-if="isEdit && activeMasterBase !== undefined"
                        >
                          <option
                            v-for="unit in unitData.filter(
                              (item) => item.base === activeMasterBase
                            )"
                            :key="unit.id"
                            :value="unit.id"
                          >
                            {{ unit.name }}
                          </option>
                        </template>
                        <template v-else>
                          <option
                            v-for="unit in unitData"
                            :key="unit.id"
                            :value="unit.id"
                          >
                            {{ unit.name }}
                          </option>
                        </template>
                      </select>
                    </Field>

                    <span class="text-xs text-red-400">
                      <template v-if="errors.unitId !== undefined">
                        {{ $t(errors.unitId) }}
                      </template>
                    </span>
                  </div>
                </div>
              </div>

              <div class="flex w-full items-center space-x-2">
                <Field
                  v-slot="{ field }"
                  v-model="reminderStock"
                  name="reminderStock"
                  type="checkbox"
                  :value="true"
                >
                  <input
                    v-bind="field"
                    class="accent-primary checked:bg-primary focus:bg-primary focus:ring-primary"
                    type="checkbox"
                    role="switch"
                  />
                </Field>
                <span>Ingatkan saat stok menipis</span>
              </div>

              <div v-if="reminderStock" class="flex w-full space-x-4">
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="target_stock_reminder"
                    >
                      <span>
                        Ingatkan saat stok mencapai<sup class="text-red-500"
                          >*
                        </sup>
                      </span>
                    </label>
                  </div>
                  <div class="text-left">
                    <Field
                      v-slot="{ field }"
                      v-model="target_stock_reminder"
                      name="target_stock_reminder"
                    >
                      <input
                        id="target_stock_reminder"
                        v-bind="field"
                        required="required"
                        class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.target_stock_reminder !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                        type="number"
                      />
                    </Field>
                    <span class="text-xs text-red-400">
                      <template
                        v-if="errors.target_stock_reminder !== undefined"
                      >
                        {{ $t(errors.target_stock_reminder) }}
                      </template>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex px-5 pb-8"
            :class="[ingredientId === null ? 'justify-end' : 'justify-between']"
          >
            <div v-if="ingredientId !== null" class="flex">
              <button
                type="button"
                class="btn w-24 bg-red-500 text-white"
                @click="showModalDelete(ingredientId)"
              >
                {{ $t('button.delete') }}
              </button>
            </div>
            <div class="flex space-x-2">
              <button type="button" class="btn w-24" @click="showModal = false">
                {{ $t('button.cancel') }}
              </button>
              <button type="submit" class="btn btn-primary w-24">
                {{ $t('button.save') }}
              </button>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
    <!-- END: Modal Input -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <DeleteModal
      :id="deleteId"
      :show-modal="showModalDel"
      @yes="confirmDelete($event)"
      @no="showModalDel = false"
    />

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import productMixin from '@/mixins/product.js'
import * as Yup from 'yup'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'

import DeleteModal from '@/components/DeleteModal.vue'

import { Form, Field } from 'vee-validate'

import BookIcon from '@/assets/svg/menu/book.svg'

export default {
  name: 'IngredientListPage',
  components: {
    Breadcrumb,
    TableComponent,
    Currency,

    DeleteModal,
    BookIcon,
    Form,
    Field,
  },
  mixins: [globalMixin, tenantMixin, productMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    return {
      t,
      n,
      store,
      route,
      router,
    }
  },
  data: () => ({
    showModal: false,
    isEdit: false,
    activeMasterBase: undefined,

    ingredientListHeader: [
      {
        item: 'page.product.ingredientList.tableHeaders.name',
        customClass: '',
      },
      // {
      //   item: 'page.product.ingredientList.tableHeaders.stock',
      //   customClass: 'text-center',
      // },
      {
        item: 'page.product.ingredientList.tableHeaders.price',
        customClass: '',
      },
      {
        item: 'page.product.ingredientList.tableHeaders.unit',
        customClass: '',
      },
      {
        item: 'page.product.ingredientList.tableHeaders.stock',
        customClass: '',
      },
    ],
    ingredientList: [],
    reminderStock: false,

    // Form Validation
    ingredientSchema: Yup.object().shape({
      name: Yup.string()
        .typeError('formError.ingredientName.required')
        .required('formError.ingredientName.required'),
      price: Yup.number()
        .min(0, 'formError.price.minimal')
        .typeError('formError.price.required')
        .required('formError.price.required'),
      categoryId: Yup.string()
        .typeError('formError.category.required')
        .required('formError.category.required'),
      unitId: Yup.string()
        .typeError('formError.unit.required')
        .required('formError.unit.required'),
      reminderStock: Yup.bool(),
      target_stock_reminder: Yup.string()
        .typeError('formError.target_stock_reminder.required')
        .matches(/^(?!\s+$).*/, 'formError.cannotOnlySpace')
        .when('reminderStock', {
          is: true,
          then: Yup.string()
            .typeError('formError.target_stock_reminder.required')
            .required('formError.target_stock_reminder.required'),
        }),
    }),
  }),
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllIngredient(true, true, null, '', true)
        this.getAllIngredientBrand(false, false, false, 50, false)
        this.getAllIngredientCategory(false, false, false, 50, false)
        this.isInstitute = false
      } else if (this.isInstituteSuperAdmin) {
        this.tenantId = this.selectedTenantIdString
        this.getAllIngredient(true, true, null, '', true)
        this.getAllIngredientBrand(false, false, false, 50, false)
        this.getAllIngredientCategory(false, false, false, 50, false)
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getAllIngredient(true, true, null, '', true)
          this.getAllIngredientBrand(false, false, false, 50, false)
          this.getAllIngredientCategory(false, false, false, 50, false)
        })
        this.isInstitute = true
      }
    }
    this.getAllUnit(false, false)
  },
  methods: {
    saveIngredient() {
      if ((this.name === '' && this.price === 0) || this.isLoadingSave) return

      const params = {
        tenant_id: this.tenantId,
        name: this.name,
        description: this.description,
        price: this.price,
        sale_price: this.sale_price,
        target_stock_reminder: this.target_stock_reminder,
        category_id: this.categoryId,
        unit_id: this.unitId,
        brand_id: this.brandId,
      }
      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.ingredientId === null) {
          this.isLoadingSave = false
          return
        }
        params.id = this.ingredientId

        this.saveIngredientAPI(params, true)
      } else {
        this.saveIngredientAPI(params)
      }
    },
    resetData(afterSave = true) {
      this.ingredientId = null
      this.name = ''
      this.description = ''
      this.price = 0
      this.sale_price = 0
      this.reminderStock = false
      this.target_stock_reminder = 0
      this.unitId = null
      this.categoryId = null
      this.brandId = null
      this.activeMasterBase = undefined

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            name: '',
            categoryId: '',
            unitId: '',
            reminderStock: '',
            target_stock_reminder: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showModal = false
        this.isEdit = false
        this.getAllIngredient(false, false, null, '', true)
      }
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllIngredient(true, false, null, '', true)
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getAllIngredient(true, false, null, '', true)
    },
    confirmDelete(id) {
      this.deleteIngredientAPI(id).then(() => {
        this.showModal = false
      })
    },
    showModalAdd() {
      this.showModal = true
      this.isEdit = false
      this.resetData(false)
    },
    showModalUpdate(data) {
      this.showModal = true
      this.isEdit = true
      this.ingredientId = data.id
      this.name = data.name
      this.description = data.description
      this.sale_price = data.sale_price
      if ('master_unit' in data) {
        this.activeMasterBase = data.master_unit.base
      } else {
        this.activeMasterBase = undefined
      }
      if ('stock' in data) {
        this.reminderStock = data.stock.target_stock_reminder !== null
        this.target_stock_reminder = data.stock.target_stock_reminder
      }
      this.price = data.price

      if ('master_unit' in data) {
        this.unitId = data.master_unit.id
      }
      if ('product_ingredient_brand' in data) {
        this.brandId = data.product_ingredient_brand.id
      }
      if ('product_ingredient_category' in data) {
        this.categoryId = data.product_ingredient_category.id
      }
    },
  },
}
</script>

<template>
  <div class="flex flex-row" :class="wrapClass">
    <div v-if="prefixText !== ''" class="mr-1">
      <span :class="textPrefixClass"> {{ prefixText }}</span>
    </div>
    <p class="inline">
      <span v-if="withPrefix">Rp{{ formatPrice(totalInString) }}</span>
      <span v-else>{{ formatPrice(totalInString) }}</span>
    </p>
    <div v-if="leadingText !== ''" class="ml-1">
      <span :class="textLeadingClass"> {{ leadingText }}</span>
    </div>
    <button
      v-if="copyText !== ''"
      v-clipboard:copy="copyText"
      class="ml-2 inline rounded-lg text-xs font-semibold"
      :title="$t('copy_to_clipboard')"
      type="button"
      @click="$emit('copy')"
    >
      <CopyIcon class="inline w-4" />
    </button>
  </div>
</template>

<script>
import CopyIcon from '@/assets/svg/copy.svg'

export default {
  name: 'CurrencyComponent',
  components: {
    CopyIcon,
  },
  props: {
    totalInString: {
      type: [String, Number],
      default: '0',
      required: true,
    },
    withPrefix: {
      type: Boolean,
      default: true,
    },
    wrapClass: {
      type: String,
      default: '',
      required: false,
    },
    textClass: {
      type: String,
      default: '',
      required: false,
    },
    leadingText: {
      type: String,
      default: '',
      required: false,
    },
    textLeadingClass: {
      type: String,
      default: '',
      required: false,
    },
    prefixText: {
      type: String,
      default: '',
      required: false,
    },
    textPrefixClass: {
      type: String,
      default: '',
      required: false,
    },
    copyText: {
      type: String,
      default: '',
      required: false,
    },
  },
  data: () => ({}),
  methods: {
    formatPrice(value) {
      // const val = Math.round(Number(value) * 100) / 100
      const parts = value.toString().split('.')

      return (
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
        (parts[1] ? ',' + parts[1] : '')
      )
      //   return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>

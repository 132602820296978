import { reactive } from 'vue'
import dayjs from 'dayjs'

import Litepicker from 'litepicker'
import 'litepicker/dist/plugins/ranges'

let litePickerInstance = reactive({})

const getDateFormat = (format) => {
  return format !== undefined ? format : 'D MMM, YYYY'
}

const setValue = (props, emit) => {
  const format = getDateFormat(props.options.format)
  if (!props.modelValue.length) {
    let date = dayjs().startOf('month').format(format)
    date +=
      !props.options.singleMode && props.options.singleMode !== undefined
        ? ' - ' + dayjs().format(format)
        : ''

    // let date = dayjs().format(format)
    // date +=
    //   !props.options.singleMode && props.options.singleMode !== undefined
    //     ? ' - ' + dayjs().add(1, 'month').format(format)
    //     : ''
    emit('update:modelValue', date)
  }
}

const init = (el, props, emit) => {
  const format = getDateFormat(props.options.format)
  const todayDate = dayjs()
  const today = new Date(todayDate)
  const yesterday = new Date(todayDate.subtract(1, 'day'))
  const startThisWeek = new Date(dayjs().day(0).add(1, 'day'))
  const endThisWeek = new Date(dayjs().day(6).add(1, 'day'))
  const startLastWeek = new Date(dayjs().day(0).subtract(6, 'day'))
  const endLastWeek = new Date(dayjs().day(6).subtract(6, 'day'))
  const startThisMonth = new Date(dayjs().startOf('month'))
  const endThisMonth = new Date(dayjs().endOf('month'))
  const startLastMonth = new Date(dayjs().subtract(1, 'month').startOf('month'))
  const endLastMonth = new Date(dayjs().subtract(1, 'month').endOf('month'))
  const startThisYear = new Date(dayjs().startOf('year'))
  const endThisYear = new Date(dayjs().endOf('year'))
  const startLastYear = new Date(dayjs().subtract(1, 'year').startOf('year'))
  const endLastYear = new Date(dayjs().subtract(1, 'year').endOf('year'))

  let customRanges = {}
  if (!props.options.singleMode) {
    customRanges = {
      'Hari Ini': [today, today],
      Kemarin: [yesterday, yesterday],
      'Pekan Ini': [startThisWeek, endThisWeek],
      'Pekan Lalu': [startLastWeek, endLastWeek],
      'Bulan Ini': [startThisMonth, endThisMonth],
      'Bulan Lalu': [startLastMonth, endLastMonth],
      'Tahun Ini': [startThisYear, endThisYear],
      'Tahun Lalu': [startLastYear, endLastYear],
    }
  }

  litePickerInstance = new Litepicker({
    element: el,
    ...props.options,
    format: format,
    plugins: ['ranges'],
    ranges: {
      customRanges,
      autoApply: false,
    },
    setup: (picker) => {
      picker.on('selected', (startDate, endDate) => {
        let date = dayjs(startDate.dateInstance).format(format)
        date +=
          endDate !== undefined
            ? ' - ' + dayjs(endDate.dateInstance).format(format)
            : ''
        emit('update:modelValue', date)
      })
    },
  })

  el.Litepicker = litePickerInstance
}

const reInit = (el, props, emit) => {
  litePickerInstance.destroy()
  init(el, props, emit)
}

export { setValue, init, reInit }

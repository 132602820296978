<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.inventoryList')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex">
      <ButtonComponent
        :title="$t('page.retail.productList.addButton')"
        @click="showModalAdd"
      >
        <template #icon> + </template>
      </ButtonComponent>
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Product List -->
        <TableComponent
          :is-show-search="true"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :title="$t('page.retail.productList.title')"
          :header="productListHeader"
          :is-not-empty="productData.length > 0"
          :meta-data="metaDataProduct"
          :show-icon="false"
          @change-filter="changeFilter($event)"
          @change-filter-dropdown="changeFilterDropdown($event)"
        >
          <tr v-for="data in productData" :key="data.id">
            <td>{{ data.name }}</td>
            <td class="text-center">
              <span v-if="'brand' in data && 'name' in data.brand">
                {{ data.brand.name }}
              </span>
              <span v-else>-</span>
            </td>
            <td class="text-center">
              <span v-if="'category' in data && 'name' in data.category">
                {{ data.category.name }}
              </span>
              <span v-else>-</span>
            </td>
            <td class="space-x-2">
              <Currency
                :total-in-string="data.price"
                wrap-class="justify-center"
              />
            </td>
            <td class="text-center">
              <span v-if="'stock' in data && 'stock' in data.stock">
                {{ data.stock.stock }}
              </span>
              <span v-else>-</span>
            </td>
            <td class="text-center">
              <span v-if="'unit' in data && 'name' in data.unit">
                {{ data.unit.name }}
              </span>
              <span v-else>-</span>
            </td>
            <td class="space-x-2">
              <div class="flex space-x-2">
                <button
                  class="flex items-center space-x-1 rounded-sm bg-primary px-4 py-2 text-xs font-medium text-white"
                  @click="showModalUpdate(data)"
                >
                  <EditIcon />
                  <span>{{ $t('action.edit') }}</span>
                </button>
                <button
                  class="flex items-center space-x-1 rounded-sm bg-danger px-4 py-2 text-xs font-medium text-white"
                  @click="showModalDelete(data.id)"
                >
                  <DeleteIcon />
                  <span>{{ $t('action.delete') }}</span>
                </button>
              </div>
            </td>
          </tr>
        </TableComponent>
        <!-- END: Product List -->
      </div>
    </div>

    <!-- BEGIN: Modal Add Product -->
    <Modal :show="showAddProductModal" size="modal-lg" @hidden="closeModal">
      <ModalBody class="p-4">
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="productSchema"
          :initial-errors="{}"
          :initial-touched="{}"
          :validate-on-mount="false"
          @submit="saveProduct"
        >
          <div class="flex flex-col space-y-4 px-5 pb-8 text-center">
            <div class="flex flex-col space-y-3">
              <div class="flex items-center space-x-1">
                <PackageIcon />
                <p class="font-bold">
                  <span v-if="!isEdit">
                    {{ $t('page.retail.productList.forms.title') }}
                  </span>
                  <span v-else>
                    {{ $t('page.retail.productList.forms.editTitle') }}
                  </span>
                </p>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="productName"
                  >
                    <span>
                      {{ $t('page.retail.productList.forms.name')
                      }}<sup class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>

                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="productName"
                    name="productName"
                  >
                    <input
                      id="productName"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.productName !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      :placeholder="
                        $t('page.retail.productList.forms.namePlaceholder')
                      "
                    />
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.productName !== undefined">
                      {{ $t(errors.productName) }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="productBarcode"
                  >
                    <span>
                      {{ $t('page.retail.productList.forms.barcode') }}
                    </span>
                  </label>
                </div>

                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="productBarcode"
                    name="productBarcode"
                  >
                    <input
                      id="productBarcode"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.productBarcode !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                      type="text"
                      :placeholder="
                        $t('page.retail.productList.forms.barcodePlaceholder')
                      "
                    />
                  </Field>
                  <span class="text-xs text-red-400">
                    <template v-if="errors.productBarcode !== undefined">
                      {{ $t(errors.productBarcode) }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="flex w-full space-x-2">
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="block text-left text-xs font-bold text-gray-700"
                      for="brandId"
                    >
                      <span>
                        {{ $t('page.retail.productList.forms.brandName')
                        }}<sup class="text-red-500">*</sup>
                      </span>
                    </label>
                  </div>
                  <div class="text-left">
                    <Field v-slot="{ field }" v-model="brandId" name="brandId">
                      <select
                        id="brandId"
                        v-bind="field"
                        required="required"
                        class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.brandId !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                      >
                        <option value="" disabled hidden>
                          {{
                            $t(
                              'page.retail.productList.forms.brandNamePlaceholder'
                            )
                          }}
                        </option>
                        <option
                          v-for="brand in brandData"
                          :key="brand.id"
                          :value="brand.id"
                        >
                          {{ brand.name }}
                        </option>
                      </select>
                    </Field>

                    <span class="text-xs text-red-400">
                      <template v-if="errors.brandId !== undefined">
                        {{ $t(errors.brandId) }}
                      </template>
                    </span>
                  </div>
                </div>
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="block text-left text-xs font-bold text-gray-700"
                      for="categoryId"
                    >
                      <span>
                        {{ $t('page.retail.productList.forms.category')
                        }}<sup class="text-red-500">*</sup>
                      </span>
                    </label>
                  </div>
                  <div class="text-left">
                    <Field
                      v-slot="{ field }"
                      v-model="categoryId"
                      name="categoryId"
                    >
                      <select
                        id="categoryId"
                        v-bind="field"
                        required="required"
                        class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.categoryId !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                      >
                        <option value="" disabled hidden>
                          {{
                            $t(
                              'page.retail.productList.forms.categoryPlaceholder'
                            )
                          }}
                        </option>
                        <option
                          v-for="category in categoryData"
                          :key="category.id"
                          :value="category.id"
                        >
                          {{ category.name }}
                        </option>
                      </select>
                    </Field>

                    <span class="text-xs text-red-400">
                      <template v-if="errors.categoryId !== undefined">
                        {{ $t(errors.categoryId) }}
                      </template>
                    </span>
                  </div>
                </div>
                <div class="flex w-full flex-col space-y-2">
                  <div>
                    <label
                      class="block text-left text-xs font-bold text-gray-700"
                      for="unitId"
                    >
                      <span>
                        {{ $t('page.retail.productList.forms.productUnit')
                        }}<sup class="text-red-500">*</sup>
                      </span>
                    </label>
                  </div>
                  <div class="text-left">
                    <Field v-slot="{ field }" v-model="unitId" name="unitId">
                      <select
                        id="unitId"
                        v-bind="field"
                        required="required"
                        class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.unitId !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                      >
                        <option value="" disabled hidden>
                          {{
                            $t(
                              'page.retail.productList.forms.productUnitPlaceholder'
                            )
                          }}
                        </option>
                        <option
                          v-for="unit in unitData"
                          :key="unit.id"
                          :value="unit.id"
                        >
                          {{ unit.name }}
                        </option>
                      </select>
                    </Field>

                    <span class="text-xs text-red-400">
                      <template v-if="errors.unitId !== undefined">
                        {{ $t(errors.unitId) }}
                      </template>
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex w-full space-x-2">
                <div class="w-full">
                  <div>
                    <label
                      class="block text-left text-xs font-bold text-gray-700"
                      for="basePrice"
                    >
                      <span>
                        {{ $t('page.retail.productList.forms.basePrice') }}
                      </span>
                    </label>
                  </div>

                  <div class="text-left">
                    <Field
                      v-slot="{ field }"
                      v-model="basePrice"
                      name="basePrice"
                    >
                      <input
                        id="basePrice"
                        v-bind="field"
                        required="required"
                        min="0"
                        class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.basePrice !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                        type="number"
                        :placeholder="
                          $t(
                            'page.retail.productList.forms.basePricePlaceholder'
                          )
                        "
                      />
                    </Field>
                    <span class="text-xs text-red-400">
                      <template v-if="errors.basePrice !== undefined">
                        {{ $t(errors.basePrice) }}
                      </template>
                    </span>
                  </div>
                </div>
                <div class="w-full">
                  <div>
                    <label
                      class="block text-left text-xs font-bold text-gray-700"
                      for="sellPrice"
                    >
                      <span>
                        {{ $t('page.retail.productList.forms.sellPrice') }}
                      </span>
                    </label>
                  </div>

                  <div class="text-left">
                    <Field
                      v-slot="{ field }"
                      v-model="sellPrice"
                      name="sellPrice"
                    >
                      <input
                        id="sellPrice"
                        v-bind="field"
                        required="required"
                        min="0"
                        class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.sellPrice !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                        type="number"
                        :placeholder="
                          $t(
                            'page.retail.productList.forms.sellPricePlaceholder'
                          )
                        "
                      />
                    </Field>
                    <span class="text-xs text-red-400">
                      <template v-if="errors.sellPrice !== undefined">
                        {{ $t(errors.sellPrice) }}
                      </template>
                    </span>
                  </div>
                </div>
              </div>
              <div class="flex w-full space-x-2">
                <div v-if="!isEdit" class="w-full">
                  <div class="pb-1">
                    <label
                      class="flex items-center space-x-2 text-left text-xs font-bold text-gray-700"
                      for="initialStock"
                    >
                      <span>
                        {{ $t('page.retail.productList.forms.initialStock') }}
                      </span>
                      <p
                        class="cursor-help self-center rounded-full border border-white px-1.5 text-xs text-white hover:border-white hover:text-white"
                      >
                        <span>i</span>
                      </p>
                    </label>
                  </div>

                  <div class="text-left">
                    <Field
                      v-slot="{ field }"
                      v-model="initialStock"
                      name="initialStock"
                    >
                      <input
                        id="initialStock"
                        v-bind="field"
                        required="required"
                        min="0"
                        class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.initialStock !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                        type="number"
                        :placeholder="
                          $t(
                            'page.retail.productList.forms.initialStockPlaceholder'
                          )
                        "
                      />
                    </Field>
                    <span class="text-xs text-red-400">
                      <template v-if="errors.initialStock !== undefined">
                        {{ $t(errors.initialStock) }}
                      </template>
                    </span>
                  </div>
                </div>
                <div class="w-full">
                  <div class="pb-1">
                    <label
                      class="flex items-center space-x-2 text-left text-xs font-bold text-gray-700"
                      for="alertStock"
                      @mouseover="showTooltip = true"
                      @mouseleave="showTooltip = false"
                    >
                      <span>
                        {{ $t('page.retail.productList.forms.alertStock') }}
                      </span>

                      <p
                        class="cursor-help self-center rounded-full border border-gray-300 px-1.5 text-xs text-gray-400 hover:border-gray-500 hover:text-gray-500"
                      >
                        <span>i</span>
                      </p>
                      <span
                        v-if="showTooltip"
                        class="absolute mt-8 w-full bg-white p-3 text-center text-xs shadow-lg"
                      >
                        {{ $t('page.retail.productList.forms.alertStockDesc') }}
                      </span>
                    </label>
                  </div>

                  <div class="text-left">
                    <Field
                      v-slot="{ field }"
                      v-model="alertStock"
                      name="alertStock"
                    >
                      <input
                        id="alertStock"
                        v-bind="field"
                        required="required"
                        min="0"
                        class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        :class="[
                          errors.alertStock !== undefined
                            ? 'border-red-400'
                            : 'border-gray-200',
                        ]"
                        type="number"
                        :placeholder="
                          $t(
                            'page.retail.productList.forms.alertStockPlaceholder'
                          )
                        "
                      />
                    </Field>
                    <span class="text-xs text-red-400">
                      <template v-if="errors.alertStock !== undefined">
                        {{ $t(errors.alertStock) }}
                      </template>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end space-x-2 px-5 pb-8">
            <button type="button" class="w-24 text-primary" @click="closeModal">
              {{ $t('button.cancel') }}
            </button>
            <button type="submit" class="btn btn-primary w-24">
              {{ $t('button.save') }}
            </button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
    <!-- END: Modal Add Product -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <DeleteModal
      :id="deleteId"
      :show-modal="showModalDel"
      @yes="confirmDelete($event)"
      @no="showModalDel = false"
    />

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>
<script>
import * as Yup from 'yup'

import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import retailMixin from '@/mixins/retail.js'

import { Form, Field } from 'vee-validate'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import Currency from '@/components/Currency.vue'

import PackageIcon from '@/assets/svg/package.svg'
import EditIcon from '@/assets/svg/edit.svg'
import DeleteIcon from '@/assets/svg/delete.svg'

const regex = /([.,*!?+=~@]).*/

export default {
  name: 'ProductListPage',
  components: {
    Form,
    Field,
    Breadcrumb,
    TableComponent,
    ButtonComponent,
    DeleteModal,
    Currency,

    PackageIcon,
    EditIcon,
    DeleteIcon,
  },
  mixins: [globalMixin, tenantMixin, retailMixin],
  data: () => ({
    showAddProductModal: false,

    productListHeader: [
      {
        item: 'page.retail.productList.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.retail.productList.tableHeaders.brandName',
        customClass: '',
      },
      {
        item: 'page.retail.productList.tableHeaders.category',
        customClass: '',
      },
      {
        item: 'page.retail.productList.tableHeaders.sellPrice',
        customClass: '',
      },
      {
        item: 'page.retail.productList.tableHeaders.productTotal',
        customClass: '',
      },
      {
        item: 'page.retail.productList.tableHeaders.productUnit',
        customClass: '',
      },
      {
        item: 'action.action',
        customClass: '',
      },
    ],
    showTooltip: false,

    // Form Validation
    productSchema: Yup.object().shape({
      productName: Yup.string()
        .typeError('formError.productName.required')
        .required('formError.productName.required')
        .matches(/^(?!\s+$).*/, 'formError.productName.required'),
      brandId: Yup.string()
        .typeError('formError.brand.required')
        .required('formError.brand.required'),
      categoryId: Yup.string()
        .typeError('formError.category.required')
        .required('formError.category.required'),
      unitId: Yup.string()
        .typeError('formError.unit.required')
        .required('formError.unit.required'),
      productBarcode: Yup.string()
        .test(
          'testBarcode',
          'formError.barcode.onlyAllow',
          (value) => !regex.test(value)
        )
        .nullable(),
      basePrice: Yup.number()
        .typeError('formError.mustNumber')
        .min(0, 'formError.min0'),
      sellPrice: Yup.number()
        .typeError('formError.mustNumber')
        .min(0, 'formError.min0'),
      initialStock: Yup.number()
        .typeError('formError.mustNumber')
        .min(0, 'formError.min0'),
      alertStock: Yup.number()
        .typeError('formError.mustNumber')
        .min(0, 'formError.min0'),
    }),
  }),
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllProductRelatedData()
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getAllProductRelatedData()
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    getAllProductRelatedData() {
      this.getAllProduct()
      this.getAllCategory({
        showMessage: false,
        setLoading: false,
        search: '',
        setMetaData: false,
      })
      this.getAllBrand({
        showMessage: false,
        setLoading: false,
        search: '',
        setMetaData: false,
      }).then(() => {
        if (this.brandData.length > 0) {
          this.brandId = this.brandData[0].id
        }
      })
      this.getAllUnit({
        showMessage: false,
        setLoading: false,
        search: '',
        setMetaData: false,
      }).then(() => {
        if (this.unitData.length > 0) {
          this.unitId = this.unitData[0].id
        }
      })
    },
    changeFilter(event) {
      this.filterDataProduct = event.filterData
      this.getAllProduct()
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getAllProductRelatedData()
    },
    showModalUpdate(data) {
      this.showAddProductModal = true
      this.isEdit = true
      this.productId = data.id
      this.productName = data.name
      if ('brand' in data) this.brandId = data.brand.id
      if ('category' in data) this.categoryId = data.category.id
      if ('unit' in data) this.unitId = data.unit.id
      this.basePrice = data.base_price
      this.sellPrice = data.price
      this.productBarcode = data.barcode
      this.productIsRecommended = data.is_recommended
      this.productIsAvailable = data.is_available
      if ('stock' in data) {
        // this.initialStock = data.stock.initial_stock
        this.alertStock = data.stock.alert_stock
      }
    },
    showModalAdd() {
      this.showAddProductModal = true
      this.isEdit = false
      this.resetData(false)
    },
    saveProduct() {
      if (this.productName === '' || this.isLoadingSave) return

      const params = {}
      params.tenant_id = this.tenantId
      params.name = this.productName
      params.reference_code = null
      params.barcode = this.productBarcode
      params.description = ''
      params.base_price = parseInt(this.basePrice)
      params.sale_price = parseInt(this.sellPrice)
      params.category_id = this.categoryId
      params.brand_id = this.brandId
      params.unit_id = this.unitId
      params.alert_stock = parseInt(this.alertStock)
      params.is_recommended = this.productIsRecommended
      params.is_available = this.productIsAvailable

      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.productId === '') {
          this.isLoadingSave = false
          return
        }
        params.id = this.productId

        this.saveProductAPI(params, true)
      } else {
        params.initial_stock = parseInt(this.initialStock)

        this.saveProductAPI(params, false)
      }
    },
    closeModal() {
      this.showAddProductModal = false
    },
    confirmDelete(id) {
      this.deleteProductAPI(id)
    },
    resetData(afterSave = true) {
      this.productId = null
      this.productName = ''
      this.brandId = null
      this.categoryId = null
      this.unitId = null
      this.productBarcode = ''
      this.basePrice = 0
      this.sellPrice = 0
      this.initialStock = 0
      this.alertStock = 0
      this.productIsRecommended = false
      this.productIsAvailable = true

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            productName: '',
            brandId: '',
            categoryId: '',
            unitId: '',
            productBarcode: '',
            basePrice: '',
            sellPrice: '',
            initialStock: '',
            alertStock: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showAddProductModal = false
        this.isEdit = false
        this.getAllProduct({
          showMessage: false,
          setLoading: true,
          search: '',
          setMetaData: true,
        })
      }
    },
  },
}
</script>

export default {
  data: () => ({
    privilegeData: [],
    activePrivilegeID: null,
    privilegeMenus: [],
    privilegeMenuFeatures: [],

    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
  }),
  methods: {
    async getAllPrivilege(showMessage = true) {
      await this.$store
        .dispatch('privilege/getAll', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: this.filterData.search,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.privilegeData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async savePrivilegeAPI(params, isUpdate = false) {
      let dispatcher = 'privilege/create'
      if (isUpdate) dispatcher = 'privilege/update'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)

          if (isUpdate) this.activePrivilegeID = params.id
          else this.activePrivilegeID = response.data.data.id
          // this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deletePrivilege(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('privilege/delete', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllPrivilege(false)
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    async savePrivilegeMenuMappingAPI(params) {
      await this.store
        .dispatch('privilege/createMenuMapping', params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          // this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}

export default {
  data: () => ({
    productId: null,
    productResponseData: [],
    productData: [],
    productDataSearch: [],
    productDataSearchOption: [],
    productWithCategory: {},
    productDetail: {},
    productDetailChildOld: [],
    productDetailChilds: [],
    createdData: {},

    ingredientId: null,
    ingredientDataAll: [],
    ingredientData: [],
    ingredientDataSearch: [],
    ingredientDataSearchOption: [],

    variantId: null,
    variantData: [],
    variantDetail: {},

    // General
    name: '',
    description: '',

    // Ingredient Unit
    stock: 0,

    // Ingredient
    price: 0,
    sale_price: 0,
    target_stock_reminder: 0,

    // Variant
    isRequired: false,
    isMultipleOption: false,
    minSelection: false,
    maxSelection: false,
    variantOptions: [],

    unitId: null,
    unitData: [],

    categoryId: null,
    categoryData: [],
    categoryDataSelect: [],
    isLoadingCategories: false,

    brandId: null,
    brandData: [],
    brandDataSelect: [],

    metaData: {
      page: 1,
      take: 50,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },

    isSuccess: false,
    searchProduct: '',
    showProductDetailModal: false,
  }),
  methods: {
    // API PRODUCT
    searchProductOption(query, exceptionProduct = []) {
      if (query === null || query === undefined || query === '') {
        if (exceptionProduct.length > 0) {
          return this.productDataSearch.filter(
            (item) => !exceptionProduct.includes(item.product_id)
          )
        }
        return this.productDataSearch
      } else {
        this.getAllProduct(false, false, false, query)
        if (exceptionProduct.length > 0) {
          return this.productDataSearchOption.filter(
            (item) => !exceptionProduct.includes(item.product_id)
          )
        }
        return this.productDataSearchOption
      }
    },
    async getAllProduct(
      showMessage = true,
      mappingWithCategory = false,
      setLoading = false,
      search = '',
      setMetaData = false,
      autoGetByMeta = false
    ) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: search === '' ? this.filterData.search : search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }
      await this.$store
        .dispatch('scm/getAllProduct', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            const productResponseData = responseData.data
            if (
              this.filterData.page !== 1 &&
              this.productResponseData.length > 0
            ) {
              this.productResponseData.push(...productResponseData)
            } else {
              this.productResponseData = productResponseData
            }

            if (search === '' || search === null) {
              this.mappingProductData(this.productResponseData)
            } else {
              this.mappingProductData(this.productResponseData, true)
            }

            if (mappingWithCategory) {
              this.mappingProductWithCategory(this.productResponseData)
            }
          }

          if ('meta' in responseData) {
            if (setMetaData) {
              this.metaData = responseData.meta
            }
            if (autoGetByMeta) {
              if (
                'hasNextPage' in responseData.meta &&
                responseData.meta.hasNextPage
              ) {
                this.filterData.page += 1
                this.getAllProduct(false, true, false, '', false, true)
              }
            }
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getProductDetailAPI(showMessage = true, setLoading = true) {
      if (setLoading) this.isLoading = true

      const params = {
        product_id: this.productId,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }
      await this.$store
        .dispatch('scm/getProductDetail', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          this.productDetail = responseData
          this.productDetailChildOld = responseData.childs
          this.productDetailChilds = responseData.childs
          this.showProductDetailModal = true

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          this.showProductDetailModal = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveProductAPI(params, isUpdate = false, isReset = true) {
      let dispatcher = 'scm/createProduct'
      if (isUpdate) dispatcher = 'scm/updateProduct'
      this.isLoadingSave = true

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.isLoadingSave = false

          this.isSuccess = response.statusCode === 200

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
            this.showModalInfo = true
          }

          if (isReset) this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async updateProductAvailabilityAPI(params) {
      this.isLoadingSave = true

      await this.store
        .dispatch('scm/updateProductAvailability', params)
        .then((response) => {
          this.isLoadingSave = false

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async uploadImageProductAPI(params) {
      this.isLoadingSave = true

      await this.store
        .dispatch('scm/uploadImageProduct', params)
        .then((response) => {
          this.isLoadingSave = false

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteProductAPI(id) {
      this.isLoadingSave = true

      await this.store
        .dispatch('scm/deleteProduct', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllProduct(false)
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteProductImageAPI(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('scm/deleteImageProduct', id)
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async updateProductImageAPI(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('scm/updateImageProduct', id)
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    // INGREDIENT
    searchIngredientOption(query) {
      if (query === null || query === undefined || query === '') {
        return this.ingredientDataSearch
      } else {
        this.getAllIngredient(false, false, 50, query)
        return this.ingredientDataSearchOption
      }
    },
    pushToIngredientAll(ingredientData) {
      const ingredientDataAll = this.ingredientDataAll.concat(ingredientData)
      this.ingredientDataAll = ingredientDataAll.reduce((ingredient, obj) => {
        if (!ingredient.some((o) => o.id === obj.id)) {
          ingredient.push(obj)
        }
        return ingredient
      }, [])
    },
    async getAllIngredient(
      showMessage = true,
      setLoading = false,
      take = null,
      search = '',
      setMetaData = false
    ) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: take !== null ? take : this.filterData.take,
        q: search === '' ? this.filterData.search : search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('scm/getAllIngredient', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            const ingredientData = responseData.data
            this.ingredientData = ingredientData
            this.pushToIngredientAll(ingredientData)

            const mappedData = ingredientData.reduce(function (
              accumulator,
              currData
            ) {
              accumulator.push({
                ingredient_id: currData.id,
                ingredient_name: currData.name,
                value: currData.id,
                label: currData.name,
              })
              return accumulator
            },
            [])
            if (search === '' || search === null) {
              this.ingredientDataSearch = mappedData
            } else {
              this.ingredientDataSearchOption = mappedData
              return this.ingredientDataSearchOption
            }
          }

          if (responseData.meta && setMetaData) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          console.log(e)
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveIngredientAPI(params, isUpdate = false) {
      let dispatcher = 'scm/createIngredient'
      if (isUpdate) dispatcher = 'scm/updateIngredient'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
            this.showModalInfo = true
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteIngredientAPI(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('scm/deleteIngredient', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllIngredient(false)
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    // INGREDIENT BRAND
    async getAllIngredientBrand(
      showMessage = true,
      setLoading = false,
      setBrand = true,
      take = null,
      setMetaData = false
    ) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: take !== null ? take : this.filterData.take,
        q: this.filterData.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('scm/getAllIngredientBrand', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.brandData = responseData.data
            const brandDataSelect = []
            this.brandData.forEach((item) => {
              brandDataSelect.push({
                label: item.name,
                value: item.id,
              })
            })
            this.brandDataSelect = brandDataSelect

            if (responseData.data.length > 0 && setBrand) {
              this.brandId = this.brandData[0].id
            }

            if (responseData.meta && setMetaData) {
              this.metaData = responseData.meta
            }

            if (showMessage) {
              this.message = response.data.message
              setTimeout(() => {
                this.showToast('success')
              }, 500)
            }
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveIngredientBrandAPI(params, isUpdate = false) {
      let dispatcher = 'scm/createIngredientBrand'
      if (isUpdate) dispatcher = 'scm/updateIngredientBrand'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
            this.showModalInfo = true
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteIngredientBrandAPI(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('scm/deleteIngredientBrand', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllIngredientBrand(false)
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    // INGREDIENT CATEGORY
    async getAllIngredientCategory(
      showMessage = true,
      setLoading = false,
      setCategory = true,
      take = null,
      setMetaData = false
    ) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: take !== null ? take : this.filterData.take,
        q: this.filterData.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('scm/getAllIngredientCategory', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.categoryData = responseData.data
            const categoryDataSelect = []
            this.categoryData.forEach((item) => {
              categoryDataSelect.push({
                label: item.name,
                value: item.id,
              })
            })
            this.categoryDataSelect = categoryDataSelect

            if (responseData.data.length > 0 && setCategory) {
              this.categoryId = this.categoryData[0].id
            }

            if (responseData.meta && setMetaData) {
              this.metaData = responseData.meta
            }

            if (showMessage) {
              this.message = response.data.message
              setTimeout(() => {
                this.showToast('success')
              }, 500)
            }
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveIngredientCategoryAPI(params, isUpdate = false) {
      let dispatcher = 'scm/createIngredientCategory'
      if (isUpdate) dispatcher = 'scm/updateIngredientCategory'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
            this.showModalInfo = true
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteIngredientCategoryAPI(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('scm/deleteIngredientCategory', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllIngredientCategory(false)
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    // INGREDIENT UNIT
    async getAllIngredientStock(showMessage = true) {
      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('scm/getAllIngredientStock', params)
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.ingredientData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveIngredientStockAPI(params, isUpdate = false) {
      let dispatcher = 'scm/createIngredientStock'
      if (isUpdate) dispatcher = 'scm/updateIngredientStock'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
            this.showModalInfo = true
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    // VARIANT
    async getAllVariant(
      showMessage = true,
      setLoading = false,
      setMetaData = false
    ) {
      if (setLoading) this.isLoading = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('scm/getAllVariant', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.variantData = responseData.data
          }
          if (responseData.meta && setMetaData) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getVariantDetail(showMessage = true, id) {
      await this.$store
        .dispatch('scm/getVariantDetail', {
          id,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.variantDetail = responseData
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveVariantAPI(params, isUpdate = false) {
      let dispatcher = 'scm/createVariant'
      if (isUpdate) dispatcher = 'scm/updateVariant'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
            this.showModalInfo = true
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteVariantAPI(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('scm/deleteVariant', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllVariant(false)
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    // UNIT
    async getAllUnit(showMessage = true, setUnit = true, take = null) {
      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: take !== null ? take : 50,
      }
      await this.$store
        .dispatch('scm/getAllUnit', params)
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.unitData = responseData.data
            if (responseData.data.length > 0 && setUnit) {
              this.unitId = this.unitData[0].id
            }
          }
          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    // CATEGORY
    async getAllCategory(tenant_ids = null) {
      this.isLoadingCategories = true

      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: 50,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      if (tenant_ids !== null) {
        params.tenant_ids = tenant_ids
      }

      await this.$store
        .dispatch('scm/getAllCategory', params)
        .then((response) => {
          this.isLoadingCategories = false

          const responseData = response.data.data
          if (responseData) {
            this.categoryData = responseData.data
            const categoryDataSelect = []
            this.categoryData.forEach((item) => {
              categoryDataSelect.push({
                label: item.name,
                value: item.id,
              })
            })
            this.categoryDataSelect = categoryDataSelect

            if (responseData.data.length > 0) {
              this.categoryId = this.categoryData[0].id
            }
          }
        })
        .catch((e) => {
          this.isLoadingCategories = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    // MAPPING PRODUCT
    mappingProductData(productResponseData, setSearch = false) {
      const productData = []
      const filterProductHasChild = productResponseData.filter((item) => {
        return item.child_product_total === 0
      })
      filterProductHasChild.forEach((item) => {
        productData.push({
          product_name: item.name,
          product_id: item.id,
          label: item.name,
          value: item.id,
        })
      })
      if (setSearch) {
        this.productDataSearch = productData
        this.productDataSearchOption = productData
      } else {
        this.productDataSearch = productData
        this.productDataSearchOption = this.productDataSearch
        this.productData = productResponseData
      }
    },
    mappingProductWithCategory(productResponseData) {
      const dataPerCategory = {}
      productResponseData.forEach((item) => {
        if (item.category.name in dataPerCategory) {
          dataPerCategory[item.category.name].push(item)
        } else {
          dataPerCategory[item.category.name] = [item]
        }
      })

      this.productWithCategory = dataPerCategory
    },
    // SEARCHING PRODUCT
    searchingProduct(
      from = 'productCategory',
      type = 'local',
      searchString = ''
    ) {
      if (type === 'local') {
        if (from === 'productCategory') {
          const filteredProduct = this.productResponseData.filter((item) =>
            item.name.toLowerCase().includes(searchString.toLowerCase())
          )
          this.mappingProductWithCategory(filteredProduct)
          this.mappingProductData(filteredProduct)
        } else if (from === 'productChild') {
          if (searchString === '') {
            this.productDetailChilds = this.productDetailChildOld
          } else {
            const filteredProduct = this.productDetailChildOld.filter((item) =>
              item.name.toLowerCase().includes(searchString.toLowerCase())
            )
            this.productDetailChilds = filteredProduct
          }
        }
      }
    },

    getMasterUnitIngredient(ingredientId) {
      if (ingredientId !== undefined && ingredientId !== null) {
        const data = this.ingredientDataAll.find(
          (item) => item.id === ingredientId
        )
        if (data !== undefined && 'master_unit' in data) {
          return data.master_unit.base
        } else {
          return null
        }
      } else {
        return null
      }
    },
    getUnitIdBy(unitSearched, by = 'name') {
      if (unitSearched !== undefined && unitSearched !== null) {
        let data = undefined
        if (by === 'name') {
          data = this.unitData.find(
            (item) => item.name.toLowerCase() === unitSearched.toLowerCase()
          )
        } else {
          data = this.unitData.find(
            (item) => item.code.toLowerCase() === unitSearched.toLowerCase()
          )
        }
        if (data !== undefined) {
          return data.id
        } else {
          return null
        }
      } else {
        return null
      }
    },
  },
}

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div class="mt-8 flex w-full justify-center space-y-4">
      <div
        class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8 md:w-7/12"
      >
        <div class="flex w-full items-center justify-between text-base">
          <span>
            {{
              $t('page.purchaseOrder.stockPurchaseCartAddress.shippingAddress')
            }}
          </span>
          <div
            class="cursor-pointer text-xs text-primary"
            @click="
              $router.push({
                name: 'stock-purchase-cart-address-form',
                params: {
                  slug: $route.params.slug,
                },
                query: {
                  lang: $route.query.lang,
                },
              })
            "
          >
            {{
              $t('page.purchaseOrder.stockPurchaseCartAddress.addNewAddress')
            }}
          </div>
        </div>
        <div class="flex w-full">
          <div
            v-if="userAddressData.length > 0"
            class="flex w-full flex-col space-y-2"
          >
            <AddressItem
              v-for="addressData in userAddressData.sort(function (x, y) {
                return x.is_primary === y.is_primary ? 0 : x.is_primary ? -1 : 1
              })"
              :key="addressData.id"
              :address-data="addressData"
              :selected-address-id="selectedAddressId"
              @select-address="selectAddress"
            />
          </div>
          <div v-else class="w-full text-gray-500">
            {{ $t('page.purchaseOrder.stockPurchaseCartAddress.noAddress') }}
          </div>
        </div>
        <div
          v-if="userAddressData.length > 0"
          class="flex w-full justify-end py-2"
        >
          <button
            type="button"
            class="btn btn-outline-primary mr-2 w-24"
            @click="toCartPage()"
          >
            {{ $t('button.back') }}
          </button>
          <button
            type="submit"
            class="btn py-3 px-4"
            :class="[
              selectedAddressId !== ''
                ? 'btn-primary'
                : 'cursor-not-allowed bg-gray-300 text-gray-50',
            ]"
            @click="saveAddress"
          >
            <LoadingIcon
              v-if="isLoading || isLoadingSave"
              icon="spinning-circles"
              color="white"
              class="mr-2 h-4 w-4"
            />
            {{
              $t('page.purchaseOrder.stockPurchaseCartAddress.selectAddress')
            }}
          </button>
        </div>
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import stockPurchaseMixin from '@/mixins/stockPurchase.js'
import tenantMixin from '@/mixins/tenant.js'

import AddressItem from '@/components/item/AddressItem.vue'

export default {
  name: 'StockPurchaseCartAddress',
  components: {
    AddressItem,
  },
  mixins: [globalMixin, stockPurchaseMixin, tenantMixin],
  data: () => ({
    selectedAddressId: '',
  }),
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllUserAddress(false)

        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getAllUserAddress(false)
        })
        this.isInstitute = true
      }
    }

    const selectedAddressStore =
      this.$store.getters['stockPurchase/selectedAddress']
    if (selectedAddressStore !== null) {
      this.selectedAddressId = selectedAddressStore
    }
  },
  methods: {
    selectAddress($event) {
      this.selectedAddressId = $event
    },
    saveAddress() {
      if (this.selectedAddressId !== '') {
        this.$store.dispatch(
          'stockPurchase/setSelectedAddress',
          this.selectedAddressId
        )
        this.toCartPage()
      }
    },
    toCartPage() {
      this.$router.push({
        name: 'stock-purchase-cart',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>

<template>
  <vue-load-image class="relative">
    <template #image>
      <template v-if="image !== null">
        <img
          :src="image"
          class="inset-0 aspect-square w-full object-cover object-center"
          :class="[
            height !== '' ? height : '',
            disabled ? 'disabled-img' : '',
            roundedClass,
            customClass,
          ]"
          :alt="alt"
        />
      </template>
      <template v-else>
        <div
          v-if="isDefaultMenuSvg"
          class="inset-0 flex h-52 w-full items-center justify-center bg-gray-50 object-cover object-center"
        >
          <MenuInfoIcon class="h-full w-full text-gray-100" />
          <div class="absolute">
            <div class="flex flex-col items-center justify-center">
              <MenuInfoSmallIcon class="h-20 w-20 text-gray-300" />
              <p class="mt-3 text-xs text-gray-500">
                {{ $t('no_photo_available') }}
              </p>
            </div>
          </div>
        </div>
        <img
          v-else-if="isDefaultAvatarSvg"
          src="@/assets/images/guest.png"
          class="inset-0 aspect-square w-full object-cover object-center"
          :class="[
            height !== '' ? height : '',
            disabled ? 'disabled-img' : '',
            customClass,
            roundedClass,
          ]"
        />
        <img
          v-else-if="isDefaultMenu"
          src="@/assets/images/menu-info.png"
          class="inset-0 aspect-square w-full object-cover object-center"
          :class="[
            height !== '' ? height : '',
            disabled ? 'disabled-img' : '',
            customClass,
            roundedClass,
          ]"
        />
        <img
          v-else
          src="@/assets/images/icon.png"
          class="inset-0 aspect-square w-full object-cover object-center"
          :class="[
            height !== '' ? height : '',
            disabled ? 'disabled-img' : '',
            customClass,
            roundedClass,
          ]"
        />
      </template>
    </template>
    <template #preloader>
      <ShimmerCard
        :height="height !== '' ? height : 'aspect-square'"
        :rounded-class="roundedClass"
      />
    </template>
    <template #error>
      <div
        v-if="isDefaultMenuSvg"
        class="inset-0 flex h-52 w-full items-center justify-center bg-gray-50 object-cover object-center"
      >
        <MenuInfoIcon class="h-full w-full text-gray-100" />
        <div class="absolute">
          <div class="flex flex-col items-center justify-center">
            <MenuInfoSmallIcon class="h-20 w-20 text-gray-300" />
            <p class="mt-3 text-xs text-gray-500">
              {{ $t('no_photo_available') }}
            </p>
          </div>
        </div>
      </div>
      <img
        v-else-if="isDefaultAvatarSvg"
        src="@/assets/images/guest.png"
        class="inset-0 aspect-square w-full object-cover object-center"
        :class="[
          height !== '' ? height : '',
          disabled ? 'disabled-img' : '',
          customClass,
          roundedClass,
        ]"
      />
      <img
        v-else-if="isDefaultMenu"
        src="@/assets/images/menu-info.png"
        class="inset-0 aspect-square w-full object-cover object-center"
        :class="[
          height !== '' ? height : '',
          disabled ? 'disabled-img' : '',
          customClass,
          roundedClass,
        ]"
      />
      <img
        v-else
        src="@/assets/images/icon.png"
        class="inset-0 aspect-square w-full object-cover object-center"
        :class="[
          height !== '' ? height : '',
          disabled ? 'disabled-img' : '',
          customClass,
          roundedClass,
        ]"
      />
    </template>
  </vue-load-image>
</template>

<script>
import ShimmerCard from '@/components/shimmer/ShimmerCard.vue'

import MenuInfoIcon from '@/assets/svg/menu-info.svg'
import MenuInfoSmallIcon from '@/assets/svg/menu-info-small.svg'

export default {
  name: 'ImageLoader',
  components: {
    ShimmerCard,
    MenuInfoIcon,
    MenuInfoSmallIcon,
  },
  props: {
    image: {
      type: String,
      default: null,
      required: false,
    },
    height: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showClosed: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
      required: false,
    },
    roundedClass: {
      type: String,
      default: 'rounded-lg',
      required: false,
    },
    alt: {
      type: String,
      default: '',
      required: false,
    },
    isDefaultMenuSvg: {
      type: Boolean,
      default: false,
      required: false,
    },
    isDefaultAvatarSvg: {
      type: Boolean,
      default: false,
      required: false,
    },
    isDefaultMenu: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
}
</script>

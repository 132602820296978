<template>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12 mt-8">
      <div class="flex w-full justify-end md:w-fit">
        <div class="relative w-full text-slate-500 sm:ml-auto">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateFilter"
            :options="{
              autoApply: false,
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              format: 'YYYY-MM-DD',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 sm:w-56"
          />
        </div>
      </div>
    </div>
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Order Table -->
        <div class="col-span-12">
          <div class="grid grid-cols-12 gap-6">
            <TableComponent
              :date-string="dateFilter"
              :is-show-search="true"
              :title="$t('page.purchaseOrder.history.title')"
              :header="purchaseOrderHeaderList"
              :meta-data="metaData"
              :is-not-empty="purchaseOrderData.length > 0"
              @change-filter="changeFilter($event)"
            >
              <template #additionalHeader>
                <div class="intro-y flex w-full px-2 pt-4 md:px-8">
                  <div
                    class="w-full max-w-full border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-gray-700 dark:text-gray-400"
                  >
                    <div
                      class="flex w-full max-w-full flex-nowrap space-x-2 overflow-auto"
                    >
                      <div
                        v-for="(status, index) in statusList"
                        :key="index"
                        class="flex basis-full"
                      >
                        <p
                          class="flex grow cursor-pointer items-center justify-center space-x-2 rounded-t-lg border-b-2 p-4 hover:border-primary hover:text-gray-600 dark:hover:text-gray-300"
                          :class="[
                            statusActive === status
                              ? 'border-primary dark:border-primary'
                              : 'border-transparent',
                          ]"
                          @click="changeStatusActive(status)"
                        >
                          <span>
                            {{ $t(`page.purchaseOrder.history.${status}`) }}
                          </span>
                          <span
                            v-if="false"
                            class="rounded-full bg-primary px-2 text-white"
                          >
                            {{
                              purchaseOrderSummaryData[
                                `${status.toLowerCase()}`
                              ]
                            }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <tr
                v-for="data in purchaseOrderData"
                :key="data.id"
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: 'stock-purchase-history-detail',
                    params: {
                      orderId: data.transaction_id,
                    },
                    query: {
                      lang: $route.query.lang,
                      id: data.id,
                      status: data.status,
                    },
                  })
                "
              >
                <td class="text-center uppercase">
                  {{ getSplitString(data.transaction_id) }}
                </td>
                <td class="text-center">
                  {{
                    formattedDate(subDate(data.created), 'dddd, DD MMMM YYYY')
                  }}
                </td>
                <td class="text-center">
                  {{
                    data.purchase_item.reduce((accumulator, currVal) => {
                      return accumulator + currVal.quantity
                    }, 0)
                  }}
                </td>
                <td>
                  <Currency
                    :total-in-string="String(data.total_origin)"
                    wrap-class="justify-center"
                  />
                </td>
                <td class="text-center">
                  <div class="flex w-full items-center justify-center">
                    <div
                      class="cursor-pointer rounded-full py-1 px-2 text-xs capitalize text-white md:px-4 md:text-sm"
                      :class="[getStatusOrderColor(data.status)]"
                    >
                      <p>
                        {{ $t(`page.purchaseOrder.history.${data.status}`) }}
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
            </TableComponent>
          </div>
        </div>
        <!-- END: Order Table -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->

        <LoadingIndicator v-if="isLoading || isLoadingSave" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import globalMixin from '@/mixins/global.js'
import stockPurchaseMixin from '@/mixins/stockPurchase.js'
import tenantMixin from '@/mixins/tenant.js'

import Currency from '@/components/Currency.vue'
import TableComponent from '@/components/TableComponent.vue'

export default {
  name: 'HistoryPurchaseOrderPage',
  components: {
    Currency,
    TableComponent,
  },
  mixins: [globalMixin, stockPurchaseMixin, tenantMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref('')

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
    }
  },

  data: () => ({
    purchaseOrderHeaderList: [
      {
        item: 'page.purchaseOrder.history.tableHeaders.trxNo',
        customClass: 'text-center',
      },
      {
        item: 'page.purchaseOrder.history.tableHeaders.trxDate',
        customClass: 'text-center',
      },
      {
        item: 'page.purchaseOrder.history.tableHeaders.totalItem',
        customClass: 'text-center',
      },
      {
        item: 'page.purchaseOrder.history.tableHeaders.totalPayment',
        customClass: 'text-center',
      },
      {
        item: 'page.purchaseOrder.history.tableHeaders.trxStatus',
        customClass: 'text-center',
      },
    ],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      if (this.tenantId !== '') {
        this.getAllPurchaseSupplier(this.statusActive, this.filterDates, true)
      }
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllPurchaseSupplier(this.statusActive, this.filterDates, true)
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getAllPurchaseSupplier(this.statusActive, this.filterDates, true)
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    changeFilter(event) {
      this.filterData = event.filterData

      if (this.tenantId !== '') {
        this.getAllPurchaseSupplier(this.statusActive, this.filterDates, true)
      }
    },
    changeStatusActive(status) {
      this.statusActive = status

      if (this.tenantId !== '') {
        this.getAllPurchaseSupplier(status, this.filterDates, true)
      }
    },
  },
}
</script>

import axiosCore, { routePathAPICore } from '@/plugins/axios-core.js'
import router from '@/router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  // Payment Method Report
  paymentMethodSummary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      axiosCore
        .get(`${routePathAPICore()}/transaction/payment-method/summary`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  paymentMethodReport({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
        payment_ids: payload.payment_ids ? payload.payment_ids : 'qris',
      }
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.payment_name) params.payment_name = payload.payment_name
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
      if (payload.institute_id) params.institute_id = payload.institute_id

      axiosCore
        .get(`${routePathAPICore()}/transaction/payment-method/list`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  paymentMethodChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      if (payload.payment_ids) params.payment_ids = payload.payment_ids
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
      if (payload.institute_id) params.institute_id = payload.institute_id

      axiosCore
        .get(`${routePathAPICore()}/transaction/payment-method/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  paymentMethodRank({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      if (payload.payment_ids) params.payment_ids = payload.payment_ids
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
      if (payload.institute_id) params.institute_id = payload.institute_id

      axiosCore
        .get(`${routePathAPICore()}/transaction/payment-method/rank`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  paymentMethodDownload({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosCore
        .get(`${routePathAPICore()}/transaction/payment-method/download`, {
          params: {
            start_date: payload.start_date,
            end_date: payload.end_date,
            order: payload.order ? payload.order : 'DESC',
            file_type: payload.file_type ? payload.file_type : '.csv', // .csv / .xlsx
          },
          headers: {
            'x-language-code': currentLanguageCode,
          },
          responseType: 'blob',
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <!-- BEGIN: Mobile Menu -->
  <div
    class="mobile-menu md:hidden"
    :class="{
      'mobile-menu--active': activeMobileMenu,
    }"
  >
    <div class="mobile-menu-bar">
      <a href="" class="mr-auto flex">
        <!-- <img
          alt="InKanteen - Web Dashboard"
          class="w-6"
          src="@/assets/images/logo.svg"
        /> -->
        <InkanteenIcon class="w-8 text-white" />
      </a>
      <a href="javascript:;" class="mobile-menu-toggler">
        <BarChart2Icon
          class="h-8 w-8 -rotate-90 transform text-white"
          @click="toggleMobileMenu"
        />
      </a>
    </div>
    <div class="scrollable">
      <a href="javascript:;" class="mobile-menu-toggler">
        <XCircleIcon
          class="h-8 w-8 -rotate-90 transform text-white"
          @click="toggleMobileMenu"
        />
      </a>
      <ul class="scrollable__content py-2">
        <div v-if="activeUserStore !== null" class="pl-3 pt-1 pb-4 text-white">
          <p class="text-xs">
            {{ $t(`info.greeting.${generateGreeting()}`) }},
          </p>
          <div
            v-if="isInstituteSuperAdmin && activeTenantStore !== null"
            class="flex gap-2"
          >
            <p class="max-w-full truncate text-lg">
              {{ activeTenantStore.name }}
            </p>
            <SwapIcon
              class="cursor-pointer hover:text-gray-100"
              @click="
                () => {
                  activeMobileMenu = false
                  toDashboardSuperInstitutePage()
                }
              "
            />
          </div>
          <p
            v-else-if="'full_name' in activeUserStore"
            class="max-w-full truncate text-lg"
          >
            {{ activeUserStore.full_name }}
          </p>
        </div>
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenuComputed">
          <li
            v-if="menu == 'devider'"
            :key="menu + menuKey"
            class="menu__devider my-6"
          ></li>
          <li v-else-if="menu.mobileDivier" :key="`mobiledivider-${menuKey}`">
            <div class="menu">
              <span class="menu__title font-bold">
                {{ $t(`${menu.title}`) }}
              </span>
            </div>
          </li>
          <li v-else :key="menuKey">
            <template v-if="['purchase_order'].includes(menu.menu)">
              <a
                v-if="
                  activeUserStore !== null &&
                  activeUserStore.email.includes('noega')
                "
                href="javascript:;"
                class="menu"
                :class="{
                  'menu--active bg-white font-bold': menu.active,
                  'menu--open': menu.activeDropdown,
                }"
                @click="linkTo(menu, router)"
              >
                <div
                  class="menu__icon"
                  :class="{
                    'text-primary': menu.active,
                  }"
                >
                  <template v-if="menu.svg === true">
                    <HomeMenuIcon v-if="menu.icon === 'HomeIcon'" class="w-6" />
                    <ReportMenuIcon
                      v-else-if="menu.icon === 'ReportIcon'"
                      class="w-6"
                    />
                    <BookIcon
                      v-else-if="menu.icon === 'BookIcon'"
                      class="w-6"
                    />
                    <IncomeIcon
                      v-else-if="menu.icon === 'IncomeIcon'"
                      class="w-6"
                    />
                    <ExpenseIcon
                      v-else-if="menu.icon === 'ExpenseIcon'"
                      class="w-6"
                    />
                    <ReportCashIcon
                      v-else-if="menu.icon === 'ReportCashIcon'"
                      class="w-6"
                    />
                    <AnalyticMenuIcon
                      v-if="menu.icon === 'AnalyticIcon'"
                      class="w-6"
                    />
                    <ProfileMenuIcon
                      v-if="menu.icon === 'ProfileIcon'"
                      class="w-5"
                    />
                    <StoreIcon v-if="menu.icon === 'StoreIcon'" class="w-5" />
                    <EmployeeIcon
                      v-if="menu.icon === 'EmployeeIcon'"
                      class="w-5"
                    />
                    <TableManagementIcon
                      v-if="menu.icon === 'TableManagementIcon'"
                      class="w-5"
                    />
                    <OmniChannelIcon
                      v-if="menu.icon === 'OmniChannelIcon'"
                      class="w-5"
                    />
                    <PaymentChannelIcon
                      v-if="menu.icon === 'PaymentChannelIcon'"
                      class="w-5"
                    />
                    <BagIcon v-if="menu.icon === 'BagIcon'" />
                    <MoneyIcon v-if="menu.icon === 'MoneyIcon'" />
                  </template>
                  <template v-else>
                    <component :is="menu.icon" />
                  </template>
                </div>
                <div class="menu__title">
                  <span
                    :class="{
                      'text-primary': menu.active,
                    }"
                  >
                    {{ $t(menu.title) }}
                  </span>
                  <div
                    v-if="menu.submenu && menu.showSubMenu"
                    class="menu__sub-icon"
                    :class="{ 'rotate-180 transform': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </a>
            </template>
            <template v-else>
              <a
                href="javascript:;"
                class="menu"
                :class="{
                  'menu--active bg-white font-bold': menu.active,
                  'menu--open': menu.activeDropdown,
                }"
                @click="linkTo(menu, router)"
              >
                <div
                  class="menu__icon"
                  :class="{
                    'text-primary': menu.active,
                  }"
                >
                  <template v-if="menu.svg === true">
                    <HomeMenuIcon v-if="menu.icon === 'HomeIcon'" class="w-6" />
                    <ReportMenuIcon
                      v-else-if="menu.icon === 'ReportIcon'"
                      class="w-6"
                    />
                    <BookIcon
                      v-else-if="menu.icon === 'BookIcon'"
                      class="w-6"
                    />
                    <IncomeIcon
                      v-else-if="menu.icon === 'IncomeIcon'"
                      class="w-6"
                    />
                    <ExpenseIcon
                      v-else-if="menu.icon === 'ExpenseIcon'"
                      class="w-6"
                    />
                    <ReportCashIcon
                      v-else-if="menu.icon === 'ReportCashIcon'"
                      class="w-6"
                    />
                    <AnalyticMenuIcon
                      v-if="menu.icon === 'AnalyticIcon'"
                      class="w-6"
                    />
                    <ProfileMenuIcon
                      v-if="menu.icon === 'ProfileIcon'"
                      class="w-5"
                    />
                    <StoreIcon v-if="menu.icon === 'StoreIcon'" class="w-5" />
                    <EmployeeIcon
                      v-if="menu.icon === 'EmployeeIcon'"
                      class="w-5"
                    />
                    <TableManagementIcon
                      v-if="menu.icon === 'TableManagementIcon'"
                      class="w-5"
                    />
                    <OmniChannelIcon
                      v-if="menu.icon === 'OmniChannelIcon'"
                      class="w-5"
                    />
                    <PaymentChannelIcon
                      v-if="menu.icon === 'PaymentChannelIcon'"
                      class="w-5"
                    />
                    <BagIcon v-if="menu.icon === 'BagIcon'" />
                    <MoneyIcon v-if="menu.icon === 'MoneyIcon'" />
                  </template>
                  <template v-else>
                    <component :is="menu.icon" />
                  </template>
                </div>
                <div class="menu__title">
                  <span
                    :class="{
                      'text-primary': menu.active,
                    }"
                  >
                    {{ $t(menu.title) }}
                  </span>
                  <div
                    v-if="menu.submenu && menu.showSubMenu"
                    class="menu__sub-icon"
                    :class="{ 'rotate-180 transform': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </a>
            </template>
            <!-- BEGIN: Second Child -->
            <transition @enter="enter" @leave="leave">
              <ul v-if="menu.submenu && menu.activeDropdown">
                <li
                  v-for="(submenu, subMenuKey) in menu.submenu"
                  :key="subMenuKey"
                >
                  <a
                    href="javascript:;"
                    class="menu"
                    :class="{ 'menu--active font-bold': submenu.active }"
                    @click="linkTo(submenu, router)"
                  >
                    <div class="menu__icon">
                      <!-- <ActivityIcon /> -->
                      <CircleIcon class="w-2" />
                    </div>
                    <div class="menu__title">
                      {{ $t(submenu.title) }}
                      <div
                        v-if="submenu.submenu"
                        class="menu__sub-icon"
                        :class="{
                          'rotate-180 transform': submenu.activeDropdown,
                        }"
                      >
                        <ChevronDownIcon />
                      </div>
                    </div>
                  </a>
                  <!-- BEGIN: Third Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="submenu.submenu && submenu.activeDropdown">
                      <li
                        v-for="(lastSubMenu, lastSubMenuKey) in submenu.submenu"
                        :key="lastSubMenuKey"
                      >
                        <a
                          href="javascript:;"
                          class="menu"
                          :class="{ 'menu--active': lastSubMenu.active }"
                          @click="linkTo(lastSubMenu, router)"
                        >
                          <div class="menu__icon">
                            <!-- <ZapIcon /> -->
                            <CircleIcon class="w-2" />
                          </div>
                          <div class="menu__title">
                            {{ $t(lastSubMenu.title) }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Third Child -->
                </li>
              </ul>
            </transition>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </div>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import { useSideMenuStore } from '@/stores/side-menu'
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave,
} from './index'
import { nestedMenu } from '@/layouts/side-menu'
import dom from '@left4code/tw-starter/dist/js/dom'
import SimpleBar from 'simplebar'

import globalMixin from '@/mixins/global.js'

import InkanteenIcon from '@/assets/svg/inkanteen-icon-dynamic.svg'

import HomeMenuIcon from '@/assets/svg/menu/home.svg'
import ReportMenuIcon from '@/assets/svg/menu/report.svg'
import AnalyticMenuIcon from '@/assets/svg/menu/analytic.svg'
import ProfileMenuIcon from '@/assets/svg/menu/user.svg'
import BookIcon from '@/assets/svg/menu/book.svg'
import IncomeIcon from '@/assets/svg/menu/income.svg'
import ExpenseIcon from '@/assets/svg/menu/expense.svg'
import ReportCashIcon from '@/assets/svg/menu/report-cash.svg'
import StoreIcon from '@/assets/svg/menu/store.svg'
import EmployeeIcon from '@/assets/svg/menu/karyawan.svg'
import TableManagementIcon from '@/assets/svg/menu/meja.svg'
import OmniChannelIcon from '@/assets/svg/menu/omnichannel.svg'
import PaymentChannelIcon from '@/assets/svg/menu/payment.svg'
import BagIcon from '@/assets/svg/menu/bag.svg'
import MoneyIcon from '@/assets/svg/menu/money.svg'

import SwapIcon from '@/assets/svg/swap.svg'

export default {
  components: {
    SimpleBar,
    nestedMenu,

    InkanteenIcon,
    HomeMenuIcon,
    ReportMenuIcon,
    AnalyticMenuIcon,
    ProfileMenuIcon,
    BookIcon,
    IncomeIcon,
    ExpenseIcon,
    ReportCashIcon,
    StoreIcon,
    EmployeeIcon,
    TableManagementIcon,
    OmniChannelIcon,
    PaymentChannelIcon,
    BagIcon,
    MoneyIcon,
    SwapIcon,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const sideMenuStore = useSideMenuStore()
    const formattedMenu = ref([])
    const menuFiltered = ref([])
    const userMenus = ref([])
    const userFeatures = ref([])
    // const mobileMenu = computed(() => nestedMenu(sideMenuStore.menu, route))

    watch(
      computed(() => route.path),
      () => {
        // formattedMenu.value = $h.toRaw(mobileMenu.value)
        formattedMenu.value = $h.toRaw(nestedMenu(menuFiltered.value, route))
      }
    )

    onMounted(async () => {
      if (dom('.mobile-menu .scrollable').length) {
        new SimpleBar(dom('.mobile-menu .scrollable')[0])
      }
      await getUserAccess()
    })
    const validatePage = () => {
      if (route.meta.general !== true) {
        if (userFeatures.value.length > 0) {
          let isRouteNameExist = false

          const routeExist = userFeatures.value.filter(
            (feature) => feature.link === route.name
          )

          if (routeExist.length > 0) {
            isRouteNameExist = true
          }

          if (isRouteNameExist === false) {
            // router.push({
            //   path: '/',
            //   params: { slug: route.params.slug },
            //   query: { lang: route.query.lang },
            // })
            // window.location = '/'
          }
        }
      }
    }

    const getUserAccess = async () => {
      if (store.getters['auth/activeUser'] !== null) {
        await store
          .dispatch('menu/access')
          .then((response) => {
            userMenus.value = response.data.menus
            userFeatures.value = response.data.features

            // validating page
            validatePage()

            const parentMenuFiltered = []
            sideMenuStore.menu.forEach((obj) => {
              parentMenuFiltered.push(obj)
            })

            parentMenuFiltered.forEach((obj) => {
              menuFiltered.value.push(obj)
              // } else {
              //   // redeclaring object, so the data will not related anymore with the real one
              //   const newMenu = {
              //     ...obj,
              //   }
              //   if (obj.submenu !== undefined && obj.submenu.length > 0) {
              //     newMenu.submenu = []
              //     obj.submenu.forEach((subObj) => {
              //       const featureCheck = userFeatures.value.filter(
              //         (feature) => feature.link === subObj.pageName
              //       )

              //       if (featureCheck.length > 0) {
              //         newMenu.submenu.push(subObj)
              //       }
              //     })
              //   }
              //   menuFiltered.value.push(newMenu)
              // }
            })
            formattedMenu.value = $h.toRaw(
              nestedMenu(menuFiltered.value, route)
            )
          })
          .catch((error) => {
            return error
          })
      }
    }

    return {
      t,
      route,
      router,
      sideMenuStore,
      formattedMenu,
      userMenus,
      userFeatures,
      activeMobileMenu,
      toggleMobileMenu,
      linkTo,
      enter,
      leave,
      getUserAccess,
      validatePage,
    }
  },
  computed: {
    formattedMenuComputed() {
      if (this.isInstituteRetail) {
        // Institute Retail
        return this.formattedMenu.filter(
          (item) =>
            (this.menuRetail.includes(item.pageName) ||
              item.menu !== 'setting') &&
            !this.forbiddenMenu.includes(item.pageName)
        )
      } else if (this.isInstituteSuperAdminMode) {
        // Institute Super Admin Mode
        const filteredMenu = []

        this.formattedMenu.forEach((item) => {
          if (this.menuDashboardSuperInstituteOnly.includes(item.pageName)) {
            filteredMenu.push(item)
          } else {
            if ('submenu' in item) {
              const subMenuFiltered = []
              item.submenu.forEach((subMenuItem) => {
                if (
                  this.menuDashboardSuperInstituteOnly.includes(
                    subMenuItem.pageName
                  )
                ) {
                  subMenuFiltered.push(subMenuItem)
                }
              })
              if (subMenuFiltered.length > 0) {
                item.submenu = subMenuFiltered
                filteredMenu.push(item)
              }
            }
          }
        })
        return filteredMenu
      } else if (!this.isInstituteSuperAdmin) {
        // Else, not institute super admin and not retail, default
        const filteredMenu = []

        this.formattedMenu.forEach((item) => {
          if (
            !this.menuSuperInstituteAdminOnly.includes(item.pageName) &&
            !this.menuRetailOnly.includes(item.pageName) &&
            !this.forbiddenMenu.includes(item.pageName)
          ) {
            filteredMenu.push(item)
          } else {
            if ('submenu' in item) {
              const subMenuFiltered = []
              item.submenu.forEach((subMenuItem) => {
                if (this.retailAllowGlobal.includes(subMenuItem.pageName)) {
                  subMenuFiltered.push(subMenuItem)
                }
              })
              if (subMenuFiltered.length > 0) {
                item.submenu = subMenuFiltered
                filteredMenu.push(item)
              }
            }
          }
        })
        return filteredMenu
      } else {
        // Institute Super Admin, Look Tenant Mode
        const filteredMenu = []

        this.formattedMenu.forEach((item) => {
          if (
            !this.menuRetailOnly.includes(item.pageName) &&
            !this.forbiddenMenu.includes(item.pageName)
          ) {
            filteredMenu.push(item)
          } else {
            if ('submenu' in item) {
              const subMenuFiltered = []
              item.submenu.forEach((subMenuItem) => {
                if (this.retailAllowGlobal.includes(subMenuItem.pageName)) {
                  subMenuFiltered.push(subMenuItem)
                }
              })
              if (subMenuFiltered.length > 0) {
                item.submenu = subMenuFiltered
                filteredMenu.push(item)
              }
            }
          }
        })
        return filteredMenu
      }
    },
  },
}
</script>

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.expense.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
      <div class="flex w-full md:w-fit">
        <div class="flex w-full md:w-fit">
          <div
            class="relative w-full text-slate-500 sm:ml-auto sm:mt-0 md:w-fit"
          >
            <CalendarIcon
              class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
            />
            <Litepicker
              v-model="dateFilter"
              :options="{
                autoApply: false,
                singleMode: false,
                numberOfColumns: 2,
                numberOfMonths: 2,
                showWeekNumbers: true,
                format: 'YYYY-MM-DD',
                dropdowns: {
                  minYear: 1990,
                  maxYear: null,
                  months: true,
                  years: true,
                },
              }"
              class="form-control box pl-8 md:w-56"
            />
          </div>
        </div>
        <AddButtonComponent
          wrapper-class="bg-primary text-white dark:bg-white dark:text-black"
          :title="$t('page.expense.add')"
          @click="showModalExpense"
        />
        <ExportCSVComponent
          wrapper-class="bg-primary text-white dark:bg-white dark:text-black"
          @export="downloadInstituteExpense"
        />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Expense List Table -->
          <TableComponent
            :date-string="dateFilterFormat(dateFilter)"
            :title="$t('page.expense.tableHeaders.title')"
            :header="expenseTableHeaders"
            :is-show-search="true"
            :is-not-empty="expenseData.length > 0"
            :meta-data="metaData"
            :is-show-pagination="true"
            @change-filter="changeFilter($event)"
          >
            <template #icon>
              <ExpenseIcon class="mr-2 h-5 w-5 text-black dark:text-white" />
            </template>

            <tr v-for="data in expenseData" :key="data.id">
              <td>
                {{ data.institute_expense.code }}
              </td>
              <td>
                {{ data.institute_expense.name }}
              </td>
              <td>
                <Currency
                  :prefix-text="data.institute_expense.amount > 0 ? '' : '-'"
                  :total-in-string="Math.abs(data.institute_expense.amount)"
                />
              </td>
              <td>
                <p class="line-clamp-2">
                  {{ data.institute_expense.notes }}
                </p>
              </td>
              <td class="space-x-2">
                <div class="flex space-x-2">
                  <button
                    class="flex items-center space-x-1 rounded-sm bg-primary px-2 py-1 text-xs font-medium text-white"
                    @click="showModalUpdate(data)"
                  >
                    <EditIcon />
                    <span>{{ $t('action.edit') }}</span>
                  </button>
                  <button
                    v-if="false"
                    class="flex items-center space-x-1 rounded-sm bg-danger px-2 py-1 text-xs font-medium text-white"
                    @click="showModalDelete(data.institute_expense.id)"
                  >
                    <DeleteIcon />
                    <span>{{ $t('action.delete') }}</span>
                  </button>
                </div>
              </td>
            </tr>
          </TableComponent>
          <!-- END: Expense List Table -->

          <!-- BEGIN: Modal Add Routine Billing -->
          <Modal
            :show="showModalAddExpense"
            size="modal-xl"
            @hidden="closeModal"
          >
            <ModalBody class="p-4">
              <Form
                ref="form"
                v-slot="{ errors }"
                :validation-schema="expenseSchema"
                :initial-errors="{}"
                :initial-touched="{}"
                :validate-on-mount="false"
                @submit="saveExpense"
              >
                <div class="flex flex-col space-y-4 px-5 pb-4 text-center">
                  <div class="flex flex-col space-y-3">
                    <div class="flex items-center space-x-1">
                      <ExpenseIcon class="h-5 w-5 text-black" />
                      <p class="font-bold">
                        <span v-if="!isEdit">
                          {{ $t('page.expense.forms.title') }}
                        </span>
                        <span v-else>
                          {{ $t('page.expense.forms.editTitle') }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-col gap-4 md:flex-row">
                    <div class="flex w-full flex-col space-y-2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="expenseName"
                        >
                          <span>
                            {{ $t('page.expense.forms.expenseName')
                            }}<sup class="text-red-500">*</sup>
                          </span>
                        </label>
                      </div>

                      <div class="text-left">
                        <Field
                          v-slot="{ field }"
                          v-model="expenseName"
                          name="expenseName"
                        >
                          <input
                            id="expenseName"
                            v-bind="field"
                            required="required"
                            class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            :class="[
                              errors.expenseName !== undefined
                                ? 'border-red-400'
                                : 'border-gray-200',
                            ]"
                            type="text"
                            :placeholder="
                              $t('page.expense.forms.expenseNamePlaceholder')
                            "
                          />
                        </Field>
                        <span class="text-xs text-red-400">
                          <template v-if="errors.expenseName !== undefined">
                            {{ $t(errors.expenseName) }}
                          </template>
                        </span>
                      </div>
                    </div>

                    <div class="flex w-full flex-col space-y-2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="expenseNumber"
                        >
                          <span>
                            {{ $t('page.expense.forms.expenseNumber') }}
                          </span>
                        </label>
                      </div>

                      <div class="text-left">
                        <Field
                          v-slot="{ field }"
                          v-model="expenseNumber"
                          name="expenseNumber"
                        >
                          <input
                            id="expenseNumber"
                            v-bind="field"
                            required="required"
                            class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            :class="[
                              errors.expenseNumber !== undefined
                                ? 'border-red-400'
                                : 'border-gray-200',
                            ]"
                            type="text"
                            :placeholder="
                              $t('page.expense.forms.expenseNumberPlaceholder')
                            "
                          />
                        </Field>
                        <span
                          v-if="errors.expenseNumber !== undefined"
                          class="text-xs text-red-400"
                        >
                          {{ $t(errors.expenseNumber) }}
                        </span>
                        <span v-else class="text-xs text-green-800">
                          {{
                            $t('page.expense.forms.expenseNumberDescription')
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-4 border-b pb-6 md:flex-row">
                    <div class="flex w-full flex-col space-y-2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="expenseDate"
                        >
                          <span>
                            {{ $t('page.expense.forms.expenseDate')
                            }}<sup class="text-red-500">*</sup>
                          </span>
                        </label>
                      </div>

                      <div class="text-left">
                        <div class="flex w-full md:w-fit">
                          <div
                            class="relative w-full text-slate-500 sm:ml-auto sm:mt-0 md:w-fit"
                          >
                            <CalendarIcon
                              class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
                            />
                            <VueDatePicker
                              v-model="expenseDate"
                              :enable-time-picker="false"
                              auto-apply
                              :placeholder="
                                $t('page.expense.forms.expenseDatePlaceholder')
                              "
                              :format="formatDatePicker"
                              :input-class-name="[
                                trySave &&
                                (expenseDate === undefined ||
                                  expenseDate === null)
                                  ? 'border-red-400'
                                  : 'border-gray-200',
                              ]"
                            />
                          </div>
                        </div>
                        <span class="text-xs text-red-400">
                          <template
                            v-if="
                              trySave &&
                              (expenseDate === undefined ||
                                expenseDate === null)
                            "
                          >
                            {{ $t('formError.expenseDate.required') }}
                          </template>
                        </span>
                      </div>
                    </div>
                    <div class="flex w-full flex-col space-y-2">
                      <div>
                        <label
                          class="block text-left text-xs font-bold text-gray-700"
                          for="expenseTime"
                        >
                          <span>
                            {{ $t('page.expense.forms.expenseTime')
                            }}<sup class="text-red-500">*</sup>
                          </span>
                        </label>
                      </div>

                      <div class="text-left">
                        <div class="flex w-full md:w-fit">
                          <div
                            class="relative w-full text-slate-500 sm:ml-auto sm:mt-0 md:w-fit"
                          >
                            <CalendarIcon
                              class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
                            />
                            <VueDatePicker
                              v-model="expenseTime"
                              time-picker
                              auto-apply
                              :placeholder="
                                $t('page.expense.forms.expenseTimePlaceholder')
                              "
                              :input-class-name="[
                                trySave &&
                                (expenseTime === undefined ||
                                  expenseTime === null)
                                  ? 'border-red-400'
                                  : 'border-gray-200',
                              ]"
                            />
                          </div>
                        </div>
                        <span class="text-xs text-red-400">
                          <template
                            v-if="
                              trySave &&
                              (expenseTime === undefined ||
                                expenseTime === null)
                            "
                          >
                            {{ $t('formError.expenseTime.required') }}
                          </template>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex w-full flex-col gap-4 md:flex-row md:justify-between md:gap-2"
                  >
                    <div class="flex w-full flex-col gap-4 md:w-1/2">
                      <div class="flex w-full flex-col space-y-2">
                        <div>
                          <label
                            class="block text-left text-xs font-bold text-gray-700"
                            for="expenseTotal"
                          >
                            <span>
                              {{ $t('page.expense.forms.expenseTotal') }}
                            </span>
                          </label>
                        </div>

                        <div class="text-left">
                          <VueNumber
                            v-model="expenseTotal"
                            v-bind="numberFormat"
                            class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                            :placeholder="
                              $t('page.expense.forms.expenseTotalPlaceholder')
                            "
                            @focus="checkZero"
                            @keyup="checkTotal"
                          />
                          <span class="text-xs text-red-400">
                            <template v-if="errors.expenseTotal !== undefined">
                              {{ $t(errors.expenseTotal) }}
                            </template>
                          </span>
                        </div>
                      </div>

                      <div class="flex w-full flex-col space-y-2">
                        <div>
                          <label
                            class="block text-left text-xs font-bold text-gray-700"
                            for="expenseNote"
                          >
                            <span>
                              {{ $t('page.expense.forms.expenseNote') }}
                            </span>
                          </label>
                        </div>

                        <div class="text-left">
                          <Field
                            v-slot="{ field }"
                            v-model="expenseNote"
                            name="expenseNote"
                          >
                            <textarea
                              id="expenseNote"
                              v-bind="field"
                              required="required"
                              class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                              :class="[
                                errors.expenseNote !== undefined
                                  ? 'border-red-400'
                                  : 'border-gray-200',
                              ]"
                              :placeholder="
                                $t('page.expense.forms.expenseNotePlaceholder')
                              "
                            >
                            </textarea>
                          </Field>
                          <span class="text-xs text-red-400">
                            <template v-if="errors.expenseNote !== undefined">
                              {{ $t(errors.expenseNote) }}
                            </template>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-start md:justify-end">
                      <div
                        class="flex items-start gap-2 text-lg font-bold md:items-end"
                      >
                        <p>{{ $t('info.total') }}</p>
                        <p>{{ expenseTotal || 0 }}</p>
                        <!-- <Currency
                          :total-in-string="
                            expenseTotal === '' || expenseTotal === null
                              ? 0
                              : expenseTotal
                          "
                        /> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex justify-end space-x-2 px-5 pb-8">
                  <button
                    type="button"
                    class="w-24 text-primary"
                    @click="closeModal"
                  >
                    {{ $t('button.cancel') }}
                  </button>
                  <button type="submit" class="btn btn-primary w-24">
                    {{ $t('button.save') }}
                  </button>
                </div>
              </Form>
            </ModalBody>
          </Modal>
          <!-- END: Modal Add Routine Billing -->

          <!-- BEGIN: Toast -->
          <Toast
            id="success"
            :title="$t('formInfo.success')"
            :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
          />
          <Toast
            id="failed"
            :title="$t('formInfo.failed')"
            :message="message !== '' ? message : $t('formInfo.fetchFailed')"
            :is-failed="true"
          />
          <!-- END: Toast -->

          <DeleteModal
            :id="deleteId"
            :show-modal="showModalDel"
            @yes="confirmDelete($event)"
            @no="showModalDel = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Yup from 'yup'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import expenseMixin from '@/mixins/expense.js'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { component as VueNumber } from '@coders-tm/vue-number-format'
import { Form, Field } from 'vee-validate'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import AddButtonComponent from '@/components/AddButtonComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import DeleteModal from '@/components/DeleteModal.vue'

import ExpenseIcon from '@/assets/svg/menu/expense.svg'
import EditIcon from '@/assets/svg/edit.svg'
import DeleteIcon from '@/assets/svg/delete.svg'

export default {
  name: 'ExpenseList',
  components: {
    Form,
    Field,
    Breadcrumb,
    TableComponent,

    Currency,
    AddButtonComponent,
    ExportCSVComponent,
    VueDatePicker,
    VueNumber,
    DeleteModal,

    ExpenseIcon,
    EditIcon,
    DeleteIcon,
  },
  mixins: [globalMixin, expenseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()

    const dateFilter = ref('')

    return {
      t,
      n,
      store,
      dateFilter,
    }
  },
  data: () => ({
    expenseTableHeaders: [
      {
        item: 'page.expense.tableHeaders.no',
        customClass: '',
      },
      {
        item: 'page.expense.tableHeaders.name',
        customClass: '',
      },
      {
        item: 'page.expense.tableHeaders.total',
        customClass: '',
      },
      {
        item: 'page.expense.tableHeaders.note',
        customClass: '',
      },
      {
        item: 'action.action',
        customClass: '',
      },
    ],

    showModalAddExpense: false,

    // Form Validation
    expenseSchema: Yup.object().shape({
      expenseName: Yup.string()
        .typeError('formError.expenseName.required')
        .required('formError.expenseName.required')
        .matches(/^(?!\s+$).*/, 'formError.expenseName.required'),
    }),
    trySave: false,
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
        this.getAllInstituteExpenseHistories()
      }
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
      this.getAllInstituteExpenseHistories()
    }
  },
  methods: {
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllInstituteExpenseHistories(false)
    },
    closeModal() {
      this.showModalAddExpense = false
    },
    showModalExpense() {
      this.showModalAddExpense = true
      this.resetData(false)
    },
    showModalUpdate(data) {
      this.showModalAddExpense = true
      this.isEdit = true
      let todayDate = new Date()
      let newDate = todayDate
      let newTime = null
      let id = data.id
      let name = data.name
      let code = data.code
      let amount = data.amount
      let notes = data.notes
      if ('institute_expense' in data) {
        id = data.institute_expense.id
        name = data.institute_expense.name
        code = data.institute_expense.code
        amount = data.institute_expense.amount
        notes = data.institute_expense.notes
        if ('date' in data.institute_expense && data.institute_expense.date) {
          newDate = new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            data.institute_expense.date
          )
        }
        if ('time' in data.institute_expense && data.institute_expense.time) {
          const times = data.institute_expense.time.split(':')
          if (times.length > 0) {
            newTime = {}
            if (times[0]) newTime.hours = times[0]
            if (times[1]) newTime.minutes = times[1]
          }
        }
      }
      this.expenseId = id
      this.expenseDate = newDate
      this.expenseTime = newTime
      this.expenseName = name
      this.expenseNumber = code
      this.expenseTotal = amount
      this.expenseNote = notes
    },
    resetData(afterSave = true) {
      this.expenseId = null
      this.expenseName = ''
      this.expenseNumber = ''
      this.expenseTotal = ''
      this.expenseDate = null
      this.expenseTime = null
      this.expenseNote = ''
      this.trySave = false

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            expenseName: '',
            expenseDate: '',
            expenseTime: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showModalAddExpense = false
        this.isEdit = false
        this.getAllInstituteExpenseHistories(false)
      }
    },
    checkTotal(event) {
      const amount = event.target.value.replace(/\D+/g, '')
      this.expenseTotal = amount
    },
    formatDatePicker(date) {
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${year}-${month}-${day}`
    },
    saveExpense() {
      this.trySave = true
      if (
        (this.expenseDate === undefined || this.expenseDate === null) &&
        (this.expenseTime === undefined || this.expenseTime === null)
      )
        return

      const params = {
        institute_id: this.activeUserInstitute,
        code: this.expenseNumber,
        name: this.expenseName,
        amount: this.extractAmount(this.expenseTotal),
        date: this.extractDate(this.expenseDate),
        time: this.extractTime(this.expenseTime),
        notes: this.expenseNote,
      }

      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.expenseId === null) {
          this.isLoadingSave = false
          return
        }
        params.id = this.expenseId

        this.saveInstituteExpenseAPI(params, true)
      } else {
        this.saveInstituteExpenseAPI(params)
      }
    },
    extractDate(date) {
      return date.getDate()
    },
    extractTime(times = { hours: 0, minutes: 0, seconds: 0 }) {
      return `${times.hours}:${times.minutes}`
    },
    extractAmount(amount) {
      return String(amount).replace(/\D+/g, '')
    },
    confirmDelete(id) {
      this.deleteInstituteExpenseAPI(id)
    },
  },
}
</script>

<template>
  <div class="flex w-full items-center justify-center py-10">
    <p class="text-xl">Coming Soon!</p>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'

export default {
  name: 'ComingSoonPage',
  components: {},
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
    }
  },
  data: () => ({}),
}
</script>

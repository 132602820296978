<template>
  <Dropdown class="ml-3 mt-0">
    <DropdownToggle
      class="btn ml-3 font-normal sm:ml-auto sm:mt-0"
      :class="
        wrapperClass === ''
          ? 'bg-black text-white dark:bg-white dark:text-black'
          : wrapperClass
      "
      @click="showDropdown = true"
    >
      <!-- <ButtonComponent :title="$t('action.export')"> -->
      <!-- <template #icon> -->

      <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
      <span>{{ $t('action.export') }}</span>

      <!-- </template> -->
      <!-- </ButtonComponent> -->
    </DropdownToggle>
    <DropdownMenu class="w-40">
      <DropdownContent v-if="showDropdown" class="overflow-y-auto">
        <DropdownItem @click="doExport('.csv')"> CSV (.csv) </DropdownItem>
        <DropdownItem @click="doExport('.xlsx')"> Excel (.xlsx) </DropdownItem>
      </DropdownContent>
    </DropdownMenu>
  </Dropdown>
</template>
<script>
export default {
  name: 'ExportCSVComponent',
  props: {
    wrapperClass: {
      type: String,
      default: '',
    },
  },
  emits: ['export'],
  data: () => ({
    showDropdown: false,
  }),
  methods: {
    doExport(type) {
      this.showDropdown = false
      this.$emit('export', type)
    },
  },
}
</script>

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div class="mt-8 flex w-full justify-center space-y-4">
      <div
        class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8 md:w-7/12"
      >
        <div class="flex w-full items-center justify-between text-base">
          <span>
            {{
              $t('page.purchaseOrder.stockPurchaseCartAddressForm.addAddress')
            }}
          </span>
        </div>
        <div class="flex flex-col space-y-3 border-b border-gray-200 pb-5">
          <div class="input-form w-full">
            <label
              for="form-label"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('page.purchaseOrder.stockPurchaseCartAddressForm.label') }}
            </label>
            <input
              id="form-label"
              v-model.trim="validate.label.$model"
              type="text"
              name="label"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.label.$error }"
              :placeholder="
                $t(
                  'page.purchaseOrder.stockPurchaseCartAddressForm.labelPlaceholder'
                )
              "
            />
            <template v-if="validate.label.$error">
              <div
                v-for="(error, index) in validate.label.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form w-full">
            <label
              for="form-name"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              Penerima
            </label>
            <input
              id="form-name"
              v-model.trim="validate.name.$model"
              type="text"
              name="name"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.name.$error }"
              placeholder="Masukkan nama penerima"
            />
            <template v-if="validate.name.$error">
              <div
                v-for="(error, index) in validate.name.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form w-full">
            <label
              for="form-phone"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.phone') }}
            </label>
            <input
              id="form-phone"
              v-model="validate.phone.$model"
              type="text"
              name="phone"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.phone.$error }"
              :placeholder="$t('formLabel.phone')"
            />
            <template v-if="validate.phone.$error">
              <div
                v-for="(error, index) in validate.phone.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
        </div>
        <div class="flex flex-col space-y-3">
          <div class="input-form w-full">
            <label
              for="form-address-province"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.province') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="validate.province.$model"
                label="province_name"
                track-by="province_name"
                :placeholder="$t('formLabel.select.province')"
                :options="provinceList"
                :searchable="true"
                :classes="multiSelectClasses"
                :class="{ 'border-danger': validate.province.$error }"
                @select="changeAddress('province')"
              />
              <template v-if="validate.province.$error">
                <div
                  v-for="(error, index) in validate.province.$errors"
                  :key="index"
                  class="mt-2 text-danger"
                >
                  {{ $t(error.$message) }}
                </div>
              </template>
            </div>
          </div>

          <div class="input-form w-full">
            <label
              for="form-address-city"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.city') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="validate.city.$model"
                label="city_name"
                track-by="city_name"
                :placeholder="$t('formLabel.select.city')"
                :options="cityList"
                :searchable="true"
                :classes="multiSelectClasses"
                :class="{ 'border-danger': validate.city.$error }"
                :disabled="cityDisabled === true"
                @select="changeAddress('city')"
              />
              <template v-if="validate.city.$error">
                <div
                  v-for="(error, index) in validate.city.$errors"
                  :key="index"
                  class="mt-2 text-danger"
                >
                  {{ $t(error.$message) }}
                </div>
              </template>
            </div>
          </div>

          <div class="input-form w-full">
            <label
              for="form-address-district"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.district') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="validate.district.$model"
                label="district_name"
                track-by="district_name"
                :placeholder="$t('formLabel.select.district')"
                :options="districtList"
                :searchable="true"
                :classes="multiSelectClasses"
                :class="{ 'border-danger': validate.district.$error }"
                :disabled="districtDisabled === true"
                @select="changeAddress('district')"
              />
              <template v-if="validate.district.$error">
                <div
                  v-for="(error, index) in validate.district.$errors"
                  :key="index"
                  class="mt-2 text-danger"
                >
                  {{ $t(error.$message) }}
                </div>
              </template>
            </div>
          </div>
          <div class="input-form w-full">
            <label
              for="form-address-village"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.village') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="validate.village.$model"
                label="village_name"
                track-by="village_name"
                :placeholder="$t('formLabel.select.village')"
                :options="villageList"
                :searchable="true"
                :classes="multiSelectClasses"
                :class="{ 'border-danger': validate.village.$error }"
                :disabled="villageDisabled === true"
                @select="changeAddress('village')"
              />
              <template v-if="validate.village.$error">
                <div
                  v-for="(error, index) in validate.village.$errors"
                  :key="index"
                  class="mt-2 text-danger"
                >
                  {{ $t(error.$message) }}
                </div>
              </template>
            </div>
          </div>
          <div class="input-form w-full">
            <label
              for="form-address-postal-code"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.postalCode') }}
            </label>
            <div class="mt-2">
              <input
                id="form-postalCode"
                v-model.trim="validate.postalCode.$model"
                type="number"
                name="postalCode"
                class="intro-x login__input form-control block py-3 px-4"
                :class="{ 'border-danger': validate.postalCode.$error }"
                :placeholder="$t('formLabel.postalCode')"
              />
              <template v-if="validate.postalCode.$error">
                <div
                  v-for="(error, index) in validate.postalCode.$errors"
                  :key="index"
                  class="mt-2 text-danger"
                >
                  {{ $t(error.$message) }}
                </div>
              </template>
            </div>
          </div>
          <div class="input-form w-full">
            <label
              for="form-address"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.address') }}
            </label>
            <input
              id="form-address"
              v-model.trim="validate.address.$model"
              type="text"
              name="address"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.address.$error }"
              placeholder="Masukkan detail alamat (Jalan, apartemen, dll)"
            />
            <template v-if="validate.address.$error">
              <div
                v-for="(error, index) in validate.address.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="flex w-full items-center space-x-2">
            <input
              v-model="formData.isPrimary"
              class="accent-primary checked:bg-primary focus:bg-primary focus:ring-primary"
              type="checkbox"
              role="switch"
            />
            <span>Simpan sebagai alamat utama</span>
          </div>
        </div>
        <div class="flex w-full justify-end py-5">
          <button
            type="button"
            class="btn btn-outline-primary mr-2 w-24"
            @click="$router.back()"
          >
            {{ $t('button.back') }}
          </button>
          <button
            v-if="userAddressId !== null"
            type="button"
            class="btn btn-danger mr-2 w-24"
            @click="showModalDelete(userAddressId)"
          >
            <span>{{ $t('action.delete') }}</span>
          </button>
          <button
            type="submit"
            class="btn btn-primary py-3 px-4"
            @click="saveUserAddress"
          >
            <LoadingIcon
              v-if="isLoading || isLoadingSave"
              icon="spinning-circles"
              color="white"
              class="mr-2 h-4 w-4"
            />
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </div>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <DeleteModal
      :id="userAddressId"
      :show-modal="showModalDel"
      @yes="confirmDelete($event)"
      @no="showModalDel = false"
    />
  </div>
</template>
<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  maxLength,
  minLength,
  numeric,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { helper as $h } from '@/utils/helper'

import globalMixin from '@/mixins/global.js'
import stockPurchaseMixin from '@/mixins/stockPurchase.js'

import Multiselect from '@vueform/multiselect'

import DeleteModal from '@/components/DeleteModal.vue'

export default {
  name: 'StockPurchaseCartAddress',
  components: {
    Multiselect,
    DeleteModal,
  },
  mixins: [globalMixin, stockPurchaseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const activeUser = store.getters['auth/activeUser']
    const route = useRoute()
    const router = useRouter()

    const provincePage = ref(1)
    const cityPage = ref(1)
    const districtPage = ref(1)
    const villagePage = ref(1)

    const cityDisabled = ref(true)
    const districtDisabled = ref(true)
    const villageDisabled = ref(true)

    const provinceList = ref([])
    const cityList = ref([])
    const districtList = ref([])
    const villageList = ref([])

    const userAddressDetail = ref({})

    const userAddressId = ref(null)

    const getDetailUserAddress = async (userAddressId) => {
      await store
        .dispatch('stockPurchase/getDetailUserAddress', {
          id: userAddressId,
        })
        .then((response) => {
          const responseData = response.data.data
          userAddressDetail.value = responseData
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const formData = reactive({
      label: '',
      name: '',
      phone: '',
      address: '',
      postalCode: '',
      privilegeId: '',
      province: '',
      city: '',
      district: '',
      village: '',
      isPrimary: false,
    })

    const labelRequired = helpers.withMessage(
      'formError.label.required',
      required
    )

    const nameRequired = helpers.withMessage(
      'formError.recipientName.required',
      required
    )

    const phoneRequired = helpers.withMessage(
      'formError.phone.required',
      required
    )
    const phoneNumeric = helpers.withMessage('formError.phone.numeric', numeric)

    const addressRequired = helpers.withMessage(
      'formError.address.required',
      required
    )

    const provinceRequired = helpers.withMessage(
      'formError.province.required',
      required
    )
    const cityRequired = helpers.withMessage(
      'formError.city.required',
      required
    )
    const districtRequired = helpers.withMessage(
      'formError.district.required',
      required
    )
    const villageRequired = helpers.withMessage(
      'formError.village.required',
      required
    )

    const postalCodeMaxLength = helpers.withMessage(
      'formError.postalCode.maxLength',
      maxLength(5)
    )
    const postalCodeRequired = helpers.withMessage(
      'formError.postalCode.required',
      required
    )

    const rules = {
      label: {
        labelRequired,
      },
      name: {
        nameRequired,
      },
      phone: {
        phoneRequired,
        phoneNumeric,
      },
      address: {
        addressRequired,
      },
      postalCode: {
        postalCodeRequired,
        postalCodeMaxLength,
      },
      province: {
        provinceRequired,
      },
      city: {
        cityRequired,
      },
      district: {
        districtRequired,
      },
      village: {
        villageRequired,
      },
    }

    cityDisabled.value = false
    districtDisabled.value = false
    villageDisabled.value = false

    let validate = useVuelidate(rules, toRefs(formData))

    onMounted(async () => {
      await getAddressProvince()

      if (route.query.id !== undefined) {
        getDetailUserAddress(route.query.id).then(() => {
          formData.label = userAddressDetail.value.label
          formData.name = userAddressDetail.value.recipient
          formData.phone = userAddressDetail.value.phone
          formData.province = userAddressDetail.value.province_id
          getAddressCity().then(() => {
            formData.city = userAddressDetail.value.city_id
            getAddressDistrict().then(() => {
              formData.district = userAddressDetail.value.district_id
              getAddressVillage().then(() => {
                formData.village = userAddressDetail.value.village_id
              })
            })
          })
          formData.postalCode = userAddressDetail.value.postal_code
          formData.address = userAddressDetail.value.detail_address
          formData.isPrimary = userAddressDetail.value.is_primary
        })
        userAddressId.value = route.query.id
      }
    })

    const getAddressProvince = async () => {
      const queryParameters = {
        order: 'DESC',
        page: provincePage.value,
        take: 20,
        q: '',
      }
      await store
        .dispatch('address/province', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const provinceTemporary = []
            response.data.data.forEach((item) => {
              provinceTemporary.push({
                value: item.id,
                ...item,
              })
            })
            provinceList.value = [...provinceList.value, ...provinceTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('province')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressCity = async () => {
      const queryParameters = {
        order: 'DESC',
        page: cityPage.value,
        take: 50,
        q: '',
        province_id: formData.province,
      }
      await store
        .dispatch('address/city', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const cityTemporary = []
            response.data.data.forEach((item) => {
              cityTemporary.push({
                value: item.id,
                ...item,
              })
            })
            if (cityPage.value === 1) {
              cityList.value = []
              cityList.value = [...cityTemporary]
            } else {
              cityList.value = [...cityList.value, ...cityTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('city')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressDistrict = async () => {
      const queryParameters = {
        order: 'DESC',
        page: 1,
        take: 50,
        q: '',
        city_id: formData.city,
      }
      await store
        .dispatch('address/district', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const districtTemporary = []
            response.data.data.forEach((item) => {
              districtTemporary.push({
                value: item.id,
                ...item,
              })
            })
            if (districtPage.value === 1) {
              districtList.value = []
              districtList.value = [...districtTemporary]
            } else {
              districtList.value = [...districtList.value, ...districtTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('district')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressVillage = async () => {
      const queryParameters = {
        order: 'DESC',
        page: 1,
        take: 50,
        q: '',
        district_id: formData.district,
      }
      await store
        .dispatch('address/village', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const villageTemporary = []
            response.data.data.forEach((item) => {
              villageTemporary.push({
                value: item.id,
                ...item,
              })
            })
            if (villagePage.value === 1) {
              villageList.value = []
              villageList.value = [...villageTemporary]
            } else {
              villageList.value = [...villageList.value, ...villageTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('village')
            }
          }
        })
        .catch((error) => {})
    }

    const loadMores = async (type) => {
      if (type === 'province') {
        provincePage.value += 1
        getAddressProvince()
      } else if (type === 'city') {
        cityPage.value += 1
        getAddressCity()
      } else if (type === 'district') {
        districtPage.value += 1
        getAddressDistrict()
      } else if (type === 'village') {
        villagePage.value += 1
        getAddressVillage()
      }
    }

    const changeAddress = async (type) => {
      setTimeout(() => {
        if (type === 'province') {
          cityPage.value = 1
          formData.city = ''
          cityList.value = []
          cityDisabled.value = false

          formData.district = ''
          districtList.value = []
          districtDisabled.value = true

          formData.village = ''
          villageList.value = []
          villageDisabled.value = true

          if (formData.province !== '') {
            getAddressCity()
          }
        } else if (type === 'city') {
          districtPage.value = 1
          formData.district = ''
          districtList.value = []
          districtDisabled.value = false

          formData.village = ''
          villageList.value = []
          villageDisabled.value = true

          if (formData.city !== '') {
            getAddressDistrict()
          }
        } else if (type === 'district') {
          villagePage.value = 1
          formData.village = ''
          villageList.value = []
          villageDisabled.value = false

          if (formData.district !== '') {
            getAddressVillage()
          }
        }
      }, 200)
    }

    return {
      t,
      n,
      store,
      route,
      router,
      formData,
      validate,
      cityDisabled,
      districtDisabled,
      villageDisabled,
      changeAddress,
      provinceList,
      cityList,
      districtList,
      villageList,
      userAddressId,

      getAddressProvince,
      getAddressCity,
      getAddressDistrict,
      getAddressVillage,
    }
  },
  methods: {
    async saveUserAddress() {
      const isFormCorrect = await this.validate.$validate()
      if (isFormCorrect) {
        const params = {
          label: this.formData.label,
          is_primary: this.formData.isPrimary,
          recipient: this.formData.name,
          phone: this.formData.phone,
          province_id: this.formData.province,
          city_id: this.formData.city,
          district_id: this.formData.district,
          village_id: this.formData.village,
          postal_code: this.formData.postalCode,
          detail_address: this.formData.address,
        }

        if (this.userAddressId !== null) {
          params.id = this.userAddressId

          this.saveUserAddressAPI(params, true)
        } else {
          this.saveUserAddressAPI(params, false)
        }
      }
    },
    confirmDelete(id) {
      this.deleteUserAddressAPI(id)
    },
  },
}
</script>

<template>
  <TabGroup>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box my-5 pt-2">
      <div
        class="mt-6 flex flex-row border-t border-slate-200/60 px-5 pt-5 dark:border-darkmode-400 lg:mt-4 lg:border-t-0 lg:pt-0"
      >
        <div class="flex justify-center self-center align-middle">
          <BussinessMenuIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
        </div>
        <div class="flex">
          <p class="text-lg">{{ $t('menu.profile.bussiness') }}</p>
        </div>
      </div>
      <form class="validate-form my-6 px-5" @submit.prevent="updateBussiness">
        <div
          class="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 dark:border-darkmode-400 lg:flex-row"
        >
          <div
            class="border-t border-slate-200/60 px-5 pt-5 dark:border-darkmode-400 lg:w-1/4 lg:border-t-0 lg:pt-0"
          >
            <div class="flex items-center truncate sm:whitespace-normal">
              <p class="text-md">{{ $t('page.bussiness.index') }}</p>
            </div>
          </div>

          <div
            class="mt-4 flex flex-col px-5 lg:mt-0 lg:w-3/4 lg:justify-start"
          >
            <div class="image-fit relative h-20 w-20 flex-none lg:h-24 lg:w-24">
              <template v-if="avatarPreview != ''">
                <ImageLoader
                  :image="avatarPreview"
                  :alt="formData.bussinessName"
                  rounded-class="rounded-full"
                />
              </template>
              <template v-else>
                <ImageLoader
                  :image="bussinessImage.value"
                  :alt="formData.bussinessName"
                  rounded-class="rounded-full"
                />
              </template>

              <div
                class="absolute bottom-0 right-0 mb-1 flex cursor-pointer items-center justify-center rounded-full bg-primary p-1 lg:p-2"
                @click="selectFile()"
              >
                <input
                  id="hidden-input"
                  ref="imageUploader"
                  type="file"
                  accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
                  class="hidden"
                />
                <CameraIcon class="h-3 w-3 text-white lg:h-4 lg:w-4" />
              </div>
            </div>

            <div class="intro-x lg:mt-4">
              <div class="flex w-full flex-col lg:flex-row">
                <div class="input-form mt-3 w-full">
                  <label
                    for="form-bussinessName"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.bussinessName') }}*
                  </label>
                  <input
                    id="form-bussinessName"
                    v-model.trim="validate.bussinessName.$model"
                    type="text"
                    name="bussinessName"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.bussinessName.$error }"
                    :placeholder="$t('formLabel.bussinessName')"
                  />
                  <template v-if="validate.bussinessName.$error">
                    <div
                      v-for="(error, index) in validate.bussinessName.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3 w-full lg:ml-4">
                  <label
                    for="form-email"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.email') }}
                  </label>
                  <input
                    id="form-email"
                    v-model.trim="validate.email.$model"
                    readonly
                    type="email"
                    name="email"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.email.$error }"
                    :placeholder="$t('formLabel.email')"
                  />
                  <template v-if="validate.email.$error">
                    <div
                      v-for="(error, index) in validate.email.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>

              <div class="input-form mt-3">
                <label
                  for="form-description"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.description') }}
                </label>
                <input
                  id="form-description"
                  v-model="validate.description.$model"
                  type="text"
                  name="description"
                  class="intro-x login__input form-control block py-3 px-4"
                  :class="{ 'border-danger': validate.description.$error }"
                  :placeholder="$t('formLabel.description')"
                />
                <template v-if="validate.description.$error">
                  <div
                    v-for="(error, index) in validate.description.$errors"
                    :key="index"
                    class="mt-2 text-danger"
                  >
                    {{ $t(error.$message) }}
                  </div>
                </template>
              </div>

              <div class="flex w-full flex-col lg:flex-row">
                <div class="input-form mt-3 w-full">
                  <label
                    for="form-phone"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.phone') }}*
                  </label>
                  <input
                    id="form-phone"
                    v-model="validate.phone.$model"
                    type="text"
                    name="phone"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.phone.$error }"
                    :placeholder="$t('formLabel.phone')"
                  />
                  <template v-if="validate.phone.$error">
                    <div
                      v-for="(error, index) in validate.phone.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
                <div class="input-form mt-3 w-full lg:ml-4">
                  <label
                    for="form-website"
                    class="form-label flex w-full flex-col sm:flex-row"
                  >
                    {{ $t('formLabel.website') }}
                  </label>
                  <input
                    id="form-website"
                    v-model.trim="validate.website.$model"
                    type="text"
                    name="website"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.website.$error }"
                    :placeholder="$t('formLabel.website')"
                  />
                  <template v-if="validate.website.$error">
                    <div
                      v-for="(error, index) in validate.website.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 dark:border-darkmode-400 lg:flex-row"
        >
          <div
            class="mt-4 border-t border-slate-200/60 px-5 dark:border-darkmode-400 lg:w-1/4 lg:border-t-0"
          >
            <div class="flex items-center truncate sm:whitespace-normal">
              <p class="text-md">
                {{ $t('page.bussiness.addressInformation') }}
              </p>
            </div>
          </div>

          <div class="mt-4 flex flex-col px-5 lg:w-3/4 lg:justify-start">
            <div class="input-form mt-3 w-full lg:mt-0">
              <label
                for="form-address"
                class="form-label flex w-full flex-col sm:flex-row"
              >
                {{ $t('formLabel.address') }}*
              </label>
              <input
                id="form-address"
                v-model.trim="validate.address.$model"
                type="text"
                name="address"
                class="intro-x login__input form-control block py-3 px-4"
                :class="{ 'border-danger': validate.address.$error }"
                :placeholder="$t('formLabel.address')"
              />
              <template v-if="validate.address.$error">
                <div
                  v-for="(error, index) in validate.address.$errors"
                  :key="index"
                  class="mt-2 text-danger"
                >
                  {{ $t(error.$message) }}
                </div>
              </template>
            </div>

            <div class="input-form mt-3 w-full">
              <label
                for="form-addressTwo"
                class="form-label flex w-full flex-col sm:flex-row"
              >
                {{ $t('formLabel.address') }}({{ $t('general.optional') }})
              </label>
              <input
                id="form-addressTwo"
                v-model.trim="validate.addressTwo.$model"
                type="text"
                name="addressTwo"
                class="intro-x login__input form-control block py-3 px-4"
                :class="{ 'border-danger': validate.addressTwo.$error }"
                :placeholder="$t('formLabel.address')"
              />
              <template v-if="validate.addressTwo.$error">
                <div
                  v-for="(error, index) in validate.addressTwo.$errors"
                  :key="index"
                  class="mt-2 text-danger"
                >
                  {{ $t(error.$message) }}
                </div>
              </template>
            </div>
            <div class="flex w-full flex-col lg:flex-row">
              <div class="input-form mt-3 w-full">
                <label
                  for="form-address-province"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.province') }}*
                </label>
                <div class="mt-2">
                  <Multiselect
                    v-model="province"
                    value="province_code"
                    label="province_name"
                    track-by="province_name"
                    :placeholder="$t('formLabel.select.province')"
                    :options="provinceList"
                    :searchable="true"
                    :classes="multiSelectClasses"
                    @change="changeAddress('province')"
                  />
                </div>
              </div>
              <div class="input-form mt-3 w-full lg:ml-4">
                <label
                  for="form-address-city"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.city') }}*
                </label>
                <div class="mt-2">
                  <Multiselect
                    v-model="city"
                    label="city_name"
                    track-by="city_name"
                    :placeholder="$t('formLabel.select.city')"
                    :options="cityList"
                    :searchable="true"
                    :classes="multiSelectClasses"
                    :disabled="cityDisabled === true"
                    @change="changeAddress('city')"
                  />
                </div>
              </div>
            </div>

            <div class="flex w-full flex-col lg:flex-row">
              <div class="input-form mt-3 w-full">
                <label
                  for="form-address-district"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.district') }}*
                </label>
                <div class="mt-2">
                  <Multiselect
                    v-model="district"
                    label="district_name"
                    track-by="district_name"
                    :placeholder="$t('formLabel.select.district')"
                    :options="districtList"
                    :searchable="true"
                    :classes="multiSelectClasses"
                    :disabled="districtDisabled === true"
                    @change="changeAddress('district')"
                  />
                </div>
              </div>
              <div class="input-form mt-3 w-full lg:ml-4">
                <label
                  for="form-address-village"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.village') }}*
                </label>
                <div class="mt-2">
                  <Multiselect
                    v-model="village"
                    label="village_name"
                    track-by="village_name"
                    :placeholder="$t('formLabel.select.village')"
                    :options="villageList"
                    :searchable="true"
                    :classes="multiSelectClasses"
                    :disabled="villageDisabled === true"
                    @change="changeAddress('village')"
                  />
                </div>
              </div>
            </div>

            <div class="flex w-full flex-col lg:flex-row">
              <div class="input-form mt-3 w-full">
                <label
                  for="form-address-postal-code"
                  class="form-label flex w-full flex-col sm:flex-row"
                >
                  {{ $t('formLabel.postalCode') }}*
                </label>
                <div class="mt-2">
                  <input
                    id="form-postalCode"
                    v-model.trim="validate.postalCode.$model"
                    type="number"
                    name="postalCode"
                    class="intro-x login__input form-control block py-3 px-4"
                    :class="{ 'border-danger': validate.postalCode.$error }"
                    :placeholder="$t('formLabel.address')"
                  />
                  <template v-if="validate.postalCode.$error">
                    <div
                      v-for="(error, index) in validate.postalCode.$errors"
                      :key="index"
                      class="mt-2 text-danger"
                    >
                      {{ $t(error.$message) }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="showSocialMedia"
          class="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 dark:border-darkmode-400 lg:flex-row"
        >
          <div
            class="mt-4 border-t border-slate-200/60 px-5 dark:border-darkmode-400 lg:w-1/4 lg:border-t-0"
          >
            <div class="flex items-center truncate sm:whitespace-normal">
              <p class="text-md">{{ $t('page.bussiness.socialMedia') }}</p>
            </div>
          </div>

          <div class="mt-4 flex flex-col px-5 lg:w-3/4 lg:justify-start">
            <div class="flex flex-row">
              <div class="flex">
                <FacebookIcon class="h-10 w-10 text-blue-600" />
              </div>
              <div class="ml-20 flex">
                <button type="submit" class="btn btn-primary py-2 px-4">
                  {{ $t('button.connect') }}
                </button>
              </div>
            </div>

            <div class="mt-6 flex flex-row">
              <div class="flex">
                <InstagramIcon class="h-10 w-10 text-red-600" />
              </div>
              <div class="ml-20 flex">
                <button type="submit" class="btn btn-primary py-2 px-4">
                  {{ $t('button.connect') }}
                </button>
              </div>
            </div>

            <div class="mt-6 flex flex-row">
              <div class="flex">
                <TwitterIcon class="h-10 w-10 text-blue-400" />
              </div>
              <div class="ml-20 flex">
                <button type="submit" class="btn btn-outline-primary py-2 px-4">
                  {{ $t('button.connected') }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="flex w-full justify-end py-5">
          <button type="submit" class="btn btn-primary py-3 px-4">
            <LoadingIcon
              v-if="isLoading"
              icon="spinning-circles"
              color="white"
              class="mr-2 h-4 w-4"
            />
            {{ $t('button.saveChanges') }}
          </button>
        </div>
      </form>

      <div
        id="success-notification-content"
        class="toastify-content flex hidden"
      >
        <CheckCircleIcon class="text-success" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('formInfo.saveChangesSuccess') }}</div>
          <div class="mt-1 text-slate-500">
            {{ responseMessage }}
          </div>
        </div>
      </div>
      <!-- END: Success Notification Content -->
      <!-- BEGIN: Failed Notification Content -->
      <div
        id="failed-notification-content"
        class="toastify-content flex hidden"
      >
        <XCircleIcon class="text-danger" />
        <div class="ml-4 mr-4">
          <div class="font-medium">{{ $t('formInfo.saveChangesFailed') }}</div>
          <div class="mt-1 text-slate-500">{{ responseMessage }}</div>
        </div>
      </div>
    </div>
  </TabGroup>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  url,
  integer,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import Toastify from 'toastify-js'
import globalMixin from '@/mixins/global.js'
import Multiselect from '@vueform/multiselect'

import BussinessMenuIcon from '@/assets/svg/menu/store.svg'
import FacebookIcon from '@/assets/svg/social-media/facebook.svg'
import InstagramIcon from '@/assets/svg/social-media/instagram.svg'
import TwitterIcon from '@/assets/svg/social-media/twitter.svg'

import ImageLoader from '@/components/image-loader/Main.vue'

export default {
  components: {
    Multiselect,
    BussinessMenuIcon,
    ImageLoader,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const avatarPreview = ref('')
    const imageFile = ref(null)

    const isLoading = ref(false)
    const responseMessage = ref('')
    const showSocialMedia = ref(false)

    const provincePage = ref(1)
    const cityPage = ref(1)
    const districtPage = ref(1)
    const villagePage = ref(1)

    const province = ref('')
    const city = ref('')
    const district = ref('')
    const village = ref('')

    const cityDisabled = ref(true)
    const districtDisabled = ref(true)
    const villageDisabled = ref(true)

    const provinceList = ref([])
    const cityList = ref([])
    const districtList = ref([])
    const villageList = ref([])

    const bussinessImage = ref('')

    const formData = reactive({
      bussinessName: '',
      email: '',
      description: '',
      phone: '',
      website: '',
      address: '',
      addressTwo: '',
      postalCode: '',
    })

    const bussinessNameRequired = helpers.withMessage(
      'formError.bussinessName.required',
      required
    )
    const emailRequired = helpers.withMessage(
      'formError.email.required',
      required
    )
    const emailValid = helpers.withMessage('formError.email.validEmail', email)
    const phoneRequired = helpers.withMessage(
      'formError.phone.required',
      required
    )
    const addressRequired = helpers.withMessage(
      'formError.address.required',
      required
    )
    const postalCodeRequired = helpers.withMessage(
      'formError.postalCode.required',
      required
    )

    const rules = {
      bussinessName: {
        bussinessNameRequired,
      },
      email: {
        emailRequired,
        emailValid,
      },
      phone: {
        phoneRequired,
      },
      description: {},
      website: {},
      address: {
        addressRequired,
      },
      addressTwo: {},
      postalCode: {
        postalCodeRequired,
      },
    }

    const validate = useVuelidate(rules, toRefs(formData))

    onMounted(async () => {
      await getUserProfile()
      // await getInstituteData()
      await getAddressProvince()
    })

    const getAddressProvince = async () => {
      const queryParameters = {
        order: 'DESC',
        page: provincePage.value,
        take: 20,
        q: '',
      }
      await store
        .dispatch('address/province', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const provinceTemporary = []
            response.data.data.forEach((item) => {
              provinceTemporary.push({
                value: item.province_code,
                ...item,
              })
            })
            provinceList.value = [...provinceList.value, ...provinceTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('province')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressCity = async () => {
      const queryParameters = {
        order: 'DESC',
        page: cityPage.value,
        take: 50,
        q: '',
        province_code: province.value,
      }
      await store
        .dispatch('address/city', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const cityTemporary = []
            response.data.data.forEach((item) => {
              cityTemporary.push({
                value: item.city_code,
                ...item,
              })
            })
            if (cityPage.value === 1) {
              cityList.value = []
              cityList.value = [...cityTemporary]
            } else {
              cityList.value = [...cityList.value, ...cityTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('city')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressDistrict = async () => {
      const queryParameters = {
        order: 'DESC',
        page: 1,
        take: 50,
        q: '',
        city_code: city.value,
      }
      await store
        .dispatch('address/district', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const districtTemporary = []
            response.data.data.forEach((item) => {
              districtTemporary.push({
                value: item.district_code,
                ...item,
              })
            })
            if (districtPage.value === 1) {
              districtList.value = []
              districtList.value = [...districtTemporary]
            } else {
              districtList.value = [...districtList.value, ...districtTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('district')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressVillage = async () => {
      const queryParameters = {
        order: 'DESC',
        page: 1,
        take: 50,
        q: '',
        district_code: district.value,
      }
      await store
        .dispatch('address/village', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const villageTemporary = []
            response.data.data.forEach((item) => {
              villageTemporary.push({
                value: item.village_code,
                ...item,
              })
            })
            if (villagePage.value === 1) {
              villageList.value = []
              villageList.value = [...villageTemporary]
            } else {
              villageList.value = [...villageList.value, ...villageTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('village')
            }
          }
        })
        .catch((error) => {})
    }

    const loadMores = async (type) => {
      if (type === 'province') {
        provincePage.value += 1
        getAddressProvince()
      } else if (type === 'city') {
        cityPage.value += 1
        getAddressCity()
      } else if (type === 'district') {
        districtPage.value += 1
        getAddressDistrict()
      } else if (type === 'village') {
        villagePage.value += 1
        getAddressVillage()
      }
    }

    const changeAddress = async (type) => {
      setTimeout(() => {
        if (type === 'province') {
          cityPage.value = 1
          city.value = ''
          cityList.value = []
          cityDisabled.value = false

          district.value = ''
          districtList.value = []
          districtDisabled.value = true

          village.value = ''
          villageList.value = []
          villageDisabled.value = true

          if (province.value !== '') {
            getAddressCity()
          }
        } else if (type === 'city') {
          districtPage.value = 1
          district.value = ''
          districtList.value = []
          districtDisabled.value = false

          village.value = ''
          villageList.value = []
          villageDisabled.value = true

          if (city.value !== '') {
            getAddressDistrict()
          }
        } else if (type === 'district') {
          villagePage.value = 1
          village.value = ''
          villageList.value = []
          villageDisabled.value = false

          if (district.value !== '') {
            getAddressVillage()
          }
        }
      }, 200)
    }

    const getUserProfile = async () => {
      await store
        .dispatch('user/profile')
        .then((response) => {
          const userData = response.data
          if (userData.tenant !== undefined) {
            bussinessImage.value = userData.tenant.image
            formData.bussinessName = userData.tenant.name
            formData.description = userData.tenant.description
            formData.email = userData.tenant.email
            formData.phone = userData.tenant.phone_number
          } else {
            formData.bussinessName = userData.institute.name
            formData.address = userData.institute.address
            formData.email = userData.institute.email
            formData.phone = userData.institute.phone_number
            formData.description = userData.institute.description
            formData.phone = userData.institute.phone
            formData.website = userData.institute.website
          }
        })
        .catch((error) => {})
    }

    // const getInstituteData = async () => {
    //   await store
    //     .dispatch('institute/getAll', {})
    //     .then((response) => {
    //       console.log(response)
    //       // const userData = response.data
    //       // if (userData.tenant !== undefined) {
    //       //   bussinessImage.value = userData.tenant.image
    //       //   formData.bussinessName = userData.tenant.name
    //       //   formData.description = userData.tenant.description
    //       //   formData.email = userData.tenant.email
    //       //   formData.phone = userData.tenant.phone_number
    //       // } else {
    //       //   formData.bussinessName = userData.institute.name
    //       //   formData.address = userData.institute.address
    //       //   formData.email = userData.institute.email
    //       //   formData.phone = userData.institute.phone_number
    //       // }
    //     })
    //     .catch((error) => {})
    // }

    const updateBussiness = async () => {
      validate.value.$touch()
      if (validate.value.$invalid == false) {
        // isLoading.value = true
        // const payload = {
        //   email: formData.email,
        //   password: formData.password,
        // }
        // await store
        //   .dispatch('user/update', payload)
        //   .then((response) => {
        // if (response.statusCode === 200) {
        //   const message = response.message
        //   responseMessage.value = message
        //   // show toast
        //   setTimeout(() => {
        //     showToast({
        //       success: true,
        //     })
        //   }, 200)
        //   setTimeout(() => {
        //     router.push({
        //       name: 'dashboard',
        //       params: { slug: route.params.slug },
        //       query: { lang: route.query.lang },
        //     })
        //   }, 2000)
        // } else {
        //   const message =
        //     response.message.detail || error.response.data.message
        //   responseMessage.value = message
        //   // show toast
        //   setTimeout(() => {
        //     showToast()
        //   }, 200)
        // }
        // isLoading.value = false
        //   })
        //   .catch((error) => {
        // isLoading.value = false
        // const statusCode = error.response.data.statusCode
        // const message =
        //   error.response.data.message.detail || error.response.data.message
        // responseMessage.value = message
        // // show toast
        // setTimeout(() => {
        //   showToast(false)
        // }, 200)
        //   })
      }
    }

    const showToast = (success) => {
      let toastId = '#failed-notification-content'
      if (success) {
        toastId = '#success-notification-content'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const previewImage = (file) => {
      // no need resize
      if (file.size < 1024) {
        avatarPreview.value = window.URL.createObjectURL(file)
        imageFile.value = file
      }
      const targetWidth = 1000
      resizeImg(file, targetWidth, (resized) => {
        avatarPreview.value = window.URL.createObjectURL(resized)
        imageFile.value = resized
      })
    }

    const selectFile = () => {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
      hidden.onchange = (e) => {
        previewImage(e.target.files[0])
      }
    }

    return {
      t,
      n,
      store,
      route,
      router,
      isLoading,
      bussinessImage,
      formData,
      validate,
      responseMessage,
      updateBussiness,
      avatarPreview,
      selectFile,
      province,
      city,
      district,
      village,
      cityDisabled,
      districtDisabled,
      villageDisabled,
      changeAddress,
      provinceList,
      cityList,
      districtList,
      villageList,
      showSocialMedia,
    }
  },
  mounted() {
    this.activeUser = this.$store.getters['auth/activeUser']
  },
}
</script>

import axios, { routePathAPI } from '@/plugins/axios.js'
import axiosSCM, { routePathAPISCM } from '@/plugins/axios-scm.js'
import axiosGeneralPayment, {
  routePathAPIGeneralPayment,
} from '@/plugins/axios-general-payment.js'
import router from '@/router'

// initial state
const state = () => ({
  stockPurchaseCart: [],
  selectedAddress: null,
})

// getters
const getters = {
  stockPurchaseCart(state) {
    if (state.stockPurchaseCart.length > 0) {
      return state.stockPurchaseCart
    } else {
      return localStorage.getItem('inkanteenDashboard.stockPurchaseCart') !==
        null
        ? JSON.parse(
            localStorage.getItem('inkanteenDashboard.stockPurchaseCart')
          )
        : []
    }
  },
  selectedAddress(state) {
    if (state.selectedAddress !== null) {
      return state.selectedAddress
    } else {
      return localStorage.getItem('inkanteenDashboard.selectedAddress') || null
    }
  },
}

// actions
const actions = {
  setStockPurchaseCart({ commit, state }, stockPurchaseCart) {
    return commit('SET_STOCK_PURCHASE_CART', stockPurchaseCart)
  },
  resetStockPurchaseCart({ commit, state }) {
    return commit('REMOVE_STOCK_PURCHASE_CART')
  },
  setSelectedAddress({ commit, state }, selectedAddress) {
    return commit('SET_SELECTED_ADDRESS', selectedAddress)
  },
  resetSelectedAddress({ commit, state }) {
    return commit('REMOVE_SELECTED_ADDRESS')
  },

  // BEGIN: PRODUCT SUPPLIER
  getAllProductSupplier({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      if (payload.supplier_id) params.supplier_id = payload.supplier_id
      if (payload.q) params.q = payload.q

      axiosSCM
        .get(`${routePathAPISCM()}/product-supplier`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetailProductSupplierStock({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .get(`${routePathAPISCM()}/product-supplier/stock/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetailProductSupplier({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .get(`${routePathAPISCM()}/product-supplier/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // END: PRODUCT SUPPLIER

  // BEGIN: SUPPLIER
  getAllSupplier({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      if (payload.q) params.q = payload.q

      axiosSCM
        .get(`${routePathAPISCM()}/supplier`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetailSupplier({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .get(`${routePathAPISCM()}/supplier/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // END: SUPPLIER

  // BEGIN: STOCK PURCHASE
  createStockPurchaseRequest({ commit }, payload) {
    const config = {
      headers: {},
    }

    return new Promise((resolve, reject) => {
      const params = {}
      params.tenant_id = payload.tenant_id
      params.address_id = payload.address_id
      params.items = payload.items

      axiosSCM
        .post(
          `${routePathAPISCM()}/purchase-ingredient/request`,
          params,
          config
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 201 || response.status === 200) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createStockPurchase({ commit }, payload) {
    const config = {
      headers: {},
    }

    return new Promise((resolve, reject) => {
      const params = {}
      params.tenant_id = payload.tenant_id
      params.address_id = payload.address_id
      params.payment_type = payload.payment_type
      params.payment_channel_code = payload.payment_channel_code
      params.payment_channel_id = payload.payment_channel_id
      params.client_reference_channel_id = payload.client_reference_channel_id
      params.phone_number = payload.phone_number
      params.items = payload.items

      axiosSCM
        .post(`${routePathAPISCM()}/purchase-ingredient/create`, params, config)
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 201 || response.status === 200) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  approveStockPurchase({ commit }, payload) {
    const config = {
      headers: {},
    }

    return new Promise((resolve, reject) => {
      const params = {}
      params.payment_type = payload.payment_type
      params.payment_channel_code = payload.payment_channel_code
      params.payment_channel_id = payload.payment_channel_id
      params.client_reference_channel_id = payload.client_reference_channel_id
      params.phone_number = payload.phone_number

      axiosSCM
        .post(
          `${routePathAPISCM()}/purchase-ingredient/approve/${payload.id}`,
          params,
          config
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 201 || response.status === 200) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  adjustStockPurchase({ commit }, payload) {
    const config = {
      headers: {},
    }

    return new Promise((resolve, reject) => {
      const params = {}
      params.items = payload.items

      axiosSCM
        .post(
          `${routePathAPISCM()}/purchase-ingredient/adjust/${payload.id}`,
          params,
          config
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 201 || response.status === 200) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllPurchaseOrder({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q
      if (payload.status) params.status = payload.status
      if (payload.start_date) params.start_date = payload.start_date
      if (payload.end_date) params.end_date = payload.end_date
      if (payload.supplier_id) params.supplier_id = payload.supplier_id
      if (payload.tenant_id) {
        params.institute_id = undefined
        params.tenant_id = payload.tenant_id
      }

      axiosSCM
        .get(`${routePathAPISCM()}/purchase-ingredient`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetailPurchaseOrder({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .get(`${routePathAPISCM()}/purchase-ingredient/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancelPurchaseOrder({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .post(
          `${routePathAPISCM()}/purchase-ingredient/cancel/${payload.id}`,
          payload,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  rejectPurchaseOrder({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .post(
          `${routePathAPISCM()}/purchase-ingredient/reject/${payload.id}`,
          payload,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  converStockPurchaseOrder({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      params.item_id = payload.item_id
      params.quantity = payload.quantity
      params.ingredient_id = payload.ingredient_id

      axiosSCM
        .post(
          `${routePathAPISCM()}/purchase-ingredient/cancel/${payload.id}`,
          params,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadPurchaseOrderProofPayment({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'x-language-code': currentLanguageCode,
      },
    }

    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('file', payload.file)

      axiosSCM
        .post(
          `${routePathAPISCM()}/purchase-ingredient/upload/${payload.id}`,
          formData,
          config
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // END: STOCK PURCHASE

  // BEGIN: STOCK PURCHASE SUPPLIER
  getAllPurchaseSupplier({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q
      if (payload.status) params.status = payload.status
      if (payload.start_date) params.start_date = payload.start_date
      if (payload.end_date) params.end_date = payload.end_date
      if (payload.supplier_id) params.supplier_id = payload.supplier_id
      if (payload.tenant_id) params.tenant_id = payload.tenant_id

      axiosSCM
        .get(`${routePathAPISCM()}/purchase-supplier`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetailPurchaseSupplier({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .get(`${routePathAPISCM()}/purchase-supplier/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  receivePurchaseOrderSupplier({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .post(
          `${routePathAPISCM()}/purchase-supplier/receive/${payload.id}`,
          payload,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  cancelPurchaseOrderSupplier({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .post(
          `${routePathAPISCM()}/purchase-supplier/cancel/${payload.id}`,
          payload,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // END: STOCK PURCHASE SUPPLIER

  // BEGIN: USER ADDRESS
  getAllUserAddress({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q

      axios
        .get(`${routePathAPI()}/user-address`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetailUserAddress({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/user-address/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createUserAddress({ commit }, payload) {
    const config = {
      headers: {},
    }

    return new Promise((resolve, reject) => {
      const params = {}
      params.label = payload.label
      params.is_primary = payload.is_primary
      params.recipient = payload.recipient
      params.phone = payload.phone
      params.province_id = payload.province_id
      params.city_id = payload.city_id
      params.district_id = payload.district_id
      params.village_id = payload.village_id
      params.postal_code = payload.postal_code
      params.detail_address = payload.detail_address

      axios
        .post(`${routePathAPI()}/user-address/create`, params, config)
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 201 || response.status === 200) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUserAddress({ commit }, payload) {
    const config = {
      headers: {},
    }

    return new Promise((resolve, reject) => {
      const params = {}
      params.label = payload.label
      params.is_primary = payload.is_primary
      params.recipient = payload.recipient
      params.phone = payload.phone
      params.province_id = payload.province_id
      params.city_id = payload.city_id
      params.district_id = payload.district_id
      params.village_id = payload.village_id
      params.postal_code = payload.postal_code
      params.detail_address = payload.detail_address

      axios
        .put(
          `${routePathAPI()}/user-address/update/${payload.id}`,
          params,
          config
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 201 || response.status === 200) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteUserAddress({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPI()}/user-address/delete/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          let message = ''
          if (error.response.status === 500) message = error.message
          else message = error.response.data.message.detail
          reject({
            status: error.response.status,
            message,
            data: error.response.data,
          })
        })
    })
  },
  // END: USER ADDRESS

  // BEGIN: SETTING
  getTransactionFee({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .get(`${routePathAPISCM()}/purchase-ingredient/transaction-fee`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getShippingFee({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosSCM
        .get(`${routePathAPISCM()}/purchase-ingredient/shipping-fee`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // END: SETTING

  getAllPaymentChannel({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.client_reference_code)
        params.client_reference_code = payload.client_reference_code
      // params.is_active = payload.is_active === true ? true : false
      params.is_cashier_payment = payload.is_cashier_payment ? true : false
      if (payload.payment_channel_id)
        params.payment_channel_id = payload.payment_channel_id
      if (payload.payment_channel_name)
        params.payment_channel_name = payload.payment_channel_name

      axiosGeneralPayment
        .get(`${routePathAPIGeneralPayment()}/payment-channels/scm-payment`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_STOCK_PURCHASE_CART(state, stockPurchaseCart) {
    state.stockPurchaseCart = stockPurchaseCart

    localStorage.setItem(
      'inkanteenDashboard.stockPurchaseCart',
      JSON.stringify(stockPurchaseCart)
    )
  },
  REMOVE_STOCK_PURCHASE_CART(state) {
    state.stockPurchaseCart = []

    localStorage.removeItem('inkanteenDashboard.stockPurchaseCart')
  },
  SET_SELECTED_ADDRESS(state, selectedAddress) {
    state.selectedAddress = selectedAddress

    localStorage.setItem('inkanteenDashboard.selectedAddress', selectedAddress)
  },
  REMOVE_SELECTED_ADDRESS(state) {
    state.selectedAddress = null

    localStorage.removeItem('inkanteenDashboard.selectedAddress')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Super Institute Control -->
        <div class="col-span-12 mt-8 grid grid-cols-12 gap-6">
          <div
            class="intro-y col-span-12 flex flex-row items-center justify-between gap-4 rounded-lg bg-white p-4 lg:col-span-8"
          >
            <div class="flex flex-col gap-4 lg:flex-row lg:gap-16">
              <div class="flex flex-col gap-3">
                <span class="text-xs">
                  {{ $t('page.dashboardSuperInstitute.openTenant') }}
                </span>
                <div class="flex items-center gap-1 font-bold text-black">
                  <p>
                    {{
                      $t('page.dashboardSuperInstitute.totalTenant', {
                        total: overviewData.tenant_opened_total,
                      })
                    }}
                  </p>
                  <DotComponent color-class="bg-green-600" />
                </div>
              </div>
              <div class="flex flex-col gap-3">
                <span class="text-xs">
                  {{ $t('page.dashboardSuperInstitute.closeTenant') }}
                </span>
                <div class="flex items-center gap-1 font-bold text-gray-400">
                  <p>
                    {{
                      $t('page.dashboardSuperInstitute.totalTenant', {
                        total: overviewData.tenant_closed_total,
                      })
                    }}
                  </p>
                  <DotComponent color-class="bg-gray-400" />
                </div>
              </div>
              <div v-if="false" class="flex flex-col gap-3">
                <span class="text-xs">
                  {{ $t('page.dashboardSuperInstitute.openBills') }}
                </span>
                <span class="font-bold text-green-600">
                  {{
                    $t('page.dashboardSuperInstitute.totalOrder', {
                      total: overviewData.active_openbill_total,
                    })
                  }}
                </span>
              </div>
            </div>
            <div class="flex justify-end">
              <ButtonComponent
                wrapper-class="border bg-white border-black dark:border-black text-gray-600 font-bold px-6 hover:bg-black hover:text-gray-200 transition dark:bg-white dark:text-black"
                :title="$t('page.dashboardSuperInstitute.seeTenant')"
                @click="showModalTenant = true"
              />
            </div>
          </div>

          <div
            class="intro-y col-span-12 flex flex-row items-center justify-between gap-4 rounded-lg bg-white p-4 lg:col-span-4"
          >
            <div class="flex flex-col gap-3">
              <p class="text-xs">
                {{ $t('page.dashboardSuperInstitute.statusFoodCourt') }}
              </p>
              <div
                class="flex items-center gap-1 font-bold"
                :class="isFoodcourtActive ? 'text-primary' : 'text-gray-500'"
              >
                <p v-if="isFoodcourtActive">{{ $t('info.open') }}</p>
                <p v-else>
                  {{ $t('info.closed') }}
                </p>
                <DotComponent
                  :color-class="
                    isFoodcourtActive ? 'bg-primary' : 'bg-gray-500'
                  "
                />
              </div>
            </div>
            <div class="form-switch">
              <input
                v-model="isFoodcourtActive"
                type="checkbox"
                class="form-check-input"
              />
            </div>
          </div>
        </div>
        <!-- END: Super Institute Control -->

        <!-- BEGIN: Performance Summary -->
        <div class="col-span-12 mt-8">
          <div
            class="intro-y flex w-full flex-col justify-between space-y-2 lg:flex-row lg:space-y-0"
          >
            <div class="flex justify-between align-middle">
              <div class="flex">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <div class="flex self-center align-middle">
                  <h2 class="mr-5 truncate text-lg font-medium">
                    {{ $t('page.dashboardSuperInstitute.performanceSummary') }}
                  </h2>
                </div>
              </div>
              <div class="flex">
                <div class="flex self-center align-middle">
                  <p class="text-sm">
                    {{ dateFilterFormat(dateFilter) }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-end gap-4">
              <div class="flex w-full lg:w-fit">
                <div
                  class="relative w-full text-slate-500 sm:ml-auto sm:mt-0 lg:w-fit"
                >
                  <CalendarIcon
                    class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
                  />
                  <Litepicker
                    v-model="dateFilter"
                    :options="{
                      autoApply: false,
                      singleMode: false,
                      numberOfColumns: 2,
                      numberOfMonths: 2,
                      showWeekNumbers: true,
                      format: 'YYYY-MM-DD',
                      dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                      },
                    }"
                    class="form-control box pl-8 lg:w-56"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-8 grid grid-cols-12 gap-6 lg:mt-5 lg:gap-3">
            <Card
              :title="$t('page.dashboardSuperInstitute.totalSales')"
              :is-currency="true"
              :is-up="cardData.sales_total.is_up"
              :percentage="cardData.sales_total.percent"
              :show-percentage="true"
              :total="String(cardData.sales_total.total)"
              wrapper-class="col-span-12 lg:col-span-3"
            />
            <Card
              :title="$t('page.dashboardSuperInstitute.totalTransaction')"
              :is-currency="false"
              :is-up="cardData.transaction_total.is_up"
              :percentage="cardData.transaction_total.percent"
              :show-percentage="true"
              :total="String(cardData.transaction_total.total)"
              wrapper-class="col-span-12 lg:col-span-3"
            />
            <Card
              :title="$t('page.dashboardSuperInstitute.newCustomer')"
              :is-currency="false"
              :is-up="cardData.new_customer_total.is_up"
              :percentage="cardData.new_customer_total.percent"
              :show-percentage="true"
              :total="String(cardData.new_customer_total.total)"
              wrapper-class="col-span-12 lg:col-span-3"
            />
            <Card
              :title="$t('page.dashboardSuperInstitute.repeatOrder')"
              :is-currency="false"
              :is-up="cardData.repeat_order_total.is_up"
              :percentage="cardData.repeat_order_total.percent"
              :show-percentage="true"
              :total="String(cardData.repeat_order_total.total)"
              wrapper-class="col-span-12 lg:col-span-3"
            />
          </div>
        </div>
        <!-- END: Performance Summary -->

        <!-- BEGIN: Trending -->
        <div class="col-span-12 mt-0 lg:col-span-12 lg:mt-4">
          <div
            class="intro-y box mt-5 flex flex-col gap-20 p-5 lg:grid lg:grid-cols-12"
          >
            <!-- BEGIN: Trending Menu -->
            <div class="col-span-12 flex flex-col lg:col-span-7">
              <div
                class="relative flex w-full flex-row justify-between space-y-1 lg:space-y-0"
              >
                <div class="flex items-center gap-2">
                  <TrollyIcon class="h-4 w-4 text-black dark:text-white" />
                  <div class="flex align-middle lg:self-center">
                    <p class="mr-5 truncate text-base lg:text-lg">
                      {{ $t('page.dashboardSuperInstitute.trendingMenu') }}
                    </p>
                  </div>

                  <div class="flex self-center text-xs">
                    <p class="text-xs text-gray-500">
                      {{ dateFilterFormat(dateFilter) }}
                    </p>
                  </div>
                </div>

                <div
                  class="flex cursor-pointer items-start justify-end lg:items-center"
                >
                  <Tippy
                    tag="div"
                    class="report-box__indicator cursor-pointer"
                    :content="getContent('trending')"
                  >
                    <InfoIcon class="h-4 w-4 text-gray-500" />
                  </Tippy>
                </div>
              </div>
              <div
                v-if="!trendingMenuChartEmpty"
                class="flex w-full flex-col gap-8 lg:mt-6 lg:flex-row lg:justify-between lg:gap-4"
              >
                <div class="flex items-center justify-center lg:items-start">
                  <ReportPieChart
                    :label="trendingMenuChartLabel"
                    :data="trendingMenuChartData"
                    :height="275"
                    :chart-colors="trendingMenuChartColors"
                    type="doughnut"
                    class="mt-6 -mb-6"
                  />
                </div>
                <div class="flex w-full flex-col gap-6 lg:gap-8">
                  <div
                    v-for="(trending, key) in trendingMenus.filter((_, key) =>
                      trendingMenuPage === 1
                        ? key >= 0 && key < 5
                        : key >= 5 && key < 10
                    )"
                    :key="key"
                    class="flex w-full justify-between gap-2"
                  >
                    <div class="flex max-w-1/2 items-center gap-1">
                      <div
                        class="rounded-full px-2"
                        :style="
                          key in trendingMenuChartColors
                            ? `background-color:${trendingMenuChartColors[key]}`
                            : `background-color: #ddd`
                        "
                      >
                        &nbsp;
                      </div>

                      <div class="truncate">{{ trending.name }}</div>
                    </div>
                    <div class="max-w-1/2 truncate">
                      {{ trending.description || '-' }}
                    </div>
                  </div>
                  <div
                    v-if="trendingMenus.length > 5 && trendingMenuTotalPage > 1"
                    class="flex justify-center gap-2"
                  >
                    <div class="flex gap-1">
                      <p>{{ $t('info.page') }}</p>
                      <p>{{ trendingMenuPage }}</p>
                      <p>/</p>
                      <p>{{ trendingMenuTotalPage }}</p>
                    </div>
                    <div
                      class="flex items-center rounded-full border"
                      :class="
                        trendingMenuPage >= trendingMenuTotalPage
                          ? 'cursor-pointer border-black text-black'
                          : 'cursor-not-allowed border-gray-200 text-gray-200'
                      "
                      @click="changePageTrendingMenu('up')"
                    >
                      <ChevronUpIcon class="h-4 w-4" />
                    </div>
                    <div
                      class="flex items-center rounded-full border"
                      :class="
                        trendingMenuPage < trendingMenuTotalPage
                          ? 'cursor-pointer border-black text-black'
                          : 'cursor-not-allowed border-gray-200 text-gray-200'
                      "
                      @click="changePageTrendingMenu('down')"
                    >
                      <ChevronDownIcon class="h-4 w-4" />
                    </div>
                  </div>
                </div>
              </div>
              <NoData v-else />
            </div>
            <!-- END: Trending Menu -->

            <!-- BEGIN: Top tenant -->
            <div class="col-span-12 lg:col-span-5">
              <div
                class="flex w-full flex-row justify-between space-y-1 lg:space-y-0"
              >
                <div class="flex items-center gap-2">
                  <TrollyIcon class="h-4 w-4 text-black dark:text-white" />
                  <div class="flex align-middle lg:self-center">
                    <p class="mr-5 truncate text-base lg:text-lg">
                      {{ $t('page.dashboardSuperInstitute.topTenant') }}
                    </p>
                  </div>

                  <div class="flex self-center text-xs text-gray-500">
                    {{ dateFilterFormat(dateFilter) }}
                  </div>
                </div>
                <div
                  class="flex cursor-pointer items-start justify-end lg:items-center"
                >
                  <Tippy
                    tag="div"
                    class="report-box__indicator cursor-pointer"
                    :content="getContent('topTenant')"
                  >
                    <InfoIcon class="h-4 w-4 text-gray-500" />
                  </Tippy>
                </div>
              </div>
              <div
                class="flex h-full flex-col"
                :class="
                  topTenantData.length > 0 ? 'mt-4 gap-4' : 'items-center'
                "
              >
                <template v-if="topTenantData.length > 0">
                  <div
                    v-for="(tenant, key) in topTenantData"
                    :key="key"
                    class="flex justify-between"
                  >
                    <p class="max-w-1/2 truncate">{{ tenant.name }}</p>
                    <p class="max-w-1/2 truncate">{{ tenant.total }} Trx</p>
                  </div>
                </template>
                <NoData v-else wrapper-class="pt-0 pb-0 items-center h-full" />
              </div>
            </div>
            <!-- END: Top tenant -->
          </div>
        </div>
        <!-- END: Trending -->

        <!-- BEGIN: Peak Hour Chart -->
        <div class="col-span-12 mt-0 lg:col-span-6 lg:mt-4">
          <div class="intro-y box mt-5 p-5">
            <div
              class="flex w-full flex-row justify-between space-y-1 lg:space-y-0"
            >
              <div class="flex items-center gap-2">
                <TrollyIcon class="h-4 w-4 text-black dark:text-white" />
                <div class="flex align-middle lg:self-center">
                  <p class="mr-5 truncate text-base lg:text-lg">
                    {{ $t('page.dashboardSuperInstitute.peakHour') }}
                  </p>
                </div>

                <div class="flex self-center text-xs">
                  <p class="text-xs text-gray-500">
                    {{ dateFilterFormat(dateFilter) }}
                  </p>
                </div>
              </div>
              <div
                class="flex cursor-pointer items-start justify-end lg:items-center"
              >
                <Tippy
                  tag="div"
                  class="report-box__indicator cursor-pointer"
                  :content="getContent('peakHour')"
                >
                  <InfoIcon class="h-4 w-4 text-gray-500" />
                </Tippy>
              </div>
            </div>

            <div v-if="!chart_peak_hour_empty">
              <ReportLineChart
                :height="275"
                item-name="Total Transaction"
                :label="chart_peak_hour_label"
                :data="chart_peak_hour_values"
                class="mt-6 -mb-6"
              />
            </div>
            <NoData v-else />
          </div>
        </div>
        <!-- END: Peak Hour Chart -->
        <!-- BEGIN: Day of Week Sales Chart -->
        <div class="col-span-12 mt-0 lg:col-span-6 lg:mt-4">
          <div class="intro-y box mt-5 p-5">
            <div
              class="flex w-full flex-col justify-between space-y-1 lg:flex-row lg:space-y-0"
            >
              <div class="flex items-center gap-2">
                <TrollyIcon class="h-4 w-4 text-black dark:text-white" />
                <div class="flex align-middle lg:self-center">
                  <p class="mr-5 truncate text-base lg:text-lg">
                    {{ $t('page.dashboardSuperInstitute.daySales') }}
                  </p>
                </div>

                <div class="flex self-center text-xs">
                  <p class="text-xs uppercase text-gray-500">
                    {{ periodicFilter }}
                  </p>
                </div>
              </div>
              <div class="flex items-center justify-end gap-2 lg:justify-start">
                <div class="col-span-12 items-end">
                  <div class="flex w-full justify-center">
                    <FilterTimeRange
                      class="w-full lg:w-fit"
                      :active="periodicFilter"
                      :options="[
                        'daily',
                        'yesterday',
                        'weekly',
                        'last_week',
                        'monthly',
                        'last_month',
                        'quarterly',
                        'last_quarter',
                        'yearly',
                        'last_year',
                      ]"
                      @change-filter="changeFilterTimeRange($event)"
                    />
                  </div>
                </div>
                <div
                  class="flex cursor-pointer items-start justify-end lg:items-center"
                >
                  <Tippy
                    tag="div"
                    class="report-box__indicator cursor-pointer"
                    :content="getContent('daySales')"
                  >
                    <InfoIcon class="h-4 w-4 text-gray-500" />
                  </Tippy>
                </div>
              </div>
            </div>
            <div v-if="!chart_sales_empty">
              <ReportLineChart
                :height="275"
                item-name="Total Sales"
                :label="chart_sales_label"
                :data="chart_sales_values"
                chart-type="bar"
                class="mt-6 -mb-6"
              />
            </div>
            <NoData v-else />
          </div>
        </div>
        <!-- END: Day of Week Sales Chart -->

        <!-- START: Modal Tenant -->
        <Modal
          :show="showModalTenant"
          size="modal-lg"
          @hidden="closeModalTenant"
        >
          <ModalBody class="p-4">
            <div class="flex flex-col gap-4 px-2">
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-1">
                  <PackageIcon />
                  <p class="font-bold">{{ $t('formLabel.select.tenant') }}</p>
                </div>
                <div
                  class="cursor-pointer text-xl hover:text-gray-500"
                  @click="closeModalTenant"
                >
                  &times;
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <div class="flex rounded-lg border">
                  <button
                    class="text-blue hover:text-blue-light flex w-auto items-center justify-end p-2"
                  >
                    <SearchIcon />
                  </button>
                  <input
                    v-model="searchTenant"
                    class="w-full rounded-lg border-none p-2 outline-none focus:outline-none focus:ring-0"
                    type="text"
                    placeholder="Cari Tenant"
                  />
                </div>
                <div
                  v-for="(tenant, key) in filteredTenantData"
                  :key="key"
                  class="flex items-center justify-between py-2 px-1"
                  :class="true ? '' : 'bg-gray-100 text-gray-400'"
                >
                  <div class="flex w-2/3 items-center gap-1 lg:w-3/4">
                    <ImageLoader
                      height="h-12"
                      :image="tenant.image !== '' ? tenant.image : null"
                      :alt="tenant.name"
                    />
                    <p class="max-w-1/2 truncate">{{ tenant.name }}</p>
                    <DotComponent
                      :color-class="
                        tenant.is_open ? 'bg-green-500' : 'bg-gray-500'
                      "
                    />
                  </div>
                  <div
                    class="flex w-1/3 items-center justify-center gap-1 rounded-lg border p-2 lg:w-1/4"
                    :class="
                      true
                        ? 'cursor-pointer hover:bg-gray-100'
                        : 'cursor-not-allowed bg-gray-100 text-gray-400'
                    "
                    @click="toDashboardTenant(tenant)"
                  >
                    <AnalyticIcon class="w-4" />
                    <p class="text-xs">{{ $t('menu.dashboard') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <!-- END: Modal Tenant -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->
      </div>
    </div>
    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import { ref, onMounted } from 'vue'
import { helper as $h } from '@/utils/helper'

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { colors } from '@/utils/colors'

import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'

import Card from '@/components/Card.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import DotComponent from '@/components/DotComponent.vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import NoData from '@/components/NoData.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import FilterTimeRange from '@/components/FilterTimeRange.vue'

import SaleIcon from '@/assets/svg/sale.svg'
import TrollyIcon from '@/assets/svg/trolly.svg'
import AnalyticIcon from '@/assets/svg/menu/analytic.svg'

export default {
  name: 'SuperInstituteDashboardPage',
  components: {
    Card,
    ButtonComponent,
    DotComponent,
    ReportLineChart,
    ReportPieChart,
    NoData,
    ImageLoader,
    FilterTimeRange,
    SaleIcon,
    TrollyIcon,
    AnalyticIcon,
  },
  mixins: [globalMixin, tenantMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const format = 'DD MMMM YYYY'
    const dateFilter = ref('')
    const periodicFilter = ref('daily')
    const dateToday = ref('')

    onMounted(() => {
      const lang = route.query.lang
      if (lang === 'en') {
        mo
        dateToday.value = $h.formatDate(new Date(), format, 'en')
      } else {
        dateToday.value = $h.formatDate(new Date(), format, 'id')
      }
    })

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
      periodicFilter,
      dateToday,
    }
  },
  data: () => ({
    isFoodcourtActive: true,
    showModalTenant: false,
    cardData: {
      transaction_total: {
        total: 0,
        percent: '0%',
        is_up: true,
      },
      sales_total: {
        total: 0,
        percent: '0%',
        is_up: true,
      },
      new_customer_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      repeat_order_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
    },
    searchTenant: '',

    trendingMenuPage: 1,
    trendingMenuTotalPage: 2,
    trendingMenuChartColors: [
      colors.danger(0.9),
      colors.success(0.3),
      colors.warning(0.9),
      colors.pending(0.3),
      colors.pending(0.9),
      colors.warning(0.3),
      colors.success(0.9),
      colors.primary(0.3),
      colors.primary(0.9),
      colors.danger(0.3),
    ],
    trendingMenus: [],
    trendingMenuChartLabel: [],
    trendingMenuChartData: [],
    trendingMenuChartEmpty: false,

    overviewData: {
      active_openbill_total: 0,
      tenant_closed_total: 0,
      tenant_opened_total: 0,
    },

    topTenantData: [],
    filteredTenantData: [],

    activeTenant: '',

    chart_sales_empty: [],
    chart_sales_label: [],
    chart_sales_values: [],
    chart_peak_hour_empty: [],
    chart_peak_hour_label: [],
    chart_peak_hour_values: [],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      if (this.activeUserStore !== null) {
        if (this.isInstituteSuperAdmin && 'institute' in this.activeUserStore) {
          this.getReportSummary()

          this.getTenantChart()
          this.getProductChart()

          this.getPeakHourChart()
        } else {
          this.toLoginPage()
        }
      } else {
        this.toLoginPage()
      }
    },
    searchTenant: debounce(function (search) {
      if (search === '') {
        this.filteredTenantData = this.tenantData
      } else {
        this.filteredTenantData = this.tenantData.filter((item) =>
          item.name.toUpperCase().includes(search.toUpperCase())
        )
      }
    }, 500),
    isFoodcourtActive: function (val) {
      this.setInstituteIsOpen({ is_open: val })
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    if (this.activeUserStore !== null) {
      if (this.isInstituteSuperAdmin && 'institute' in this.activeUserStore) {
        this.instituteId = this.activeUserStore.institute.id
        this.getAllTenant(false).then(() => {
          this.filteredTenantData = this.tenantData
        })
        this.getReportOverview()
        this.getInstituteDetail()
        this.initPeriodic()
      } else {
        this.toLoginPage()
      }
    } else {
      this.toLoginPage()
    }
  },
  methods: {
    saveTenant() {
      this.showModalTenant = false
      this.$router.push({
        name: 'dashboard',
        params: { slug: this.$route.params.slug },
        query: { lang: this.$route.query.lang },
      })
    },
    closeModalTenant() {
      this.activeTenant = ''
      this.showModalTenant = false
    },
    changePageTrendingMenu(type = 'up') {
      if (type === 'up') {
        if (this.trendingMenuPage >= this.trendingMenuTotalPage)
          this.trendingMenuPage -= 1
      } else if (type === 'down') {
        if (this.trendingMenuTotalPage > this.trendingMenuPage)
          this.trendingMenuPage += 1
      }
    },
    initPeriodic(isReloading = false) {
      if (this.instituteId !== null) {
        this.getReportSalesChart(false, isReloading)
      }
    },
    toDashboardTenant(tenant) {
      this.$store.dispatch('auth/setActiveTenant', tenant)
      this.showModalTenant = false
      this.$router.push({
        name: 'dashboard',
        params: { slug: this.$route.params.slug },
        query: { lang: this.$route.query.lang },
      })
    },
    async getReportOverview(showMessage = false, setLoading = true) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('report/overview', {
          institute_id: this.instituteId,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data
          if ('data' in responseData) {
            this.overviewData = responseData.data
          }
          if (showMessage) {
            this.message = responseData.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getReportSummary(showMessage = false, setLoading = true) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('report/summary', {
          institute_id: this.instituteId,
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data
          if ('data' in responseData) {
            this.cardData = responseData.data
          }
          if (showMessage) {
            this.message = responseData.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getReportSalesChart(showMessage = false, setLoading = true) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('report/salesChart', {
          periodic: this.periodicFilter,
          institute_id: this.instituteId,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data
          if ('data' in responseData) {
            const chartData = responseData.data

            this.chart_sales_empty = chartData.length <= 0
            this.chart_sales_label = chartData.map((item) => item.name)
            this.chart_sales_values = chartData.map((item) => item.total)
          }
          if (showMessage) {
            this.message = responseData.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getInstituteDetail(showMessage = false, setLoading = true) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('institute/getById', {
          id: this.instituteId,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data
          if ('data' in responseData) {
            if ('is_open' in responseData.data) {
              this.isFoodcourtActive = responseData.data.is_open
            }
          }
          if (showMessage) {
            this.message = responseData.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async setInstituteIsOpen(payload) {
      this.isLoading = true

      await this.$store
        .dispatch('institute/changeStatus', {
          id: this.instituteId,
          is_open: payload.is_open,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data

          this.message = responseData.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getSalesChart(showMessage = false, setLoading = true) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('transaction/salesChart', {
          periodic: this.periodicFilter,
          tenant_id: this.selectedTenantIdString,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            const sales_chart = responseData
            this.chart_sales_empty = sales_chart.length <= 0
            this.chart_sales_label = sales_chart.map((item) => item.name)
            this.chart_sales_values = sales_chart.map((item) => item.total)
          }
          if (showMessage) {
            this.message = response.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getPeakHourChart(showMessage = false, setLoading = true) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('transaction/peakHourChart', {
          institute_id: this.instituteId,
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            const peak_hour_chart = responseData
            this.chart_peak_hour_empty = peak_hour_chart.length <= 0
            this.chart_peak_hour_label = peak_hour_chart.map(
              (item) => item.name
            )
            this.chart_peak_hour_values = peak_hour_chart.map(
              (item) => item.total
            )
          }
          if (showMessage) {
            this.message = response.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getTenantChart(showMessage = false, setLoading = true) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('analytic/mostLeastTenantChart', {
          institute_id: this.instituteId,
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          limit: 5,
          type: 'MOST',
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.topTenantData = responseData
          }
          if (showMessage) {
            this.message = response.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getProductChart(showMessage = false, setLoading = true) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('analytic/mostLeastProductChart', {
          institute_id: this.instituteId,
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          limit: 10,
          type: 'MOST',
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            const trendingMenuChart = responseData
            this.trendingMenuChartEmpty = trendingMenuChart.length <= 0
            this.trendingMenuTotalPage = responseData.length > 5 ? 2 : 1
            this.trendingMenuChartLabel = trendingMenuChart.map(
              (item) => item.name
            )
            this.trendingMenuChartData = trendingMenuChart.map(
              (item) => item.total
            )
            this.trendingMenus = trendingMenuChart
          }
          if (showMessage) {
            this.message = response.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false
          if (showMessage) {
            this.message = e.message
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    changeFilterTimeRange(event) {
      this.periodicFilter = event
      this.initPeriodic(true)
    },
    getContent(type = 'trending') {
      if (type === 'trending') {
        return this.$t('page.dashboardSuperInstitute.tooltips.trendingMenu')
      } else if (type === 'topTenant') {
        return this.$t('page.dashboardSuperInstitute.tooltips.topTenant')
      } else if (type === 'peakHour') {
        return this.$t('page.dashboardSuperInstitute.tooltips.peakHour')
      } else if (type === 'daySales') {
        return this.$t('page.dashboardSuperInstitute.tooltips.daySales')
      }

      return ''
    },
  },
}
</script>

<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.employee.title')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
  </div>

  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 mt-4">
          <div class="intro-y box p-5 md:mt-6">
            <div
              class="flex flex-col justify-between space-y-4 md:flex-row md:items-center md:space-y-0"
            >
              <div class="flex">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <div class="flex">
                  <div class="flex self-center align-middle">
                    <h2 class="mr-3 truncate text-lg font-medium">
                      {{ $t($route.meta.title) }}
                    </h2>
                  </div>
                </div>
              </div>
              <div
                class="flex flex-col justify-center space-y-4 md:flex-row md:items-center md:space-y-0 md:space-x-4"
              >
                <div class="flex">
                  <select
                    v-if="isInstitute && tenantOptions.length > 0"
                    v-model="tenantId"
                    class="w-full p-2 pr-10 outline-none dark:bg-dark"
                    @change="changeTenant"
                  >
                    <option
                      v-for="searchItem in tenantOptions"
                      :key="searchItem.value"
                      :value="searchItem.value"
                    >
                      {{ searchItem.label }}
                    </option>
                  </select>
                </div>
                <div class="relative text-slate-500 sm:ml-auto sm:mt-0">
                  <CalendarIcon
                    class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
                  />
                  <Litepicker
                    v-model="dateFilter"
                    :options="{
                      autoApply: false,
                      singleMode: false,
                      numberOfColumns: 2,
                      numberOfMonths: 2,
                      showWeekNumbers: true,
                      format: 'YYYY-MM-DD',
                      dropdowns: {
                        minYear: 1990,
                        maxYear: null,
                        months: true,
                        years: true,
                      },
                    }"
                    class="form-control box border pl-8 sm:w-56"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="shiftDataArr.length > 0"
              class="flex flex-col justify-between pt-10 md:space-x-5 lg:flex-row"
            >
              <div class="flex w-full flex-col space-y-4 md:w-1/3">
                <ShiftItem
                  v-for="(shift, key) in shiftData"
                  :key="key"
                  :shift="shift"
                  :date="formattedDate(key)"
                  :active-shift-id="
                    !checkEmptyObject(activeShift) ? activeShift.id : ''
                  "
                  @shift-detail="shiftDetail"
                />
              </div>
              <div
                v-if="!checkEmptyObject(activeShiftData)"
                class="flex w-full flex-wrap md:w-2/3"
              >
                <div class="flex w-full flex-col space-y-4 px-2">
                  <div class="flex w-full flex-col space-y-2 border-b pb-5">
                    <div class="flex w-full justify-between">
                      <p class="text-gray-500">
                        {{ $t('page.shiftHistory.date') }}
                      </p>
                      <p>{{ formattedDate(activeShiftData.start_date) }}</p>
                    </div>
                    <div class="flex w-full justify-between">
                      <p class="text-gray-500">
                        {{ $t('page.shiftHistory.spv') }}
                      </p>
                      <p>{{ activeShiftData.user.full_name }}</p>
                    </div>
                    <div class="flex w-full justify-between">
                      <p class="text-gray-500">
                        {{ $t('page.shiftHistory.startShift') }}
                      </p>
                      <p>{{ activeShiftData.start_time }}</p>
                    </div>
                    <div
                      v-if="
                        activeShiftData.end_time !== null &&
                        activeShiftData.end_time !== ''
                      "
                      class="flex w-full justify-between"
                    >
                      <p class="text-gray-500">
                        {{ $t('page.shiftHistory.endShift') }}
                      </p>
                      <p>{{ activeShiftData.end_time }}</p>
                    </div>
                    <div class="flex w-full justify-between">
                      <p class="text-gray-500">
                        {{ $t('page.shiftHistory.statusShift') }}
                      </p>
                      <p
                        class="rounded-sm px-4 py-1 text-xs"
                        :class="[
                          activeShiftData.status !== 'DONE'
                            ? 'bg-green-100 text-green-400'
                            : 'bg-red-100 text-red-400',
                        ]"
                      >
                        <span v-if="activeShiftData.status !== 'DONE'">
                          {{ $t('page.shiftHistory.openShift') }}
                        </span>
                        <span v-else>
                          {{ $t('page.shiftHistory.closeShift') }}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div
                    class="grid w-full grid-cols-1 items-center gap-4 py-4 md:grid-cols-3"
                  >
                    <div
                      class="cursor-pointer rounded-lg border px-4 py-4 shadow-sm"
                      :class="[
                        activeDetail === 'cash'
                          ? 'bg-primary-150 border border-primary'
                          : '',
                      ]"
                      @click="setActiveDetail('cash')"
                    >
                      <div class="flex space-x-2">
                        <p class="text-sm font-semibold">
                          {{ $t('page.shiftHistory.totalEndCash') }}
                        </p>
                        <div
                          class="text-left text-xs font-bold text-gray-700"
                          @mouseover="showTooltip = true"
                          @mouseleave="showTooltip = false"
                        >
                          <p
                            class="cursor-help self-center rounded-full border border-gray-300 px-1.5 text-xs text-gray-400 hover:border-gray-500 hover:text-gray-500"
                          >
                            <span>i</span>
                          </p>
                          <div
                            v-if="showTooltip"
                            class="absolute flex flex-col space-y-4 bg-white p-3 text-center text-xs shadow-lg"
                          >
                            <p class="text-gray-500">
                              {{ $t('page.shiftHistory.cashDesc') }}
                            </p>
                            <div
                              v-for="icoming in activeShiftData.cashes.incoming"
                              :key="icoming.id"
                              class="flex justify-between"
                            >
                              <p>{{ icoming.notes }}</p>
                              <Currency :total-in-string="icoming.amount" />
                            </div>
                            <div class="flex justify-between">
                              <p>Uang Masuk</p>
                              <Currency
                                :total-in-string="activeShiftData.orders.cash"
                              />
                            </div>
                            <div
                              v-for="outcoming in activeShiftData.cashes
                                .outcoming"
                              :key="outcoming.id"
                              class="flex justify-between"
                            >
                              <p>{{ outcoming.notes }}</p>
                              <Currency
                                prefix-text="-"
                                class="text-red-500"
                                :total-in-string="outcoming.amount"
                              />
                            </div>

                            <div class="flex justify-between border-t pt-4">
                              <p>
                                {{ $t('page.shiftHistory.totalCash') }}
                              </p>
                              <Currency :total-in-string="totalFinalCash" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <Currency
                        :total-in-string="totalFinalCash"
                        wrap-class="font-bold
                      text-xl text-primary"
                      />
                    </div>
                    <div
                      class="cursor-pointer rounded-lg border px-4 py-4 shadow-sm"
                      :class="[
                        activeDetail === 'sales'
                          ? 'bg-primary-150 border border-primary'
                          : '',
                      ]"
                      @click="setActiveDetail('sales')"
                    >
                      <p class="text-sm font-semibold">
                        {{ $t('page.shiftHistory.totalSales') }}
                      </p>
                      <Currency
                        :total-in-string="totalSales"
                        wrap-class="font-bold text-xl text-primary"
                      />
                    </div>
                    <div
                      class="cursor-pointer rounded-lg border px-4 py-4 shadow-sm"
                      :class="[
                        activeDetail === 'items'
                          ? 'bg-primary-150 border border-primary'
                          : '',
                      ]"
                      @click="setActiveDetail('items')"
                    >
                      <p class="text-sm font-semibold">
                        {{ $t('page.shiftHistory.soldItem') }}
                      </p>
                      <p class="text-xl font-bold text-primary">
                        {{ dataItems }}
                      </p>
                    </div>
                  </div>

                  <template v-if="activeDetail === 'cash'">
                    <div class="flex w-full flex-col space-y-4 border-b pb-4">
                      <div
                        v-for="operationalIn in incomingOperational"
                        :key="operationalIn.id"
                        class="flex w-full justify-between"
                      >
                        <p
                          :class="[
                            operationalIn.notes.toUpperCase() ===
                            'UANG OPERASIONAL'
                              ? 'text-black'
                              : 'text-gray-500',
                          ]"
                        >
                          {{ operationalIn.notes }}
                        </p>
                        <Currency
                          :wrap-class="
                            operationalIn.notes.toUpperCase() ===
                            'UANG OPERASIONAL'
                              ? 'text-black'
                              : 'text-gray-500'
                          "
                          :total-in-string="operationalIn.amount"
                        />
                      </div>
                      <div
                        v-for="operationalOut in outcomingOperational"
                        :key="operationalOut.id"
                        class="flex w-full justify-between"
                      >
                        <p
                          :class="[
                            operationalOut.notes.toUpperCase() ===
                            'UANG OPERASIONAL'
                              ? 'text-black'
                              : 'text-gray-500',
                          ]"
                        >
                          {{ operationalOut.notes }}
                        </p>
                        <Currency
                          prefix-text="-"
                          :wrap-class="
                            operationalOut.notes.toUpperCase() ===
                            'UANG OPERASIONAL'
                              ? 'text-black'
                              : 'text-gray-500'
                          "
                          :total-in-string="operationalOut.amount"
                        />
                      </div>
                    </div>

                    <div class="flex w-full flex-col space-y-4">
                      <div
                        v-for="cashIn in incomingCash"
                        :key="cashIn.id"
                        class="flex w-full justify-between"
                      >
                        <p
                          :class="[
                            cashIn.notes.toUpperCase() === 'UANG KAS AWAL'
                              ? 'text-black'
                              : 'text-gray-500',
                          ]"
                        >
                          {{ cashIn.notes }}
                        </p>
                        <Currency
                          :wrap-class="
                            cashIn.notes.toUpperCase() === 'UANG KAS AWAL'
                              ? 'text-black'
                              : 'text-gray-500'
                          "
                          :total-in-string="cashIn.amount"
                        />
                      </div>
                      <div v-if="false" class="flex w-full justify-between">
                        <p class="text-gray-500">Uang Masuk</p>
                        <Currency
                          wrap-class="text-gray-500"
                          :total-in-string="orderCashIn"
                        />
                      </div>
                      <div class="flex w-full justify-between">
                        <p class="text-black">Total Cash In</p>
                        <Currency
                          wrap-class="text-black"
                          :total-in-string="activeShiftData.orders.cash"
                        />
                      </div>
                      <div
                        v-for="cashOut in outcomingCash"
                        :key="cashOut.id"
                        class="flex w-full justify-between"
                      >
                        <p
                          :class="[
                            cashOut.notes.toUpperCase() === 'UANG KAS AWAL'
                              ? 'text-black'
                              : 'text-gray-500',
                          ]"
                        >
                          {{ cashOut.notes }}
                        </p>
                        <Currency
                          prefix-text="-"
                          :wrap-class="
                            cashOut.notes.toUpperCase() === 'UANG KAS AWAL'
                              ? 'text-black'
                              : 'text-gray-500'
                          "
                          :total-in-string="cashOut.amount"
                        />
                      </div>
                      <div class="flex w-full justify-between">
                        <p class="text-black">Total Cash Out</p>
                        <Currency
                          prefix-text="-"
                          wrap-class="text-black"
                          :total-in-string="totalOutcomingCash"
                        />
                      </div>

                      <div class="flex w-full justify-between">
                        <p class="text-black">Total Final Cash</p>
                        <Currency
                          :prefix-text="totalFinalCash > 0 ? '' : '-'"
                          wrap-class="text-black"
                          :total-in-string="Math.abs(totalFinalCash)"
                        />
                      </div>
                    </div>
                  </template>
                  <template v-else-if="activeDetail === 'sales'">
                    <div class="flex w-full justify-between">
                      <p>Total Sales</p>
                      <Currency :total-in-string="totalSales" />
                    </div>
                    <div class="flex w-full justify-between text-gray-500">
                      <p>Cash</p>
                      <Currency
                        :total-in-string="activeShiftData.orders.cash"
                      />
                    </div>
                    <div class="flex w-full justify-between text-gray-500">
                      <p>Cashless</p>
                      <Currency
                        :total-in-string="activeShiftData.orders.non_cash"
                      />
                    </div>
                    <div class="flex w-full justify-between">
                      <p>Admin Fee</p>
                      <Currency
                        :total-in-string="activeShiftData.sales.admin_fee"
                        prefix-text="-"
                      />
                    </div>
                    <div class="flex w-full justify-between">
                      <p>Tax Fee</p>
                      <Currency
                        :total-in-string="activeShiftData.sales.tax_fee"
                        prefix-text="-"
                      />
                    </div>
                    <div class="flex w-full justify-between">
                      <p>Institute Fee</p>
                      <Currency
                        :total-in-string="activeShiftData.sales.institute_fee"
                        prefix-text="-"
                      />
                    </div>
                    <div class="flex w-full justify-between">
                      <p>Service Fee</p>
                      <Currency
                        :total-in-string="activeShiftData.sales.service_fee"
                        prefix-text="-"
                      />
                    </div>
                    <div class="flex w-full justify-between">
                      <p>Total Revenue</p>
                      <Currency :total-in-string="totalFinalSales" />
                    </div>
                  </template>
                  <template v-else-if="activeDetail === 'items'">
                    <div class="flex w-full justify-between">
                      <p class="font-bold">Makanan</p>
                    </div>
                    <template v-if="activeShiftData.items.foods.length > 0">
                      <div
                        v-for="food in activeShiftData.items.foods"
                        :key="food.id"
                        class="flex w-full justify-between"
                      >
                        <p class="text-gray-500">{{ food.name }}</p>
                        <p class="text-gray-500">{{ food.total }}</p>
                      </div>
                    </template>
                    <div v-else>-</div>

                    <div class="flex w-full justify-between">
                      <p class="font-bold">Minuman</p>
                    </div>

                    <template v-if="activeShiftData.items.drinks.length > 0">
                      <div
                        v-for="drink in activeShiftData.items.drinks"
                        :key="drink.id"
                        class="flex w-full justify-between"
                      >
                        <p class="text-gray-500">{{ drink.name }}</p>
                        <p class="text-gray-500">{{ drink.total }}</p>
                      </div>
                    </template>
                    <div v-else>-</div>

                    <div class="flex w-full justify-between">
                      <p class="font-bold">Lainnya</p>
                    </div>

                    <template v-if="activeShiftData.items.others.length > 0">
                      <div
                        v-for="other in activeShiftData.items.others"
                        :key="other.id"
                        class="flex w-full justify-between"
                      >
                        <p class="text-gray-500">{{ other.name }}</p>
                        <p class="text-gray-500">{{ other.total }}</p>
                      </div>
                    </template>
                    <div v-else>-</div>

                    <div class="flex w-full justify-between">
                      <p class="font-bold">TOTAL ITEM TERJUAL</p>
                      <p>{{ dataItems }}</p>
                    </div>
                  </template>
                </div>
              </div>

              <NoData v-else wrapper-class="flex w-2/3 flex-wrap" />
            </div>
            <NoData v-else />
          </div>
        </div>
        <!-- END: Sales Report -->
      </div>
    </div>

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>

  <!-- BEGIN: Toast -->
  <Toast
    id="success"
    :title="$t('formInfo.success')"
    :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
  />
  <Toast
    id="failed"
    :title="$t('formInfo.failed')"
    :message="message !== '' ? message : $t('formInfo.fetchFailed')"
    :is-failed="true"
  />
  <!-- END: Toast -->
</template>

<script>
import { ref } from 'vue'
import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import Currency from '@/components/Currency.vue'

import ShiftItem from '@/components/ShiftItem.vue'

import SaleIcon from '@/assets/svg/sale.svg'
import NoData from '@/components/NoData.vue'

export default {
  name: 'SalesSummary',
  components: {
    ShiftItem,
    Breadcrumb,
    Currency,
    SaleIcon,
    NoData,
  },
  mixins: [globalMixin, tenantMixin],
  setup() {
    const dateFilter = ref('')

    return {
      dateFilter,
    }
  },
  data: () => ({
    activeShift: {},
    shiftDataArr: [],
    shiftData: {},
    activeShiftData: {},
    activeDetail: 'cash',
    showTooltip: false,

    incomingOperational: [],
    incomingCash: [],
    outcomingOperational: [],
    outcomingCash: [],
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
    dataItems() {
      if ('items' in this.activeShiftData) {
        const dataItems = this.activeShiftData.items

        let totalItemsFood = 0
        if ('foods' in dataItems) {
          totalItemsFood = dataItems.foods.reduce((total, currVal) => {
            return (total += currVal.total)
          }, 0)
        }

        let totalItemsDrink = 0
        if ('drinks' in dataItems) {
          totalItemsDrink = dataItems.drinks.reduce((total, currVal) => {
            return (total += currVal.total)
          }, 0)
        }

        let totalItemsOther = 0
        if ('others' in dataItems) {
          totalItemsOther = dataItems.others.reduce((total, currVal) => {
            return (total += currVal.total)
          }, 0)
        }

        const total = totalItemsFood + totalItemsDrink + totalItemsOther
        return total
      } else {
        return 0
      }
    },
    orderCashIn() {
      let orderCash = 0
      if (
        'orders' in this.activeShiftData &&
        'cash' in this.activeShiftData.orders
      )
        orderCash = this.activeShiftData.orders.cash

      return orderCash
    },
    totalCashIn() {
      return this.totalIncomingCash + this.orderCashIn
    },
    totalCashOut() {
      let outcomingCashTotal = 0
      if ('outcoming_cash_total' in this.activeShiftData)
        outcomingCashTotal = this.activeShiftData.outcoming_cash_total

      return outcomingCashTotal
    },
    totalIncomingCash() {
      let totalIncomingCash = 0

      this.incomingCash.forEach((item) => (totalIncomingCash += item.amount))
      return totalIncomingCash
    },
    totalOutcomingCash() {
      let totalOutcomingCash = 0

      this.outcomingCash.forEach((item) => (totalOutcomingCash += item.amount))
      return totalOutcomingCash
    },
    totalOperational() {
      let totalOperational = 0
      this.incomingOperational.forEach(
        (item) => (totalOperational += item.amount)
      )
      this.outcomingOperational.forEach(
        (item) => (totalOperational -= item.amount)
      )

      return totalOperational
    },
    totalFinalCash() {
      let incomingCashTotal = 0
      if ('incoming_cash_total' in this.activeShiftData)
        incomingCashTotal = this.activeShiftData.incoming_cash_total

      let orderCash = 0
      if (
        'orders' in this.activeShiftData &&
        'cash' in this.activeShiftData.orders
      )
        orderCash = this.activeShiftData.orders.cash

      let outcomingCashTotal = 0
      if ('outcoming_cash_total' in this.activeShiftData)
        outcomingCashTotal = this.activeShiftData.outcoming_cash_total

      return incomingCashTotal + orderCash - outcomingCashTotal
    },
    totalSales() {
      let totalSales = 0
      if (
        'orders' in this.activeShiftData &&
        'total' in this.activeShiftData.orders
      )
        totalSales = this.activeShiftData.orders.total

      return totalSales
    },
    totalFeeSales() {
      let totalFeeSales = 0

      if ('admin_fee' in this.activeShiftData.sales)
        totalFeeSales += this.activeShiftData.sales.admin_fee
      if ('tax_fee' in this.activeShiftData.sales)
        totalFeeSales += this.activeShiftData.sales.tax_fee
      if ('institute_fee' in this.activeShiftData.sales)
        totalFeeSales += this.activeShiftData.sales.institute_fee
      if ('service_fee' in this.activeShiftData.sales)
        totalFeeSales += this.activeShiftData.sales.service_fee

      return totalFeeSales
    },
    totalFinalSales() {
      return this.totalSales - this.totalFeeSales
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      if (this.tenantId !== '') {
        this.getShift()
      }
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getShift(false)
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getShift(false)
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    mappingDateShift(dataShift) {
      const dataMapping = {}
      if (dataShift.length > 0) {
        dataShift.forEach((item) => {
          if (item.start_date in dataMapping) {
            dataMapping[item.start_date].push(item)
          } else {
            dataMapping[item.start_date] = [item]
          }
        })
      }
      return dataMapping
    },
    getActiveShift(data) {
      if (data.length > 0) {
        this.activeShift.id = data[0].id
        this.getShiftDetail(false)
      }
      // let dataActive = {}
      // const activeShift = data.filter((item) => item.status !== 'DONE')
      // if (activeShift.length > 0) {
      //   dataActive = activeShift.at(-1)
      // } else {
      //   const dataMapping = this.mappingDateShift(data, true)
      //   if (dataMapping.length > 0) {
      //     dataActive = dataMapping.at(-1)
      //   }
      // }

      // this.activeShift = dataActive
      // if (!this.checkEmptyObject(this.activeShift)) {
      //   this.getShiftDetail()
      // }
    },
    async getShift(showMessage = true) {
      this.isLoading = true

      const params = {
        page: 1,
        take: 50,
        order: 'DESC',
        start_date: this.filterDates.start,
        end_date: this.filterDates.end,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('shift/getAll', params)
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.shiftDataArr = responseData.data
            this.shiftData = this.mappingDateShift(responseData.data)
            this.getActiveShift(responseData.data)
          }
          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getShiftDetail(showMessage = true) {
      this.isLoading = true

      const params = {
        id: this.activeShift.id,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('shift/getDetail', params)
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          this.activeShiftData = responseData
          if ('cashes' in this.activeShiftData) {
            if ('incoming' in this.activeShiftData.cashes) {
              this.incomingOperational =
                this.activeShiftData.cashes.incoming.filter(
                  (item) => item.category === 'OPERATIONAL'
                )
              this.incomingCash = this.activeShiftData.cashes.incoming.filter(
                (item) => item.category === 'CASH'
              )
            }
            if ('outcoming' in this.activeShiftData.cashes) {
              this.outcomingOperational =
                this.activeShiftData.cashes.outcoming.filter(
                  (item) => item.category === 'OPERATIONAL'
                )
              this.outcomingCash = this.activeShiftData.cashes.outcoming.filter(
                (item) => item.category === 'CASH'
              )
            }
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },

    changeTenant() {
      this.getShift()
    },
    shiftDetail(event) {
      this.activeShift.id = event
      this.getShiftDetail()
    },
    setActiveDetail(detail) {
      this.activeDetail = detail
    },
  },
}
</script>

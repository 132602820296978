<template>
  <div>
    <div v-if="!checkEmptyObject(activeSupplier)" class="relative">
      <LoadingIndicator v-if="isLoading || isLoadingSave" />
      <div
        class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
      >
        <div class="flex">
          <Breadcrumb
            :title="$t('menu.purchaseOrder.stockPurchase')"
            link="stock-purchase"
            :subtitles="[
              {
                title: 'title' in $route.meta ? $t($route.meta.title) : '',
                link: 'stock-purchase-supplier',
              },
              {
                title: activeSupplier.name,
                link: null,
              },
            ]"
          />
        </div>
      </div>
      <div class="mt-8 flex w-full flex-col justify-center space-y-4">
        <div
          class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8"
        >
          <div class="flex w-full items-center justify-between text-base">
            <span>{{ activeSupplier.name }}</span>

            <div class="ml-4 flex shadow">
              <input
                v-model="searchSupplierProduct"
                class="w-full border-none p-2 outline-none"
                type="text"
                :placeholder="$t('formLabel.searchFoo', { foo: 'Product' })"
              />
              <button
                class="text-blue hover:text-blue-light flex w-auto items-center justify-end p-2"
                @click="filterSupplierProduct"
              >
                <SearchIcon />
              </button>
            </div>
          </div>
          <div class="flex w-full flex-col">
            <div
              v-if="supplierProductData.length > 0"
              class="mt-2 grid grid-cols-3 gap-6 md:grid-cols-5"
            >
              <ProductSupplierItem
                v-for="product in supplierProductData"
                :key="product.id"
                :product="product"
                @show-detail="showDetailProductSupplier"
              />
            </div>
            <div
              v-else-if="!isLoading"
              class="w-full text-center text-gray-500"
            >
              Product
              <span class="font-bold">{{ searchSupplierProduct }}</span> tidak
              ditemukan
            </div>
          </div>
          <div
            v-if="supplierProductData.length > 0"
            class="flex w-full justify-between"
          >
            <ChevronLeftIcon
              :class="[
                !metaDataProductSupplier.hasPreviousPage
                  ? 'cursor-not-allowed opacity-25'
                  : 'cursor-pointer',
              ]"
              class="h-6 w-6"
              @click="metaDataProductSupplier.hasPreviousPage ? prevPage() : ''"
            />
            <ChevronRightIcon
              :class="[
                !metaDataProductSupplier.hasNextPage ||
                metaDataProductSupplier.pageCount ===
                  metaDataProductSupplier.page
                  ? 'cursor-not-allowed opacity-25'
                  : 'cursor-pointer',
              ]"
              class="h-6 w-6"
              @click="metaDataProductSupplier.hasNextPage ? nextPage() : ''"
            />
          </div>
        </div>
      </div>

      <!-- BEGIN: Toast -->
      <Toast
        id="success"
        :title="$t('formInfo.success')"
        :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
      />
      <Toast
        id="failed"
        :title="$t('formInfo.failed')"
        :message="message !== '' ? message : $t('formInfo.fetchFailed')"
        :is-failed="true"
      />
      <!-- END: Toast -->
    </div>
  </div>
</template>
<script>
import debounce from 'lodash.debounce'
import globalMixin from '@/mixins/global.js'
import stockPurchaseMixin from '@/mixins/stockPurchase.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import ProductSupplierItem from '@/components/item/ProductSupplierItem.vue'

export default {
  name: 'StockPurchaseSupplierDetail',
  components: {
    Breadcrumb,

    ProductSupplierItem,
  },
  mixins: [globalMixin, stockPurchaseMixin],
  watch: {
    searchSupplierProduct: debounce(function (newVal, oldVal) {
      this.filterSupplierProduct()
    }, 500),
  },
  mounted() {
    this.supplierId = this.$route.params.id
    this.getDetailSupplier(this.supplierId, false).then(() => {
      this.getAllProductSupplier(true, true)
    })
  },
  methods: {
    filterSupplierProduct() {
      this.getAllProductSupplier(
        false,
        true,
        this.searchSupplierProduct,
        null,
        null
      )
    },
    prevPage() {
      this.filterDataProductSupplier.page -= 1
      this.getAllProductSupplier(true, true)
    },
    nextPage() {
      this.filterDataProductSupplier.page += 1
      this.getAllProductSupplier(true, true)
    },
  },
}
</script>

export default {
  data: () => ({
    tableDataArr: [],
    tableData: [],
    oldTableData: [],
    deletedId: [],
    changeStatusData: [],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 150,
      search: '',
      sortBy: 'segmentation:asc,number:asc',
    },
  }),
  methods: {
    async getAllTable(
      showMessage = true,
      setLoading = false,
      getAgain = false
    ) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('table/getAll', {
          sortBy: this.filterData.sortBy,
          page: this.filterData.page,
          take: this.filterData.take,
          q: '',
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            if (getAgain) {
              this.tableDataArr = this.mergeTwoArrayByKey(
                this.tableDataArr,
                responseData.data
              )
            } else {
              this.tableDataArr = responseData.data
            }

            const mappingSegmentation = {}
            this.tableDataArr.forEach((item) => {
              let segment = item.segmentation
              if (segment === '') segment = 'without'
              if (mappingSegmentation.hasOwnProperty(segment)) {
                mappingSegmentation[segment].push(item)
              } else {
                mappingSegmentation[segment] = [item]
              }
            })
            this.tableData = mappingSegmentation
            this.oldTableData = mappingSegmentation
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
            if (
              'hasNextPage' in responseData.meta &&
              responseData.meta.hasNextPage
            ) {
              this.filterData.page++
              this.getAllTable(false, false, true)
            }
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveTableAPI(params, isUpdate = false) {
      let dispatcher = 'table/create'
      if (isUpdate) dispatcher = 'table/update'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetData()
          this.getAllTable(false)
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteTableAPI(ids) {
      this.isLoadingSave = true
      await this.store
        .dispatch('table/delete', { ids })
        .then((response) => {
          this.isEdit = false
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.getAllTable(false)
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async updateStatusAPI(params) {
      this.isLoadingSave = true
      await this.store
        .dispatch('table/updateStatus', params)
        .then((response) => {
          this.isEdit = false
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.getAllTable(false)
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}

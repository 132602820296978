<template>
  <div
    class="col-span-12 bg-white dark:bg-slate-900"
    :class="[wrapperClass !== '' ? wrapperClass : 'mt-6 p-5 md:p-10']"
  >
    <div v-if="showHeader" class="intro-y block items-center sm:flex">
      <div v-if="showTitle" class="flex align-middle">
        <div
          v-if="showIcon"
          class="flex justify-center self-center align-middle"
        >
          <slot v-if="hasIconSlot" name="icon"></slot>
          <SaleIcon v-else class="mr-2 h-4 w-4 text-black dark:text-white" />
        </div>
        <TitleComponent :title="title" :date-string="dateString" />
      </div>
      <div
        class="mt-3 flex flex-wrap items-center justify-center space-y-2 md:ml-auto md:mt-0 md:flex-nowrap md:justify-end md:space-y-0"
      >
        <slot name="additionalPreFilter"></slot>

        <v-select
          v-if="vueSelectData.length > 0"
          v-model="vueSelectModel"
          :label="vueSelectLabel"
          :options="vueSelectData"
        ></v-select>

        <Dropdown
          v-if="
            Object.keys(filter).length !== 0 &&
            ((filter.items[0] !== undefined &&
              typeof filter.items[0] === 'object' &&
              filter.items[0] !== null &&
              'label' in filter.items[0]) ||
              isNotEmpty)
          "
          class="mt-5 md:ml-auto md:mt-0"
        >
          <DropdownToggle
            class="btn hover:btn-outline-secondary font-normal"
            @click="isDropdownShow = true"
          >
            <span v-if="selectedFilter">
              <template
                v-if="
                  selectedFilter !== undefined &&
                  typeof selectedFilter === 'object' &&
                  selectedFilter !== null &&
                  'label' in selectedFilter
                "
              >
                {{ selectedFilter.label }}
              </template>
              <template v-else>
                {{ selectedFilter }}
              </template>
            </span>
            <span
              v-else-if="
                filter.items[0] !== undefined &&
                typeof filter.items[0] === 'object' &&
                filter.items[0] !== null &&
                'label' in filter.items[0]
              "
            >
              {{ filter.items[0].label }}
            </span>
            <span v-else-if="filter.items[0] !== undefined">{{
              filter.items[0]
            }}</span>
            <span v-else>Filter by {{ filter.title }}</span>
            <ChevronDownIcon class="ml-2 h-4 w-4" />
          </DropdownToggle>
          <DropdownMenu class="w-40">
            <DropdownContent v-if="isDropdownShow" class="h-32 overflow-y-auto">
              <template
                v-if="
                  filter.items.length > 0 &&
                  typeof filter.items[0] === 'object' &&
                  filter.items[0] !== null &&
                  'value' in filter.items[0]
                "
              >
                <DropdownItem
                  v-for="item in filter.items"
                  :key="item.value"
                  @click="filterSelected(item)"
                >
                  {{ item.label || '' }}
                </DropdownItem>
              </template>
              <template v-else-if="filter.items.length > 0">
                <DropdownItem
                  v-for="item in filter.items"
                  :key="item"
                  @click="filterSelected(item)"
                >
                  {{ item }}
                </DropdownItem>
              </template>
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>

        <div v-if="isShowSearch" class="ml-4 flex shadow">
          <select
            v-if="searchOptions.length > 0"
            v-model="search"
            class="w-full border-none p-2 pr-10 outline-none"
            @change="doFilter('filter')"
          >
            <option value="">{{ $t('button.showAll') }}</option>
            <option
              v-for="searchItem in searchOptions"
              :key="searchItem"
              :value="searchItem"
            >
              {{ searchItem }}
            </option>
          </select>
          <select
            v-else-if="searchOptionsWithKey.length > 0"
            v-model="search"
            class="w-full border-none p-2 pr-10 outline-none"
            @change="doFilter('filter')"
          >
            <option
              v-for="searchItem in searchOptionsWithKey"
              :key="searchItem.value"
              :value="searchItem.value"
            >
              {{ searchItem.label }}
            </option>
          </select>
          <template v-else>
            <input
              v-model="search"
              class="w-full border-none p-2 outline-none"
              type="text"
              :placeholder="$t(searchPlaceholder)"
            />
            <button
              class="text-blue hover:text-blue-light flex w-auto items-center justify-end p-2"
              @click="doFilter('search')"
            >
              <SearchIcon />
            </button>
          </template>
        </div>

        <div v-if="isShowSearchWithFilter" class="ml-4 flex shadow">
          <input
            v-model="searchQuery"
            class="w-full border-none p-2 outline-none"
            type="text"
            :placeholder="$t('formLabel.search')"
          />
          <button
            class="text-blue hover:text-blue-light flex w-auto items-center justify-end p-2"
            @click="doFilter('search-filter')"
          >
            <SearchIcon />
          </button>
        </div>

        <div v-if="isShowExport" class="ml-2 flex">
          <!-- <ExportCSVComponent @click="$emit('do-export')" /> -->
          <ExportCSVComponent @export="doExport" />
          <!-- <Dropdown class="mt-5 md:ml-auto md:mt-0">
            <DropdownToggle class="btn hover:btn-outline-secondary font-normal">
            </DropdownToggle>
            <DropdownMenu class="w-40">
              <DropdownContent class="h-32 overflow-y-auto">
                <DropdownItem @click="$emit('do-export', 'csv')">
                  .csv
                </DropdownItem>
                <DropdownItem @click="$emit('do-export', 'xlsx')">
                  .xlsx
                </DropdownItem>
              </DropdownContent>
            </DropdownMenu>
          </Dropdown> -->
        </div>
        <div v-if="isShowAdd" class="ml-2 flex">
          <AddButtonComponent
            :title="titleAdd === '' ? $t('action.add') : titleAdd"
            @click="$emit('do-add')"
          />
        </div>

        <div
          v-if="isShowButtonEdit"
          class="mt-3 flex items-center space-x-2 sm:ml-auto sm:mt-0"
        >
          <template v-if="!isEdit">
            <ButtonComponent
              :title="$t('action.edit')"
              wrapper-class="bg-white text-black dark:bg-gray-100 dark:text-black border-black dark:border-white hover:opacity-80"
              @click="change"
            >
              <template #icon>
                <EditIcon class="mr-1 h-4 w-4" />
              </template>
            </ButtonComponent>
          </template>
          <template v-else>
            <ButtonComponent
              :title="$t('button.cancel')"
              wrapper-class="bg-white text-black dark:bg-gray-100 dark:text-black border-black dark:border-white hover:opacity-80"
              @click="cancelChange"
            >
            </ButtonComponent>
            <ButtonComponent
              :title="$t('button.saveChange')"
              @click="saveChange"
            >
            </ButtonComponent>
          </template>
        </div>
      </div>
      <slot name="additionalFilter"></slot>
    </div>

    <slot name="additionalHeader"></slot>

    <div
      class="intro-y overflow-auto"
      :class="[
        wrapperTableClass !== '' ? wrapperTableClass : 'mt-0 py-5 md:mt-5',
      ]"
    >
      <table class="table-report table md:mt-2">
        <thead v-if="showTableHeader">
          <tr v-if="header.length > 0">
            <th
              v-for="headerItem in header.filter((h) => h.item !== '')"
              :key="headerItem.item"
              class="whitespace-nowrap"
              :class="headerItem.customClass"
            >
              <p
                v-if="Array.isArray(headerItem.item)"
                class="flex flex-col text-right"
              >
                <span v-for="item in headerItem.item" :key="item">
                  {{ $t(item) }}
                </span>
              </p>
              <p v-else>{{ $t(headerItem.item) }}</p>
            </th>
            <th v-if="addEmptyHeader" class="whitespace-nowrap">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <slot v-if="isNotEmpty"></slot>
          <tr v-else>
            <td :colspan="header.length" class="text-center">
              {{ $t('info.thereIsNoData') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="isNotEmpty && isShowPagination"
      class="intro-y mt-3 flex flex-wrap items-center justify-between sm:flex-row sm:flex-nowrap"
    >
      <div class="flex flex-col">
        <nav class="w-full sm:mr-auto sm:w-auto">
          <ul class="pagination">
            <li
              class="page-item"
              :class="[
                !metaData.hasPreviousPage
                  ? 'cursor-not-allowed opacity-25'
                  : '',
              ]"
            >
              <a
                class="page-link"
                @click="metaData.hasPreviousPage ? changePage(1) : ''"
              >
                <ChevronsLeftIcon class="h-4 w-4" />
              </a>
            </li>
            <li
              class="page-item"
              :class="[
                !metaData.hasPreviousPage
                  ? 'cursor-not-allowed opacity-25'
                  : '',
              ]"
            >
              <a
                class="page-link"
                @click="
                  metaData.hasPreviousPage ? changePage(metaData.page - 1) : ''
                "
              >
                <ChevronLeftIcon class="h-4 w-4" />
              </a>
            </li>
            <template v-if="pageCountArr.length > 0">
              <li
                v-for="index in pageCountArr"
                :key="index"
                class="page-item rounded-lg text-center"
              >
                <a
                  class="page-link"
                  :class="[
                    index === metaData.page
                      ? 'active bg-primary font-bold text-slate-100 shadow-md shadow-slate-200'
                      : '',
                  ]"
                  @click="changePage(index)"
                  >{{ index }}</a
                >
              </li>
            </template>
            <template v-else>
              <li
                v-for="index in metaData.pageCount"
                :key="index"
                class="page-item rounded-lg text-center"
              >
                <a
                  v-if="index <= 4 || index === metaData.pageCount"
                  class="page-link"
                  :class="[
                    index === metaData.page
                      ? 'active bg-primary font-bold text-slate-100 shadow-md shadow-slate-200'
                      : '',
                  ]"
                  @click="changePage(index)"
                  >{{ index }}</a
                >
              </li>
            </template>

            <li
              class="page-item"
              :class="[
                !metaData.hasNextPage || metaData.pageCount === metaData.page
                  ? 'cursor-not-allowed opacity-25'
                  : '',
              ]"
            >
              <a
                class="page-link"
                @click="
                  metaData.hasNextPage || metaData.pageCount !== metaData.page
                    ? changePage(metaData.page + 1)
                    : ''
                "
              >
                <ChevronRightIcon class="h-4 w-4" />
              </a>
            </li>
            <li
              class="page-item"
              :class="[
                !metaData.hasNextPage || metaData.pageCount === metaData.page
                  ? 'cursor-not-allowed opacity-25'
                  : '',
              ]"
            >
              <a
                class="page-link"
                @click="
                  metaData.hasNextPage || metaData.pageCount !== metaData.page
                    ? changePage(metaData.pageCount)
                    : ''
                "
              >
                <ChevronsRightIcon class="h-4 w-4" />
              </a>
            </li>
          </ul>
        </nav>
        <p
          v-if="false"
          class="pt-4 pl-1 text-center text-gray-500 md:text-left"
        >
          Menampilkan {{ metaData.take }} dari {{ metaData.itemCount }} hasil
        </p>
      </div>
      <select
        v-if="showOption && isNotEmpty"
        v-model="take"
        class="box form-select mt-3 w-full sm:mt-0 md:w-20"
        @change="doFilter('take')"
      >
        <option v-for="option in showOption" :key="option">
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import SaleIcon from '@/assets/svg/sale.svg'
import debounce from 'lodash.debounce'

import TitleComponent from '@/components/TitleComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import AddButtonComponent from '@/components/AddButtonComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
  name: 'TableComponent',
  components: {
    SaleIcon,
    TitleComponent,
    ExportCSVComponent,
    AddButtonComponent,
    ButtonComponent,
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
    showTableHeader: {
      type: Boolean,
      default: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    wrapperTableClass: {
      type: String,
      default: '',
    },
    dateString: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => {
        return {}
      },
    },
    header: {
      type: Array,
      default: () => {
        return []
      },
    },
    vueSelectLabel: {
      type: String,
      default: '',
    },
    vueSelectData: {
      type: Array,
      default: () => {
        return []
      },
    },
    searchOptions: {
      type: Array,
      default: () => {
        return []
      },
    },
    searchOptionsWithKey: {
      type: Array,
      default: () => {
        return []
      },
    },
    selectedSearch: {
      type: [String, Number],
      default: '',
    },
    isShowSearch: {
      type: Boolean,
      default: false,
    },
    isShowSearchWithFilter: {
      type: Boolean,
      default: false,
    },
    isNotEmpty: {
      type: Boolean,
      default: true,
    },
    showOption: {
      type: Array,
      default: () => {
        return [10, 25, 35, 50]
      },
    },
    metaData: {
      type: Object,
      default: () => {
        return {
          page: 1,
          take: 10,
          itemCount: 10,
          pageCount: 1,
          hasPreviousPage: false,
          hasNextPage: true,
        }
      },
    },
    isShowExport: {
      type: Boolean,
      default: false,
    },
    isShowAdd: {
      type: Boolean,
      default: false,
    },
    titleAdd: {
      type: String,
      default: '',
    },
    isShowPagination: {
      type: Boolean,
      default: true,
    },
    isShowButtonEdit: {
      type: Boolean,
      default: false,
    },
    addEmptyHeader: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'formLabel.search',
    },
  },
  emits: [
    'change-filter',
    'do-export',
    'vue-select-change',
    'change-filter-dropdown',
    'do-add',
    'cancel-change',
    'save-change',
    'is-edited',
  ],
  data: () => ({
    take: 10,
    page: 1,
    order: 'DESC',
    search: '',
    searchQuery: '',
    vueSelectModel: '',
    pageCountArr: [],
    selectedFilter: null,
    isDropdownShow: false,
    isEdit: false,
  }),
  computed: {
    hasIconSlot() {
      return !!this.$slots.icon
    },
    activePage() {
      return this.metaData.page
    },
  },
  watch: {
    activePage: function () {
      this.paging()
    },
    selectedSearch: function () {
      this.search = this.selectedSearch
    },
    metaData: function (val) {
      if (val !== null && 'take' in val) {
        this.take = val.take
      }
    },
    search: debounce(function (newVal, oldVal) {
      if (!this.isShowSearchWithFilter) {
        if (newVal.length === 0) {
          this.$emit('change-filter', {
            filterData: {
              order: this.order,
              page: 1,
              take: this.take,
              search: '',
            },
          })
        } else if (newVal !== oldVal && newVal.length >= 2) {
          if (!this.isShowSearchWithFilter) {
            this.emitSearch(newVal)
          } else {
            this.emitSearch(this.searchQuery)
          }
        }
      }
    }, 500),
    searchQuery: debounce(function (newVal) {
      this.$emit('change-filter', {
        filterData: {
          order: this.order,
          page: 1,
          take: this.take,
          search: newVal,
        },
      })
    }, 500),
  },
  mounted() {
    this.paging()
    this.search = this.selectedSearch
  },
  methods: {
    emitSearch(search) {
      this.$emit('change-filter', {
        filterData: {
          order: this.order,
          page: 1,
          take: this.take,
          search: search,
        },
      })
    },
    changePage(page) {
      this.page = page
      this.doFilter()
    },
    doFilter(mode = '') {
      if (mode === 'take' || mode === 'search') {
        this.page = 1
      } else if (mode === 'filter') {
        return this.$emit('change-filter-dropdown', this.search)
      } else if (mode === 'search-filter') {
        return this.$emit('change-filter', {
          filterData: {
            order: this.order,
            page: this.page,
            take: this.take,
            search: this.searchQuery,
          },
        })
      }

      let search = this.search
      if (this.isShowSearchWithFilter) {
        search = this.searchQuery
      }

      this.$emit('change-filter', {
        filterData: {
          order: this.order,
          page: this.page,
          take: this.take,
          search: search,
        },
      })
    },
    changeSelectModel() {
      this.$emit('vue-select-change', this.vueSelectModel)
    },
    paging() {
      this.pageCountArr = []
      const pageCount = Math.abs(this.metaData.pageCount)

      if (pageCount > 1) {
        let pageCountArr = Array.from({ length: pageCount }, (_, i) => i + 1)

        let page = this.metaData.page
        let newArr = []
        let counter = 1
        pageCountArr.forEach((item, index, arr) => {
          if (item <= 2) {
            newArr.push(item)
          } else if (item <= page + 1 && item >= page - 1) {
            newArr.push(item)
            counter = 1
          } else if (counter <= 1) {
            counter++
            newArr.push('...')
          } else if (index === arr.length - 1) {
            newArr.push(item)
          }
        })

        this.pageCountArr = newArr
      }
    },
    filterSelected(item) {
      this.selectedFilter = item
      this.isDropdownShow = false
      this.$emit('change-filter-dropdown', this.selectedFilter)
    },
    change() {
      this.isEdit = true
      this.$emit('is-edited')
    },
    cancelChange() {
      this.isEdit = false
      this.$emit('cancel-change')
    },
    saveChange() {
      this.isEdit = false
      this.$emit('save-change')
    },
    doExport($event) {
      this.$emit('do-export', $event)
    },
  },
}
</script>

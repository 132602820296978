<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
  </div>

  <!-- BEGIN: Order Detail Table -->

  <TableComponent
    :title="$t($route.meta.title)"
    :header="
      isEdited
        ? tenantOfficeHourTableHeaderEdited
        : tenantOfficeHourTableHeaders
    "
    :is-show-search="isInstitute"
    :search-options-with-key="isInstitute ? tenantOptions : []"
    :selected-search="tenantId"
    :meta-data="metaDataTenantSchedules"
    :is-show-pagination="false"
    :is-not-empty="tenantSchedulesData.length > 0"
    :is-show-button-edit="true"
    @is-edited="changeStatusEdit"
    @cancel-change="cancelChange"
    @save-change="saveChange"
    @change-filter="changeFilter($event)"
    @change-filter-dropdown="changeFilterDropdown($event)"
  >
    <tr v-for="(data, index) in tenantSchedulesData" :key="index">
      <td class="text-center">{{ getDayTranslation(data.day) }}</td>
      <td class="flex w-full items-center justify-center">
        <p
          class="rounded-full py-1 px-4 text-white"
          :class="[
            data.is_close || !data.is_open ? 'bg-red-700' : 'bg-green-700',
          ]"
        >
          <span v-if="data.is_close || !data.is_open">
            {{ $t('page.officeHour.closed') }}
          </span>
          <span v-else>
            {{ $t('page.officeHour.open') }}
          </span>
        </p>
      </td>
      <template v-if="isEdited">
        <template v-if="data.is_open">
          <td class="text-center">
            <VueTimePicker v-model="data.open_time" :format="timeFormat">
              <template #clearButton>&nbsp;</template>
            </VueTimePicker>
          </td>
          <td class="text-center">
            <VueTimePicker v-model="data.closed_time" :format="timeFormat">
              <template #clearButton>&nbsp;</template>
            </VueTimePicker>
          </td>
        </template>
        <template v-else>
          <td class="text-center">&nbsp;</td>
          <td class="text-center">&nbsp;</td>
        </template>
      </template>
      <template v-else>
        <td class="text-center">{{ data.open_time }}</td>
        <td class="text-center">{{ data.closed_time }}</td>
      </template>
      <td v-if="isEdited" class="text-center">
        <input
          v-model="data.is_open"
          class="mt-[0.3rem] mr-2 h-3.5 w-10 appearance-none rounded-[0.4375rem] bg-[rgba(0,0,0,0.25)] outline-none before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
          type="checkbox"
          role="switch"
          @change="changeIsOpen(data.is_open, data.day)"
        />
      </td>
    </tr>
  </TableComponent>
  <!-- END: Order Detail Table -->

  <!-- BEGIN: Toast -->
  <Toast
    id="success"
    :title="$t('formInfo.success')"
    :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
  />
  <Toast
    id="failed"
    :title="$t('formInfo.failed')"
    :message="message !== '' ? message : $t('formInfo.fetchFailed')"
    :is-failed="true"
  />
  <!-- END: Toast -->
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'

import VueTimePicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'

export default {
  name: 'TenantOfficeHourPage',
  components: {
    Breadcrumb,

    TableComponent,
    VueTimePicker,
  },
  mixins: [globalMixin, tenantMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    return {
      t,
      n,
      store,
      route,
      router,
    }
  },
  data: () => ({
    isEdited: false,
    dayClose: [],
    schedules: [],
    timeFormat: 'HH:mm:ss',

    tenantOfficeHourTableHeaders: [
      {
        item: 'page.officeHour.tableHeaders.day',
        customClass: 'text-center',
      },
      {
        item: 'page.officeHour.tableHeaders.status',
        customClass: 'text-center',
      },
      {
        item: 'page.officeHour.tableHeaders.openTime',
        customClass: 'text-center',
      },
      {
        item: 'page.officeHour.tableHeaders.closeTime',
        customClass: 'text-center',
      },
    ],
    tenantOfficeHourTableHeaderEdited: [
      {
        item: 'page.officeHour.tableHeaders.day',
        customClass: 'text-center',
      },
      {
        item: 'page.officeHour.tableHeaders.status',
        customClass: 'text-center',
      },
      {
        item: 'page.officeHour.tableHeaders.openTime',
        customClass: 'text-center',
      },
      {
        item: 'page.officeHour.tableHeaders.closeTime',
        customClass: 'text-center',
      },
      {
        item: 'page.officeHour.tableHeaders.openClose',
        customClass: 'text-center',
      },
    ],
  }),
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getTenantSchedules(true)
        this.isInstitute = false
      } else {
        this.getAllTenant(true, true)
        this.isInstitute = true
      }
    }
  },
  methods: {
    changeFilter(event) {
      this.filterData = event.filterData
      this.tenantId = event.filterData.search
      this.getTenantSchedules()
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getTenantSchedules()
    },
    changeStatusEdit() {
      this.isEdited = true
      this.mappingSchedules()
    },
    mappingSchedules(checkFilter = false) {
      let schedules = this.tenantSchedulesData.map((item) => ({
        day: item.day,
        open_time: item.open_time,
        closed_time: item.closed_time,
      }))

      if (checkFilter) {
        schedules = schedules.filter(
          (item) => !this.dayClose.includes(String(item.day))
        )
      }

      this.schedules = schedules
    },
    cancelChange() {
      this.isEdited = false
    },
    saveChange() {
      this.isEdited = false
      this.mappingSchedules()

      const params = {
        day_close: this.dayClose,
        schedules: this.schedules,
      }
      this.setTenantSchedules(params)
    },
    changeIsOpen(isOpen, day) {
      const dayString = String(day)
      if (isOpen) {
        if (this.dayClose.includes(dayString)) {
          const index = this.dayClose.indexOf(dayString)
          if (index > -1) {
            this.dayClose.splice(index, 1)
          }
        }
      } else {
        if (!this.dayClose.includes(dayString)) {
          this.dayClose.push(dayString)
        }
      }
      console.log(this.dayClose)
    },
  },
}
</script>

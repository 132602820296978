<template>
  <div class="relative">
    <div v-if="isLoading" class="mt-8 h-screen">
      <LoadingIndicator v-if="isLoading || isLoadingSave" />
    </div>

    <template v-if="adjustStockPurchaseId !== undefined">
      <div
        v-if="!checkEmptyObject(purchaseOrderDetailData)"
        class="mt-8 flex w-full justify-center space-y-4"
      >
        <div
          class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8 md:w-7/12"
        >
          <div class="flex w-full items-center justify-between text-base">
            <div class="flex space-x-2">
              <CartIcon />
              <span>
                {{ $t('page.purchaseOrder.stockPurchaseCart.cart') }}
              </span>
            </div>
          </div>
          <div v-if="purchaseOrderDetailData.purchase_suppliers.length > 0">
            <div
              v-for="(
                supplierData, key
              ) in purchaseOrderDetailData.purchase_suppliers"
              :key="key"
              class="flex flex-col space-y-2 py-2"
            >
              <div class="flex items-center justify-between">
                <p>{{ supplierData.supplier_name }}</p>
              </div>
              <div
                v-for="(cartData, cartKey) in supplierData.purchase_item"
                :key="cartKey"
                class="flex items-center space-x-2 border-gray-200 pb-2"
                :class="[
                  (supplierData.purchase_item.length > 1 &&
                    cartData.quantity > 0) ||
                  supplierData.purchase_item.length === 1
                    ? 'border-b'
                    : '',
                ]"
              >
                <template
                  v-if="
                    (supplierData.purchase_item.length > 1 &&
                      cartData.quantity > 0) ||
                    supplierData.purchase_item.length === 1
                  "
                >
                  <div class="w-1/12">
                    <ImageLoader
                      :image="cartData.product.image"
                      :alt="cartData.product.name"
                    />
                  </div>
                  <div class="flex w-9/12 flex-col">
                    <p class="font-semibold">{{ cartData.product.name }}</p>
                    <div class="flex space-x-1 text-xs text-gray-500">
                      <Currency
                        :total-in-string="cartData.product.sale_price"
                      />
                      <p>•</p>
                      <p
                        v-if="'master_unit' in cartData.product"
                        class="space-x-1"
                      >
                        <span>Unit :</span>
                        <span class="text-primary">{{
                          cartData.product.master_unit.name
                        }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="w-2/12">
                    <QtyCounter
                      :show-delete="false"
                      :auto-delete="true"
                      :last-qty="cartData.quantity"
                      @delete-item="
                        atcAdjustment(cartData, 'delete', supplierData.id)
                      "
                      @sub-item="
                        atcAdjustment(cartData, 'sub', supplierData.id)
                      "
                      @add-item="
                        atcAdjustment(cartData, 'add', supplierData.id)
                      "
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div v-else class="flex items-center justify-center">
            <div class="flex flex-col items-center space-y-4">
              <CartEmptyIcon />
              <p class="text-center text-lg font-semibold">
                {{ $t('page.purchaseOrder.stockPurchaseCart.noTransaction') }}
              </p>
              <p class="text-center">
                {{ $t('page.purchaseOrder.stockPurchaseCart.clickBelow') }}
              </p>
              <button
                class="rounded-lg bg-primary px-16 py-3 text-lg text-white"
                @click="
                  $router.push({
                    name: 'stock-purchase',
                    params: {
                      slug: $route.params.slug,
                    },
                    query: {
                      lang: $route.query.lang,
                    },
                  })
                "
              >
                {{
                  $t('page.purchaseOrder.stockPurchaseCart.createTransaction')
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          !checkEmptyObject(purchaseOrderDetailData) &&
          purchaseOrderDetailData.purchase_suppliers.length > 0
        "
        class="mt-8 flex w-full justify-center space-y-4"
      >
        <div
          class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8 md:w-7/12"
        >
          <div class="flex w-full items-center justify-between text-base">
            <span>
              {{ $t('page.purchaseOrder.stockPurchaseCart.anotherSetting') }}
            </span>
          </div>
          <div class="flex w-full flex-col space-y-4">
            <div class="grid w-full grid-cols-12 gap-6">
              <div class="col-span-4 text-gray-500">
                <p>
                  {{ $t('page.purchaseOrder.stockPurchaseCart.subtotal') }}
                </p>
              </div>
              <div class="col-span-4 text-gray-500">
                <p>
                  {{
                    $t('page.purchaseOrder.stockPurchaseCart.totalItem', {
                      total: purchaseOrderDetailQtyTotal,
                    })
                  }}
                </p>
              </div>
              <div class="col-span-4 justify-end">
                <Currency
                  :total-in-string="purchaseOrderDetailPriceTotal"
                  wrap-class="font-semibold justify-end"
                />
              </div>
            </div>
            <div
              v-if="transactionFeePercentage > 0"
              class="grid w-full grid-cols-12 gap-6"
            >
              <div class="col-span-4 text-gray-500">
                <p>
                  {{
                    $t('page.purchaseOrder.stockPurchaseCart.transactionFee')
                  }}
                </p>
              </div>
              <div class="col-span-4 text-gray-500">
                <p>{{ transactionFeePercentage }}%</p>
              </div>
              <div class="col-span-4 justify-end">
                <Currency
                  :total-in-string="
                    (purchaseOrderDetailPriceTotal * transactionFeePercentage) /
                    100
                  "
                  wrap-class="font-semibold justify-end"
                />
              </div>
            </div>
            <div
              v-if="!checkEmptyObject(shippingData)"
              class="grid w-full grid-cols-12 gap-6"
            >
              <div class="col-span-4 text-gray-500">
                <p>
                  {{ $t('page.purchaseOrder.stockPurchaseCart.shipping') }}
                </p>
              </div>
              <div class="col-span-4 text-gray-500">
                <p>{{ shippingData.name }}</p>
              </div>
              <div class="col-span-4 justify-end">
                <Currency
                  :total-in-string="
                    shippingData.amount *
                    purchaseOrderDetailData.purchase_suppliers.length
                  "
                  wrap-class="font-semibold justify-end"
                />
              </div>
            </div>
            <div class="w-full border-b"></div>
            <div
              v-if="
                !checkEmptyObject(shippingData) || transactionFeePercentage > 0
              "
              class="flex w-full flex-col space-y-4"
            >
              <div class="grid w-full grid-cols-12 gap-6">
                <div class="col-span-8 justify-end font-semibold">
                  <p class="text-right">
                    {{ $t('page.purchaseOrder.stockPurchaseCart.total') }}:
                  </p>
                </div>
                <div class="col-span-4 justify-end">
                  <Currency
                    :total-in-string="
                      purchaseOrderDetailPriceTotal +
                      shippingData.amount *
                        purchaseOrderDetailData.purchase_suppliers.length +
                      (purchaseOrderDetailPriceTotal *
                        transactionFeePercentage) /
                        100
                    "
                    wrap-class="font-semibold justify-end"
                  />
                </div>
              </div>
            </div>
            <div
              class="flex flex-col items-center justify-end space-y-5 py-2 md:flex-row md:space-x-2 md:space-y-0"
            >
              <div
                class="flex"
                @click="
                  showHistoryDetailPurchaseOrder({
                    id: purchaseOrderDetailData.id,
                  })
                "
              >
                <button type="submit" class="btn">
                  {{ $t('button.back') }}
                </button>
              </div>
              <div class="flex justify-end">
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="purchaseOrderDetailQtyTotal === 0"
                  @click="showModalPurchase = true"
                >
                  {{
                    $t('page.purchaseOrder.stockPurchaseCart.stockAdjustment')
                  }}:
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-if="hasStockPurchaseCart"
        class="mt-8 flex w-full justify-center space-y-4"
      >
        <div
          class="flex w-full flex-col space-y-4 rounded-lg bg-white py-6 px-8 md:w-7/12"
        >
          <div class="flex w-full items-center justify-between text-base">
            <span>
              {{ $t('page.purchaseOrder.stockPurchaseCart.shippingAddress') }}
            </span>
            <div
              v-if="
                userAddressData.length > 0 && activeUserAddress !== undefined
              "
              class="cursor-pointer text-xs text-primary"
              @click="
                $router.push({
                  name: 'stock-purchase-cart-address',
                  params: {
                    slug: $route.params.slug,
                  },
                  query: {
                    lang: $route.query.lang,
                  },
                })
              "
            >
              {{ $t('page.purchaseOrder.stockPurchaseCart.changeAddress') }}
            </div>
            <div
              v-else
              class="cursor-pointer text-xs text-primary"
              @click="
                $router.push({
                  name: 'stock-purchase-cart-address-form',
                  params: {
                    slug: $route.params.slug,
                  },
                  query: {
                    lang: $route.query.lang,
                  },
                })
              "
            >
              {{ $t('page.purchaseOrder.stockPurchaseCart.addNewAddress') }}
            </div>
          </div>
          <div class="flex w-full">
            <div
              v-if="
                userAddressData.length > 0 && activeUserAddress !== undefined
              "
              class="flex w-full flex-col"
            >
              <div class="flex w-full items-center space-x-2">
                <p class="font-semibold">{{ activeUserAddress.label }}</p>
                <p
                  v-if="activeUserAddress.is_primary"
                  class="rounded-lg bg-green-50 p-1 text-green-500"
                >
                  {{
                    $t('page.purchaseOrder.stockPurchaseCart.addressPrimary')
                  }}
                </p>
              </div>
              <div class="flex w-full flex-col">
                <p>{{ activeUserAddress.recipient }}</p>
                <p>{{ activeUserAddress.phone }}</p>
              </div>
              <div class="flex w-full text-left">
                <p>
                  <span>{{ activeUserAddress.detail_address }}</span>
                  <span v-if="'village' in activeUserAddress">
                    ,
                    {{ activeUserAddress.village }}
                  </span>
                  <span v-if="'district' in activeUserAddress">
                    ,
                    {{ activeUserAddress.district }}
                  </span>
                  <span v-if="'city' in activeUserAddress">
                    ,
                    {{ activeUserAddress.city }}
                  </span>
                  <span v-if="'province' in activeUserAddress">
                    ,
                    {{ activeUserAddress.province }}
                  </span>
                  <span v-if="'postal_code' in activeUserAddress">
                    ,
                    {{ activeUserAddress.postal_code }}
                  </span>
                </p>
              </div>
            </div>
            <div v-else class="w-full text-gray-500">
              {{ $t('page.purchaseOrder.stockPurchaseCart.noAddress') }}
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 flex w-full justify-center space-y-4">
        <div
          class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8 md:w-7/12"
        >
          <div class="flex w-full items-center justify-between text-base">
            <div class="flex space-x-2">
              <CartIcon />
              <span>
                {{ $t('page.purchaseOrder.stockPurchaseCart.cart') }}
              </span>
            </div>
          </div>
          <div
            v-if="
              hasStockPurchaseCart &&
              !checkEmptyObject(stockPurchaseCartBySupplier)
            "
          >
            <div
              v-for="(data, key) in stockPurchaseCartBySupplier"
              :key="key"
              class="flex flex-col space-y-2 py-2"
            >
              <div class="flex items-center justify-between">
                <p>{{ splitString(key, '|', 1) }}</p>
                <p
                  class="cursor-pointer text-xs text-primary"
                  @click="showDetailSupplier(splitString(key, '|', 0))"
                >
                  {{ $t('page.purchaseOrder.stockPurchaseCart.addAnother') }}
                </p>
              </div>
              <div
                v-for="(cartData, cartKey) in data.sort((x, y) => {
                  if (x.productId < y.productId) return -1
                  if (x.productId > y.productId) return 1
                  return 0
                })"
                :key="cartKey"
                class="flex items-center space-x-2 border-b border-gray-200 pb-2"
              >
                <div class="w-1/12">
                  <ImageLoader
                    :image="cartData.productData.image"
                    :alt="cartData.productData.name"
                  />
                </div>
                <div class="flex w-9/12 flex-col">
                  <p class="font-semibold">{{ cartData.productData.name }}</p>
                  <div class="flex space-x-1 text-xs text-gray-500">
                    <Currency
                      :total-in-string="cartData.productData.sale_price"
                    />
                    <p>•</p>
                    <p
                      v-if="'master_unit' in cartData.productData"
                      class="space-x-1"
                    >
                      <span>
                        {{ $t('page.purchaseOrder.stockPurchaseCart.unit') }} :
                      </span>
                      <span class="text-primary">{{
                        cartData.productData.master_unit.name
                      }}</span>
                    </p>
                  </div>
                </div>
                <div class="w-2/12">
                  <QtyCounter
                    :show-delete="false"
                    :auto-delete="true"
                    :last-qty="cartData.qty"
                    @delete-item="atc(cartData, 'delete')"
                    @sub-item="atc(cartData, 'sub')"
                    @add-item="atc(cartData, 'add')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="flex items-center justify-center">
            <div class="flex flex-col items-center space-y-4">
              <CartEmptyIcon />
              <p class="text-center text-lg font-semibold">
                {{ $t('page.purchaseOrder.stockPurchaseCart.noTransaction') }}
              </p>
              <p class="text-center">
                {{ $t('page.purchaseOrder.stockPurchaseCart.clickBelow') }}
              </p>
              <button
                class="rounded-lg bg-primary px-16 py-3 text-lg text-white"
                @click="
                  $router.push({
                    name: 'stock-purchase',
                    params: {
                      slug: $route.params.slug,
                    },
                    query: {
                      lang: $route.query.lang,
                    },
                  })
                "
              >
                {{
                  $t('page.purchaseOrder.stockPurchaseCart.createTransaction')
                }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="hasStockPurchaseCart"
        class="mt-8 flex w-full justify-center space-y-4"
      >
        <div
          class="flex w-full flex-col space-y-8 rounded-lg bg-white py-6 px-8 md:w-7/12"
        >
          <div class="flex w-full items-center justify-between text-base">
            <span>Pengaturan Lainnya</span>
          </div>
          <div class="flex w-full flex-col space-y-4">
            <div v-if="isInstitute" class="flex w-full flex-col space-y-1">
              <label>Pilih metode pembayaran</label>
              <select
                v-model="selectedPaymentChannel"
                class="w-full rounded-lg border p-2 pr-10 outline-none hover:border-primary"
              >
                <option
                  v-for="channel in paymentchannelData.filter(
                    (item) => item.name.toLowerCase() === 'cash'
                  )"
                  :key="channel.id"
                  :value="channel"
                >
                  {{ channel.name }}
                </option>
              </select>
            </div>
            <div class="grid w-full grid-cols-12 gap-6">
              <div class="col-span-4 text-gray-500">
                <p>{{ $t('page.purchaseOrder.stockPurchaseCart.subtotal') }}</p>
              </div>
              <div class="col-span-4 text-gray-500">
                <p>
                  {{
                    $t('page.purchaseOrder.stockPurchaseCart.totalItem', {
                      total: stockPurchaseCartQtyTotal,
                    })
                  }}
                </p>
              </div>
              <div class="col-span-4 justify-end">
                <Currency
                  :total-in-string="stockPurchaseCartPriceTotal"
                  wrap-class="font-semibold justify-end"
                />
              </div>
            </div>
            <div
              v-if="transactionFeePercentage > 0"
              class="grid w-full grid-cols-12 gap-6"
            >
              <div class="col-span-4 text-gray-500">
                <p>
                  {{
                    $t('page.purchaseOrder.stockPurchaseCart.transactionFee')
                  }}
                </p>
              </div>
              <div class="col-span-4 text-gray-500">
                <p>{{ transactionFeePercentage }}%</p>
              </div>
              <div class="col-span-4 justify-end">
                <Currency
                  :total-in-string="
                    (stockPurchaseCartPriceTotal * transactionFeePercentage) /
                    100
                  "
                  wrap-class="font-semibold justify-end"
                />
              </div>
            </div>
            <div
              v-if="!checkEmptyObject(shippingData)"
              class="grid w-full grid-cols-12 gap-6"
            >
              <div class="col-span-4 text-gray-500">
                <p>{{ $t('page.purchaseOrder.stockPurchaseCart.shipping') }}</p>
              </div>
              <div class="col-span-4 text-gray-500">
                <p>{{ shippingData.name }}</p>
              </div>
              <div class="col-span-4 justify-end">
                <Currency
                  :total-in-string="
                    shippingData.amount *
                    Object.keys(stockPurchaseCartBySupplier).length
                  "
                  wrap-class="font-semibold justify-end"
                />
              </div>
            </div>
            <div class="w-full border-b"></div>
            <div
              v-if="
                !checkEmptyObject(shippingData) || transactionFeePercentage > 0
              "
              class="flex w-full flex-col space-y-4"
            >
              <div class="grid w-full grid-cols-12 gap-6">
                <div class="col-span-8 justify-end font-semibold">
                  <p class="text-right">
                    {{ $t('page.purchaseOrder.stockPurchaseCart.total') }}:
                  </p>
                </div>
                <div class="col-span-4 justify-end">
                  <Currency
                    :total-in-string="
                      stockPurchaseCartPriceTotal +
                      shippingData.amount *
                        Object.keys(stockPurchaseCartBySupplier).length +
                      (stockPurchaseCartPriceTotal * transactionFeePercentage) /
                        100
                    "
                    wrap-class="font-semibold justify-end"
                  />
                </div>
              </div>
            </div>
            <div
              class="flex flex-col items-center justify-between space-y-5 py-2 md:flex-row md:space-y-0"
            >
              <div class="flex w-full items-center space-x-2 md:w-9/12">
                <InfoIcon class="h-12 w-12 md:h-7 md:w-7" />
                <p class="space-x-1 pr-0 text-justify md:text-left xl:pr-8">
                  <span>{{
                    $t('page.purchaseOrder.stockPurchaseCart.buttonPressed')
                  }}</span>
                  <span v-if="isInstitute" class="font-semibold"
                    >{{
                      $t('page.purchaseOrder.stockPurchaseCart.purchase')
                    }},</span
                  >
                  <span v-else
                    >{{
                      $t('page.purchaseOrder.stockPurchaseCart.submitPurchase')
                    }},</span
                  >
                  <span>{{
                    $t('page.purchaseOrder.stockPurchaseCart.youHaveAgreed')
                  }}</span>
                  <span class="text-primary">{{
                    $t('page.purchaseOrder.stockPurchaseCart.toc')
                  }}</span>
                  <span>{{
                    $t('page.purchaseOrder.stockPurchaseCart.determinedBy')
                  }}</span>
                </p>
              </div>
              <div class="flex w-full justify-end space-x-2 md:w-3/12">
                <button
                  type="submit"
                  class="btn"
                  :disabled="activeUserAddress === undefined"
                  :class="[
                    activeUserAddress === undefined
                      ? 'cursor-not-allowed bg-gray-200'
                      : 'btn-primary',
                  ]"
                  @click="showModalPurchase = true"
                >
                  <span v-if="isInstitute">
                    {{ $t('page.purchaseOrder.stockPurchaseCart.purchase') }}
                  </span>
                  <span v-else>
                    {{
                      $t('page.purchaseOrder.stockPurchaseCart.submitPurchase')
                    }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <Modal :show="showModalPurchase" @hidden="showModalPurchase = false">
      <ModalBody class="p-0">
        <div class="p-5 text-center">
          <div v-if="adjustStockPurchaseId === undefined" class="my-6 text-sm">
            {{ $t('page.purchaseOrder.stockPurchaseCart.areYouSureSubmit') }}
          </div>
          <div v-else>
            {{ $t('page.purchaseOrder.stockPurchaseCart.areYouSureAdjust') }}
          </div>
        </div>
        <div class="px-5 pb-8 text-center">
          <button
            type="button"
            class="btn btn-outline-primary mr-2 w-24"
            @click="showModalPurchase = false"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            v-if="adjustStockPurchaseId === undefined"
            type="button"
            class="btn btn-primary"
            @click="purchase"
          >
            <p v-if="isInstitute">
              {{ $t('page.purchaseOrder.stockPurchaseCart.makePurchase') }}
            </p>
            <p v-else>
              {{ $t('page.purchaseOrder.stockPurchaseCart.applyNow') }}
            </p>
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            @click="adjustPurchase"
          >
            {{ $t('page.purchaseOrder.stockPurchaseCart.makeAdjustment') }}
          </button>
        </div>
      </ModalBody>
    </Modal>

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import stockPurchaseMixin from '@/mixins/stockPurchase.js'
import tenantMixin from '@/mixins/tenant.js'

import QtyCounter from '@/components/QtyCounter.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import Currency from '@/components/Currency.vue'

import CartIcon from '@/assets/svg/cart.svg'
import CartEmptyIcon from '@/assets/svg/empty-cart.svg'

export default {
  name: 'StockPurchaseCart',
  components: {
    QtyCounter,
    Currency,
    ImageLoader,
    CartIcon,
    CartEmptyIcon,
  },
  mixins: [globalMixin, stockPurchaseMixin, tenantMixin],
  data: () => ({
    showModalPurchase: false,
  }),
  computed: {
    purchaseOrderDetailQtyTotal() {
      if (
        !this.checkEmptyObject(this.purchaseOrderDetailData) &&
        this.purchaseOrderDetailData.purchase_suppliers.length > 0
      ) {
        return this.purchaseOrderDetailData.purchase_suppliers.reduce(
          (accumulator, currVal) => {
            currVal.purchase_item.forEach((item) => {
              accumulator += item.quantity
            })
            return accumulator
          },
          0
        )
      } else {
        return 0
      }
    },
    purchaseOrderDetailPriceTotal() {
      if (
        !this.checkEmptyObject(this.purchaseOrderDetailData) &&
        this.purchaseOrderDetailData.purchase_suppliers.length > 0
      ) {
        return this.purchaseOrderDetailData.purchase_suppliers.reduce(
          (accumulator, currVal) => {
            accumulator += currVal.sub_total
            return accumulator
          },
          0
        )
      } else {
        return 0
      }
    },
  },
  mounted() {
    const selectedAddress = this.$store.getters['stockPurchase/selectedAddress']

    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getSettings()
        this.getAllUserAddress(false).then(() => {
          if (this.userAddressData.length > 0) {
            if (selectedAddress) {
              this.activeUserAddress = this.userAddressData.find(
                (item) => item.id === selectedAddress
              )
            } else {
              const primaryUserAddress = this.userAddressData.find(
                (item) => item.is_primary
              )
              if (primaryUserAddress !== undefined) {
                this.activeUserAddress = primaryUserAddress
              } else {
                this.activeUserAddress = this.userAddressData[0]
              }
            }
          }
        })

        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.adjustStockPurchaseId = this.$route.query.from

          this.getSettings()
          if (this.adjustStockPurchaseId === undefined) {
            this.getAllUserAddress(false).then(() => {
              if (this.userAddressData.length > 0) {
                if (selectedAddress) {
                  this.activeUserAddress = this.userAddressData.find(
                    (item) => item.id === selectedAddress
                  )
                } else {
                  const primaryUserAddress = this.userAddressData.find(
                    (item) => item.is_primary
                  )
                  if (primaryUserAddress !== undefined) {
                    this.activeUserAddress = primaryUserAddress
                  } else {
                    this.activeUserAddress = this.userAddressData[0]
                  }
                }
              }
            })
          }
          this.getAllPaymentChannel(false)

          if (this.adjustStockPurchaseId !== undefined) {
            this.getDetailPurchaseOrder(this.adjustStockPurchaseId, true)
          }
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    getSettings() {
      this.getTransactionFee()
      this.getShippingFee()
    },
    purchase() {
      if (this.activeUserAddress !== undefined) {
        const params = {}
        params.tenant_id = this.tenantId
        params.address_id = this.activeUserAddress.id
        params.items = this.stockPurchaseCartItems

        if (
          this.isInstitute &&
          !this.checkEmptyObject(this.selectedPaymentChannel)
        ) {
          params.payment_type = this.selectedPaymentChannel.channel_type
          params.payment_channel_code =
            this.selectedPaymentChannel.payment_channel_code
          params.payment_channel_id = this.selectedPaymentChannel.id
          params.client_reference_channel_id =
            this.selectedPaymentChannel.client_reference_channel_id

          // Purchase
          this.saveStockPurchaseAPI(params)
        } else {
          // Request PO
          this.saveStockPurchaseRequestAPI(params)
        }
      }
    },
    adjustPurchase() {
      if (
        this.adjustStockPurchaseId !== undefined &&
        this.purchaseOrderDetailQtyTotal > 0
      ) {
        const params = {}
        const items = []
        this.purchaseOrderDetailData.purchase_suppliers.forEach(
          (supplierItem) => {
            supplierItem.purchase_item.forEach((purchaseItem) => {
              if (purchaseItem.quantity > 0) {
                items.push({
                  supplier_product_id: purchaseItem.supplier_product_id,
                  quantity: purchaseItem.quantity,
                })
              }
            })
          }
        )

        params.items = items
        params.id = this.adjustStockPurchaseId
        this.adjustStockPurchaseAPI(params)
      }
    },
    atc(params, type = 'add') {
      let qty = params.qty
      if (type === 'add') qty += 1
      else if (type === 'sub') qty -= 1
      else if (type === 'delete') qty = 0

      this.addToCart(
        {
          supplierId: params.supplierId,
          productData: params.productData,
          productId: params.productId,
          qty,
          supplierData: params.supplierData,
        },
        false
      )
    },
    atcAdjustment(params, type = 'add', supplierId = null) {
      let quantity = params.quantity
      if (type === 'add') quantity += 1
      else if (type === 'sub') quantity -= 1
      else if (type === 'delete') quantity = 0

      const purchaseOrderDetailData = this.purchaseOrderDetailData
      purchaseOrderDetailData.purchase_suppliers.forEach((supplierItem) => {
        let subTotal = 0
        if (supplierItem.id === supplierId) {
          supplierItem.purchase_item.forEach((purchaseItem) => {
            if (purchaseItem.id === params.id) {
              // if (quantity === 0) {
              //   purchaseItem = {}
              // } else {
              purchaseItem.quantity = quantity
              purchaseItem.total = purchaseItem.price * quantity
              // }
            }
            if (!isNaN(purchaseItem.total)) subTotal += purchaseItem.total
          })
          supplierItem.sub_total = subTotal
        }
      })
      this.purchaseOrderDetailData = purchaseOrderDetailData
    },
  },
}
</script>

export default {
  data: () => ({
    customerData: [],
    customerTransaction: [],
    customerDetailData: null,
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
  }),
  methods: {
    async getAllCustomer(showMessage = true) {
      await this.$store
        .dispatch('customer/getAll', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: '',
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.customerData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getAllCustomerTransaction(showMessage = true) {
      await this.$store
        .dispatch('customer/getAllCustomerTransaction', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: '',
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.customerTransaction = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getCustomerTransaction(userId, showMessage = true) {
      await this.$store
        .dispatch('customer/getCustomerTransaction', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          user_id: userId,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.customerTransaction = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getCustomerDetail(userId) {
      await this.$store
        .dispatch('customer/getCustomerDetail', {
          user_id: userId,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.customerDetailData = responseData
            this.detailModal = true
          }
          // if (responseData.meta) {
          //   this.metaData = responseData.meta
          // }

          // if (showMessage) {
          //   this.message = response.data.message
          //   setTimeout(() => {
          //     this.showToast('success')
          //   }, 500)
          // }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveCustomerAPI(params, isUpdate = false) {
      let dispatcher = 'customer/create'
      if (isUpdate) dispatcher = 'customer/update'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteCustomer(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('customer/delete', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getCustomerData(false)
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}

<template>
  <div
    class="flex w-full justify-center pt-10 pb-5 text-gray-500"
    :class="[wrapperClass]"
  >
    {{ $t('info.thereIsNoData') }}
  </div>
</template>
<script>
export default {
  name: 'NoDataComponent',
  props: {
    wrapperClass: { type: String, default: '' },
  },
}
</script>

export default {
  appName: 'Dashboard InKanteen',
  language: {
    id: 'ID',
    en: 'EN',
    languages: 'Bahasa',
    change: 'Ubah Bahasa',
    bahasa: 'Indonesia',
    english: 'Inggris',
  },
  day: {
    0: 'Minggu',
    1: 'Senin',
    2: 'Selasa',
    3: 'Rabu',
    4: 'Kamis',
    5: "Jum'at",
    6: 'Sabtu',
  },
  menu: {
    home: 'Beranda',
    account: 'Akun',
    dashboard: 'Dashboard',
    report: {
      index: 'Laporan',
      summary: 'Ringkasan Penjualan',
      compareTenant: 'Compare Tenant',
      orderDetail: 'Detail Penjualan',
      tenantSales: 'Penjualan Tenant',
      dailySales: 'Penjualan Harian',
      productSales: 'Penjualan Produk',
      paymentMethod: 'Laporan Metode Pembayaran',
      withdrawal: 'Laporan Withdrawal',
      promotion: 'Laporan Promo',
      nonSalesDetail: 'Detail Non Penjualan',
      additionalFee: 'Pajak & Biaya Tambahan',
    },
    analytic: {
      index: 'Analisis',
      averageTransaction: 'Transaksi Rerata',
      growthPercentage: 'Persentase Pertumbuhan',
      stockTurnOver: 'Perputaran Stok',
      omnichannelIndex: 'Indeks OmniChannel',
      satisfactionIndex: 'Indeks Kepuasan',
    },
    profile: {
      index: 'Profil',
      account: 'Informasi Akun',
      bussiness: 'Informasi Bisnis',
    },
    balance: {
      index: 'Laporan Balance',
    },
    cashbook: {
      index: 'Buku Kas',
      bankList: 'Buku Kas dan Bank',
      addBankList: 'Tambah Kas dan Bank',
      detailBankList: 'Detail Buku Kas dan Bank',
      transferList: 'Daftar Transfer',
      accountList: 'Daftar Akun',
    },
    income: {
      index: 'Pemasukan',
      incomeList: 'List Pemasukan',
      incomeReconciliation: 'Rekonsiliasi Pemasukan',
      tenantBillList: 'Daftar Tagihan Tenant',
    },
    expense: {
      index: 'Pengeluaran',
      expenseList: 'Daftar Pengeluaran',
      routineBillingList: 'Daftar Tagihan Rutin',
      paymentList: 'Daftar Pembayaran',
      costList: 'Daftar Biaya',
      partnerList: 'Daftar Mitra',
    },
    financeReport: {
      index: 'Laporan Keuangan',
      balanceSheet: 'Laporan Neraca',
      incomeStatement: 'Laporan Laba Rugi',
      cashflowStatement: 'Laporan Arus Kas',
      debtReport: 'Laporan Hutang',
    },
    product: {
      index: 'Produk',
      productList: 'List Produk',
      addProduct: 'Tambahkan Produk',
      ingredients: 'List Bahan',
      addIngredients: 'Tambah Jenis Bahan',
      ingredientBrands: 'List Brand Bahan',
      addIngredientBrands: 'Tambah Brand Bahan',
      ingredientCategories: 'List Kategori Bahan',
      addIngredientCategories: 'Tambah Kategori Bahan',
      variant: 'List Varian',
    },
    employee: {
      title: 'Manajemen Karyawan',
      index: 'Daftar Karyawan',
      add: 'Tambah Karyawan',
      access: 'Daftar Hak Akses Web',
      privilege: 'Daftar Hak Akses Privilege',
      addPrivilege: 'Tambah Hak Akses',
    },
    setting: {
      index: 'Pengaturan',
      tableManagement: 'Manajemen Meja',
      kiosk: 'Manajemen KiosK',
      payment: 'Pembayaran',
      additionalFee: 'Biaya Tambahan',
      omnichannel: 'Omni Channel',
      paymentChannel: 'Payment Channel',
      shift: 'Riwayat Shift',
      officeHour: 'Office Hour',
      inventory: 'Inventory',
      inventoryAdjustment: 'Penyesuaian Inventory',
      addInventoryAdjustment: 'Tambah Penyesuaian',
      pantry: 'Pantry',
      inventoryList: 'Daftar Inventori',
      unitList: 'List Unit',
      categoryList: 'List Kategori',
      brandList: 'List Brand',
      productList: 'List Produk',
      inventoryManagement: 'Manajemen Inventori',
      stockAdjustment: 'Stock Adjustment',
      stockPurchase: 'Stock Purchase',
      supplier: 'Supplier',
    },
    purchaseOrder: {
      historyOrder: 'Riwayat Pesanan',
      stockPurchase: 'Stock Purchase',
      stockPurchaseSupplier: 'Daftar Supplier',
    },
  },
  action: {
    action: 'Aksi',
    edit: 'Edit',
    delete: 'Hapus',
    add: 'Tambah Data',
    logout: 'Keluar',
    reloadData: 'Muat Ulang Data',
    exportCSV: 'Export CSV',
    export: 'Export',
  },
  topBarMenu: {
    sale: 'Penjualan',
    finance: 'Keuangan',
    setting: 'Pengaturan',
    purchaseOrder: 'Stock Purchase',
    profile: 'Profil',
  },
  page: {
    dashboard: {
      sale: 'Penjualan',
      saleChart: 'Grafik Penjualan',
      grossProfit: 'Laba Kotor',
      grossSales: 'Penjualan Kotor',
      netProfit: 'Laba Bersih',
      netSales: 'Penjualan Bersih',
      transactionTotal: 'Total Transaksi',
      bestSellingMenu: 'Menu Terlaris',
      leastSellingMenu: 'Menu paling sedikit terjual',
      peakHour: 'Peak Hour',
      daySales: 'Day of the week sales',
      paymentMethod: 'Metode Pembayaran',
      higherThanYesterday: 'Lebih tinggi dari kemarin',
      lowerThanYesterday: 'Lebih rendah dari kemarin',
      sameAsYesterday: 'Sama dengan kemarin',
      thanMonth: 'Dibanding bulan lalu',
    },
    dashboardSuperInstitute: {
      performanceSummary: 'Ringkasan Performa',
      openTenant: 'Tenant Buka',
      closeTenant: 'Tenant Tutup',
      openBills: 'Open Bills',
      seeTenant: 'Laporan per tenant',
      totalTenant: '%{total} Tenants',
      totalOrder: '%{total} Pesanan',
      statusFoodCourt: 'Status Foodcourt',

      totalSales: 'Total Sales',
      totalTransaction: 'Total Transaction',
      newCustomer: 'Pelanggan Baru',
      repeatOrder: 'Repeat Orders',

      trendingMenu: 'Trending Menu',
      topTenant: 'Top Tenant',

      peakHour: 'Peak Hour',
      daySales: 'Day of the week sales',
      higherThanYesterday: 'Lebih tinggi dari kemarin',
      lowerThanYesterday: 'Lebih rendah dari kemarin',
      sameAsYesterday: 'Sama dengan kemarin',
      thanMonth: 'Dibanding bulan lalu',

      tooltips: {
        trendingMenu:
          'Menu paling sering dipesan dari total penjualan seluruh produk',
        topTenant: 'Tenant dengan jumlah transaksi terbanyak',
        peakHour:
          'Tingkat keramaian pengunjung di setiap jam berdasarkan jumlah transaksi',
        daySales: 'Grafik tinggi penjualan per hari setiap minggu',
      },
    },
    profile: {
      index: 'Profil',
      account: 'Akun Profil',
      accessInformation: 'Informasi Hak Akses',
      addressInformation: 'Informasi Alamat',
      changePassword: 'Ubah Kata Sandi',
      accessRight: 'Hak Akses',
    },
    bussiness: {
      index: 'Bisnis',
      information: 'Informasi Bisnis',
      addressInformation: 'Informasi Alamat',
      socialMedia: 'Media Sosial',
    },
    report: {
      addComparison: 'Tambah Pembanding',
      summary: {
        title: 'Ringkasan Penjualan',
        income: {
          title: 'Pendapatan',
          grossSales: 'Penjualan Kotor',
          sales: 'Penjualan Bersih',
          shippingCost: 'Ongkos kirim',
          // serviceCharge: 'Service charge',
          serviceCharge: 'Service Charge',
          // adminFee: 'Admin Fee',
          // instituteFee: 'Institute Fee',
          instituteFee: 'Food Court Fee',
          serviceChargeMDR: 'Service charge MDR',
          rounding: 'Pembulatan',
          tax: 'Pajak',
          uniqueCode: 'Kode Unik',
          other: 'Lainnya',
        },
        promoCost: {
          title: 'Biaya Promo',
          nonComplement: 'Non komplimen',
          promo: 'Total Biaya Promo',
          purchase: 'Pembelian',
          product: 'Produk',
          complement: 'Komplimen',
          totalProduct: 'Total Product',
          totalQuantity: 'Total Quantity',
          totalProductCost: 'Total Biaya Product',
          coveredByMerchant: 'Covered by Merchant',
          coveredByInkanteen: 'Covered by Inkanteen',
        },
        other: {
          title: 'Lain-lain',
          MDR: 'MDR Fee',
          totalRefund: 'Total Refund',
          serviceCharge: 'Service charge',
          adminFee: 'Admin Fee',
          instituteFee: 'Institute Fee',
          HPP: 'HPP',
          rounding: 'Pembulatan',
          tax: 'Pajak',
          uniqueCode: 'Kode Unik',
          other: 'Lainnya',
          adminFee: 'Inkanteen Fee',
        },
        total: 'Total',
        comparison: {
          selectTenant: 'PILIH TENANT',
          compareTenant: 'Compare tenant',
          selectTenantToCompare: 'Pilih tenant untuk dibandingkan',
        },
      },
      orderDetail: {
        title: 'Detail Penjualan',
        totalSalesGross: 'Total Penjualan Kotor',
        totalSales: 'Total Penjualan Bersih',
        totalPurchase: 'Total Pembayaran',
        totalTransaction: 'Total Transaksi',
        orderDetailTable: 'Tabel Detail Penjualan',
        tableHeaders: {
          orderNo: 'No. Pesananan',
          customerName: 'Nama Pelanggan',
          time: 'Waktu',
          tenant: 'Tenant',
          paymentMethod: 'Metode Pembayaran',
          totalPay: 'Total Pembayaran',
          orderDetail: 'Detail Pesanan',
          notes: 'Catatan',
          discount: 'Diskon',
          adminFee: 'Admin Fee',
          taxFee: 'Tax Fee',
          serviceFee: 'Service Fee',
          instituteFee: 'Institute Fee',
          mdrFee: 'MDR Fee',
          uniqueCode: 'Unique Code',
          discount: 'Discount',
          total: 'Total',
        },
      },
      nonSalesDetail: {
        insight:
          'Transaksi non penjualan adalah transaksi yang tidak terdapat uang masuk/keluar.',
        title: 'Detail Non Penjualan',
        totalNominal: 'Total Nominal',
        totalTransaction: 'Total Transaksi',
        nonSalesDetailTable: 'Tabel Detail Non Penjualan',
        tableHeaders: {
          orderNo: 'No. Pesananan',
          customerName: 'Nama Pelanggan',
          time: 'Waktu',
          tenant: 'Tenant',
          paymentMethod: 'Metode Pembayaran',
          totalPay: 'Total Pembayaran',
          nonSalesDetail: 'Detail Pesanan',
          discount: 'Diskon',
          adminFee: 'Admin Fee',
          taxFee: 'Tax Fee',
          serviceFee: 'Service Fee',
          instituteFee: 'Institute Fee',
          mdrFee: 'MDR Fee',
          uniqueCode: 'Unique Code',
          discount: 'Discount',
          total: 'Total',
        },
      },
      tenantSales: {
        title: 'Penjualan Tenant',
        totalSalesGross: 'Total Penjualan Kotor',
        totalSales: 'Total Penjualan Bersih',
        totalPay: 'Total Pembayaran',
        grossProfit: 'Laba Kotor',
        totalTransaction: 'Total Transaksi',
        totalProduct: 'Total Produk',
        tenantSalesTable: 'Tabel Penjualan Tenant',
        tenantTable: 'Pilih Tenant Sebagai Pembanding',
        tableHeaders: {
          tenant: 'Tenant',
          totalSales: 'Total Penjualan',
          percentSales: 'Penjualan %',
          totalTransaction: 'Jumlah Transaksi',
          percentTransaction: 'Transaksi %',
        },
      },
      dailySales: {
        title: 'Penjualan Harian',
        salesGross: 'Penjualan Kotor',
        sales: 'Penjualan Bersih',
        totalSalesGross: 'Total Penjualan Kotor',
        totalSales: 'Total Penjualan Bersih',
        totalPay: 'Total Pembayaran',
        grossProfit: 'Laba Kotor',
        transaction: 'Transaksi',
        totalTransaction: 'Total Transaksi',
        product: 'Produk',
        totalProduct: 'Total Produk',
        dailySalesTable: 'Tabel Penjualan Harian',
        tableHeaders: {
          time: 'Waktu',
          sales: 'Penjualan',
          grossProfit: 'Laba Kotor',
          totalTransaction: 'Jumlah Transaksi',
          totalProduct: 'Total Produk',
        },
      },
      additionalFee: {
        title: 'Laporan Pajak dan Service Charge',
        totalTax: 'Total Tax',
        totalService: 'Total Layanan',
        instituteCharge: 'Institute Charge',
        mdrFee: 'MDR Fee',
        inkanteenFee: 'Inkanteen Fee',
        other: 'Lainnya',
        additionalFeeTable: 'Tabel Laporan Biaya Tambahan',
        info: {
          take: 'Diambil',
          perItem: 'per item',
          beforeDiscount: 'sebelum diskon',
          afterDiscount: 'setelah diskon',
          includePrice: 'termasuk harga',
        },
        tableHeaders: {
          orderNumber: 'No. Order',
          totalCost: 'Total Biaya',
          tax: 'Pajak',
          service: 'Layanan',
          institute: 'Institute',
          mdrFee: 'MDR Fee',
          inkanteenFee: 'Inkanteen Fee',
          other: 'Lainnya',
        },
      },
      productSales: {
        title: 'Penjualan Produk',
        totalSales: 'Total Penjualan',
        totalSalesNett: 'Total Penjualan Bersih',
        totalPay: 'Total Pembayaran',
        grossProfit: 'Laba Kotor',
        totalTransaction: 'Total Transaksi',
        totalProduct: 'Total Produk',
        productSalesTable: 'Tabel Penjualan Produk',
        productTable: 'Pilih Produk Sebagai Pembanding',
        tableHeaders: {
          product: 'Produk',
          category: 'Kategori',
          totalSale: 'Total Terjual',
          totalPercent: 'Jumlah %',
          sales: 'Penjualan',
          percentSales: 'Penjualan %',
          grossProfit: 'Laba Kotor',
        },
      },
      paymentMethod: {
        title: 'Laporan Metode Pembayaran',
        totalTransaction: 'Total Transaksi',
        totalSales: 'Total Penjualan',
        totalPayment: 'Total Pembayaran',
        paymentMethodTable: 'Tabel Metode Pembayaran',
        tableHeaders: {
          paymentMethod: 'Metode Pembayaran',
          totalTransaction: 'Jumlah Transaksi',
          totalPercent: 'Jumlah %',
          sales: 'Penjualan',
          salesPercent: 'Penjualan %',
        },
      },
      withdrawal: {
        todaySales: 'Penjualan Hari Ini',
        totalSales: 'Total Penjualan',
        balance: 'Saldo Ditarik',
        dailySales: 'Penjualan Harian',
        tableHeaders: {
          time: 'Waktu',
          sales: 'Penjualan',
          grossProfit: 'Laba Kotor',
          totalTransactions: 'Jumlah Transaksi',
          totalProducts: 'Jumlah Produk',
          totalWithdrawal: 'Jumlah Withdraw',
          withdrawalMethod: 'Metode Withdraw',
          totalTransfered: 'Total Ditransfer',
          mdrFee: 'MDR Fee',
          posAdminFee: 'POS Administration Fee',
          adminFee: 'Admin Fee',
          sendTo: 'Dikirim ke',
          status: 'Status',
        },
        withdrawalReport: 'Laporan Withdrawal',
      },
    },
    analytic: {
      averageTransaction: {
        value: 'Nilai transaksi rerata',
        sales: 'Total penjualan',
        salesDetal: 'Detail penjualan',
        totalTransaction: 'Total transaksi',
        tableHeaders: {
          customerName: 'Nama Pelanggan',
          time: 'Waktu',
          orderNo: 'No. Order',
          paymentMethod: 'Metode Pembayaran',
          paymentTotal: 'Total Bayar',
        },
      },
      growthPercentage: {
        title: 'Persentase Pertumbuhan',
        thisMonthSale: 'Penjualan Bulan Ini',
        lastMonthSale: 'Penjualan Bulan Lalu',
        growthMoM: 'Pertumbuhan MoM',
        growthMoMPercentage: 'Persentase Pertumbuhan MoM',
      },
      stockTurnOver: {
        title: 'Analisa Perputaran Stok',
        saleStock: 'Stok Terjual',
        sale: 'Penjualan',
        tableTitle: 'Tabel Perputaran Stok',
        searchStock: 'Cari stok',
        tableHeaders: {
          stockName: 'Nama Stok',
          category: 'Kategori',
          sale: 'Terjual',
          stock: 'Sisa',
          saleTotal: 'Jumlah Terjual',
        },
      },
      omnichannelIndex: {
        title: 'Indeks OmniChannel',
        saleStock: 'Stok Terjual',
        sale: 'Total Penjualan',
        grossProfit: 'Laba Kotor',
        netProfit: 'Laba Bersih',
        totalTransaction: 'Total Transaksi',
        omnichannelIndexTable: 'Tabel Indeks OmniChannel',
        tableHeaders: {
          omnichannelName: 'Nama OmniChannel',
          totalTransaction: 'Jumlah Transaksi',
          percentTransaction: '% Transaksi',
          productSold: 'Produk Terjual',
          sale: 'Penjualan',
        },
      },
      satisfactionIndex: {
        totalTransaction: 'Total transaksi',
        totalReview: 'Total ulasan masuk',
        userReview: 'Ulasan Pelanggan',
        tableHeaders: {
          customerName: 'Nama Pelanggan',
          time: 'Waktu',
          satisficationLevel: 'Tingkat Kepuasan',
          review: 'Ulasan',
        },
        satisfactionLevel: {
          verySatisfied: 'Puas Sekali',
          satisfied: 'Puas',
          aLittleSatisfied: 'Sedikit Puas',
          notSatisfied: 'Tidak Puas',
          veryNotSatisfied: 'Tidak Puas Sekali',
          noComment: 'No Comment',
        },
      },
    },
    balance: {
      totalBalance: 'Total Balance',
      cashOnHand: 'Cash On Hand',
      totalIncome: 'Total Income',
      omniPayment: 'Omni-Payment',

      balanceTransaction: 'Transaksi Balance',
      incomingBalance: 'Balance Masuk',
      outcomingBalance: 'Balance Keluar',
      tableHeaders: {
        transactionDate: 'Tanggal Transaksi',
        transactionChannel: 'Channel',
        transactionTotal: 'Jumlah Transaksi',
        transactionType: 'Jenis Transaksi',
      },
    },
    cashbook: {
      bankList: {
        index: 'Kas dan Bank',
        addTransaction: 'Tambah Transaksi',
        addButton: 'Tambah Kas dan Bank',
        tableHeaders: {
          accountCode: 'Kode Akun',
          accountType: 'Tipe',
          accountName: 'Nama Akun',
          accountCategory: 'Kategori Akun',
          finalBalance: 'Saldo Akhir',
        },
        tableDetailHeaders: {
          time: 'Waktu',
          transactionNo: 'No. Transaksi',
          detail: 'Keterangan',
          debit: 'Debit',
          credit: 'Kredit',
          balance: 'Saldo',
        },
        fields: {
          accountCode: 'Kode Akun',
          accountType: 'Tipe',
          accountName: 'Nama Akun',
          accountCategory: 'Kategori',
          accountActivation: 'Aktifkan Akun',
          detailFrom: 'Detail Dari',
        },
      },
      transferList: {
        index: 'Daftar Transfer',
        addButton: 'Tambah Transfer',
        done: 'Selesai',
        draft: 'Draft',
        tableHeaders: {
          transactionNo: 'No. Transaksi',
          date: 'Tanggal',
          tenant: 'Tenant',
          sender: 'Pengirim',
          receiver: 'Penerima',
          total: 'Jumlah',
        },
      },
    },
    tenantBill: {
      add: 'Tambah Tagihan Tenant',
      all: 'Semua',
      alreadyPaid: 'Sudah Bayar',
      notYetPaid: 'Belum Bayar',
      tableHeaders: {
        title: 'Daftar Tagihan Tenant',
        tenantName: 'Nama Tenant',
        billDate: 'Tanggal Tagihan',
        paymentSystem: 'Sistem Pembayaran',
        total: 'Jumlah Pembayaran',
        status: 'Status',
      },
      forms: {
        title: 'Tambah Tagihan Tenant',
        editTitle: 'Edit Tagihan Tenant',
        tenantName: 'Nama Tenant',
        tenantNamePlaceholder: 'Pilih Nama Tenant',
        whatsAppNumber: 'Nomor WhatsApp',
        whatsAppNumberPlaceholder: '08xxxx',
        totalBill: 'Jumlah Pembayaran',
        totalBillPlaceholder: 'Rp. 0',
        paymentSystem: 'Sistem Pembayaran',
        payPerMonth: 'Bayar Perbulan',
        profitSharing: 'Bagi Hasil',
        billDate: 'Tanggal Tagihan',
        billDatePlaceholder: 'Pilih tanggal',
        tenantWarning: 'Beri peringatan ke Tenant',
        tenantWarningPlaceholder: 'Peringatan',
      },
    },
    expense: {
      add: 'Tambah Pengeluaran',
      alreadyPaid: 'Sudah Bayar',
      notYetPaid: 'Belum Bayar',
      tableHeaders: {
        title: 'Daftar Pengeluaran',
        no: 'No. Transaksi',
        name: 'Nama Pengeluaran',
        total: 'Jumlah',
        note: 'Catatan',
      },
      forms: {
        title: 'Tambah Daftar Pengeluaran',
        editTitle: 'Edit Daftar Pengeluaran',
        expenseName: 'Nama Pengeluaran',
        expenseNamePlaceholder: 'Nama Pengeluaran',
        expenseNumber: 'No. Transaksi',
        expenseNumberPlaceholder: 'INK-1001',
        expenseNumberDescription:
          '*jika dikosongkan, akan otomatis terisi oleh sistem',
        expenseDate: 'Tanggal Transaksi',
        expenseDatePlaceholder: 'Pilih tanggal',
        expenseTime: 'Waktu Transaksi',
        expenseTimePlaceholder: 'Set waktu',
        expenseTotal: 'Jumlah',
        expenseTotalPlaceholder: 'Rp. 0',
        expenseNote: 'Catatan',
        expenseNotePlaceholder: 'Isi catatan disini...',
      },
    },
    routineBilling: {
      add: 'Tambah Tagihan Rutin',
      alreadyPaid: 'Sudah Bayar',
      notYetPaid: 'Belum Bayar',
      tableHeaders: {
        title: 'Daftar Tagihan Rutin',
        name: 'Nama Tagihan',
        dueDate: 'Tanggal Jatuh Tempo',
        total: 'Jumlah',
        customerId: 'ID Pelanggan',
        accountName: 'Nama Rekening',
        accountNumber: 'Nomor Rekening',
      },
      forms: {
        title: 'Tambah Tagihan Rutin',
        editTitle: 'Edit Tagihan Rutin',
        name: 'Nama Tagihan',
        namePlaceholder: 'Nama Tagihan',
        partnerName: 'Nama Mitra',
        partnerNamePlaceholder: 'Nama Mitra',
        total: 'Jumlah Tagihan',
        totalPlaceholder: 'Rp. 0',
        isAutomaticBilling: 'Jadikan Tagihan Otomatis',
        dueDate: 'Tanggal Jatuh Tempo',
        dueDatePlaceholder: 'Pilih tanggal',
        customerId: 'ID Pelanggan',
        customerIdPlaceholder: 'cth. 87001234',
        addAccount: 'Tambah Rekening',
        accountName: 'Nama Rekening',
        accountNamePlaceholder: 'Nama Rekening',
        accountNumber: 'Nomor Rekening',
        accountNumberPlaceholder: 'Nomor Rekening',
      },
    },
    product: {
      productList: {
        title: 'List Produk',
        tableHeaders: {
          name: 'Nama Produk',
          stock: 'Jumlah Stok',
          price: 'Harga Produk',
          ingredients: 'Jumlah Bahan',
          availability: 'Ketersediaan',
        },
        detail: {
          tableHeaders: {
            name: 'Nama Bahan',
            stock: 'Stok Bahan',
            type: 'Jenis',
            ingredients: 'Jumlah Bahan digunakan',
          },
        },
      },
      ingredientList: {
        title: 'List Bahan',
        tableHeaders: {
          name: 'Nama Bahan',
          unit: 'Unit',
          stock: 'Jumlah Stok',
          price: 'Harga Bahan per Satuan',
        },
      },
      ingredientBrandList: {
        title: 'List Brand Bahan',
        tableHeaders: {
          name: 'Nama Brand',
          description: 'Deskripsi',
        },
        forms: {
          addBrand: 'Tambah Brand',
          editBrand: 'Edit Brand',
          name: 'Nama Brand',
          namePlaceholder: 'Masukkan nama brand',
          description: 'Deskripsi',
          descriptionPlaceholder: 'Masukkan deskripsi brand',
        },
      },
      ingredientCategoryList: {
        title: 'List Kategori Bahan',
        tableHeaders: {
          name: 'Nama Kategori',
          categoryType: 'Jenis Kategori',
          description: 'Deskripsi',
        },
        forms: {
          addCategory: 'Tambah Kategori',
          editCategory: 'Edit Kategori',
          name: 'Nama Kategori',
          namePlaceholder: 'Masukkan nama kategori',
          categoryType: 'Jenis Kategori',
          categoryTypePlaceholder: 'Pilih jenis kategori',
          description: 'Deskripsi',
          descriptionPlaceholder: 'Masukkan deskripsi kategori',
        },
      },
      variantList: {
        title: 'List Variant',
        edit: 'Edit Variant',
        tableHeaders: {
          name: 'Nama Variant',
          subVariant: 'Sub-Variant',
          totalProduct: 'Jumlah Produk Terhubung',
          action: 'Action',
        },
      },
    },
    retail: {
      unitList: {
        title: 'Daftar Unit',
        addButton: 'Tambahkan Unit',
        tableHeaders: {
          name: 'Nama Unit',
          description: 'Deskripsi',
          decimalTotal: 'Jumlah desimal',
        },
        forms: {
          title: 'Tambah Unit',
          editTitle: 'Edit Unit',
          name: 'Nama Unit',
          namePlaceholder: 'Masukkan nama unit',
          description: 'Deskripsi',
          descriptionPlaceholder: 'Masukkan deskripsi unit',
          decimalTotal: 'Jumlah desimal',
        },
      },
      categoryList: {
        title: 'List Kategori',
        addButton: 'Tambahkan Kategori',
        tableHeaders: {
          name: 'Nama Kategori',
          description: 'Deskripsi',
        },
        forms: {
          addCategory: 'Tambah Kategori',
          editCategory: 'Edit Kategori',
          name: 'Nama Kategori',
          namePlaceholder: 'Masukkan nama kategori',
          description: 'Deskripsi',
          descriptionPlaceholder: 'Masukkan deskripsi kategori',
        },
      },
      brandList: {
        title: 'List Brand',
        addButton: 'Tambahkan Brand',
        tableHeaders: {
          name: 'Nama Brand',
          description: 'Deskripsi',
        },
        forms: {
          title: 'Tambah Brand',
          editTitle: 'Edit Brand',
          name: 'Nama Brand',
          namePlaceholder: 'Masukkan nama brand',
          description: 'Deskripsi',
          descriptionPlaceholder: 'Masukkan deskripsi brand',
        },
      },
      productList: {
        title: 'Daftar Produk',
        addButton: 'Tambahkan Produk',
        tableHeaders: {
          name: 'Nama Produk',
          brandName: 'Nama Brand',
          category: 'Kategori',
          sellPrice: 'Harga Jual/Unit',
          productTotal: 'Jumlah Produk',
          productUnit: 'Unit Produk',
        },
        forms: {
          title: 'Tambah Produk',
          editTitle: 'Edit Produk',
          name: 'Nama Produk',
          namePlaceholder: 'Masukkan nama produk',
          barcode: 'SKU/Barcode',
          barcodePlaceholder: 'Masukkan SKU/barcode',
          brandName: 'Brand Produk',
          brandNamePlaceholder: 'Pilih brand',
          category: 'Kategori',
          categoryPlaceholder: 'Pilih kategori',
          productUnit: 'Unit',
          productUnitPlaceholder: 'Pilih unit',
          basePrice: 'Harga Beli/Unit',
          basePricePlaceholder: 'Rp0',
          sellPrice: 'Harga Jual/Unit',
          sellPricePlaceholder: 'Rp0',
          initialStock: 'Jumlah Produk Sekarang',
          initialStockPlaceholder: 'Jumlah produk sekarang',
          alertStock: 'Peringatan Produk',
          alertStockPlaceholder: 'Peringatan produk',
          alertStockDesc:
            'Anda akan mendapatkan pemberitahuan ketika stok produk di bawah angka peringatan',
        },
      },
      supplierList: {
        title: 'Daftar Supplier',
        addButton: 'Tambahkan Supplier',
        tableHeaders: {
          name: 'Nama Supplier',
          phoneNumber: 'Nomor HP',
          supplierAddress: 'Alamat Supplier',
        },
        forms: {
          title: 'Tambah Supplier',
          editTitle: 'Edit Supplier',
          name: 'Nama Supplier',
          namePlaceholder: 'Masukkan nama supplier',
          phoneNumber: 'Nomor HP',
          phoneNumberPlaceholder: 'Masukkan nomor HP supplier',
          supplierAddress: 'Alamat Supplier',
          supplierAddressPlaceholder:
            'Masukkan alamat supplier (Jalan, RT/RW, Nomor, Kelurahan, dll.)',
          city: 'Kota',
          cityPlaceholder: 'Pilih kota',
          province: 'Provinsi',
          provincePlaceholder: 'Pilih provinsi',
          postalCode: 'Kode Pos',
          postalCodePlaceholder: 'Masukkan kode pos',
        },
      },
      simpleStockPurchase: {
        title: 'Stock Purchase',
        addButton: 'Buat Stock Purchase',
        search: 'Cari no. purchase..',
        tableHeaders: {
          purchaseNo: 'Nomor Purchase',
          date: 'Tanggal',
          productTotal: 'Jumlah Produk',
          itemTotal: 'Jumlah Item',
          purchaseTotal: 'Jumlah Pembelian',
        },
        forms: {
          title: 'Buat Stock Purchase',
          supplier: 'Supplier',
          supplierPlaceholder: 'Pilih supplier',
          note: 'Catatan',
          notePlaceholder: 'Masukkan catatan',
          tableHeaders: {
            productName: 'Nama Produk',
            stockTotal: 'Jmlh Stok',
            inv: '(inv.)',
            in: '(masuk)',
            total: 'Total',
            price: 'Biaya/unit',
          },
          total: 'Total',
          addProduct: 'Tambah produk',
          createPurchase: 'Buat Purchase',
        },
      },
      stockAdjustment: {
        title: 'Stock Adjustment',
        addButton: 'Buat Adjustment',
        search: 'Cari adjustment..',
        tableHeaders: {
          adjustmentNo: 'Nomor Adjustment',
          date: 'Tanggal',
          reason: 'Alasan',
          itemTotal: 'Jumlah Item',
        },
        forms: {
          title: 'Buat Adjustment',
          reason: 'Alasan',
          reasonPlaceholder: 'Pilih alasan',
          reasonDetail: 'Detail alasan',
          reasonDetailPlaceholder: 'Pilih detail alasan',
          note: 'Catatan',
          notePlaceholder: 'Masukkan catatan',
          tableHeaders: {
            productName: 'Nama Produk',
            stockTotal: 'Jmlh Stok',
            inv: '(inv.)',
            in: '(masuk)',
            out: '(keluar)',
            new: '(baru)',
          },
          addProduct: 'Tambah produk',
          createAdjustment: 'Buat Adjustment',
        },
      },
    },
    setting: 'Pengaturan',
    employee: {
      employeeList: {
        title: 'Karyawan Terdaftar',
        tableHeaders: {
          name: 'Nama Karyawan',
          NIK: 'ID Karyawan',
          tenantName: 'Nama Tenant',
          posision: 'Posisi',
          access: 'Hak Akses',
        },
      },
      addEmployee: {
        title: 'Form Tambah Karyawan',
      },
      employeeAccess: {
        title: 'Daftar Hak Akses',
        tableHeaders: {
          name: 'Nama Hak Akses',
          description: 'Deskripsi Hak Akses',
          rank: 'Rank',
        },
      },
      employeePrivilege: {
        title: 'Akses Privilege',
        tableHeaders: {
          name: 'Nama Privilege',
          description: 'Deskripsi Privilege',
        },
      },
    },
    kiosk: {
      modal: {
        add: 'Add Kiosk',
        edit: 'Edit Kiosk',
        info: 'Informasi Login KiosK',
        infoDesc:
          'Informasi Login ini hanya akan tampil sekali. Pastikan untuk menyimpan informasi ini sebaik mungkin',
      },
      input: {
        name: 'Nama',
        code: 'Kode',
        user: 'User / Email',
        password: 'Password',
        placeholder: {
          name: 'Nama KiosK',
          code: 'Kode KiosK',
        },
      },
      tableHeaders: {
        name: 'Nama',
        institute: 'Institusi',
        code: 'Kode',
      },
    },
    customer: {
      modal: {
        add: 'Data Diri Pelanggan',
        detail: 'Detail Pelanggan',
        edit: 'Edit Customer',

        totalVisit: 'Total Visit',
        lastVisit: 'Last Visit',
        totalSpending: 'Total Spending',
        memberSince: 'Member Since',
        statusSocial: 'Status Social',
        points: '%{point} Points',
        point: 'Points',
      },
      input: {
        name: 'Nama*',
        phoneNumber: 'Nomor HP (Opsional)',
        placeholder: {
          name: 'Nama Pelanggan',
          phoneNumber: 'Nomor HP',
        },
      },
      tableHeaders: {
        name: 'Nama Pelanggan',
        phoneNumber: 'Nomor HP',
        totalTransaction: 'Total Transaksi',
        lastTransaction: 'Transaksi Terakhir',
      },
    },
    tableManagement: {
      index: 'Manajemen Meja',
      addTable: 'Tambah Meja',
      segmentation: 'Segmentasi %{segmen}',
      withoutSegmentation: 'Tanpa Segmentasi',
      input: {
        add: 'Tambah Meja Baru',
        addMultiple: 'Add Multiple Table Number',
        segmentedTable: 'Segmented Table',
        tableSegmentation: 'Segmentasi Meja',
        from: 'Dari',
        to: 'Sampai',
        addSegmentation: 'Tambah segmentasi meja',
      },
    },
    omniChannel: {
      index: 'Omni Channel',
      feePercent: '{percent}% dari harga Dine In',
      feePrice: 'Rp. {price} dari harga Dine In',
      tableHeaders: {
        name: 'Nama Channel',
        platformFee: 'Platform Fee',
        status: 'Status',
      },
      modal: {
        add: 'Tambah Platform Baru',
        edit: 'Ubah Data Platform',
      },
      input: {
        name: 'Nama Channel',
        active: 'Active',
        posPaymentChannel: 'POS Payment Channel',
        fee: 'Biaya',
        itemAffected: 'Item Yang Berlaku',
        addItem: 'Tambah Item',
      },
    },
    paymentChannel: {
      activeChannel: 'Channel Name',
      editMDR: 'Edit Fee MDR',
      mdrFee: 'Fee MDR',
      mdrFeeType: 'Tipe Fee MDR',
      tableHeaders: {
        name: 'Nama Channel',
        mdrFee: 'MDR Fee',
        status: 'Status',
      },
    },
    additionalFee: {
      title: 'Biaya Tambahan dan Pajak',
      addButton: 'Tambahkan Biaya',
      cannotActivate: 'tidak dapat mengaktifkan 2 pajak sekaligus',
      tableHeaders: {
        name: 'Nama',
        feeType: 'Jenis Biaya',
        total: 'Jumlah',
        isActive: 'Status Aktif',
      },
      forms: {
        title: 'Tambah Biaya',
        editTitle: 'Edit Biaya',
        name: 'Nama Biaya Tambahan',
        namePlaceholder: 'Masukkan nama biaya',
        feeType: 'Jenis Biaya Tambahan',
        feeTypePlaceholder: 'Jenis biaya tambahan',
        beforeDiscount: 'Sebelum diskon',
        afterDiscount: 'Setelah diskon',
        includePrice: 'Termasuk harga',
        taxPerItem: 'Pajak per item',
        infoLamp: '💡',
        infoDesc:
          'Pengenaan biaya akan dilakukan pada transaksi dengan harga normal',
        amount: 'Jumlah',
        percent: 'Persen',
        nominal: 'Nominal',
        totalPlaceholder: 'Masukkan jumlah',

        addItem: 'Tambah item',
        selectAll: 'Pilih semua',
        totalItem: '(%{total} items)',
      },
      feeTypes: {
        pajak: 'Tax',
        pajakDesc: 'Biaya tambahan berupa pajak',
        service: 'Service Fee',
        serviceDec: 'Biaya tambahan berupa biaya layanan',
        institute_fee: 'Institution Fee',
        institute_feeDesc: 'Biaya tambahan berupa biaya institusi',
        lainnya: 'Others',
        lainnyaDesc: 'Untuk biaya tambahan jenis lainnya',
      },
    },
    officeHour: {
      open: 'Buka',
      closed: 'Tutup',
      tableHeaders: {
        day: 'Hari',
        status: 'Status',
        openTime: 'Jam Buka',
        closeTime: 'Jam Tutup',
        openClose: 'Buka/Tutup',
      },
    },
    purchaseOrder: {
      stockPurchase: {
        recomendation: 'Rekomendasi',
        noRecomendation: 'Tidak ada rekomendasi',
        stockRunningLow: 'Stok menipis',
        outOfStock: 'Stok habis',
        searchFromSupplier: 'Cari Bahan dari supplier',
        showSupplierList: 'Lihat daftar supplier',
      },
      stockPurchaseCart: {
        cart: 'Keranjang',
        addAnother: 'Tambah lagi',
        unit: 'Unit',
        noTransaction: 'Belum ada transaksi',
        clickBelow: 'Tekan tombol di bawah untuk membuat transaksi',
        createTransaction: 'Buat Transaksi',
        anotherSetting: 'Pengaturan Lainnya',
        subtotal: 'Subtotal',
        totalItem: '%{total} item',
        transactionFee: 'Fee Transaksi',
        total: 'Total',
        stockAdjustment: 'Sesuaikan stok',
        shipping: 'Pengiriman',
        shippingAddress: 'Alamat pengiriman',
        changeAddress: 'Ganti Alamat',
        addNewAddress: 'Tambah Alamat baru',
        addressPrimary: 'Utama',
        noAddress:
          'Kamu belum memiliki alamat. Silakan buat alamat baru di atas',
        buttonPressed: 'Dengan menekan tombol',
        purchase: 'Purchase',
        submitPurchase: 'Ajukan purchase',
        youHaveAgreed: 'Kamu telah menyetujui',
        toc: 'Syarat dan Ketentuan',
        determinedBy: 'yang ditentukan oleh InKanteen.',
        areYouSureSubmit:
          'Kamu yakin ingin mengajukan pesanan stok ini? Pastikan stok bahan yang kamu pilih sudah sesuai',
        areYouSureAdjust:
          'Kamu yakin ingin melakukan adjustment pesanan stok ini? Pastikan stok bahan yang kamu pilih sudah sesuai',
        makePurchase: 'Lakukan Purchase',
        applyNow: 'Ajukan Sekarang',
        makeAdjustment: 'Lakukan Adjustment',
      },
      stockPurchaseCartAddress: {
        shippingAddress: 'Alamat pengiriman',
        changeAddress: 'Ganti Alamat',
        addNewAddress: 'Tambah Alamat baru',
        noAddress:
          'Kamu belum memiliki alamat. Silakan buat alamat baru di atas',
        selectAddress: 'Pilih alamat',
      },
      stockPurchaseCartAddressForm: {
        addAddress: 'Tambah Alamat pengiriman',
        label: 'Label',
        labelPlaceholder: 'Masukkan label (Alamat kantor, alamat toko, dll)',
      },
      history: {
        paidByInstitute: 'Dibayar Institute',
        title: 'Riwayat pesanan',
        tableHeaders: {
          trxNo: 'Nomor Pesanan',
          trxDate: 'Tanggal Transaksi',
          totalItem: 'Total Barang',
          totalPayment: 'Total Pembayaran',
          trxStatus: 'Status Pengiriman',
        },
        detail: {
          title: 'Detail Order',
          tableHeaders: {
            id: 'ID',
            productName: 'Nama Produk',
            qty: 'Qty',
            price: 'Harga',
            total: 'Total',
          },
        },
        TOTAL: 'Semua Pesanan',
        REQUEST: 'Request',
        PENDING: 'Pending',
        PROCESS: 'Diproses',
        DELIVERY: 'Sedang dikirim',
        CANCELLED: 'Dibatalkan',
        CANCELLEDBY: 'Dibatalkan oleh %{by}',
        PAID: 'Dibayar',
        COMPLETED: 'Selesai',
        RECEIVED: 'Diterima',
        REJECTED: 'Ditolak',
      },
    },
    inventoryAdjustment: {
      tableHeaders: {
        title: 'Penyesuaian Inventory',
        adjustmentNo: 'Nomor Adjustment',
        date: 'Tanggal',
        reason: 'Alasan',
        note: 'Catatan',
      },
      tableDetailHeaders: {
        name: 'Nama Bahan',
        stock: 'Stok Inventori',
        receiveStock: 'Stok Diterima',
        countStock: 'Stok Disesuaikan',
        reduceStock: 'Stok Dikurangi',
        newestTotal: 'Jumlah Terbaru',
      },
      reason: {
        damage: 'Rusak',
        rotten: 'Busuk',
        badQuality: 'Kualitas Buruk',
      },
    },
    privilege: {
      tableHeaders: {
        subFeature: 'Sub-Feature',
        show: 'Lihat',
        add: 'Tambah',
        edit: 'Edit',
        delete: 'Hapus',
      },
    },
    shiftHistory: {
      date: 'Tanggal',
      spv: 'Penanggung Jawab',
      startShift: 'Mulai Shift',
      endShift: 'Selesai Shift',
      statusShift: 'Status Shift',
      openShift: 'Open Shift',
      closeShift: 'Close Shift',
      totalEndCash: 'Uang Kas',
      totalSales: 'Total Penjualan',
      soldItem: 'Item Terjual',
      startCash: 'Uang Kas Awal',
      operationalCash: 'Uang Operasional',
      totalCash: 'Total Kas',
      cashDesc:
        'Uang kas dibagi menjadi dua bagian, yaitu uang operasional dan uang kas awal.',
    },
  },
  general: {
    greeting: 'Selamat Datang!',
    hello: 'Halo',
    tnc: 'Syarat & Ketentuan',
    privacyPolicy: 'Kebijakan Pribadi',
    optional: 'opsional',
  },
  info: {
    dataNotFound: 'Data tidak ditemukan',
    loginGreeting: 'Beberapa klik lagi untuk masuk ke akun Anda',
    loginGreetingDetail: 'Kelola akun anda di satu tempat',
    currentVersion: 'Versi Dashboard Saat Ini',
    pageMissing: 'Ups. Halaman ini tidak ditemukan',
    pageMissingInstruction:
      'Anda mungkin salah mengetik alamat atau halaman mungkin telah dipindahkan.',
    thereIsNoData: 'Tidak ada data untuk ditampilkan',
    max6: 'Maksimal 6',
    max6Warning: '* Mohon untuk tidak memilih lebih dari 6 pembanding',
    active: 'Aktif',
    inActive: 'Tidak Aktif',
    onlyAccess: 'Hanya %{user} yang bisa mengakses halaman ini',
    totalItems: '%{total} items',
    timeHour: 'Jam',
    timeMinute: 'Menit',
    product: 'Produk',
    ingredient: 'Bahan',
    status: 'Status',
    understand: 'Mengerti',
    lamp: '💡',
    featureOnlyOneTenant: 'Fitur ini tidak tersedia untuk multi tenant',
    open: 'Buka',
    closed: 'Tutup',
    page: 'Halaman',
    yes: 'Ya',
    no: 'Tidak',
    total: 'Total',
    remind: 'Ingatkan',
    greeting: {
      morning: 'Selamat Pagi',
      afternoon: 'Selamat Siang',
      evening: 'Selamat Sore',
      night: 'Selamat Malam',
    },
  },
  confirmation: {
    logout: 'Apakah Anda yakin ingin keluar?',
    delete: 'Apakah Anda yakin ingin menghapus data ini?',
  },
  button: {
    submit: 'Kirim',
    login: 'Masuk',
    logout: 'Keluar',
    cancel: 'Batal',
    close: 'Tutup',
    delete: 'Hapus',
    register: 'Daftar',
    sendEmail: 'Kirim Email',
    sendOTP: 'Kirim OTP',
    resetPassword: 'Setel Ulang Kata Sandi',
    loginInstead: 'Atau login disini',
    forgotPassword: 'Lupa Password?',
    saveChanges: 'Simpan Perubahan',
    save: 'Simpan',
    saveChange: 'Simpan Perubahan',
    connect: 'Hubungkan',
    connected: 'Terhubung',
    backToHome: 'Kembali ke Beranda',
    showAll: 'Tampilkan semua',
    seeAll: 'Lihat semua',
    yesOfCourse: 'Ya, Tentu',
    next: 'Selanjutnya',
    back: 'Kembali',
    apply: 'Terapkan',
  },
  formInfo: {
    notLogin: 'Anda belum masuk',
    registerSuccess: 'Anda telah berhasil mendaftar',
    registerFailed: 'Registrasi gagal',
    loginSuccess: 'Berhasil Masuk',
    loginFailed: 'Gagal Masuk',
    saveChangesSuccess: 'Perubahan Berhasil Disimpan',
    saveChangesFailed: 'Perubahan Gagal Disimpan',
    loginDesc: 'Masukan email dan kata sandi anda',
    forgotPasswordDesc: 'Masukan email anda untuk mereset password',
    loginCheck: 'Dengan mendaftar, Anda setuju dengan ',
    rememberMe: 'Remember me',
    success: 'Berhasil',
    failed: 'Gagal',
    fetchSuccess: 'Berhasil mengambil data',
    fetchFailed: 'Gagal mengambil data',
    selectAll: 'Pilih semua',
    dataNotFound: 'Data tidak ditemukan',
  },
  formLabel: {
    name: 'Nama',
    fullName: 'Nama Lengkap',
    bussinessName: 'Nama Bisnis',
    tenantName: 'Nama Tenant',
    email: 'Email',
    phone: 'Nomor Telepon',
    password: 'Kata Sandi',
    passwordConfirmation: 'Konfirmasi Password',
    oldPassword: 'Kata Sandi Lama',
    newPassword: 'Kata Sandi Baru',
    newPasswordConfirmation: 'Konfirmasi Kata Sandi Baru',
    accessRight: 'Hak Akses',
    pin: 'PIN',
    description: 'Deskripsi',
    website: 'Website',
    province: 'Provinsi',
    city: 'Kota',
    district: 'Kecamatan',
    village: 'Kelurahan',
    postalCode: 'Kode Pos',
    address: 'Alamat',
    search: 'Cari...',
    searchFoo: 'Cari %{foo}...',
    select: {
      province: 'Pilih Provinsi',
      city: 'Pilih Kota',
      district: 'Pilih Kecamatan',
      village: 'Pilih Kelurahan',
      tenant: 'Pilih Tenant',
      ingredient: 'Pilih Bahan',
      product: 'Pilih Produk',
    },
    dropdown: {
      timeRange: {
        title: 'Filter by Time Range',
        daily: 'Harian',
        weekly: 'Mingguan',
        monthly: 'Bulanan',
        quarterly: 'Triwulanan',
        yearly: 'Tahunan',
        yesterday: 'Kemarin',
        last_week: 'Minggu Lalu',
        last_month: 'Bulan Lalu',
        last_quarter: 'Triwulan Lalu',
        last_year: 'Tahun Lalu',
      },
    },
    from: 'Dari',
    to: 'Hingga',
  },
  formInput: {
    enterName: 'Mohon masukan nama anda',
    enterFullName: 'Mohon masukan nama lengkap anda',
    enterBussinessName: 'Mohon masukan nama bisnis',
    enterTenantName: 'Mohon masukan nama tenant',
    enterDescription: 'Mohon masukan deskripsi',
    enterWebsite: 'Mohon masukan website',
    enterEmail: 'Mohon masukan email anda',
    enterPhone: 'Mohon masukan nomor telepon anda',
    enterPassword: 'Mohon masukan password anda',
    enterPIN: 'Mohon masukan PIN anda',
    enterAddress: 'Mohon masukan alamat anda',
    enterPostalCode: 'Mohon masukan kode pos',
    filterTenant: 'Filter Tenant',
  },
  formError: {
    onlyNumber: 'Mohon hanya masukkan angka',
    cannotOnlySpace: 'Field ini tidak boleh hanya berisi spasi atau tab',
    min0: 'Nilai minimal adalah 0',
    mustNumber: 'Mohon hanya isi dengan angka',
    onlyNumberAndDot: 'Mohon hanya isi dengan angka atau titik (.)',
    name: {
      required: 'Nama tidak boleh kosong',
    },
    recipientName: {
      required: 'Nama penerima tidak boleh kosong',
    },
    label: {
      required: 'Label tidak boleh kosong',
    },
    fullName: {
      required: 'Nama Lengkap tidak boleh kosong',
    },
    bussinessName: {
      required: 'Nama Bisnis tidak boleh kosong',
    },
    tenantName: {
      required: 'Nama Tenant tidak boleh kosong',
    },
    email: {
      required: 'Email tidak boleh kosong',
      validEmail: 'Email harus berupa email yang valid',
    },
    phone: {
      required: 'Nomor Telepon tidak boleh kosong',
      numeric: 'Nomor Telepon hanya boleh berisi angka',
    },
    pin: {
      required: 'PIN tidak boleh kosong',
    },
    tenant: {
      required: 'Tenant tidak boleh kosong',
    },
    address: {
      required: 'Alamat tidak boleh kosong',
    },
    position: {
      required: 'Posisi tidak boleh kosong',
    },
    province: {
      required: 'Provinsi tidak boleh kosong',
    },
    city: {
      required: 'Kota tidak boleh kosong',
    },
    district: {
      required: 'Kecamatan tidak boleh kosong',
    },
    village: {
      required: 'Desa tidak boleh kosong',
    },
    role: {
      required: 'Role tidak boleh kosong',
    },
    pin: {
      required: 'PIN tidak boleh kosong',
      numeric: 'PIN hanya boleh berisi angka',
      minLength: 'Minimal 6 Digit',
      maxLength: 'Maksimal 6 Digit',
    },
    postalCode: {
      maxLength: 'Maksimal 5 Digit',
      required: 'Kode Pos tidak boleh kosong',
    },
    oldPassword: {
      required: 'Kata sandi lama tidak boleh kosong',
    },
    newPassword: {
      required: 'Kata sandi baru tidak boleh kosong',
    },
    newPasswordConfirmation: {
      required: 'Konfirmasi kata sandi baru tidak boleh kosong',
      sameAsPassword: 'Konfirmasi kata sandi baru tidak sama',
    },
    password: {
      required: 'Kata sandi tidak boleh kosong',
      minLength: 'Kata sandi minimal 6 karakter',
      alphaNumeric: 'Password only allowed alphanumeric and special characters',
    },
    wrongEmailOrPassword: 'Wrong email or password',
    validEmailAddress: 'This field must be valid email address',
    validPhoneNumber:
      'Please enter valid WhatsApp number, min 10 digit numbers',
    fieldAlphabet: 'must only contain alphabetic characters',
    fieldAlphaNumeric: 'must only contain alphanumeric characters',
    fieldNumeric: 'must only contain numeric characters',
    variantName: {
      required: 'Nama Variant tidak boleh kosong',
    },
    subVariantName: {
      required: 'Nama Sub-Variant tidak boleh kosong',
    },
    productName: {
      required: 'Nama Produk tidak boleh kosong',
    },
    productTimeStart: {
      required: 'Waktu produksi mulai tidak boleh kosong',
      less: 'Waktu produksi mulai tidak boleh lebih dari waktu produksi selesai',
    },
    productTimeEnd: {
      required: 'Waktu produksi selesai tidak boleh kosong',
    },
    ingredientName: {
      required: 'Nama Bahan tidak boleh kosong',
    },
    category: {
      required: 'Mohon pilih kategori',
    },
    brand: {
      required: 'Mohon pilih brand',
    },
    unit: {
      required: 'Mohon pilih unit',
    },
    price: {
      minimal: 'Minimal harga adalah 0',
      required: 'Mohon isi harga',
      dotDecimal: 'Mohon gunakan titik (.) untuk desimal',
    },
    target_stock_reminder: {
      required: 'Mohon isi stok reminder',
    },
    ingredientBrandName: {
      required: 'Nama brand tidak boleh kosong',
    },
    ingredientCategoryName: {
      required: 'Nama kategori tidak boleh kosong',
    },
    simplePurchasingStock: {
      stockItemQuantityRequired: 'Mohon isi jumlah stok terlebih dahulu',
      stockItemProductRequired: 'Produk tidak boleh kosong',
    },
    inventoryStock: {
      required: 'Tambahkan bahan terlebih dahulu',
    },
    ingredientStock: {
      required: 'Mohon isi penyesuaian stok',
    },
    unitName: {
      required: 'Nama unit tidak boleh kosong',
    },
    brandName: {
      required: 'Nama brand tidak boleh kosong',
    },
    categoryName: {
      required: 'Nama kategori tidak boleh kosong',
    },
    phoneNumber: {
      required: 'Nomor HP tidak boleh kosong',
      notValid: 'Nomor HP tidak valid',
    },
    whatsAppNumber: {
      required: 'Nomor WhatsApp tidak boleh kosong',
      notValid: 'Nomor WhatsApp tidak valid',
    },
    supplierName: {
      required: 'Nama supplier tidak boleh kosong',
    },
    supplier: {
      required: 'Mohon pilih supplier terlebih dahulu',
    },
    barcode: {
      onlyAllow: 'Hanya diperbolehkan huruf, angka, atau strip (-)',
    },
    detailReason: {
      required: 'Mohon pilih detail alasan terlebih dahulu',
    },
    additionalFeeName: {
      required: 'Nama biaya tambahan tidak boleh kosong',
    },
    additionalFeeType: {
      required: 'Mohon pilih tipe biaya tambahan terlebih dahulu',
    },
    additionalFeeAmount: {
      required: 'Jumlah biaya tambahan tidak boleh kosong',
      maxPercent: 'Jumlah biaya tambahan tidak boleh lebih dari 100%',
    },
    billingName: {
      required: 'Mohon isi nama tagihan',
    },
    dueDate: {
      required: 'Mohon pilih tanggal jatuh tempo',
    },
    expenseName: {
      required: 'Mohon isi nama pengeluaran',
    },
    expenseDate: {
      required: 'Mohon isi tanggal transaksi',
    },
    expenseTime: {
      required: 'Mohon isi waktu transaksi',
    },
  },
  tooltip: {
    grossSales: 'Akumulasi seluruh uang yang masuk ke merchant',
    income: 'Total pendapatan bersih (penjualan kotor - expenses)',
    netSales:
      'Akumulasi penjualan dari harga produk X quantitiy - biaya2 potongan lainnya)',
    others:
      'Biaya layanan yang dibebankan kepada merchant atau customer sebagai bagian jasa pelayanan inkanteen',
    promo: 'Hitungan biaya untuk campaign/promosi',
  },
}

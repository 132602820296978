<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.analytic.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
    </div>

    <div class="relative grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Indeks OmniChannel Line Chart -->
          <div class="col-span-12 mt-4">
            <div class="intro-y box py-5 px-1 md:mt-6 md:p-5">
              <div
                class="flex w-full flex-col justify-between md:flex-row md:items-center"
              >
                <div class="flex self-center align-middle">
                  <h2 class="mr-3 truncate text-lg font-medium">
                    {{ $t('page.analytic.omnichannelIndex.title') }}
                  </h2>
                  <span
                    class="flex self-center text-xs uppercase text-gray-500"
                  >
                    {{ periodicFilter }}
                  </span>
                </div>
                <div
                  class="mt-0 flex w-full items-center justify-center sm:ml-auto md:mt-3"
                >
                  <FilterTimeRange
                    :active="periodicFilter"
                    @change-filter="changeFilterTimeRange($event)"
                  />
                  <div
                    v-show="false"
                    class="relative w-full text-slate-500 sm:ml-auto sm:mt-0"
                  >
                    <CalendarIcon
                      class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
                    />
                    <Litepicker
                      v-model="dateFilter"
                      :options="{
                        autoApply: false,
                        singleMode: false,
                        numberOfColumns: 2,
                        numberOfMonths: 2,
                        showWeekNumbers: true,
                        format: 'YYYY-MM-DD',
                        dropdowns: {
                          minYear: 1990,
                          maxYear: null,
                          months: true,
                          years: true,
                        },
                      }"
                      class="form-control box pl-8 sm:w-56"
                    />
                  </div>
                </div>
              </div>
              <div class="my-4 grid grid-cols-12 gap-6 md:my-5">
                <Card
                  :title="$t('page.analytic.omnichannelIndex.sale')"
                  :is-currency="true"
                  :is-up="cardData.gross_sales_total.is_up"
                  :percentage="cardData.gross_sales_total.percent"
                  :total="cardData.gross_sales_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
                <Card
                  :title="$t('page.analytic.omnichannelIndex.grossProfit')"
                  :is-currency="true"
                  :is-up="cardData.net_sales_total.is_up"
                  :percentage="cardData.net_sales_total.percent"
                  :total="cardData.net_sales_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
                <Card
                  :title="$t('page.analytic.omnichannelIndex.netProfit')"
                  :is-currency="true"
                  :is-up="cardData.payment_total.is_up"
                  :percentage="cardData.payment_total.percent"
                  :total="cardData.payment_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
                <Card
                  :title="$t('page.analytic.omnichannelIndex.totalTransaction')"
                  :is-currency="false"
                  :is-up="cardData.transaction_total.is_up"
                  :percentage="cardData.transaction_total.percent"
                  :total="cardData.transaction_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
              </div>
              <div>
                <ReportLineChart
                  v-if="!tenantSalesChartEmpty"
                  :label="tenantSalesChartLabel"
                  :item-name="tenantSalesChartItemName"
                  :data="tenantSalesChartData"
                  :show-legend="true"
                  :height="275"
                  class="mt-6 -mb-6"
                />
                <NoData v-else />
              </div>
            </div>
          </div>
          <!-- END: Indeks OmniChannel Line Chart -->
        </div>
      </div>
    </div>

    <!-- BEGIN: OmniChannel Index Table -->
    <TableComponent
      :date-string="periodicFilter"
      :title="$t('page.analytic.omnichannelIndex.omnichannelIndexTable')"
      :header="omnichannelIndexHeader"
      :is-show-search="false"
      :is-show-export="false"
      :is-not-empty="omnichannelIndexData.length > 0"
      :meta-data="metaData"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="data in omnichannelIndexData" :key="data.omnichannel_id">
        <td class="text-center">{{ data.omnichannel_name }}</td>
        <td class="text-center">{{ data.transaction_total }}</td>
        <td class="text-center">{{ data.transaction_percentage }}</td>
        <td class="text-center">{{ data.product_total }}</td>
        <td>
          <Currency
            :total-in-string="data.sales_total"
            wrap-class="justify-center"
          />
        </td>
      </tr>
    </TableComponent>
    <!-- END: OmniChannel Index Table -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'

import FilterTimeRange from '@/components/FilterTimeRange.vue'
import TableComponent from '@/components/TableComponent.vue'
import Card from '@/components/Card.vue'

import Currency from '@/components/Currency.vue'
import NoData from '@/components/NoData.vue'

export default {
  name: 'OmnichannelIndexPage',
  components: {
    Breadcrumb,
    FilterTimeRange,
    TableComponent,
    Card,

    Currency,
    ReportLineChart,
    NoData,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()
    const periodicFilter = ref('daily')
    const tenantIds = ref([])

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
      periodicFilter,
      tenantIds,
    }
  },
  data: () => ({
    omnichannelIndexHeader: [
      {
        item: 'page.analytic.omnichannelIndex.tableHeaders.omnichannelName',
        customClass: 'text-center',
      },
      {
        item: 'page.analytic.omnichannelIndex.tableHeaders.totalTransaction',
        customClass: 'text-center',
      },
      {
        item: 'page.analytic.omnichannelIndex.tableHeaders.percentTransaction',
        customClass: 'text-center',
      },
      {
        item: 'page.analytic.omnichannelIndex.tableHeaders.productSold',
        customClass: 'text-center',
      },
      {
        item: 'page.analytic.omnichannelIndex.tableHeaders.sale',
        customClass: 'text-center',
      },
    ],
    tenantPickHeader: [
      {
        item: '',
        customClass: '',
      },
      {
        item: 'page.report.tenantSales.tableHeaders.tenant',
        customClass: 'text-center',
      },
    ],
    cardData: {
      gross_sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      net_sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      payment_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      transaction_total: {
        total: 0,
        percent: '%',
        is_up: false,
      },
    },
    omnichannelIndexData: [],
    tenantData: [],
    tenantDataAll: [],
    tenantDataMapped: [],
    activeTenantData: [],
    showTenantModal: false,
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    metaDataTenant: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataTenant: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    tenantSalesChartEmpty: true,
    tenantSalesChartLabel: [],
    tenantSalesChartItemName: [],
    tenantSalesChartData: [],
    isTenantUser: false,
    activeTenant: {},
    showWarningFilter: false,
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
    tenantId() {
      return this.tenantIds.toString()
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      if (this.tenantId !== '') {
        this.init()
      }
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    this.getAllTenant(true).then(() => {
      this.init()
    })

    const activeUser = this.store.getters['auth/activeUser']
    if (activeUser.tenant !== undefined) {
      this.activeTenant = activeUser.tenant
      this.isTenantUser = true
    }
  },
  methods: {
    init() {
      this.getSummary()
      this.getOmniChannelIndexChart()
      this.getAllOmnichannelIndex()
    },
    changeFilterTimeRange(event) {
      this.periodicFilter = event
      this.init()
    },
    saveTenantIds() {
      if (this.tenantIds.length > 0) {
        if (this.tenantIds.length > 6) {
          this.showWarningFilter = true
          return
        }
        this.showWarningFilter = false
        this.showTenantModal = false
        this.init()
      }
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllOmnichannelIndex()
    },
    changeFilterTenant(event) {
      this.filterDataTenant = event.filterData
      this.getAllTenant()
    },
    selectTenant(event, tenantId) {
      if (event.target.checked) {
        this.tenantIds.push(tenantId)
      } else {
        this.tenantIds = this.tenantIds.filter((item) => item != tenantId)
      }
    },
    deleteComparison(event) {
      this.tenantIds = this.tenantIds.filter((item) => item != event.item)
      // this.getSalesTenantChart()
      this.init()
    },
    async getAllTenant(isFirst = false) {
      await this.store
        .dispatch('tenant/getAll', {
          order: this.filterDataTenant.order,
          page: this.filterDataTenant.page,
          take: this.filterDataTenant.take,
          q: this.filterDataTenant.search,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.tenantData = responseData.data
            this.tenantDataAll.push(...new Set(responseData.data))
            this.tenantDataUnique = this.uniqByKeepLast(
              this.tenantDataAll,
              (it) => it.id
            )
            if (isFirst) {
              if (this.activeTenant.id) {
                this.tenantIds.push(this.activeTenant.id)
              } else if (this.tenantData.length > 0) {
                this.tenantIds.push(this.tenantData[0].id)
              }
            }
            const tenantDataReduced = this.tenantDataUnique.reduce(
              (filtered, item) => {
                let key = item.id
                if (!filtered[key]) {
                  filtered[key] = null
                }
                filtered[key] = item
                return filtered
              },
              {}
            )
            this.tenantDataMapped = tenantDataReduced
          }
          if (responseData.meta) {
            this.metaDataTenant = responseData.meta
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    async getSummary() {
      this.isLoading = true

      await this.$store
        .dispatch('analytic/omniChannelIndexSummary', {
          periodic: this.periodicFilter,
          tenant_id: this.tenantId,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.cardData.gross_sales_total = responseData.gross_sales_total
            this.cardData.net_sales_total = responseData.net_sales_total
            this.cardData.payment_total = responseData.payment_total
            this.cardData.transaction_total = responseData.transaction_total
          }
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },

    async getOmniChannelIndexChart() {
      await this.store
        .dispatch('analytic/omniChannelIndexChart', {
          periodic: this.periodicFilter,
          tenant_id: this.tenantId,
        })
        .then((response) => {
          const responseData = response.data
          if (responseData.data) {
            // const filteredChart = responseData.data.reduce((filtered, item) => {
            //   let key = item.chart_id
            //   if (!filtered[key]) {
            //     filtered[key] = null
            //   }
            //   filtered[key] = item.charts
            //   return filtered
            // }, {})

            this.tenantSalesChartEmpty = false
            this.tenantSalesChartItemName = []
            this.activeTenantData = []
            this.tenantSalesChartData = []
            responseData.data.forEach((item, index) => {
              // if (this.tenantDataMapped[item] !== undefined) {
              // this.activeTenantData.push(this.tenantDataMapped[item])
              this.tenantSalesChartItemName.push(item.chart_name.toUpperCase())
              // }

              // if (filteredChart[item] !== undefined) {
              //   this.tenantSalesChartEmpty = false
              // }
              let labels = []
              let values = []
              item.charts.forEach((item) => {
                labels.push(item.name)
                values.push(item.total)
              })
              if (index === 0) {
                this.tenantSalesChartLabel = labels
              }
              this.tenantSalesChartData.push(values)
            })
          }
        })
        .catch((e) => {
          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getAllOmnichannelIndex() {
      await this.store
        .dispatch('analytic/omniChannelIndexGetAll', {
          periodic: this.periodicFilter,
          page: this.filterData.page,
          take: this.filterData.take,
          order: this.filterData.order,
          q: this.filterData.search,
          tenant_id: this.tenantId,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.omnichannelIndexData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
  },
}
</script>

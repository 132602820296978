<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.report.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
    </div>

    <div class="relative grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Tenant Sales Line Chart -->
          <div class="col-span-12 mt-4">
            <div class="intro-y box py-5 px-1 md:mt-6 md:p-5">
              <div
                class="flex w-full flex-col justify-between md:flex-row md:items-center"
              >
                <div class="flex self-center align-middle">
                  <h2 class="mr-3 truncate text-lg font-medium">
                    {{ $t('page.report.tenantSales.title') }}
                  </h2>
                  <span
                    class="flex self-center text-xs uppercase text-gray-500"
                  >
                    {{ periodicFilter }}
                  </span>
                </div>
                <div
                  class="mt-0 flex w-full items-center justify-center sm:ml-auto md:mt-3"
                >
                  <FilterTimeRange
                    :active="periodicFilter"
                    @change-filter="changeFilterTimeRange($event)"
                  />
                </div>
              </div>
              <div>
                <ReportLineChart
                  v-if="!tenantSalesChartEmpty"
                  :label="tenantSalesChartLabel"
                  :item-name="tenantSalesChartItemName"
                  :data="tenantSalesChartData"
                  :show-legend="true"
                  :height="275"
                  class="mt-6 -mb-6"
                />
                <NoData v-else />
              </div>
              <div
                v-if="!tenantSalesChartEmpty"
                class="flex w-full max-w-full flex-row overflow-x-scroll pt-10"
              >
                <ComparisonItem
                  v-for="(tenant, index) in activeTenantData"
                  :key="tenant.id"
                  :title="tenant.name"
                  :value="tenant.id"
                  :has-close-icon="!isTenantUser && activeTenantData.length > 1"
                  :show-color="true"
                  :color="getColor(index)"
                  custom-class="w-1/2 md:w-1/4 2xl:w-1/6"
                  @delete-comparison="deleteComparison($event)"
                />
                <ComparisonItem
                  v-if="!isTenantUser"
                  :title="$t('page.report.addComparison')"
                  :has-plus-icon="true"
                  value="add"
                  custom-class="w-1/2 md:w-1/4 2xl:w-1/6 bg-slate-700 text-white hover:bg-slate-800"
                  @click="showTenantModal = true"
                />
              </div>
            </div>
          </div>
          <!-- END: Tenant Sales Line Chart -->
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Tenant Sales Report -->
          <div class="col-span-12 mt-8">
            <div
              class="intro-y flex w-full flex-col justify-between space-y-2 md:flex-row md:space-y-0"
            >
              <div class="flex align-middle">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <TitleComponent
                  :title="$t($route.meta.title)"
                  :date-string="dateFilter"
                />
              </div>
              <div class="flex align-middle">
                <a href="" class="flex items-center space-x-2 text-primary">
                  <RefreshCcwIcon class="h-4 w-4" />
                  <span>{{ $t('action.reloadData') }}</span>
                </a>
              </div>
            </div>
            <div class="mt-4 grid grid-cols-12 gap-6 md:mt-5">
              <template v-if="isInstituteAdmin">
                <Card
                  :title="$t('page.report.tenantSales.totalSalesGross')"
                  :is-currency="true"
                  :is-up="cardData.gross_sales_total.is_up"
                  :percentage="cardData.gross_sales_total.percent"
                  :total="cardData.gross_sales_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
                <Card
                  :title="$t('page.report.tenantSales.totalSales')"
                  :is-currency="true"
                  :is-up="cardData.net_sales_total.is_up"
                  :percentage="cardData.net_sales_total.percent"
                  :total="cardData.net_sales_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
                <Card
                  :title="$t('page.report.tenantSales.totalPay')"
                  :is-currency="true"
                  :is-up="cardData.payment_total.is_up"
                  :percentage="cardData.payment_total.percent"
                  :total="cardData.payment_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
                <Card
                  :title="$t('page.report.tenantSales.totalTransaction')"
                  :is-currency="false"
                  :is-up="cardData.transaction_total.is_up"
                  :percentage="cardData.transaction_total.percent"
                  :total="cardData.transaction_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-3"
                />
              </template>
              <template v-else>
                <Card
                  :title="$t('page.report.tenantSales.totalSalesGross')"
                  :is-currency="true"
                  :is-up="cardData.gross_sales_total.is_up"
                  :percentage="cardData.gross_sales_total.percent"
                  :total="cardData.gross_sales_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
                />
                <Card
                  :title="$t('page.report.tenantSales.totalSales')"
                  :is-currency="true"
                  :is-up="cardData.net_sales_total.is_up"
                  :percentage="cardData.net_sales_total.percent"
                  :total="cardData.net_sales_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
                />
                <Card
                  :title="$t('page.report.tenantSales.totalTransaction')"
                  :is-currency="false"
                  :is-up="cardData.transaction_total.is_up"
                  :percentage="cardData.transaction_total.percent"
                  :total="cardData.transaction_total.total"
                  :show-percentage="true"
                  wrapper-class="col-span-12 sm:col-span-6 xl:col-span-4"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Tenant Sales Report -->
    <div class="flex w-full justify-end pt-10 md:pt-20">
      <div class="flex w-full justify-end md:w-fit">
        <div class="relative w-full text-slate-500 sm:ml-auto sm:mt-0">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateFilter"
            :options="{
              autoApply: false,
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              format: 'YYYY-MM-DD',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 sm:w-56"
          />
        </div>
        <ExportCSVComponent @export="downloadSalesTenant" />
      </div>
    </div>
    <!-- BEGIN: Order Detail Table -->
    <TableComponent
      :date-string="dateFilter"
      :title="$t('page.report.tenantSales.tenantSalesTable')"
      :header="tenantSalesReportHeader"
      :is-show-search="true"
      :is-not-empty="tenantSalesReportData.length > 0"
      :meta-data="metaData"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="data in tenantSalesReportData" :key="data.tenant_id">
        <td>{{ data.tenant_name }}</td>
        <td><Currency :total-in-string="data.sales_total" /></td>
        <td class="text-center">{{ data.sales_percentage }}</td>
        <td>{{ data.transaction_total }}</td>
        <td class="text-center">{{ data.transaction_percentage }}</td>
      </tr>
    </TableComponent>
    <!-- END: Order Detail Table -->

    <!-- BEGIN: Modal Add Tenant -->
    <Modal
      :show="showTenantModal"
      size="modal-xl"
      :slide-over="true"
      @hidden="showTenantModal = false"
    >
      <ModalBody class="p-0">
        <div class="pb-8 text-center md:px-5">
          <TableComponent
            :date-string="$t('info.max6')"
            :title="$t('page.report.tenantSales.tenantTable')"
            :header="tenantPickHeader"
            :is-show-search="true"
            :show-icon="false"
            :meta-data="metaDataTenant"
            :is-not-empty="tenantData.length > 0"
            @change-filter="changeFilterTenant($event)"
          >
            <tr v-for="tenant in tenantData" :key="tenant.id">
              <td>
                <input
                  id="default-checkbox"
                  type="checkbox"
                  :value="tenant.id"
                  class="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                  :checked="tenantIds.includes(tenant.id)"
                  @change="selectTenant($event, tenant.id)"
                />
              </td>
              <td>{{ tenant.name }}</td>
            </tr>
          </TableComponent>
        </div>
        <div class="flex justify-end px-5 pb-8">
          <span
            v-if="showWarningFilter"
            class="flex w-full justify-start text-danger"
            >{{ $t('info.max6Warning') }}</span
          >
          <button
            type="button"
            class="btn btn-outline-primary mr-2 w-24"
            @click="showTenantModal = false"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            type="button"
            class="btn w-24"
            :class="[
              tenantIds.length > 0
                ? 'btn-primary cursor-pointer text-white'
                : 'btn-slate-300 cursor-not-allowed text-slate-100',
            ]"
            @click="saveTenantIds"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </ModalBody>
    </Modal>
    <!-- END: Modal Add Tenant -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'

import FilterTimeRange from '@/components/FilterTimeRange.vue'
import TableComponent from '@/components/TableComponent.vue'
import TitleComponent from '@/components/TitleComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'

import Card from '@/components/Card.vue'
import Currency from '@/components/Currency.vue'
import ComparisonItem from '@/components/ComparisonItem.vue'
import SaleIcon from '@/assets/svg/sale.svg'
import NoData from '@/components/NoData.vue'

export default {
  name: 'TenantSalesPage',
  components: {
    Breadcrumb,
    FilterTimeRange,
    TableComponent,
    TitleComponent,
    ExportCSVComponent,
    Card,

    Currency,
    ComparisonItem,
    SaleIcon,
    ReportLineChart,
    NoData,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()
    const periodicFilter = ref('daily')
    const tenantIds = ref([])

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
      periodicFilter,
      tenantIds,
    }
  },
  data: () => ({
    tenantSalesReportHeader: [
      {
        item: 'page.report.tenantSales.tableHeaders.tenant',
        customClass: '',
      },
      {
        item: 'page.report.tenantSales.tableHeaders.totalSales',
        customClass: '',
      },
      {
        item: 'page.report.tenantSales.tableHeaders.percentSales',
        customClass: 'text-center',
      },
      {
        item: 'page.report.tenantSales.tableHeaders.totalTransaction',
        customClass: '',
      },
      {
        item: 'page.report.tenantSales.tableHeaders.percentTransaction',
        customClass: 'text-center',
      },
    ],
    tenantPickHeader: [
      {
        item: '',
        customClass: '',
      },
      {
        item: 'page.report.tenantSales.tableHeaders.tenant',
        customClass: 'text-center',
      },
    ],
    cardData: {
      gross_sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      net_sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      payment_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      product_total: {
        total: 0,
        percent: '%',
        is_up: false,
      },
      transaction_total: {
        total: 0,
        percent: '%',
        is_up: false,
      },
    },
    tenantSalesReportData: [],
    tenantData: [],
    tenantDataAll: [],
    tenantDataMapped: [],
    activeTenantData: [],
    showTenantModal: false,
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: false,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    metaDataTenant: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataTenant: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
    tenantSalesChartEmpty: true,
    tenantSalesChartLabel: [],
    tenantSalesChartItemName: [],
    tenantSalesChartData: [],
    isTenantUser: false,
    activeTenant: {},
    showWarningFilter: false,
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
    tenantId() {
      return this.tenantIds.toString()
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.init()
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    this.getAllTenant(true)
    this.init()
    const activeUser = this.store.getters['auth/activeUser']
    if (activeUser.tenant !== undefined) {
      this.activeTenant = activeUser.tenant
      this.isTenantUser = true
    }
  },
  methods: {
    init() {
      this.getSummary()
      this.getSalesTenant()
    },
    async getAllTenant(isFirst = false) {
      await this.store
        .dispatch('tenant/getAll', {
          order: this.filterDataTenant.order,
          page: this.filterDataTenant.page,
          take: this.filterDataTenant.take,
          q: this.filterDataTenant.search,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.tenantData = responseData.data
            this.tenantDataAll.push(...new Set(responseData.data))
            this.tenantDataUnique = this.uniqByKeepLast(
              this.tenantDataAll,
              (it) => it.id
            )
            if (isFirst) {
              if (this.activeTenant.id) {
                this.tenantIds.push(this.activeTenant.id)
                this.getSalesTenantChart()
              } else if (this.tenantData.length > 0) {
                this.tenantIds.push(this.tenantData[0].id)
                this.getSalesTenantChart()
              }
            }
            const tenantDataReduced = this.tenantDataUnique.reduce(
              (filtered, item) => {
                let key = item.id
                if (!filtered[key]) {
                  filtered[key] = null
                }
                filtered[key] = item
                return filtered
              },
              {}
            )
            this.tenantDataMapped = tenantDataReduced
          }
          if (responseData.meta) {
            this.metaDataTenant = responseData.meta
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    async getSummary() {
      this.isLoading = true

      await this.$store
        .dispatch('tenant/salesSummary', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.cardData.gross_sales_total = responseData.gross_sales_total
            this.cardData.net_sales_total = responseData.net_sales_total
            this.cardData.payment_total = responseData.payment_total
            this.cardData.transaction_total = responseData.transaction_total
            this.cardData.product_total = responseData.product_total
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    async getSalesTenant() {
      await this.store
        .dispatch('tenant/salesTenant', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          page: this.filterData.page,
          take: this.filterData.take,
          tenant_name: this.filterData.search,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.tenantSalesReportData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    async getSalesTenantChart() {
      await this.store
        .dispatch('tenant/salesTenantChart', {
          periodic: this.periodicFilter,
          tenant_ids: this.tenantId,
        })
        .then((response) => {
          const responseData = response.data
          if (responseData.data) {
            const filteredChart = responseData.data.reduce((filtered, item) => {
              let key = item.tenant_id
              if (!filtered[key]) {
                filtered[key] = null
              }
              filtered[key] = item.charts
              return filtered
            }, {})

            this.tenantSalesChartEmpty = true
            this.tenantSalesChartItemName = []
            this.activeTenantData = []
            this.tenantSalesChartData = []
            this.tenantIds.forEach((item, index) => {
              if (this.tenantDataMapped[item] !== undefined) {
                this.activeTenantData.push(this.tenantDataMapped[item])
                this.tenantSalesChartItemName.push(
                  this.tenantDataMapped[item].name.toUpperCase()
                )
              }

              if (filteredChart[item] !== undefined) {
                this.tenantSalesChartEmpty = false
              }
              let labels = []
              let values = []
              filteredChart[item].forEach((item) => {
                labels.push(item.name)
                values.push(item.total)
              })
              if (index === 0) {
                this.tenantSalesChartLabel = labels
              }
              this.tenantSalesChartData.push(values)
            })
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(e)
        })
    },
    async downloadSalesTenant(fileType) {
      await this.store
        .dispatch('tenant/salesTenantDownload', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'report_sales.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          // this.showToast('failed')
          console.log(this.message)
        })
    },
    changeFilterTimeRange(event) {
      this.periodicFilter = event
      this.getSalesTenantChart()
    },
    saveTenantIds() {
      if (this.tenantIds.length > 0) {
        if (this.tenantIds.length > 6) {
          this.showWarningFilter = true
          return
        }
        this.showWarningFilter = false
        this.showTenantModal = false
        this.getSalesTenantChart()
      }
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getSalesTenant()
    },
    changeFilterTenant(event) {
      this.filterDataTenant = event.filterData
      this.getAllTenant()
    },
    selectTenant(event, tenantId) {
      if (event.target.checked) {
        this.tenantIds.push(tenantId)
      } else {
        this.tenantIds = this.tenantIds.filter((item) => item != tenantId)
      }
    },
    deleteComparison(event) {
      this.tenantIds = this.tenantIds.filter((item) => item != event.item)
      this.getSalesTenantChart()
    },
  },
}
</script>

export default {
  data: () => ({
    additionalFeeData: [],
    additionalFeeDetailData: {},
    additionalFeeTypes: ['pajak', 'service', 'institute_fee'], //, 'lainnya'

    additionalFeeReportData: [],
    additionalFeeCardData: {
      tax_total: 0,
      service_total: 0,
      institute_fee_total: 0,
      other_fee_total: 0,
      transaction_total: 0,
    },

    // Forms
    additionalFeeId: '',
    additionalFeeName: '',
    additionalFeeType: '',
    additionalFeeAdvanceType: '',
    additionalFeeAmountType: 'percentage',
    additionalFeeAmountTypeIsPercent: true,
    additionalFeeAmount: '',
    additionalFeeItems: [],
    additionalFeeIsActive: 0,

    // Metadata and Filterdata
    metaDataAdditionalFee: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataAdditionalFee: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },

    metaDataAdditionalFeeReport: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataAdditionalFeeReport: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
  }),
  methods: {
    // BEGIN: ADDITIONAL FEE
    async getAllAdditionalFee(
      props = {
        showMessage: true,
        setLoading: true,
        search: '',
        setMetaData: true,
      }
    ) {
      if (props.setLoading) this.isLoading = true

      const params = {
        page: this.filterDataAdditionalFee.page,
        take: this.filterDataAdditionalFee.take,
        sortBy: `name:${this.filterDataAdditionalFee.order}`,
      }
      if (this.filterDataAdditionalFee.search !== '') {
        params.criteria = `name:${this.filterDataAdditionalFee.search}`
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('tax/getAllAdditionalFee', params)
        .then((response) => {
          if (props.setLoading) this.isLoading = false

          const responseData = response.data.data
          this.additionalFeeData = responseData.data

          if ('meta' in responseData) {
            if (props.setMetaData) {
              this.metaDataAdditionalFee = responseData.meta
            }
          }

          if (props.showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (props.setLoading) this.isLoading = false

          if (props.showMessage) {
            const message = e.response.data.message
            this.message = this.handleErrorMessage(message)

            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getAdditionalFeeDetailAPI(id, showMessage = true) {
      this.isLoading = true
      await this.$store
        .dispatch('tax/getAdditionalFeeDetail', { id })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.additionalFeeDetailData = responseData

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          if (showMessage) {
            const message = e.response.data.message
            this.message = this.handleErrorMessage(message)

            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async saveAdditionalFeeAPI(params, isUpdate = false) {
      let dispatcher = 'tax/createAdditionalFee'
      if (isUpdate) dispatcher = 'tax/updateAdditionalFee'

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteAdditionalFeeAPI(id) {
      this.isLoadingSave = true
      await this.$store
        .dispatch('tax/deleteAdditionalFee', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async setAdditionalFeeStatusAPI(payload) {
      this.isLoading = true
      await this.$store
        .dispatch('tax/setAdditionalFeeStatus', payload)
        .then((response) => {
          this.isLoading = false

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)

          this.resetData()
        })
        .catch((e) => {
          this.isLoading = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    // END: ADDITIONAL FEE

    // BEGIN: ADDITIONAL FEE REPORT
    async getAdditionalFeeReport(first = false, filterDates) {
      const params = {
        start_date: filterDates.start,
        end_date: filterDates.end,
        page: this.filterDataAdditionalFeeReport.page,
        take: this.filterDataAdditionalFeeReport.take,
        sortBy: `created_at:${this.filterDataAdditionalFeeReport.order}`,
      }
      if (this.filterDataAdditionalFeeReport.search !== '') {
        params.criteria = `id:${this.filterDataAdditionalFeeReport.search}`
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }
      await this.store
        .dispatch('tax/additionalFeeReport', params)
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.additionalFeeReportData = responseData.data
          }
          if (responseData.meta) {
            this.metaDataAdditionalFeeReport = responseData.meta
            this.metaDataAdditionalFeeReport.pageCount = Math.abs(
              responseData.meta.pageCount
            )
          }

          if (!first) {
            this.message = response.data.message

            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getAdditionalFeeReportSummary(first = false, filterDates) {
      this.isLoading = true

      await this.$store
        .dispatch('tax/additionalFeeReportSummary', {
          start_date: filterDates.start,
          end_date: filterDates.end,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          this.additionalFeeCardData = responseData

          if (!first) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async downloadAdditionalFeeReport(fileType, filterDates) {
      await this.store
        .dispatch('tax/additionalFeeReportDownload', {
          start_date: filterDates.start,
          end_date: filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'additional_fee_report.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    // END: ADDITIONAL FEE REPORT
  },
}

export default {
  data: () => ({
    menuData: [],
    activeMenu: '',
    activeMenuName: '',
    featureData: [],
    featureDataActiveMenu: [],
    featureReads: [],
    featureWrites: [],
    featureEdits: [],
    featureDeletes: [],
    activeMenuFeature: '',

    metaData: {
      page: 1,
      take: 10,
      itemCount: 50,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
  }),
  methods: {
    async getAllMenu(
      showMessage = true,
      setLoading = true,
      setMetaData = true,
      setActiveData = true
    ) {
      if (setLoading) this.isLoading = true
      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('menu/getAllMenu', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            this.menuData = responseData.data
            if (setActiveData && this.menuData.length > 0) {
              this.activeMenu = this.menuData[0].id
              this.activeMenuName = this.menuData[0].name
            }
          }
          if (responseData.meta && setMetaData) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getAllMenuFeature(
      showMessage = true,
      setLoading = true,
      setMetaData = true
    ) {
      if (setLoading) this.isLoading = true
      const params = {
        order: this.filterData.order,
        page: this.filterData.page,
        take: this.filterData.take,
        q: this.filterData.search,
      }
      if (this.activeMenu !== '') {
        params.menu_id = this.activeMenu
      }

      await this.$store
        .dispatch('menu/getAllMenuFeature', params)
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            this.featureData = responseData.data
            if (this.activeMenu !== '') {
              this.featureDataActiveMenu.push({
                id: this.activeMenu,
                values: this.featureData,
              })
            }
          }
          if (responseData.meta && setMetaData) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          const message = e.response.data.message
          if (typeof message === 'string') {
            this.message = message
          } else {
            this.message = message.detail
          }

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}

<template>
  <div class="relative flex items-center border-t-2 py-4">
    <p class="absolute -top-3 w-full text-center">
      <span
        class="bg-white px-2 text-xs text-gray-500 dark:bg-dark dark:text-gray-50"
      >
        {{ date }}
      </span>
    </p>

    <div
      class="flex w-full flex-col-reverse space-y-2 rounded-lg border px-2 py-2 shadow-md"
    >
      <div
        v-for="(shifData, index) in shift.slice().reverse()"
        :key="shifData.id"
        :data-id="shifData.id"
        class="flex cursor-pointer items-center justify-between px-2 py-2"
        :class="[
          index === shifData.length - 1 ? '' : 'border-t pt-4',
          activeShiftId === shifData.id
            ? 'bg-primary-150 rounded-lg border border-primary'
            : '',
        ]"
        @click="toShiftDetail(shifData.id)"
      >
        <div class="space-y-1">
          <p class="space-x-4 font-bold">
            <span>Shift {{ index + 1 }}</span>
            <span
              v-if="shifData.status !== 'DONE'"
              class="rounded-sm bg-green-100 px-4 py-1 text-xs text-green-400"
            >
              {{ $t('page.shiftHistory.openShift') }}
            </span>
          </p>
          <p class="text-xs text-gray-500">{{ shifData.user.full_name }}</p>
          <p class="text-xs text-gray-500">
            <span v-if="shifData.start_time">
              {{ shifData.start_time }}
            </span>
            <span v-if="shifData.end_time && shifData.status === 'DONE'">
              -
              {{ shifData.end_time }}
            </span>
          </p>
        </div>

        <div
          v-if="shifData.status !== 'DONE'"
          class="flex items-center space-x-2 rounded-full bg-red-400 px-2 py-1 font-semibold text-white"
        >
          <p class="rounded-full bg-white px-[6px] text-xs">&nbsp;</p>
          <p>On Going</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ShiftItem',
  props: {
    shift: {
      type: Object,
      required: true,
    },
    activeShiftId: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      required: true,
    },
  },
  emits: ['shiftDetail'],
  methods: {
    toShiftDetail(id) {
      this.$emit('shiftDetail', id)
    },
  },
}
</script>

export default {
  data: () => ({
    provinces: [],
    selectedProvince: null,
    provinceFilterData: {
      page: 1,
      take: 20,
      search: '',
      order: 'DESC',
    },

    cities: [],
    selectedCity: null,
    cityFilterData: {
      page: 1,
      take: 20,
      search: '',
      order: 'DESC',
    },

    districts: [],
    selectedDistrict: null,
    districtFilterData: {
      page: 1,
      take: 20,
      search: '',
      order: 'DESC',
    },

    villages: [],
    selectedVillage: null,
    villageFilterData: {
      page: 1,
      take: 20,
      search: '',
      order: 'DESC',
    },

    postalCode: '',
  }),
  methods: {
    async getProvince() {
      const params = {
        order: this.provinceFilterData.order,
        page: this.provinceFilterData.page,
        take: this.provinceFilterData.take,
        q: this.provinceFilterData.search,
      }
      await this.$store
        .dispatch('address/province', params)
        .then((response) => {
          if ('data' in response.data) {
            const provinceTemporary = []
            response.data.data.forEach((item) => {
              provinceTemporary.push({
                value: item.province_code,
                ...item,
              })
            })
            this.provinces = [...this.provinces, ...provinceTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('province')
            }
          }
        })
        .catch((error) => {})
    },
    changeProvince() {
      this.getCity(this.selectedProvince)
    },

    async getCity(provinceId = null) {
      const params = {
        order: this.cityFilterData.order,
        page: this.cityFilterData.page,
        take: this.cityFilterData.take,
        q: this.cityFilterData.search,
        province_id: provinceId === null ? this.selectedProvince : provinceId,
      }
      await this.$store
        .dispatch('address/city', params)
        .then((response) => {
          if ('data' in response.data) {
            const cityTemporary = []
            response.data.data.forEach((item) => {
              cityTemporary.push({
                value: item.city_code,
                ...item,
              })
            })
            if (this.cityFilterData.page === 1) {
              this.cities = []
              this.cities = [...cityTemporary]
            } else {
              this.cities = [...this.cities, ...cityTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('city')
            }
          }
        })
        .catch((error) => {})
    },
    async getDistrict() {
      const params = {
        order: this.districtFilterData.order,
        page: this.districtFilterData.page,
        take: this.districtFilterData.take,
        q: this.districtFilterData.search,
        city_code: this.selectedCity,
      }
      await this.$store
        .dispatch('address/district', params)
        .then((response) => {
          if ('data' in response.data) {
            const districtTemporary = []
            response.data.data.forEach((item) => {
              districtTemporary.push({
                value: item.district_code,
                ...item,
              })
            })
            if (this.districtFilterData.page === 1) {
              this.districts = []
              this.districts = [...districtTemporary]
            } else {
              this.districts = [...this.districts, ...districtTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('district')
            }
          }
        })
        .catch((error) => {})
    },
    async getVillage() {
      const params = {
        order: this.villageFilterData.order,
        page: this.villageFilterData.page,
        take: this.villageFilterData.take,
        q: this.villageFilterData.search,
        district_code: this.selectedDistrict,
      }
      await this.$store
        .dispatch('address/district', params)
        .then((response) => {
          if ('data' in response.data) {
            const villageTemporary = []
            response.data.data.forEach((item) => {
              villageTemporary.push({
                value: item.village_code,
                ...item,
              })
            })
            if (this.villageFilterData.page === 1) {
              this.villages = []
              this.villages = [...villageTemporary]
            } else {
              this.villages = [...this.villages, ...villageTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('village')
            }
          }
        })
        .catch((error) => {})
    },
    loadMores(type) {
      if (type === 'province') {
        this.provinceFilterData.page += 1
        this.getProvince()
      } else if (type === 'city') {
        this.cityFilterData.page += 1
        this.getCity()
      } else if (type === 'district') {
        this.districtFilterData.page += 1
        this.getDistrict()
      } else if (type === 'village') {
        this.villageFilterData.page += 1
        this.getVillage()
      }
    },
  },
}

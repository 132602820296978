import axiosCore, { routePathAPICore } from '@/plugins/axios-core.js'
import router from '@/router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  summary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
        tenant_id: payload.tenant_id ? payload.tenant_id : '',
      }
      axiosCore
        .get(`${routePathAPICore()}/transaction/summary`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        tenant_id: payload.tenant_id ? payload.tenant_id : '',
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
      }
      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  topAndLeastSelling({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
        limit: 5,
      }
      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      axiosCore
        .get(`${routePathAPICore()}/analytic/top-and-least-selling`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  peakHourChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}

      if (payload.institute_id) params.institute_id = payload.institute_id
      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
      if (payload.periodic) params.periodic = payload.periodic.toUpperCase()
      if (payload.start_date) params.start_date = payload.start_date
      if (payload.end_date) params.end_date = payload.end_date

      axiosCore
        .get(`${routePathAPICore()}/analytic/peak-hour/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: responseData.message,
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

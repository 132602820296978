<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.payment')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <div
          v-if="activeUserStore.role.role !== 'Tenant Admin'"
          class="col-span-12 mt-6 space-x-2 bg-white p-5 text-center dark:bg-slate-900 md:p-10"
        >
          <InfoIcon class="inline w-5" />
          <span>{{ $t('info.onlyAccess', { user: 'Tenant Admin' }) }}</span>
        </div>

        <!-- BEGIN: OmniChannel List -->
        <TableComponent
          v-else
          :title="$t($route.meta.title)"
          :header="omniChannelHeader"
          :is-show-search="true"
          :is-show-button-edit="true"
          :is-show-add="true"
          :is-not-empty="omnichannelData.length > 0"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :meta-data="metaData"
          @change-filter="changeFilter($event)"
          @do-add="showModalAdd"
          @is-edited="edited"
          @cancel-change="cancelEdited"
          @save-change="saveEdited"
        >
          <tr v-for="omni in omnichannelData" :key="omni.id">
            <td class="text-center">
              {{ omni.name }}
            </td>
            <td class="text-center">
              <p class="space-x-1 text-sm text-primary">
                <ArrowUpIcon class="inline w-4" />
                <span v-if="omni.type === 'PERCENTAGE'">
                  {{
                    $t('page.omniChannel.feePercent', {
                      percent: omni.channel_fee,
                    })
                  }}
                </span>
                <span v-else>
                  {{
                    $t('page.omniChannel.feePrice', {
                      price: omni.channel_fee,
                    })
                  }}
                </span>
              </p>
            </td>
            <td class="text-center">
              <template v-if="isEdit">
                <div class="form-switch">
                  <input
                    v-model="omni.is_active"
                    type="checkbox"
                    class="form-check-input"
                    @change="changeActiveStatusOmni($event, omni.id)"
                  />
                </div>
              </template>
              <template v-else>
                <p
                  v-if="omni.is_active"
                  class="rounded-full bg-green-700 px-5 text-white md:px-0"
                >
                  {{ $t('info.active') }}
                </p>
                <p
                  v-else
                  class="rounded-full bg-red-700 px-5 text-white md:px-0"
                >
                  {{ $t('info.inActive') }}
                </p>
              </template>
            </td>
            <td v-if="isEdit" class="space-x-2">
              <span
                class="cursor-pointer font-medium text-primary"
                @click="showModalEdit(omni)"
              >
                {{ $t('action.edit') }}
              </span>
              <span
                class="cursor-pointer font-medium text-danger"
                @click="showModalDelete(omni.id)"
              >
                {{ $t('action.delete') }}
              </span>
            </td>
          </tr>
        </TableComponent>
        <!-- END: OmniChannel List -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->

        <!-- BEGIN: Modal Input -->
        <Modal :show="showModal" size="modal-lg" @hidden="showModal = false">
          <ModalBody class="p-4">
            <!-- Product Form -->
            <template v-if="addItemMode">
              <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
                <div class="flex flex-col space-y-3">
                  <div class="flex justify-between">
                    <p class="font-bold">
                      {{ $t('page.omniChannel.input.itemAffected') }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-col space-y-4">
                  <div class="flex shadow">
                    <input
                      v-model="searchProduct"
                      class="w-full border-none p-2 outline-none"
                      type="text"
                      :placeholder="$t('formLabel.searchFoo', { foo: 'Item' })"
                    />
                    <button
                      class="text-blue hover:text-blue-light flex w-auto items-center justify-end p-2"
                      @click="
                        searchingProduct(
                          'productCategory',
                          'local',
                          searchProduct
                        )
                      "
                    >
                      <SearchIcon />
                    </button>
                  </div>
                  <template v-if="productData.length > 0">
                    <div class="mr-auto flex items-center">
                      <input
                        id="check-all"
                        type="checkbox"
                        class="form-check-input mr-2 border"
                        @change="checkAll"
                      />
                      <label class="cursor-pointer select-none" for="check-all">
                        {{ $t('formInfo.selectAll') }}
                      </label>
                    </div>
                    <AccordionGroup>
                      <AccordionItem
                        v-for="(products, category) in productWithCategory"
                        :key="category"
                      >
                        <Accordion>
                          <div class="flex justify-between">
                            <p>
                              {{ category }}
                            </p>
                            <p class="text-xs">
                              {{
                                $t('info.totalItems', {
                                  total: products.length,
                                })
                              }}
                            </p>
                          </div>
                        </Accordion>
                        <AccordionPanel
                          class="space-y-4 leading-relaxed text-slate-600 dark:text-slate-500"
                        >
                          <div
                            v-for="product in products"
                            :key="product.id"
                            class="flex items-center justify-between"
                          >
                            <div class="flex items-center space-x-2">
                              <ImageLoader
                                :image="null"
                                :alt="product.name"
                                rounded-class="rounded-lg"
                                height="h-12"
                              />
                              <div class="flex flex-col space-y-2 text-left">
                                <p>{{ product.name }}</p>
                                <div class="flex items-center space-x-2">
                                  <Currency
                                    :total-in-string="
                                      countOmniChannel(product.price)
                                    "
                                  />
                                  <Currency
                                    :total-in-string="product.price"
                                    wrap-class="line-through text-red-500"
                                  />
                                  <p
                                    class="bg-primary-150 flex items-center space-x-1 rounded-lg px-2 py-1 text-sm text-primary"
                                  >
                                    <ArrowUpIcon class="inline w-4" />
                                    <span v-if="feeType === 'PERCENTAGE'">
                                      {{ channelFee }} %
                                    </span>
                                    <Currency
                                      v-else
                                      :total-in-string="channelFee"
                                      prefix-text="+"
                                    />
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <input
                                :id="`check-${product.id}`"
                                type="checkbox"
                                class="form-check-input border"
                                :checked="
                                  checkedAll ||
                                  checkedProduct.includes(product.id)
                                "
                                @change="checkProduct($event, product)"
                              />
                            </div>
                          </div>
                        </AccordionPanel>
                      </AccordionItem>
                    </AccordionGroup>
                  </template>
                  <div
                    v-else
                    class="flex w-full justify-center pt-5 text-center"
                  >
                    {{ $t('info.dataNotFound') }}
                  </div>
                </div>
              </div>
              <div class="flex justify-end space-x-2 px-5 pb-8">
                <button
                  type="button"
                  class="btn w-24"
                  @click="cancelProductOmni"
                >
                  {{ $t('button.cancel') }}
                </button>
                <button
                  type="button"
                  class="btn btn-primary w-24"
                  @click="saveProductOmni"
                >
                  {{ $t('button.save') }}
                </button>
              </div>
            </template>
            <!-- End of Product Form -->

            <!-- Omnichannel Form -->
            <template v-else>
              <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
                <div class="flex flex-col space-y-3">
                  <div class="flex justify-between">
                    <p class="font-bold">
                      <span v-if="!isEdit">
                        {{ $t('page.omniChannel.modal.add') }}
                      </span>
                      <span v-else>
                        {{ $t('page.omniChannel.modal.edit') }}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="flex flex-col space-y-4">
                  <div class="flex w-full flex-col space-y-2">
                    <div>
                      <label
                        class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                        for="omniChannel-name"
                      >
                        {{ $t('page.omniChannel.input.name') }}
                      </label>
                    </div>
                    <div>
                      <input
                        id="omniChannel-name"
                        v-model="name"
                        required="required"
                        class="w-full appearance-none rounded border-2 py-2 px-4 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                        type="text"
                        :placeholder="$t('page.omniChannel.input.name')"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex justify-between">
                  <div>
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="active"
                    >
                      {{ $t('page.omniChannel.input.active') }}
                    </label>
                  </div>
                  <div>
                    <div class="form-switch">
                      <input
                        v-model="isActive"
                        type="checkbox"
                        class="form-check-input"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="false" class="flex justify-between">
                  <div>
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="active"
                    >
                      {{ $t('page.omniChannel.input.posPaymentChannel') }}
                    </label>
                  </div>
                  <div>
                    <div class="form-switch">
                      <input
                        v-model="posPaymentChannel"
                        type="checkbox"
                        class="form-check-input"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex flex-col">
                  <div>
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="active"
                    >
                      {{ $t('page.omniChannel.input.fee') }}
                    </label>
                  </div>

                  <div class="flex justify-start space-x-2">
                    <input
                      id="omniChannel-fee"
                      v-model="channelFee"
                      required="required"
                      class="w-full appearance-none rounded border-2 py-2 px-4 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      type="number"
                      :placeholder="$t('page.omniChannel.input.fee')"
                    />
                    <select
                      v-model="feeType"
                      required="required"
                      class="w-full appearance-none rounded border-2 py-2 px-4 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                    >
                      <option
                        v-for="fType in feeTypes"
                        :key="fType.value"
                        :value="fType.value"
                      >
                        {{ fType.label }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="flex w-full flex-col space-y-2">
                  <div class="flex w-full">
                    <label class="font-bold">
                      {{ $t('page.omniChannel.input.itemAffected') }}
                    </label>
                  </div>
                  <div
                    class="flex w-full cursor-pointer space-x-2 text-gray-400 hover:text-gray-600"
                    @click="addItemMode = true"
                  >
                    <p>+ {{ $t('page.omniChannel.input.addItem') }}</p>
                  </div>
                  <div
                    v-if="omniChannelProducts.length > 0"
                    class="flex w-full flex-col space-y-6"
                  >
                    <div
                      v-for="omni in omniChannelProducts"
                      :key="omni.id"
                      class="flex w-full"
                    >
                      <div
                        v-if="omni.product"
                        class="flex w-full items-center justify-between space-x-2"
                      >
                        <div class="flex items-center space-x-2">
                          <ImageLoader
                            :image="null"
                            :alt="omni.product.name"
                            rounded-class="rounded-lg"
                            height="h-12"
                          />
                          <div class="flex flex-col space-y-2 text-left">
                            <p>{{ omni.product.name }}</p>
                            <div class="flex items-center space-x-2">
                              <Currency
                                :total-in-string="
                                  countOmniChannel(omni.product.price)
                                "
                              />
                              <Currency
                                :total-in-string="omni.product.price"
                                wrap-class="line-through text-red-500"
                              />
                              <p
                                class="bg-primary-150 flex items-center space-x-1 rounded-lg px-2 py-1 text-sm text-primary"
                              >
                                <ArrowUpIcon class="inline w-4" />
                                <span v-if="feeType === 'PERCENTAGE'">
                                  {{ channelFee }} %
                                </span>
                                <Currency
                                  v-else
                                  :total-in-string="channelFee"
                                  prefix-text="+"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <TrachIcon
                          class="inline cursor-pointer text-danger hover:text-gray-500"
                          @click="deleteProductOmni(omni.product.id)"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- <NoData v-else /> -->
                </div>
              </div>
              <div v-if="showWarning" class="flex w-full py-2 text-red-500">
                <p>{{ warning }}</p>
              </div>
              <div class="flex justify-end space-x-2 px-5 pb-8">
                <button
                  type="button"
                  class="btn w-24"
                  @click="showModal = false"
                >
                  {{ $t('button.cancel') }}
                </button>
                <button
                  type="button"
                  class="btn btn-primary w-24"
                  @click="saveOmnichannel"
                >
                  {{ $t('button.save') }}
                </button>
              </div>
            </template>
            <!-- End of Omnichannel Form -->
          </ModalBody>
        </Modal>
        <!-- END: Modal Input -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->

        <DeleteModal
          :id="deleteId"
          :show-modal="showModalDel"
          @yes="confirmDelete($event)"
          @no="showModalDel = false"
        />

        <LoadingIndicator v-if="isLoading || isLoadingSave" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import debounce from 'lodash.debounce'
import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import omnichannelMixin from '@/mixins/omnichannel.js'
import productMixin from '@/mixins/product.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'

import DeleteModal from '@/components/DeleteModal.vue'

import TableComponent from '@/components/TableComponent.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import Currency from '@/components/Currency.vue'

import TrachIcon from '@/assets/svg/trash.svg'

export default {
  name: 'OmniChanelPage',
  components: {
    Breadcrumb,
    TableComponent,
    DeleteModal,

    ImageLoader,
    Currency,
    TrachIcon,
  },
  mixins: [globalMixin, tenantMixin, omnichannelMixin, productMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()

    const dateFilter = ref('')

    return {
      t,
      n,
      store,
      dateFilter,
    }
  },
  data: () => ({
    omniChannelHeader: [],
    omniChannelFieldHeader: [
      {
        item: 'page.omniChannel.tableHeaders.name',
        customClass: 'text-center',
      },
      {
        item: 'page.omniChannel.tableHeaders.platformFee',
        customClass: 'text-center',
      },
      {
        item: 'page.omniChannel.tableHeaders.status',
        customClass: 'text-center',
      },
    ],
    omniChannelEditHeader: [
      {
        item: 'action.action',
        customClass: '',
      },
    ],
    addItemMode: false,

    checkedAll: false,
    checkedProduct: [],
  }),
  watch: {
    addItemMode(val) {
      if (!val) {
        this.checkedAll = false
        this.searchProduct = ''
        this.mappingProductData(this.productResponseData)
        this.mappingProductWithCategory(this.productResponseData)
      }
    },
    searchProduct: debounce(function (newVal, oldVal) {
      if (newVal.length === 0) {
        this.searchingProduct('productCategory', 'local', '')
      } else if (newVal !== oldVal && newVal.length >= 2) {
        this.searchingProduct('productCategory', 'local', this.searchProduct)
      }
    }, 500),
  },
  mounted() {
    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        this.getAllOmnichannel(false, this.tenantId, true)
        this.getAllProduct(false, true, false, '', false, true)
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          this.getAllOmnichannel(false, this.tenantId, true)
          this.getAllProduct(false, true, false, '', false, true)
        })
        this.isInstitute = true
      }
    }
    this.omniChannelHeader = [...this.omniChannelFieldHeader]
  },
  methods: {
    changeFilter(event) {
      this.filterData = event.filterData
      this.getAllOmnichannel(false, this.tenantId, true)
    },
    resetData(afterSave = true) {
      this.omnichannelId = ''
      // this.isActive = false
      this.omniChannelProducts = []
      this.name = ''
      this.channelFee = 0
      this.feeType = 'PERCENTAGE'

      if (afterSave) {
        this.isLoadingSave = false
        this.showModal = false

        // this.cancelEdited()
        this.getAllOmnichannel(false, this.tenantId, true)

        // this.searchProduct = ''
        // this.mappingProductData(this.productResponseData)
        // this.mappingProductWithCategory(this.productResponseData)
      }
    },
    confirmDelete(id) {
      this.deleteOmnichannelAPI(id)
    },
    showModalAdd() {
      this.showModal = true
      // this.cancelEdited()
      this.resetData(false)
    },
    showModalEdit(data) {
      this.getDetailOmnichannel(true, data.id)
    },
    saveOmnichannel() {
      if (this.name === '' || this.channelFee === '' || this.isLoadingSave) {
        return
      }

      // if (this.feeType === 'PERCENTAGE' && this.channelFee > 0) {
      //   this.warning = ''
      //   this.showWarning = true
      // }

      const params = {
        name: this.name,
        tenant_id: this.tenantId,
        description: '',
        channel_fee: this.channelFee,
        type: this.feeType,
        product_ids: this.productIds,
      }
      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.omniChannelId === '') {
          this.isLoadingSave = false
          return
        }
        params.id = this.omniChannelId

        this.saveOmnichannelAPI(params, true).then(() => {
          this.updateOmnichannelStatusAPI({
            id: this.omniChannelId,
            active: this.isActive,
          }).then(() => {
            const omniChannel = this.omnichannelData.find(
              (item) => item.id == this.omniChannelId
            )
            if (omniChannel !== undefined && omniChannel !== null) {
              omniChannel.is_active = this.isActive
            }
          })
        })
      } else {
        this.saveOmnichannelAPI(params)
      }
    },
    edited() {
      this.isEdit = true
      this.omniChannelHeader.push(...this.omniChannelEditHeader)
    },
    cancelEdited() {
      this.isEdit = false
      this.omniChannelHeader = [...this.omniChannelFieldHeader]
    },
    saveEdited() {
      this.checkedIds.forEach((id) => {
        this.updateOmnichannelStatusAPI({
          id: id,
          active: true,
        })
      })
      this.unChekedIds.forEach((id) => {
        this.updateOmnichannelStatusAPI({
          id: id,
          active: false,
        })
      })

      this.isEdit = false
      this.omniChannelHeader = [...this.omniChannelFieldHeader]
    },
    countOmniChannel(basePrice) {
      const baseFloat = parseFloat(basePrice)
      if (this.feeType === 'PERCENTAGE') {
        const additionalPrice = (parseFloat(this.channelFee) * baseFloat) / 100
        return Math.round(baseFloat + additionalPrice)
      } else {
        return Math.round(baseFloat + parseFloat(this.channelFee))
      }
    },
    changeActiveStatusOmni($event, id) {
      if ($event.target.checked) {
        if (!this.checkedIds.includes(id)) {
          this.checkedIds.push(id)
        }

        if (this.unChekedIds.includes(id)) {
          let index = this.unChekedIds.indexOf(id)
          if (index !== -1) {
            this.unChekedIds.splice(index, 1)
          }
        }
      } else {
        if (!this.unChekedIds.includes(id)) {
          this.unChekedIds.push(id)
        }

        if (this.checkedIds.includes(id)) {
          let index = this.checkedIds.indexOf(id)
          if (index !== -1) {
            this.checkedIds.splice(index, 1)
          }
        }
      }
    },
    checkAll($event) {
      this.checkedAll = $event.target.checked
      if (this.checkedAll) {
        this.productData.forEach((item) => {
          this.checkProduct(
            {
              target: {
                checked: true,
              },
            },
            item
          )
        })
      }
    },
    checkProduct($event, product) {
      if ($event.target.checked) {
        if (!this.checkedProduct.includes(product.id)) {
          this.checkedProduct.push(product.id)
          this.omniChannelProducts.push({ product: product })
        }
      } else {
        if (this.checkedProduct.includes(product.id)) {
          let index = this.checkedProduct.indexOf(product.id)
          if (index !== -1) {
            this.checkedProduct.splice(index, 1)
          }
        }
      }
    },
    cancelProductOmni() {
      this.addItemMode = false
    },
    saveProductOmni() {
      // if (this.checkedAll) {
      //   const dataIds = this.productData.map((item) => {
      //     return item.id
      //   })

      //   this.productIds = dataIds.toString()
      // } else {
      this.productIds = this.checkedProduct.toString()
      // }

      this.addItemMode = false
    },
    deleteProductOmni(id) {
      this.omniChannelProducts = this.omniChannelProducts.filter(
        (item) => item.product.id !== id
      )

      if (this.checkedProduct.includes(id)) {
        let index = this.checkedProduct.indexOf(id)
        if (index !== -1) {
          this.checkedProduct.splice(index, 1)
        }
      }

      this.productIds = this.checkedProduct.toString()
    },
  },
}
</script>

<template>
  <div>
    <DarkModeSwitcher />
    <div class="container">
      <div
        class="error-page flex h-screen flex-col items-center justify-center text-center lg:flex-row lg:text-left"
      >
        <div class="text-white">
          <div class="intro-x text-8xl font-medium">
            {{ appVersion }}
          </div>
          <div class="intro-x mt-5 text-xl font-medium lg:text-3xl">
            {{ $t('info.currentVersion') }}
          </div>

          <button
            class="intro-x btn mt-10 border-white py-3 px-4 text-white dark:border-darkmode-400 dark:text-slate-200"
            @click="openURL('dashboard')"
          >
            {{ $t('button.backToHome') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dom from '@left4code/tw-starter/dist/js/dom'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'

export default {
  name: 'VersionPage',
  components: {
    DarkModeSwitcher,
  },
  data() {
    return {
      appVersion: '',
    }
  },
  mounted() {
    this.appVersion = import.meta.env.VITE_APP_VERSION
    dom('body').removeClass('main').removeClass('login').addClass('error-page')
  },
  methods: {
    openURL(pageName) {
      this.$router.push({
        name: pageName,
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>

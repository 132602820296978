<template>
  <ButtonComponent
    :wrapper-class="wrapperClass"
    :title="title === '' ? $t('action.add') : title"
  >
    <template #icon>
      <PlusIcon class="mr-2 hidden h-4 w-4 text-white sm:block" />
    </template>
  </ButtonComponent>
</template>
<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
export default {
  name: 'AddButtonComponent',
  components: {
    ButtonComponent,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
}
</script>

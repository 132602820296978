export default {
  data: () => ({
    unitResponseData: [],
    unitData: [],
    unitDataSearch: [],
    unitDataSearchOption: [],

    brandResponseData: [],
    brandData: [],
    brandDataSearch: [],
    brandDataSearchOption: [],

    categoryResponseData: [],
    categoryData: [],
    categoryDataSearch: [],
    categoryDataSearchOption: [],

    productResponseData: [],
    productData: [],
    productDataSearch: [],
    productDataSearchOption: [],
    selectedItemProductIds: [],
    selectedProductData: [],

    supplierResponseData: [],
    supplierData: [],
    supplierDataSearch: [],
    supplierDataSearchOption: [],

    purchaseStockData: [],
    purchaseStockDetailData: {},

    stockAdjustmentData: [],
    stockAdjustmentDetailData: {},

    // Form
    unitId: '',
    unitName: '',
    unitDescription: '',
    unitDecimalTotal: false,

    brandId: '',
    brandName: '',
    brandDescription: '',

    productId: '',
    productName: '',
    productBarcode: '',
    brandId: '',
    categoryId: '',
    unitId: '',
    basePrice: 0,
    sellPrice: 0,
    initialStock: 0,
    alertStock: 0,
    productIsRecommended: false,
    productIsAvailable: true,

    supplierId: '',
    supplierName: '',
    phoneNumber: '',
    supplierAddress: '',

    categoryId: '',
    categoryName: '',
    categoryDescription: '',

    purchaseStockProductError: false,
    purchaseStockSupplierId: '',
    purchaseStockNote: '',
    purchaseStockItemQuantities: [],
    purchaseStockItems: [],
    purchaseStockItemQuantitiesError: false,
    purchaseStockError: false,

    stockAdjustmentCategoryCodes: [
      { label: 'Barang masuk', value: 'STOCK_IN' },
      { label: 'Barang keluar', value: 'STOCK_OUT' },
    ],
    stockInDetailReasons: ['Stock Purchase', 'Refund/Void', 'Titip Barang'],
    stockOutDetailReasons: [
      'Hancur',
      'Kadaluarsa',
      'Hilang',
      'Rusak',
      'Terbuang',
    ],
    stockAdjustmentCategoryCode: 'STOCK_IN',
    stockAdjustmentReason: '',
    stockAdjustmentNote: '',
    stockAdjustmentItems: [],
    stockAdjustmentItemQuantities: [],
    stockAdjustmentError: false,
    stockAdjustmentProductError: false,
    stockAdjustmentItemQuantitiesError: false,

    // FILTER AND METADATA
    metaDataUnit: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataUnit: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },

    metaDataBrand: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataBrand: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },

    metaDataCategory: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataCategory: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },

    metaDataProduct: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataProduct: {
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
    },

    metaDataSupplier: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataSupplier: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },

    metaDataPurchaseStock: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataPurchaseStock: {
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
    },

    metaDataStockAdjustment: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataStockAdjustment: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
  }),
  methods: {
    // BEGIN: UNIT
    async getAllUnit(
      props = {
        showMessage: true,
        setLoading: true,
        search: '',
        setMetaData: true,
      }
    ) {
      if (props.setLoading) this.isLoading = true

      const params = {
        order: this.filterDataUnit.order,
        page: this.filterDataUnit.page,
        take: this.filterDataUnit.take,
        q: props.search === '' ? this.filterDataUnit.search : props.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }
      await this.$store
        .dispatch('retail/getAllUnit', params)
        .then((response) => {
          if (props.setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            const unitResponseData = responseData.data
            if (
              this.filterDataUnit.page !== 1 &&
              this.unitResponseData.length > 0 &&
              !props.setMetaData
            ) {
              this.unitResponseData.push(...unitResponseData)
            } else {
              this.unitResponseData = unitResponseData
            }

            if (props.search === '' || props.search === null) {
              this.mappingUnitData(this.unitResponseData)
            } else {
              this.mappingUnitData(this.unitResponseData, true)
            }
          }

          if ('meta' in responseData) {
            if (props.setMetaData) {
              this.metaDataUnit = responseData.meta
            }
          }

          if (props.showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (props.setLoading) this.isLoading = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveUnitAPI(params, isUpdate = false) {
      let dispatcher = 'retail/createUnit'
      if (isUpdate) dispatcher = 'retail/updateUnit'

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteUnitAPI(id) {
      this.isLoadingSave = true
      await this.$store
        .dispatch('retail/deleteUnit', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllUnit({
            showMessage: false,
            setLoading: true,
            search: '',
            setMetaData: true,
          })
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    mappingUnitData(unitResponseData, setSearch = false) {
      const unitData = []
      const filterUnitHasChild = unitResponseData.filter((item) => {
        return item.child_unit_total === 0
      })
      filterUnitHasChild.forEach((item) => {
        unitData.push({
          unit_name: item.name,
          unit_id: item.id,
          label: item.name,
          value: item.id,
        })
      })
      if (setSearch) {
        this.unitDataSearchOption = unitData
      } else {
        this.unitDataSearch = unitData
        this.unitData = unitResponseData
      }
    },
    // END: UNIT

    // BEGIN: BRAND
    async getAllBrand(
      props = {
        showMessage: true,
        setLoading: true,
        search: '',
        setMetaData: true,
      }
    ) {
      if (props.setLoading) this.isLoading = true

      const params = {
        order: this.filterDataBrand.order,
        page: this.filterDataBrand.page,
        take: this.filterDataBrand.take,
        q: props.search === '' ? this.filterDataBrand.search : props.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }
      await this.$store
        .dispatch('retail/getAllBrand', params)
        .then((response) => {
          if (props.setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            const brandResponseData = responseData.data
            if (
              this.filterDataBrand.page !== 1 &&
              this.brandResponseData.length > 0 &&
              !props.setMetaData
            ) {
              this.brandResponseData.push(...brandResponseData)
            } else {
              this.brandResponseData = brandResponseData
            }

            if (props.search === '' || props.search === null) {
              this.mappingBrandData(this.brandResponseData)
            } else {
              this.mappingBrandData(this.brandResponseData, true)
            }
          }

          if ('meta' in responseData) {
            if (props.setMetaData) {
              this.metaDataBrand = responseData.meta
            }
          }

          if (props.showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (props.setLoading) this.isLoading = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveBrandAPI(params, isUpdate = false) {
      let dispatcher = 'retail/createBrand'
      if (isUpdate) dispatcher = 'retail/updateBrand'

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteBrandAPI(id) {
      this.isLoadingSave = true
      await this.$store
        .dispatch('retail/deleteBrand', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllBrand({
            showMessage: false,
            setLoading: true,
            search: '',
            setMetaData: true,
          })
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    mappingBrandData(brandResponseData, setSearch = false) {
      const brandData = []
      const filterBrandHasChild = brandResponseData.filter((item) => {
        return item.child_brand_total === 0
      })
      filterBrandHasChild.forEach((item) => {
        brandData.push({
          brand_name: item.name,
          brand_id: item.id,
          label: item.name,
          value: item.id,
        })
      })
      if (setSearch) {
        this.brandDataSearchOption = brandData
      } else {
        this.brandDataSearch = brandData
        this.brandData = brandResponseData
      }
    },
    // END: BRAND

    // BEGIN: CATEGORY
    async getAllCategory(
      props = {
        showMessage: true,
        setLoading: true,
        search: '',
        setMetaData: true,
      }
    ) {
      if (props.setLoading) this.isLoading = true

      const params = {
        order: this.filterDataCategory.order,
        page: this.filterDataCategory.page,
        take: this.filterDataCategory.take,
        q: props.search === '' ? this.filterDataCategory.search : props.search,
      }

      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('retail/getAllCategory', params)
        .then((response) => {
          if (props.setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            const categoryResponseData = responseData.data
            if (
              this.filterDataCategory.page !== 1 &&
              this.categoryResponseData.length > 0 &&
              !props.setMetaData
            ) {
              this.categoryResponseData.push(...categoryResponseData)
            } else {
              this.categoryResponseData = categoryResponseData
            }

            if (props.search === '' || props.search === null) {
              this.mappingCategoryData(this.categoryResponseData)
            } else {
              this.mappingCategoryData(this.categoryResponseData, true)
            }
          }

          if ('meta' in responseData) {
            if (props.setMetaData) {
              this.metaDataCategory = responseData.meta
            }
          }

          if (props.showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (props.setLoading) this.isLoading = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveCategoryAPI(params, isUpdate = false) {
      let dispatcher = 'retail/createCategory'
      if (isUpdate) dispatcher = 'retail/updateCategory'

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteCategoryAPI(id) {
      this.isLoadingSave = true
      await this.$store
        .dispatch('retail/deleteCategory', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllCategory({
            showMessage: false,
            setLoading: true,
            search: '',
            setMetaData: true,
          })
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    mappingCategoryData(categoryResponseData, setSearch = false) {
      const categoryData = []
      const filterCategoryHasChild = categoryResponseData.filter((item) => {
        return item.child_category_total === 0
      })
      filterCategoryHasChild.forEach((item) => {
        categoryData.push({
          category_name: item.name,
          category_id: item.id,
          label: item.name,
          value: item.id,
        })
      })
      if (setSearch) {
        this.categoryDataSearchOption = categoryData
      } else {
        this.categoryDataSearch = categoryData
        this.categoryData = categoryResponseData
      }
    },
    // END: CATEGORY

    // BEGIN: PRODUCT RETAIL
    async getAllProduct(
      props = {
        showMessage: true,
        setLoading: true,
        search: '',
        setMetaData: true,
      }
    ) {
      if (props.setLoading) this.isLoading = true

      const params = {
        order: this.filterDataProduct.order,
        page: this.filterDataProduct.page,
        take: this.filterDataProduct.take,
        q: props.search === '' ? this.filterDataProduct.search : props.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }
      await this.$store
        .dispatch('retail/getAllProduct', params)
        .then((response) => {
          if (props.setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            const productResponseData = responseData.data
            if (
              this.filterDataProduct.page !== 1 &&
              this.productResponseData.length > 0 &&
              !props.setMetaData
            ) {
              this.productResponseData.push(...productResponseData)
            } else {
              this.productResponseData = productResponseData
            }

            if (props.search === '' || props.search === null) {
              this.mappingProductData(this.productResponseData)
            } else {
              this.mappingProductData(this.productResponseData, true)
            }
          }

          if ('meta' in responseData) {
            if (props.setMetaData) {
              this.metaDataProduct = responseData.meta
            }
          }

          if (props.showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (props.setLoading) this.isLoading = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveProductAPI(params, isUpdate = false) {
      let dispatcher = 'retail/createProduct'
      if (isUpdate) dispatcher = 'retail/updateProduct'

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteProductAPI(id) {
      this.isLoadingSave = true
      await this.$store
        .dispatch('retail/deleteProduct', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllProduct({
            showMessage: false,
            setLoading: true,
            search: '',
            setMetaData: true,
          })
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    mappingProductData(productResponseData, setSearch = false) {
      const productData = []
      productResponseData.forEach((item) => {
        productData.push({
          product_name: item.name,
          product_id: item.id,
          stock: 'stock' in item ? item.stock.stock : 0,
          price: item.base_price,
          label: item.name,
          value: item.id,
        })
      })
      if (setSearch) {
        this.productDataSearchOption = productData
      } else {
        this.productDataSearch = productData
        this.productData = productResponseData
      }
    },
    searchProduct(query, setOptions) {
      if (query !== null && query !== undefined && query !== '') {
        const getSelectedItemProduct = this.selectedProductData.reduce(
          (accumulator, curVal) => {
            accumulator.push(curVal.product_id)
            return accumulator
          },
          []
        )

        this.getAllProduct({
          showMessage: false,
          setLoading: false,
          search: query,
          setMetaData: false,
        }).then(() => {
          setOptions(
            this.productDataSearchOption.filter(
              (item) => !getSelectedItemProduct.includes(item.product_id)
            )
          )
        })
      }
    },
    selectedProduct(event, key = null) {
      const selectedProductData = this.selectedProductData.filter(
        (item) => item.key !== key
      )
      selectedProductData.push({ key: key, ...event })
      this.selectedProductData = selectedProductData
    },
    resetSelectedProduct() {
      this.selectedProductData = []
    },
    // END: PRODUCT RETAIL

    // BEGIN: SUPPLIER
    async getAllSupplier(
      props = {
        showMessage: true,
        setLoading: true,
        search: '',
        setMetaData: true,
      }
    ) {
      if (props.setLoading) this.isLoading = true

      const params = {
        order: this.filterDataSupplier.order,
        page: this.filterDataSupplier.page,
        take: this.filterDataSupplier.take,
        q: props.search === '' ? this.filterDataSupplier.search : props.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }
      await this.$store
        .dispatch('retail/getAllSupplier', params)
        .then((response) => {
          if (props.setLoading) this.isLoading = false

          const responseData = response.data.data
          if ('data' in responseData) {
            const supplierResponseData = responseData.data
            if (
              this.filterDataSupplier.page !== 1 &&
              this.supplierResponseData.length > 0 &&
              !props.setMetaData
            ) {
              this.supplierResponseData.push(...supplierResponseData)
            } else {
              this.supplierResponseData = supplierResponseData
            }

            if (props.search === '' || props.search === null) {
              this.mappingSupplierData(this.supplierResponseData)
            } else {
              this.mappingSupplierData(this.supplierResponseData, true)
            }
          }

          if ('meta' in responseData) {
            if (props.setMetaData) {
              this.metaDataSupplier = responseData.meta
            }
          }

          if (props.showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (props.setLoading) this.isLoading = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveSupplierAPI(params, isUpdate = false) {
      let dispatcher = 'retail/createSupplier'
      if (isUpdate) dispatcher = 'retail/updateSupplier'

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteSupplierAPI(id) {
      this.isLoadingSave = true
      await this.$store
        .dispatch('retail/deleteSupplier', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getAllSupplier({
            showMessage: false,
            setLoading: true,
            search: '',
            setMetaData: true,
          })
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    mappingSupplierData(supplierResponseData, setSearch = false) {
      const supplierData = []
      const filterSupplierHasChild = supplierResponseData.filter((item) => {
        return item.child_supplier_total === 0
      })
      filterSupplierHasChild.forEach((item) => {
        supplierData.push({
          supplier_name: item.name,
          supplier_id: item.id,
          label: item.name,
          value: item.id,
        })
      })
      if (setSearch) {
        this.supplierDataSearchOption = supplierData
      } else {
        this.supplierDataSearch = supplierData
        this.supplierData = supplierResponseData
      }
    },
    // END: SUPPLIER

    // BEGIN: PURCHASE STOCK
    async getAllPurchaseStock(
      filterDates = { start: null, end: null },
      showMessage = true
    ) {
      this.isLoading = true

      const params = {
        order: this.filterDataPurchaseStock.order,
        page: this.filterDataPurchaseStock.page,
        take: this.filterDataPurchaseStock.take,
        start_date: filterDates.start,
        end_date: filterDates.end,
        q: this.filterDataPurchaseStock.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('retail/getAllPurchaseStock', params)
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data

          this.purchaseStockData = responseData.data

          if ('meta' in responseData) {
            this.metaDataPurchaseStock = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          if (showMessage) {
            const message = e.response.data.message
            this.message = this.handleErrorMessage(message)

            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getPurchaseStockDetailAPI(purchaseStockId, showMessage = true) {
      this.isLoading = true
      await this.$store
        .dispatch('retail/getPurchaseStockDetail', { id: purchaseStockId })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.purchaseStockDetailData = responseData

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          if (showMessage) {
            const message = e.response.data.message
            this.message = this.handleErrorMessage(message)

            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async savePurchaseStockAPI(params, isUpdate = false) {
      let dispatcher = 'retail/createPurchaseStock'
      if (isUpdate) dispatcher = 'retail/createPurchaseStock'

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    // END: PURCHASE STOCK

    // BEGIN: STOCK ADJUSTMENT
    async getAllStockAdjustment(
      filterDates = { start: null, end: null },
      showMessage = true
    ) {
      this.isLoading = true

      const params = {
        order: this.filterDataStockAdjustment.order,
        page: this.filterDataStockAdjustment.page,
        take: this.filterDataStockAdjustment.take,
        start_date: filterDates.start,
        end_date: filterDates.end,
        q: this.filterDataStockAdjustment.search,
      }
      if (this.tenantId !== null) {
        params.tenant_id = this.tenantId
      }

      await this.$store
        .dispatch('retail/getAllStockAdjustment', params)
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data

          this.stockAdjustmentData = responseData.data

          if ('meta' in responseData) {
            this.metaDataStockAdjustment = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          if (showMessage) {
            const message = e.response.data.message
            this.message = this.handleErrorMessage(message)

            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getStockAdjustmentDetailAPI(purchaseStockId, showMessage = true) {
      this.isLoading = true
      await this.$store
        .dispatch('retail/getStockAdjustmentDetail', { id: purchaseStockId })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.stockAdjustmentDetailData = responseData

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.isLoading = false

          if (showMessage) {
            const message = e.response.data.message
            this.message = this.handleErrorMessage(message)

            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async saveStockAdjustmentAPI(params, isUpdate = false) {
      let dispatcher = 'retail/createStockAdjustment'
      if (isUpdate) dispatcher = 'retail/createStockAdjustment'

      await this.$store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false

          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)

          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    // END: STOCK ADJUSTMENT
  },
}

<template>
  <div class="intro-y" :class="[wrapperClass]">
    <div class="report-box zoom-in">
      <div class="box p-2 2xl:p-5">
        <div class="flex justify-between">
          <div class="mt-1 text-xs text-slate-500 2xl:text-sm">
            {{ title }}
          </div>
          <div v-if="showPercentage" class="flex justify-end">
            <div v-if="parseInt(percentage) !== 0" class="ml-auto">
              <Tippy
                tag="div"
                class="report-box__indicator cursor-pointer text-xs 2xl:text-sm"
                :class="isUp ? 'bg-success' : 'bg-danger'"
                :content="
                  isUp
                    ? percentage +
                      ' ' +
                      $t('page.dashboard.higherThanYesterday')
                    : percentage + ' ' + $t('page.dashboard.lowerThanYesterday')
                "
              >
                {{ percentage }}
                <ArrowUpIcon v-if="isUp" class="ml-0.5 h-4 w-4" />
                <ArrowDownIcon v-else class="ml-0.5 h-4 w-4" />
              </Tippy>
            </div>
            <div v-else class="ml-auto">
              <Tippy
                tag="div"
                class="report-box__indicator cursor-pointer bg-gray-500"
                :content="sameAsYesterday"
              >
                {{ percentage }} =
              </Tippy>
            </div>
          </div>
        </div>
        <div
          v-if="totalIsPercentage"
          class="flex-between mt-6 flex pl-6 text-4xl font-medium leading-8"
          :class="isUp ? 'text-success' : 'text-danger'"
        >
          <!-- <div v-tooltip="different" class="flex"> -->
          <Tippy :content="differentNum">
            <span>{{ total }} </span>
            <ArrowUpIcon v-if="isUp" class="ml-0.5 inline h-8 w-8" />
            <ArrowDownIcon v-else class="ml-0.5 inline h-8 w-8" />
          </Tippy>
          <!-- </div> -->
          <!-- <Currency
            :total-in-string="different"
            wrap-class="inline ml-4 pt-2 text-xs text-slate-500"
            :prefix-text="`(${prefixDiff}`"
            :leading-text="`${$t('page.dashboard.thanMonth')})`"
          /> -->
        </div>
        <div v-else class="mt-6 text-2xl font-medium leading-8">
          <Currency v-if="isCurrency" :total-in-string="total" />
          <span v-else>{{ total }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Currency from '@/components/Currency.vue'

export default {
  name: 'CardComponent',
  components: {
    Currency,
  },
  props: {
    total: {
      type: [String, Number],
      default: '0',
    },
    different: {
      type: [String, Number],
      default: '0',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    isUp: {
      type: Boolean,
      default: false,
    },
    showPercentage: {
      type: Boolean,
      default: false,
    },
    isCurrency: {
      type: Boolean,
      default: true,
    },
    totalIsPercentage: {
      type: Boolean,
      default: false,
    },
    percentage: {
      type: [String, Number],
      default: 0,
    },
  },
  setup() {
    const { t, n } = useI18n()

    const sameAsYesterday = ref('')
    sameAsYesterday.value = t('page.dashboard.sameAsYesterday')

    return {
      t,
      sameAsYesterday,
    }
  },
  computed: {
    prefixDiff() {
      return this.isUp ? '+' : '-'
    },
    differentNum() {
      return (
        this.prefixDiff +
        'Rp' +
        String(this.formatPrice(Math.abs(this.different))) +
        ' ' +
        this.$t('page.dashboard.thanMonth')
      )
    },
  },
  methods: {
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>

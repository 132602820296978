import axios, { routePathAPI } from '@/plugins/axios.js'
import router from '@/router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  create({ commit }, payload) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('full_name', payload.full_name)
      formData.append('email', payload.email)
      formData.append('phone', payload.phone)
      formData.append('employee_number', payload.employee_number)
      formData.append('employee_pin', payload.employee_pin)
      formData.append('role_id', payload.role_id)
      formData.append('privilege_id', payload.privilege_id)
      formData.append('is_active', payload.is_active)
      formData.append('avatar_file', payload.avatar_file)
      formData.append('province_id', payload.province_id)
      formData.append('city_id', payload.city_id)
      formData.append('district_id', payload.district_id)
      formData.append('village_id', payload.village_id)
      formData.append('postal_code', payload.postal_code)
      formData.append('detail_address', payload.detail_address)
      if (payload.tenant_id !== '')
        formData.append('tenant_id', payload.tenant_id)

      axios
        .post(`${routePathAPI()}/user/staff/add`, formData, config)
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 201 || response.status === 200) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({ commit }, payload) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('full_name', payload.full_name)
      formData.append('email', payload.email)
      formData.append('phone', payload.phone)
      formData.append('employee_number', payload.employee_number)
      formData.append('employee_pin', payload.employee_pin)
      formData.append('role_id', payload.role_id)
      formData.append('privilege_id', payload.privilege_id)
      formData.append('is_active', payload.is_active)
      formData.append('avatar_file', payload.avatar_file)
      formData.append('province_id', payload.province_id)
      formData.append('city_id', payload.city_id)
      formData.append('district_id', payload.district_id)
      formData.append('village_id', payload.village_id)
      formData.append('postal_code', payload.postal_code)
      formData.append('detail_address', payload.detail_address)
      if (payload.tenant_id !== '')
        formData.append('tenant_id', payload.tenant_id)

      axios
        .put(
          `${routePathAPI()}/user/staff/edit/${payload.id}`,
          formData,
          config
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 201 || response.status === 200) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPI()}/user/staff/delete/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          let message = ''
          if (error.response.status === 500) message = error.message
          else message = error.response.data.message.detail
          reject({
            status: error.response.status,
            message,
            data: error.response.data,
          })
        })
    })
  },
  getAll({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.order_employee_number)
        params.order_employee_number = payload.order_employee_number
      if (payload.q) params.q = payload.q

      axios
        .get(`${routePathAPI()}/user/staff`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetail({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/user/staff/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllStaffAccess({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q

      axios
        .get(`${routePathAPI()}/user/staff/access`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.report.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex w-full justify-end md:w-fit">
      <div class="relative w-full text-slate-500 sm:ml-auto">
        <CalendarIcon
          class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
        />
        <Litepicker
          v-model="dateFilter"
          :options="{
            autoApply: false,
            singleMode: false,
            numberOfColumns: 2,
            numberOfMonths: 2,
            showWeekNumbers: true,
            format: 'YYYY-MM-DD',
            dropdowns: {
              minYear: 1990,
              maxYear: null,
              months: true,
              years: true,
            },
          }"
          class="form-control box pl-8 sm:w-56"
        />
      </div>
      <ExportCSVComponent @export="downloadSummary" />
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 md:mt-4">
          <div class="intro-y box mt-5 p-5">
            <div
              class="flex flex-col md:flex-row md:items-center md:justify-between"
            >
              <div class="flex">
                <div class="flex justify-center self-center align-middle">
                  <SaleIcon class="mr-2 h-4 w-4 text-black dark:text-white" />
                </div>
                <div class="flex">
                  <div
                    class="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0 md:self-center md:align-middle"
                  >
                    <h2 class="truncate text-lg font-medium">
                      {{ $t('page.report.summary.title') }}
                    </h2>
                    <span class="flex text-xs text-gray-500 md:self-center">
                      {{ dateFilterFormat(dateFilter) }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="isInstituteSuperAdminMode">
                <div
                  class="btn btn-outline-primary flex gap-1"
                  @click="
                    $router.push({
                      name: 'report-summary-compare-tenant',
                      params: { slug: $route.params.slug },
                      query: { lang: $route.query.lang },
                    })
                  "
                >
                  <SwapIcon />
                  <p>{{ $t('menu.report.compareTenant') }}s</p>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-3 pt-5 md:pt-10 lg:flex-row">
              <!-- BEGIN: Income -->
              <SummaryItem
                :title="$t('page.report.summary.income.title')"
                :total="incomes.net_sales"
                :tooltip-header="$t('tooltip.grossSales')"
                :tooltip-footer="$t('tooltip.netSales')"
              >
                <div class="flex justify-between py-2">
                  <p>{{ $t('page.report.summary.income.grossSales') }}</p>
                  <Currency :total-in-string="incomes.gross_sales" />
                </div>
                <div v-if="false" class="flex justify-between py-2">
                  <p>{{ $t('page.report.summary.income.sales') }}</p>
                  <Currency :total-in-string="incomes.net_sales" />
                </div>
                <div class="divider py-3"></div>
                <div class="flex justify-between py-2">
                  <p>{{ $t('page.report.summary.income.serviceCharge') }}</p>
                  <Currency :total-in-string="incomes.service_charge" />
                </div>
                <div class="flex justify-between py-2">
                  <p>{{ $t('page.report.summary.income.instituteFee') }}</p>
                  <Currency :total-in-string="incomes.institute_fee" />
                </div>
                <div class="flex justify-between py-2">
                  <p>{{ $t('page.report.summary.income.tax') }}</p>
                  <Currency :total-in-string="incomes.tax_fee" />
                </div>
              </SummaryItem>
              <!-- END: Income -->

              <!-- BEGIN: Promo Cost -->
              <SummaryItem
                :title="$t('page.report.summary.promoCost.title')"
                :total="promos.total"
                :tooltip-header="$t('tooltip.promo')"
              >
                <div class="flex justify-between py-2">
                  <p class="font-bold">
                    {{ $t('page.report.summary.promoCost.nonComplement') }}
                  </p>
                </div>
                <div class="flex justify-between py-2">
                  <p>{{ $t('page.report.summary.promoCost.promo') }}</p>
                  <Currency
                    :total-in-string="promos.non_complement.cost_total"
                    prefix-text="("
                    leading-text=")"
                  />
                </div>
                <div class="flex justify-between py-2 pl-6">
                  <ul class="w-full list-outside list-disc">
                    <li>
                      <p>
                        {{
                          $t('page.report.summary.promoCost.coveredByMerchant')
                        }}
                      </p>
                    </li>
                  </ul>
                  <Currency
                    :total-in-string="promos.non_complement.covered_by_merchant"
                  />
                </div>
                <div class="flex justify-between py-2 pl-6">
                  <ul class="w-full list-outside list-disc">
                    <li>
                      <p>
                        {{
                          $t('page.report.summary.promoCost.coveredByInkanteen')
                        }}
                      </p>
                    </li>
                  </ul>
                  <Currency
                    :total-in-string="
                      promos.non_complement.covered_by_inkanteen
                    "
                  />
                </div>
                <div class="divider py-3"></div>
                <div class="flex justify-between py-2">
                  <p class="font-bold">
                    {{ $t('page.report.summary.promoCost.complement') }}
                  </p>
                </div>
                <div class="flex justify-between py-2 pl-6">
                  <ul class="w-full list-outside list-disc">
                    <li>
                      <p>
                        {{ $t('page.report.summary.promoCost.totalProduct') }}
                      </p>
                    </li>
                  </ul>
                  <div>
                    <p>{{ promos.complement.product_total }}</p>
                  </div>
                </div>
                <div class="flex justify-between py-2 pl-6">
                  <ul class="w-full list-outside list-disc">
                    <li>
                      <p>
                        {{ $t('page.report.summary.promoCost.totalQuantity') }}
                      </p>
                    </li>
                  </ul>
                  <div>
                    <p>{{ promos.complement.quantity_total }}</p>
                  </div>
                </div>
                <div class="flex justify-between py-2 pl-6">
                  <ul class="w-full list-outside list-disc">
                    <li>
                      <p>
                        {{
                          $t('page.report.summary.promoCost.totalProductCost')
                        }}
                      </p>
                    </li>
                  </ul>
                  <Currency :total-in-string="promos.complement.cost_total" />
                </div>
              </SummaryItem>
              <!-- END: Promo Cost -->

              <!-- BEGIN: Other -->
              <SummaryItem
                :title="$t('page.report.summary.other.title')"
                :total="others.total"
                :tooltip-header="$t('tooltip.others')"
              >
                <div class="flex justify-between py-2">
                  <p>{{ $t('page.report.summary.other.adminFee') }}</p>
                  <Currency :total-in-string="others.admin_fee" />
                </div>
                <div class="flex justify-between py-2">
                  <p>{{ $t('page.report.summary.other.MDR') }}</p>
                  <Currency :total-in-string="others.mdr_fee" />
                </div>
                <div v-if="false" class="flex justify-between py-2">
                  <p>{{ $t('page.report.summary.income.uniqueCode') }}</p>
                  <Currency :total-in-string="others.unique_code" />
                </div>
                <div class="flex justify-between py-2">
                  <p>{{ $t('page.report.summary.income.rounding') }}</p>
                  <Currency :total-in-string="others.round_price" />
                </div>
              </SummaryItem>
              <!-- END: Gross Profit -->
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
      </div>
    </div>

    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import globalMixin from '@/mixins/global.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import Currency from '@/components/Currency.vue'
import SummaryItem from '@/components/SummaryItem.vue'

import ExportCSVComponent from '@/components/ExportCSVComponent.vue'

import SaleIcon from '@/assets/svg/sale.svg'
import SwapIcon from '@/assets/svg/swap.svg'

export default {
  name: 'SalesSummary',
  components: {
    //
    Breadcrumb,
    Currency,
    SummaryItem,
    ExportCSVComponent,

    SaleIcon,
    SwapIcon,
  },
  mixins: [globalMixin],
  setup() {
    const dateFilter = ref('')

    return {
      dateFilter,
    }
  },
  data: () => ({
    message: '',
    promos: {
      complement: {
        quantity_total: 0,
        product_total: 0,
        cost_total: 0,
      },
      non_complement: {
        covered_by_merchant: 0,
        covered_by_inkanteen: 0,
        cost_total: 0,
      },
      total: 0,
    },
    others: {},
    others_total: 0,
    incomes: {},
    incomes_total: 0,
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.getSalesSummary()
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }
  },
  methods: {
    async getSalesSummary() {
      this.isLoading = true

      await this.$store
        .dispatch('sales/summary', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          tenant_ids: this.selectedTenantIdString,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.others = responseData.others
            this.promos = responseData.promos
            this.incomes = responseData.incomes
            this.others_total = this.sumObjectValue(responseData.others)
            this.incomes_total = this.sumObjectValue(responseData.incomes)
          }
          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.isLoading = false

          this.message = e.message
          this.showToast('failed')
        })
    },
    async downloadSummary(fileType = '.csv') {
      await this.$store
        .dispatch('sales/summaryDownload', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'summary_data.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message
          // this.showToast('success')
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
  },
}
</script>

import axios, { routePathAPI } from '@/plugins/axios.js'
import axiosCore, { routePathAPICore } from '@/plugins/axios-core.js'
import axiosLumen, { routePathAPILumen } from '@/plugins/axios-lumen.js'
import router from '@/router'

const getSelectedTenantIds = () => {
  const selectedTenantId =
    localStorage.getItem('inkanteenDashboard.selectedTenantId') || []
  if (selectedTenantId.length > 0) {
    return JSON.parse(selectedTenantId)
  } else {
    return []
  }
}

// initial state
const state = () => ({})

// getters
const getters = {
  selectedTenantIdGetter(state) {
    return getSelectedTenantIds()
  },
}

// actions
const actions = {
  getAll({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q

      axios
        .get(`${routePathAPI()}/tenant`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  salesSummary({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/tenant/summary`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesTenant({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        start_date: payload.start_date,
        end_date: payload.end_date,
      }
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.tenant_name) params.tenant_name = payload.tenant_name

      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/tenant/list`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesTenantChart({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        periodic: payload.periodic ? payload.periodic.toUpperCase() : 'DAILY',
        tenant_ids: payload.tenant_ids ? payload.tenant_ids : '',
      }

      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/tenant/chart`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  salesTenantDownload({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axiosCore
        .get(`${routePathAPICore()}/transaction/sales/tenant/download`, {
          params: {
            start_date: payload.start_date,
            end_date: payload.end_date,
            order: 'DESC',
            file_type: payload.file_type ? payload.file_type : '.csv', // .csv / .xlsx
          },
          headers: {
            'x-language-code': currentLanguageCode,
          },
          responseType: 'blob',
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // Tenant Schedules
  getTenantSchedules({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.tenantId) params.tenant_id = payload.tenantId
      // if (payload.take) params.take = payload.take
      // if (payload.order) params.order = payload.order

      axiosLumen
        .get(`${routePathAPILumen()}/tenants/schedule`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setTenantSchedules({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        day_close: payload.day_close,
        schedules: payload.schedules,
      }

      axiosLumen
        .put(
          `${routePathAPILumen()}/tenants/schedule?tenant_id=${
            payload.tenant_id
          }`,
          params,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getUserTenant({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q

      axios
        .get(`${routePathAPI()}/user/tenant`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setSelectedTenantId({ commit, state }, tenantId) {
    return commit('SET_SELECTED_TENANT_ID', tenantId)
  },
  removeSelectedTenantId({ commit, state }) {
    return commit('REMOVE_SELECTED_TENANT_ID')
  },
  removeUserTenant({ commit, state }) {
    return commit('REMOVE_USER_TENANT')
  },
}

// mutations
const mutations = {
  SET_SELECTED_TENANT_ID(state, tenantId) {
    let newSelectedTenant = []
    newSelectedTenant.push(...tenantId)

    localStorage.setItem(
      'inkanteenDashboard.selectedTenantId',
      JSON.stringify(newSelectedTenant)
    )
  },
  REMOVE_SELECTED_TENANT_ID(state) {
    localStorage.removeItem('inkanteenDashboard.selectedTenantId')
  },
  REMOVE_USER_TENANT(state) {
    localStorage.removeItem('inkanteenDashboard.userTenantList')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default {
  data: () => ({
    paymentchannelId: null,
    paymentchannelData: [],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
  }),
  methods: {
    async getAllPaymentChannel(
      showMessage = true,
      client_reference_code,
      setLoading = false
    ) {
      if (setLoading) this.isLoading = true

      await this.$store
        .dispatch('generalpayment/getAll', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          payment_channel_name: this.filterData.search,
          client_reference_code: client_reference_code,
          is_cashier_payment: false,
        })
        .then((response) => {
          if (setLoading) this.isLoading = false

          const responseData = response.data.data
          if (responseData) {
            this.paymentchannelData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (setLoading) this.isLoading = false

          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async setPaymentChannelStatusAPI(payload) {
      this.isLoading = true
      await this.$store
        .dispatch('generalpayment/update', payload)
        .then((response) => {
          this.isLoading = false

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)

          // this.getAllPaymentChannel(false, client_reference_code)
        })
        .catch((e) => {
          this.isLoading = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async setPaymentChannelMDRAPI(payload) {
      this.isLoading = true
      await this.$store
        .dispatch('generalpayment/updateMDR', payload)
        .then((response) => {
          this.isLoading = false

          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)

          this.resetData()
        })
        .catch((e) => {
          this.isLoading = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
  },
}

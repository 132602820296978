import axios, { routePathAPI } from '@/plugins/axios.js'
import router from '@/router'
import { create } from 'lodash'

// initial state
const state = () => ({})

// v1/tenant-bill

// getters
const getters = {}

// actions
const actions = {
  // TENANT BILL
  getAllTenantBill({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q
      if (payload.institute_id) params.institute_id = payload.institute_id
      if (payload.tenant_ids) params.tenant_ids = payload.tenant_ids
      if (payload.payment_type) params.payment_type = payload.payment_type //PAY_MONTHLY,PROFIT_SHARING
      if ('is_paid' in payload) params.is_paid = payload.is_paid

      axios
        .get(`${routePathAPI()}/tenant-bill`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createTenantBill({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const params = {}
      if (payload.tenant_id) params.tenant_id = payload.tenant_id
      if (payload.name) params.name = payload.name
      if (payload.phone) params.phone = payload.phone
      if (payload.amount) params.amount = payload.amount
      if (payload.invoice_date) params.invoice_date = payload.invoice_date
      if (payload.payment_type) params.payment_type = payload.payment_type
      if (payload.payment_reminder)
        params.payment_reminder = payload.payment_reminder

      axios
        .post(`${routePathAPI()}/tenant-bill/create`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  remindTenantBill({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/tenant-bill/reminder/${payload.id}`,
          {},
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (
            (response.status === 200 || response.status === 201) &&
            responseData.error === undefined
          ) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.index')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
  </div>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Order Detail Table -->
        <TableComponent
          :title="$t($route.meta.title)"
          :header="kiosKManagementHeader"
          :is-show-search="true"
          :is-show-add="true"
          :is-not-empty="kiosKData.length > 0"
          :meta-data="metaData"
          @change-filter="changeFilter($event)"
          @do-add="showModalAdd"
        >
          <tr v-for="kiosk in kiosKData" :key="kiosk.id">
            <td class="text-center">
              {{ kiosk.name }}
            </td>
            <td class="text-center">
              {{ kiosk.institute_name }}
            </td>
            <td class="text-center">
              {{ kiosk.code }}
            </td>
            <td class="space-x-2">
              <EditIcon
                class="inline h-4 w-4 cursor-pointer text-warning hover:text-gray-500"
                @click="showModalEdit(kiosk)"
              />
              <DeleteIcon
                class="inline h-4 w-4 cursor-pointer text-danger hover:text-gray-500"
                @click="showModalDelete(kiosk.id)"
              />
            </td>
          </tr>
        </TableComponent>
        <!-- END: Order Detail Table -->

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->

        <!-- BEGIN: Modal Input -->
        <Modal :show="showModal" size="modal-lg" @hidden="showModal = false">
          <ModalBody class="p-4">
            <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
              <div class="flex flex-col space-y-3">
                <div class="flex justify-between">
                  <p class="font-bold">
                    <span v-if="!isEdit">
                      {{ $t('page.kiosk.modal.add') }}
                    </span>
                    <span v-else>
                      {{ $t('page.kiosk.modal.edit') }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="flex flex-col space-y-4">
                <div class="space-x-4 md:flex md:items-center">
                  <div class="md:w-1/12">
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="kiosk-name"
                    >
                      {{ $t('page.kiosk.input.name') }}
                    </label>
                  </div>
                  <div class="md:w-11/12">
                    <input
                      id="kiosk-name"
                      v-model="name"
                      required="required"
                      class="w-full appearance-none rounded border-2 py-2 px-4 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      type="text"
                      :placeholder="$t('page.kiosk.input.placeholder.name')"
                    />
                  </div>
                </div>
                <div class="space-x-4 md:flex md:items-center">
                  <div class="md:w-1/12">
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="kiosk-code"
                    >
                      {{ $t('page.kiosk.input.code') }}
                    </label>
                  </div>
                  <div class="md:w-11/12">
                    <input
                      id="kiosk-code"
                      v-model="code"
                      required="required"
                      class="w-full appearance-none rounded border-2 py-2 px-4 leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      type="text"
                      :placeholder="$t('page.kiosk.input.placeholder.code')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end space-x-2 px-5 pb-8">
              <button type="button" class="btn w-24" @click="showModal = false">
                {{ $t('button.cancel') }}
              </button>
              <button
                type="button"
                class="btn btn-primary w-24"
                @click="saveKiosk"
              >
                {{ $t('button.save') }}
              </button>
            </div>
          </ModalBody>
        </Modal>
        <!-- END: Modal Input -->

        <!-- BEGIN: Modal Info -->
        <Modal :show="showModalInfo" size="modal-md" @hidden="closeModalInfo">
          <ModalBody class="p-4">
            <div class="flex flex-col space-y-6 px-5 pb-8 text-center">
              <div class="flex flex-col space-y-3">
                <div class="flex justify-between">
                  <p class="font-bold">
                    <span>
                      {{ $t('page.kiosk.modal.info') }}
                    </span>
                  </p>
                </div>
              </div>
              <div v-if="createdData !== null" class="flex flex-col space-y-4">
                <div class="space-x-4 md:flex md:items-center">
                  <div class="md:w-3/12">
                    <label
                      class="mb-1 block text-left text-gray-700 md:mb-0"
                      for="kiosk-name"
                    >
                      {{ $t('page.kiosk.input.name') }}
                    </label>
                  </div>
                  <div class="md:w-1/12">
                    <span>:</span>
                  </div>
                  <div class="flex justify-start md:w-8/12">
                    {{ createdData.name }}
                  </div>
                </div>
                <div class="space-x-4 md:flex md:items-center">
                  <div class="md:w-3/12">
                    <label
                      class="mb-1 block text-left text-gray-700 md:mb-0"
                      for="kiosk-code"
                    >
                      {{ $t('page.kiosk.input.code') }}
                    </label>
                  </div>

                  <div class="md:w-1/12">
                    <span>:</span>
                  </div>
                  <div class="flex justify-start md:w-8/12">
                    {{ createdData.code }}
                  </div>
                </div>
                <div class="space-x-4 border-t pt-5 md:flex md:items-center">
                  <div class="md:w-3/12">
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="kiosk-user"
                    >
                      {{ $t('page.kiosk.input.user') }}
                    </label>
                  </div>

                  <div class="md:w-1/12">
                    <span>:</span>
                  </div>
                  <div
                    v-if="createdData.user"
                    class="flex justify-start md:w-8/12"
                  >
                    {{ createdData.user.username_or_email }}
                  </div>
                </div>
                <div class="space-x-4 md:flex md:items-center">
                  <div class="md:w-3/12">
                    <label
                      class="mb-1 block text-left font-bold text-gray-700 md:mb-0"
                      for="kiosk-password"
                    >
                      {{ $t('page.kiosk.input.password') }}
                    </label>
                  </div>
                  <div class="md:w-1/12">
                    <span>:</span>
                  </div>
                  <div class="flex justify-start md:w-8/12">
                    {{ createdData.user.password }}
                  </div>
                </div>
                <div class="space-x-4 md:flex md:items-center">
                  <div class="md:w-full md:px-4">
                    <span class="text-danger">{{
                      $t('page.kiosk.modal.infoDesc')
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end space-x-2 px-5">
              <button
                type="button"
                class="btn btn-primary w-24"
                @click="closeModalInfo"
              >
                {{ $t('button.close') }}
              </button>
            </div>
          </ModalBody>
        </Modal>
        <!-- END: Modal Info -->

        <DeleteModal
          :id="deleteId"
          :show-modal="showModalDel"
          @yes="confirmDelete($event)"
          @no="showModalDel = false"
        />

        <!-- BEGIN: Toast -->
        <Toast
          id="success"
          :title="$t('formInfo.success')"
          :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
        />
        <Toast
          id="failed"
          :title="$t('formInfo.failed')"
          :message="message !== '' ? message : $t('formInfo.fetchFailed')"
          :is-failed="true"
        />
        <!-- END: Toast -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import DeleteModal from '@/components/DeleteModal.vue'

import TableComponent from '@/components/TableComponent.vue'

export default {
  name: 'KiosKListPage',
  components: {
    Breadcrumb,
    DeleteModal,
    TableComponent,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()

    const dateFilter = ref('')

    return {
      t,
      n,
      store,
      dateFilter,
    }
  },
  data: () => ({
    name: '',
    code: '',
    kioskId: null,
    message: '',
    showModal: false,
    showModalInfo: false,
    createdData: null,
    isEdit: false,
    isLoadingSave: false,
    kiosKManagementHeader: [
      {
        item: 'page.kiosk.tableHeaders.name',
        customClass: 'text-center',
      },
      {
        item: 'page.kiosk.tableHeaders.institute',
        customClass: 'text-center',
      },
      {
        item: 'page.kiosk.tableHeaders.code',
        customClass: 'text-center',
      },
      {
        item: 'action.action',
      },
    ],
    kiosKData: [],
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
    },
  }),
  mounted() {
    this.getKiosKData()
  },
  methods: {
    async getKiosKData(showMessage = true) {
      await this.store
        .dispatch('kiosk/getAll', {
          order: this.filterData.order,
          page: this.filterData.page,
          take: this.filterData.take,
          q: '',
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.kiosKData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }

          if (showMessage) {
            this.message = response.data.message
            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async saveKiosKAPI(params, isUpdate = false) {
      let dispatcher = 'kiosk/create'
      if (isUpdate) dispatcher = 'kiosk/update'

      await this.store
        .dispatch(dispatcher, params)
        .then((response) => {
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          if (!isUpdate) {
            this.createdData = response.data.data
            this.showModalInfo = true
          }
          this.resetData()
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async deleteKiosK(id) {
      this.isLoadingSave = true
      await this.store
        .dispatch('kiosk/delete', { id })
        .then((response) => {
          this.isLoadingSave = false
          this.message = response.data.message
          setTimeout(() => {
            this.showToast('success')
          }, 500)
          this.resetModalDelete()
          this.getKiosKData(false)
        })
        .catch((e) => {
          this.isLoadingSave = false
          this.message = e.message
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getKiosKData()
    },
    resetData(afterSave = true) {
      this.kioskId = null
      this.name = ''
      this.code = ''
      if (afterSave) {
        this.isLoadingSave = false
        this.showModal = false
        this.isEdit = false
        this.getKiosKData(false)
      }
    },
    confirmDelete(id) {
      this.deleteKiosK(id)
    },
    showModalAdd() {
      this.showModal = true
      this.isEdit = false
      this.resetData(false)
    },
    showModalEdit(data) {
      this.showModal = true
      this.isEdit = true
      this.name = data.name
      this.code = data.code
      this.kioskId = data.id
    },
    saveKiosk() {
      if ((this.name === '' && this.code === '') || this.isLoadingSave) return

      const params = {
        name: this.name,
        code: this.code,
      }
      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.kioskId === null) {
          this.isLoadingSave = false
          return
        }
        params.id = this.kioskId

        this.saveKiosKAPI(params, true)
      } else {
        this.saveKiosKAPI(params)
      }
    },
    closeModalInfo() {
      this.showModalInfo = false
      this.createdData = null
    },
  },
}
</script>

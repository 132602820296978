<template>
  <div class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row">
    <div class="flex">
      <Breadcrumb
        :title="$t('menu.setting.inventoryManagement')"
        :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
      />
    </div>
    <div class="flex">
      <ButtonComponent
        :title="$t('page.retail.simpleStockPurchase.addButton')"
        @click="showModalAdd"
      >
        <template #icon> + </template>
      </ButtonComponent>
    </div>
  </div>
  <div class="relative grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: StockPurchase List -->
        <TableComponent
          :is-show-search="true"
          :search-options-with-key="isInstitute ? tenantOptions : []"
          :selected-search="isInstitute ? tenantId : ''"
          :title="$t('page.retail.simpleStockPurchase.title')"
          :header="simpleStockPurchaseHeader"
          :is-not-empty="purchaseStockData.length > 0"
          :meta-data="metaDataPurchaseStock"
          :show-icon="false"
          search-placeholder="page.retail.simpleStockPurchase.search"
          @change-filter="changeFilter($event)"
          @change-filter-dropdown="changeFilterDropdown($event)"
        >
          <template #additionalPreFilter>
            <div class="relative text-slate-500 shadow sm:ml-auto">
              <CalendarIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <Litepicker
                v-model="dateFilter"
                :options="{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  format: 'YYYY-MM-DD',
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                  },
                }"
                class="form-control box pl-8 sm:w-56"
              />
            </div>
          </template>

          <tr
            v-for="data in purchaseStockData"
            :key="data.id"
            class="cursor-pointer"
            @click="getDetailPurchaseStock(data.id)"
          >
            <td class="uppercase">{{ getSplitString(data.id, '-', 0, '') }}</td>
            <td>
              {{ formattedDate(subDate(data.created), 'DD MMMM YYYY') }}
            </td>
            <td>{{ data.items.length }}</td>
            <td>{{ countItemQuantity(data.items) }}</td>
            <td><Currency :total-in-string="data.total" /></td>
          </tr>
        </TableComponent>
        <!-- END: StockPurchase List -->
      </div>
    </div>

    <!-- BEGIN: Modal Add StockPurchase -->
    <Modal
      :show="showAddStockPurchaseModal"
      size="modal-xl"
      @hidden="closeModal('add')"
    >
      <ModalBody class="p-4">
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="purchaseStockSchema"
          :initial-errors="{}"
          :initial-touched="{}"
          :validate-on-mount="false"
          @submit="saveStockPurchase"
        >
          <div class="flex flex-col space-y-4 px-5 pb-8 text-center">
            <div class="flex flex-col space-y-3">
              <div class="flex items-center space-x-1">
                <PackageIcon />
                <p class="font-bold">
                  <span v-if="!isEdit">
                    {{ $t('page.retail.simpleStockPurchase.forms.title') }}
                  </span>
                  <span v-else>
                    {{ $t('page.retail.simpleStockPurchase.forms.editTitle') }}
                  </span>
                </p>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="purchaseStockSupplierId"
                  >
                    <span>
                      {{ $t('page.retail.simpleStockPurchase.forms.supplier') }}
                      <sup v-if="false" class="text-red-500">*</sup>
                    </span>
                  </label>
                </div>

                <div class="text-left">
                  <Field
                    v-slot="{ field }"
                    v-model="purchaseStockSupplierId"
                    name="purchaseStockSupplierId"
                  >
                    <select
                      id="purchaseStockSupplierId"
                      v-bind="field"
                      required="required"
                      class="w-full appearance-none rounded border-2 py-2 px-4 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                      :class="[
                        errors.purchaseStockSupplierId !== undefined
                          ? 'border-red-400'
                          : 'border-gray-200',
                      ]"
                    >
                      <option value="" disabled hidden>
                        {{
                          $t(
                            'page.retail.simpleStockPurchase.forms.supplierPlaceholder'
                          )
                        }}
                      </option>
                      <option
                        v-for="supplier in supplierData"
                        :key="supplier.id"
                        :value="supplier.id"
                      >
                        {{ supplier.name }}
                      </option>
                    </select>
                  </Field>

                  <span class="text-xs text-red-400">
                    <template
                      v-if="errors.purchaseStockSupplierId !== undefined"
                    >
                      {{ $t(errors.purchaseStockSupplierId) }}
                    </template>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-col space-y-4">
              <div class="flex w-full flex-col space-y-2">
                <div>
                  <label
                    class="block text-left text-xs font-bold text-gray-700"
                    for="purchaseStockNote"
                  >
                    {{ $t('page.retail.simpleStockPurchase.forms.note') }}
                  </label>
                </div>
                <div>
                  <textarea
                    id="purchaseStockNote"
                    v-model="purchaseStockNote"
                    required="required"
                    class="w-full appearance-none rounded border-2 p-2 text-xs leading-tight text-gray-700 focus:border-primary focus:bg-white focus:outline-none focus:ring-0"
                    :placeholder="
                      $t(
                        'page.retail.simpleStockPurchase.forms.notePlaceholder'
                      )
                    "
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-6">
              <TableComponent
                :is-show-search="false"
                :is-show-pagination="false"
                :show-title="false"
                :header="stockPurchaseFormHeader"
                :add-empty-header="true"
                wrapper-class="-mt-3"
                wrapper-table-class="pb-16"
              >
                <template v-if="purchaseStockItems.length > 0">
                  <tr
                    v-for="item in purchaseStockItems"
                    :key="item.id"
                    class="cursor-pointer"
                  >
                    <td class="w-full">
                      <ComboBox
                        v-model="selectedItemProductIds[item.id]"
                        :class="[
                          purchaseStockProductError &&
                          selectedItemProductIds[item.id] === undefined
                            ? 'border border-red-400'
                            : 'border-none',
                        ]"
                        :load-options="searchProduct"
                        @selected="selectedProduct($event, item.id)"
                      />
                    </td>
                    <td class="text-center text-gray-500">
                      <span
                        v-if="
                          selectedItemProductIds[item.id] !== undefined &&
                          !checkEmptyObject(selectedItemProductIds[item.id]) &&
                          'stock' in selectedItemProductIds[item.id]
                        "
                      >
                        {{ selectedItemProductIds[item.id].stock }}
                      </span>
                      <span v-else>-</span>
                    </td>
                    <td class="text-center">
                      <input
                        v-model="purchaseStockItemQuantities[item.id]"
                        type="number"
                        required="required"
                        class="w-full appearance-none rounded text-center focus:border-none focus:bg-white focus:outline-none focus:ring-0"
                        min="0"
                        placeholder="0"
                        :class="[
                          purchaseStockItemQuantitiesError &&
                          (purchaseStockItemQuantities[item.id] === undefined ||
                            purchaseStockItemQuantities[item.id] === null ||
                            purchaseStockItemQuantities[item.id] === '')
                            ? 'border border-red-400'
                            : 'border-none',
                        ]"
                        @keypress="isInputNumber($event)"
                      />
                    </td>
                    <td class="text-center text-gray-500">
                      <span
                        v-if="
                          selectedItemProductIds[item.id] !== undefined &&
                          !checkEmptyObject(selectedItemProductIds[item.id]) &&
                          'price' in selectedItemProductIds[item.id]
                        "
                      >
                        <Currency
                          :total-in-string="
                            selectedItemProductIds[item.id].price
                          "
                        />
                      </span>
                      <span v-else><Currency :total-in-string="0" /></span>
                    </td>
                    <td class="text-center text-gray-500">
                      <span
                        v-if="
                          selectedItemProductIds[item.id] !== undefined &&
                          !checkEmptyObject(selectedItemProductIds[item.id]) &&
                          'price' in selectedItemProductIds[item.id] &&
                          purchaseStockItemQuantities[item.id] !== undefined &&
                          purchaseStockItemQuantities[item.id] > 0
                        "
                      >
                        <Currency
                          :total-in-string="
                            selectedItemProductIds[item.id].price *
                            purchaseStockItemQuantities[item.id]
                          "
                        />
                      </span>
                      <span v-else><Currency :total-in-string="0" /></span>
                    </td>
                    <td class="space-x-2">
                      <div class="flex space-x-2">
                        <button
                          class="flex items-center space-x-1 rounded-sm bg-danger px-2 py-1 font-medium text-white"
                          @click="deleteItem(item.id)"
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
              </TableComponent>
            </div>
            <div class="border-b pb-2">
              <div
                class="flex w-full cursor-pointer"
                :class="
                  purchaseStockError ? 'border border-red-500 text-red-500' : ''
                "
                @click="addNewItem()"
              >
                <p class="text-gray-800">
                  + {{ $t('page.retail.simpleStockPurchase.forms.addProduct') }}
                </p>
              </div>
            </div>
            <div class="flex w-full justify-end">
              <div class="flex w-1/2 justify-between">
                <p>{{ $t('page.retail.simpleStockPurchase.forms.total') }}:</p>
                <Currency :total-in-string="purchaseStockTotal" />
              </div>
            </div>
          </div>
          <div class="flex justify-end space-x-2 px-5 pb-8">
            <button
              type="button"
              class="w-24 text-primary"
              @click="closeModal('add')"
            >
              {{ $t('button.cancel') }}
            </button>
            <button type="submit" class="btn btn-primary">
              {{ $t('page.retail.simpleStockPurchase.forms.createPurchase') }}
            </button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
    <!-- END: Modal Add StockPurchase -->

    <!-- END: Modal Detail StockPurchase -->
    <Modal
      :show="showDetailStockPurchaseModal"
      size="modal-xl"
      @hidden="closeModal('detail')"
    >
      <ModalBody class="p-4">
        <div
          v-if="!checkEmptyObject(purchaseStockDetailData)"
          class="space-y-4"
        >
          <div class="flex items-center justify-between">
            <p class="text-sm font-semibold">Stock Purchase</p>
            <p class="text-xs text-gray-500">
              {{
                formattedDate(
                  subDate(purchaseStockDetailData.created),
                  'dddd, DD MMMM YYYY, HH.mm.ss ZZ'
                )
              }}
            </p>
          </div>
          <div class="flex items-center justify-between text-xs">
            <p>No. Purchase</p>
            <p class="uppercase">
              {{ getSplitString(purchaseStockDetailData.id) }}
            </p>
          </div>
          <div class="flex items-center justify-between text-xs">
            <p>Supplier</p>
            <p>
              <span v-if="'supplier' in purchaseStockDetailData">
                {{ purchaseStockDetailData.supplier.name }}
              </span>
              <span v-else>-</span>
            </p>
          </div>
          <div class="flex items-center justify-between text-xs">
            <p>Catatan</p>
            <p class="max-w-full truncate">
              {{ purchaseStockDetailData.notes || '-' }}
            </p>
          </div>

          <div class="flex w-full justify-center border-b border-gray-600">
            <TableComponent
              :is-show-search="false"
              :is-show-pagination="false"
              :show-title="false"
              :header="stockPurchaseDetailHeader"
              :add-empty-header="true"
              wrapper-class="-mt-3 w-full"
              wrapper-table-class="pb-2"
            >
              <tr
                v-for="(item, key) in purchaseStockDetailData.items"
                :key="key"
                class="cursor-pointer"
              >
                <td>
                  <span v-if="'product' in item">
                    {{ item.product.name }}
                  </span>
                  <span v-else>-</span>
                </td>
                <td class="text-center">{{ item.in_stock }}</td>
                <td class="text-center">{{ item.quantity }}</td>
                <td><Currency :total-in-string="item.price" /></td>
                <td><Currency :total-in-string="item.total" /></td>
              </tr>
            </TableComponent>
          </div>
          <div class="flex w-full justify-end">
            <div class="flex w-1/2 justify-between">
              <p class="font-semibold">
                {{ $t('page.retail.simpleStockPurchase.forms.total') }}:
              </p>
              <Currency
                wrap-class="font-semibold"
                :total-in-string="purchaseStockDetailData.total"
              />
            </div>
          </div>
          <div class="flex justify-end pt-2 pb-5">
            <button
              type="button"
              class="btn-primary btn text-xs"
              @click="closeModal('detail')"
            >
              {{ $t('button.close') }}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
    <!-- END: Modal Detail StockPurchase -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->

    <DeleteModal
      :id="deleteId"
      :show-modal="showModalDel"
      @yes="confirmDelete($event)"
      @no="showModalDel = false"
    />

    <LoadingIndicator v-if="isLoading || isLoadingSave" />
  </div>
</template>
<script>
import * as Yup from 'yup'

import globalMixin from '@/mixins/global.js'
import tenantMixin from '@/mixins/tenant.js'
import retailMixin from '@/mixins/retail.js'

import { Form, Field } from 'vee-validate'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import ComboBox from '@/components/select-search/ComboBox.vue'
import Currency from '@/components/Currency.vue'

import PackageIcon from '@/assets/svg/package.svg'
import DeleteIcon from '@/assets/svg/delete.svg'

export default {
  name: 'SimplePurchasingStockPage',
  components: {
    Form,
    Field,
    Breadcrumb,
    TableComponent,
    ButtonComponent,
    DeleteModal,
    ComboBox,
    Currency,

    PackageIcon,
    DeleteIcon,
  },
  mixins: [globalMixin, tenantMixin, retailMixin],
  data: () => ({
    showDetailStockPurchaseModal: false,
    showAddStockPurchaseModal: false,
    dateFilter: '',

    simpleStockPurchaseHeader: [
      {
        item: 'page.retail.simpleStockPurchase.tableHeaders.purchaseNo',
        customClass: '',
      },
      {
        item: 'page.retail.simpleStockPurchase.tableHeaders.date',
        customClass: '',
      },
      {
        item: 'page.retail.simpleStockPurchase.tableHeaders.productTotal',
        customClass: '',
      },
      {
        item: 'page.retail.simpleStockPurchase.tableHeaders.itemTotal',
        customClass: '',
      },
      {
        item: 'page.retail.simpleStockPurchase.tableHeaders.purchaseTotal',
        customClass: '',
      },
    ],
    stockPurchaseFormHeader: [
      {
        item: 'page.retail.simpleStockPurchase.forms.tableHeaders.productName',
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.simpleStockPurchase.forms.tableHeaders.stockTotal',
          'page.retail.simpleStockPurchase.forms.tableHeaders.inv',
        ],
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.simpleStockPurchase.forms.tableHeaders.stockTotal',
          'page.retail.simpleStockPurchase.forms.tableHeaders.in',
        ],
        customClass: 'text-xs',
      },
      {
        item: 'page.retail.simpleStockPurchase.forms.tableHeaders.price',
        customClass: 'text-xs',
      },
      {
        item: 'page.retail.simpleStockPurchase.forms.tableHeaders.total',
        customClass: 'text-xs',
      },
      {
        item: 'action.action',
        customClass: 'text-xs',
      },
    ],
    stockPurchaseDetailHeader: [
      {
        item: 'page.retail.simpleStockPurchase.forms.tableHeaders.productName',
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.simpleStockPurchase.forms.tableHeaders.stockTotal',
          'page.retail.simpleStockPurchase.forms.tableHeaders.inv',
        ],
        customClass: 'text-xs',
      },
      {
        item: [
          'page.retail.simpleStockPurchase.forms.tableHeaders.stockTotal',
          'page.retail.simpleStockPurchase.forms.tableHeaders.in',
        ],
        customClass: 'text-xs',
      },
      {
        item: 'page.retail.simpleStockPurchase.forms.tableHeaders.price',
        customClass: 'text-xs',
      },
      {
        item: 'page.retail.simpleStockPurchase.forms.tableHeaders.total',
        customClass: 'text-xs',
      },
    ],

    // Form Validation
    purchaseStockSchema: Yup.object().shape({
      // purchaseStockSupplierId: Yup.string()
      //   .typeError('formError.supplier.required')
      //   .required('formError.supplier.required')
      //   .matches(/^(?!\s+$).*/, 'formError.supplier.required'),
    }),
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
    purchaseStockTotal() {
      let total = 0
      this.selectedProductData.forEach((item) => {
        if (
          'price' in item &&
          this.purchaseStockItemQuantities[item.key] !== undefined &&
          this.purchaseStockItemQuantities[item.key] > 0
        ) {
          total += this.purchaseStockItemQuantities[item.key] * item.price
        }
      })
      return total
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      if (this.tenantId !== '' && !this.isLoading) {
        this.getAllPurchaseStock(this.filterDates)
      }
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }

    if (this.activeUserStore !== null) {
      const role = this.activeUserStore.role.role
      if (role === 'Tenant Admin' && this.activeUserStore.tenant) {
        this.tenantId = this.activeUserStore.tenant.id
        if (!this.isLoading) {
          this.getAllPurchaseStock(this.filterDates)
          this.getAllSupplier({
            showMessage: false,
            setLoading: false,
            search: '',
            setMetaData: false,
          })
        }
        this.isInstitute = false
      } else {
        this.getAllTenant(true).then(() => {
          if (!this.isLoading) {
            this.getAllPurchaseStock(this.filterDates)
            this.getAllSupplier({
              showMessage: false,
              setLoading: false,
              search: '',
              setMetaData: false,
            })
          }
        })
        this.isInstitute = true
      }
    }
  },
  methods: {
    changeFilter(event) {
      this.filterDataPurchaseStock = event.filterData
      this.getAllPurchaseStock(this.filterDates)
    },
    changeFilterDropdown(event) {
      this.tenantId = event
      this.getAllPurchaseStock(this.filterDates)
    },
    showModalAdd() {
      this.showAddStockPurchaseModal = true
      this.isEdit = false
      this.resetData(false)
    },
    getDetailPurchaseStock(id) {
      this.getPurchaseStockDetailAPI(id).then((res) => {
        this.showDetailStockPurchaseModal = true
      })
    },
    saveStockPurchase() {
      if (this.isLoadingSave) return

      const params = {}
      params.tenant_id = this.tenantId
      params.supplier_id = this.purchaseStockSupplierId
      params.notes = this.purchaseStockNote

      const items = []

      this.purchaseStockItems.forEach((item) => {
        const data = {}
        if (item.id in this.selectedItemProductIds) {
          data['product_id'] = this.selectedItemProductIds[item.id].product_id
          data['price'] = this.selectedItemProductIds[item.id].price

          this.purchaseStockProductError = false
        } else {
          this.message = this.$t(
            'formError.simplePurchasingStock.stockItemProductRequired'
          )
          this.purchaseStockProductError = true

          return
        }

        if (
          item.id in this.purchaseStockItemQuantities &&
          this.purchaseStockItemQuantities[item.id] !== null &&
          this.purchaseStockItemQuantities[item.id] !== ''
        ) {
          data['quantity'] = this.purchaseStockItemQuantities[item.id]

          this.purchaseStockItemQuantitiesError = false
        } else {
          this.message = this.$t(
            'formError.simplePurchasingStock.stockItemQuantityRequired'
          )
          this.purchaseStockItemQuantitiesError = true

          return
        }

        items.push(data)
      })

      if (
        this.purchaseStockProductError ||
        this.purchaseStockItemQuantitiesError
      ) {
        setTimeout(() => {
          this.showToast('failed')
        }, 500)
        return
      }

      params.items = items

      this.isLoadingSave = true

      if (this.isEdit) {
        if (this.activeId === '') {
          this.isLoadingSave = false
          return
        }
        params.id = this.activeId

        this.savePurchaseStockAPI(params, true)
      } else {
        this.savePurchaseStockAPI(params, false)
      }
    },
    closeModal(type = 'add') {
      if (type === 'add') {
        this.showAddStockPurchaseModal = false
      } else {
        this.showDetailStockPurchaseModal = false
      }
    },
    confirmDelete(id) {
      this.deleteStockPurchaseAPI(id)
    },
    resetData(afterSave = true) {
      this.purchaseStockId = null
      this.purchaseStockSupplierId = ''
      this.purchaseStockNote = ''
      this.purchaseStockItemQuantities = []
      this.purchaseStockItems = []
      this.selectedItemProductIds = []
      this.purchaseStockError = false
      this.purchaseStockProductError = false
      this.purchaseStockItemQuantitiesError = false
      this.resetSelectedProduct()

      this.addNewItem()

      setTimeout(() => {
        if (this.$refs.form !== null) {
          this.$refs.form.setErrors({
            purchaseStockSupplierId: '',
          })
        }
      }, 500)

      if (afterSave) {
        this.isLoadingSave = false
        this.showAddStockPurchaseModal = false
        this.isEdit = false
        this.getAllPurchaseStock(this.filterDates, false)
      }
    },
    addNewItem() {
      const generatedId = this.generateId()
      this.purchaseStockItems.push({ id: generatedId })
      this.purchaseStockItemQuantities[generatedId] = ''
      this.purchaseStockError = false
    },
    deleteItem(id) {
      this.purchaseStockItems = this.purchaseStockItems.filter(
        (item) => item.id !== id
      )
    },
    countItemQuantity(items) {
      return items.reduce((val, currVal) => {
        val += currVal.quantity
        return val
      }, 0)
    },
  },
}
</script>

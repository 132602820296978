<template>
  <Modal :show="showModal" @hidden="$emit('no')">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="my-6 text-xl">{{ $t('confirmation.delete') }}</div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          class="btn btn-outline-primary mr-2 w-24"
          @click="$emit('no')"
        >
          {{ $t('button.cancel') }}
        </button>
        <button
          type="button"
          class="btn btn-danger w-24"
          @click="$emit('yes', id)"
        >
          {{ $t('button.yesOfCourse') }}
        </button>
      </div>
    </ModalBody>
  </Modal>
</template>
<script>
export default {
  name: 'DeleteModalComponent',
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['yes', 'no'],
}
</script>

<template>
  <div class="relative">
    <LoadingIndicator v-if="isLoading || isLoadingSave" />

    <div
      class="mt-4 flex w-full flex-col justify-between space-y-4 md:flex-row"
    >
      <div class="flex">
        <Breadcrumb
          :title="$t('menu.report.index')"
          :subtitle="'title' in $route.meta ? $t($route.meta.title) : ''"
        />
      </div>
    </div>

    <!-- BEGIN: Order Detail Table -->
    <div class="flex w-full justify-end pt-10">
      <div class="flex w-full justify-end md:w-fit">
        <div class="relative w-full text-slate-500 sm:ml-auto sm:mt-0">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="dateFilter"
            :options="{
              autoApply: false,
              singleMode: false,
              numberOfColumns: 2,
              numberOfMonths: 2,
              showWeekNumbers: true,
              format: 'YYYY-MM-DD',
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="form-control box pl-8 sm:w-56"
          />
        </div>
        <ExportCSVComponent @export="downloadPaymentMethod" />
      </div>
    </div>

    <div class="relative grid grid-cols-12 gap-6">
      <div class="col-span-12">
        <div class="col-span-12 mt-0 lg:col-span-12 lg:mt-4">
          <div
            class="box mt-5 flex flex-col gap-20 p-5 lg:grid lg:grid-cols-12"
          >
            <div class="col-span-12 flex flex-col lg:col-span-7">
              <div
                class="flex w-full flex-col justify-between md:flex-row md:items-center"
              >
                <div
                  class="flex flex-col self-center pb-5 align-middle md:flex-row md:pb-0"
                >
                  <h2 class="truncate text-base font-medium md:text-lg">
                    {{ $t('page.report.paymentMethod.title') }}
                  </h2>
                  <span class="flex self-center text-xs text-gray-500">
                    {{ dateFilterFormat(dateFilter) }}
                  </span>
                </div>
              </div>

              <div
                v-if="!paymentMethodChartEmpty"
                class="flex w-full flex-col gap-8 lg:mt-6 lg:flex-row lg:justify-between lg:gap-4"
              >
                <div class="flex items-center justify-center lg:items-start">
                  <ReportPieChart
                    :label="paymentMethodChartLabel"
                    :data="paymentMethodChartData"
                    :chart-colors="paymentMethodChartColors"
                    :height="275"
                    type="doughnut"
                  />
                </div>
                <div class="flex w-full flex-col gap-6 lg:gap-8">
                  <div
                    v-for="(chart, key) in paymentMethodCharts.filter(
                      (_, key) =>
                        paymentMethodChartPage === 1
                          ? key >= 0 && key < showPerPage
                          : key >= showPerPage &&
                            key < showPerPage * paymentMethodChartPage
                    )"
                    :key="key"
                    class="flex w-full justify-between gap-2"
                  >
                    <div class="flex max-w-1/2 items-center gap-1">
                      <div
                        class="rounded-full px-2"
                        :style="
                          key in paymentMethodChartColors
                            ? `background-color:${paymentMethodChartColors[key]}`
                            : `background-color: #ddd`
                        "
                      >
                        &nbsp;
                      </div>

                      <div class="truncate">{{ chart.payment_name }}</div>
                    </div>
                    <div class="max-w-1/2 truncate">
                      {{ chart.payment_percentage || '-' }}
                    </div>
                  </div>
                  <div
                    v-if="
                      paymentMethodCharts.length > showPerPage &&
                      paymentMethodChartTotalPage > 1
                    "
                    class="flex justify-center gap-2"
                  >
                    <div class="flex gap-1">
                      <p>{{ $t('info.page') }}</p>
                      <p>{{ paymentMethodChartPage }}</p>
                      <p>/</p>
                      <p>{{ paymentMethodChartTotalPage }}</p>
                    </div>
                    <div
                      class="flex items-center rounded-full border"
                      :class="
                        paymentMethodChartPage >= paymentMethodChartTotalPage
                          ? 'cursor-pointer border-black text-black'
                          : 'cursor-not-allowed border-gray-200 text-gray-200'
                      "
                      @click="changePagePaymentMethodChart('up')"
                    >
                      <ChevronUpIcon class="h-4 w-4" />
                    </div>
                    <div
                      class="flex items-center rounded-full border"
                      :class="
                        paymentMethodChartPage < paymentMethodChartTotalPage
                          ? 'cursor-pointer border-black text-black'
                          : 'cursor-not-allowed border-gray-200 text-gray-200'
                      "
                      @click="changePagePaymentMethodChart('down')"
                    >
                      <ChevronDownIcon class="h-4 w-4" />
                    </div>
                  </div>
                </div>
              </div>
              <NoData v-else />
            </div>

            <!-- BEGIN: Top Payment Method -->
            <div class="col-span-12 lg:col-span-5">
              <div
                class="flex w-full flex-row justify-between space-y-1 lg:space-y-0"
              >
                <div
                  class="flex w-full flex-col items-center gap-2 pb-5 md:flex-row md:pb-0"
                >
                  <div class="flex self-center align-middle">
                    <p class="truncate text-base lg:text-lg">
                      Transaksi Berdasarkan Metode
                    </p>
                  </div>

                  <div class="flex self-center text-xs text-gray-500">
                    {{ dateFilterFormat(dateFilter) }}
                  </div>
                </div>
                <div
                  v-if="false"
                  class="flex cursor-pointer items-start justify-end lg:items-center"
                >
                  <Tippy
                    tag="div"
                    class="report-box__indicator cursor-pointer"
                    :content="getContent('topTenant')"
                  >
                    <InfoIcon class="h-4 w-4 text-gray-500" />
                  </Tippy>
                </div>
              </div>
              <div
                class="flex h-full flex-col"
                :class="
                  topPaymentMethod.length > 0
                    ? 'mt-6 gap-6 lg:gap-8'
                    : 'items-center'
                "
              >
                <template v-if="topPaymentMethod.length > 0">
                  <div
                    v-for="(tenant, key) in topPaymentMethod.filter((_, key) =>
                      topPaymentMethodPage === 1
                        ? key >= 0 && key < showPerPage
                        : key >= showPerPage &&
                          key < showPerPage * topPaymentMethodPage
                    )"
                    :key="key"
                    class="flex justify-between"
                  >
                    <p class="max-w-1/2 truncate">{{ tenant.payment_name }}</p>
                    <p class="max-w-1/2 truncate">
                      {{ tenant.transaction_total }} Trx
                    </p>
                  </div>

                  <div
                    v-if="
                      topPaymentMethod.length > showPerPage &&
                      topPaymentMethodTotalPage > 1
                    "
                    class="flex justify-center gap-2"
                  >
                    <div class="flex gap-1">
                      <p>{{ $t('info.page') }}</p>
                      <p>{{ topPaymentMethodPage }}</p>
                      <p>/</p>
                      <p>{{ topPaymentMethodTotalPage }}</p>
                    </div>
                    <div
                      class="flex items-center rounded-full border"
                      :class="
                        topPaymentMethodPage >= topPaymentMethodTotalPage
                          ? 'cursor-pointer border-black text-black'
                          : 'cursor-not-allowed border-gray-200 text-gray-200'
                      "
                      @click="changePagePaymentMethodChart('up', 'top')"
                    >
                      <ChevronUpIcon class="h-4 w-4" />
                    </div>
                    <div
                      class="flex items-center rounded-full border"
                      :class="
                        topPaymentMethodPage < topPaymentMethodTotalPage
                          ? 'cursor-pointer border-black text-black'
                          : 'cursor-not-allowed border-gray-200 text-gray-200'
                      "
                      @click="changePagePaymentMethodChart('down', 'top')"
                    >
                      <ChevronDownIcon class="h-4 w-4" />
                    </div>
                  </div>
                </template>
                <NoData v-else wrapper-class="pt-0 pb-0 items-center h-full" />
              </div>
            </div>
            <!-- END: Top Payment Method -->
          </div>
          <!-- END: Tenant Sales Line Chart -->
        </div>
      </div>
    </div>
    <TableComponent
      :date-string="dateFilterFormat(dateFilter)"
      :title="$t('page.report.paymentMethod.paymentMethodTable')"
      :header="paymentMethodReportHeader"
      :is-show-search="false"
      :is-show-pagination="false"
      :meta-data="metaData"
      :is-not-empty="paymentMethodReportData.length > 0"
      :filter="{
        title: 'Payment Method',
        items: ['all'].concat(paymentIds),
      }"
      @change-filter-dropdown="changeFilterDropdown($event)"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="data in paymentMethodReportData" :key="data.payment_id">
        <td class="uppercase">{{ data.payment_name }}</td>
        <td class="text-center">{{ data.transaction_total }}</td>
        <td class="text-center">{{ data.transaction_percentage }}</td>
        <td><Currency :total-in-string="data.payment_total" /></td>
        <td class="text-center">{{ data.payment_percentage }}</td>
      </tr>
    </TableComponent>
    <!-- END: Order Detail Table -->

    <!-- BEGIN: Toast -->
    <Toast
      id="success"
      :title="$t('formInfo.success')"
      :message="message !== '' ? message : $t('formInfo.fetchSuccess')"
    />
    <Toast
      id="failed"
      :title="$t('formInfo.failed')"
      :message="message !== '' ? message : $t('formInfo.fetchFailed')"
      :is-failed="true"
    />
    <!-- END: Toast -->
  </div>
</template>

<script>
import { ref } from 'vue'
import { colors } from '@/utils/colors'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'

import Breadcrumb from '@/components/breadcrumb/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'

import TableComponent from '@/components/TableComponent.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import Currency from '@/components/Currency.vue'
import NoData from '@/components/NoData.vue'

export default {
  name: 'PaymentMethodPage',
  components: {
    Breadcrumb,
    ReportPieChart,
    TableComponent,
    ExportCSVComponent,
    Currency,
    NoData,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const dateFilter = ref()
    const paymentIds = ref([
      'balance',
      'qris',
      'va',
      'ewallet',
      'cash',
      'brizzi',
      'edc',
    ])

    return {
      t,
      n,
      store,
      route,
      router,
      dateFilter,
      paymentIds,
    }
  },
  data: () => ({
    paymentMethodReportHeader: [
      {
        item: 'page.report.paymentMethod.tableHeaders.paymentMethod',
        customClass: '',
      },
      {
        item: 'page.report.paymentMethod.tableHeaders.totalTransaction',
        customClass: 'text-center',
      },
      {
        item: 'page.report.paymentMethod.tableHeaders.totalPercent',
        customClass: 'text-center',
      },
      {
        item: 'page.report.paymentMethod.tableHeaders.sales',
        customClass: '',
      },
      {
        item: 'page.report.paymentMethod.tableHeaders.salesPercent',
        customClass: 'text-center',
      },
    ],
    paymentMethodReportData: [],
    cardData: {
      payment_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      sales_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
      transaction_total: {
        total: 0,
        percent: '0%',
        is_up: false,
      },
    },
    metaData: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterData: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
      payment_ids: '',
    },

    showPerPage: 6,
    paymentMethodChartEmpty: true,
    paymentMethodChartTotalPage: 2,
    paymentMethodChartPage: 1,
    paymentMethodCharts: [],
    paymentMethodChartLabel: [],
    paymentMethodChartItemName: [],
    paymentMethodChartData: [],
    paymentMethodChartColors: [
      colors.danger(0.9),
      colors.success(0.3),
      colors.warning(0.9),
      colors.pending(0.3),
      colors.pending(0.9),
      colors.warning(0.3),
      colors.success(0.9),
      colors.primary(0.3),
      colors.primary(0.9),
      colors.danger(0.3),
    ],

    topPaymentMethod: [],
    topPaymentMethodPage: 1,
    topPaymentMethodTotalPage: 2,
  }),
  computed: {
    filterDates() {
      const date = this.dateFilter.split(' - ')
      return {
        start: date.hasOwnProperty(0) ? date[0] : '',
        end: date.hasOwnProperty(1) ? date[1] : '',
      }
    },
    paymentId() {
      return this.paymentIds.toString()
    },
  },
  watch: {
    dateFilter: function () {
      const getDateFilter = this.getDateFilter()
      if (
        getDateFilter === null ||
        getDateFilter.start !== this.filterDates.start ||
        getDateFilter.end !== this.filterDates.end
      ) {
        this.setDateFilter(this.filterDates.start, this.filterDates.end)
      }

      this.init()
    },
  },
  mounted() {
    const getDateFilter = this.getDateFilter()
    if (getDateFilter !== null) {
      this.dateFilter = `${getDateFilter.start} - ${getDateFilter.end}`
    }
  },
  methods: {
    init() {
      this.filterData.payment_ids = this.paymentId
      this.getPaymentMethodReport()
      this.getPaymentMethodChart()
      this.getPaymentMethodRank()
    },
    async getPaymentMethodReport(showLoading = true) {
      if (showLoading) this.isLoading = true

      const payload = {
        start_date: this.filterDates.start,
        end_date: this.filterDates.end,
        payment_name: this.filterData.search,
        payment_ids: this.filterData.payment_ids,
      }

      if (this.isInstituteSuperAdminMode) {
        payload.institute_id = this.activeUserInstitute
      }

      await this.store
        .dispatch('payment/paymentMethodReport', payload)
        .then((response) => {
          if (showLoading) this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.paymentMethodReportData = responseData.data
          }
          if (responseData.meta) {
            this.metaData = responseData.meta
          }
          this.message = response.data.message

          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          if (showLoading) this.isLoading = false
          const message = e.response.data.message
          this.message = this.handleErrorMessage(message)
          setTimeout(() => {
            this.showToast('failed')
          }, 500)
        })
    },
    async getPaymentMethodChart(showMessage = false) {
      const payload = {
        start_date: this.filterDates.start,
        end_date: this.filterDates.end,
        payment_ids: this.paymentId,
      }
      if (this.isInstituteSuperAdminMode) {
        payload.institute_id = this.activeUserInstitute
      }

      await this.store
        .dispatch('payment/paymentMethodChart', payload)
        .then((response) => {
          const responseData = response.data
          if (responseData.data) {
            const paymentMethodChart = responseData.data
            this.paymentMethodChartEmpty = paymentMethodChart.length <= 0
            this.trendingMenuTotalPage =
              paymentMethodChart.length > this.showPerPage ? 2 : 1

            this.paymentMethodChartLabel = paymentMethodChart.map(
              (item) => item.payment_name
            )
            this.paymentMethodChartData = paymentMethodChart.map(
              (item) => item.payment_total
            )
            this.paymentMethodCharts = paymentMethodChart
          }

          if (showMessage) {
            this.message = response.data.message

            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (showMessage) {
            const message = e.response.data.message
            this.message = this.handleErrorMessage(message)
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async getPaymentMethodRank(showMessage = false) {
      const payload = {
        start_date: this.filterDates.start,
        end_date: this.filterDates.end,
        payment_ids: this.paymentId,
      }
      if (this.isInstituteSuperAdminMode) {
        payload.institute_id = this.activeUserInstitute
      }

      await this.store
        .dispatch('payment/paymentMethodRank', payload)
        .then((response) => {
          const responseData = response.data
          if (responseData.data) {
            this.topPaymentMethod = responseData.data
            this.trendingMenuTotalPage =
              responseData.data.length > this.showPerPage ? 2 : 1
          }

          if (showMessage) {
            this.message = response.data.message

            setTimeout(() => {
              this.showToast('success')
            }, 500)
          }
        })
        .catch((e) => {
          if (showMessage) {
            const message = e.response.data.message
            this.message = this.handleErrorMessage(message)
            setTimeout(() => {
              this.showToast('failed')
            }, 500)
          }
        })
    },
    async downloadPaymentMethod(fileType) {
      await this.store
        .dispatch('payment/paymentMethodDownload', {
          start_date: this.filterDates.start,
          end_date: this.filterDates.end,
          file_type: fileType,
        })
        .then((response) => {
          const filename = this.extractFilename(
            response.dispotition,
            'resport_payment_method.csv'
          )
          saveAs(response.data, filename)

          this.message = response.message

          setTimeout(() => {
            this.showToast('success')
          }, 500)
        })
        .catch((e) => {
          this.message = e.message
          this.showToast('failed')
        })
    },
    changePagePaymentMethodChart(type = 'up', data = 'chart') {
      if (data == 'top') {
        if (type === 'up') {
          if (this.topPaymentMethodPage >= this.topPaymentMethodTotalPage)
            this.topPaymentMethodPage -= 1
        } else if (type === 'down') {
          if (this.topPaymentMethodTotalPage > this.topPaymentMethodPage)
            this.topPaymentMethodPage += 1
        }
      } else {
        if (type === 'up') {
          if (this.paymentMethodChartPage >= this.paymentMethodChartTotalPage)
            this.paymentMethodChartPage -= 1
        } else if (type === 'down') {
          if (this.paymentMethodChartTotalPage > this.paymentMethodChartPage)
            this.paymentMethodChartPage += 1
        }
      }
    },
    changeFilterDropdown(event) {
      if (event === 'all') {
        if (this.filterData.payment_ids === this.paymentIds) return
        this.filterData.payment_ids = this.paymentId
      } else {
        if (event === this.filterData.payment_ids) return
        this.filterData.payment_ids = event
      }
      this.getPaymentMethodReport()
    },
    changeFilter(event) {
      this.filterData = event.filterData
      this.getPaymentMethodReport()
    },
  },
}
</script>
